import { Button, Card, CardContent, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { AllocationDestinationAndStackingStrategyDto, YardPositionDto } from '@storage/app/api'
import { DetailedSelect } from '@storage/components/DetailedSelect'
import { useStackingStrategyOptions } from '@storage/features/carrier-visit-allocation-rules/hooks/use-stacking-strategy-options.hook'
import { YardPositionSelector } from '@storage/features/yard-position-selector/YardPositionSelector'
import { BaseAllocationRulesUIStore } from '@storage/stores/base-allocation-rules.ui-store'
import { AllocationRuleDto } from '@storage/types'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { useWatch } from 'react-hook-form'

interface AllocationRulePanelFormDestinationProps<T extends AllocationRuleDto> {
  uiStore: BaseAllocationRulesUIStore<T>
  allocationRuleForm: any
  destinationAndStackingStrategiesFormValue: AllocationDestinationAndStackingStrategyDto[]
  fieldIndex: number
  goBack: () => void
}

export const AllocationRulePanelFormDestination = observer(
  <T extends AllocationRuleDto>({
    uiStore,
    allocationRuleForm,
    destinationAndStackingStrategiesFormValue,
    fieldIndex,
    goBack,
  }: AllocationRulePanelFormDestinationProps<T>) => {
    const { t } = useTranslate()
    const { stackingStrategyOptions } = useStackingStrategyOptions()

    // Store the initial destination value
    const originalDestinationRef = useRef<AllocationDestinationAndStackingStrategyDto | null>(null)

    const stackingStrategyValue = useWatch({
      control: allocationRuleForm.control,
      name: `destinationAndStackingStrategies.${fieldIndex}.stackingStrategy`,
    })

    const destinationValue = useWatch({
      control: allocationRuleForm.control,
      name: `destinationAndStackingStrategies.${fieldIndex}.destination`,
    })

    const destinationsToKeep = destinationAndStackingStrategiesFormValue
      .filter((_, index) => index !== fieldIndex)
      .map(strategy => ({
        destination: strategy.destination,
        fill: true,
        border: false,
      }))

    useEffect(() => {
      // Store the initial value when component mounts
      originalDestinationRef.current = destinationAndStackingStrategiesFormValue[fieldIndex]

      // Case of add: keep the existing destinations highlighted (fill only) on 1st render
      uiStore.setDestinationsToHighlight(destinationsToKeep)

      // Case of edit: highlight the destination in question (border only) on 1st render
      if (destinationValue?.block) {
        handleYardPositionChange(destinationValue)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleYardPositionChange = (position: YardPositionDto) => {
      allocationRuleForm.setValue(
        `destinationAndStackingStrategies.${fieldIndex}.destination`,
        position,
      )

      // Keep the existing destinations highlighted (fill only) and add the new one (border only)
      uiStore.setDestinationsToHighlight([
        ...destinationsToKeep,
        { destination: position, fill: false, border: true },
      ])
    }

    const handleStackingStrategyChange = (value: string) => {
      allocationRuleForm.setValue(
        `destinationAndStackingStrategies.${fieldIndex}.stackingStrategy`,
        value,
      )
    }

    //TODO: show validation error in YardPositionSelector component
    const handleConfirm = async () => {
      const isValid = await allocationRuleForm.trigger(
        `destinationAndStackingStrategies.${fieldIndex}.destination`,
      )
      if (isValid) {
        goBack()
      }
    }

    const handleCancel = () => {
      const isNewDestination = !originalDestinationRef.current?.destination?.block
      if (isNewDestination) {
        // For add case: remove the newly added field
        allocationRuleForm.setValue(
          'destinationAndStackingStrategies',
          destinationAndStackingStrategiesFormValue.slice(0, -1),
        )
      } else {
        // For edit case: revert to the stored original value
        allocationRuleForm.setValue(
          `destinationAndStackingStrategies.${fieldIndex}`,
          originalDestinationRef.current,
        )
      }

      goBack()
    }

    return (
      <Card>
        <CardContent>
          <Stack gap={2}>
            <Stack gap={1}>
              <Typography variant='h4'>{t('defineDestination', 'Define destination')}</Typography>
              <YardPositionSelector
                handleChange={handleYardPositionChange}
                showTier={false}
                gridDirection='column'
                position={destinationValue}
              />
            </Stack>
            <Stack gap={1}>
              <Typography variant='h4'>{t('stackingStrategy', 'Stacking strategy')}</Typography>
              <DetailedSelect
                options={stackingStrategyOptions}
                value={stackingStrategyValue}
                onChange={handleStackingStrategyChange}
              />
            </Stack>
            <Stack gap={1}>
              <Stack direction='row' spacing={2}>
                <Button
                  variant='contained'
                  color='inherit'
                  onClick={handleConfirm}
                  data-cy='confirm-destination-button'
                >
                  {t('confirm', 'Confirm')}
                </Button>
                <Button onClick={handleCancel} color='inherit'>
                  {t('cancel', 'Cancel')}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    )
  },
)
