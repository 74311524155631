import { CarrierVisitDirection } from '@storage/app/api'
import { PATH_STORAGE } from '@storage/routes'
import { SyntheticEvent, useState } from 'react'
import { generatePath, useNavigate } from 'react-router'

interface UseCarrierVisitDirectionNavigationProps {
  carrierVisitId: number
  discriminator: string
  onDirectionChange?: (direction: CarrierVisitDirection) => void
  hasUnsavedChanges?: boolean
}

export const useCarrierVisitDirectionNavigation = ({
  carrierVisitId,
  discriminator,
  onDirectionChange,
  hasUnsavedChanges = false,
}: UseCarrierVisitDirectionNavigationProps) => {
  const navigate = useNavigate()
  const [pendingDirection, setPendingDirection] = useState<CarrierVisitDirection | null>(null)
  const [showUnsavedChangesConfirmDialog, setShowUnsavedChangesConfirmDialog] = useState(false)

  const navigateToDirection = (newDirection: CarrierVisitDirection) => {
    onDirectionChange?.(newDirection)
    navigate(
      generatePath(PATH_STORAGE.yardPlanningDashboard.details.root, {
        [PATH_STORAGE.yardPlanningDashboard.details.params.carrierVisitId]:
          carrierVisitId.toString(),
        [PATH_STORAGE.yardPlanningDashboard.details.params.handlingDirection]: newDirection,
        [PATH_STORAGE.yardPlanningDashboard.details.params.discriminator]: discriminator,
      }),
      { replace: true },
    )
  }

  const handleDirectionChange = (_: SyntheticEvent, newDirection: CarrierVisitDirection) => {
    if (hasUnsavedChanges) {
      setPendingDirection(newDirection)
      setShowUnsavedChangesConfirmDialog(true)
      return
    }
    navigateToDirection(newDirection)
  }

  const handleConfirmDirectionChange = () => {
    if (pendingDirection) {
      navigateToDirection(pendingDirection)
      setPendingDirection(null)
    }
    setShowUnsavedChangesConfirmDialog(false)
  }

  const handleCancelDirectionChange = () => {
    setPendingDirection(null)
    setShowUnsavedChangesConfirmDialog(false)
  }

  return {
    handleDirectionChange,
    handleConfirmDirectionChange,
    handleCancelDirectionChange,
    showUnsavedChangesConfirmDialog,
  }
}
