import {
  BlockLabellingPattern,
  CreateYardBlockDto,
  StackingStrategy,
  UpdateYardBlockDto,
  YardBlockDto,
} from '@storage/app/api'
import {
  mapFormValuesToYardBlockUsageOptions,
  mapUsageOptions,
} from '../yard-block-form/yard-block-form.mapper'
import { YardFlexibleZoneFormProfile } from './yard-flexible-zone-form.profile'

const mapFormValuesToUpdateYardBlockDto = ({
  id,
  name,
  usageOptions,
}: YardFlexibleZoneFormProfile): UpdateYardBlockDto => ({
  yardBlockId: id!,
  name,
  maxTier: 1,
  usageOptions: mapFormValuesToYardBlockUsageOptions(usageOptions),
  stackingStrategy: StackingStrategy.TierBayRow,
})

const mapFormValuesToCreateYardBlockDto = ({
  name,
  usageOptions,
}: YardFlexibleZoneFormProfile): CreateYardBlockDto => ({
  name,
  maxTier: 1,
  isFlexibleZone: true,
  rowNames: ['01'],
  bayNames: ['01'],
  equipmentTypes: [],
  usageOptions: mapFormValuesToYardBlockUsageOptions(usageOptions),
  stackingStrategy: StackingStrategy.TierBayRow,
  rowLabellingPattern: BlockLabellingPattern.Numeric,
  bayLabellingPattern: BlockLabellingPattern.Numeric,
})

const mapDtoToFormValues = ({
  id,
  name,
  usageOptions,
}: YardBlockDto): YardFlexibleZoneFormProfile => ({
  id,
  name,
  usageOptions: mapUsageOptions(usageOptions),
})

export const YardFlexibleZoneMapper = {
  mapFormValuesToCreateYardBlockDto,
  mapFormValuesToUpdateYardBlockDto,
  mapDtoToFormValues,
}
