import { BerthDto, BerthsPutRequest, Bollard, CreateBerthDto } from '@storage/app/api'
import { BerthFormProfile } from './berth-form.profile'

const mapFormValuesToCreateDto = ({
  name,
  maxDraft,
  quayMeterMarkFrom,
  quayMeterMarkTo,
  pierId,
  bollards,
}: BerthFormProfile): CreateBerthDto => ({
  name,
  maxDraft,
  quayMeterMarkFrom,
  quayMeterMarkTo,
  pierId,
  bollards: bollards
    .filter(e => e !== null && e.meterMark !== null && e.name !== null)
    .map(e => ({
      name: e.name!,
      meterMark: e.meterMark!,
    })),
})

const mapFormValuesToUpdateDto = ({
  id,
  name,
  maxDraft,
  quayMeterMarkFrom,
  quayMeterMarkTo,
  pierId,
  bollards,
}: BerthFormProfile): BerthsPutRequest => ({
  id: id!,
  name,
  maxDraft,
  quayMeterMarkFrom,
  quayMeterMarkTo,
  pierId,
  bollards: bollards
    .filter(e => e !== null && e.meterMark !== null)
    .map(
      e =>
        <Bollard>{
          name: e.name,
          meterMark: e.meterMark!,
        },
    ),
})

const mapDtoToFormValues = ({
  id,
  name,
  maxDraft,
  quayMeterMarkFrom,
  quayMeterMarkTo,
  pierId,
  bollards,
}: BerthDto): BerthFormProfile => ({
  id,
  name,
  maxDraft: maxDraft ?? undefined,
  quayMeterMarkFrom,
  quayMeterMarkTo,
  pierId,
  bollards: bollards.map(e => {
    return {
      meterMark: e.meterMark,
      name: e.name ?? '',
    }
  }),
})

export const BerthFormMapper = {
  mapFormValuesToCreateDto,
  mapFormValuesToUpdateDto,
  mapDtoToFormValues,
}
