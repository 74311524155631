import { Chip } from '@mui/material'
import { Stack } from '@mui/system'
import { GeneralCargoItemPropertiesDto } from '@storage/app/api'
import { useMinimalsTheme } from '@tom-ui/ui'

interface GeneralCargoStockOccupancyItemsProps {
  items: GeneralCargoItemPropertiesDto[]
}
const GeneralCargoStockOccupancyItems = ({ items }: GeneralCargoStockOccupancyItemsProps) => {
  const theme = useMinimalsTheme()

  return (
    <Stack direction='row' gap={theme.customSpacing.xs}>
      {items.map((item, index) => (
        <Chip key={index} label={item.unitId} color='secondary' size='small' />
      ))}
    </Stack>
  )
}

export default GeneralCargoStockOccupancyItems
