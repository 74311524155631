import { SxProps, Theme } from '@mui/material'
import { UnitTypeSelectChip } from '@planning/components/molecules/UnitTypeSelectChip'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface IProps {
  sx?: SxProps<Theme>
}

export const UnitTypeField: FC<IProps> = observer(({ sx }) => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={`unitType`}
      rules={{ required: true }}
      render={({ field: { onChange, value } }) => (
        <UnitTypeSelectChip sx={sx} value={value} onChange={onChange} />
      )}
    />
  )
})
