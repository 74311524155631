import { ExportApi, StorageReportType } from '@billing/app/api'
import { createApiClient } from '@billing/app/http-client'
import StorageReportMode from '@billing/app/models/storage-report-mode.enum'
import { ExportContainerTurnoverOptionsDto } from '@billing/features/reports/mappers/ct-report-mapper'
import { downloadFile, getFileNameFromAxiosResponse } from '@billing/utils/download-file'
import { Dayjs } from 'dayjs'

class ExportService {
  async exportNonVesselContainers(start: Dayjs, end: Dayjs, storageReportMode: StorageReportMode) {
    const response = await createApiClient(ExportApi).exportNonVesselContainers(
      start.toString(),
      end.toString(),
      storageReportMode === StorageReportMode.Standard
        ? StorageReportType.Standard
        : StorageReportType.ExitDate,
      {
        responseType: 'blob',
      },
    )
    const data = response.data as unknown

    const filename = getFileNameFromAxiosResponse(response)

    downloadFile(new Blob([data as BlobPart]), filename)
  }

  async exportVesselContainers(vesselVisitId: number) {
    const response = await createApiClient(ExportApi).exportVesselContainers(vesselVisitId, {
      responseType: 'blob',
    })

    const data = response.data as unknown

    const filename = getFileNameFromAxiosResponse(response)

    downloadFile(new Blob([data as BlobPart]), filename)
  }

  async exportContainerTurnovers(
    exportContainerTurnoverOptionsDto: ExportContainerTurnoverOptionsDto,
  ) {
    const response = await createApiClient(ExportApi).exportContainerTurnovers(
      exportContainerTurnoverOptionsDto.isDangerous,
      exportContainerTurnoverOptionsDto.isDamaged,
      exportContainerTurnoverOptionsDto.includeContainerNumber,
      exportContainerTurnoverOptionsDto.includeIsoCode,
      exportContainerTurnoverOptionsDto.includeOperator,
      exportContainerTurnoverOptionsDto.includeGrossWeight,
      exportContainerTurnoverOptionsDto.includeIsOOG,
      exportContainerTurnoverOptionsDto.includeIsReefer,
      exportContainerTurnoverOptionsDto.includeIsEmpty,
      exportContainerTurnoverOptionsDto.includeIsDangerous,
      exportContainerTurnoverOptionsDto.includeEntryDateTime,
      exportContainerTurnoverOptionsDto.includeIsDamaged,
      {
        responseType: 'blob',
      },
    )

    const data = response.data as unknown

    const filename = getFileNameFromAxiosResponse(response)

    downloadFile(new Blob([data as BlobPart]), filename)
  }

  async getNonVesselSummary(start: Dayjs, end: Dayjs, storageReportMode: StorageReportMode) {
    const response = await createApiClient(ExportApi).getStorageSummary(
      start.toString(),
      end.toString(),
      storageReportMode === StorageReportMode.Standard //TODO: why StorageReportMode cannot be assigned to undefined | StorageReportMode ?
        ? StorageReportType.Standard
        : StorageReportType.ExitDate,
    )
    return response.data
  }

  async getVesselSummary(vesselVisitId: number) {
    const { data } = await createApiClient(ExportApi).getVesselSummary(vesselVisitId)
    return data
  }

  async exportCustomerBillingData(customerId: number, from: Dayjs | null, to: Dayjs | null) {
    const response = await createApiClient(ExportApi).exportCustomerBillingData(
      customerId,
      from?.toISOString(),
      to?.toISOString(),
      {
        responseType: 'blob',
      },
    )

    const data = response.data as unknown

    const filename = getFileNameFromAxiosResponse(response)

    downloadFile(new Blob([data as BlobPart]), filename)
  }

  async exportStoragePreBillingData(orderIds: number[], byStock: boolean) {
    const response = await createApiClient(ExportApi).exportGcStoragePrebillingData(
      orderIds,
      byStock,
      {
        responseType: 'blob',
      },
    )

    const data = response.data as unknown

    const filename = getFileNameFromAxiosResponse(response)

    downloadFile(new Blob([data as BlobPart]), filename)
  }

  async exportServicesPreBillingData(customerId: number, from: Dayjs | null, to: Dayjs | null) {
    const response = await createApiClient(ExportApi).exportServiceOrdersReport(
      customerId,
      from?.toISOString(),
      to?.toISOString(),
    )

    const data = response.data as unknown

    const filename = getFileNameFromAxiosResponse(response)

    downloadFile(new Blob([data as BlobPart]), filename)
  }

  async exportHandlingServicesPreBillingData(orderIds: number[]) {
    const response = await createApiClient(ExportApi).exportHandlingServicesPrebillingReport(
      orderIds,
      {
        responseType: 'blob',
      },
    )

    const data = response.data as unknown

    const filename = getFileNameFromAxiosResponse(response)

    downloadFile(new Blob([data as BlobPart]), filename)
  }

  async exportBillableItemsData(customerId: number, from: Dayjs | null, to: Dayjs | null) {
    const response = await createApiClient(ExportApi).exportBillableItemsReport(
      customerId,
      from?.toISOString(),
      to?.toISOString(),
      {
        responseType: 'blob',
      },
    )

    const data = response.data as unknown

    const filename = getFileNameFromAxiosResponse(response)

    downloadFile(new Blob([data as BlobPart]), filename)
  }
}

const exportService = new ExportService()

export default exportService
