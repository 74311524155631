import { BlockLabellingPattern } from '@storage/app/api'

export const generateLabelRange = (
  from: string,
  to: string,
  patternType: BlockLabellingPattern,
): string[] => {
  const range: string[] = []
  if (patternType === BlockLabellingPattern.Numeric) {
    const maxLength = Math.max(to.length, from.length, 2)
    const start = parseInt(from, 10)
    const end = parseInt(to, 10)
    const step = start <= end ? 1 : -1

    for (let i = start; step > 0 ? i <= end : i >= end; i += step) {
      range.push(i.toString().padStart(maxLength, '0'))
    }
  } else if (patternType === BlockLabellingPattern.Alphabetic) {
    const start = from.charCodeAt(0)
    const end = to.charCodeAt(0)
    const step = start <= end ? 1 : -1

    for (let i = start; step > 0 ? i <= end : i >= end; i += step) {
      range.push(String.fromCharCode(i))
    }
  }
  return range
}

export const generateCustomLabelRange = (label: string): string[] => {
  return label.split(',').map(l => l.trim())
}
