import { Button, Card, CardContent, Stack, Typography } from '@mui/material'
import { IVesselVisitItem } from '@planning/rt-stores/vesselVisit/VesselVisitItem'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

interface IProps {
  vesselVisitItem: IVesselVisitItem
  onViewClick: (e: any) => void
}

export const RestowDashboardCard = observer(({ vesselVisitItem, onViewClick }: IProps) => {
  const { t } = useTranslate()
  const expectedRestows = vesselVisitItem.restows.length ?? 0
  return (
    <Card>
      <CardContent>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
          <Typography variant='h4'>{t('restows', 'Restows')}</Typography>

          <Stack direction='row' alignItems={'center'}>
            <Button onClick={onViewClick}>{t('view', 'View')}</Button>
          </Stack>
        </Stack>

        <Stack direction='column' spacing='0.25rem'>
          <Typography variant='subtitle1'>{t('expected', 'Expected')}</Typography>
          <Typography variant='h4'>{expectedRestows}</Typography>
        </Stack>
      </CardContent>
    </Card>
  )
})
