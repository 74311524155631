import { Drawer } from '@mui/material'
import {
  FinishWorkInstructionJobDto,
  useOperationsStore,
  WorkInstructionJobStepper,
  WorkInstructionJobStepperHeader,
} from '@tom-ui/operations'
import { orderService, serviceOrdersService } from '@tom-ui/planning'
import { GeneralCargoStorageTracker, useStorageStore } from '@tom-ui/storage'
import { observer } from 'mobx-react-lite'
import { ConfirmActualCargoAmountOrderCommand } from 'modules/planning/src/app/api'
import { useCallback } from 'react'

const useHandleConfirm = () => {
  const {
    equipmentOperatorWorkInstructionStepperUIStore,
    equipmentOperatorWorkInstructionsUIStore,
    appStore,
  } = useOperationsStore()
  const { generalCargoStorageTrackerUIStore } = useStorageStore()
  return useCallback(
    async (currentJob: FinishWorkInstructionJobDto) => {
      const workInstructionDto = equipmentOperatorWorkInstructionsUIStore.getWorkInstructionJobDto(
        currentJob.workInstructionId,
      )

      try {
        const amount = generalCargoStorageTrackerUIStore.totalNewQuantity

        if (
          workInstructionDto?.operationType === 'Service' &&
          workInstructionDto?.service?.externalId &&
          workInstructionDto?.cargoUnit.externalId
        ) {
          await generalCargoStorageTrackerUIStore.onConfirm(
            workInstructionDto?.service.type === 'Stuffing' ? 'Outbound' : 'Inbound',
            workInstructionDto?.service.externalId,
            workInstructionDto?.cargoUnit.externalId,
          )

          if (workInstructionDto?.service.type === 'Stuffing') {
            await serviceOrdersService.confirmCargoItemActualAmmount({
              id: workInstructionDto?.cargoUnit.externalId,
              actualAmount: amount,
            })
          } else {
            await serviceOrdersService.confirmStrippingOrderCargoItemActualAmmount({
              id: workInstructionDto?.cargoUnit.externalId,
              actualAmount: amount,
            })
          }
        } else if (workInstructionDto?.order?.externalId && amount >= 0) {
          await generalCargoStorageTrackerUIStore.onConfirm(
            workInstructionDto?.operationType,
            workInstructionDto?.order?.externalId,
          )

          await orderService.confirmActualCargoAmount({
            id: workInstructionDto?.order?.externalId,
            amount: amount,
          } as ConfirmActualCargoAmountOrderCommand)
        }

        await equipmentOperatorWorkInstructionStepperUIStore.uploadSignatures(currentJob.signature)
        await equipmentOperatorWorkInstructionStepperUIStore.finishJob({
          ...currentJob,
          amount: amount,
        })
      } catch (ex: any) {
        appStore.setShowAlert(
          'error',
          JSON.stringify(ex?.response?.data?.errors) ??
            ex?.response?.data?.detail ??
            JSON.stringify(ex?.message) ??
            'Unkown error',
        )

        equipmentOperatorWorkInstructionsUIStore.showJob(currentJob.workInstructionId)
      }
    },
    [
      appStore,
      equipmentOperatorWorkInstructionStepperUIStore,
      equipmentOperatorWorkInstructionsUIStore,
      generalCargoStorageTrackerUIStore,
    ],
  )
}

export const GeneralCargoWorkInstructionStepper = observer(() => {
  const { equipmentOperatorWorkInstructionStepperUIStore } = useOperationsStore()
  const { generalCargoStorageTrackerUIStore } = useStorageStore()

  const handleConfirm = useHandleConfirm()
  const handleClose = async (): Promise<void> => {
    await generalCargoStorageTrackerUIStore.deleteAllUploadedFiles()
  }

  return (
    <Drawer
      open={equipmentOperatorWorkInstructionStepperUIStore.isOpen}
      anchor='right'
      PaperProps={{
        sx: {
          width: '100vw',
        },
      }}
    >
      <WorkInstructionJobStepperHeader onConfirm={handleConfirm} onClose={handleClose} />

      <WorkInstructionJobStepper DestinationComponent={GeneralCargoStorageTracker} />
    </Drawer>
  )
})
