import { Grid } from '@mui/material'
import { VesselDto } from '@planning/app/api'
import { IDeleteRecordActions, useDeleteRecord } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { LabeledTypography } from './LabeledTypography'
import { ListCard, ListCardAction } from './ListCard'

interface IVesselListCardProps {
  vessel: VesselDto
  actions: IDeleteRecordActions
  onEdit: (id: number) => void
  canWrite?: boolean
}

export const VesselListCard = observer(
  ({ vessel, actions, onEdit, canWrite }: IVesselListCardProps) => {
    const onDelete = useDeleteRecord(actions, vessel.name)
    const { t } = useTranslate()
    return (
      <ListCard>
        <Grid item xs={2} display='flex' alignContent='center' justifyContent='center'>
          <LabeledTypography label={t('name', 'Name')} text={vessel.name} />
        </Grid>
        <Grid item xs={2} display='flex' alignContent='center' justifyContent='center'>
          <LabeledTypography
            label={t('shippingLine', 'Shipping Line')}
            text={vessel.shippingLine}
          />
        </Grid>
        <Grid item xs={2} display='flex' alignContent='center' justifyContent='center'>
          <LabeledTypography label={t('imoNumber', 'IMONumber')} text={vessel.imoNumber} />
        </Grid>
        <Grid item xs={1} display='flex' alignContent='center' justifyContent='center'>
          <LabeledTypography label={t('mmsi', 'MMSI')} text={vessel.mmsi} />
        </Grid>
        <Grid item xs={1} display='flex' alignContent='center' justifyContent='center'>
          <LabeledTypography label={t('callSign', 'Call Sign')} text={vessel.callSign} />
        </Grid>
        <Grid item xs={1} display='flex' alignContent='center' justifyContent='center'>
          <LabeledTypography label={t('width', 'Width')} text={vessel.width.toString()} />
        </Grid>
        <Grid item xs={1} display='flex' alignContent='center' justifyContent='center'>
          <LabeledTypography label={t('length', 'Length')} text={vessel.length.toString()} />
        </Grid>
        {canWrite && (
          <>
            <ListCardAction onClick={() => onEdit(vessel.id)}>{t('edit', 'Edit')}</ListCardAction>
            <ListCardAction onClick={() => onDelete(vessel.id)}>
              {t('delete', 'Delete')}
            </ListCardAction>
          </>
        )}
      </ListCard>
    )
  },
)
