import { appStore } from '@host/stores/AppStore'
import { Box, ClickAwayListener, Collapse, Grow, Paper, Popper } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import useActiveLink from '../../../hooks/useActiveLink'
import { RouteItem } from '../types'
import NavItemContent from './NavItemContent'

interface NavListSubProps {
  routes: RouteItem[] | undefined
  depth: number
  isMenuOpen?: boolean
  onClose: () => void
}

const NavSubMenu = ({ routes, depth, isMenuOpen, onClose }: NavListSubProps) => {
  return (
    <>
      {routes?.map(route => (
        <NavItem
          key={route.title + route.path}
          route={route}
          depth={depth + 1}
          hasChild={!!route.children}
          isMenuOpen={isMenuOpen}
          onClose={onClose}
        />
      ))}
    </>
  )
}

interface Props {
  route: RouteItem
  depth: number
  hasChild: boolean
  isMenuOpen?: boolean
  onClose?: () => void
}

export const NavItem = observer(({ route, depth, hasChild, isMenuOpen, onClose }: Props) => {
  const { active, isExternalLink } = useActiveLink(route.path)
  const [open, setOpen] = useState(false)
  const theme = useMinimalsTheme()

  const anchorRef = useRef<HTMLDivElement>(null)

  const handleToggle = () => {
    if (open) setOpen(false)
    setOpen(!open)
  }

  const handleClose = (event?: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event?.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const handleNavClose = () => {
    if (onClose) {
      onClose()
    } else if (depth === 1) {
      handleToggle()
    }

    if (isMenuOpen) {
      appStore.toggleMenu(false)
    }
  }

  return (
    <>
      <Box ref={anchorRef}>
        <NavItemContent
          route={route}
          depth={depth}
          open={open}
          active={active}
          isExternalLink={isExternalLink}
          onClick={handleToggle}
          onClose={handleNavClose}
          hasChild={hasChild}
          isMenuMobileOpen={isMenuOpen}
        />
      </Box>

      {hasChild && (
        <>
          {isMenuOpen ? (
            <Collapse in={open} unmountOnExit>
              <NavSubMenu
                routes={route.children}
                depth={depth}
                onClose={handleNavClose}
                isMenuOpen={isMenuOpen}
              />
            </Collapse>
          ) : (
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              placement='right'
              transition
              sx={{ zIndex: theme.zIndex.drawer }}
            >
              {({ TransitionProps }) => (
                <Grow {...TransitionProps}>
                  <Paper
                    sx={{
                      marginLeft: theme.customSpacing.xs,
                      boxShadow: theme.customShadows.dropdown,
                    }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <Box>
                        <NavSubMenu
                          routes={route.children}
                          depth={depth}
                          onClose={handleNavClose}
                          isMenuOpen={isMenuOpen}
                        />
                      </Box>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          )}
        </>
      )}
    </>
  )
})
