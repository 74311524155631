import { Add, AllInclusive, Edit } from '@mui/icons-material'
import { Divider, IconButton, InputAdornment } from '@mui/material'
import { Stack } from '@mui/system'
import { WeightClassDto } from '@storage/app/api'
import { FormProps } from '@storage/app/models'
import { ControlledTextField } from '@storage/components/form-controls'
import StackedAlert from '@storage/components/StackedAlert'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import { AppAlert } from '@storage/stores/alert.store'
import { useTranslate } from '@tolgee/react'
import { BinIcon } from '@tom-ui/ui'
import { useEffect, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import { mapWeightClassDtoListToWeightClassFormProfile } from './weight-classes-form.mapper'
import { defaultWeightClass, WeightClassFormProfile } from './weight-classes-form.profile'
import { schema } from './weight-classes-form.scheme'

interface Props extends FormProps {
  weightClasses: WeightClassDto[]
  validationAlerts: AppAlert[]
  onSubmit: (formValues: WeightClassFormProfile) => Promise<void> | void
  showAlert: (key: string, message: string) => void
  canWrite: boolean
}

const WeightClassesForm = ({
  id,
  onSubmit,
  showAlert,
  weightClasses,
  validationAlerts,
  canWrite,
}: Props) => {
  const { t } = useTranslate()

  const { handleSubmit, control, formState, getValues, reset } =
    useFormWithSchema<WeightClassFormProfile>(schema, {})

  const { fields, append, update, remove, move } = useFieldArray({
    control,
    name: 'weightClasses',
  })

  const [noData, setNoData] = useState<boolean>(weightClasses.length === 0)

  useEffect(() => {
    if (weightClasses.length) {
      reset(mapWeightClassDtoListToWeightClassFormProfile(weightClasses))
    } else {
      if (noData) {
        setNoData(false)
        append(defaultWeightClass)
      }
    }
  }, [weightClasses, reset, noData, append])

  useEffect(() => {
    const fieldError = formState.errors.weightClasses?.root
    if (fieldError) {
      showAlert(fieldError.type, fieldError.message!)
    }
  }, [formState.errors.weightClasses?.root, showAlert])

  const isDeleteButtonDisabled = fields.length <= 2

  const isLastIndex = (index: number) => index === fields.length - 1

  const handleOnAddNew = (index: number) => {
    append(defaultWeightClass)
    move(fields.length, index + 1)

    if (isLastIndex(index)) {
      const field = getValues(`weightClasses.${index}`)

      update(index, {
        ...field,
        maxFieldIsInfinite: false,
        max: '' as any,
      })
    }
  }

  const handleOnDelete = (index: number) => {
    remove(index)
  }

  const handleOnInfinityToggle = (index: number) => {
    const currentField = getValues(`weightClasses.${index}`)

    update(index, {
      ...currentField,
      maxFieldIsInfinite: !currentField.maxFieldIsInfinite,
      max: undefined,
    })
  }

  return (
    <>
      <Stack
        id={id}
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
        spacing={2}
        mt={4}
      >
        {fields.map((field, index) => (
          <Stack key={field.id} direction='row' spacing={1} alignItems='center'>
            <ControlledTextField
              label={t('name', 'Name')}
              name={`weightClasses.${index}.name`}
              formState={formState}
              control={control}
            />
            <Divider orientation='vertical' variant='middle' flexItem />
            <ControlledTextField
              label={t('from', 'From')}
              type='number'
              name={`weightClasses.${index}.min`}
              formState={formState}
              control={control}
            />
            <ControlledTextField
              label={t('to', 'To')}
              type='number'
              name={`weightClasses.${index}.max`}
              formState={formState}
              control={control}
              disabled={field.maxFieldIsInfinite}
              endAdornment={
                isLastIndex(index) && (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => handleOnInfinityToggle(index)}>
                      {field.maxFieldIsInfinite ? <Edit /> : <AllInclusive />}
                    </IconButton>
                  </InputAdornment>
                )
              }
              sx={{ width: isLastIndex(index) ? '195px' : undefined }}
            />
            {canWrite && (
              <>
                <IconButton onClick={() => handleOnAddNew(index)}>
                  <Add />
                </IconButton>

                <IconButton
                  color='error'
                  disabled={isDeleteButtonDisabled}
                  onClick={() => handleOnDelete(index)}
                >
                  <BinIcon />
                </IconButton>
              </>
            )}
          </Stack>
        ))}

        {!!validationAlerts.length && <StackedAlert alerts={validationAlerts} />}
      </Stack>
    </>
  )
}

export default WeightClassesForm
