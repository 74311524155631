import { Button, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import React from 'react'

interface IProps {
  onClick: () => void
  updateCount?: number
}

export const ReviewUpdatesBtn: React.FC<IProps> = ({ onClick, updateCount }) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  return (
    <Stack
      direction='row'
      bgcolor={theme.palette.grey[100]}
      gap={theme.customSpacing.xs}
      borderRadius={theme.customRadius.small}
    >
      <Stack direction='row' alignItems='center' sx={{ padding: '0 .5rem' }} gap={1}>
        <Typography variant='body2' color='primary'>
          {t('updates', 'Updates')} ({updateCount ?? 0})
        </Typography>
        <Button color='inherit' variant='contained' onClick={onClick}>
          {t('review', 'Review')}
        </Button>
      </Stack>
    </Stack>
  )
}
