import { tolgee } from '@storage/app/translation'
import * as yup from 'yup'
import { DGSegregationRuleFormProfile } from './dg-segregation-rules-table-form.profile'

export const schema: yup.ObjectSchema<DGSegregationRuleFormProfile> = yup.object({
  distance: yup
    .number()
    .typeError(tolgee.t('dgSegregationRuleDistanceValidationNumber', 'Distance must be a number'))
    .required(tolgee.t('dgSegregationRuleDistanceValidationRequired', 'Distance is required'))
    .min(0, tolgee.t('dgSegregationRuleDistanceValidationMin', 'Distance must be 0 or greater')),
})
