import { useEffect, useState } from 'react'

interface AsyncFetchOptions {
  cleanUp?: () => void
  onError?: (error: any) => void
  onSuccess?: () => void
}

export const useAsyncFetch = (
  fetch: () => Promise<void>,
  dependencies: React.DependencyList = [],
  options?: AsyncFetchOptions,
) => {
  const [error, setError] = useState<any>(undefined)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetch()
        if (options?.onSuccess) {
          options.onSuccess()
        }
      } catch (error: any) {
        setError(error)
        if (options?.onError) {
          options.onError(error)
        }
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    if (options?.cleanUp) {
      return options.cleanUp()
    }
  }, dependencies) // eslint-disable-line react-hooks/exhaustive-deps

  return { error, loading }
}
