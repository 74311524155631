import { Typography } from '@mui/material'
import Stack from '@mui/material/Stack'
import { FilterChip } from '@planning/components/atoms/FilterChip'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

type ActiveFilters = {
  text: string
  key: string
}

interface Props {
  activeFilters: ActiveFilters[]
  title?: string
  onFilterRemove: (key: string) => void
}

export const FilterChips = observer(({ activeFilters, title, onFilterRemove }: Props) => {
  const { t } = useTranslate()

  if (activeFilters.length === 0) {
    return <></>
  }

  return (
    <Stack direction='row' alignItems='center' gap={1} padding={2}>
      <Typography variant='subtitle1'>{title ?? t('filterBy', 'Filter By')}: </Typography>
      {activeFilters.map(filter => (
        <FilterChip
          key={filter.key}
          label={filter.text}
          onDelete={() => onFilterRemove(filter.key)}
        />
      ))}
    </Stack>
  )
})
