import { CarrierVisitDto, VesselSummaryDto } from '@billing/app/api'
import { CarrierVisitStore } from '@billing/stores/CarrierVisitStore'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { VesselReportTotalSummary } from '../models/vessel-report-total-summary.model'

export class VesselReportUIStore {
  selectedVesselVisitId?: number | string = ''
  vesselSummary?: VesselSummaryDto

  constructor(private carrierVisitStore: CarrierVisitStore) {
    makeObservable(this, {
      selectedVesselVisitId: observable,
      vesselSummary: observable,

      vesselVisitsOptions: computed,
      vesselSummaryMetrics: computed,

      setSelectedVesselVisitId: action,
      setVesselSummary: action,
    })
  }

  public get vesselVisitsOptions(): CarrierVisitDto[] {
    return _(this.carrierVisitStore.items)
      .sortBy(wq => wq.ata)
      .value()
  }

  public get vesselSummaryMetrics(): VesselReportTotalSummary {
    const totalDischarge = _.sumBy(this.vesselSummary?.discharge, x => x.amount),
      totalLoad = _.sumBy(this.vesselSummary?.load, x => x.amount),
      totalRestow = _.sumBy(this.vesselSummary?.restow, x => x.amount)

    return {
      total: totalDischarge + totalLoad + totalRestow,
      discharge: totalDischarge,
      load: totalLoad,
      restow: totalRestow,
    }
  }

  setSelectedVesselVisitId(id?: number): void {
    this.selectedVesselVisitId = id
  }

  setVesselSummary(dto?: VesselSummaryDto): void {
    this.vesselSummary = dto
  }

  public getVoyageDisplay(carrierVisitDto: CarrierVisitDto): string {
    return `${carrierVisitDto.importVoyage ? carrierVisitDto.importVoyage : '-'} / ${
      carrierVisitDto.exportVoyage ? carrierVisitDto.exportVoyage : '-'
    }`
  }

  public resetData(): void {
    this.setSelectedVesselVisitId()
    this.setVesselSummary()
  }
}
