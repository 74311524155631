import { IInspectionRailCar } from '../../Stores/RailTallyViewStore'

const sortBySequence = (a: IInspectionRailCar, b: IInspectionRailCar) => a.sequence - b.sequence
const sortByIsMissingRailcar = (a: IInspectionRailCar, b: IInspectionRailCar) =>
  a.isMissingRailcar === b.isMissingRailcar ? 0 : a.isMissingRailcar ? 1 : -1

export const sortRailcars = (a: IInspectionRailCar, b: IInspectionRailCar): number => {
  const missingRailcarSortValue = sortByIsMissingRailcar(a, b)
  if (missingRailcarSortValue === 0) return sortBySequence(a, b)

  return missingRailcarSortValue
}
