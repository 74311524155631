import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { EmptyIndicator, useMinimalsTheme } from '@tom-ui/ui'
import { useCallback, useMemo } from 'react'

interface Props {
  value?: number | null
  min?: number | null
  max?: number | null
  type?: string
}
export const CoolingOrderRange = ({ value, min, max, type }: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const isNullOrEmpty = useCallback(
    (field?: number | null) => field === undefined || field === null,
    [],
  )
  const isMinAndMaxValid = useCallback(
    () => !isNullOrEmpty(min) && !isNullOrEmpty(max),
    [min, max, isNullOrEmpty],
  )

  const getFieldWithType = useCallback(
    (field?: number | null) => (type ? `${field}${type}` : field),
    [type],
  )

  const valueField = useMemo(() => {
    if (isNullOrEmpty(value)) {
      if (isMinAndMaxValid()) return ''
      else return EmptyIndicator
    }

    return getFieldWithType(value)
  }, [isMinAndMaxValid, isNullOrEmpty, getFieldWithType, value])

  return (
    <Stack flexDirection='row' gap={theme.customSpacing.xxs}>
      <Typography variant='body2' color='secondary'>
        {valueField}
      </Typography>
      {isMinAndMaxValid() && (
        <Typography variant='body2' color='secondary'>
          (
          {t('fromTo', 'From {min} to {max}', {
            min: getFieldWithType(min),
            max: getFieldWithType(max),
          })}
          )
        </Typography>
      )}
    </Stack>
  )
}
