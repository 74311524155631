import { Box, Stack, TextField, Typography } from '@mui/material'
import { CarrierVisitDirection, OrderResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { InitializationWrapper } from '@planning/components'
import { OrderListItem } from '@planning/components/organisms/OrderListItem'
import { useOrderNavigation } from '@planning/hooks/useOrderNavigation'
import { orderService } from '@planning/services'
import {
  filterOutboundOrdersByVisitId,
  sortOrdersByContainerNumber,
} from '@planning/utils/order-utils'
import { useTranslate } from '@tolgee/react'
import { Breadcrumbs, Header, NoListResult, useMinimalsTheme } from '@tom-ui/ui'
import { useAsyncFetch } from '@tom-ui/utils'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'

export const OrdersList: FC = observer(() => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const { appViewStore, orderItemStore } = usePlanningStore()

  const { navigateBookingOrderPage } = useOrderNavigation()
  const navigate = useNavigate()

  const { bookingNumber, visitId } = useParams()

  const [filter, setFilter] = useState('')
  const [filteredOrders, setFilteredOrders] = useState<OrderResponseDto[]>([])

  const { loading, error } = useAsyncFetch(async () => {
    if (bookingNumber)
      await orderItemStore.fetchByReferenceNumber(bookingNumber, CarrierVisitDirection.Outbound)
  }, [orderItemStore, bookingNumber])

  const orders = computed(() => {
    if (bookingNumber && visitId) {
      const orderItems = orderItemStore.ordersByReferenceNumber[bookingNumber]
      const orders = orderItems?.map(o => o.data)

      if (!orders || orders.length === 0) {
        return []
      }

      const filteredOrders = filterOutboundOrdersByVisitId(orders, Number(visitId))
      const sortedOrders = sortOrdersByContainerNumber(filteredOrders)

      return sortedOrders
    } else {
      return []
    }
  }).get()

  if (error) {
    return <Typography variant='h4'>{t('errorLoadingOrders', 'Error loading orders')}</Typography>
  }

  if (!loading && (!orders || orders.length === 0)) {
    navigate('/bookings')
  }

  const onDeleteOrder = async (order: OrderResponseDto) => {
    const hasServiceOrders = order.hasServiceOrders

    const isConfirmed = await appViewStore.setOpenConfirmDialog(
      true,
      t('orderWillBeDeletedContinue', 'Order will be deleted. Continue?'),
      t('confirmOrderDelete', 'Confirm order delete'),
      hasServiceOrders
        ? t(
            'anyAssociatedStrippingOrStuffingOrdersWillBeDeleted',
            'Any associated stripping/stuffing orders will be deleted',
          )
        : undefined,
    )

    if (!isConfirmed) return

    try {
      await orderService.deleteByIds([order.id])

      appViewStore.setShowAlert(
        'success',
        t('successfullyDeletedOrder', 'Successfully deleted order'),
      )
    } catch (e) {
      appViewStore.setShowAlert('error', t('failedToDeleteOrder', 'Failed to delete order'))
    }
  }

  useEffect(() => {
    const filteredOrders = orders?.filter(order => order.containerNumber?.includes(filter))
    setFilteredOrders(filteredOrders)
  }, [filter, orders])

  return (
    <InitializationWrapper isInitializing={loading}>
      <Box sx={{ backgroundColor: theme.palette.grey[200], minHeight: '100%' }}>
        <Breadcrumbs
          items={[
            {
              label: t('bookings', 'Bookings'),
              onClick: () => navigate('/bookings'),
            },
            {
              label: `${bookingNumber}`,
            },
          ]}
        />
        <Header
          title={t('containersList', 'Containers List')}
          rightRenderOption={
            <Stack flexDirection='row' alignItems='center' gap={2}>
              <Box width='300px'>
                <TextField
                  label={t('searchByContainerNumber', 'Search by container number')}
                  data-cy='order-list-page-search-field'
                  sx={{ width: '100%', marginTop: 0 }}
                  value={filter}
                  onChange={event => {
                    setFilter(event.currentTarget.value.toLocaleUpperCase())
                  }}
                />
              </Box>
            </Stack>
          }
        />
        {!!filteredOrders?.length && (
          <Box
            m={theme.customSpacing.l}
            bgcolor={'white'}
            border={`1px solid ${theme.palette.grey[300]}`}
          >
            {filteredOrders.map(order => {
              return (
                <OrderListItem
                  key={order.id}
                  order={order}
                  filter={filter}
                  options={[
                    {
                      label: 'Order Details',
                      onClick: () => navigateBookingOrderPage(order),
                    },
                    {
                      label: 'Delete',
                      color: 'error',
                      onClick: () => onDeleteOrder(order),
                    },
                  ]}
                />
              )
            })}
          </Box>
        )}

        {(!filteredOrders || filteredOrders.length === 0) && (
          <NoListResult result='No orders found' />
        )}
      </Box>
    </InitializationWrapper>
  )
})
