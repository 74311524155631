import * as yup from 'yup'
import { CustomerFormProfile } from './customer-form.profile'

export const schema = (): yup.ObjectSchema<CustomerFormProfile> =>
  yup.object({
    id: yup.number().optional(),
    name: yup.string().label('Name').emptyAsUndefined().required(),
    abbreviation: yup.string().label('Abbreviation').emptyAsUndefined().required(),
    address: yup.string().label('Address').emptyAsUndefined(),
  })
