import { LinearProgress, Stack, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'

interface TextBoxProps {
  text: string
  isLoading?: boolean
}

export const TextBox = ({ text, isLoading }: TextBoxProps) => {
  const theme = useMinimalsTheme()
  return (
    <Stack
      height={theme.customSpacing.xxl}
      justifyContent={isLoading ? 'flex-start' : 'center'}
      gap={isLoading ? 1 : 0}
    >
      {isLoading && <LinearProgress color='info' />}
      <Typography variant='body1' textAlign='center'>
        {text}
      </Typography>
    </Stack>
  )
}
