import {
  CarrierVisitStatus,
  CoolingOrderDtoV2,
  OrderStatus,
  ReeferTemperatureDto,
  WorkInstructionStatus,
} from '@operations/app/api'
import { ReeferTemperatureStoreV2 } from '@operations/stores/ReeferTemperatureStoreV2'
import { TenantConfigStore } from '@operations/stores/TenantConfigStore'
import dayjs from 'dayjs'
import _ from 'lodash'
import { action, autorun, computed, makeObservable, observable, runInAction } from 'mobx'
import { PlugInStatus } from '../components/filter/reefer-monitoring-filter.model'
import { CoolingOrderAlerts, CoolingOrderCardDto } from '../models/cooling-order-model'
import { ReeferMonitoringFiltersUIStoreV2 } from './reefer-monitoring-filters.ui-storeV2'

export interface CoolingOrderTimerInfo {
  id: number
  nextCheckDateTime: Date | null
  isTemperatureCheckRequired: boolean

  lastPlugInTime: Date | null
  lastPlugOutTime: Date | null
  reachMaxUnpluggedDateTime: Date | null
  isMaxUnpluggedTimeReached: boolean
}

export class ReeferMonitoringUIStoreV2 {
  coolingOrderTimerInfoList: CoolingOrderTimerInfo[] = []
  intervalId: NodeJS.Timeout | null = null

  historicalCoolingOrders: CoolingOrderDtoV2[] = []

  constructor(
    private reeferTemperatureStore: ReeferTemperatureStoreV2,
    private filtersUIStore: ReeferMonitoringFiltersUIStoreV2,
    private tenantConfigStore: TenantConfigStore,
  ) {
    makeObservable(this, {
      coolingOrderTimerInfoList: observable,
      historicalCoolingOrders: observable,

      getCoolingOrdersHistoryByContainer: action,

      mappedItems: computed,
      filteredMappedItems: computed,
      mappedHistoricalItems: computed,
      items: computed,
    })

    autorun(() => this.createCoolingOrderTimerInfoList())
  }

  public createCoolingOrderTimerInfoList() {
    const maxUnpluggedTime = this.tenantConfigStore.tenantConfig?.reeferContainerMaxUnpluggedTime

    const list = this.reeferTemperatureStore.items
      .filter(x => x.isMonitoringRequired)
      .map<CoolingOrderTimerInfo>(item => {
        const orderedReeferTemperatures = _.orderBy(item.reeferTemperatures, ['recordedAt', 'id'])
        const lastRecord = _.last(orderedReeferTemperatures)

        const nextCheckDateTime =
          item.monitoringFrequency && lastRecord
            ? dayjs(lastRecord.recordedAt).add(item.monitoringFrequency!, 'hour').toDate()
            : null

        const isTemperatureCheckRequired =
          item.outboundWorkInstruction.carrierVisitStatus !== CarrierVisitStatus.InOperation &&
          (!nextCheckDateTime || nextCheckDateTime <= new Date())

        const lastPlugInTime = dayjs(
          _.last(orderedReeferTemperatures.filter(x => x.isPluggedIn))?.recordedAt,
        ).toDate()

        const lastPlugOutTime = dayjs(
          _.last(orderedReeferTemperatures.filter(x => !x.isPluggedIn))?.recordedAt,
        ).toDate()

        const reachMaxUnpluggedDateTime =
          !lastRecord?.isPluggedIn && lastPlugOutTime && maxUnpluggedTime
            ? dayjs(lastPlugOutTime).add(maxUnpluggedTime, 'hour').toDate()
            : null

        const isMaxUnpluggedTimeReached =
          reachMaxUnpluggedDateTime !== null && reachMaxUnpluggedDateTime <= new Date()

        return {
          id: item.id,
          nextCheckDateTime,
          isTemperatureCheckRequired,
          lastPlugInTime,
          lastPlugOutTime,
          reachMaxUnpluggedDateTime,
          isMaxUnpluggedTimeReached,
        }
      })

    runInAction(() => {
      this.coolingOrderTimerInfoList = list
    })
  }

  startTimer() {
    if (this.intervalId) return

    this.intervalId = setInterval(() => {
      //TemperatureCheck
      this.coolingOrderTimerInfoList
        .filter(
          x =>
            !x.isTemperatureCheckRequired &&
            (!x.nextCheckDateTime || x.nextCheckDateTime <= new Date()),
        )
        .forEach(timerInfo => {
          const coolingOrder = this.reeferTemperatureStore.items.find(x => x.id === timerInfo.id)

          if (
            coolingOrder?.isMonitoringRequired &&
            coolingOrder.outboundWorkInstruction.carrierVisitStatus !==
              CarrierVisitStatus.InOperation
          ) {
            runInAction(() => {
              timerInfo.isTemperatureCheckRequired = true
            })
          }
        })

      //MaxUnpluggedTimeReached
      this.coolingOrderTimerInfoList
        .filter(
          x =>
            !x.isMaxUnpluggedTimeReached &&
            x.reachMaxUnpluggedDateTime &&
            x.reachMaxUnpluggedDateTime <= new Date(),
        )
        .forEach(timerInfo => {
          runInAction(() => {
            timerInfo.isMaxUnpluggedTimeReached = true
          })
        })
    }, 60000)
  }

  stopTimer() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
      this.intervalId = null
    }
  }

  public async getCoolingOrdersHistoryByContainer(): Promise<void> {
    if (!this.filtersUIStore.containerNumber) {
      this.historicalCoolingOrders = []
      return
    }

    const data = await this.reeferTemperatureStore.getCoolingOrdersHistoryByContainer(
      this.filtersUIStore.containerNumber,
    )

    this.historicalCoolingOrders = data
  }

  public get mappedItems(): CoolingOrderCardDto[] {
    const list = this.reeferTemperatureStore.items.map(coolingOrder =>
      this.mapToCardDto(coolingOrder, false),
    )

    //Sort
    const sortListFunc = _.flow(
      list =>
        _.orderBy(
          list,
          [
            item => item.alerts.includes(CoolingOrderAlerts.PlugInRequired),
            item => item.alerts.includes(CoolingOrderAlerts.PlugOutRequired),
            item => item.alerts.includes(CoolingOrderAlerts.TemperatureCheck),
            'nextCheckDateTime',
          ],
          ['desc', 'desc', 'desc', 'asc'],
        ),
      items =>
        _.map(items, ({ reeferTemperatures, ...o }) => ({
          ...o,
          reeferTemperatures: _.orderBy(reeferTemperatures, ['recordedAt', 'id']),
        })),
    )

    return sortListFunc(list)
  }

  public get filteredMappedItems(): CoolingOrderCardDto[] {
    if (this.filtersUIStore.areFiltersAndSearchEmpty) {
      return this.mappedItems
    }

    if (this.filtersUIStore.containerNumber) {
      return this.mappedItems.filter(item =>
        this.doesItemHaveContainerNumber(item, this.filtersUIStore.containerNumber!),
      )
    }

    const filters = this.filtersUIStore.filter!

    return this.mappedItems.filter(
      item =>
        this.doesItemHaveYardBlock(item, filters.yardBlockIds) &&
        this.doesItemHaveCustomer(item, filters.customerIds) &&
        this.doesItemHaveInboundCarrierType(item, filters.inboundCarrierType) &&
        this.doesItemHaveOutboundCarrierType(item, filters.outboundCarrierType) &&
        this.doesItemHaveIsPluggedIn(item, filters.isPluggedIn) &&
        this.doesItemHaveAlert(item, filters.status as CoolingOrderAlerts[]),
    )
  }

  public get mappedHistoricalItems(): CoolingOrderCardDto[] {
    return this.historicalCoolingOrders.map(coolingOrder => this.mapToCardDto(coolingOrder, true))
  }

  public get items(): CoolingOrderCardDto[] {
    return [...this.filteredMappedItems, ...this.mappedHistoricalItems]
  }

  public mapToCardDto(coolingOrder: CoolingOrderDtoV2, isHistoricalItem: boolean) {
    const computedDto: CoolingOrderCardDto = { ...coolingOrder, alerts: [] }

    if (
      computedDto.status === OrderStatus.Open &&
      computedDto.outboundWorkInstruction.status === WorkInstructionStatus.Finished
    ) {
      computedDto.status = OrderStatus.Fulfilled
    }

    const lastRecord = _.last(_.orderBy(coolingOrder.reeferTemperatures, ['recordedAt', 'id']))

    if (lastRecord) {
      this.mapLastRecordAndNextCheckInfo(computedDto, lastRecord)
    }

    if (!isHistoricalItem) {
      computedDto.alerts = this.getAlerts(computedDto, lastRecord)
    }

    return computedDto
  }

  public mapLastRecordAndNextCheckInfo(dto: CoolingOrderCardDto, lastRecord: ReeferTemperatureDto) {
    dto.lastStatus = {
      isPluggedIn: lastRecord.isPluggedIn,
      temperature: lastRecord.temperature,
      dateTime: lastRecord.recordedAt,
    }

    //NextCheckDateTime
    const timerInfo = this.coolingOrderTimerInfoList.find(x => x.id === dto.id)
    dto.nextCheckDateTime = timerInfo?.nextCheckDateTime
    dto.lastPlugInDateTime = timerInfo?.lastPlugInTime
    dto.lastPlugOutDateTime = timerInfo?.lastPlugOutTime
  }

  public getAlerts(dto: CoolingOrderCardDto, lastRecord?: ReeferTemperatureDto) {
    const isOutboundReadyForOperations =
      dto.outboundWorkInstruction?.carrierVisitStatus === CarrierVisitStatus.InOperation

    const alerts = []

    //UnpluggedTimeExceeded
    if (this.coolingOrderTimerInfoList.find(x => x.id === dto.id)?.isMaxUnpluggedTimeReached) {
      alerts.push(CoolingOrderAlerts.UnpluggedTimeExceeded)
    }

    //PlugInRequired
    if (dto.isPlugInRequired && !lastRecord?.isPluggedIn && !isOutboundReadyForOperations) {
      alerts.push(CoolingOrderAlerts.PlugInRequired)
    }

    //PlugOutRequired
    if (lastRecord?.isPluggedIn && isOutboundReadyForOperations) {
      alerts.push(CoolingOrderAlerts.PlugOutRequired)
    }

    //TemperatureCheck
    if (this.coolingOrderTimerInfoList.find(x => x.id === dto.id)?.isTemperatureCheckRequired) {
      alerts.push(CoolingOrderAlerts.TemperatureCheck)
    }

    //HasError
    if (lastRecord?.additionalInfo) {
      alerts.push(CoolingOrderAlerts.HasError)
    }

    //TemperatureDeviation
    if (
      this.checkDeviation(
        lastRecord?.temperature,
        dto.requestedTemperature,
        dto.minRequestedTemperature,
        dto.maxRequestedTemperature,
      )
    ) {
      alerts.push(CoolingOrderAlerts.TemperatureDeviation)
    }

    //HumidityDeviation
    if (
      this.checkDeviation(
        lastRecord?.humidity,
        dto.idealHumidity,
        dto.minIdealHumidity,
        dto.maxIdealHumidity,
      )
    ) {
      alerts.push(CoolingOrderAlerts.HumidityDeviation)
    }

    //VentilationDeviation
    if (
      this.checkDeviation(
        lastRecord?.ventilation,
        dto.idealVentilation,
        dto.minIdealVentilation,
        dto.maxIdealVentilation,
      )
    ) {
      alerts.push(CoolingOrderAlerts.VentilationDeviation)
    }

    return alerts
  }

  public checkDeviation(
    lastRecordValue?: number | null,
    ideal?: number | null,
    min?: number | null,
    max?: number | null,
  ): boolean {
    if (!lastRecordValue) return false

    if (min && max) {
      return lastRecordValue < min || lastRecordValue > max
    } else if (ideal) {
      return lastRecordValue !== ideal
    }

    return false
  }

  private doesItemHaveContainerNumber(item: CoolingOrderCardDto, containerNumber: string) {
    return (
      item.container.containerNumber &&
      item.container.containerNumber.toLowerCase().includes(containerNumber.toLowerCase())
    )
  }

  private doesItemHaveYardBlock(item: CoolingOrderCardDto, selectedYardBlockIds?: string[] | null) {
    return (
      !selectedYardBlockIds?.length ||
      (item.container.yardBlock && selectedYardBlockIds.includes(item.container.yardBlock))
    )
  }

  private doesItemHaveCustomer(item: CoolingOrderCardDto, selectedCustomerIds?: string[] | null) {
    return (
      !selectedCustomerIds?.length || (item.customer && selectedCustomerIds.includes(item.customer))
    )
  }

  private doesItemHaveInboundCarrierType(
    item: CoolingOrderCardDto,
    carrierTypes?: string[] | null,
  ) {
    return (
      !carrierTypes?.length ||
      (item.inboundWorkInstruction.carrierType &&
        carrierTypes.includes(item.inboundWorkInstruction.carrierType))
    )
  }

  private doesItemHaveOutboundCarrierType(
    item: CoolingOrderCardDto,
    carrierTypes?: string[] | null,
  ) {
    return (
      !carrierTypes?.length ||
      (item.outboundWorkInstruction.carrierType &&
        carrierTypes.includes(item.outboundWorkInstruction.carrierType))
    )
  }

  private doesItemHaveIsPluggedIn(item: CoolingOrderCardDto, isPluggedIn?: PlugInStatus[] | null) {
    return (
      !isPluggedIn?.length ||
      (isPluggedIn.some(x => x === PlugInStatus.PluggedIn) && item.lastStatus?.isPluggedIn) ||
      (isPluggedIn.some(x => x === PlugInStatus.PluggedOut) && !item.lastStatus?.isPluggedIn)
    )
  }

  private doesItemHaveAlert(item: CoolingOrderCardDto, status?: CoolingOrderAlerts[] | null) {
    return (
      !status?.length ||
      (status.some(x => x === CoolingOrderAlerts.PlugInRequired) &&
        item.alerts.includes(CoolingOrderAlerts.PlugInRequired)) ||
      (status.some(x => x === CoolingOrderAlerts.PlugOutRequired) &&
        item.alerts.includes(CoolingOrderAlerts.PlugOutRequired)) ||
      (status.some(x => x === CoolingOrderAlerts.TemperatureCheck) &&
        item.alerts.includes(CoolingOrderAlerts.TemperatureCheck)) ||
      (status.some(x => x === CoolingOrderAlerts.HasError) &&
        item.alerts.includes(CoolingOrderAlerts.HasError)) ||
      (status.some(x => x === CoolingOrderAlerts.TemperatureDeviation) &&
        item.alerts.includes(CoolingOrderAlerts.TemperatureDeviation)) ||
      (status.some(x => x === CoolingOrderAlerts.HumidityDeviation) &&
        item.alerts.includes(CoolingOrderAlerts.HumidityDeviation)) ||
      (status.some(x => x === CoolingOrderAlerts.VentilationDeviation) &&
        item.alerts.includes(CoolingOrderAlerts.VentilationDeviation))
    )
  }
}
