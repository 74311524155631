import { Shuffle } from '@mui/icons-material'
import { Divider, Tooltip, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { UnallocatedTurnoversBreakDown } from '@storage/stores/carrier-visit.store'
import { useTranslate } from '@tolgee/react'
import { ChevronRightIcon, IconButton, useMinimalsTheme } from '@tom-ui/ui'

interface Props {
  label: string | JSX.Element
  total: number
  breakdownItem: UnallocatedTurnoversBreakDown
  onCreateNewRule: (breakdownItem: UnallocatedTurnoversBreakDown) => void
  onExpand?: (breakdownItem: UnallocatedTurnoversBreakDown) => void
}

export const UnAllocatedItem = ({
  label,
  total,
  breakdownItem,
  onCreateNewRule,
  onExpand,
}: Props) => {
  const { t } = useTranslate()
  const { palette } = useMinimalsTheme()

  return (
    <Box
      sx={{
        '&:hover': {
          backgroundColor: palette.grey[200],
        },
      }}
    >
      <Stack
        direction='row'
        alignItems='center'
        gap={1}
        mx={2}
        my={1}
        justifyContent='space-between'
      >
        <Stack direction='row' gap={1} alignItems='center' mx={2} my={1}>
          <Typography variant='body1'>{label}</Typography>
        </Stack>
        <Stack direction='row' gap={1} alignItems='center' mx={2} my={1}>
          <Typography variant='body1'>{total}</Typography>
          <Tooltip title={t('createNewRule', 'Create new rule')}>
            <IconButton
              onClick={() => onCreateNewRule(breakdownItem)}
              data-cy='yard-planning-btn-create-rule'
            >
              <Shuffle />
            </IconButton>
          </Tooltip>
          {!!onExpand && (
            <Tooltip title={t('seeMoreDetails', 'See more details')}>
              <IconButton onClick={() => onExpand(breakdownItem)}>
                <ChevronRightIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>
      <Divider />
    </Box>
  )
}
