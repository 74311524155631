import {
  ContainerHeight,
  ContainerType,
  CreateIsoCodeMappingCommand,
  IsoCodeMappingResponseDto,
  UpdateIsoCodeMappingCommand,
} from '@admin/app/api'
import { IsoCodeMappingStore } from '@admin/stores/IsoCodeMapping.store'
import { action, computed, makeObservable, observable } from 'mobx'

export interface IsoCodeMappingFormData {
  id?: number
  length?: number
  isoCode: string
  type: ContainerType
  height: ContainerHeight
}

export class IsoCodeMappingUIStore {
  filter = ''
  isFormDialogOpen = false
  isDeleteDialogOpen = false

  dataToBeEdited?: IsoCodeMappingFormData

  filteredStandardIsoCode?: IsoCodeMappingResponseDto

  constructor(public isoCodeMappingStore: IsoCodeMappingStore) {
    makeObservable(this, {
      filter: observable,
      dataToBeEdited: observable,
      isFormDialogOpen: observable,
      isDeleteDialogOpen: observable,
      filteredStandardIsoCode: observable,

      isoCodeMappings: computed,

      setFilter: action,
      toggleFormDialog: action,
      toggleDeleteDialog: action,
      setFilteredStandardIsoCode: action,
    })
  }

  get isoCodeMappings() {
    const filteredItems = this.isoCodeMappingStore.items.filter(item =>
      item.isoCode?.toLocaleUpperCase().includes(this.filter?.toLocaleUpperCase()),
    )

    if (this.filteredStandardIsoCode) {
      filteredItems.unshift(this.filteredStandardIsoCode)
    }

    return filteredItems
  }

  setFilter = (filter: string) => (this.filter = filter)

  setFilteredStandardIsoCode = (standardIsoCode?: IsoCodeMappingResponseDto) =>
    (this.filteredStandardIsoCode = standardIsoCode)

  toggleFormDialog = (shouldOpen: boolean, data?: IsoCodeMappingFormData) => {
    this.isFormDialogOpen = shouldOpen
    this.dataToBeEdited = data
  }

  toggleDeleteDialog = (shouldOpen: boolean) => (this.isDeleteDialogOpen = shouldOpen)

  resetFilteredStandardIsoCode = (isoCode: string) => {
    if (isoCode === this.filteredStandardIsoCode?.isoCode) {
      this.setFilteredStandardIsoCode()
    }
  }

  onDelete = async (id: number, isoCode: string) => {
    this.toggleDeleteDialog(false)
    await this.isoCodeMappingStore.delete(id)
    this.resetFilteredStandardIsoCode(isoCode)
  }

  onSubmit = async (data: IsoCodeMappingResponseDto) => {
    const isEditMode = !!data.id

    if (isEditMode) {
      const cmd: UpdateIsoCodeMappingCommand = {
        ...data,
      }
      await this.isoCodeMappingStore.update(cmd)
    } else {
      const cmd: CreateIsoCodeMappingCommand = {
        ...data,
      }
      await this.isoCodeMappingStore.add(cmd)
    }
    this.resetFilteredStandardIsoCode(data.isoCode)
  }
}
