import { Box, styled } from '@mui/system'
import { EquipmentType } from '@operations/app/api'
import { RTGIcon } from '@operations/assets/icons/equipmentTypes/IconRTG'
import { ColorSchema, CraneIcon, ReachStackerIcon, TruckIcon } from '@tom-ui/ui'

interface Props {
  type?: EquipmentType
  zoom?: number
  backgroundColorTransparent?: boolean
  color?: ColorSchema
  skipPadding?: boolean
}

const IconContainer = styled(Box)<{ transparent?: string; skippadding?: string }>(
  ({ theme, transparent, skippadding }) => ({
    backgroundColor: transparent ? 'transparent' : theme.palette.grey[200],
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: skippadding ? undefined : '8px',
  }),
)

export const EquipmentTypeIcon = ({
  type,
  zoom,
  backgroundColorTransparent,
  color,
  skipPadding,
}: Props) => {
  let icon = <CraneIcon color={color} sx={{ transform: `scale(${(zoom ?? 100) / 100})` }} />
  switch (type) {
    case EquipmentType.Rs:
    case EquipmentType.Ech:
      icon = <ReachStackerIcon color={color} sx={{ transform: `scale(${(zoom ?? 100) / 100})` }} />
      break
    case EquipmentType.Rmg:
    case EquipmentType.Rtg:
      icon = <RTGIcon color={color} sx={{ transform: `scale(${(zoom ?? 100) / 100})` }} />
      break
    case EquipmentType.Sts:
      icon = <CraneIcon color={color} sx={{ transform: `scale(${(zoom ?? 100) / 100})` }} />
      break
    case EquipmentType.Tt:
      icon = <TruckIcon color={color} sx={{ transform: `scale(${(zoom ?? 100) / 100})` }} />
      break
  }

  return (
    <IconContainer
      transparent={backgroundColorTransparent ? 'true' : undefined}
      skippadding={skipPadding ? 'true' : undefined}
    >
      {icon}
    </IconContainer>
  )
}
