import {
  CarrierType,
  CarrierVisitDirection,
  OrderResponseDto,
  OrdersApi,
  OrderStatus,
} from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetOrdersByReferenceNumberQuery implements IQueryMessage {
  static type = 'GetOrdersByReferenceNumberQuery'
  type = GetOrdersByReferenceNumberQuery.type

  constructor(
    public referenceNumber: string,
    public direction: CarrierVisitDirection,
    public carrierType?: CarrierType,
    public status?: OrderStatus,
  ) {}

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(OrdersApi)

export const getOrdersByReferenceNumberQueryHandler: IQueryHandler<
  GetOrdersByReferenceNumberQuery,
  IEvent<OrderResponseDto[]>
> = async query => {
  console.log(query)
  const { data } = await httpClient.getByReferenceNumber(
    query.referenceNumber,
    query.direction,
    query.carrierType,
    query.status,
  )

  return new Event(GetOrdersByReferenceNumberQuery.type, data)
}
