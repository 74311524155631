import { RailVisitResponseDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetRailVisitByIdQuery } from '@planning/messages/queries/getRailVisitByIdQueryHandler'
import { GetRailVisitsByIdsQuery } from '@planning/messages/queries/getRailVisitsByIdsQueryHandler'
import { GetRailVisitsQuery } from '@planning/messages/queries/getRailVisitsQueryHandler'
import _ from 'lodash'
import { action, computed, makeObservable } from 'mobx'
import { ItemStore } from '../base/ItemStore'
import { OrderItemStore } from '../order/OrderItemStore'
import { RailcarTrackPositionItemStore } from '../railTrack/RailcarTrackPositionItemStore'
import { RailTrackItemStore } from '../railTrack/RailTrackItemStore'
import { IEntityStore } from '../types'
import { IRailVisitItem, RailVisitItem } from './RailVisitItem'

interface IVisitConflicts {
  carrierVisitId: number
  isConflicted?: boolean
  isInboundRailcarLengthConflicted?: boolean
  isOutboundRailcarLengthConflicted?: boolean
  hasAssignedRailcarsWithoutLength?: boolean
}
export class RailVisitItemStore
  extends ItemStore<RailVisitResponseDto, IRailVisitItem>
  implements IEntityStore<IRailVisitItem>
{
  private orderItemStore?: OrderItemStore

  constructor(
    private messageBus: IMessageBus,
    railTrackStore: RailTrackItemStore,
    railcarTrackPositionStore: RailcarTrackPositionItemStore,
  ) {
    super(
      (key, data) => new RailVisitItem(key, data, this, railTrackStore, railcarTrackPositionStore),
      {
        messageBus,
        fetchFunc: (id: number) => new GetRailVisitByIdQuery(id),
        bulkFetchFunc: (ids: number[]) => new GetRailVisitsByIdsQuery(ids),
      },
    )
    makeObservable(this, {
      receiveRailVisitsMessage: action,
      receiveRailVisitUpsertMessage: action,
      receiveRailVisitDeletedMessage: action,
      ordersByRailVisitId: computed,
    })

    messageBus.subscribeEvent(GetRailVisitsQuery.type, this.receiveRailVisitsMessage)
    messageBus.subscribeEvent(GetRailVisitsByIdsQuery.type, this.receiveRailVisitsMessage)
    messageBus.subscribeEvent(GetRailVisitByIdQuery.type, this.receiveRailVisitUpsertMessage)
    messageBus.subscribeEvent(EventTypes.RailVisitCreated, this.receiveRailVisitUpsertMessage)
    messageBus.subscribeEvent(EventTypes.RailVisitUpdated, this.receiveRailVisitUpsertMessage)
    messageBus.subscribeEvent(EventTypes.CarrierVisitDeleted, this.receiveRailVisitDeletedMessage)
    messageBus.subscribeEvent(
      EventTypes.CarrierVisitConflictUpdated,
      this.receiveRailVisitConflictUpdatedMessage,
    )
  }

  connect = async (orderItemStore: OrderItemStore) => {
    this.orderItemStore = orderItemStore
  }

  get ordersByRailVisitId() {
    return this.orderItemStore?.ordersByCarrierVisitId
  }

  fetch = async (from: Date, to: Date) => {
    return this.messageBus.dispatchQuery(new GetRailVisitsQuery(from, to))
  }

  getById = (id: number) => {
    return _.get<IRailVisitItem | undefined>(this.elements, id)
  }

  receiveRailVisitsMessage = (event: IEvent<RailVisitResponseDto[]>): void => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }

    this.hasBeenInitialized = true
  }

  receiveRailVisitUpsertMessage = (event: IEvent<RailVisitResponseDto>): void => {
    console.log('receiveRailVisitUpsertMessage event', event)
    if (event.payload) {
      console.log('receiveRailVisitUpsertMessage', event.payload)
      this.upsert(event.payload)
    }
  }

  receiveRailVisitDeletedMessage = (event: IEvent<number>): void => {
    if (event.payload && _.has(this.elements, event.payload)) {
      this.delete(event.payload)
    }
  }

  receiveRailVisitConflictUpdatedMessage = (event: IEvent<IVisitConflicts>): void => {
    if (event.payload) {
      const visit = this.getById(event.payload.carrierVisitId)
      if (visit) {
        this.updadteVisitConflicts(event, visit)
      }
    }
  }

  private updadteVisitConflicts(event: IEvent<IVisitConflicts>, visit: IRailVisitItem) {
    if (event.payload.isConflicted !== null && event.payload.isConflicted !== undefined)
      visit.data.isConflicted = event.payload.isConflicted

    if (
      event.payload.isInboundRailcarLengthConflicted !== null &&
      event.payload.isInboundRailcarLengthConflicted !== undefined
    )
      visit.data.isInboundRailcarLengthConflicted = event.payload.isInboundRailcarLengthConflicted

    if (
      event.payload.isOutboundRailcarLengthConflicted !== null &&
      event.payload.isOutboundRailcarLengthConflicted !== undefined
    )
      visit.data.isOutboundRailcarLengthConflicted = event.payload.isOutboundRailcarLengthConflicted

    if (
      event.payload.hasAssignedRailcarsWithoutLength !== null &&
      event.payload.hasAssignedRailcarsWithoutLength !== undefined
    )
      visit.data.hasAssignedRailcarsWithoutLength = event.payload.hasAssignedRailcarsWithoutLength
  }
}
