export interface IContainerDamageFormData {
  damagesReported: IContainerDamage[]
}

export const getDefaultContainerDamage = (): IContainerDamage => ({
  description: '',
  images: [],
  containerDamageLocation: null,
  containerDamageTypes: [],
  size: '',
})

export interface IContainerDamage {
  description?: string
  containerDamageLocation?: number | null
  containerDamageTypes?: number[]
  size?: string
  images: File[]
}

export interface IContainerDamageOption {
  id: number
  name: string
  code?: string
}
