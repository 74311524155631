import { DragIndicator } from '@mui/icons-material'
import { Box, Stack } from '@mui/system'
import { useMinimalsTheme } from '@tom-ui/ui'
import React from 'react'

interface ListItemSelectionIndicatorProps {
  selected?: boolean
  dragHandleProps?: any
  showDragIndicator?: boolean
}

const ListItemSelectionIndicator: React.FC<ListItemSelectionIndicatorProps> = ({
  selected,
  dragHandleProps,
  showDragIndicator,
}) => {
  const theme = useMinimalsTheme()

  return (
    <Stack direction='row' {...dragHandleProps}>
      <Box
        sx={{
          width: '4px',
          flex: 1,
          bgcolor: selected ? theme.palette.info.main : 'transparent',
          transition: 'background-color 0.3s',
        }}
      />
      <Box
        sx={{
          pt: 1.5,
          width: !showDragIndicator ? theme.customSpacing.l : undefined,
        }}
      >
        {showDragIndicator && <DragIndicator fontSize='medium' />}
      </Box>
    </Stack>
  )
}

export default ListItemSelectionIndicator
