import {
  BerthResponseDto,
  CargoType,
  CarrierVisitDirection,
  OrderStatus,
  RestowResponseDto,
  VesselVisitDto,
} from '@planning/app/api'
import { CarrierVisitViewStatus } from '@planning/constants'
import _ from 'lodash'
import { computed, makeObservable } from 'mobx'
import { EntityItem } from '../base/EntityItem'
import { IBerthItem } from '../berth/BerthItem'
import { getCarrierVisitViewStatus } from '../helpers'
import { IOrderItem } from '../order/OrderItem'
import { RailVisitItem } from '../railVisit/RailVisitItem'
import { RailVisitItemStore } from '../railVisit/RailVisitItemStore'
import { RestowItemStore } from '../restow/RestowItemStore'
import { IEntity, IEntityStore } from '../types'
import { IVesselItem } from '../vessel/VesselItem'
import { VesselVisitItemStore } from './VesselVisitItemStore'

export const calculateContainerOrderCounts = (orderItems: IOrderItem[]): IOrderCounts => {
  if (!orderItems.length)
    return {
      confirmed: 0,
      pending: 0,
      total: 0,
    }

  const orders = orderItems.map(o => o.data).filter(order => !order.commodityId)

  const total = orders.length
  const pending = orders.filter(order => order.status === OrderStatus.Open).length
  const confirmed = total - pending

  return {
    confirmed,
    pending,
    total,
  }
}

export const calculateGeneralCargoOrderCounts = (orderItems: IOrderItem[]): IOrderCounts => {
  if (!orderItems.length)
    return {
      confirmed: 0,
      pending: 0,
      total: 0,
    }

  const orders = orderItems.map(o => o.data).filter(order => order.commodityId)
  const total = orders.reduce((acc, order) => acc + (order.plannedCargoAmount || 0), 0)
  const pending = orders
    .filter(order => order.status === OrderStatus.Open)
    .reduce((acc, order) => acc + (order.plannedCargoAmount || 0), 0)
  const confirmed = total - pending

  return {
    confirmed,
    pending,
    total,
  }
}

export interface IOrderCounts {
  confirmed: number
  pending: number
  total: number
}

export interface IOrderCountsByCargoType {
  generalCargo: IOrderCounts
  container: IOrderCounts
  estimated: number
}

export interface IDirectionData {
  orders: IOrderItem[]
  containerOrders: IOrderItem[]
  generalCargoOrders: IOrderItem[]
  orderCounts: IOrderCountsByCargoType
  getCount: (countType: keyof IOrderCounts, cargoType: CargoType) => number
}

export class DirectionData implements IDirectionData {
  constructor(
    private direction: CarrierVisitDirection,
    private item: VesselVisitItem | RailVisitItem,
    private itemStore: VesselVisitItemStore | RailVisitItemStore,
  ) {
    makeObservable(this, {
      orders: computed,
      containerOrders: computed,
      generalCargoOrders: computed,
      orderCounts: computed,
    })
  }

  get orders() {
    return _.filter(this.item.orders, order => order.data.direction === this.direction)
  }

  get containerOrders() {
    return _.filter(this.orders, order => !!order.data.containerId)
  }

  get generalCargoOrders() {
    return _.filter(this.orders, order => !order.data.containerId && !!order.data.commodityId)
  }

  get orderCounts() {
    return {
      container: calculateContainerOrderCounts(this.orders),
      generalCargo: calculateGeneralCargoOrderCounts(this.orders),
      // [OCTA-948] TODO: Do we need estimates for GC?
      estimated:
        this.direction === CarrierVisitDirection.Inbound
          ? this.item.data.dischargeEstimate ?? 0
          : this.item.data.loadEstimate ?? 0,
    } as IOrderCountsByCargoType
  }

  getCount = (countType: keyof IOrderCounts, cargoType: CargoType) => {
    if (!this.orderCounts) return 0

    const cargoKey = cargoType === CargoType.GeneralCargo ? 'generalCargo' : 'container'

    if (!this.orderCounts[cargoKey]) return 0

    return this.orderCounts[cargoKey][countType]
  }
}

export interface IVesselVisitItem extends IEntity<VesselVisitDto> {
  vessels: IVesselItem[]
  orders: IOrderItem[]
  restows: RestowResponseDto[]
  berths: BerthResponseDto[]
  arrival: string | null | undefined
  departure: string | null | undefined
  load: IDirectionData
  discharge: IDirectionData
  getCount: (
    countType: keyof IOrderCounts,
    direction: CarrierVisitDirection,
    cargoType: CargoType,
  ) => number
  status: CarrierVisitViewStatus
  hasOpenOrders: boolean
  inboundOpenOrders: IOrderItem[]
  outboundOpenOrders: IOrderItem[]
}

export class VesselVisitItem extends EntityItem<VesselVisitDto> implements IVesselVisitItem {
  public readonly load: DirectionData
  public readonly discharge: DirectionData

  constructor(
    id: number,
    data: VesselVisitDto,
    private itemStore: VesselVisitItemStore,
    private vesselStore?: IEntityStore<IVesselItem>,
    private berthStore?: IEntityStore<IBerthItem>,
    private restowStore?: RestowItemStore,
  ) {
    super(id, data)
    makeObservable(this, {
      vessels: computed,
      orders: computed,
      berths: computed,
      arrival: computed,
      departure: computed,
      status: computed,
      hasOpenOrders: computed,
      inboundOpenOrders: computed,
      outboundOpenOrders: computed,
    })

    this.load = new DirectionData(CarrierVisitDirection.Outbound, this, this.itemStore)
    this.discharge = new DirectionData(CarrierVisitDirection.Inbound, this, this.itemStore)
  }

  get vessels() {
    const data: IVesselItem[] = []
    this.data.carrierIds.map(i => {
      const ele = this.vesselStore?.elements[i]
      if (ele) data.push(ele)
    })
    return data
  }

  get orders() {
    return _.get(this.itemStore.ordersByVesselVisitId, this.id) ?? []
  }

  get restows() {
    return this.restowStore?.unreadRestows.filter(r => r.vesselVisitId === this.id) ?? []
  }

  get berths() {
    return _(this.data.berthIds ?? [])
      .map(berthId => this.berthStore?.elements[berthId])
      .compact()
      .map(item => item.data)
      .value()
  }

  get arrival() {
    return this.data.ata ?? this.data.eta
  }

  get departure() {
    return this.data.atd ?? this.data.etd
  }

  get status() {
    return getCarrierVisitViewStatus(this.data.status, this.data.atb)
  }

  get hasOpenOrders() {
    return this.orders.some(o => o.data.status === OrderStatus.Open)
  }

  get inboundOpenOrders() {
    return this.orders.filter(
      o => o.data.status === OrderStatus.Open && o.data.direction === CarrierVisitDirection.Inbound,
    )
  }

  get outboundOpenOrders() {
    return this.orders.filter(
      o =>
        o.data.status === OrderStatus.Open && o.data.direction === CarrierVisitDirection.Outbound,
    )
  }

  getCount = (
    countType: keyof IOrderCounts,
    direction: CarrierVisitDirection,
    cargoType: CargoType,
  ) => {
    return direction === CarrierVisitDirection.Inbound
      ? this.discharge.getCount(countType, cargoType)
      : this.load.getCount(countType, cargoType)
  }
}
