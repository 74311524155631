import { Box, Typography } from '@mui/material'
import { RestowDto } from '@planning/app/api'
import { emptyIndicator } from '@planning/constants'
import { GeneralIcon, useMinimalsTheme } from '@tom-ui/ui'
import { FC } from 'react'

interface IProps {
  restow: RestowDto
}

export const RestowListCard: FC<IProps> = ({ restow }) => {
  const theme = useMinimalsTheme()

  const renderContainerAttr = (label: string) => (
    <span
      style={{
        fontSize: '.875rem',
        color: theme.palette.grey[600],
        marginRight: '.5rem',
      }}
    >
      {label ? label : emptyIndicator}
    </span>
  )

  if (!restow) return <></>

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.customRadius.medium,
        m: '1rem 0',
      }}
    >
      {/* Card Header */}
      <Box
        sx={{
          padding: '1rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Box sx={{ display: 'flex' }}>
            <GeneralIcon />
            <Typography variant='h4' ml={theme.customSpacing.xs}>
              {restow.containerNumber}
            </Typography>
          </Box>

          {renderContainerAttr(
            `Bay:${restow.destination.bay} - Row: ${restow.destination.row} - Tier: ${restow.destination.tier}`,
          )}
        </Box>
      </Box>
    </Box>
  )
}
