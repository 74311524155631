import { Alert, Box } from '@mui/material'
import { FormProps } from '@storage/app/models'
import { ControlledSelect, ControlledTextField } from '@storage/components/form-controls'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import { useTranslate } from '@tolgee/react'
import { useEffect } from 'react'
import { schema } from './general-cargo-area-form.scheme'

import { GeneralCargoAreaDto } from '@storage/app/api'
import { useGeneralCargoAreaTypeOptions } from '../../hooks/use-general-cargo-area-type-options.hook'
import GCALocationsFormAttribute from './GCALocationsFormAttribute'
import {
  GeneralCargoAreaFormProfile,
  defaultGCLocationValue,
  defaultValues,
} from './general-cargo-area-form.profile'
import { mapGeneralCargoAreaDtoToFormValues } from './general-cargo-area.mapper'
interface Props extends FormProps {
  generalCargoArea?: GeneralCargoAreaDto
  onSubmit: (formValues: GeneralCargoAreaFormProfile) => Promise<void> | void
}

const GeneralCargoAreaForm = ({ id, onSubmit, generalCargoArea }: Props) => {
  const { handleSubmit, control, formState, reset } =
    useFormWithSchema<GeneralCargoAreaFormProfile>(schema, defaultValues)

  const areaTypeOptions = useGeneralCargoAreaTypeOptions()

  const { t } = useTranslate()

  useEffect(() => {
    if (generalCargoArea) reset(mapGeneralCargoAreaDtoToFormValues(generalCargoArea))
  }, [reset, generalCargoArea])

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
      my={1}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <ControlledTextField
          formState={formState}
          control={control}
          name='name'
          label={t('name', 'Name')}
          required
        />
        <ControlledSelect
          formState={formState}
          control={control}
          label={t('type', 'Type')}
          options={areaTypeOptions}
          name='type'
        />
        <GCALocationsFormAttribute
          formState={formState}
          control={control}
          defaultValue={defaultGCLocationValue}
        />
        {formState.errors.locations?.root && (
          <Alert sx={{ boxShadow: 'none' }} severity='warning'>
            {formState.errors.locations?.root?.message}
          </Alert>
        )}
      </Box>
    </Box>
  )
}

export default GeneralCargoAreaForm
