import { Hvac, PlaylistAddCheckCircle, Thermostat } from '@mui/icons-material'
import { DefaultParamType, TFnType, TranslationKey } from '@tolgee/react'
import {
  HumidityPercentageIcon,
  MotionSensorUrgentIcon,
  PowerOnIcon,
  PowerOutIcon,
  PowerTimeIcon,
} from '@tom-ui/ui'
import { CoolingOrderAlerts } from '../../models/cooling-order-model'

export function AlertNameIcon(
  alerts: CoolingOrderAlerts[],
  t: TFnType<DefaultParamType, string, TranslationKey>,
) {
  return alerts.map(alert => {
    switch (alert) {
      case CoolingOrderAlerts.PlugInRequired:
        return {
          label: t('plugInRequired', 'Plug in required'),
          icon: <PowerOnIcon fontSize='small' />,
        }
      case CoolingOrderAlerts.PlugOutRequired:
        return {
          label: t('plugOutRequired', 'Plug out required'),
          icon: <PowerOutIcon fontSize='small' />,
        }
      case CoolingOrderAlerts.TemperatureCheck:
        return {
          label: t('monitoringRequired', 'Monitoring required'),
          icon: <PlaylistAddCheckCircle fontSize='small' />,
        }
      case CoolingOrderAlerts.HasError:
        return {
          label: t('hasError', 'Has error'),
          icon: <MotionSensorUrgentIcon fontSize='small' />,
        }
      case CoolingOrderAlerts.TemperatureDeviation:
        return {
          label: t('temperatureDeviation', 'Temperature deviation'),
          icon: <Thermostat fontSize='small' />,
        }
      case CoolingOrderAlerts.HumidityDeviation:
        return {
          label: t('humidityDeviation', 'Humidity deviation'),
          icon: <HumidityPercentageIcon fontSize='small' />,
        }
      case CoolingOrderAlerts.VentilationDeviation:
        return {
          label: t('ventilationDeviation', 'Ventilation deviation'),
          icon: <Hvac fontSize='small' />,
        }
      case CoolingOrderAlerts.UnpluggedTimeExceeded:
        return {
          label: t('unpluggedTimeExceeded', 'Unplugged time exceeded'),
          icon: <PowerTimeIcon fontSize='small' />,
        }
      default:
        return {
          label: '',
          icon: <></>,
        }
    }
  })
}
