import { Box, Stack, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { StackSlotDetails } from '../stores/stack-details-tooltip-container.ui-store'
import { multipleStackingIndicator } from '@storage/utils/icons'
import useImage from 'use-image'
import { useMemo } from 'react'

interface StackSlotDetailItemProps {
  slotDetails: StackSlotDetails
}

const StackSlotDetailItem = ({ slotDetails }: StackSlotDetailItemProps) => {
  const { tier, containerNumber, containerInfo, nbrOfOccupancies } = slotDetails
  const { palette, customSpacing, customRadius } = useMinimalsTheme()

  const stackingIndicator = useMemo(() => {
    return nbrOfOccupancies && nbrOfOccupancies > 1
      ? multipleStackingIndicator(nbrOfOccupancies)
      : null
  }, [nbrOfOccupancies])

  const [multipleStackingImage] = useImage(stackingIndicator || '')

  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      sx={{
        height: 48,
        backgroundColor: slotDetails.isOnTerminal ? palette.info.main : 'transparent',
        paddingX: customSpacing.xs,
        borderEndStartRadius: slotDetails.tier === 1 ? customRadius.medium : undefined,
        borderEndEndRadius: slotDetails.tier === 1 ? customRadius.medium : undefined,
      }}
    >
      <Stack>
        <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
          {`${tier}  ${containerNumber || ''}`}
        </Typography>
        <Typography variant='body2' sx={{ ml: 2 }}>
          {containerInfo || ''}
        </Typography>
      </Stack>
      {stackingIndicator && multipleStackingImage && (
        <Box
          component='img'
          src={multipleStackingImage.src}
          sx={{
            filter: 'invert(1)',
          }}
        />
      )}
    </Stack>
  )
}

export default StackSlotDetailItem
