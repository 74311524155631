import { AppStore } from '@tom-ui/utils'
import { AxiosResponse } from 'axios'

export const onFulfilled = (appStore: AppStore) => {
  return (response: AxiosResponse<any>) => {
    appStore.decreaseLoadingCounter()

    return response
  }
}

export const onRejected = (appStore: AppStore) => {
  return (error: any) => {
    appStore.decreaseLoadingCounter()

    if (error?.response?.status !== 500) {
      let message = ''

      if (error?.response?.data?.errors) {
        Object.values(error.response.data.errors).forEach((error: any) => {
          message += error + '\n'
        })
      }

      if (!message) {
        message = error?.response?.data?.detail ?? JSON.stringify(error?.message)
      }

      appStore.setShowAlert('error', message)
    } else if (error?.response?.status === 500) {
      appStore.setShowAlert('error', 'Something went wrong')
    }

    return Promise.reject(error)
  }
}
