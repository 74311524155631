import { Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useOperationsStore } from '@tom-ui/operations'
import { ColoredLabel } from '@tom-ui/ui'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { CraneQueueDto, OperationType, WorkQueueItem } from 'modules/operations/src/app/api'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

interface Props {
  currentDate: Date
}
export const CraneQueueStats = observer(({ currentDate }: Props) => {
  const { equipmentStore } = useOperationsStore()

  const [stats, setStats] = useState<CraneQueueDto[]>([])
  const { t } = useTranslate()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      const data = await equipmentStore.loadCraneQueueStats(currentDate)
      setStats(data)
    }
    fetchData()
  }, [equipmentStore, currentDate])

  const queueItem = (containerNumber: string, blockName: string | null | undefined) => {
    if (blockName) {
      return `${containerNumber} to ${blockName}`
    }
    return `${containerNumber}`
  }

  const opTypeText = (opType: OperationType) => {
    switch (opType) {
      case OperationType.Inbound:
        return t('discharge', 'Discharge')
      case 'Outbound':
        return t('load', 'Load')
    }
  }

  const yardBlocksAssignments = (items: WorkQueueItem[]) =>
    _(items)
      .unionBy(x => x.blockName)
      .value()
      .map(x => x.blockName)
      .filter(x => x)
      .join(', ')

  const restOfItems = (items: WorkQueueItem[]) => {
    if (items.length === 0) return <></>
    let result = `${items.length} more`
    const yardAssignedEquipments = yardBlocksAssignments(items)
    if (yardAssignedEquipments) result += ` to ${yardAssignedEquipments}`
    return result
  }

  const amountOfCOntainersToShow = 5

  return (
    <Stack gap={2} flexDirection='row' flexWrap='wrap'>
      {stats.length > 0 ? (
        stats.map((stat, index) => (
          <Card key={`${stat.craneName}_${index}`}>
            <CardHeader
              title={
                <Stack gap={2}>
                  <Stack
                    gap={2}
                    flexDirection={'row'}
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Typography variant='h3'>{stat.craneName}</Typography>

                    <ColoredLabel label={opTypeText(stat.operationType) ?? ''} color='info' />
                  </Stack>

                  <Typography variant='subtitle2' color='textPrimary'>
                    {stat.vesselName}, Bay {stat.bay} {stat.vesselArea}
                  </Typography>
                </Stack>
              }
              sx={{ height: '5rem' }}
            />
            <CardContent sx={{ height: 'calc(100% - 5rem)' }}>
              <Stack gap={1} flexDirection={'column'} height='100%'>
                {stat.items.slice(0, amountOfCOntainersToShow).map((item, i) => (
                  <Typography
                    key={`${item.containerNumber}_${index}`}
                    variant='body1'
                    color='textSecondary'
                  >
                    {queueItem(item.containerNumber, item.blockName)}
                  </Typography>
                ))}
                {restOfItems(stat.items.slice(amountOfCOntainersToShow))}
                {stat.nextQueue && (
                  <Typography variant='subtitle2' color='textPrimary'>
                    {t('next', 'Next')}: {t('bay', 'Bay')} {stat.nextQueue.bay}{' '}
                    {stat.nextQueue.vesselArea} in {stat.secondsToTheEnd / 60}{' '}
                    {t('minutes', 'minute')}
                  </Typography>
                )}

                <Stack alignItems='center' height='100%' justifyContent='end'>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => navigate(`/EquipmentPlanning/${stat.visitId}/craneSplit`)}
                  >
                    {t('manage', 'Manage')}
                  </Button>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography variant='subtitle1'>
          {t('noWorkQueueInProgress', 'No work queue in progress')}
        </Typography>
      )}
    </Stack>
  )
})
