import { AccordionSummary, Chip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'

interface UnAllocatedContainersHeaderProps {
  numberOfUnallocatedContainers: number
  isLoading: boolean
}

const UnAllocatedContainersHeader = ({
  numberOfUnallocatedContainers,
  isLoading,
}: UnAllocatedContainersHeaderProps) => {
  const { palette } = useMinimalsTheme()
  const { t } = useTranslate()
  return (
    <AccordionSummary
      sx={{
        backgroundColor: palette.grey[200],
        pointerEvents: 'none',
        borderBottom: 1,
        borderColor: 'divider',
      }}
    >
      <Stack direction='row' alignItems='center' gap={1}>
        <Typography variant='subtitle1'>
          {t('unAllocatedContainers', 'Unallocated containers')}
        </Typography>
        {!isLoading && <Chip size='small' label={numberOfUnallocatedContainers} />}
      </Stack>
    </AccordionSummary>
  )
}

export default UnAllocatedContainersHeader
