import {
  CarrierVisitDirection,
  ContainerTurnoverBasicInfoDto,
  ContainerTurnoverDto,
  ContainerTurnoverDtoPaginatedList,
  ContainerTurnoversApi,
  ContainerTurnoversApiGetAllBasicInfoRequest,
  ContainerTurnoversApiGetAllRequest,
  ContainerTurnoversApiGetContainerPositionByNumberRequest,
  ContainerTurnoversApiGetDetailsRequest,
  ContainerYardOperationDto,
  DeviceOperationMode,
} from '@storage/app/api'

export class ContainerTurnoverService {
  constructor(private api: ContainerTurnoversApi) {}

  async getAll(
    containerTurnoversApiGetAllRequest: ContainerTurnoversApiGetAllRequest,
  ): Promise<ContainerTurnoverDtoPaginatedList> {
    const { data: containerTurnovers } = await this.api.getAll(containerTurnoversApiGetAllRequest)

    return containerTurnovers
  }

  async getTurnover(containerTurnoverGetDetailsRequest: ContainerTurnoversApiGetDetailsRequest) {
    const { data: containerTurnover } = await this.api.getDetails(
      containerTurnoverGetDetailsRequest,
    )

    return containerTurnover
  }

  async getContainerPositionByNumber(
    containerTurnoverGetDetailsRequest: ContainerTurnoversApiGetContainerPositionByNumberRequest,
  ) {
    const { data: containers } = await this.api.getContainerPositionByNumber(
      containerTurnoverGetDetailsRequest,
    )

    return containers
  }

  async getAllBasicInfo(
    containerTurnoversApiGetAllBasicInfoRequest: ContainerTurnoversApiGetAllBasicInfoRequest,
  ): Promise<ContainerTurnoverBasicInfoDto[]> {
    const { data: containerTurnovers } = await this.api.getAllBasicInfo(
      containerTurnoversApiGetAllBasicInfoRequest,
    )

    return containerTurnovers
  }

  async getCarrierVisitContainerTurnovers(
    carrierVisitId: number,
    direction: CarrierVisitDirection,
  ) {
    const { data: containerTurnovers } = await this.api.getContainerTurnoversByCarrierVisit({
      carrierVisitId,
      direction,
    })

    return containerTurnovers
  }

  async getYardOperationData(
    containerNumber?: string,
    orderId?: number,
    isStackIn?: boolean,
    mode?: DeviceOperationMode,
  ): Promise<ContainerYardOperationDto> {
    const { data: yardOperationData } = await this.api.getYardOperationData({
      containerNumber,
      orderId,
      isStackIn,
      mode,
    })
    return yardOperationData
  }

  async getTurnoversByCarrierDirection(
    direction: CarrierVisitDirection,
  ): Promise<ContainerTurnoverDto[]> {
    const { data: containerTurnovers } = await this.api.getTurnoversByCarrierDirection({
      direction,
    })
    return containerTurnovers
  }
}
