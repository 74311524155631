import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import {
  AllocationDestinationAndStackingStrategyDto,
  CarrierVisitAllocationRuleDto,
  CarrierVisitDirection,
} from '@storage/app/api'
import AllocationRuleTemplatesTableDialog from '@storage/features/carrier-visit-allocation-rules/components/AllocationRuleTemplatesTableDialog'
import CarrierVisitAllocationRuleListItemDialog from '@storage/features/carrier-visit-allocation-rules/components/CarrierVisitAllocationRuleListItemDialog'
import CarrierVisitAllocationRulesDialog from '@storage/features/carrier-visit-allocation-rules/components/CarrierVisitAllocationRulesDialog'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import CarrierVisitDetails from './components/CarrierVisitDetails'
import YardPlanningDashboardBlocks from './components/YardPlanningDashboardBlocks'
import YardPlanningDashboardDetailsSidePanel from './components/YardPlanningDashboardDetailsSidePanel'
import YardPlanningDashboardLegend from './components/YardPlanningDashboardLegendComponent'

type Params = {
  vesselVisitId: string
  handlingDirection: CarrierVisitDirection
  discriminator: string
}

const YardPlanningDashboardDetails = observer(() => {
  const { vesselVisitId, handlingDirection, discriminator } = useParams<Params>()
  const { breakpoints, palette } = useMinimalsTheme()

  const {
    carrierVisitAllocationRulesV2UIStore,
    unallocatedTurnoversStore,
    yardBlockStore,
    yardBlockBayStore,
    yardBlockRowStore,
    yardBlockStackStore,
    carrierVisitStore,
    weightClassContainerUIStore,
    yardBlockSlotStore,
    weightClassStore,
    carrierVisitAllocationRulesV2Store,
    customerStore,
    allocationRuleTemplatesV2UIStore,
  } = useStores()

  // Needed to be loaded once regardless of the handling direction
  useEffect(() => {
    Promise.all([
      carrierVisitStore.load(Number(vesselVisitId)),
      yardBlockStore.loadList(),
      yardBlockBayStore.loadList(),
      yardBlockRowStore.loadAll(),
      yardBlockStackStore.loadAll({}),
      yardBlockSlotStore.loadOccupiedSlots(),

      customerStore.loadCustomers(),
      weightClassStore.loadAll(),
    ])
    return () => {
      carrierVisitStore.resetData()
      yardBlockStore.resetData()
      yardBlockBayStore.resetData()
      yardBlockRowStore.resetData()
      yardBlockStackStore.resetData()
      yardBlockSlotStore.resetData()
      weightClassStore.resetData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!discriminator || !handlingDirection) return

    unallocatedTurnoversStore.loadTurnovers(handlingDirection, Number(vesselVisitId))

    return () => {
      carrierVisitAllocationRulesV2Store.resetData()
      unallocatedTurnoversStore.resetTurnovers()
      carrierVisitAllocationRulesV2UIStore.setShowDestinationForm(false)
      carrierVisitAllocationRulesV2UIStore.setShowAllocationRuleForm(false)
    }
  }, [
    carrierVisitAllocationRulesV2Store,
    carrierVisitStore,
    unallocatedTurnoversStore,
    handlingDirection,
    vesselVisitId,
    weightClassStore,
    yardBlockBayStore,
    yardBlockRowStore,
    yardBlockSlotStore,
    yardBlockStackStore,
    yardBlockStore,
    customerStore,
    carrierVisitAllocationRulesV2UIStore,
    discriminator,
  ])

  useEffect(() => {
    carrierVisitAllocationRulesV2UIStore.handleDestinationsHighlight()
  }, [
    carrierVisitAllocationRulesV2UIStore,
    carrierVisitAllocationRulesV2UIStore.allocationRules,
    carrierVisitAllocationRulesV2UIStore.selectedAllocationRule,
    carrierVisitAllocationRulesV2UIStore.showAllocationRuleForm,
  ])

  const setAllocationRuleState = (allocationRule?: CarrierVisitAllocationRuleDto) =>
    carrierVisitAllocationRulesV2UIStore.setSelectedAllocationRule(allocationRule)

  const handleOnClose = () => {
    carrierVisitAllocationRulesV2UIStore.containerDialogUtilStore.toggleDialog()
    allocationRuleTemplatesV2UIStore.resetSearchFilter()
  }

  const handleCopyToVisit = async (
    allocationRuleId: string,
    destinationAndStackingStrategies: AllocationDestinationAndStackingStrategyDto[],
  ) => {
    if (!discriminator || !handlingDirection) return

    await allocationRuleTemplatesV2UIStore.copyRuleTemplateToCarrierVisit(
      allocationRuleId,
      destinationAndStackingStrategies,
      handlingDirection,
      discriminator,
      setAllocationRuleState,
      handleOnClose,
    )
  }

  if (!vesselVisitId || !handlingDirection) return <></>

  const showAllocationRulePanel =
    carrierVisitAllocationRulesV2UIStore.showAllocationRuleForm ||
    carrierVisitAllocationRulesV2UIStore.showDestinationForm

  return (
    <Box sx={{ height: '100%', backgroundColor: palette.grey[200] }}>
      <CarrierVisitDetails
        carrierVisit={carrierVisitStore.getById(Number(vesselVisitId))!}
        direction={handlingDirection}
      />
      <Grid
        container
        spacing={4}
        sx={{
          padding: 2,
          [breakpoints.up('sm')]: {
            height: 'calc(100% - 40px)',
          },
        }}
      >
        <Grid item xs={12} sm={5} lg={4} xl={3}>
          <YardPlanningDashboardDetailsSidePanel
            carrierVisitId={parseInt(vesselVisitId, 10)}
            carrierVisitDirection={handlingDirection}
            discriminator={discriminator ?? ''}
            unallocatedTurnovers={unallocatedTurnoversStore.unAllocatedTurnovers}
            weightClasses={weightClassContainerUIStore.weightClasses}
            showAllocationRulePanel={showAllocationRulePanel}
          />
        </Grid>

        <Grid item xs={12} sm={7} lg={8} xl={9} sx={{ height: '100%', position: 'relative' }}>
          <YardPlanningDashboardLegend
            carrierVisitDirection={handlingDirection}
            carrierVisitId={parseInt(vesselVisitId, 10)}
          />
          <YardPlanningDashboardBlocks
            uiStore={carrierVisitAllocationRulesV2UIStore}
            carrierVisitDirection={handlingDirection}
            carrierVisitId={parseInt(vesselVisitId, 10)}
          />
        </Grid>
      </Grid>

      <CarrierVisitAllocationRulesDialog
        destinationUIStore={carrierVisitAllocationRulesV2UIStore}
        isOpen={carrierVisitAllocationRulesV2UIStore.containerDialogUtilStore.isDialogOpen}
        onCopy={handleCopyToVisit}
        onClose={handleOnClose}
      />

      <CarrierVisitAllocationRuleListItemDialog />

      <AllocationRuleTemplatesTableDialog
        destinationUIStore={carrierVisitAllocationRulesV2UIStore}
      />
    </Box>
  )
})

export default YardPlanningDashboardDetails
