import { Box, Button, MenuItem, Tab, Tabs, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { useMinimalsTheme } from '../../../hooks'
import { ConfirmationDialog } from '../dialog/ConfirmationDialog'
import { ThreeDotMenu } from '../threeDotMenu/ThreeDotMenu'
import GroupingForm, { GroupFormProfile } from './GroupingForm'
import { Group, GroupMap, Id } from './types'

export interface IProp {
  groupOrder: Id[]
  groups: GroupMap
  currentTabId: Id
  areaLabel: string
  uniqueGroupNames: boolean
  allowGroupAdd: boolean
  allowGroupOptions: boolean
  allowGroupNotes: boolean
  allowSequence?: boolean
  addGroup: () => void
  ungroup?: (groupId: Id) => void
  toggleSeqFn?: () => void
  setCurrentTabId: (index: Id) => void
  updateGroupFn?: () => void
}

const updateProperty = (
  groups: GroupMap,
  id: string,
  newValue: string | boolean,
  property: 'name' | 'note' | 'sequenced',
) => {
  const group = groups[id]
  const updatedGroupData = { ...group, [property]: newValue }
  groups[id] = updatedGroupData
}

export const GroupingTabs: FC<IProp> = observer(
  ({
    groupOrder,
    groups,
    currentTabId,
    areaLabel,
    uniqueGroupNames,
    allowGroupAdd,
    allowGroupOptions,
    allowGroupNotes,
    allowSequence,
    addGroup,
    ungroup,
    toggleSeqFn,
    setCurrentTabId,
    updateGroupFn,
  }) => {
    const [openFormDialog, setOpenFormDialog] = useState<boolean>(false)
    const [formError, setFormError] = useState<boolean>(false)
    const [editingGroup, setEditingGroup] = useState<Group>()

    const { palette } = useMinimalsTheme()

    const handleOpenFormDialog = (group: Group) => {
      setEditingGroup(group)
      setOpenFormDialog(true)
    }

    const handleSubmitForm = (formValues: GroupFormProfile) => {
      if (editingGroup) {
        const hasGroupWithSameName = Object.values(groups).some(
          g =>
            g.name.toLocaleLowerCase() === formValues.name.toLocaleLowerCase() &&
            g.id !== editingGroup.id,
        )

        if (uniqueGroupNames && hasGroupWithSameName) {
          setFormError(true)
          return
        } else setFormError(false)

        updateProperty(groups, editingGroup.id, formValues.name, 'name')
        updateProperty(groups, editingGroup.id, formValues.note, 'note')

        if (updateGroupFn) updateGroupFn()
      }

      setOpenFormDialog(false)
    }

    const handleUngroup = (groupId: Id) => {
      if (ungroup) {
        ungroup(groupId)
        const groupValues = Object.values(groups)
        if (groupValues?.length > 0) setCurrentTabId(groupValues[0].id)
      }
    }

    const handleIsSequenced = (groupId: Id, isSequenced: boolean) => {
      updateProperty(groups, groupId, isSequenced, 'sequenced')
      if (toggleSeqFn) toggleSeqFn()
    }

    const MenuItems = (group: Group) => {
      return (
        <>
          <MenuItem divider={true} onClick={() => handleOpenFormDialog(group)}>
            Edit group details
          </MenuItem>
          {allowSequence && (
            <MenuItem divider={true} onClick={() => handleIsSequenced(group.id, !group.sequenced)}>
              Toggle sequence
            </MenuItem>
          )}
          <MenuItem onClick={() => handleUngroup(group.id)}>Remove group</MenuItem>
        </>
      )
    }

    return (
      <Box sx={{ display: 'flex' }}>
        <Tabs
          value={`${currentTabId}`}
          onChange={(_, val) => setCurrentTabId(val)}
          variant='scrollable'
          scrollButtons
          allowScrollButtonsMobile
        >
          {groupOrder
            .map(id => groups[id])
            .map(group => (
              <Tab
                id={group.id}
                key={group.id}
                value={group.id}
                label={
                  <>
                    {group.ItemIds?.length > 0 ? group.ItemIds.length : 0}x {group.name}
                    {!group.defaultGroup && allowGroupOptions && (
                      <ThreeDotMenu>{MenuItems(group)}</ThreeDotMenu>
                    )}
                  </>
                }
                data-cy={`group-tab-${group.name}`}
              />
            ))}
        </Tabs>
        {allowGroupAdd && <Button onClick={addGroup}>+</Button>}

        <ConfirmationDialog
          open={openFormDialog}
          onClose={() => {
            setOpenFormDialog(false)
            setFormError(false)
          }}
          title={`Edit ${areaLabel.toLocaleLowerCase()} group`}
          closeLabel={'Cancel'}
          primaryActionText={'Update'}
          maxWidth='xs'
          formId='group-form-id'
        >
          <GroupingForm
            onSubmit={handleSubmitForm}
            id='group-form-id'
            allowGroupNotes={allowGroupNotes}
            groupName={editingGroup?.name ?? ''}
            groupNote={editingGroup?.note ?? ''}
            areaLabel={areaLabel}
          />
          {formError && (
            <Typography color={palette.error.main}>Group names must be unique</Typography>
          )}
        </ConfirmationDialog>
      </Box>
    )
  },
)
