import { Box, Button, TextField } from '@mui/material'
import {
  CarrierVisitDirection,
  ContainerDamageResponseDto,
  OrderResponseDto,
} from '@planning/app/api'
import { ContainerDamageRecordStep } from '@planning/components'
import { useNavigationStore } from '@planning/stores'
import { GateInViewStore } from '@planning/stores/gateClerk/GateInViewStore'
import { NonNumericOrderWithPickUpAmount } from '@planning/stores/truckAppointment/TruckAppointmentDetailsViewStore'
import { useTranslate } from '@tolgee/react'
import {
  ConfirmationDialog,
  ContainerMobile,
  Header,
  StepperInfo,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NavigationStackCloseButton } from '../TallymanV2/Components/HeaderComponents/NavigationStackCloseButton'
import { IInspectContainerFormData, InspectContainer } from './Components/InspectContainer'
import { OrderHandlingBox } from './Components/OrderHandlingBox'
import { NoOrdersHint } from './NoOrdersHint'
import { SearchDropOffOrders } from './SearchDropOffOrders'
import { SearchPickUpOrders } from './SearchPickUpOrders'

interface IProps {
  store: GateInViewStore
  onSubmit: (data: IGateInFormData) => Promise<void> | void
}

export interface IGateInFormData {
  truckVisitId?: number
  truckPlate: string
  driverName?: string
  eta: string | null
  etd: string | null
  dropOffOrders: IInspectContainerFormData[]
  pickUpOrders: IInspectContainerFormData[]
  generalCargoOrders: IInspectContainerFormData[]
  nnrOrders: NonNumericOrderWithPickUpAmount[]
  truckAppointmentDate?: string | null
}

export const GateInForm: FC<IProps> = observer(({ store, onSubmit }) => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      truckPlate: store.licensePlate,
      dropOffOrders: store.dropOffOrders,
      pickUpOrders: store.pickUpOrders,
      nnrOrders: store.nnrOrders,
    } as IGateInFormData,
  })

  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const navigationStore = useNavigationStore()

  const onSelectDropOff = (order: OrderResponseDto): void => {
    navigationStore.push(
      <InspectContainer
        order={order}
        submitButtonText={t('save', 'Save')}
        displayDamageReport
        onSubmit={data => {
          const handleSubmit = (orderData: IInspectContainerFormData) => {
            store.upsertDropOffOrder(orderData)
            navigationStore.pop()
            navigationStore.pop()
          }

          if (data.hasDamage) {
            handleReportDamage(data, handleSubmit)
          } else {
            handleSubmit(data)
          }
        }}
      />,
    )
  }

  const onAddDropOff = () => {
    store.parentStore.dropOffOrderSearchStore.setFilter('')
    navigationStore.push(
      <SearchDropOffOrders
        store={store.parentStore.dropOffOrderSearchStore}
        selectedOrders={store.dropOffOrders}
        onClickOrder={onSelectDropOff}
        onClickGCOrder={onSelectGeneralCargoOrder}
      />,
    )
  }

  const onSelectGeneralCargoOrder = (order: OrderResponseDto): void => {
    store.upsertDropOffOrder({
      ...order,
      carrierType: order.carrierVisitType ?? 'Universal',
      damages: [],
    })
    navigationStore.pop()
  }

  const onSelectPickUp = (order: OrderResponseDto): void => {
    // [GateClerk] TODO: do we need the damages here as well?
    store.upsertPickUpOrder({
      ...order,
      carrierType: order.carrierVisitType ?? 'Universal',
      damages: [] as ContainerDamageResponseDto[],
    })
    navigationStore.pop()
  }

  const onAddPickUp = () => {
    store.parentStore.pickUpOrderSearchStore.setFilter('')
    navigationStore.push(
      <SearchPickUpOrders
        store={store.parentStore.pickUpOrderSearchStore}
        selectedOrders={store.pickUpOrders}
        onClickOrder={onSelectPickUp}
        selectedNNROrders={store.nnrOrders}
        onClickNNROrder={nnrOrder => {
          store.upsertNNROrder(nnrOrder)
          navigationStore.pop()
        }}
        validateOutboundRequest={store.validateOutboundRequest}
      />,
    )
  }

  const onEditDropOffOrder = (order: IInspectContainerFormData) => {
    navigationStore.push(
      <InspectContainer
        order={order}
        damages={order.damagesReported}
        submitButtonText={t('save', 'Save')}
        displayDamageReport
        onSubmit={data => {
          const handleSubmit = (orderData: IInspectContainerFormData) => {
            store.upsertDropOffOrder(orderData)
            navigationStore.pop()
          }

          if (data.hasDamage) {
            handleReportDamage(data, handleSubmit)
          } else {
            handleSubmit(data)
          }
        }}
      />,
    )
  }

  const onEditPickUpOrder = (order: IInspectContainerFormData) => {
    navigationStore.push(
      <InspectContainer
        order={order}
        damages={order.damagesReported}
        submitButtonText={t('save', 'Save')}
        onSubmit={data => {
          store.upsertPickUpOrder(data)
          navigationStore.pop()
        }}
      />,
    )
  }

  const handleReportDamage = (
    orderData: IInspectContainerFormData,
    onConfirm: (orderData: IInspectContainerFormData) => void,
  ) => {
    navigationStore.push(
      <ContainerDamageRecordStep
        orderData={orderData}
        containerNumber={orderData?.containerNumber}
        onSubmit={(data, damages) => {
          data.damagesReported = damages
          navigationStore.pop()

          onConfirm(data)
        }}
      />,
    )
  }

  const showNoOrdersHint = !!errors.dropOffOrders && !!errors.pickUpOrders

  const validateNoOrderVisit = (f: IGateInFormData) =>
    !!f.dropOffOrders.length || !!f.pickUpOrders.length || !!f.nnrOrders.length

  const Action: FC = observer(() => {
    return (
      <Button
        data-cy='gate-in-next-btn'
        variant='contained'
        type='submit'
        sx={{ width: '15%', minWidth: '100px' }}
        style={
          !validateNoOrderVisit(getValues()) || !store.licensePlate
            ? {
                backgroundColor: theme.palette.grey[100],
                color: theme.palette.grey[500],
                boxShadow: '0 8px 16px 0 rgba(249, 250, 251, 0.24)',
              }
            : {}
        }
      >
        {t('next', 'Next')}
      </Button>
    )
  })

  const Dialogs: FC = observer(() => {
    const onClose = () => {
      store.reset()
      navigationStore.pop()
    }

    const deleteOrder = () => {
      if (!store.selectedOrder) return

      const { id, direction } = store.selectedOrder

      if (direction === CarrierVisitDirection.Inbound) store.deleteDropOffOrder(id)
      else store.deletePickUpOrder(id)

      store.resetDialogs()
    }

    const deleteNNROrder = () => {
      if (!store.selectedNNROrder) return

      store.deleteNNROrder(store.selectedNNROrder.id)
      store.resetDialogs()
    }

    const onConfirmRemoval = () => (store.selectedOrder ? deleteOrder() : deleteNNROrder())

    return (
      <>
        <ConfirmationDialog
          open={store.isRemoveDialogOpen}
          title={`${t('sureYouWantToRemove', 'Sure you want to remove')} ${
            store.selectedOrder?.containerNumber ?? store.selectedNNROrder?.referenceNumber ?? ''
          }?`}
          primaryActionText={t('remove', 'Remove')}
          closeLabel={t('cancel', 'Cancel')}
          onConfirm={onConfirmRemoval}
          onClose={store.resetDialogs}
          mobile
        />

        <ConfirmationDialog
          open={store.isCancelGateDialogOpen}
          title={t('cancelGateIn?', 'Cancel gate in?')}
          message={t('anyChangesYouMadeWillBeLost', 'Any changes you made will be lost')}
          closeLabel={t('noDontCancel', `No, don't cancel`)}
          primaryActionText={t('cancel', 'Cancel')}
          onClose={store.resetDialogs}
          onConfirm={onClose}
          mobile
        />
      </>
    )
  })

  const HeaderWithBreadcrumbs: FC = observer(() => (
    <>
      <Header
        title={t('gateIn', 'Gate in')}
        leftRenderOption={
          <NavigationStackCloseButton onClose={() => store.openCancelGateDialog()} />
        }
        rightRenderOption={<Action />}
      />
      <StepperInfo
        title={t('recordVisitDetails', 'Record visit details')}
        steps={`1 ${t('of', 'of')} 2`}
      />
    </>
  ))

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <HeaderWithBreadcrumbs />
      <Box style={{ padding: '1rem' }}>
        <ContainerMobile sx={{ pt: theme.customSpacing.l }}>
          <NoOrdersHint show={showNoOrdersHint} />

          <TextField
            data-cy='gate-in-truck-plate-input'
            fullWidth
            label={`${t('truckPlateNumber', 'Truck plate number')}*`}
            variant='outlined'
            {...register('truckPlate', { required: true })}
            name='truckPlate'
            onChange={e => store.setLicensePlate(e.target.value)}
            sx={{ m: '1rem 0' }}
            error={!!errors.truckPlate}
            helperText={
              errors.truckPlate
                ? t('truckPlateNumberMandatory', 'Truck plate number is mandatory')
                : ''
            }
          />

          <Controller
            control={control}
            name={`dropOffOrders`}
            rules={{ validate: (_, formData) => validateNoOrderVisit(formData) }}
            render={() => {
              return (
                <OrderHandlingBox
                  orders={store.dropOffOrders.map(o => ({
                    ...o,
                    direction: CarrierVisitDirection.Inbound,
                  }))}
                  type='DropOff'
                  onAddOrder={onAddDropOff}
                  onRemoveOrder={store.openRemoveDialog}
                  onEditOrder={onEditDropOffOrder}
                  error={showNoOrdersHint}
                />
              )
            }}
          />

          <Controller
            control={control}
            name={`pickUpOrders`}
            rules={{ validate: (_, formData) => validateNoOrderVisit(formData) }}
            render={() => {
              return (
                <OrderHandlingBox
                  orders={store.pickUpOrders.map(o => ({
                    ...o,
                    direction: CarrierVisitDirection.Outbound,
                  }))}
                  nnrOrders={store.nnrOrders}
                  type='PickUp'
                  onAddOrder={onAddPickUp}
                  onRemoveOrder={store.openRemoveDialog}
                  onEditOrder={onEditPickUpOrder}
                  onRemoveNNROrder={store.openRemoveDialog}
                  error={showNoOrdersHint}
                  validateOutboundRequest={store.validateOutboundRequest}
                />
              )
            }}
          />

          <Dialogs />
        </ContainerMobile>
      </Box>
    </form>
  )
})
