import { CoolingOrderDto } from '@operations/app/api'
import { useOperationsStore } from '@operations/AppProvider'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

interface Props {
  order: CoolingOrderDto
}

export const setTimeReached = (
  setMaxUnpluggedTimeReached: React.Dispatch<React.SetStateAction<boolean>>,
  pluggedOutTime?: string | null,
  reeferContainerMaxUnpluggedTime?: number | null,
) => {
  if (
    !pluggedOutTime ||
    (!reeferContainerMaxUnpluggedTime && reeferContainerMaxUnpluggedTime !== 0)
  ) {
    setMaxUnpluggedTimeReached(false)
    return
  }

  const timeDifferenceInMinutes =
    (Date.now() - new Date(pluggedOutTime!).getTime()) / (1000 * 60 * 60)

  if (timeDifferenceInMinutes >= reeferContainerMaxUnpluggedTime) {
    setMaxUnpluggedTimeReached(true)
  } else {
    setMaxUnpluggedTimeReached(false)
  }
}

export const CoolingOrderUnpluggedTooLongWarning = observer(({ order }: Props) => {
  const { t } = useTranslate()
  const [maxUnpluggedTimeReached, setMaxUnpluggedTimeReached] = useState(false)
  const { tenantConfigStore } = useOperationsStore()

  useEffect(
    //use for update setMaxUnpluggedTimeReached immediately when pluggedOutTime and reeferContainerMaxUnpluggedTime changes
    () =>
      setTimeReached(
        setMaxUnpluggedTimeReached,
        order.pluggedOutTime,
        tenantConfigStore.tenantConfig?.reeferContainerMaxUnpluggedTime,
      ),
    [order.pluggedOutTime, tenantConfigStore.tenantConfig?.reeferContainerMaxUnpluggedTime],
  )

  useEffect(() => {
    //use for update setMaxUnpluggedTimeReached every 60 seconds
    const intervalId = setInterval(() => {
      setTimeReached(
        setMaxUnpluggedTimeReached,
        order.pluggedOutTime,
        tenantConfigStore.tenantConfig?.reeferContainerMaxUnpluggedTime,
      )
    }, 60000)

    return () => clearInterval(intervalId)
  }, [order, tenantConfigStore])

  return (
    !order.isOutboundWorkInstructionFinished &&
    maxUnpluggedTimeReached && (
      <ColoredLabel color='warning' label={t('unpluggedTimeExceeded', 'Unplugged time exceeded')} />
    )
  )
})
