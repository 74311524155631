import { Box, Grid } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CancellableTimeoutAlert } from '@planning/pages/GateClerk/Components/CancellableTimeoutAlert'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { RailTallyViewStore } from '../../Stores/RailTallyViewStore'
import { InspectRailcarContainerCard } from './InspectRailcarContainerCard'
import { InspectRailcarEmptyMessage } from './InspectRailcarEmptyMessage'

interface IProps {
  railcarOrders: IOrderItem[]
  onReportShortlandedIssue?: (order: IOrderItem) => void
}

interface IPropsList extends IProps {
  store: RailTallyViewStore
}

const InspectRailcarContainersList: FC<IPropsList> = observer(
  ({ railcarOrders, store, onReportShortlandedIssue }) => {
    const mergedOrdersMap = new Map<string, any>()

    railcarOrders.forEach(order => {
      const containerNumber = order.container?.data.number
      if (containerNumber && !mergedOrdersMap.has(containerNumber)) {
        mergedOrdersMap.set(containerNumber, { railcarOrder: order })
      }
    })

    store.upsertedOrders.forEach(upsertedOrder => {
      const containerNumber = upsertedOrder.containerNumber
      if (containerNumber && !mergedOrdersMap.has(containerNumber)) {
        mergedOrdersMap.set(containerNumber, { upsertedOrder })
      }
    })

    const mergedOrders = Array.from(mergedOrdersMap.values())

    return (
      <Grid container spacing='0.5rem' p='1rem'>
        {mergedOrders.map((item, index) => {
          const { railcarOrder, upsertedOrder } = item

          if (upsertedOrder) {
            return (
              <InspectRailcarContainerCard
                key={upsertedOrder.containerNumber}
                containerNumber={upsertedOrder.containerNumber}
                isoCode={upsertedOrder.containerIsoCode}
                type={upsertedOrder.containerType}
                length={upsertedOrder.containerLength}
                height={upsertedOrder.containerHeight}
                isEmpty={upsertedOrder.isEmpty}
                vgm={upsertedOrder.vgm}
                grossWeight={upsertedOrder.containerMaxGross}
                containerTare={upsertedOrder.containerTare}
                imoClasses={upsertedOrder.imoClasses}
                isDamaged={upsertedOrder.hasDamage}
                upsertedOrder={upsertedOrder}
              />
            )
          } else if (railcarOrder) {
            return (
              <InspectRailcarContainerCard
                key={index}
                containerNumber={railcarOrder.container?.data.number}
                isoCode={railcarOrder.container?.data.isoCode}
                type={railcarOrder.container?.data.type}
                length={railcarOrder.container?.data.length}
                height={railcarOrder.container?.data.height}
                isEmpty={railcarOrder.container?.data.isEmpty}
                grossWeight={railcarOrder.container?.data.maxGross}
                containerTare={railcarOrder.data.containerTare}
                imoClasses={railcarOrder.data.imoClasses}
                isDamaged={railcarOrder.data.isDamaged}
                vgm={railcarOrder.vgm}
                railcarOrder={railcarOrder}
                onReportShortlandedIssue={onReportShortlandedIssue}
              />
            )
          }

          return <></>
        })}
      </Grid>
    )
  },
)

export const InspectRailcarContainers: FC<IProps> = observer(
  ({ railcarOrders, onReportShortlandedIssue }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const { tallymanSelectVesselAndOperationViewStore } = usePlanningStore()
    const store = tallymanSelectVesselAndOperationViewStore.railTallyStore

    if (!railcarOrders.length && !store.upsertedOrders.length) {
      return <InspectRailcarEmptyMessage store={store} />
    }

    return (
      <>
        <InspectRailcarContainersList
          railcarOrders={railcarOrders}
          store={store}
          onReportShortlandedIssue={onReportShortlandedIssue}
        />
        <Box
          sx={{
            position: 'fixed',
            bottom: '0',
            left: '0',
            width: '100%',
            padding: theme.spacing(2),
          }}
        >
          <CancellableTimeoutAlert
            testId='check-success-alert'
            store={store}
            successText={t(
              'OrderHasBeenSuccessfullyUpdated',
              'Order has been successfully updated',
            )}
            cancelledText={t('OrderUpdateHasBeenCancelled', 'Order update has been cancelled')}
            errorText={t('failedToUpdateOrder', 'Failed to update order')}
            cancellable={store.cancellable}
          />
        </Box>
      </>
    )
  },
)
