import { Cached } from '@mui/icons-material'
import { Box, Button, IconButton, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ChevronLeftIcon, ChevronRightIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'

interface IProps {
  updates: IOrderUpdateNotification[]
  onMarkAllAsRead?: () => void
}

export interface IOrderUpdateNotification {
  title: string | JSX.Element
  subtitle: string | JSX.Element
}

/**
 * OrderUpdateNotification component displays a notification box for order updates.
 * It shows the current update and allows navigation through multiple updates if available.
 *
 * @component
 * @param {IOrderUpdateNotification[]} updates - The list of updates to display.
 * @param {Function} [onMarkAllAsRead] - Optional callback function to mark all updates as read.
 *
 * @returns {JSX.Element} The rendered OrderUpdateNotification component.
 *
 * @example
 * <OrderUpdateNotification
 *   updates={[{ title: 'Update 1', subtitle: 'Details of update 1' }, { title: 'Update 2', subtitle: 'Details of update 2' }]}
 *   onMarkAllAsRead={() => console.log('All updates marked as read')}
 * />
 */
export const OrderUpdateNotification: FC<IProps> = observer(({ updates, onMarkAllAsRead }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const [currentUpdateIndex, setCurrentUpdateIndex] = useState(0)

  const updatesCount = updates.length
  const currentUpdate = updates[currentUpdateIndex]

  if (updatesCount === 0) return <></>

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.customRadius.medium,
        bgcolor: theme.palette.grey[200],
        padding: theme.customSpacing.m,
        display: 'flex',
        alignItems: 'center',
        placeContent: 'space-between',
      }}
    >
      <Stack
        direction={'row'}
        gap={theme.customSpacing.m}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box
          bgcolor={'white'}
          borderRadius={'50%'}
          width={theme.customSpacing.xxl}
          height={theme.customSpacing.xxl}
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          padding={theme.customSpacing.m}
        >
          <Cached fontSize='large' />
        </Box>
        <Stack gap={theme.customSpacing.xs}>
          {updates.length > 1 && (
            <Stack
              display={'flex'}
              flexDirection={'row'}
              gap={theme.customSpacing.xs}
              alignContent={'center'}
            >
              <Typography
                variant='subtitle1'
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
              >
                {currentUpdateIndex + 1} of {updatesCount} updates
              </Typography>

              <Box>
                <IconButton
                  disabled={currentUpdateIndex === 0}
                  onClick={() => setCurrentUpdateIndex(currentUpdateIndex - 1)}
                >
                  <ChevronLeftIcon fontSize='small' />
                </IconButton>
                <IconButton
                  disabled={currentUpdateIndex + 1 === updatesCount}
                  onClick={() => setCurrentUpdateIndex(currentUpdateIndex + 1)}
                >
                  <ChevronRightIcon fontSize='small' />
                </IconButton>
              </Box>
            </Stack>
          )}

          <Stack>
            <Typography variant='body1' color={theme.palette.text.secondary}>
              {currentUpdate?.title}
            </Typography>
            <Typography variant='body2' fontSize='.75rem' color={theme.palette.text.secondary}>
              {currentUpdate?.subtitle}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Box alignSelf={'center'} alignContent={'end'}>
        {onMarkAllAsRead && (
          <Button color='inherit' onClick={onMarkAllAsRead}>
            {t('markAllAsRead', 'Mark all as read')}
          </Button>
        )}
      </Box>
    </Box>
  )
})
