import { Button, Stack } from '@mui/material'
import { ChevronRightIcon, useMinimalsTheme } from '../../../index'

export interface BreadcrumpItem {
  label: string
  onClick?: () => void
}

interface Props {
  items: BreadcrumpItem[]
}
export const Breadcrumbs = ({ items }: Props) => {
  const theme = useMinimalsTheme()

  return (
    <Stack
      flexDirection='row'
      alignItems='center'
      gap={theme.customSpacing.xxs}
      divider={<ChevronRightIcon fontSize='small' />}
      paddingY={theme.customSpacing.xxs}
      paddingX={theme.customSpacing.m}
      sx={{
        backgroundColor: theme.palette.grey[200],
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        width: '100%',
      }}
    >
      {items.map((item, index) => {
        const isLast = index === items.length - 1

        return (
          <Button
            key={`breadcrumb_item_${index}`}
            variant='text'
            color={isLast ? 'secondary' : 'inherit'}
            onClick={() => {
              if (item.onClick) {
                item.onClick()
              }
            }}
            disabled={isLast}
          >
            {item.label}
          </Button>
        )
      })}
    </Stack>
  )
}
