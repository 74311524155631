import { Stack } from '@mui/material'
import { CarrierType } from '@operations/app/api'
import { FormProps, SelectOption } from '@operations/app/models'
import { CheckboxSelector } from '@operations/components/form-controls/CheckboxSelector'
import { ChipSelector } from '@operations/components/form-controls/ChipSelector'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { CoolingOrderAlerts } from '../../models/cooling-order-model'
import {
  defaultValues,
  PlugInStatus,
  ReeferMonitoringFilter,
} from './reefer-monitoring-filter.model'

interface Props extends FormProps {
  filter?: ReeferMonitoringFilter
  yardBlocks: SelectOption[]
  customers: SelectOption[]
  onSubmit: (formValues: ReeferMonitoringFilter) => Promise<void> | void
}

export const ReeferMonitoringFiltersForm = ({
  id,
  filter,
  yardBlocks,
  customers,
  onSubmit,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const carrierTypeOptions = useMemo<SelectOption[]>(
    () => [
      { label: t('vessel', 'Vessel'), value: CarrierType.Vessel },
      { label: t('truck', 'Truck'), value: CarrierType.Truck },
      { label: t('train', 'Train'), value: CarrierType.Train },
    ],
    [t],
  )

  const pluggedOptions = useMemo<SelectOption[]>(
    () => [
      { label: t('pluggedIn', 'Plugged in'), value: PlugInStatus.PluggedIn },
      { label: t('pluggedOut', 'Plugged out'), value: PlugInStatus.PluggedOut },
    ],
    [t],
  )

  const statusOptions = useMemo<SelectOption[]>(
    () => [
      { label: t('plugInRequired', 'Plug in required'), value: CoolingOrderAlerts.PlugInRequired },
      {
        label: t('plugOutRequired', 'Plug out required'),
        value: CoolingOrderAlerts.PlugOutRequired,
      },
      {
        label: t('temperatureCheck', 'Temperature check'),
        value: CoolingOrderAlerts.TemperatureCheck,
      },
      { label: t('hasError', 'Has error'), value: CoolingOrderAlerts.HasError },
      {
        label: t('temperatureDeviation', 'Temperature deviation'),
        value: CoolingOrderAlerts.TemperatureDeviation,
      },
      {
        label: t('humidityDeviation', 'Humidity deviation'),
        value: CoolingOrderAlerts.HumidityDeviation,
      },
      {
        label: t('ventilationDeviation', 'Ventilation deviation'),
        value: CoolingOrderAlerts.VentilationDeviation,
      },
    ],
    [t],
  )

  const { handleSubmit, control, reset } = useForm<ReeferMonitoringFilter>({
    defaultValues: defaultValues,
  })

  useEffect(() => {
    if (filter) {
      reset(filter)
    }
  }, [filter, reset])

  return (
    <Stack
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      gap={theme.customSpacing.l}
      onSubmit={handleSubmit(onSubmit)}
    >
      {yardBlocks.length > 0 && (
        <Controller
          name={'yardBlockIds'}
          control={control}
          render={({ field }) => (
            <ChipSelector
              label={t('yardBlocks', 'Yard blocks')}
              options={yardBlocks}
              onChange={field.onChange}
              selectedChips={field.value}
            />
          )}
        />
      )}

      {customers.length > 0 && (
        <Controller
          name={'customerIds'}
          control={control}
          render={({ field }) => (
            <ChipSelector
              label={t('customers', 'Customers')}
              options={customers}
              onChange={field.onChange}
              selectedChips={field.value}
            />
          )}
        />
      )}

      <Controller
        name={'isPluggedIn'}
        control={control}
        render={({ field }) => (
          <ChipSelector
            label={t('plugInStatus', 'Plug in status')}
            options={pluggedOptions}
            onChange={field.onChange}
            selectedChips={field.value}
          />
        )}
      />

      <Controller
        name={'inboundCarrierType'}
        control={control}
        render={({ field }) => (
          <CheckboxSelector
            label={t('inboundCarrierType', 'Inbound carrier type')}
            options={carrierTypeOptions}
            onChange={field.onChange}
            selectedCheckboxes={field.value}
          />
        )}
      />

      <Controller
        name={'outboundCarrierType'}
        control={control}
        render={({ field }) => (
          <CheckboxSelector
            label={t('outboundCarrierType', 'Outbound carrier type')}
            options={carrierTypeOptions}
            onChange={field.onChange}
            selectedCheckboxes={field.value}
          />
        )}
      />

      <Controller
        name={'status'}
        control={control}
        render={({ field }) => (
          <ChipSelector
            label={t('status', 'Status')}
            options={statusOptions}
            onChange={field.onChange}
            selectedChips={field.value}
          />
        )}
      />
    </Stack>
  )
}
