import { Box, Grid, TextField } from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import {
  CarrierType,
  ContainerDamageResponseDto,
  OrderResponseDto,
  UnitType,
} from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { IContainerDamage } from '@planning/components'
import { ControlledContainerSeals } from '@planning/components/ControlledContainerSeals'
import { ControlledDamageRecordBox } from '@planning/components/damage-report/ControlledDamageRecordBox'
import { DangerousGoodAutoComplete } from '@planning/components/dangerous-goods/DangerousGoodsAutoComplete'
import { DamageReportBox } from '@planning/pages/TallymanV2/Components/DamageReportBox'
import { IContainerItem } from '@planning/rt-stores/container/ContainerItem'
import { validateIsoCode } from '@planning/rt-stores/helpers'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { Controller, FieldErrors, useFormContext } from 'react-hook-form'

interface IProps {
  damages?: ContainerDamageResponseDto[]
  containerItem?: IContainerItem
  containerAutocomplete?: React.ReactNode
}

export interface IInspectContainerFormData extends OrderResponseDto {
  carrierType: CarrierType
  carrierVisitId: number
  damages: ContainerDamageResponseDto[]
  damagesReported?: IContainerDamage[]
  hasDamage?: boolean
}

export const InspectContainerForm: FC<IProps> = observer(
  ({ containerItem, containerAutocomplete }) => {
    const { t } = useTranslate()
    const hasNewDamageRecording = useBooleanFlagValue('damage-recording', false)
    const { isoCodeMappingInputStore } = usePlanningStore()

    const { control, getValues, setValue, watch, formState } =
      useFormContext<IInspectContainerFormData>()

    const { errors } = formState

    const [isDangerous, setIsDangerous] = useState<boolean>(!!getValues('imoClasses')?.length)
    const hasDoorDirection = !!getValues('doorDirection')
    const hasOperationalInstructions = !!getValues('operationalInstructions')

    const customIsoMappingFf = useBooleanFlagValue('custom-iso-mapping', false)

    const onEditDamageReport = (
      editedReport: Partial<ContainerDamageResponseDto>,
      initialReport: ContainerDamageResponseDto,
    ): void => {
      setValue('damages', [
        ...getValues('damages').filter(d => d.description !== initialReport.description),
        editedReport as ContainerDamageResponseDto,
      ])
    }

    const containerIsoCodeErrorText = (errors: FieldErrors) => {
      const isoCodeError = errors.containerIsoCode
      if (isoCodeError) {
        const errorType = isoCodeError.type
        if (errorType === 'required') return t('fieldIsRequired', 'Field is required.')
        if (errorType === 'validate') return t('isoCodeNotFound', 'ISO Code not found')

        return isoCodeError.message?.toString()
      }
    }

    const unitTypeIsContainer = containerItem?.data.unitType === UnitType.Container

    return (
      <form>
        <Grid container spacing='0.5rem' p='1rem'>
          {containerAutocomplete ? (
            <Grid item xs={12}>
              {containerAutocomplete}
            </Grid>
          ) : (
            ''
          )}
          {hasOperationalInstructions && (
            <Grid item xs={12}>
              <Controller
                control={control}
                name='operationalInstructions'
                render={({ field }) => (
                  <TextField
                    {...field}
                    data-cy='container-operational-instructions'
                    disabled
                    multiline
                    fullWidth
                    label={t('operationalInstructions', 'Operational Instructions')}
                    variant='outlined'
                    sx={{ marginTop: '1rem' }}
                  />
                )}
              />
            </Grid>
          )}
          {unitTypeIsContainer &&
            ((customIsoMappingFf && (
              <Grid item xs={12}>
                {isoCodeMappingInputStore.renderIsoCodeMappingInput?.({
                  label: `${t('isoCode', 'ISO Code')} *`,
                  fullWidth: true,
                  variant: 'outlined',
                  type: 'text',
                  uppercase: true,
                  formState: formState,
                  name: 'containerIsoCode',
                  required: true,
                  control: control,
                  watch: watch,
                })}
              </Grid>
            )) || (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name='containerIsoCode'
                  rules={{
                    required: true,
                    validate: v => !!v && validateIsoCode(v),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      data-cy='container-iso-code'
                      fullWidth
                      label={`${t('iso', 'ISO')}*`}
                      variant='outlined'
                      error={!!errors.containerIsoCode}
                      helperText={containerIsoCodeErrorText(errors)}
                      sx={{ marginTop: '1rem' }}
                    />
                  )}
                />
              </Grid>
            ))}

          <Grid item xs={12}>
            <Controller
              control={control}
              name='containerMaxGross'
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  data-cy='container-max-gross-weight'
                  fullWidth
                  label={`${t('maxGrossWeight', 'Max. gross weight')}`}
                  variant='outlined'
                  type='number'
                  InputProps={{
                    endAdornment: (
                      <CustomInputAdornment position='end' color='secondary'>
                        kg
                      </CustomInputAdornment>
                    ),
                  }}
                  sx={{ marginTop: '1rem' }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name='containerTare'
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  data-cy='container-tare-weight'
                  fullWidth
                  label={`${t('tareWeight', 'Tare weight')}`}
                  variant='outlined'
                  type='number'
                  InputProps={{
                    endAdornment: (
                      <CustomInputAdornment position='end' color='secondary'>
                        kg
                      </CustomInputAdornment>
                    ),
                  }}
                  sx={{ marginTop: '1rem' }}
                />
              )}
            />
          </Grid>
          {hasDoorDirection && (
            <Grid item xs={12}>
              <Controller
                control={control}
                name='doorDirection'
                render={({ field }) => (
                  <TextField
                    {...field}
                    data-cy='container-door-direction'
                    disabled
                    fullWidth
                    label={t('doorDirection', 'Door direction')}
                    variant='outlined'
                    sx={{ marginTop: '1rem' }}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Box sx={{ marginTop: '1rem !important' }}>
              <Controller
                control={control}
                name={`imoClasses`}
                rules={{ required: isDangerous }}
                render={({ field: { onChange } }) => (
                  <DangerousGoodAutoComplete
                    assignedCodes={getValues(`imoClasses`) ?? []}
                    enableSelect={isDangerous}
                    onToggleSelectDangerousGoods={() => setIsDangerous(!isDangerous)}
                    onDangerousGoodChange={onChange}
                    error={!!errors.imoClasses}
                    helperText={errors.imoClasses ? t('fieldIsRequired', 'Field is required.') : ''}
                  />
                )}
              />
            </Box>
          </Grid>
          {containerItem && (
            <Grid item xs={12}>
              {hasNewDamageRecording ? (
                <ControlledDamageRecordBox control={control} name='hasDamage' />
              ) : (
                <Box sx={{ marginTop: '1rem !important' }}>
                  <Controller
                    control={control}
                    name={`damages`}
                    render={({ field: { onChange } }) => (
                      <DamageReportBox
                        containerId={0}
                        damages={getValues('damages')}
                        onCreateDamageReport={(_, data) =>
                          onChange([
                            ...getValues('damages'),
                            {
                              ...(data as ContainerDamageResponseDto),
                              created: new Date().toISOString(),
                            },
                          ])
                        }
                        onEditDamageReport={onEditDamageReport}
                      />
                    )}
                  />
                </Box>
              )}
            </Grid>
          )}
          <Grid item xs={12} sx={{ marginTop: '1rem !important' }}>
            <ControlledContainerSeals
              name='seals'
              control={control}
              sealsName='seals'
              hasSealsName='hasSeals'
            />
          </Grid>
        </Grid>
      </form>
    )
  },
)
