import { Button, Grid, Stack, TextField } from '@mui/material'
import { YardPositionDto } from '@storage/app/api'
import { YardPositionInputContainer } from '@storage/features/yard-operation-control/Variants/YardPositionInput/YardPositionInputContainer'
import { ContainerAutoCompleteStore } from '@storage/stores/container-auto-complete.store'
import { ContainerShiftingsFormStore } from '@storage/stores/container-shiftings-form.store'
import { ContainerShiftingsStore } from '@storage/stores/container-shiftings.store'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { ShiftedContainerDto } from '../SearchContainerAutoComplete'

interface Props {
  item: ShiftedContainerDto
  autoCompleteStore: ContainerAutoCompleteStore
  formStore: ContainerShiftingsFormStore
  shiftingsStore: ContainerShiftingsStore
  onPositionUpdate?: (id: string) => void
}

export const ContainerShiftingsForm = observer(
  ({ item, formStore, shiftingsStore, onPositionUpdate }: Props) => {
    const { t } = useTranslate()
    const { palette, customSpacing } = useMinimalsTheme()

    const handleOnPositionChange = (position?: YardPositionDto) => {
      formStore.setContainerCurrentPosition(item.id, position)
      formStore.disableConfirmButton(item.id, false)
    }

    const updateContainerPosition = () => {
      if (onPositionUpdate) {
        onPositionUpdate(item.id)
      }
    }

    useEffect(() => {
      const loadTurnover = async () => {
        shiftingsStore.fetchTurnover(item.containerNumber)
      }
      loadTurnover()
    }, [item.containerNumber, shiftingsStore])

    return (
      <Grid container spacing={customSpacing.m} padding={customSpacing.m}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label={t('containerNumber', 'Container number')}
            value={item.containerNumber}
            fullWidth
            disabled
            sx={{
              input: {
                color: palette.grey[600],
                fontSize: 24,
                fontWeight: 600,
                textTransform: 'uppercase',
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <YardPositionInputContainer
            plannedPosition={item.previousLocation}
            disableSelectFlexibleZones={true}
            label={t('previousLocation', 'Previous Location')}
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <YardPositionInputContainer
            disableSelectFlexibleZones={true}
            onChange={handleOnPositionChange}
            label={t('currentLocation', 'Current Location')}
            turnover={shiftingsStore.turnover}
            alertSx={{ width: '335px', height: '60px' }}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Stack flexDirection='row' gap={customSpacing.xs} alignItems='center' height='100%'>
            <Button
              variant='contained'
              disabled={
                formStore.containerCurrentPositions.get(item.id) === undefined ||
                formStore.containersToBeShiftedFormItems.get(item.id)?.isConfirmButtonDisabled
              }
              onClick={updateContainerPosition}
            >
              Confirm
            </Button>
            <Button
              variant='text'
              color='inherit'
              onClick={() => formStore.removeFormItem(item.id)}
            >
              Delete
            </Button>
          </Stack>
        </Grid>
      </Grid>
    )
  },
)
