import { Grid, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { IssueType } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { ReportIssueTypeOptionButton } from './ReportIssueTypeOptionButton'

interface IProps {
  defaultValue: IssueType
  options: IssueType[]
  onSelect: (issueType: IssueType) => void
}

export const ReportIssueTypeOption: FC<IProps> = observer(({ defaultValue, options, onSelect }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const [selectedIssueType, setSelectedIssueType] = useState<IssueType>(defaultValue)

  return (
    <Stack gap={theme.customSpacing.s}>
      <Typography variant='h3'> {t('issueType', 'Issue type')}</Typography>
      <Grid container spacing={theme.customSpacing.m}>
        {options.map(option => (
          <Grid
            key={option}
            item
            xs={options.length === 1 ? 12 : 6}
            md
            sx={{ display: 'flex', alignItems: 'stretch' }}
          >
            <ReportIssueTypeOptionButton
              value={option}
              selected={option === selectedIssueType}
              onSelect={issue => {
                setSelectedIssueType(issue)
                onSelect(issue)
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Stack>
  )
})
