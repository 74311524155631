import { BlockLabellingPattern, EquipmentType } from '@storage/app/api'
import { FormType } from '@storage/app/models'

export enum StackingStrategy {
  BayRowTier = 'BayRowTier',
  RowBayTier = 'RowBayTier',
  TierBayRow = 'TierBayRow',
  TierRowBay = 'TierRowBay',
}

export interface YardBlockFormProfile {
  id?: string
  name: string
  maxTier: number
  rowsPatternType?: BlockLabellingPattern
  rowsFrom?: string
  rowsTo?: string
  customRows?: string
  baysPatternType?: BlockLabellingPattern
  baysFrom?: string
  baysTo?: string
  customBays?: string
  equipmentTypes: EquipmentType[]
  usageOptions?: string[]
  stackingStrategy: StackingStrategy
}

export interface YardBlockFormContext {
  type: FormType
}

export const defaultValues: YardBlockFormProfile = {
  name: '',
  maxTier: 1,
  rowsPatternType: BlockLabellingPattern.Alphabetic,
  rowsFrom: 'A',
  rowsTo: 'D',
  customRows: '',
  baysPatternType: BlockLabellingPattern.Numeric,
  baysFrom: '01',
  baysTo: '08',
  customBays: '',
  equipmentTypes: [EquipmentType.Rs],
  stackingStrategy: StackingStrategy.TierBayRow,
}
