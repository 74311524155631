import { PATHS } from '@host/app/paths'
import { useTranslate } from '@tolgee/react'
import { usePlanningStore } from '@tom-ui/planning'
import { useMinimalsTheme } from '@tom-ui/ui'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { GetTruckVisitsQuery } from 'modules/planning/src/messages/queries'
import moment from 'moment'
import { useEffect } from 'react'
import { CarrierVisitStatus } from '../../VesslVisit/VesselVisitDetails'
import { PieItem } from '../components/PieGraph'
import { VisitCard } from '../components/VisitCard'
import { isDateBetweenFromAndTo } from '../helpers/helper'

export const TruckVisitWidget = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { truckVisitItemStore } = usePlanningStore()
  const todayStartDate = moment().startOf('d')
  const todayEndDate = moment().endOf('d')

  useEffect(() => {
    const includeCompleted = true
    const orderBy: 'eta' | 'ata' | 'atd' = 'ata'
    const isDescending = false
    truckVisitItemStore.setDateRange(todayStartDate.toDate(), todayEndDate.toDate())

    truckVisitItemStore.fetch(
      new GetTruckVisitsQuery(undefined, undefined, includeCompleted, orderBy, isDescending, 'eta'),
    )
  }, [truckVisitItemStore])

  const getFilteredVisits = () => {
    const timeIsBetweenFromTo = (date?: string | null) => {
      return isDateBetweenFromAndTo(date, todayStartDate.toString(), todayEndDate.toString())
    }

    return truckVisitItemStore.sortedTruckVisits.filter(
      v =>
        (timeIsBetweenFromTo(v.data.ata) && v.data.status === CarrierVisitStatus.Arrived) ||
        (timeIsBetweenFromTo(v.data.atd) && v.data.status === CarrierVisitStatus.Departed) ||
        (timeIsBetweenFromTo(v.data.etd) && v.data.status === CarrierVisitStatus.Expected),
    )
  }

  const graphItems = computed(() => {
    const filteredVisits = getFilteredVisits()
    const graphCounts: PieItem[] = [
      {
        id: 1,
        color: theme.palette.info.light,
        value: 0,
        label: t('expected', 'Expected'),
      },
      {
        id: 2,
        color: theme.palette.primary.light,
        value: 0,
        label: t('arrived', 'Arrived'),
      },
      {
        id: 3,
        color: theme.palette.secondary.light,
        value: 0,
        label: t('Departed', 'Departed'),
      },
    ]

    filteredVisits.forEach(visit => {
      switch (visit.data.status) {
        case CarrierVisitStatus.Expected:
          graphCounts[0].value += 1
          break
        case CarrierVisitStatus.Arrived:
          graphCounts[1].value += 1
          break
        case CarrierVisitStatus.Departed:
          graphCounts[2].value += 1
          break
      }
    })

    return graphCounts.filter(x => x.value > 0)
  }).get()

  const containerMetrics = computed(() => {
    const filteredVisits = getFilteredVisits()

    const containersIn = { completed: 0, expected: 0 }
    const containersOut = { completed: 0, expected: 0 }

    filteredVisits.forEach(visit => {
      containersIn.completed += visit.inboundOrders.reduce(
        (prev, curr) => prev + (curr.data.status === 'Fulfilled' ? 1 : 0),
        0,
      )
      containersIn.expected += visit.inboundOrders.reduce(
        (prev, curr) => prev + (curr.data.status === 'Open' ? 1 : 0),
        0,
      )
      containersOut.completed += visit.outboundOrders.reduce(
        (prev, curr) => prev + (curr.data.status === 'Fulfilled' ? 1 : 0),
        0,
      )
      containersOut.expected += visit.outboundOrders.reduce(
        (prev, curr) => prev + (curr.data.status === 'Open' ? 1 : 0),
        0,
      )
    })

    return {
      in: containersIn,
      out: containersOut,
    }
  }).get()

  return (
    <VisitCard
      title={t('trucks', 'Trucks')}
      seeMorePath={PATHS.gateControl}
      graphItems={graphItems}
      containersIn={containerMetrics.in}
      containersOut={containerMetrics.out}
    />
  )
})
