import { DGSegregationRuleDto, ImoClassPairDto } from '@storage/app/api'
import { DGSegregationRuleService } from '@storage/services/dg-segregation-rule.service'

export class DGSegregationRuleDataStore {
  constructor(private readonly _dgSegregationRuleService: DGSegregationRuleService) {}

  public async getAll(): Promise<DGSegregationRuleDto[]> {
    const dgSegregationRules = await this._dgSegregationRuleService.getAll()
    return dgSegregationRules
  }

  public async upsertBulk(rules: DGSegregationRuleDto[]): Promise<DGSegregationRuleDto[]> {
    const dgSegregationRules = await this._dgSegregationRuleService.upsertBulk({ rules })
    return dgSegregationRules
  }

  public async deleteBulk(classPairs: ImoClassPairDto[]): Promise<void> {
    await this._dgSegregationRuleService.deleteBulk({ classPairs })
  }
}
