import { Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useOperationsStore } from '@tom-ui/operations'
import { useStorageStore, YardOperationControl } from '@tom-ui/storage'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { AggregateIssuesViewStore } from '../Stores/AggregateIssuesViewStore'
import { OperationsIssueCard } from './OperationsIssueCard'

interface Props {
  store: AggregateIssuesViewStore
}
export const PositionMissingDialog = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const [hasSelectedDestination, setHasSelectedDestination] = useState<boolean>(false)
  const { yardOperationControlStore } = useStorageStore()
  const { appStore } = useOperationsStore()

  const containerNumber = store.selectedMissingPositionIssue?.getContainerNumber()
  const orderId = store.selectedMissingPositionIssue?.orderId

  const handleConfirm = async () => {
    if (containerNumber && orderId)
      await yardOperationControlStore.onConfirm(containerNumber, orderId)
    appStore.setShowAlert(
      'success',
      t('positionWasUpdatedSuccessfully', 'Position was updated successfully'),
    )
    store.toggleMissingPositionIssue()
    setHasSelectedDestination(false)
  }

  return (
    <>
      <ConfirmationDialog
        open={!!store.selectedMissingPositionIssue}
        title={t('resolveIssue', 'Resolve issue')}
        primaryActionText={t('confirm', 'Confirm')}
        primaryActionDisabled={!hasSelectedDestination}
        onConfirm={handleConfirm}
        closeLabel={t('cancel', 'Cancel')}
        maxWidth='lg'
        onClose={() => store.toggleMissingPositionIssue()}
        hasBorderTop
      >
        {!store.selectedMissingPositionIssue ? (
          <Typography>{t('issueNotFound', 'Issue not found')}</Typography>
        ) : (
          <Stack gap={2}>
            <OperationsIssueCard store={store} issue={store.selectedMissingPositionIssue} />

            <YardOperationControl
              containerNumber={containerNumber!}
              handleChange={(value: any[]) => {
                setHasSelectedDestination(!!value)
              }}
              orderId={orderId!}
            />
          </Stack>
        )}
      </ConfirmationDialog>
    </>
  )
})
