import { Stack, Typography } from '@mui/material'
import { EmptyIndicator } from '@tom-ui/ui'
import dayjs from 'dayjs'

interface Props {
  date?: Date | string | null
}
export const CoolingOrderDate = ({ date }: Props) => {
  return date ? (
    <Stack>
      <Typography variant='body2'>{dayjs(date).format('MMMM DD, YYYY')}</Typography>
      <Typography variant='caption' color='secondary'>
        {dayjs(date).format('hh:mm A')}
      </Typography>
    </Stack>
  ) : (
    EmptyIndicator
  )
}
