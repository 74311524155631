import { Card, Grid, IconButton, MenuItem, Tooltip, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { ContainerHeight, OrderStatus } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { ContainerDamageRecordStep } from '@planning/components'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { useNavigationStore } from '@planning/stores'
import { useTranslate } from '@tolgee/react'
import {
  ColoredLabel,
  ConfirmationDialog,
  EditIcon,
  ThreeDotMenu,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { WarningIcon } from 'modules/ui/src/theme/overrides/CustomIcons'
import { FC } from 'react'
import { IInspectContainerFormData } from '../InspectContainer/InspectContainerForm'
import { InspectRailcarContainer } from './InspectRailcarContainer'
import { InspectRailcarContainerInfo } from './InspectRailcarContainerInfo'

interface IProps {
  containerNumber?: string | null
  isoCode?: string | null
  type?: string | null
  length?: number | null
  height?: ContainerHeight | null
  isEmpty?: boolean | null
  grossWeight?: number | null
  containerTare?: number | null
  imoClasses?: string[] | null
  isDamaged?: boolean | null
  vgm?: number | null

  railcarOrder?: IOrderItem
  upsertedOrder?: IInspectContainerFormData

  onReportShortlandedIssue?: (order: IOrderItem) => void
}

export const InspectRailcarContainerCard: FC<IProps> = observer(
  ({
    containerNumber,
    isoCode,
    type,
    length,
    height,
    isEmpty,
    grossWeight,
    containerTare,
    imoClasses,
    isDamaged,
    railcarOrder,
    upsertedOrder,
    vgm,
    onReportShortlandedIssue,
  }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const navigationStore = useNavigationStore()
    const { tallymanSelectVesselAndOperationViewStore } = usePlanningStore()
    const store = tallymanSelectVesselAndOperationViewStore.railTallyStore

    const deleteContainerDialog = () => (
      <ConfirmationDialog
        open={!!store.containerOrderToBeRemoved}
        title={`${t('deleteContainer', 'Delete container')}?`}
        message={t(
          'containerWillBeDeletedAndYouCannotUndoThisAction',
          `Container ${store.containerOrderToBeRemoved?.containerNumber} will be deleted from the railcar and you cannot undo this action!`,
        )}
        primaryActionText={t('delete', 'Delete')}
        closeLabel={t('cancel', 'Cancel')}
        onClose={() => store.setContainerOrderToBeRemoved()}
        onConfirm={() => {
          store.removeContainerOrder()
          navigationStore.pop()
        }}
      />
    )

    const handleReportDamage = (orderData: IInspectContainerFormData) => {
      navigationStore.push(
        <ContainerDamageRecordStep
          orderData={orderData}
          containerNumber={(railcarOrder?.data ?? upsertedOrder)?.containerNumber}
          onSubmit={(data, damages) => {
            data.damagesReported = damages
            navigationStore.pop()

            handleSubmit(data)
          }}
        />,
      )
    }

    const handleSubmit = (data: IInspectContainerFormData) => {
      const isOrderFulfilled = railcarOrder?.data.status === OrderStatus.Fulfilled
      if (isOrderFulfilled) {
        store.editContainer(data)
      } else {
        store.upsertOrders(data)
      }
      navigationStore.pop()
    }

    return (
      <Grid item xs={12}>
        <Card
          sx={{
            padding: '1rem',
          }}
        >
          <Stack
            direction={'row'}
            spacing={2}
            alignItems={'center'}
            sx={{
              placeContent: 'space-between',
            }}
            mb={theme.customSpacing.s}
          >
            <Stack alignItems='center' gap={theme.customSpacing.s} direction='row'>
              {railcarOrder?.hasNotResolvedIssues && <WarningIcon color='warning' />}
              <Typography variant='h2'>{containerNumber}</Typography>
            </Stack>
            <Stack direction='row' gap={theme.customSpacing.s}>
              {railcarOrder?.hasNotResolvedIssues && (
                <ColoredLabel color='secondary' label='Shortlanded' />
              )}
              {!railcarOrder?.hasNotResolvedIssues && (
                <>
                  <Tooltip title={t('edit', 'Edit')}>
                    <IconButton
                      aria-label='edit'
                      onClick={() => {
                        navigationStore.push(
                          <InspectRailcarContainer
                            order={railcarOrder?.data ?? upsertedOrder}
                            onSubmit={data => {
                              if (data.hasDamage) {
                                handleReportDamage(data)
                              } else {
                                handleSubmit(data)
                              }
                            }}
                            damages={upsertedOrder?.damagesReported}
                            seals={upsertedOrder?.seals}
                            hasSeals={upsertedOrder?.hasSeals}
                            submitButtonText={t('done', 'Done')}
                            onDelete={
                              upsertedOrder
                                ? () => store.setContainerOrderToBeRemoved(upsertedOrder)
                                : undefined
                            }
                            deleteContainerDialog={deleteContainerDialog}
                          />,
                        )
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  {onReportShortlandedIssue && railcarOrder && (
                    <ThreeDotMenu>
                      <MenuItem onClick={() => onReportShortlandedIssue(railcarOrder)}>
                        {t('reportAnIssue', 'Report an issue')}
                      </MenuItem>
                    </ThreeDotMenu>
                  )}
                </>
              )}
            </Stack>
          </Stack>

          <Box alignItems={'center'} mb={theme.customSpacing.s}>
            <InspectRailcarContainerInfo
              isoCode={isoCode}
              type={type}
              length={length}
              height={height}
              isEmpty={isEmpty}
              grossWeight={grossWeight}
              vgm={vgm}
              containerTare={containerTare}
              imoClasses={imoClasses}
              isDamaged={isDamaged}
              hasSeals={upsertedOrder?.hasSeals}
              seals={upsertedOrder?.seals}
              doorDirection={(railcarOrder?.data ?? upsertedOrder)?.doorDirection}
              operationalInstructions={railcarOrder?.data?.operationalInstructions}
            />
          </Box>
        </Card>
      </Grid>
    )
  },
)
