import { CoolingOrderDtoV2 } from '@operations/app/api'

export enum CoolingOrderAlerts {
  PlugInRequired = 'plugInRequired',
  PlugOutRequired = 'plugOutRequired',
  TemperatureCheck = 'temperatureCheck',
  HasError = 'hasError',
  TemperatureDeviation = 'temperatureDeviation',
  HumidityDeviation = 'humidityDeviation',
  VentilationDeviation = 'ventilationDeviation',
  UnpluggedTimeExceeded = 'unpluggedTimeExceeded',
}

export interface CoolingOrderCardDto extends CoolingOrderDtoV2 {
  alerts: CoolingOrderAlerts[]
  lastStatus?: CoolingOrderLastStatusDto
  lastPlugInDateTime?: Date | null
  lastPlugOutDateTime?: Date | null
  nextCheckDateTime?: Date | null
}

export interface CoolingOrderLastStatusDto {
  isPluggedIn: boolean
  temperature?: number | null
  dateTime: string
}
