import { FC, ReactNode, useState } from 'react'

export type FieldboxSelectionGroupController = {
  selectedKey: string
  handleSelect: (key: string) => void
}

type FieldboxSelectionGroupProps = {
  defaultValue?: string
  onSelect?: (key: string) => void
  options: (controller: FieldboxSelectionGroupController) => ReactNode
}

export const FieldboxSelectionGroup: FC<FieldboxSelectionGroupProps> = ({
  defaultValue,
  onSelect,
  options,
}) => {
  const [selected, setSelected] = useState(defaultValue ?? '')

  const handleSelect = (key: string) => {
    setSelected(key)
    onSelect?.(key)
  }

  return <>{options({ selectedKey: selected, handleSelect })}</>
}
