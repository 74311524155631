import { CarrierType, IssueStatus, OrderIssueResolutionType } from '@planning/app/api'
import { ContainerJourneyDataStore } from '@planning/pages/ServiceOrders/Stores/ContainerJourneyDataStore'
import { RailcarTrackPositionItemStore } from '@planning/rt-stores/railTrack/RailcarTrackPositionItemStore'
import { RailVisitItemStore } from '@planning/rt-stores/railVisit/RailVisitItemStore'
import _ from 'lodash'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { IIssueItem } from './IssueItem'
import { IssueItemStore } from './IssueItemStore'

export type IssuesTypeCount = {
  all: number
  unresolved: number
  followUp: number
}

export interface IssueTransportData {
  carrierType?: CarrierType
  carrierVisitId?: number
  railTrackId?: string
  railcarId?: number
  releaseExpired?: string
}

export const requiredTransportDataResolution: OrderIssueResolutionType[] = [
  OrderIssueResolutionType.ChangeInboundTransport,
  OrderIssueResolutionType.ChangeOutboundTransport,
]

// [railcarMissingIssue] TODO: UT this store - filtering, fetching
export class IssuesViewStore {
  issueFilterStatus?: IssueStatus
  issueToBeResolved?: IIssueItem
  carrierNameFilter? = ''
  containerNumberFilter? = ''
  isFilterEnabled = false

  filterByVisitId?: number
  filterByRailcarTrackPositionId?: number

  loadContainerJourneyDataReaction = false

  constructor(
    private issueItemStore: IssueItemStore,
    private containerJourneyDataStore: ContainerJourneyDataStore,
    private railVisitItemStore: RailVisitItemStore,
    private railcarTrackPositionItemStore: RailcarTrackPositionItemStore,
  ) {
    makeObservable(this, {
      issueFilterStatus: observable,
      carrierNameFilter: observable,
      containerNumberFilter: observable,
      isFilterEnabled: observable,
      issueToBeResolved: observable,
      filterByVisitId: observable,
      filterByRailcarTrackPositionId: observable,
      loadContainerJourneyDataReaction: observable,

      dataFilteredByIssueStatus: computed,
      filteredData: computed,
      dataFilteredByVisit: computed,
      issuesTypeCount: computed,
      isFilteredByVisit: computed,
      isFilteredByCarrier: computed,
      isFiltered: computed,
      railVisitFilterName: computed,
      railcarTrackPositionFilterName: computed,

      setIssueToBeResolved: action,
      setContainerNumberFilter: action,
      setIssueFilterStatus: action,
      setCarrierNameFilter: action,
      enableFilters: action,
      setFilterByVisitId: action,
      setFilterByRailCarTrackPositionId: action,
      setLoadContainerJourneyDataReaction: action,
      clearFilters: action,
    })

    reaction(
      () => {
        return {
          loadJourneyData: this.loadContainerJourneyDataReaction,
          ids: this.GetIssueOrderIds(),
        }
      },
      react => {
        if (react.loadJourneyData && react.ids) {
          this.containerJourneyDataStore.fetchByOrderIds(react.ids)
        }
      },
    )
  }

  get railVisitFilterName() {
    return (
      _(this.railVisitItemStore.elements).find(v => v.id === this.filterByVisitId)?.data.name ?? ''
    )
  }

  get railcarTrackPositionFilterName() {
    return (
      _(this.railcarTrackPositionItemStore.elements).find(
        r => r.id === this.filterByRailcarTrackPositionId,
      )?.data.railcarName ?? ''
    )
  }

  GetIssueOrderIds(): number[] {
    return this.issueItemStore.notResolvedItemsWithObjectTypeOrder.map(i => i.data.objectId)
  }

  setIssueToBeResolved(issue?: IIssueItem) {
    this.issueToBeResolved = issue
  }

  setContainerNumberFilter(filter?: string) {
    this.containerNumberFilter = filter
  }

  setCarrierNameFilter(filter?: string) {
    this.carrierNameFilter = filter
  }

  enableFilters(apply: boolean) {
    this.isFilterEnabled = apply
  }

  fetch = async () => {
    await this.issueItemStore.fetch()
    //Workaround: since containerJourneyDataStore is not an ItemStore, the items could be overwrite by other pages that calls different fetch. Here, we need to reload the Container Journey we required when entering the page, (there is a logic build in fetchByOrderIds to avoid fetch if other fetch function not get called).
    //TOLATER: we need a containerJourneyItemStore.
    await this.containerJourneyDataStore.fetchByOrderIds(this.GetIssueOrderIds())
  }

  setIssueFilterStatus(issueFilterType?: IssueStatus) {
    if (this.issueFilterStatus !== issueFilterType) {
      this.issueFilterStatus = issueFilterType
    }
  }

  setFilterByVisitId(visitId?: number) {
    this.filterByVisitId = visitId
  }

  setFilterByRailCarTrackPositionId(railCarTrackPositionId?: number) {
    this.filterByRailcarTrackPositionId = railCarTrackPositionId
  }

  setLoadContainerJourneyDataReaction(value: boolean) {
    this.loadContainerJourneyDataReaction = value
  }

  clearFilters() {
    this.setCarrierNameFilter()
    this.setContainerNumberFilter()
    this.setFilterByVisitId()
    this.setFilterByRailCarTrackPositionId()
    this.enableFilters(false)
  }

  get isFilteredByVisit(): boolean {
    return !!this.filterByVisitId || !!this.filterByRailcarTrackPositionId
  }

  get isFilteredByCarrier(): boolean {
    return this.isFilterEnabled && !!this.carrierNameFilter
  }

  get isFiltered(): boolean {
    return this.isFilteredByCarrier || this.isFilteredByVisit
  }

  get issuesTypeCount(): IssuesTypeCount {
    const items = this.filteredData
    let unresolved = 0
    let followUp = 0

    items.forEach(item => {
      if (item.data.issueStatus === IssueStatus.Unresolved) {
        unresolved++
      } else if (item.data.issueStatus === IssueStatus.FollowUp) {
        followUp++
      }
    })

    return {
      all: items.length,
      unresolved: unresolved,
      followUp: followUp,
    }
  }

  get dataFilteredByIssueStatus(): IIssueItem[] {
    const data = this.filteredData

    const filteredData = this.issueFilterStatus
      ? data.filter(i => i.data.issueStatus === this.issueFilterStatus)
      : data

    return filteredData
  }

  get filteredData(): IIssueItem[] {
    let filteredData = this.dataFilteredByVisit

    // Filter by container number
    if (this.containerNumberFilter) {
      filteredData = filteredData.filter(i => {
        const { containerJourney } = i

        return (
          containerJourney &&
          (containerJourney.inboundOrder?.order?.containerNumber?.includes(
            this.containerNumberFilter!,
          ) ||
            containerJourney.outboundOrder?.order?.containerNumber?.includes(
              this.containerNumberFilter!,
            ))
        )
      })
    }

    // Filter by carrier name
    if (this.carrierNameFilter && this.isFilterEnabled) {
      filteredData = filteredData.filter(i => {
        const { containerJourney } = i
        const filter = this.carrierNameFilter!.toLocaleUpperCase()

        return (
          containerJourney &&
          (containerJourney.inboundOrder?.visit?.identifier?.toLocaleUpperCase().includes(filter) ||
            containerJourney.outboundOrder?.visit?.identifier?.toLocaleUpperCase().includes(filter))
        )
      })
    }

    return filteredData
  }

  get dataFilteredByVisit() {
    let data = this.issueItemStore.notResolvedItemsWithObjectTypeOrder

    // Filter by VisitId
    if (this.filterByVisitId) {
      data = data.filter(i => {
        const { containerJourney } = i

        return (
          containerJourney &&
          (containerJourney.inboundOrder?.visit?.id === this.filterByVisitId ||
            containerJourney.outboundOrder?.visit?.id === this.filterByVisitId)
        )
      })
    }

    // Filter by RailCarTrackPositionId
    if (this.filterByRailcarTrackPositionId) {
      data = data.filter(i => {
        const { containerJourney } = i

        return (
          containerJourney &&
          (containerJourney.inboundOrder?.order?.railcarTrackPositionId ===
            this.filterByRailcarTrackPositionId ||
            containerJourney.outboundOrder?.order?.railcarTrackPositionId ===
              this.filterByRailcarTrackPositionId)
        )
      })
    }

    return data
  }
}
