import { CarrierVisitDirection, CarrierVisitStatus } from '@storage/app/api'
import { CarrierVisitStore } from '@storage/stores/carrier-visit.store'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import CarrierVisitsWidget from './CarrierVisitsWidget'

interface Props {
  carrierVisitStore: CarrierVisitStore
  handlePlan?: (
    carrierVisitId: number,
    handlingDirection: CarrierVisitDirection,
    discriminator: string,
  ) => void
  navigateToCarrierVisitDetails?: (carrierVisitId: number, discriminator: string) => void
}

const CarrierVisitsInOperationWidget = observer(
  ({ carrierVisitStore, handlePlan, navigateToCarrierVisitDetails }: Props) => {
    const { t } = useTranslate()

    const sortedCarrierVisits = carrierVisitStore.sortActiveCarrierVisits(
      CarrierVisitStatus.InOperation,
    )
    const carrierVisitsWithoutTrucks = sortedCarrierVisits.filter(
      carrierVisit => carrierVisit.discriminator !== 'TruckVisit',
    )

    return (
      <CarrierVisitsWidget
        title={t('inOperationCarrierVisits', 'Carrier Visits in operation')}
        carrierVisits={carrierVisitsWithoutTrucks}
        handlePlan={handlePlan}
        navigateToCarrierVisitDetails={navigateToCarrierVisitDetails}
      />
    )
  },
)

export default CarrierVisitsInOperationWidget
