import { withAuthenticationRequired } from '@auth0/auth0-react'
import { GeneralCargoPage } from '@tom-ui/planning'
import {
  GeneralCargoDamage,
  GeneralCargoOrderReservation,
  GeneralCargoOrderReservationDialog,
  OutboundRequestValidationControl,
  generalCargoDamageService,
  useStorageStore,
} from '@tom-ui/storage'
import { OrderResponseDto, OrderStatus } from 'modules/planning/src/app/api'
import { OutboundOrderValidationDto } from 'modules/planning/src/stores/gateControl/ValidateOutboundDto'
import { useCallback } from 'react'
import { OrderDamageDto } from './DamageOrderDto'

export const GeneralCargoOrdersPage = withAuthenticationRequired(() => {
  const renderReservations = (order: OrderResponseDto) => (
    <GeneralCargoOrderReservation
      generalCargoOrderId={order.id}
      quantity={order.plannedCargoAmount ?? 0}
      showActionButton={order.status === OrderStatus.Open}
    />
  )
  const renderDamage = (orderId: number) => <GeneralCargoDamage orderId={orderId} />

  const { tenantConfigStore } = useStorageStore()

  const handleGetOrdersDamageAmount = useCallback(async (orderIds: number[]) => {
    const damages = await generalCargoDamageService.getOrderDamageAmount(orderIds)

    return damages.map(
      x =>
        ({
          orderId: x.orderId,
          amount: x.totalQuantity,
        }) as OrderDamageDto,
    )
  }, [])

  const validateOutboundRequest = (params: OutboundOrderValidationDto): React.ReactElement => (
    <OutboundRequestValidationControl
      isOutbound={params.isOutbound}
      customerId={params.customerId}
      commodityId={params.commodityId}
      lotNumber={params.lotNumber}
      packageId={params.packageId}
      quantity={params.quantity}
      unitIds={params.unitIds}
      imoClasses={params.imoClasses}
    />
  )

  return (
    <>
      <GeneralCargoPage
        renderReservations={tenantConfigStore.skipYardPlanning ? undefined : renderReservations}
        renderDamage={renderDamage}
        getOrderDamagesAmountFunc={handleGetOrdersDamageAmount}
        validateOutboundRequest={validateOutboundRequest}
      />
      <GeneralCargoOrderReservationDialog />
    </>
  )
})
