import {
  CarrierType,
  CarrierVisitDirection,
  OrderResponseDto,
  RailVisitResponseDto,
  TruckDto,
  TruckVisitDto,
  VesselVisitDto,
} from '@planning/app/api'
import { IListStore } from '@planning/components/list/SimpleListStore'
import { groupById } from '@planning/rt-stores/helpers'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { OrderItemStore } from '@planning/rt-stores/order/OrderItemStore'
import { IRailVisitItem } from '@planning/rt-stores/railVisit/RailVisitItem'
import { TruckItemStore } from '@planning/rt-stores/truck/TruckItemStore'
import { IEntityMap } from '@planning/rt-stores/types'
import { VesselItem } from '@planning/rt-stores/vessel/VesselItem'
import { VesselItemStore } from '@planning/rt-stores/vessel/VesselItemStore'
import { IDirectionData } from '@planning/rt-stores/vesselVisit/VesselVisitItem'
import { truckVisitService, vesselVisitService } from '@planning/services'
import railVisitService from '@planning/services/railVisitService'
import _ from 'lodash'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { ContainerVisit, ContainerVisitOrderItem, ITruckVisitItem } from './ContainerVisitItem'

export interface IContainerVisitAccumulator {
  containerVisits: ContainerVisit[]
  storedItem?: IOrderItem
}

export class OrderListStore implements IListStore<ContainerVisit> {
  filter = ''
  isLoading = false
  vesselVisits: IEntityMap<VesselVisitDto> = {}
  truckVisits: IEntityMap<TruckVisitDto> = {}
  railVisits: IEntityMap<RailVisitResponseDto> = {}

  constructor(
    private orderItemStore: OrderItemStore,
    private vesselItemStore: VesselItemStore,
    private truckItemStore: TruckItemStore,
  ) {
    makeObservable(this, {
      filter: observable,
      isLoading: observable,
      vesselVisits: observable,
      truckVisits: observable,
      railVisits: observable,
      items: computed,
      isNoMatches: computed,
      setFilter: action,
      setVesselVisits: action,
      setTruckVisits: action,
      reset: action,
      setIsLoading: action,
    })

    reaction(
      () =>
        _(
          this.orderItemStore.ordersByContainerNumber[this.filter]?.map(item => {
            return {
              visitId: item.data.carrierVisitId ?? 0,
              visitType: item.data.carrierVisitType ?? CarrierType.Universal,
            }
          }),
        )
          .filter(x => !!x.visitId && x.visitType !== CarrierType.Universal)
          .uniq()
          .value(),

      this.fetchAdjacentData,
    )
  }

  fetch = (filter: string) => Promise.resolve()

  fetchAdjacentData = async (item: { visitId: number; visitType: CarrierType }[]) => {
    this.setIsLoading(true)
    if (item) {
      const groupedVisits = _(item)
        .groupBy(o => o.visitType)
        .value()

      const vvIds = _.uniq(
        (groupedVisits[CarrierType.Vessel] ?? []).map(o => o.visitId).filter(id => id > 0),
      )
      if (vvIds.length) {
        try {
          const { data: visits } = await vesselVisitService.getByIds(vvIds)
          this.setVesselVisits(visits)

          const ids = _(visits)
            .map(o => o.carrierIds ?? 0)
            .uniq()
            .flatten()
            .filter(id => id > 0)
            .value()
          await this.vesselItemStore.fetchByIds(ids)
        } catch (error) {
          console.log(error)
        }
      }

      const tIds = _.uniq(
        (groupedVisits[CarrierType.Truck] ?? []).map(o => o.visitId).filter(id => id > 0),
      )
      if (tIds.length) {
        try {
          const { data: visits } = await truckVisitService.getByIds(tIds)
          this.setTruckVisits(visits)

          const ids = _(visits)
            .map(o => o.carrierIds ?? 0)
            .flatten()
            .uniq()
            .filter(id => id > 0)
            .value()
          await this.truckItemStore.fetchByIds(ids)
        } catch (error) {
          console.log(error)
        }
      }

      const rIds = _.uniq(
        (groupedVisits[CarrierType.Train] ?? []).map(o => o.visitId).filter(id => id > 0),
      )
      if (rIds.length) {
        try {
          const visits = await railVisitService.getByIds(rIds)
          this.setRailVisits(visits)
        } catch (error) {
          console.log(error)
        }
      }
    }

    this.setIsLoading(false)
  }

  setVesselVisits = (visits: VesselVisitDto[]) => {
    this.vesselVisits = groupById(visits)
  }

  setTruckVisits = (visit: TruckVisitDto[]) => {
    this.truckVisits = groupById(visit)
  }

  setRailVisits = (visit: RailVisitResponseDto[]) => {
    this.railVisits = groupById(visit)
  }

  get items() {
    const res: ContainerVisit[] = []
    const grp = this.orderItemStore.activeOrdersByContainerNumber[this.filter]
    if (grp) {
      const grp2 = _(grp)
        .groupBy(order => {
          const sorted = _.sortBy([order.id, order.data.linkedOrderId ?? -1])

          const key = `${sorted[0]}_${sorted[1]}`
          return key
        })
        .value()

      _.keys(grp2).forEach(key => {
        const v: ContainerVisit = {} as ContainerVisit
        const inbound = grp2[key].filter(x => x.data.direction === CarrierVisitDirection.Inbound)[0]
          ?.data
        const outbound = grp2[key].filter(
          x => x.data.direction === CarrierVisitDirection.Outbound,
        )[0]?.data

        if (inbound) {
          if (inbound.carrierVisitType === CarrierType.Vessel) {
            v[0] = this.createVesselOrderItem(
              inbound,
              this.vesselVisits[inbound.carrierVisitId ?? 0],
              this.vesselVisits[inbound.carrierVisitId ?? 0]
                ? _.get(
                    this.vesselItemStore.elements,
                    this.vesselVisits[inbound.carrierVisitId ?? 0].carrierIds,
                  )
                : undefined,
            )
          } else if (inbound.carrierVisitType === CarrierType.Truck) {
            v[0] = this.createTruckOrderItem(
              inbound,
              this.truckVisits[inbound.carrierVisitId ?? 0],
              this.truckVisits[inbound.carrierVisitId ?? 0]
                ? _.get(
                    this.truckItemStore.elements,
                    this.truckVisits[inbound.carrierVisitId ?? 0].carrierIds,
                  )
                : undefined,
            )
          } else if (inbound.carrierVisitType === CarrierType.Train) {
            v[0] = this.createRailOrderItem(inbound, this.railVisits[inbound.carrierVisitId ?? 0])
          }
        }

        if (outbound) {
          if (outbound.carrierVisitType === CarrierType.Vessel) {
            v[1] = this.createVesselOrderItem(
              outbound,
              this.vesselVisits[outbound.carrierVisitId ?? 0],
              this.vesselVisits[outbound.carrierVisitId ?? 0]
                ? _.get(
                    this.vesselItemStore.elements,
                    this.vesselVisits[outbound.carrierVisitId ?? 0].carrierIds,
                  )
                : undefined,
            )
          } else if (outbound.carrierVisitType === CarrierType.Truck) {
            v[1] = this.createTruckOrderItem(
              outbound,
              this.truckVisits[outbound.carrierVisitId ?? 0],
              this.truckVisits[outbound.carrierVisitId ?? 0]
                ? _.get(
                    this.truckItemStore.elements,
                    this.truckVisits[outbound.carrierVisitId ?? 0].carrierIds,
                  )
                : undefined,
            )
          } else if (outbound.carrierVisitType === CarrierType.Train) {
            v[1] = this.createRailOrderItem(outbound, this.railVisits[outbound.carrierVisitId ?? 0])
          }
        }

        res.push(v)
      })
    }

    return res
  }

  get isNoMatches() {
    return !!this.filter && !this.items.length
  }

  // todo: sorting by eta
  // sortByNumber: ISortDelegate<ContainerVisit> = (_, a, b) => {
  //   const containerNumber1 = this.getContainerNumber(a)
  //   const containerNumber2 = this.getContainerNumber(b)

  //   return (containerNumber1 ?? '').localeCompare(containerNumber2 ?? '')
  // }

  setFilter = (filter: string) => {
    this.filter = filter
  }

  reset = () => {
    this.setFilter('')

    this.vesselVisits = {}
    this.truckVisits = {}
    this.railVisits = {}
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  createRailOrderItem = (order: OrderResponseDto, railVisit?: RailVisitResponseDto) => {
    return {
      id: order.id,
      data: order,
      visit: !railVisit
        ? undefined
        : ({
            id: railVisit.id,
            data: railVisit,
            orders: [] as IOrderItem[],
            discharge: {} as IDirectionData,
            load: {} as IDirectionData,
            arrival: railVisit.ata ?? railVisit.eta,
            departure: railVisit.atd ?? railVisit.etd,
            lastUpdated: 0,
            update: (): boolean => true,
            getCount: () => 0, // TODO: Check why this is needed
            railTracks: [],
            railcarTrackPositions: [],
            hasOperationStarted: false,
            hasOpenOrders: false,
            inboundOpenOrders: [],
            outboundOpenOrders: [],
          } as IRailVisitItem),
    } as ContainerVisitOrderItem
  }

  createVesselOrderItem = (
    order: OrderResponseDto,
    vesselVisit?: VesselVisitDto,
    vessel?: VesselItem,
  ) => {
    return {
      id: order.id,
      data: order,
      visit: !vesselVisit
        ? undefined
        : {
            id: vesselVisit.id,
            vessel: vessel,
            data: vesselVisit,
            arrival: vesselVisit.ata ?? vesselVisit.eta,
            departure: vesselVisit.atd ?? vesselVisit.etd,
            lastUpdated: 0,
            update: (): boolean => true,
          },
    } as ContainerVisitOrderItem
  }

  createTruckOrderItem = (
    order: OrderResponseDto,
    truckVisit?: TruckVisitDto,
    truck?: TruckDto,
  ) => {
    return {
      id: order.id,
      data: order,
      visit: !truckVisit
        ? undefined
        : ({
            id: truckVisit.id,
            data: truckVisit,
            trucks: [truck],
            arrival: truckVisit.ata ?? truckVisit.eta,
            departure: truckVisit.atd ?? truckVisit.etd,
          } as ITruckVisitItem),
    } as ContainerVisitOrderItem
  }
}
