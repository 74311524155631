import { CoolingOrderAlerts } from '../../models/cooling-order-model'

export interface ReeferMonitoringFilter {
  yardBlockIds?: string[] | null
  customerIds?: string[] | null
  inboundCarrierType?: string[] | null
  outboundCarrierType?: string[] | null
  isPluggedIn?: PlugInStatus[] | null
  status?: CoolingOrderAlerts[] | null
}

export const defaultValues: ReeferMonitoringFilter = {
  yardBlockIds: null,
  customerIds: null,
  inboundCarrierType: [],
  outboundCarrierType: [],
  isPluggedIn: [],
}

export enum PlugInStatus {
  PluggedIn = 'pluggedIn',
  PluggedOut = 'pluggedOut',
}
