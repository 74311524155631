import {
  CarrierVisitAllocationRuleDto,
  ContainerTurnoverDto,
  CreateAllocationRuleDto,
  GlobalAllocationRuleDto,
} from '@storage/app/api'

export const mapAllocationRuleDtoToCreateAllocationRuleDto = (
  { name, facets, destinationAndStackingStrategies }: CarrierVisitAllocationRuleDto,
  rulePosition: number,
  containerTurnovers: ContainerTurnoverDto[],
): CreateAllocationRuleDto => {
  if (facets.containerNumber && facets.containerNumber !== '') {
    return {
      name,
      facets: {
        containerNumber: facets.containerNumber,
        weightClasses: [],
        inboundCarrierType: facets.inboundCarrierType,
        outboundCarrierType: facets.outboundCarrierType,
      },
      destinationAndStackingStrategies: destinationAndStackingStrategies,
      rulePosition,
      containerTurnovers,
    }
  }
  return {
    name,
    facets,
    destinationAndStackingStrategies: destinationAndStackingStrategies,
    rulePosition,
    containerTurnovers,
  }
}

export const mapGlobalAllocationRuleDtoToCarrierVisitAllocationRuleDto = (
  globalAllocationRule: GlobalAllocationRuleDto,
): CarrierVisitAllocationRuleDto => {
  return {
    id: globalAllocationRule.id,
    name: globalAllocationRule.name,
    facets: globalAllocationRule.facets,
    destinationAndStackingStrategies: globalAllocationRule.destinationAndStackingStrategies,
  }
}
