import { Box, Button, Stack, Typography } from '@mui/material'
import { IssueResponseDto } from '@planning/app/api'
import { ContainerVisitsCard } from '@planning/pages/Order/components/ContainerVisitsCard/ContainerVisitsCard'
import { ContainerJourney } from '@planning/pages/ServiceOrders/Stores/ContainerJourneyDataStore'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { IssueStatusChip } from '../atoms/IssueStatusChip'

type Props = {
  containerVisit?: ContainerJourney
  issue: IssueResponseDto
  onClickResolve: () => void
}

export const IssueCard: FC<Props> = observer(({ containerVisit, onClickResolve, issue }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  if (!containerVisit || !issue) return <></>

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.customRadius.medium,
      }}
    >
      <Box sx={{ padding: '1rem', display: 'flex' }}>
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h4'>{t(issue.issueType)}</Typography>
          <Stack direction='row' spacing={1} alignItems='center'>
            <IssueStatusChip status={issue.issueStatus} />
            <Button size='medium' variant='contained' onClick={() => onClickResolve()}>
              {t('resolve', 'Resolve')}
            </Button>
          </Stack>
        </Stack>
      </Box>

      <ContainerVisitsCard containerVisit={containerVisit} announcedVisits={[]} isInsideIssueCard />
    </Box>
  )
})
