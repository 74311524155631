import { Button, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { IssueType } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface IProps {
  value: IssueType
  icon?: JSX.Element
  selected: boolean
  onSelect: (issue: IssueType) => void
}

export const ReportIssueTypeOptionButton: FC<IProps> = observer(
  ({ value, icon, selected, onSelect }) => {
    const theme = useMinimalsTheme()

    const { t } = useTranslate()
    return (
      <Button
        color='primary'
        variant='outlined'
        fullWidth
        sx={{
          backgroundColor: selected ? theme.palette.primary.lighter : undefined,
          borderColor: selected ? undefined : theme.palette.grey[300],
        }}
        onClick={() => {
          onSelect(value)
        }}
      >
        <Stack direction='column' alignItems='center' p={theme.customSpacing.l}>
          {icon}
          <Typography
            variant={selected ? 'button' : 'body2'}
            color={selected ? theme.palette.primary.darker : 'secondary'}
          >
            {t(value)}
          </Typography>
        </Stack>
      </Button>
    )
  },
)
