import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { OrderResponseDto } from '@planning/app/api'
import { DamagedContainerIcon } from '@planning/components/DamagedContainerIcon'
import { OrderItemChip } from '@planning/pages/Order/components/OrderItemChip'
import { useTranslate } from '@tolgee/react'
import { DangerousIcon, ReeferIcon } from '@tom-ui/ui'
import { FC } from 'react'

interface IProps {
  order: OrderResponseDto
}

export const OrderItemChips: FC<IProps> = ({ order }) => {
  const { t } = useTranslate()

  return (
    <Stack direction={'row'} spacing={0.5}>
      {order.containerIsoCode && <OrderItemChip label={`${order.containerIsoCode}`} />}
      {!!order.containerHeight && <OrderItemChip label={`${order.containerHeight}`} />}
      <OrderItemChip label={(!!order.isEmpty && 'E') || 'F'} />
      {order.containerType === 'Reefer' && (
        <OrderItemChip
          label={
            <Typography
              display={'flex'}
              gap={'4px'}
              fontSize={'14px'}
              sx={{ alignItems: 'center' }}
              component={'div'}
              variant='body2'
            >
              <ReeferIcon sx={{ height: '16px', width: '16px' }} />
            </Typography>
          }
        />
      )}
      {order.imoClasses.length > 0 && (
        <OrderItemChip
          label={
            <Typography
              display={'flex'}
              gap={'4px'}
              fontSize={'14px'}
              sx={{ alignItems: 'center' }}
              component={'div'}
              variant='body2'
            >
              <DangerousIcon sx={{ height: '16px', width: '16px', marginRight: '4px' }} />
              {order.imoClasses.join(', ')}
            </Typography>
          }
        ></OrderItemChip>
      )}
      {!!order.content && <OrderItemChip label={order.content}></OrderItemChip>}
      {!!order.grossWeight && (
        <OrderItemChip
          label={`${t('gwt', 'GWT')}: ${order.grossWeight.toLocaleString(undefined, { maximumFractionDigits: 0 })}kg`}
        />
      )}
      {!!order.vgm && (
        <OrderItemChip
          label={`${t('vgm', 'VGM')}: ${order.vgm.toLocaleString(undefined, { maximumFractionDigits: 0 })}kg`}
        />
      )}
      {!!order.consignee && <OrderItemChip label={order.consignee}></OrderItemChip>}
      {!!order.isDamaged && (
        <DamagedContainerIcon
          iconHeight={'16px'}
          iconWeight={'16px'}
          sx={{
            borderRadius: '2px',
            background: '#919eab29',
          }}
        />
      )}
    </Stack>
  )
}
