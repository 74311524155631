import { Box, Stack, TextField } from '@mui/material'
import { InitializationWrapper } from '@planning/components'
import { useWrappedLocalPagination } from '@planning/stores'
import { useTranslate } from '@tolgee/react'
import { Header, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { BookingsTable } from './Components/organisms/BookingsTable'
import { BookingsViewStore } from './Stores/BookingsViewStore'

export const BookingsPage = observer(() => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const bookingsViewStore = useMemo(() => new BookingsViewStore(), [])

  useEffect(() => {
    bookingsViewStore.fetch()
  }, [bookingsViewStore])

  const bookingsListViewStore = useWrappedLocalPagination(
    () => (bookingsViewStore.filteredData ? bookingsViewStore.filteredData : []),
    () => bookingsViewStore.fetch(),
  )

  // TODO VG: fix pagination

  return (
    <InitializationWrapper isInitializing={!bookingsViewStore.firstLoad}>
      <Box
        sx={{
          height: '100%',
          bgcolor: theme.palette.grey[100],
        }}
      >
        <Header
          title={t('Bookings', 'Bookings')}
          rightRenderOption={
            <Stack flexDirection='row' alignItems='center' gap={2}>
              <Box width='300px'>
                <TextField
                  label={t('searchBookingNumber', 'Search for booking no. or vessel name')}
                  sx={{ width: '100%', marginTop: 0 }}
                  data-cy='booking-page-search-field'
                  value={bookingsViewStore.filter}
                  onChange={event => {
                    bookingsViewStore.setFilter(event.currentTarget.value)
                  }}
                />
              </Box>
            </Stack>
          }
        />

        <Box sx={{ padding: theme.customSpacing.l }}>
          <BookingsTable store={bookingsListViewStore} />
        </Box>
      </Box>
    </InitializationWrapper>
  )
})
