import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  SelectProps,
  SxProps,
  Typography,
} from '@mui/material'
import { SelectOption } from '@storage/app/models'
import { useTranslate } from '@tolgee/react'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'
import { AllTranslationKey, AllValue } from './constants'

type FieldProps = Pick<SelectProps, 'disabled' | 'margin'>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  allOption?: boolean
  formState: FormState<T>
  label: string
  labelWidth?: string | number
  fullWidth?: boolean
  options: SelectOption[]
  formControlSx?: SxProps
  autoFocus?: boolean
  optionsOnColumnOrientation?: boolean
}

export const ControlledRadioGroup = <T extends FieldValues>({
  name,
  control,
  allOption,
  label,
  labelWidth,
  margin,
  fullWidth,
  options,
  formState: { errors },
  formControlSx,
  optionsOnColumnOrientation,
}: Props<T>) => {
  const { t } = useTranslate()

  return (
    <FormControl margin={margin} sx={formControlSx} fullWidth={fullWidth}>
      <Typography variant='body1' sx={{ width: labelWidth }}>
        {t(label, label)}
      </Typography>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup row={!optionsOnColumnOrientation} {...field} value={field.value ?? AllValue}>
            {allOption && (
              <FormControlLabel
                key={AllValue}
                value={AllValue}
                label={t(AllTranslationKey, 'All')}
                control={<Radio />}
              />
            )}
            {options.map(({ value, label }) => (
              <FormControlLabel
                key={label}
                value={value}
                label={t(label, label)}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        )}
      />
      {errors[name] && (
        <FormHelperText error={!!errors[name]}>{errors[name]?.message as string}</FormHelperText>
      )}
    </FormControl>
  )
}
