import {
  CarrierType,
  CarrierVisitDirection,
  DoorDirection,
  RailcarTrackPositionResponseDto,
  RailTrackResponseDto,
  RestowDto,
  StowagePosition,
  UnitListDto,
  UnitType,
} from '@planning/app/api'
import { BaplieParserApi } from '@planning/app/baplie-parser-api/baplie-parser-api'
import { createApiClient } from '@planning/app/http-client'
import { IOrderDto, IOrderList } from '@planning/pages/Order/stores/OrderListUploadViewStoreV2'
import { IRailcarTrackPositionItem } from '@planning/rt-stores/railTrack/RailcarTrackPositionItem'
import { IRailVisitItem } from '@planning/rt-stores/railVisit/RailVisitItem'
import { IEntityMap } from '@planning/rt-stores/types'
import { formatDateTime } from '@planning/utils'
import { unformatRailcarName } from '@planning/utils/railcar-utils'
import CSVFileValidator, { FieldSchema, ParsedResults, RowError } from 'csv-file-validator'
import dayjs from 'dayjs'
import moment from 'moment'
import Papa from 'papaparse'
import containerService from './containerService'

export class FileFormatError extends Error {
  constructor(msg: string) {
    super(msg)

    Object.setPrototypeOf(this, FileFormatError.prototype)
  }
}

export class DuplicateRailcarsWithDifferentRailTracksError extends Error {
  constructor(public railcars: string[]) {
    super(`Duplicate railcars with different rail tracks`)

    Object.setPrototypeOf(this, DuplicateRailcarsWithDifferentRailTracksError.prototype)
  }
}
export class DuplicateRailcarsWithDifferentSequenceError extends Error {
  constructor(public railcars: string[]) {
    super(`Duplicate rail cars with different sequence`)

    Object.setPrototypeOf(this, DuplicateRailcarsWithDifferentSequenceError.prototype)
  }
}
export class DuplicateSequencesWithDifferentRailcarsError extends Error {
  constructor(public lineNumbers: number[]) {
    super(`Duplicate sequences with different railcars`)

    Object.setPrototypeOf(this, DuplicateSequencesWithDifferentRailcarsError.prototype)
  }
}

export class IsoCodeValidationError extends Error {
  constructor(public isoCodes: string[]) {
    super(`Found invalid IsoCodes`)

    Object.setPrototypeOf(this, IsoCodeValidationError.prototype)
  }
}

export class MissingRailtrackError extends Error {
  constructor(public railcars: string[]) {
    super(`Found railcars with missing rail track`)

    Object.setPrototypeOf(this, MissingRailtrackError.prototype)
  }
}

export class RailcarSequenceConflict extends Error {
  constructor(public mismatchedRailcars: string[]) {
    super(`Found mismatched railcars/waggons for the same sequence and track`)
    Object.setPrototypeOf(this, RailcarSequenceConflict.prototype)
  }
}

export class InvalidRailtrackError extends Error {
  constructor(public railTracks: string[]) {
    super(
      `Found railcars with invalid tracks. Either use the tracks assigned to the visit or assign the missing track`,
    )

    Object.setPrototypeOf(this, InvalidRailtrackError.prototype)
  }
}

export class NotPlannedRailtrackError extends Error {
  constructor(public railTracks: string[]) {
    super(
      `Found railcars with invalid tracks. Either use the tracks assigned to the visit or assign the missing track`,
    )

    Object.setPrototypeOf(this, NotPlannedRailtrackError.prototype)
  }
}

const csvHeadersConfigBase = [
  {
    name: 'Reference Number',
    inputName: 'referenceNumber',
    required: true,
  },
  {
    name: 'Container Number',
    inputName: 'containerNumber',
    required: true,
  },
  {
    name: 'IsoCode',
    inputName: 'isoCode',
  },
  {
    name: 'Gross Weight',
    inputName: 'grossWeight',
  },
  {
    name: 'ImoClasses',
    inputName: 'imoClasses',
  },
  {
    name: 'IsEmpty',
    inputName: 'isEmpty',
  },
  {
    name: 'PortOfLoading',
    inputName: 'portOfLoading',
  },
  {
    name: 'PortOfDischarge',
    inputName: 'portOfDischarge',
  },
  {
    name: 'Operator',
    inputName: 'operator',
  },
  {
    name: 'Temperature',
    inputName: 'temperature',
  },
  {
    name: 'TypeCode',
    inputName: 'typeCode',
  },
  {
    name: 'FinalDestination',
    inputName: 'finalDestination',
  },
  {
    name: 'UnNumber',
    inputName: 'unNumber',
  },
  {
    name: 'Consignee',
    inputName: 'consignee',
  },
  {
    name: 'AtConsignee',
    inputName: 'atConsignee',
  },
  {
    name: 'Notes',
    inputName: 'notes',
  },
  {
    name: 'Content',
    inputName: 'content',
  },
  { name: 'HasSeals', inputName: 'hasSeals' },
  { name: 'Seals', inputName: 'seals' },
  { name: 'VGM', inputName: 'vgm' },
]

const csvFieldSchemaMap = new Map<CarrierType, FieldSchema[] | undefined>([
  [CarrierType.Vessel, [...csvHeadersConfigBase]],
  [CarrierType.Train, [...csvHeadersConfigBase]],
  [CarrierType.Truck, undefined],
  [CarrierType.Universal, undefined],
])

const getOptionalFieldSchema = (
  visitType: CarrierType,
  handlingDirection: CarrierVisitDirection,
  hasDoorDirection: boolean,
  hasOperationalInstructions: boolean,
  hasOrderVisitPosition: boolean,
  hasUnitType: boolean,
  skipTallyLoadPrepFF?: boolean,
) => {
  const fieldSchema: FieldSchema[] = []

  if (
    hasDoorDirection &&
    visitType === CarrierType.Train &&
    handlingDirection === CarrierVisitDirection.Outbound
  ) {
    fieldSchema.push({ name: 'DoorDirection', inputName: 'doorDirection' })
  }

  if (hasOperationalInstructions) {
    fieldSchema.push({ name: 'OperationalInstructions', inputName: 'operationalInstructions' })
  }

  if (hasUnitType) {
    fieldSchema.push({ name: 'UnitType', inputName: 'unitType' })
  }

  if (visitType === CarrierType.Train) {
    if (
      handlingDirection === CarrierVisitDirection.Inbound ||
      hasOrderVisitPosition ||
      skipTallyLoadPrepFF === false
    ) {
      fieldSchema.push(
        { name: 'Waggon', inputName: 'waggon' },
        { name: 'Sequence', inputName: 'sequence' },
        { name: 'Track', inputName: 'track' },
      )
    }
  }

  return fieldSchema
}

export interface IOrderListCsvData {
  referenceNumber: string
  containerNumber: string
  grossWeight?: number
  isEmpty?: string
  portOfLoading?: string
  portOfDischarge?: string
  operator?: string
  imoClasses?: string
  temperature?: string
  typeCode?: string
  finalDestination?: string
  unNumber?: string
  consignee?: string
  atConsignee?: string
  notes?: string
  operationalInstructions?: string
  content?: string
  waggon?: string
  sequence?: string
  track?: string
  doorDirection?: string
  hasSeals?: string
  seals?: string
  vgm?: number
  unitType: string
}

class OrderListParsingService {
  private baplieParserClient = createApiClient(BaplieParserApi)

  private booleanTrueString = 'TRUE'
  private convertBool = (str?: string) => str === this.booleanTrueString

  private static parseDoorDirection = (str?: string | null): DoorDirection => {
    switch (str) {
      case DoorDirection.Inward:
        return DoorDirection.Inward
      case DoorDirection.Outward:
        return DoorDirection.Outward
      default:
        return DoorDirection.Anyway
    }
  }

  private static parseUnitType = (str: string): UnitType => {
    switch (str) {
      case UnitType.Container:
        return UnitType.Container
      case UnitType.SwapBody:
        return UnitType.SwapBody
      case UnitType.Trailer:
        return UnitType.Trailer
      default:
        return UnitType.Container
    }
  }

  private readonly splitStringIntoArray = (str?: string) =>
    str?.split('/').filter((str: string) => str !== '') ?? []

  private cleanUpAndSortParsedResults = (data: any, headerConfig: any) => {
    const schema = headerConfig
    const dataArr: { [key: string]: any }[] = []

    data.forEach((d: any) => {
      const objectKeys = Object.keys(d).filter(key =>
        schema.some((field: { name: string }) => field.name === key),
      )

      objectKeys.sort((a, b) => {
        const indexA = schema.findIndex((field: { name: string }) => field.name === a)
        const indexB = schema.findIndex((field: { name: string }) => field.name === b)
        return indexA - indexB
      })

      const sortedObject: {
        [key: string]: any
      } = {}

      objectKeys.forEach(key => {
        const fieldName = schema.find((field: { name: string }) => field.name === key)?.name
        if (fieldName) {
          sortedObject[fieldName] = d[key as keyof typeof d]
        }
      })

      dataArr.push(sortedObject)
    })

    return dataArr
  }

  private async parseData(upload: File) {
    return await new Promise((resolve, reject) => {
      try {
        Papa.parse(upload, {
          dynamicTyping: true,
          header: true,
          skipEmptyLines: true,
          complete: r => {
            resolve(r.data)
          },
        })
      } catch (e) {
        reject(e)
      }
    })
  }

  parseCsv = async (
    visitId: number,
    handlingDirection: CarrierVisitDirection,
    upload: File,
    visitType: CarrierType,
    skipTallyLoadPrepFF: boolean,
    railTrackNameMap?: Map<string, RailTrackResponseDto>,
    visitRailTrackIds?: string[] | null,
    relatedRailcars?: RailcarTrackPositionResponseDto[],
  ) => {
    let fieldSchema = csvFieldSchemaMap.get(visitType)

    if (!fieldSchema) throw new Error(`System does not support ${visitType}`)

    const data = (await this.parseData(upload)) as [any]

    const optionalFieldSchema = getOptionalFieldSchema(
      visitType,
      handlingDirection,
      !!data.filter(o => o.DoorDirection).length,
      !!data.filter(o => o.OperationalInstructions).length,
      !!data.filter(o => o.Waggon || o.Track || o.Sequence).length,
      !!data.filter(o => o.UnitType).length,
      skipTallyLoadPrepFF,
    )

    fieldSchema = [...fieldSchema, ...optionalFieldSchema]

    const sortedAndCleanedData = this.cleanUpAndSortParsedResults(data, fieldSchema)
    const formattedCsv = Papa.unparse(sortedAndCleanedData)

    const csvData: ParsedResults<IOrderListCsvData, RowError> = await CSVFileValidator(
      formattedCsv,
      { headers: fieldSchema },
    )

    if (csvData.inValidData.length > 0) {
      const invalid = csvData.inValidData.pop()

      if (invalid?.message.toLowerCase().includes('Number of fields mismatch'.toLowerCase())) {
        const missingFields: string[] = []
        const invalidRow = sortedAndCleanedData[invalid?.rowIndex ? invalid.rowIndex - 1 : 0]
        fieldSchema
          .map(fs => fs.name)
          .forEach(fieldName => {
            if (invalidRow[fieldName] === undefined) {
              missingFields.push(fieldName)
            }
          })

        if (missingFields.length) {
          throw new FileFormatError(
            `row: ${invalid?.rowIndex}${invalid?.columnIndex ? `, column: ${invalid.columnIndex}` : ''}, message: Missing columns ${missingFields.join(', ')}`,
          )
        }
      }

      throw new FileFormatError(
        `row: ${invalid?.rowIndex}, column: ${invalid?.columnIndex}, message: ${invalid?.message}`,
      )
    }

    const orderList: IOrderList = {
      carrierVisitId: visitId,
      direction: handlingDirection,
      orders: csvData.data.map(row => {
        const railTrackId = row.track
          ? railTrackNameMap?.get(row.track.toLocaleLowerCase())?.id ?? '-1'
          : undefined

        const seals = this.splitStringIntoArray(row.seals)
        const imoClasses = this.splitStringIntoArray(row.imoClasses)
        const doorDirection =
          !row.doorDirection || row.doorDirection.trim() === '' ? null : row.doorDirection

        return {
          ...row,
          grossWeight: Number(row.grossWeight),
          portOfLoading: row.portOfLoading,
          portOfDischarge: row.portOfDischarge,
          isEmpty: this.convertBool(row.isEmpty?.toLocaleUpperCase()),
          operator: row.operator,
          temperature: row.temperature,
          imoClasses: imoClasses,
          atConsignee:
            row.atConsignee !== ''
              ? dayjs(row.atConsignee, ['DD.MM.YYYY HH:mm:ss', 'DD.MM.YYYY']).toISOString()
              : null,
          waggon: row.waggon ? unformatRailcarName(row.waggon) : undefined,
          sequence: row.sequence ? Number(row.sequence) : undefined,
          railTrackId: railTrackId,
          railTrack: row.track,
          doorDirection: OrderListParsingService.parseDoorDirection(doorDirection),
          hasSeals: this.convertBool(row.hasSeals?.toLocaleUpperCase()),
          seals: seals,
          vgm: Number(row.vgm) ?? null,
          unitType: OrderListParsingService.parseUnitType(row.unitType),
        }
      }),
    }

    let warningMessages: string[] = []

    if (orderList.orders) {
      await this.verifyIsoCodes(orderList.orders)

      if (visitType === CarrierType.Train) {
        this.verifyMissingRailTrack(orderList.orders)
        this.verifyInvalidRailTracks(orderList.orders)
        this.verifyRailcarConsistency(orderList.orders, relatedRailcars)
        this.verifyNotPlannedRailTracks(orderList.orders, visitRailTrackIds)
        this.verifyDuplicateRailcarsWithDifferentRailTracks(orderList.orders)
        this.verifyDuplictedRailcarsWithDifferentSequence(orderList.orders)
        this.verifyDuplicateSequencesWithDifferentRailcars(orderList.orders)

        if (!skipTallyLoadPrepFF) {
          this.validateMissingSequence(orderList.orders)
          warningMessages = this.validateRailWarningMessages(orderList.orders)
        }
      }
    }

    return {
      orderList,
      warningMessages,
    }
  }

  parseRestowCsv = async (upload: File): Promise<RestowDto[]> => {
    const data = (await this.parseData(upload)) as [any]

    const requiredFields = ['containerNumber', 'position']
    const missingFields = requiredFields.filter(field => !data[0] || data[0][field] === undefined)

    if (missingFields.length > 0) {
      throw new FileFormatError(`Missing required columns: ${missingFields.join(', ')}`)
    }

    const restowData: RestowDto[] = data.map(row => ({
      containerNumber: row.containerNumber,
      destination: this.parseContainerPosition(String(row.position).padStart(6, '0')),
    }))

    const invalidRows = restowData.filter(r => !r.containerNumber || !r.destination)

    if (invalidRows.length > 0) {
      throw new FileFormatError(
        `Invalid rows detected. Ensure all rows have containerNumber, ReferenceNumber, and Position.`,
      )
    }

    return restowData
  }

  parseBaplie = async (
    vesselVisitId: number,
    handlingDirection: CarrierVisitDirection,
    portCodes: string[],
    upload: File,
  ) => {
    const fileFormatErrorPrefix = 'FileFormatError: '
    let response
    try {
      response = await this.baplieParserClient.uploadBaplie(
        vesselVisitId,
        handlingDirection,
        portCodes,
        upload,
      )
    } catch (error: any) {
      const responseError = error.response.data.error as string

      if (responseError.startsWith(fileFormatErrorPrefix))
        throw new FileFormatError(responseError.split(fileFormatErrorPrefix)[1])

      throw error
    }

    return response.data
  }

  verifyIsoCodes = async (orders: UnitListDto[]) => {
    const isoCodes = orders.filter(o => o.isoCode).map(o => o.isoCode!)
    const invalidIsoCodes = await containerService.validateIsoCodes(isoCodes)

    if (invalidIsoCodes.length) {
      throw new IsoCodeValidationError(invalidIsoCodes)
    }
  }

  verifyRailcarConsistency = (
    orders: UnitListDto[],
    relatedRailcars?: RailcarTrackPositionResponseDto[],
  ) => {
    const mismatchedRailcars: string[] = []
    if (!relatedRailcars) return

    for (const order of orders) {
      const matchingRailcar = relatedRailcars.find(
        railcar =>
          railcar.railcarSequenceNumber === order.sequence &&
          railcar.railTrackId === order.railTrackId,
      )

      if (matchingRailcar && matchingRailcar.railcarName !== order.waggon) {
        mismatchedRailcars.push(
          `Sequence ${order.sequence} on track ${matchingRailcar.railTrackName}: expected ${matchingRailcar.railcarName}, found ${order.waggon}.`,
        )
      }
    }

    if (mismatchedRailcars.length > 0) {
      throw new RailcarSequenceConflict(mismatchedRailcars)
    }
  }

  verifyMissingRailTrack = (orders: UnitListDto[]) => {
    if (!orders.some(x => x.railTrackId)) return

    const ordersWithMissingRailTrack = orders
      .map((order, index) => ({ ...order, index }))
      .filter(order => !order.railTrackId)

    if (ordersWithMissingRailTrack?.length) {
      const railcars = ordersWithMissingRailTrack
        .map(o => o.waggon ?? '')
        .filter((value, index, self) => self.indexOf(value) === index)

      throw new MissingRailtrackError(railcars)
    }
  }

  verifyInvalidRailTracks = (orders: IOrderDto[]) => {
    if (orders.some(x => !x.railTrackId)) return

    const ordersWithInvalidRailTrack = orders
      .map((order, index) => ({ ...order, index }))
      .filter(order => order.railTrackId && order.railTrackId === '-1')

    if (ordersWithInvalidRailTrack?.length) {
      const railTracks = ordersWithInvalidRailTrack
        .map(o => o.railTrack ?? '')
        .filter((value, index, self) => self.indexOf(value) === index)

      throw new InvalidRailtrackError(railTracks)
    }
  }

  verifyNotPlannedRailTracks = (orders: IOrderDto[], visitRailTrackIds?: string[] | null) => {
    if (orders.some(x => !x.railTrackId)) return

    const ordersWithInvalidRailTrack = orders
      .map((order, index) => ({ ...order, index }))
      .filter(
        order =>
          order.railTrackId &&
          (!visitRailTrackIds || !visitRailTrackIds.includes(order.railTrackId)),
      )

    if (ordersWithInvalidRailTrack?.length) {
      const railTracks = ordersWithInvalidRailTrack
        .map(o => o.railTrack ?? '')
        .filter((value, index, self) => self.indexOf(value) === index)

      throw new NotPlannedRailtrackError(railTracks)
    }
  }

  verifyDuplicateRailcarsWithDifferentRailTracks = (orders: UnitListDto[]) => {
    const duplicateRailcarsWithDifferentRailTracks = orders.filter((order, index) => {
      return orders.some((o, i) => {
        return i !== index && o.waggon === order.waggon && o.railTrackId !== order.railTrackId
      })
    })

    if (duplicateRailcarsWithDifferentRailTracks?.length) {
      const railTracks = duplicateRailcarsWithDifferentRailTracks
        .map(o => o.waggon ?? '')
        .filter((value, index, self) => self.indexOf(value) === index)

      throw new DuplicateRailcarsWithDifferentRailTracksError(railTracks)
    }
  }

  verifyDuplictedRailcarsWithDifferentSequence = (orders: UnitListDto[]) => {
    const duplicateRailcarsWithDifferentSequence = orders.filter((order, index) => {
      return orders.some((o, i) => {
        return i !== index && o.waggon === order.waggon && o.sequence !== order.sequence
      })
    })

    if (duplicateRailcarsWithDifferentSequence?.length) {
      const railTracks = duplicateRailcarsWithDifferentSequence
        .map(o => o.waggon ?? '')
        .filter((value, index, self) => self.indexOf(value) === index)

      throw new DuplicateRailcarsWithDifferentSequenceError(railTracks)
    }
  }

  verifyDuplicateSequencesWithDifferentRailcars = (orders: UnitListDto[]) => {
    const duplicateSequencesWithDifferentRailcars = orders
      .map((order, index) => ({ ...order, index }))
      .filter((order, index) => {
        return orders.some((o, i) => {
          return (
            i !== index &&
            o.sequence &&
            o.sequence === order.sequence &&
            o.railTrackId === order.railTrackId &&
            o.waggon !== order.waggon
          )
        })
      })

    if (duplicateSequencesWithDifferentRailcars?.length) {
      const lineNumbers = duplicateSequencesWithDifferentRailcars.map(o => o.index + 2)

      throw new DuplicateSequencesWithDifferentRailcarsError(lineNumbers)
    }
  }

  validateMissingSequence = (orders: UnitListDto[]) => {
    const ordersWithMissingSequence = orders
      .map((order, index) => ({ ...order, index })) // Add index to each order
      .filter(order => !order.sequence)

    if (ordersWithMissingSequence.length > 0) {
      const invalidLineNumber = ordersWithMissingSequence.map(o => o.index + 2)

      throw new Error(
        `You are trying to upload a file that contains railcars without a sequence. Please make sure each line of your file provides the correct sequence of the railcar. The following lines are in the csv don't have a sequence: ${invalidLineNumber.join(', ')}`,
      )
    }
  }

  validateRailWarningMessages = (orders: UnitListDto[]) => {
    const messages = []

    const noAssignedRailTrackMessage = this.checkVisitWithNoRailTracks(orders)
    if (noAssignedRailTrackMessage) messages.push(noAssignedRailTrackMessage)

    return messages
  }

  checkVisitWithNoRailTracks = (orders: UnitListDto[]) => {
    const noRailTrackOrders = orders.some(o => !o.railTrackId)
    if (noRailTrackOrders) {
      return 'Orders in this file do not have a rail track. They will be added in the first available rail track assigned to the visit'
    }
  }

  parseRailVisitOrderListExport = (
    visit: IRailVisitItem,
    positions: IEntityMap<IRailcarTrackPositionItem>,
    direction: CarrierVisitDirection,
  ) => {
    const fields = csvFieldSchemaMap.get(CarrierType.Train)?.map(f => f.inputName) ?? []
    const optionalFields = getOptionalFieldSchema(
      CarrierType.Train,
      direction,
      true,
      true,
      true,
      true,
    ).map(f => f.inputName)

    const directionOrders =
      direction === CarrierVisitDirection.Inbound ? visit.discharge : visit.load

    const orders = directionOrders.orders.map(item => {
      const rtp = item.data.railcarTrackPositionId
        ? positions[item.data.railcarTrackPositionId]?.data
        : null
      return {
        ...item.data,
        isoCode: item.data.containerIsoCode,
        imoClasses: item.data.imoClasses.join('/'),
        seals: item.data.seals?.join('/'),
        sequence: rtp?.railcarSequenceNumber,
        track: rtp?.railTrackName,
        checkedOut: formatDateTime(rtp?.checkoutDate),
      }
    })

    return {
      fileName: this.generateRailVisitOrderListExportFileName(
        visit.data.name ?? '',
        visit.arrival ?? '',
        visit.data.outboundTripIds ?? [],
      ),
      header: [...fields, ...optionalFields, 'checkedOut'],
      data: orders,
    }
  }

  generateRailVisitOrderListExportFileName = (
    trainName: string,
    arrivalDateTime: string,
    voyages: string[],
  ) => {
    const train = `train-${trainName}`
    const arrival = `arrival-${moment.utc(arrivalDateTime).format('YYYYMMDDTHHmm[Z]')}`
    const voyage = voyages.length ? `_voyage-${voyages.join('-')}` : ''

    return `export_${train}_${arrival}${voyage}.csv`
  }

  parseContainerPosition = (position: string): StowagePosition => {
    if (position.length !== 6) {
      throw new Error('Invalid position. It must be exactly 6 characters long.')
    }

    return {
      bay: parseInt(position.slice(0, 2), 10),
      row: parseInt(position.slice(2, 4), 10),
      tier: parseInt(position.slice(4, 6), 10),
    }
  }
}

const orderListParsingService = new OrderListParsingService()

export default orderListParsingService
