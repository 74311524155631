import * as yup from 'yup'
import { EquipmentFormProfile } from './equipment-form.profile'

export const schema = (): yup.ObjectSchema<EquipmentFormProfile> =>
  yup.object({
    id: yup.number().optional(),
    name: yup.string().label('Name').emptyAsUndefined().required(),
    equipmentType: yup.string().label('Equipment Type').emptyAsUndefined().required(),
    isOnMaintenance: yup.boolean().label('Status').required(),
    maxHeight: yup.number().label('Max weight').min(0).emptyAsUndefined(),
    maxWeight: yup.number().label('Max weight').min(0).emptyAsUndefined(),
    yardBlockIds: yup.array(),
    yardBlockAssignments: yup.array(),
  })
