import {
  AllocationRulePropertiesDto,
  AllocationRuleSummaryDto,
  CarrierVisitAllocationRuleDto,
  CarrierVisitDirection,
  CarrierVisitsApi,
  CarrierVisitsCreateCarrierVisitAllocationAndRulesRequest,
} from '@storage/app/api'

export class CarrierVisitsService {
  constructor(private readonly _carrierVisitsApi: CarrierVisitsApi) {}

  async getAllocationRules(
    carrierVisitId: number,
    direction?: CarrierVisitDirection,
  ): Promise<Array<CarrierVisitAllocationRuleDto>> {
    const { data: allocationRules } = await this._carrierVisitsApi.getAllocationRules({
      carrierVisitId,
      direction,
    })
    return allocationRules
  }

  async createCarrierVisitAllocationAndRules(
    carrierVisitsCreateCarrierVisitAllocationAndRulesRequest?: CarrierVisitsCreateCarrierVisitAllocationAndRulesRequest,
  ): Promise<void> {
    await this._carrierVisitsApi.createCarrierVisitAllocationAndRules({
      carrierVisitsCreateCarrierVisitAllocationAndRulesRequest,
    })
  }

  async getAllocationSummary(
    allocationRulePropertiesDto?: Array<AllocationRulePropertiesDto>,
  ): Promise<Array<AllocationRuleSummaryDto>> {
    const { data: allocationRuleSummaries } = await this._carrierVisitsApi.allocationSummary({
      allocationRulePropertiesDto,
    })
    return allocationRuleSummaries
  }
}
