import { useTranslate } from '@tolgee/react'
import {
  AirSurfaceIcon,
  AutoIcon,
  BulkIcon,
  FishIcon,
  FoldableIcon,
  GeneralIcon,
  LivestockIcon,
  NonCargoIcon,
  OpenTopIcon,
  PlataformIcon,
  ReeferIcon,
  TankIcon,
  UnknowIcon,
} from '@tom-ui/ui'

type ContainerTypes = {
  [key: string]: {
    label: string
    icon: JSX.Element
  }
}

export const useContainerTypeParameters = () => {
  const { t } = useTranslate()

  const types: ContainerTypes = {
    Unknown: {
      label: t('unknown', 'Unknown'),
      icon: <UnknowIcon />,
    },
    General: {
      label: t('generalPurpose', 'General Purpose'),
      icon: <GeneralIcon />,
    },
    GeneralVentilated: {
      label: t('generalVentilated', 'General Ventilated'),
      icon: <GeneralIcon />,
    },
    Reefer: {
      label: t('reefer', 'Reefer'),
      icon: <ReeferIcon />,
    },
    OpenTop: {
      label: t('openTop', 'Open Top'),
      icon: <OpenTopIcon />,
    },
    Platform: {
      label: t('platform', 'Platform'),
      icon: <PlataformIcon />,
    },
    Tank: {
      label: t('tank', 'Tank'),
      icon: <TankIcon />,
    },
    Bulk: {
      label: t('bulk', 'Bulk'),
      icon: <BulkIcon />,
    },
    Foldable: {
      label: t('foldable', 'Foldable'),
      icon: <FoldableIcon />,
    },
    AirSurface: {
      label: t('airSurface', 'Air Surface'),
      icon: <AirSurfaceIcon />,
    },
    Livestock: {
      label: t('livestock', 'Livestock'),
      icon: <LivestockIcon />,
    },
    Auto: {
      label: t('auto', 'Auto'),
      icon: <AutoIcon />,
    },
    Fish: {
      label: t('fish', 'Fish'),
      icon: <FishIcon />,
    },
    NonCargoCarrying: {
      label: t('nonCargoCarrying', 'Non Cargo'),
      icon: <NonCargoIcon />,
    },
  }

  return types
}
