import { Stack } from '@mui/material'
import { YardBlockSelectOption } from '@operations/features/equipments/components/form/controlledYardBlock/ControlledYardBlockWithBayAssignment'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { YardBaysChip } from './YardBaysChip'
import { YardBlocksChip } from './YardBlocksChip'

interface Props {
  selectedYardBlock?: string
  selectedBay?: string
  yardBlocks: YardBlockSelectOption[]
  onSelectYardBlock: (yardBlock?: string) => void
  onSelectBay: (bay?: string) => void
}
export const YardBlockChips = observer(
  ({ selectedYardBlock, yardBlocks, selectedBay, onSelectYardBlock, onSelectBay }: Props) => {
    const theme = useMinimalsTheme()

    return (
      <Stack flexDirection='row' gap={theme.customSpacing.xs}>
        <YardBlocksChip
          yardBlocks={yardBlocks}
          selectedYardBlock={selectedYardBlock}
          onSelectYardBlock={onSelectYardBlock}
        />

        {selectedYardBlock && (
          <YardBaysChip
            bays={yardBlocks.find(x => x.label === selectedYardBlock)?.bays ?? []}
            selectedBay={selectedBay}
            onSelectBay={onSelectBay}
          />
        )}
      </Stack>
    )
  },
)
