import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { useOperationsStore } from '@operations/AppProvider'
import { ReeferMonitoringContainer } from '@operations/features/reeferMonitoring/containers/ReeferMonitoringContainer'
import { ReeferMonitoringContainerV2 } from '@operations/features/reeferMonitoring/containers/ReeferMonitoringContainerV2'
import { ReeferMonitoringDetailsContainers } from '@operations/features/reeferMonitoring/containers/ReeferMonitoringDetailsContainer'
import { ReeferMonitoringDialogContainer } from '@operations/features/reeferMonitoring/containers/ReeferMonitoringDialogContainer'
import { ReeferMonitoringDrawerContainer } from '@operations/features/reeferMonitoring/containers/ReeferMonitoringDrawerContainer'
import { ReeferMonitoringDetailsUIStore } from '@operations/features/reeferMonitoring/stores/reefer-monitoring-details.ui-store'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { ReactNode, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'

interface Props {
  maxPluggedOutComponent?: ReactNode
}
export const ReeferMonitoring = observer(({ maxPluggedOutComponent }: Props) => {
  const { t } = useTranslate()

  const isNewReeferPageLayout = useBooleanFlagValue('new-reefer-page-layout', false)

  const {
    reeferTemperatureStore,
    reeferTemperatureStoreV2,
    reeferMonitoringUIStoreV2,
    tenantConfigStore,
  } = useOperationsStore()

  const reeferMonitoringDetailsUIStore = useMemo(
    () => new ReeferMonitoringDetailsUIStore(reeferMonitoringUIStoreV2),
    [reeferMonitoringUIStoreV2],
  )

  useEffect(() => {
    if (isNewReeferPageLayout) {
      reeferTemperatureStoreV2.loadInProgressCoolingOrders()
    } else {
      reeferTemperatureStore.loadInProgressCoolingOrders()
    }
  }, [isNewReeferPageLayout, reeferTemperatureStore, reeferTemperatureStoreV2])

  return (
    <>
      <Helmet>
        <title>{t('reeferMonitoringTomOperation', 'Reefer Monitoring - Tom Operation')}</title>
      </Helmet>

      {reeferMonitoringDetailsUIStore.isOpen ? (
        <ReeferMonitoringDetailsContainers
          reeferMonitoringDetailsUIStore={reeferMonitoringDetailsUIStore}
          maxPluggedOutTime={tenantConfigStore.tenantConfig?.reeferContainerMaxUnpluggedTime}
        />
      ) : isNewReeferPageLayout ? (
        <ReeferMonitoringContainerV2
          maxPluggedOutComponent={maxPluggedOutComponent}
          reeferMonitoringDetailsUIStore={reeferMonitoringDetailsUIStore}
        />
      ) : (
        <ReeferMonitoringContainer />
      )}

      <ReeferMonitoringDrawerContainer />
      <ReeferMonitoringDialogContainer
        reeferMonitoringDetailsUIStore={reeferMonitoringDetailsUIStore}
      />
    </>
  )
})
