enum Permission {
  ReadOrders = 'read:orders',
  WriteOrders = 'write:orders',
  ReadVessels = 'read:vessels',
  WriteVessels = 'write:vessels',
  ReadRailVisits = 'read:rail-visits',
  WriteRailVisits = 'write:rail-visits',
  ReadRailCars = 'read:rail-cars',
  WriteRailCars = 'write:rail-cars',
  ReadDangerousGoodsSettings = 'read:dangerous-goods-settings',
  WriteDangerousGoodsSettings = 'write:dangerous-goods-settings',
}

export default Permission
