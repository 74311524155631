import { Drawer } from '@mui/material'
import { useOpenFeatureClient } from '@openfeature/react-sdk'
import { useTolgee } from '@tolgee/react'
import { JobStepper, JobStepperHeader, useOperationsStore } from '@tom-ui/operations'
import { orderService, RailcarAutocomplete } from '@tom-ui/planning'
import { ContainerShiftings, useStorageStore, YardOperationControl } from '@tom-ui/storage'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

export const EquipmentOperatorStepper = observer(() => {
  const { equipmentOperatorStepperUIStore, tenantConfigStore, appStore } = useOperationsStore()
  const { yardOperationControlStore } = useStorageStore()
  const { containerShiftingsStore, containerShiftingsFormStore, snackbarStore } = useStorageStore()
  const { t } = useTolgee()

  const featureFlagClient = useOpenFeatureClient()

  const isOperatorRailcarSelectionComponentOn = useMemo(
    () => featureFlagClient.getBooleanValue('operator-railcar-selection-component', false),
    [featureFlagClient],
  )

  const handleConfirm = async (currentJob: any) => {
    const jobOriginType = equipmentOperatorStepperUIStore.getOriginType(
      currentJob.workInstructionId,
    )
    const jobDestinationType = equipmentOperatorStepperUIStore.getDestinationType(
      currentJob.workInstructionId,
    )
    const containerNumber = equipmentOperatorStepperUIStore.getContainerNumber(
      currentJob.workInstructionId,
    )

    try {
      if (currentJob.orderId && currentJob.grossWeight) {
        await orderService.updateWeight({
          id: currentJob.orderId,
          grossWeight: currentJob.grossWeight,
        })
      }

      if (
        currentJob.orderId &&
        jobDestinationType === 'Train' &&
        tenantConfigStore.skipRailTally &&
        isOperatorRailcarSelectionComponentOn
      ) {
        const visitId = equipmentOperatorStepperUIStore.getVisitId(currentJob.workInstructionId)

        const railcarTrackPositionId = await orderService.createOrAssignOrderToRailcarTrackPosition(
          {
            orderId: currentJob.orderId,
            railVisitId: visitId,
            railcarId: currentJob.railcarId,
          },
        )

        currentJob.railcarTrackPositionId = railcarTrackPositionId
      }

      await equipmentOperatorStepperUIStore.finishJob(currentJob)

      if (jobDestinationType === 'Yard') {
        await yardOperationControlStore.onConfirm(containerNumber ?? '', currentJob.orderId)
      }

      if (jobOriginType === 'Yard') {
        containerShiftingsFormStore.resetFormItems()
        containerShiftingsStore.resetNumberOfShiftings()
      }
    } catch (ex: any) {
      equipmentOperatorStepperUIStore.equipmentOperatorUIStore.showJob(currentJob.workInstructionId)
      await equipmentOperatorStepperUIStore.cancelStartJob(currentJob.workInstructionId)

      if (ex.response.data.status === 400 && ex.response.data.detail) {
        appStore.setShowAlert('error', ex.response.data.detail)
      }
    }
  }

  const handleOnClose = () => {
    containerShiftingsFormStore.resetFormItems()
  }

  const handlePicks = async (turnoverId: string) => {
    try {
      await containerShiftingsStore.updateContainerPosition(
        turnoverId,
        containerShiftingsFormStore.containerCurrentPositions.get(turnoverId)!,
      )
      equipmentOperatorStepperUIStore.setPicks(containerShiftingsStore.numberOfShiftingsDone)
      snackbarStore.showMessage(
        t('updateContainerPositionSuccess', 'The container position was successfully updated'),
        'success',
      )
      containerShiftingsFormStore.disableConfirmButton(turnoverId, true)
    } catch (ex: any) {
      snackbarStore.showMessage(
        t('updateContainerPositionWarning', 'The container position was not successfully updated'),
        'warning',
      )
    }
  }

  return (
    <Drawer
      open={equipmentOperatorStepperUIStore.isOpen}
      anchor='right'
      PaperProps={{
        sx: {
          width: '100vw',
        },
      }}
      data-cy='job-container-stepper'
    >
      <JobStepperHeader onConfirm={handleConfirm} onClose={handleOnClose} />

      <JobStepper
        EditDestinationComponent={YardOperationControl}
        ConfirmPickupComponent={ContainerShiftings}
        RailcarAutocompleteComponent={RailcarAutocomplete}
        handlePicks={handlePicks}
      />
    </Drawer>
  )
})
