import { Box, Card } from '@mui/material'
import { CarrierVisitDirection, ContainerTurnoverDto, WeightClassDto } from '@storage/app/api'
import { CarrierVisitAllocationRulesContainer } from '@storage/features/carrier-visit-allocation-rules/CarrierVisitAllocationRulesContainer'
import UnsavedChangesDialog from '@storage/features/carrier-visit-allocation-rules/components/UnsavedChangesDialog'
import { useCarrierVisitDirectionNavigation } from '@storage/features/carrier-visit-allocation-rules/hooks/use-carrier-visit-direction-navigation.hook'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { mapCarrierVisitDiscriminatorToCarrierType } from '../mappers'
import UnAllocatedContainers from './UnAllocatedContainers'
import { AllocationRulePanel } from './allocation-rule-panel/AllocationRulePanel'

interface YardPlanningDashboardDetailsSidePanelProps {
  carrierVisitId: number
  carrierVisitDirection: CarrierVisitDirection
  discriminator: string
  unallocatedTurnovers: ContainerTurnoverDto[]
  weightClasses: WeightClassDto[]
  showAllocationRulePanel: boolean
}

const YardPlanningDashboardDetailsSidePanel = observer(
  ({
    carrierVisitId,
    carrierVisitDirection,
    discriminator,
    unallocatedTurnovers,
    weightClasses,
    showAllocationRulePanel,
  }: YardPlanningDashboardDetailsSidePanelProps) => {
    const {
      carrierVisitAllocationRulesV2UIStore,
      unallocatedTurnoversStore,
      carrierVisitAllocationRulesV2Store,
    } = useStores()
    const { t } = useTranslate()
    const [activeCarrierVisitDirection, setActiveCarrierVisitDirection] =
      useState<CarrierVisitDirection>(carrierVisitDirection)

    useEffect(() => {
      carrierVisitAllocationRulesV2UIStore.setCarrierVisitContext(
        carrierVisitDirection,
        discriminator,
      )

      carrierVisitAllocationRulesV2UIStore.loadGlobalAllocationRules(
        carrierVisitDirection,
        mapCarrierVisitDiscriminatorToCarrierType(discriminator),
      )

      carrierVisitAllocationRulesV2UIStore.loadAllocationRules(
        carrierVisitId,
        carrierVisitDirection,
      )

      return () => {
        carrierVisitAllocationRulesV2UIStore.setSelectedAllocationRule()
        carrierVisitAllocationRulesV2UIStore.clearAlerts()
      }
    }, [carrierVisitAllocationRulesV2UIStore, carrierVisitDirection, carrierVisitId, discriminator])

    useEffect(() => {
      let turnovers = unallocatedTurnoversStore.containerTurnovers

      carrierVisitAllocationRulesV2UIStore.allocationRules.forEach(rule => {
        turnovers = carrierVisitAllocationRulesV2UIStore.filterTurnoversByAllocationRule(
          rule,
          turnovers,
        )
      })
    }, [
      unallocatedTurnoversStore.containerTurnovers,
      carrierVisitAllocationRulesV2UIStore.carrierVisitAllocationRules,
      carrierVisitAllocationRulesV2UIStore.carrierVisitGlobalAllocationRules,
      carrierVisitAllocationRulesV2UIStore,
    ])

    const {
      handleDirectionChange,
      handleConfirmDirectionChange,
      handleCancelDirectionChange,
      showUnsavedChangesConfirmDialog,
    } = useCarrierVisitDirectionNavigation({
      carrierVisitId,
      discriminator,
      onDirectionChange: setActiveCarrierVisitDirection,
      hasUnsavedChanges: carrierVisitAllocationRulesV2Store.hasChanges,
    })

    if (showAllocationRulePanel) {
      return <AllocationRulePanel uiStore={carrierVisitAllocationRulesV2UIStore} />
    }

    return (
      <>
        <Card sx={{ height: '80vh', display: 'flex', flexDirection: 'column' }}>
          <CarrierVisitAllocationRulesContainer
            activeCarrierVisitDirection={activeCarrierVisitDirection}
            handleDirectionChange={handleDirectionChange}
          />
          <Box sx={{ flexGrow: 1, minHeight: 0 }}>
            <UnAllocatedContainers
              uiStore={carrierVisitAllocationRulesV2UIStore}
              isLoading={unallocatedTurnoversStore.dataLoading}
              unallocatedTurnovers={unallocatedTurnovers}
              carrierVisitDirection={carrierVisitDirection}
              weightClasses={weightClasses}
            />
          </Box>
        </Card>

        <UnsavedChangesDialog
          open={showUnsavedChangesConfirmDialog}
          message={t(
            'unsavedChangesDirectionMessage',
            'You have unsaved changes. Are you sure you want to switch direction? Your changes will be lost.',
          )}
          confirmLabel={t('cancel', 'Cancel')}
          cancelLabel={t('switchAnyway', 'Switch Anyway')}
          onConfirm={handleConfirmDirectionChange}
          onCancel={handleCancelDirectionChange}
        />
      </>
    )
  },
)

export default YardPlanningDashboardDetailsSidePanel
