import { Stack } from '@mui/material'
import { CustomsStatus } from '@planning/app/api'
import { NonPrimaryButton } from '@planning/components/atoms/NonPrimaryButton'
import { CustomsItem } from '@planning/components/molecules/CustomsItem'
import { MultiselectActionBar } from '@planning/components/molecules/MultiselectActionBar'
import { EmptyCustoms } from '@planning/pages/Order/components/Molecules/EmptyCustoms'
import { CustomsViewStore } from '@planning/pages/Order/stores/CustomsViewStore'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

type Props = {
  store: CustomsViewStore
}

export const Customs = observer(({ store }: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const {
    items,
    updateCustoms,
    setSelectAll,
    setSelectItem,
    updateSelectedCustoms,
    selectAllState,
    selectItemStates,
    errors,
  } = store

  const actions = [
    <NonPrimaryButton
      key={CustomsStatus.Pending}
      text={t('pending', 'Pending')}
      onClick={() => updateSelectedCustoms(CustomsStatus.Pending)}
      size='small'
    />,
    <NonPrimaryButton
      key={CustomsStatus.Cleared}
      text={t('cleared', 'Cleared')}
      onClick={() => updateSelectedCustoms(CustomsStatus.Cleared)}
      size='small'
    />,
    <NonPrimaryButton
      key={CustomsStatus.Refused}
      text={t('refused', 'Refused')}
      onClick={() => updateSelectedCustoms(CustomsStatus.Refused)}
      size='small'
    />,
  ]

  return (
    <Stack gap={theme.customSpacing.xs}>
      {items.length ? (
        <MultiselectActionBar
          state={selectAllState}
          handleOnStateChange={(checked: boolean) => setSelectAll(checked)}
          label={t('setTo', 'Set to')}
          actions={actions}
        />
      ) : (
        <EmptyCustoms />
      )}
      {items.map((customs, index) => (
        <CustomsItem
          key={index}
          state={selectItemStates[index]}
          onChangeState={(checked: boolean) => setSelectItem(index, checked)}
          customs={customs}
          onChangeCustoms={customs => updateCustoms(index, customs)}
          error={errors[index]}
        />
      ))}
    </Stack>
  )
})
