import PublishIcon from '@mui/icons-material/Publish'
import { Box, Button, Tooltip } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface Props {
  onClick: () => Promise<void> | void
  disabled?: boolean
  customTooltipTitle?: string
}

export const UploadOrdersButton: FC<Props> = observer(
  ({ onClick, disabled: disable, customTooltipTitle }) => {
    const { t } = useTranslate()

    return (
      <Tooltip title={customTooltipTitle ? customTooltipTitle : t('uploadOrders', 'Upload orders')}>
        <Box>
          <Button
            disabled={disable}
            variant='contained'
            size='large'
            onClick={onClick}
            data-cy='upload-documents-vessel-visit-btn'
            startIcon={<PublishIcon />}
          >
            {t('upload', 'Upload')}
          </Button>
        </Box>
      </Tooltip>
    )
  },
)
