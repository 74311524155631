import { Box, Typography } from '@mui/material'
import { OrderListUploadViewStoreV2 } from '@planning/pages/Order/stores/OrderListUploadViewStoreV2'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { FC } from 'react'

interface IProps {
  store: OrderListUploadViewStoreV2
  isRestow?: boolean
}
export const OrderListUploadCounter: FC<IProps> = ({ store, isRestow }) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const renderCountBox = (title: string, value: any) => (
    <Box
      sx={{
        padding: `${theme.customSpacing.s} ${theme.customSpacing.m}`,
        borderRight: '1px solid rgba(145, 158, 171, 0.24)',
        flex: '1 1 0px',
        '&:last-child': {
          borderRight: 0,
        },
      }}
    >
      <Typography variant='caption' color={theme.palette.grey[600]}>
        {title}
      </Typography>
      <Typography variant='body1' color={theme.palette.grey[600]}>
        {value}
      </Typography>
    </Box>
  )

  return (
    <Box
      sx={{
        display: 'flex',
        mb: theme.customSpacing.l,
        borderRadius: theme.customRadius.medium,
        border: '1px solid rgba(145, 158, 171, 0.24)',
      }}
    >
      {isRestow ? (
        renderCountBox(t('totalRecords', 'Total Records'), store.dataStore.restowUnits.length)
      ) : (
        <>
          {renderCountBox(t('totalRecords', 'Total Records'), store.totalCount)}
          {store.orderUpdates?.created.length
            ? renderCountBox(t('new', 'New'), store.orderUpdates?.created.length)
            : ''}
          {store.orderUpdates?.amended.length
            ? renderCountBox(t('amended', 'Amended'), store.orderUpdates?.amended.length)
            : ''}
          {store.orderUpdates?.deleted.length
            ? renderCountBox(t('cancelled', 'Cancelled'), store.orderUpdates?.deleted.length)
            : ''}
          {store.orderUpdates?.unchanged.length
            ? renderCountBox(t('unchanged', 'Unchanged'), store.orderUpdates?.unchanged.length)
            : ''}
        </>
      )}
    </Box>
  )
}
