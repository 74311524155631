import { ReeferTemperatureDto } from '@operations/app/api'
import { FormType } from '@operations/app/models'
import { action, computed, makeObservable, observable } from 'mobx'
import { CoolingOrderCardDto } from '../models/cooling-order-model'
import { ReeferMonitoringUIStoreV2 } from './reefer-monitoring.ui-storeV2'

export class ReeferMonitoringDetailsUIStore {
  recordId?: number
  isOpen = false
  editingEntityId?: number
  isRecordOpen = false

  constructor(private reeferMonitoringUIStoreV2: ReeferMonitoringUIStoreV2) {
    makeObservable(this, {
      recordId: observable,
      isOpen: observable,
      isRecordOpen: observable,
      editingEntityId: observable,

      toggleRecord: action,
      toggle: action,

      editingRecord: computed,
    })
  }

  public get formType(): FormType {
    return this.recordId ? 'Edit' : 'Add'
  }

  public get editingEntity(): CoolingOrderCardDto | undefined {
    return this.editingEntityId
      ? this.reeferMonitoringUIStoreV2.items.find(i => i.id === this.editingEntityId)
      : undefined
  }

  public toggle(id?: number): void {
    this.editingEntityId = id
    this.isOpen = !this.isOpen
  }

  public toggleRecord(recordId?: number): void {
    if (this.recordId !== recordId) this.recordId = recordId
    this.isRecordOpen = !this.isRecordOpen
  }

  public get editingRecord(): ReeferTemperatureDto | undefined {
    return this.recordId && this.editingEntity
      ? this.editingEntity.reeferTemperatures?.find(i => i.id === this.recordId)
      : undefined
  }
}
