import { RemainOnBoardUnitsApi, UpsertRestowListCommand } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class RestowService {
  httpClient = createApiClient(RemainOnBoardUnitsApi)

  async upsertRestowList(cmd: UpsertRestowListCommand) {
    const { data } = await this.httpClient.restows(cmd)
    return data
  }

  async delete(ids: number[]) {
    const { data } = await this.httpClient.removeRestow(ids)
    return data
  }
}

const restowService = new RestowService()

export default restowService
