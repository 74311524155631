import { makeObservable, observable, action } from 'mobx'
import { ContainerTurnoverDto } from '@storage/app/api'
import { FormType } from '@storage/app/models'
import { HighlightedDestination } from '@storage/pages/yard-planning-dashboard-details/interfaces'
import { AllocationRuleTemplateFormProfile } from '@storage/features/carrier-visit-allocation-rules/forms/allocation-rule-templates-form/allocation-rule-templates-form.profile'
import { AllocationRuleDto } from '@storage/types'
import { UnallocatedTurnoversBreakDown } from './carrier-visit.store'
import { mapSettingsToAllocationRuleTemplateFormProfile } from '@storage/features/carrier-visit-allocation-rules/forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'

export abstract class BaseAllocationRulesUIStore<T extends AllocationRuleDto> {
  selectedAllocationRule?: T
  allocationRuleSummaries: Map<string, ContainerTurnoverDto[]> = new Map()
  showAllocationRuleForm = false
  showDestinationForm = false
  destinationsToHighlight: HighlightedDestination[] = []
  allocationRuleFormType: FormType = 'Add'
  formExternalDefaultValues?: AllocationRuleTemplateFormProfile
  openDialogForOnlyContainerNumber = false

  constructor() {
    makeObservable(this, {
      selectedAllocationRule: observable,
      allocationRuleSummaries: observable,
      showAllocationRuleForm: observable,
      showDestinationForm: observable,
      destinationsToHighlight: observable,
      allocationRuleFormType: observable,
      formExternalDefaultValues: observable,
      openDialogForOnlyContainerNumber: observable,

      setSelectedAllocationRule: action,
      toggleAllocationRule: action,
      setShowAllocationRuleForm: action,
      setShowDestinationForm: action,
      setDestinationsToHighlight: action,
      setAllocationRuleFormType: action,
      setOpenDialogForOnlyContainerNumber: action,
      setAllocationRuleSummary: action,
      handleDestinationsHighlight: action,
      highlightSelectedAllocationRule: action,
      setFormExternalDefaultValuesFromSettings: action,
    })
  }

  setSelectedAllocationRule(allocationRule?: T) {
    this.selectedAllocationRule = allocationRule
  }

  toggleAllocationRule(allocationRule: T) {
    if (this.selectedAllocationRule?.id === allocationRule.id) {
      this.selectedAllocationRule = undefined
    } else {
      this.selectedAllocationRule = allocationRule
    }
  }

  setShowAllocationRuleForm(show: boolean) {
    this.showAllocationRuleForm = show
  }

  setShowDestinationForm(show: boolean) {
    this.showDestinationForm = show
  }

  setDestinationsToHighlight(destinations: HighlightedDestination[]) {
    this.destinationsToHighlight = destinations
  }

  setAllocationRuleFormType(formType: FormType) {
    this.allocationRuleFormType = formType
  }

  setOpenDialogForOnlyContainerNumber(open: boolean) {
    this.openDialogForOnlyContainerNumber = open
  }

  setAllocationRuleSummary(allocationRuleId: string, turnovers: ContainerTurnoverDto[]) {
    this.allocationRuleSummaries.set(allocationRuleId, turnovers)
  }

  getAllocationSummary(allocationRuleId: string) {
    return this.allocationRuleSummaries.get(allocationRuleId)
  }

  handleDestinationsHighlight(allocationRules: T[]) {
    if (this.showAllocationRuleForm || this.showDestinationForm) {
      return
    }

    // Highlight all existing allocation rules destinations (fill only)
    this.destinationsToHighlight = allocationRules.flatMap(rule =>
      rule.destinationAndStackingStrategies.map(strategy => ({
        destination: strategy.destination,
        fill: true,
        border: false,
      })),
    )

    this.highlightSelectedAllocationRule()
  }

  highlightSelectedAllocationRule() {
    if (!this.selectedAllocationRule) {
      return
    }
    // Prepare a set of selected destinations
    const selectedDestinations = new Set(
      this.selectedAllocationRule.destinationAndStackingStrategies.map(dAndS =>
        JSON.stringify(dAndS.destination),
      ),
    )
    // Update the selected destinations to have a border
    this.destinationsToHighlight = this.destinationsToHighlight.map(dest => {
      if (selectedDestinations.has(JSON.stringify(dest.destination))) {
        return { ...dest, border: true }
      } else {
        return { ...dest, border: false }
      }
    })
  }

  hideAllocationRuleForm() {
    this.setShowAllocationRuleForm(false)
    this.setShowDestinationForm(false)
  }

  setFormExternalDefaultValuesFromSettings(breakdownItem: UnallocatedTurnoversBreakDown) {
    this.formExternalDefaultValues = mapSettingsToAllocationRuleTemplateFormProfile(breakdownItem)
  }

  openAddDialogWithBreakdown(breakdownItem: UnallocatedTurnoversBreakDown) {
    this.setFormExternalDefaultValuesFromSettings(breakdownItem)
    if (breakdownItem.containerNumber) {
      this.setOpenDialogForOnlyContainerNumber(true)
    }

    this.setShowAllocationRuleForm(true)
    this.setAllocationRuleFormType('Add')
  }

  abstract toggleDeletionConfirmationDialog(dialogEntityId: string): void

  abstract getAllocationRuleById(id: string): T | undefined

  abstract addAllocationRule(formValues: AllocationRuleTemplateFormProfile): void

  abstract updateAllocationRule(formValues: AllocationRuleTemplateFormProfile): void
}
