import { OrderUpdateResponseDto, OrderUpdateStatus } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetUnreadUpdatesByOrderIdsQuery } from '@planning/messages/queries/getUnreadUpdatesByOrderIdsQueryHandler'
import _ from 'lodash'
import { action, computed, makeObservable } from 'mobx'
import { ItemStore } from '../base/ItemStore'
import { IOrderUpdateItem, OrderUpdateItem } from './OrderUpdateItem'

export class OrderUpdateItemStore extends ItemStore<OrderUpdateResponseDto, IOrderUpdateItem> {
  fetchedIds: Set<number> = new Set()

  constructor(private messageBus: IMessageBus) {
    super((key, data) => new OrderUpdateItem(key, data), {
      messageBus,
      bulkFetchFunc: (ids: number[]) => new GetUnreadUpdatesByOrderIdsQuery(ids),
    })

    makeObservable(this, {
      unreadOrderUpdates: computed,

      fetchByOrderIds: action,
      receiveOrderUpdatesMessage: action,
      receiveOrderUpdateMessage: action,
    })

    messageBus.subscribeEvent(GetUnreadUpdatesByOrderIdsQuery.type, this.receiveOrderUpdatesMessage)
    messageBus.subscribeEvent(EventTypes.OrderUpdatesUpsertedEvent, this.receiveOrderUpdatesMessage)
  }

  get unreadOrderUpdates(): OrderUpdateResponseDto[] {
    return _(this.elements)
      .map(i => i.data)
      .filter(i => i.status === OrderUpdateStatus.Unread)
      .value()
  }

  fetchByOrderIds = async (orderIds: number[]) => {
    const newOrderIds = orderIds.filter(id => !this.fetchedIds.has(id))

    if (newOrderIds.length === 0) return

    newOrderIds.forEach(id => this.fetchedIds.add(id))

    await this.messageBus.dispatchQuery(new GetUnreadUpdatesByOrderIdsQuery(newOrderIds))
  }

  receiveOrderUpdatesMessage = (event: IEvent<OrderUpdateResponseDto[]>): void => {
    if (event.payload) {
      console.log('OrderUpdateItemStore: receiveOrderUpdatesMessage', event.payload)
      this.upsertBulk(event.payload)
    }
  }

  receiveOrderUpdateMessage = (event: IEvent<OrderUpdateResponseDto>): void => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }
}
