import { CoolingOrderDto } from '@operations/app/api'
import { YardBlockSelectOption } from '@operations/features/equipments/components/form/controlledYardBlock/ControlledYardBlockWithBayAssignment'
import { ReeferTemperatureStore } from '@operations/stores/ReeferTemperatureStore'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'

export class CoolingOrdersUIStore {
  selectedYardBlock?: string
  selectedYardBlockBay?: string

  isSearchOpen = false
  searchText?: string

  isStepperOpen = false
  coolingOrderId?: number

  constructor(private reeferTemperatureStore: ReeferTemperatureStore) {
    makeObservable(this, {
      coolingOrderId: observable,
      isSearchOpen: observable,
      isStepperOpen: observable,
      searchText: observable,
      selectedYardBlock: observable,
      selectedYardBlockBay: observable,

      setSearchText: action,
      setYardBlock: action,
      setYardBlockBay: action,
      toggleSearch: action,
      toggleStepper: action,

      coolingOrders: computed,
      coolingOrdersFiltered: computed,
      isSearching: computed,
      searchedCoolingOrders: computed,
      selectedCoolingOrder: computed,
      yardBlocks: computed,
    })
  }

  public toggleSearch() {
    this.isSearchOpen = !this.isSearchOpen
    this.setSearchText()
  }

  public toggleStepper(orderId?: number) {
    this.isStepperOpen = !this.isStepperOpen
    this.coolingOrderId = orderId
  }

  public setSearchText(newValue?: string) {
    if (this.searchText !== newValue) {
      this.searchText = newValue
    }
  }

  public setYardBlock(yardBlock?: string) {
    if (this.selectedYardBlock !== yardBlock) {
      this.selectedYardBlock = yardBlock
      this.setYardBlockBay()
    }
  }

  public setYardBlockBay(bay?: string) {
    if (this.selectedYardBlockBay !== bay) {
      this.selectedYardBlockBay = bay
    }
  }

  public get coolingOrdersFiltered() {
    return filterCoolingOrdersByYardBlockAndBay(
      this.coolingOrders,
      this.selectedYardBlock,
      this.selectedYardBlockBay,
    )
  }

  public get searchedCoolingOrders() {
    if (!this.searchText) return []

    const searchedContainerWithoutSpace = this.searchText.toUpperCase().replace(/\s/g, '')

    return this.coolingOrders.filter(x =>
      x.containerNumber?.includes(searchedContainerWithoutSpace),
    )
  }

  public get isSearching() {
    return !!this.searchText
  }

  public get coolingOrders(): CoolingOrderDto[] {
    return this.reeferTemperatureStore.coolingOrders
  }

  public get yardBlocks(): YardBlockSelectOption[] {
    return getYardBlockSelectionOptionsFromCoolingOrders(this.coolingOrders)
  }

  public get selectedCoolingOrder() {
    return this.coolingOrders.find(x => x.id === this.coolingOrderId)
  }
}

export const getYardBlockSelectionOptionsFromCoolingOrders = (coolingOrders: CoolingOrderDto[]) => {
  const yardBlocksWithBays: YardBlockSelectOption[] = []
  coolingOrders
    .filter(x => x.yardBlock)
    .forEach(x => {
      const yardBlock = x.yardBlock!
      const yardBlockWithBay = yardBlocksWithBays.find(yb => yb.value === yardBlock)
      if (!yardBlockWithBay) {
        yardBlocksWithBays.push({
          value: yardBlock,
          label: yardBlock,
          bays: x.yardBays ?? [],
        })
      } else if (x.yardBays) {
        yardBlockWithBay.bays = _([...yardBlockWithBay.bays, ...x.yardBays])
          .uniq()
          .sort()
          .value()
      }
    })

  return yardBlocksWithBays
}

export const filterCoolingOrdersByYardBlockAndBay = (
  coolingOrders: CoolingOrderDto[],
  selectedYardBlock?: string,
  selectedYardBlockBay?: string,
) => {
  if (!selectedYardBlock) return coolingOrders

  return coolingOrders.filter(
    x =>
      x.yardBlock &&
      selectedYardBlock === x.yardBlock &&
      (!selectedYardBlockBay || !x.yardBays?.length || x.yardBays.includes(selectedYardBlockBay)),
  )
}
