import { ImportContacts } from '@mui/icons-material'
import { Box } from '@mui/system'
import { AccordionContainer } from '@storage/components/AccordionContainer'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import DraggableAllocationRuleListItem from './DraggableAllocationRuleListItem'
import { TextBox } from '@storage/components/TextBox'

export const CarrierVisitAccordionContainer = () => {
  const { t } = useTranslate()
  const { carrierVisitAllocationRulesV2UIStore } = useStores()
  const dialogUtilStore = carrierVisitAllocationRulesV2UIStore.containerDialogUtilStore

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return

    carrierVisitAllocationRulesV2UIStore.reorderAllocationRules(
      result.source.index,
      result.destination.index,
    )
  }

  const renderLoadingState = () => (
    <TextBox
      text={t('loadingVisitAllocationRules', 'Loading visit allocation rules...')}
      isLoading
    />
  )

  const renderEmptyState = () => (
    <TextBox text={t('noAllocationRulesRunning', 'No allocation rules running')} />
  )

  const renderAllocationRules = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='allocationRulesList'>
        {provided => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              overflowY: 'auto',
              overflowX: 'hidden',
              maxHeight: '35vh',
            }}
          >
            {carrierVisitAllocationRulesV2UIStore.carrierVisitAllocationRules.map(
              (allocationRule, index, list) => (
                <DraggableAllocationRuleListItem
                  uiStore={carrierVisitAllocationRulesV2UIStore}
                  key={allocationRule.id}
                  allocationRule={allocationRule}
                  index={index}
                  isLast={index === list.length - 1}
                  variant='visit'
                />
              ),
            )}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )

  const renderContent = () => {
    if (carrierVisitAllocationRulesV2UIStore.isCarrierVisitAllocationRulesRequestLoading) {
      return renderLoadingState()
    }

    return carrierVisitAllocationRulesV2UIStore.carrierVisitAllocationRules.length
      ? renderAllocationRules()
      : renderEmptyState()
  }

  return (
    <AccordionContainer
      title={t('visitRules', 'Visit rules')}
      action={{
        icon: <ImportContacts />,
        onClick: () => dialogUtilStore.toggleDialog('Add'),
      }}
      tooltip={t(
        'visitRulesTooltip',
        'Visit rules allow you to override your global rules for this visit',
      )}
    >
      {renderContent()}
    </AccordionContainer>
  )
}
