import { useMemo } from 'react'
import { Skeleton, Box, Stack } from '@mui/material'

const BLOCK_COUNT = 5
const MIN_WIDTH = 70
const MAX_WIDTH = 100

const getRandomWidths = (count: number, min: number, max: number): number[] => {
  const range = max - min + 1
  const randomBuffer = new Uint32Array(count)
  crypto.getRandomValues(randomBuffer)
  return Array.from(randomBuffer).map(val => Math.floor((val / (0xffffffff + 1)) * range + min))
}

const skeletonStyle = {
  bgcolor: 'rgba(0, 0, 0, 0.1)',
  '&::after': {
    background: 'linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.1), transparent)',
  },
}

interface BlockSkeletonProps {
  blockWidth: number
}

const BlockSkeleton = ({ blockWidth }: BlockSkeletonProps) => (
  <Box sx={{ mb: 4, width: '100%' }}>
    <Stack direction='row' gap={1}>
      <Skeleton
        variant='rounded'
        animation='wave'
        width={90}
        height={24}
        sx={{ ...skeletonStyle, mb: 1 }}
      />
      <Skeleton
        variant='rounded'
        animation='wave'
        width={60}
        height={24}
        sx={{ ...skeletonStyle, mb: 1 }}
      />
      <Skeleton
        variant='rounded'
        animation='wave'
        width={60}
        height={24}
        sx={{ ...skeletonStyle, mb: 1 }}
      />
    </Stack>
    <Skeleton
      variant='rounded'
      animation='wave'
      width={`${blockWidth}%`}
      height={150}
      sx={skeletonStyle}
    />
  </Box>
)

const YardBlocksSkeleton = () => {
  const blockWidths = useMemo(() => getRandomWidths(BLOCK_COUNT, MIN_WIDTH, MAX_WIDTH), [])

  return (
    <Stack spacing={4}>
      {blockWidths.map((width, index) => (
        <BlockSkeleton key={index} blockWidth={width} />
      ))}
    </Stack>
  )
}

export default YardBlocksSkeleton
