import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { WorkInstructionDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { MRT_TableInstance } from 'material-react-table'
import { observer } from 'mobx-react-lite'
import { MutableRefObject } from 'react'
import { WorkInstructionTypes } from '../stores/work-instruction.ui-store'
interface Props {
  workInstructionsTableInstanceRef: MutableRefObject<MRT_TableInstance<WorkInstructionDto> | null>
}

export const WorkInstructionsTypeDropdownContainer = observer(
  ({ workInstructionsTableInstanceRef }: Props) => {
    const { t } = useTranslate()
    const { workInstructionUIStore } = useOperationsStore()
    return (
      <FormControl sx={{ minWidth: '15rem' }}>
        <Select
          value={workInstructionUIStore.workInstructionType ?? ''}
          onChange={(event: SelectChangeEvent) => {
            workInstructionsTableInstanceRef?.current?.resetPageIndex()
            workInstructionUIStore.setWorkInstructionType(
              event.target.value as WorkInstructionTypes,
            )
          }}
          sx={{ height: '3rem' }}
          data-cy='workInstructionsTable-TypeDropdown'
        >
          <MenuItem key={WorkInstructionTypes.vessels} value={WorkInstructionTypes.vessels}>
            {t('vessels', 'Vessels')}
          </MenuItem>
          <MenuItem
            key={WorkInstructionTypes.trains}
            value={WorkInstructionTypes.trains}
            data-cy='workInstructionsTable-TypeDropdown-item-Trains'
          >
            {t('trains', 'Trains')}
          </MenuItem>
          <MenuItem
            key={WorkInstructionTypes.externalTrucks}
            value={WorkInstructionTypes.externalTrucks}
            data-cy='workInstructionsTable-TypeDropdown-item-externalTrucks'
          >
            {t('externalTrucks', 'External trucks')}
          </MenuItem>
          <MenuItem
            key={WorkInstructionTypes.internalMoves}
            value={WorkInstructionTypes.internalMoves}
          >
            {t('internalMoves', 'Internal Moves')}
          </MenuItem>
          <MenuItem key={WorkInstructionTypes.services} value={WorkInstructionTypes.services}>
            {t('services', 'Services')}
          </MenuItem>
        </Select>
      </FormControl>
    )
  },
)
