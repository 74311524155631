import { ContainerDto, ContainerType } from '@planning/app/api'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { ServiceOrdersViewStore } from '../Stores/ServiceOrdersViewStore'
import { NumericForm } from './NumericForm'

export const PackingOrderNumericForm: FC<{
  store: ServiceOrdersViewStore
  isEmpty: boolean
  type?: ContainerType
}> = observer(({ store, isEmpty, type }) => {
  const onSearchFilterChange = (value: string | ContainerDto | null) => {
    if (value && typeof value != 'string' && value.id) {
      store.fetchContainerVisits(value.id)
    }
  }

  return (
    <NumericForm
      selectedContainerJourneys={
        store.selectedContainerJourney ? [store.selectedContainerJourney] : []
      }
      containerJourneyOptions={store.containerVisits}
      onSelectContainerJourney={containerJourneys =>
        store.setSelectedContainerJourney(containerJourneys?.[0])
      }
      onSearchFilterChange={(_, value) => onSearchFilterChange(value)}
      isEmpty={isEmpty}
      type={type}
    />
  )
})
