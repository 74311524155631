import { ReportOrderShortlandedCommand } from '@planning/app/api'
import { orderService } from '@planning/services'
import { AxiosError } from 'axios'
import { makeObservable } from 'mobx'
import { CancellableTimeoutStore } from '../gateClerk/CancellableTimeoutStore'

export class ReportIssueViewStore extends CancellableTimeoutStore {
  constructor(delay?: number) {
    super(delay)
    makeObservable(this, {})
  }

  /**
   * Reports a shortlanded container for a given order.
   *
   * @param orderId - The ID of the order to report as shortlanded.
   * @returns A promise that resolves when the report is completed.
   *
   * @throws Will throw an error if the report fails.
   */
  reportShortlandedContainer = async (orderId: number) => {
    this.createRequest(async () => {
      try {
        const cmd: ReportOrderShortlandedCommand = {
          orderId,
        }
        orderService.reportShortlandedContainer(cmd)

        super.reset()
      } catch (error) {
        this.handleError(error as AxiosError<any>)
      }
    })
  }

  private handleError(err: AxiosError<any>) {
    const errorMessage = err.message
    this.setRequestErrorMessage(errorMessage)
    this.cancelRequest()
  }
}
