import { Search } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputAdornment,
  Typography,
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import { AllocationDestinationAndStackingStrategyDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { AllocationRulesTemplatesListItem } from './AllocationRulesTemplatesListItem'
import { GlobalAllocationRulesUIStore } from '@storage/pages/global-allocation-rules/stores/global-allocation-rules.ui-store'
import { CarrierVisitAllocationRulesV2UIStore } from '../stores/carrier-visit-allocation-rules-v2.ui-store'

interface CarrierVisitAllocationRulesDialogProps {
  destinationUIStore: CarrierVisitAllocationRulesV2UIStore | GlobalAllocationRulesUIStore
  isOpen: boolean
  onCopy: (
    allocationRuleId: string,
    destinationAndStackingStrategies: AllocationDestinationAndStackingStrategyDto[],
  ) => void
  onClose: () => void
}

const CarrierVisitAllocationRulesDialog = observer(
  ({ destinationUIStore, isOpen, onCopy, onClose }: CarrierVisitAllocationRulesDialogProps) => {
    const { t } = useTranslate()
    const { allocationRuleTemplatesV2UIStore, manualInputValidationStore } = useStores()

    const allocationRuleTemplatesDialogUtilStore = allocationRuleTemplatesV2UIStore.dialogUtilStore

    const handleOnCreateNew = () => allocationRuleTemplatesDialogUtilStore.toggleDialog('Add')

    useEffect(() => {
      allocationRuleTemplatesV2UIStore.loadAllocationRuleTemplates()
    }, [allocationRuleTemplatesV2UIStore])

    const handleCopyToVisit = async (
      allocationRuleId: string,
      destinationAndStackingStrategies: AllocationDestinationAndStackingStrategyDto[],
    ) => {
      onCopy(allocationRuleId, destinationAndStackingStrategies)
    }

    const handleOnSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
      allocationRuleTemplatesV2UIStore.setSearchQuery(e.target.value)

    return (
      <Dialog onClose={onClose} open={isOpen} maxWidth='md' fullWidth>
        <DialogTitle>
          <Stack direction='row' justifyContent='space-between'>
            {t('allocationRulesTemplates', 'Allocation Rules Templates')}
            <Box display='flex' gap={2}>
              <Button variant={'contained'} color={'primary'} onClick={handleOnCreateNew}>
                {t('createNew', 'Create New')}
              </Button>
              <Button variant='text' color='inherit' onClick={onClose}>
                {t('close', 'Close')}
              </Button>
            </Box>
          </Stack>
        </DialogTitle>

        <DialogContent sx={{ mb: 3 }}>
          <Stack>
            <FormControl variant='standard' sx={{ m: 2 }}>
              <Input
                placeholder={t('searchByName', 'Search by name')}
                onChange={handleOnSearchChange}
                startAdornment={
                  <InputAdornment position='start'>
                    <Search />
                  </InputAdornment>
                }
              />
            </FormControl>
            <Stack gap={1} sx={{ maxHeight: '50vh', overflow: 'auto' }} p={0.5}>
              {allocationRuleTemplatesV2UIStore.allocationRuleTemplates
                .reverse()
                .map(allocationRuleTemplate => (
                  <AllocationRulesTemplatesListItem
                    key={allocationRuleTemplate.id}
                    allocationRuleTemplate={allocationRuleTemplate}
                    manualInputValidationStore={manualInputValidationStore}
                    onActionClick={handleCopyToVisit}
                    destinationUIStore={destinationUIStore}
                  />
                ))}
            </Stack>
            {allocationRuleTemplatesV2UIStore.searchInfoMessage && (
              <Box m='auto' p={3}>
                <Typography>{allocationRuleTemplatesV2UIStore.searchInfoMessage}</Typography>
              </Box>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    )
  },
)

export default CarrierVisitAllocationRulesDialog
