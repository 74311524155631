import { createSvgIcon } from '@mui/material'

export const LongArrowRight = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='36' height='8' viewBox='0 0 36 8' fill='none'>
    <path
      d='M35.4485 3.7324C35.6441 3.9274 35.6445 4.24399 35.4495 4.43951L32.2717 7.62572C32.0767 7.82124 31.7602 7.82166 31.5646 7.62666C31.3691 7.43166 31.3687 7.11507 31.5637 6.91955L34.3884 4.08737L31.5562 1.2627C31.3606 1.0677 31.3602 0.751118 31.5552 0.555596C31.7502 0.360074 32.0668 0.359653 32.2623 0.554656L35.4485 3.7324ZM35.0961 4.58643L0.0961551 4.63296L0.0948255 3.63296L35.0948 3.58643L35.0961 4.58643Z'
      fill='currentColor'
    />
  </svg>,
  'LongArrowRight',
)
