import { Box, Button } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { ContainerDto, UpsertContainerCommand } from '@planning/app/api'
import { SearchAutocomplete } from '@planning/components/list/SearchAutocomplete'
import { SimpleList } from '@planning/components/list/SimpleList'
import { IListStore } from '@planning/components/list/SimpleListStore'
import { CustomAlert } from '@planning/pages/TallymanV2/Components'
import { orderService } from '@planning/services'
import { ISearchAutocompleteStore } from '@planning/stores/SearchAutocompleteStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { EditOrdersPage } from '../EditOrders'
import { ContainerVisit } from '../stores/ContainerVisitItem'
import { IAmAContainerVisitToo, SelectOrderViewStore } from '../stores/SelectOrderViewStore'
import { AddUnitInfoForm } from './AddUnitInfoForm'
import { ContainerAutocompleteListItem } from './ContainerAutocompleteListItem'
import { ContainerAutocompleteListItemLoader } from './ContainerAutocompleteListItemLoader'
import { ContainerCard } from './ContainerCard'
import { ContainerVisitPaperLoader } from './ContainerVisitPaperLoader'
import { OrdersListCard } from './OrdersListCard'

interface IProps {
  searchStore: ISearchAutocompleteStore<ContainerDto>
  listStore: IListStore<ContainerVisit>
  viewStore: SelectOrderViewStore
  onCreateNewOrderClick?: () => void
  onNewContainerSubmit?: (data: UpsertContainerCommand) => Promise<void>
  showHavingOpenOrdersWarning?: boolean
  orderCreationOnly?: boolean
}

export const SearchContainerVisit = observer(
  ({
    searchStore,
    listStore,
    viewStore,
    onCreateNewOrderClick,
    onNewContainerSubmit,
    showHavingOpenOrdersWarning,
    orderCreationOnly,
  }: IProps) => {
    const { appViewStore, dialogStore } = usePlanningStore()

    const { t } = useTranslate()

    const [showContainerForm, setShowContainerForm] = useState(false)

    const renderAutocompleteOption = (option: ContainerDto) => {
      return <ContainerAutocompleteListItem container={option} />
    }

    const CreateNewOrderButton = () => (
      <Button
        variant='contained'
        onClick={onCreateNewOrderClick}
        sx={{ mt: '1rem' }}
        data-cy='create-new-order-btn'
      >
        {t('createNewOrder', 'Create new order')}
      </Button>
    )

    const CreateOrderFromNewContainer = () => (
      <Box sx={{ mt: '1rem' }}>
        <Button onClick={() => setShowContainerForm(true)} data-cy='create-new-container-btn'>
          {t('createNewUnit', 'Create new unit')}
        </Button>
        {onNewContainerSubmit && showContainerForm && (
          <AddUnitInfoForm containerNumber={searchStore.filter} onSubmit={onNewContainerSubmit} />
        )}
      </Box>
    )

    const RenderEmptyOrders = () => (
      <>
        <ContainerCard container={viewStore.selectedContainer} />
        <Box sx={{ mt: '1rem' }}>
          <CustomAlert
            severity='info'
            message={t('noOpenOrdersForThisContainer', 'No open orders for this container')}
          ></CustomAlert>
          {onCreateNewOrderClick && <CreateNewOrderButton />}
        </Box>
      </>
    )

    const convertContainerVisit = (containerVisit: ContainerVisit): IAmAContainerVisitToo => {
      return [
        {
          order: containerVisit[0]?.data,
          visit: containerVisit[0]?.visit?.data,
        },
        {
          order: containerVisit[1]?.data,
          visit: containerVisit[1]?.visit?.data,
        },
      ]
    }

    const onEditOrder = (containerVisit: ContainerVisit) => {
      viewStore.selectContainerVisit(convertContainerVisit(containerVisit))
      dialogStore.openDialog(<EditOrdersPage />)
    }

    const onDeleteOrder = async (containerVisit: ContainerVisit) => {
      const data = containerVisit[0] || containerVisit[1]

      try {
        const confirmed = await appViewStore.setOpenConfirmDialog(
          true,
          t('doYouWantToDeleteOrder', 'Do you want to delete order?'),
        )
        if (confirmed && data) {
          await orderService.delete(data.id, true)
          appViewStore.setShowAlert(
            'success',
            t('orderDeletedSuccessfully', 'Order deleted successfully'),
          )
        }
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToDelete', 'Failed to delete'))
      }
    }

    return (
      <>
        <SearchAutocomplete
          label={t('searchUnitNumber', 'Search unit number')}
          store={searchStore}
          renderOption={renderAutocompleteOption}
          renderLoadingOption={() => <ContainerAutocompleteListItemLoader />}
          getOptionLabel={c => c.number ?? ''}
          onChange={async (filter, value) => {
            listStore.setFilter(filter)
            if (value && typeof value != 'string') viewStore.selectContainer(value)
          }}
          testId={'container-visit-containers'}
        />

        {/* TODO: maybe refactor and improve this one */}
        {onNewContainerSubmit && searchStore.filter && !searchStore.items.length ? (
          <CreateOrderFromNewContainer />
        ) : (
          <>
            {showHavingOpenOrdersWarning && !!listStore.items.length && (
              <Box sx={{ mt: '1rem' }}>
                <CustomAlert
                  severity='warning'
                  message={`${viewStore.selectedContainer?.number} ${t(
                    'hasTheFollowingOpenOrders',
                    'has the following open orders',
                  )}`}
                ></CustomAlert>
              </Box>
            )}
            <SimpleList
              store={listStore}
              renderListItem={(containerVisit: ContainerVisit, i: number) => (
                <OrdersListCard
                  orderCreationOnly={orderCreationOnly}
                  containerVisit={containerVisit}
                  container={viewStore.selectedContainer}
                  key={`container-visit-paper-${i}`}
                  onClickEdit={() => onEditOrder(containerVisit)}
                  onClickDelete={() => onDeleteOrder(containerVisit)}
                />
              )}
              renderLoadingListItem={() => <ContainerVisitPaperLoader />}
              renderEmpty={() => RenderEmptyOrders()}
              showTotalResults={false}
            />
            {onCreateNewOrderClick && !!listStore.items.length && <CreateNewOrderButton />}
          </>
        )}
      </>
    )
  },
)
