import { createSvgIcon } from '@mui/material'

export const SwapBodyIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask id='path-1-inside-1_4378_28075' fill='white'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4 4C2.89543 4 2 4.89543 2 6V14C2 15.1046 2.89543 16 4 16H20C21.1046 16 22 15.1046 22 14V6C22 4.89543 21.1046 4 20 4H4ZM11 13C11 13.4734 11.4477 13.8571 12 13.8571C12.5523 13.8571 13 13.4734 13 13V7C13 6.52661 12.5523 6.14286 12 6.14286C11.4477 6.14286 11 6.52661 11 7V13ZM6 13.8571C5.44772 13.8571 5 13.4734 5 13L5 7C5 6.52661 5.44772 6.14286 6 6.14286C6.55229 6.14286 7 6.52661 7 7L7 13C7 13.4734 6.55228 13.8571 6 13.8571ZM17 13C17 13.4734 17.4477 13.8571 18 13.8571C18.5523 13.8571 19 13.4734 19 13V7C19 6.52661 18.5523 6.14286 18 6.14286C17.4477 6.14286 17 6.52661 17 7V13Z'
      />
    </mask>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 4C2.89543 4 2 4.89543 2 6V14C2 15.1046 2.89543 16 4 16H20C21.1046 16 22 15.1046 22 14V6C22 4.89543 21.1046 4 20 4H4ZM11 13C11 13.4734 11.4477 13.8571 12 13.8571C12.5523 13.8571 13 13.4734 13 13V7C13 6.52661 12.5523 6.14286 12 6.14286C11.4477 6.14286 11 6.52661 11 7V13ZM6 13.8571C5.44772 13.8571 5 13.4734 5 13L5 7C5 6.52661 5.44772 6.14286 6 6.14286C6.55229 6.14286 7 6.52661 7 7L7 13C7 13.4734 6.55228 13.8571 6 13.8571ZM17 13C17 13.4734 17.4477 13.8571 18 13.8571C18.5523 13.8571 19 13.4734 19 13V7C19 6.52661 18.5523 6.14286 18 6.14286C17.4477 6.14286 17 6.52661 17 7V13Z'
      fill='currentColor'
    />
    <rect x='4' y='15' width='2' height='5' rx='1' fill='currentColor' />
    <rect x='18' y='15' width='2' height='5' rx='1' fill='currentColor' />
  </svg>,
  'SwapBodyIcon',
)
