import { CarrierType } from '@storage/app/api'

export const mapCarrierVisitDiscriminatorToCarrierType = (discriminator: string): CarrierType => {
  switch (discriminator) {
    case 'VesselVisit':
      return CarrierType.Vessel
    case 'RailVisit':
      return CarrierType.Train
    case 'TruckVisit':
      return CarrierType.Truck
    case 'UniversalVisit':
      return CarrierType.Universal
    default:
      return CarrierType.Unknown
  }
}
