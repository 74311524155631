import * as yup from 'yup'
import { UserFormProfile } from './user-form.profile'

export const schema = (canManageTenant: boolean): yup.ObjectSchema<UserFormProfile> =>
  yup.object({
    id: yup.string().optional(),
    email: yup.string().label('Email').required(),
    firstName: yup.string().label('First name').required(),
    lastName: yup.string().label('Last name').required(),
    tenantId: canManageTenant
      ? yup.string().label('Tenant').required()
      : yup.string().label('Tenant'),
  })
