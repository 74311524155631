import { GeneralCargoItemPropertiesDto, GeneralCargoOccupancyActionDto } from '@storage/app/api'

export enum StockTrackingMethod {
  QUANTITY,
  IDENTIFIABLE_ITEMS,
}
export interface OccupancyDamage {
  isDamaged: boolean
  damageDescription: string
  damageImagePaths: string[]
}

export interface PlannedOccupancy extends GeneralCargoOccupancyActionDto {
  id: string
  newQuantity: number
}
export interface StockOccupancy extends GeneralCargoOccupancyActionDto, OccupancyDamage {
  id: string
  isNewOccupancy: boolean
  newQuantity: number
  reservedQuantity: number
  newProperties: GeneralCargoItemPropertiesDto[]
}

export type OccupancyItem = StockOccupancy | PlannedOccupancy
