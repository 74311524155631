import { Button, Stack, Typography } from '@mui/material'
import { ErrorCodes } from '@storage/app/api'
import { isApplicationDomainException } from '@storage/app/http-client/interceptors/domain-exception.response-interceptor'
import { PageHeader } from '@storage/components/PageHeader'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useGoBack } from '@storage/pages/yard-planning-dashboard-details/hooks/use-go-back.hook'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

export const GlobalAllocationRulesHeader = observer(() => {
  const { t } = useTranslate()
  const { globalAllocationRuleStore, snackbarStore, globalAllocationRulesUIStore } = useStores()

  const handleGoBack = useGoBack()

  const handleOnCancel = () => {
    if (globalAllocationRuleStore.hasChanges) {
      globalAllocationRulesUIStore.toggleUnsavedChangesDialog('generic')
    } else {
      handleGoBack()
    }
  }
  const handleOnSave = async () => {
    await globalAllocationRulesUIStore
      .persistAllChanges()
      .then(() => {
        snackbarStore.showMessage(
          t('changesSaveSuccess', 'All changes are saved successfully'),
          'success',
        )
        handleGoBack()
      })
      .catch(error => {
        if (isApplicationDomainException(error, ErrorCodes.AllocationSpaceConflict)) {
          snackbarStore.showMessage(
            t('insufficientAllocationSpace', 'Insufficient allocation space'),
            'error',
          )
        } else {
          // Generic error
          snackbarStore.showMessage(
            t('persistAllChangesFailure', 'An unexpected error occurred while saving the changes'),
            'error',
          )
        }
      })
      .finally(() => {
        globalAllocationRulesUIStore.setRequestLoadingState(false)
      })
  }

  const showActionButtons =
    !globalAllocationRulesUIStore.showAllocationRuleForm &&
    !globalAllocationRulesUIStore.showDestinationForm

  return (
    <PageHeader>
      <Typography variant='subtitle1'>{t('globalYardRules', 'Global Yard Rules')}</Typography>

      {showActionButtons && (
        <Stack flexDirection='row' gap={1}>
          <Button onClick={handleOnCancel}>{t('cancel', 'Cancel')}</Button>
          <Button
            onClick={handleOnSave}
            variant='contained'
            disabled={globalAllocationRulesUIStore.isRequestLoading}
          >
            {globalAllocationRulesUIStore.isRequestLoading
              ? t('savingChanges', 'Saving Changes...')
              : t('save', 'Save')}
          </Button>
        </Stack>
      )}
    </PageHeader>
  )
})
