export enum Permission {
  ReadYardBlocks = 'read:yard-blocks',
  WriteYardBlocks = 'write:yard-blocks',

  ReadPiers = 'read:piers',
  WritePiers = 'write:piers',

  ReadBerths = 'read:berths',
  WriteBerths = 'write:berths',

  ReadRules = 'read:rules',
  WriteRules = 'write:rules',

  ReadWeightClasses = 'read:weight-classes',
  WriteWeightClasses = 'write:weight-classes',

  ReadDangerousGoodsSettings = 'read:dangerous-goods-settings',
  WriteDangerousGoodsSettings = 'write:dangerous-goods-settings',

  ReadRailTracks = 'read:rail-tracks',
  WriteRailTracks = 'write:rail-tracks',
}
