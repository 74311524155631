import { alpha, Box, Stack, Typography } from '@mui/material'
import {
  CarrierVisitDirection,
  ContainerSection,
  StackDto,
  YardBlockBayDto,
  YardBlockDto,
  YardBlockRowDto,
} from '@storage/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'
import { getHighlightedBlockZones } from '../utils/yard-planning-dashboard-stack.util'
import { YardPlanningDashboardStacks } from './YardPlanningDashboardStacks'
import { CarrierVisitAllocationRulesV2UIStore } from '@storage/features/carrier-visit-allocation-rules/stores/carrier-visit-allocation-rules-v2.ui-store'
import { GlobalAllocationRulesUIStore } from '@storage/pages/global-allocation-rules/stores/global-allocation-rules.ui-store'

interface YardPlanningDashboardBlockProps {
  uiStore: CarrierVisitAllocationRulesV2UIStore | GlobalAllocationRulesUIStore
  yardBlock: YardBlockDto
  rows: YardBlockRowDto[]
  bays: YardBlockBayDto[]
  stacks: StackDto[]
  availableSpace: number
  carrierVisitId: number
  carrierVisitDirection: CarrierVisitDirection
}

const YardPlanningDashboardBlock = observer(
  ({
    uiStore,
    yardBlock,
    rows,
    bays,
    stacks,
    carrierVisitId,
    availableSpace,
    carrierVisitDirection,
  }: YardPlanningDashboardBlockProps) => {
    const { t } = useTranslate()
    const { palette } = useMinimalsTheme()

    const stackWidthPixels = '70px'
    const rowLabelPixels = '30px'
    const slotHeight = '24px'

    const sortedRows = useMemo(() => {
      return rows.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
    }, [rows])

    const sortedBays = useMemo(() => {
      return bays.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
    }, [bays])

    const getStacksForRow = useCallback(
      (rowId: string) => {
        return stacks
          .filter(stack => stack.rowId === rowId && stack.section === ContainerSection.Head)
          .sort((a, b) => a.baySequenceNumber - b.baySequenceNumber)
      },
      [stacks],
    )

    const highlightedZones = useMemo(() => {
      const destinations = uiStore.destinationsToHighlight
      if (destinations.length === 0) return null
      return getHighlightedBlockZones(stacks, destinations)
    }, [uiStore.destinationsToHighlight, stacks])

    return (
      <Stack gap={1}>
        <Stack flexDirection='row' marginLeft={rowLabelPixels} gap={1} alignItems='center'>
          <Typography variant='h4'>{yardBlock.name}</Typography>
          {availableSpace > 0 ? (
            <>
              <Typography variant='body1'>{t('freeSpace', 'Free space')}</Typography>|
              <Typography variant='body1'>20ft: {availableSpace}</Typography>
            </>
          ) : (
            <Typography>{t('fullyOccupied', 'Fully occupied')}</Typography>
          )}
        </Stack>

        <Box position='relative'>
          <Stack>
            {sortedRows.map(row => (
              <Stack key={`row_${row.id}`} flexDirection='row' height={slotHeight}>
                <Typography width={rowLabelPixels} variant='subtitle2' flex='none'>
                  {row.name}
                </Typography>

                <YardPlanningDashboardStacks
                  stackWidthPixels={stackWidthPixels}
                  stacks={getStacksForRow(row.id)}
                  maxTier={yardBlock.maxTier}
                  bayCount={bays.length}
                  carrierVisitId={carrierVisitId}
                  carrierVisitDirection={carrierVisitDirection}
                />
              </Stack>
            ))}
          </Stack>

          {highlightedZones?.map((zone, index) => (
            <Box
              key={index}
              position='absolute'
              top={`calc(${zone.startRow - 1} * ${slotHeight})`}
              left={`calc(${rowLabelPixels} + ${zone.startBay - 1} * ${stackWidthPixels})`}
              width={`calc(${zone.endBay - zone.startBay + 1} * ${stackWidthPixels})`}
              height={`calc(${zone.endRow - zone.startRow + 1} * ${slotHeight})`}
              sx={{
                border: zone.border ? `4px solid ${palette.info.main}` : 'none',
                backgroundColor: zone.fill ? alpha(palette.info.light, 0.4) : 'transparent',
                pointerEvents: 'none',
                zIndex: 1,
              }}
            />
          ))}
        </Box>

        <Stack flexDirection='row'>
          <Box width={rowLabelPixels} flex='none'></Box>
          {sortedBays.map(bay => (
            <Typography
              key={`bay_${bay.id}`}
              width={stackWidthPixels}
              textAlign='center'
              variant='subtitle2'
              flex='none'
            >
              {bay.name}
            </Typography>
          ))}
        </Stack>
      </Stack>
    )
  },
)

export default YardPlanningDashboardBlock
