import { CustomerDto } from '@storage/app/api'
import { customerService } from '@storage/services'
import { makeObservable, observable, runInAction } from 'mobx'

export class CustomerStore {
  customers: CustomerDto[] = []

  constructor() {
    makeObservable(this, {
      customers: observable,
    })
  }

  async loadCustomers() {
    const customers = await customerService.getAll()

    runInAction(() => {
      this.customers = customers
    })
  }
}
