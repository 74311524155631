import dayjs from 'dayjs'
import * as yup from 'yup'
import { WorkQueueFormProfile } from './workqueue-form.profile'

const dateTimeFormat = 'MM/DD/YYYY HH:mm'
const displayTimeFormat = 'MM/DD/YYYY hh:mm A'

export const schema = (
  containerMax: number,
  minStartTime: Date,
): yup.ObjectSchema<WorkQueueFormProfile> =>
  yup.object({
    bay: yup.number().label('Bay').required(),
    position: yup.string().label('Position').required(),
    operationType: yup.string().label('Operation').required(),
    containersAmount: yup
      .number()
      .label('Number of Containers')
      .min(1)
      .max(containerMax)
      .required(),
    twinContainers: yup.boolean().label('Twin containers').required(),
    totalMoves: yup.number().label('Total moves').required(),
    startDate: yup
      .date()
      .label('Start time')
      .test(
        'is-after-min',
        `Start time must be equals or after than ${dayjs(minStartTime).format(displayTimeFormat)}`,
        function (value) {
          const valueTimeString = dayjs(value).format(dateTimeFormat)
          const validationTimeString = dayjs(minStartTime).format(dateTimeFormat)

          const valueTime = dayjs(valueTimeString, dateTimeFormat)
          const validationTime = dayjs(validationTimeString, dateTimeFormat)

          return valueTime.isSame(validationTime) || valueTime.isAfter(validationTime)
        },
      )
      .required(),
    craneId: yup.string().label('Crane').required(),
  })
