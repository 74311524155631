import { SelectOption } from '@operations/app/models'
import { FilterChip } from '@operations/components/filter/filter.model'
import { ReeferTemperatureStore } from '@operations/stores/ReeferTemperatureStore'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import {
  ReeferMonitoringFilter,
  defaultValues,
} from '../components/filter/reefer-monitoring-filter.model'

export class ReeferMonitoringFiltersUIStore {
  containerNumber?: string
  isOpen = false
  filter?: ReeferMonitoringFilter

  constructor(private reeferTemperatureStore: ReeferTemperatureStore) {
    makeObservable(this, {
      containerNumber: observable,
      isOpen: observable,
      filter: observable,
      setContainerNumber: action,
      toggle: action,
      setFilter: action,
      removeFilter: action,
      isFilterFilled: computed,
      areFiltersAndSearchEmpty: computed,
      yardBlocks: computed,
      customers: computed,
      filterChips: computed,
    })
  }

  public setContainerNumber(containerNumber?: string) {
    if (this.containerNumber !== containerNumber) {
      this.containerNumber = containerNumber
    }
  }

  public toggle(): void {
    this.isOpen = !this.isOpen
  }

  public setFilter(filter?: ReeferMonitoringFilter) {
    if (!filter) {
      this.filter = defaultValues
    } else {
      this.filter = filter
    }
  }

  public removeFilter(filter: FilterChip): void {
    if (!this.filter) {
      return
    }

    const key = filter.key as keyof ReeferMonitoringFilter
    const filterValue = this.filter[key]

    if (filterValue !== undefined && filterValue !== null) {
      if (Array.isArray(filterValue)) {
        this.filter[key] = (filterValue as any).filter((x: string) => x !== filter.value)
      } else if (typeof filterValue === 'boolean') {
        this.filter[key] = false as any
      } else {
        this.filter[key] = null
      }
    }
  }

  public get filterChips() {
    if (!this.filter) {
      return []
    }

    const filterChips: FilterChip[] = []

    for (const propName in this.filter) {
      const key = propName as keyof ReeferMonitoringFilter
      const filterValue = this.filter[key]

      if (!filterValue) continue
      ;(filterValue as string[]).forEach(value => filterChips.push({ key, value, label: value }))
    }

    return filterChips
  }

  public get yardBlocks(): SelectOption[] {
    return _(this.reeferTemperatureStore.items.filter(x => x.yardBlock).map(x => x.yardBlock))
      .uniq()
      .map(x => ({ label: x, value: x }) as SelectOption)
      .value()
  }

  public get customers(): SelectOption[] {
    return _(this.reeferTemperatureStore.items.filter(x => x.customer).map(x => x.customer))
      .uniq()
      .map(x => ({ label: x, value: x }) as SelectOption)
      .value()
  }

  public get areFiltersAndSearchEmpty() {
    return !this.isFilterFilled && !this.containerNumber
  }

  public get isFilterFilled(): boolean {
    return (
      !!this.filter &&
      (!!this.filter.yardBlockIds?.length ||
        !!this.filter.inboundCarrierType ||
        !!this.filter.outboundCarrierType ||
        !!this.filter.isPluggedIn ||
        !!this.filter.status)
    )
  }
}
