import { Stack } from '@mui/system'
import { mapAllocationRuleTemplateDtoToFormValues } from '@storage/features/carrier-visit-allocation-rules/forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'
import {
  AllocationRuleTemplateFormProfile,
  defaultDestinationAndStackingStrategy,
} from '@storage/features/carrier-visit-allocation-rules/forms/allocation-rule-templates-form/allocation-rule-templates-form.profile'
import { schema } from '@storage/features/carrier-visit-allocation-rules/forms/allocation-rule-templates-form/allocation-rule-templates-form.scheme'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { AllocationRulePanelForm } from './AllocationRulePanelForm'
import { AllocationRulePanelFormDestination } from './AllocationRulePanelFormDestination'
import { AllocationRuleDto } from '@storage/types'
import { BaseAllocationRulesUIStore } from '@storage/stores/base-allocation-rules.ui-store'

interface AllocationRulePanelProps<T extends AllocationRuleDto> {
  uiStore: BaseAllocationRulesUIStore<T>
}

export const AllocationRulePanel = observer(
  <T extends AllocationRuleDto>({ uiStore }: AllocationRulePanelProps<T>) => {
    const [destinationFieldIndex, setDestinationFieldIndex] = useState(0)

    const formId = 'carrier_visit_allocation_rule_form'
    const allocationRuleFormType = uiStore.allocationRuleFormType

    const formExternalDefaultValues = uiStore.formExternalDefaultValues
    const existingAllocationRule = uiStore.selectedAllocationRule
      ? uiStore.getAllocationRuleById(uiStore.selectedAllocationRule.id)
      : undefined

    const getDefaultValues = (
      formType: string,
    ): AllocationRuleTemplateFormProfile | Record<string, never> => {
      switch (formType) {
        case 'Add':
          return formExternalDefaultValues || {}
        case 'Edit':
          return existingAllocationRule
            ? mapAllocationRuleTemplateDtoToFormValues(existingAllocationRule)
            : {}
        default:
          return {}
      }
    }

    const allocationRuleForm = useFormWithSchema<AllocationRuleTemplateFormProfile>(
      schema(false, true),
      getDefaultValues(allocationRuleFormType),
    )

    const { fields, append, remove, move } = useFieldArray({
      control: allocationRuleForm.control,
      name: 'destinationAndStackingStrategies',
    })

    const destinationAndStackingStrategiesFormValue = useWatch({
      control: allocationRuleForm.control,
      name: 'destinationAndStackingStrategies',
    })

    const goToAllocationRuleFormDestination = () => {
      uiStore.setShowDestinationForm(true)
      uiStore.setShowAllocationRuleForm(false)
    }

    const goToAllocationRuleForm = () => {
      uiStore.setShowAllocationRuleForm(true)
      uiStore.setShowDestinationForm(false)
    }

    const handleOnSubmit = (formValues: AllocationRuleTemplateFormProfile) => {
      if (allocationRuleFormType === 'Add') {
        uiStore.addAllocationRule(formValues)
      } else if (allocationRuleFormType === 'Edit') {
        uiStore.updateAllocationRule(formValues)
      }
    }

    const handleAddDestination = () => {
      const newPosition = fields.length + 1
      append({
        ...defaultDestinationAndStackingStrategy,
        position: newPosition,
      })
      setDestinationFieldIndex(fields.length)
      goToAllocationRuleFormDestination()
    }

    const handleEditDestination = (id: number) => {
      setDestinationFieldIndex(id)
      goToAllocationRuleFormDestination()
    }

    const handleDeleteDestination = (id: number) => {
      remove(id)
      // Update positions after deletion
      const updatedDestinations = destinationAndStackingStrategiesFormValue
        .filter((_, index) => index !== id)
        .map((dest, index) => ({
          ...dest,
          position: index + 1,
        }))
      allocationRuleForm.setValue('destinationAndStackingStrategies', updatedDestinations)
    }

    const handleReorderDestination = (startIndex: number, endIndex: number) => {
      move(startIndex, endIndex)
      // Update positions after reordering
      const updatedDestinations = [...destinationAndStackingStrategiesFormValue]
      const [movedItem] = updatedDestinations.splice(startIndex, 1)
      updatedDestinations.splice(endIndex, 0, movedItem)

      const reorderedDestinations = updatedDestinations.map((dest, index) => ({
        ...dest,
        position: index + 1,
      }))
      allocationRuleForm.setValue('destinationAndStackingStrategies', reorderedDestinations)
    }

    return (
      <Stack height='80vh'>
        {uiStore.showAllocationRuleForm ? (
          <AllocationRulePanelForm
            uiStore={uiStore}
            formId={formId}
            allocationRuleForm={allocationRuleForm}
            destinationAndStackingStrategiesFormValue={
              destinationAndStackingStrategiesFormValue || []
            }
            onSubmit={handleOnSubmit}
            onEditDestination={handleEditDestination}
            onAddDestination={handleAddDestination}
            onDeleteDestination={handleDeleteDestination}
            onReorderDestination={handleReorderDestination}
          />
        ) : uiStore.showDestinationForm ? (
          <AllocationRulePanelFormDestination
            uiStore={uiStore}
            fieldIndex={destinationFieldIndex}
            allocationRuleForm={allocationRuleForm}
            destinationAndStackingStrategiesFormValue={
              destinationAndStackingStrategiesFormValue || []
            }
            goBack={goToAllocationRuleForm}
          />
        ) : undefined}
      </Stack>
    )
  },
)
