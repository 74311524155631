import { RemainOnBoardUnitUpsertedDto, RestowResponseDto } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetRestowsQuery } from '@planning/messages/queries/getRestowsQueryHandler'
import _ from 'lodash'
import { action, computed, makeObservable } from 'mobx'
import { ItemStore } from '../base/ItemStore'
import { IRestowItem, RestowItem } from './RestowItem'

export class RestowItemStore extends ItemStore<RestowResponseDto, IRestowItem> {
  fetchedIds: Set<number> = new Set()

  constructor(private messageBus: IMessageBus) {
    super((key, data) => new RestowItem(key, data), {
      messageBus,
    })

    makeObservable(this, {
      unreadRestows: computed,

      fetchByVesselVisitId: action,
      receiveRestowMessages: action,
      receiveRestowMessage: action,
    })

    messageBus.subscribeEvent(GetRestowsQuery.type, this.receiveRestowMessages)
    messageBus.subscribeEvent(
      EventTypes.RemainOnBoardUnitsUpsertedEvent,
      this.receiveRemainOnBoardMessages,
    )
    messageBus.subscribeEvent(EventTypes.RemainOnBoardUnitsDeletedEvent, this.deleteRestowMessages)
  }

  get unreadRestows(): RestowResponseDto[] {
    return _(this.elements)
      .map(i => i.data)
      .value()
  }

  fetchByVesselVisitId = async (vesselVisitId: number): Promise<void> => {
    await this.messageBus.dispatchQuery(new GetRestowsQuery(vesselVisitId))
  }

  receiveRestowMessages = (event: IEvent<RestowResponseDto[]>): void => {
    if (event.payload) {
      console.log('RestowItemStore: receiveRestowMessages', event.payload)
      this.upsertBulk(event.payload)
    }
  }

  deleteRestowMessages = (event: IEvent<number[]>): void => {
    if (event.payload) {
      console.log('RestowItemStore: deleteRestowMessages', event)
      event.payload.forEach(i => {
        this.delete(i)
      })
    }
  }

  receiveRemainOnBoardMessages = (event: IEvent<RemainOnBoardUnitUpsertedDto[]>): void => {
    if (event.payload) {
      console.log('RestowItemStore: receiveRemainOnBoardMessages', event.payload)

      const mappedRestowResponse = event.payload
        .filter(i => i.isRestow)
        .map<RestowResponseDto>(i => ({
          ...i,
          customerId: i.customerId ?? 0,
          number: i.number ?? 'Unknown',
          destination: i.destination ?? {
            bay: 0,
            row: 0,
            tier: 0,
          },
          origin: i.origin ?? {
            bay: 0,
            row: 0,
            tier: 0,
          },
        }))

      this.upsertBulk(mappedRestowResponse)

      event.payload
        .filter(i => !i.isRestow)
        .forEach(i => {
          this.delete(i.id)
        })
    }
  }

  receiveRestowMessage = (event: IEvent<any>): void => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }
}
