import { PATHS } from '@host/app/paths'
import {
  Card,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Divider,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
} from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useOperationsStore } from '@tom-ui/operations'
import { ButtonOption, ButtonWithDropdown, ColoredLabel } from '@tom-ui/ui'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import { EquipmentDetailStatsDto, EquipmentStatsDto } from 'modules/operations/src/app/api'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'

interface Props {
  currentDate: Date
}
export const EquipmentStats = observer(({ currentDate }: Props) => {
  const { equipmentStore } = useOperationsStore()
  const [stats, setStats] = useState<EquipmentStatsDto[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const data = await equipmentStore.loadEquipmentStatistics(currentDate)
      setStats(data)
    }
    fetchData()
  }, [equipmentStore, currentDate])

  return (
    <Stack gap={2} flexDirection={'row'}>
      {stats.map(group => {
        return <EquipmentTypeStat key={group.equipmentType} stats={group} />
      })}
    </Stack>
  )
})

const EquipmentTypeStat = observer(({ stats }: { stats: EquipmentStatsDto }) => {
  const { t } = useTranslate()
  const navigate = useNavigate()

  const getEquipmentOptions = useMemo(() => {
    const options: ButtonOption[] = []

    if (stats.assignedToYard) {
      options.push({
        label: t('equipmentPage', 'Equipment page'),
        onClick: () => navigate(`/${PATHS.registers.equipments}`),
      })
    }

    _(stats.visits)
      .unionBy(x => x.visitId)
      .value()
      .forEach(v =>
        options.push({
          label: v.carrierName ?? v.carrierType,
          onClick: () =>
            v.carrierType === 'Train'
              ? navigate(`/EquipmentPlanningRail/${v.visitId}`)
              : navigate(`/EquipmentPlanning/${v.visitId}/equipments`),
        }),
      )

    return options
  }, [navigate, stats.assignedToYard, stats.visits, t])

  return (
    <Grid xs={4} item>
      <Card sx={{ height: '100%' }}>
        <CardHeader
          sx={{ height: '5rem' }}
          title={
            <Typography variant='h3'>{t(stats.equipmentType, stats.equipmentType)}</Typography>
          }
        />
        <CardContent sx={{ height: 'calc(100% - 5rem)' }}>
          <Stack gap={1} height='100%'>
            <EquipmentTypeStatField
              label={t('assignedToYard', 'Assigned to yard')}
              value={stats.assignedToYard}
              showYardBlocks
            />

            <EquipmentTypeStatField
              label={t('assignedToVisit', 'Assigned to visit')}
              value={stats.assignedToVisit}
              showCarrierVisits
            />
            <EquipmentTypeStatField
              label={t('notAssigned', 'Not assigned')}
              value={stats.notAssigned}
            />

            {stats.onMaintenance.length > 0 && (
              <EquipmentTypeStatField
                label={t('onMaintenance', 'Maintenance')}
                value={stats.onMaintenance}
              />
            )}

            {getEquipmentOptions.length > 0 && (
              <Stack alignItems='center' height='100%' justifyContent='end'>
                <ButtonWithDropdown
                  label={t('manage', 'Manage')}
                  color='primary'
                  options={getEquipmentOptions}
                />
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  )
})

const EquipmentTypeStatField = observer(
  ({
    label,
    value,
    showYardBlocks,
    showCarrierVisits,
  }: {
    label: string
    value: EquipmentDetailStatsDto[]
    showYardBlocks?: boolean
    showCarrierVisits?: boolean
  }) => {
    const anchorRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(false)

    const handleToggle = () => {
      setOpen(prevOpen => !prevOpen)
    }

    return (
      <Stack flexDirection='row' justifyContent='space-between'>
        <Typography variant='body2' color={'secondary'}>
          {label}
        </Typography>
        <Typography
          variant='body1'
          sx={{ cursor: value.length > 0 ? 'pointer' : undefined }}
          onClick={() => (value.length > 0 ? handleToggle() : undefined)}
          ref={anchorRef}
        >
          {value.length}
        </Typography>

        <Popper id={`${label}_popper`} open={open} anchorEl={anchorRef.current} placement='left'>
          <Paper elevation={8} sx={{ m: 1 }}>
            <ClickAwayListener onClickAway={() => handleToggle()}>
              <Stack divider={<Divider />} gap={1} sx={{ width: '100%', maxWidth: 360 }} py={1}>
                {value.map(x => (
                  <Stack key={`${label}_${x.name}`} gap={1} px={1}>
                    <Typography variant='subtitle2'>{x.name}</Typography>
                    {showYardBlocks && (
                      <Stack flexDirection='row' flexWrap='wrap' gap={1}>
                        {x.yardBlockNames.map(yb => (
                          <ColoredLabel
                            key={`${label}_${x.name}_${yb}`}
                            label={yb}
                            color='primary'
                          />
                        ))}
                      </Stack>
                    )}

                    {showCarrierVisits && (
                      <Stack flexDirection='row' flexWrap='wrap' gap={1}>
                        {x.carrierVisitNames.map(cv => (
                          <ColoredLabel
                            key={`${label}_${x.name}_${cv}`}
                            label={cv}
                            color='primary'
                          />
                        ))}
                      </Stack>
                    )}
                  </Stack>
                ))}
              </Stack>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </Stack>
    )
  },
)
