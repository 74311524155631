import { createSvgIcon } from '@mui/material'

export const HumidityPercentageIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='14' viewBox='0 0 12 14' fill='none'>
    <path
      d='M5.99996 13.6667C4.47774 13.6667 3.20829 13.1444 2.19163 12.1C1.17496 11.0555 0.666626 9.75555 0.666626 8.19999C0.666626 7.5111 0.822182 6.82221 1.13329 6.13332C1.4444 5.44443 1.83329 4.78332 2.29996 4.14999C2.76663 3.51666 3.27218 2.92221 3.81663 2.36666C4.36107 1.8111 4.86663 1.32777 5.33329 0.916656C5.42218 0.827768 5.52496 0.763879 5.64163 0.72499C5.75829 0.686101 5.87774 0.666656 5.99996 0.666656C6.12218 0.666656 6.24163 0.686101 6.35829 0.72499C6.47496 0.763879 6.57774 0.827768 6.66663 0.916656C7.13329 1.32777 7.63885 1.8111 8.18329 2.36666C8.72774 2.92221 9.23329 3.51666 9.69996 4.14999C10.1666 4.78332 10.5555 5.44443 10.8666 6.13332C11.1777 6.82221 11.3333 7.5111 11.3333 8.19999C11.3333 9.75555 10.825 11.0555 9.80829 12.1C8.79163 13.1444 7.52218 13.6667 5.99996 13.6667ZM7.66663 11C7.9444 11 8.18052 10.9028 8.37496 10.7083C8.5694 10.5139 8.66663 10.2778 8.66663 9.99999C8.66663 9.72221 8.5694 9.4861 8.37496 9.29166C8.18052 9.09721 7.9444 8.99999 7.66663 8.99999C7.38885 8.99999 7.15274 9.09721 6.95829 9.29166C6.76385 9.4861 6.66663 9.72221 6.66663 9.99999C6.66663 10.2778 6.76385 10.5139 6.95829 10.7083C7.15274 10.9028 7.38885 11 7.66663 11ZM3.83329 10.5C3.96663 10.6333 4.12218 10.7 4.29996 10.7C4.47774 10.7 4.63329 10.6333 4.76663 10.5L8.16663 7.09999C8.29996 6.96666 8.36663 6.8111 8.36663 6.63332C8.36663 6.45555 8.29996 6.29999 8.16663 6.16666C8.03329 6.03332 7.87496 5.96666 7.69163 5.96666C7.50829 5.96666 7.34996 6.03332 7.21663 6.16666L3.83329 9.54999C3.69996 9.68332 3.63329 9.84166 3.63329 10.025C3.63329 10.2083 3.69996 10.3667 3.83329 10.5ZM4.33329 7.66666C4.61107 7.66666 4.84718 7.56943 5.04163 7.37499C5.23607 7.18055 5.33329 6.94443 5.33329 6.66666C5.33329 6.38888 5.23607 6.15277 5.04163 5.95832C4.84718 5.76388 4.61107 5.66666 4.33329 5.66666C4.05552 5.66666 3.8194 5.76388 3.62496 5.95832C3.43052 6.15277 3.33329 6.38888 3.33329 6.66666C3.33329 6.94443 3.43052 7.18055 3.62496 7.37499C3.8194 7.56943 4.05552 7.66666 4.33329 7.66666Z'
      fill='currentColor'
    />
  </svg>,
  'HumidityPercentageIcon',
)
