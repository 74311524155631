import { SummaryEntryDto } from '@billing/app/api'
import { useBillingServiceStore } from '@billing/AppProvider'
import { Stack } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { ReportSummaryCard } from '../reportSummaryCard/ReportSummaryCard'
import { ReportSummaryTable } from '../reportSummaryTable/ReportSummaryTable'

export const StorageReport = observer(() => {
  const { storageReportUIStore } = useBillingServiceStore()
  const { t } = useTranslate()

  useEffect(() => {
    //TODO: how to ensure that filter is set?
    const loadData = async () => {
      await storageReportUIStore.loadSummary()
    }

    loadData()
  }, [storageReportUIStore])

  const fullContainers = (containers: SummaryEntryDto[]) =>
    containers.filter(c => c.isEmpty === false)

  const emptyContainers = (containers: SummaryEntryDto[]) =>
    containers.filter(c => c.isEmpty === true)

  const operatorName = (name: string) =>
    name.length ? name : t('unknownOperator', 'UNKNOWN OPERATOR')

  return (
    <Stack width='100%' gap={theme => theme.customSpacing.m}>
      <ReportSummaryCard
        title={t('total', 'Total')}
        totalNumber={storageReportUIStore.totalDays}
        numberDescription={t('days', 'Days')}
      />

      {storageReportUIStore.summary.map((s, i) => (
        <ReportSummaryTable
          key={`${s.name}-${i.toString()}`}
          title={operatorName(s.name)}
          subtitle=''
          sections={[
            {
              nameColumn: t('full', 'Full'),
              valueColumn: t('days', 'Days'),
              entries: fullContainers(s.containers),
            },
            {
              nameColumn: t('empty', 'Empty'),
              valueColumn: t('days', 'Days'),
              entries: emptyContainers(s.containers),
            },
          ]}
        />
      ))}
    </Stack>
  )
})
