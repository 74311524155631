import { Box, Tab, Tabs } from '@mui/material'
import { CarrierVisitDirection } from '@storage/app/api'
import { useTranslate } from '@tolgee/react'
import { SyntheticEvent } from 'react'

interface CarrierVisitDirectionTabsProps {
  value: CarrierVisitDirection
  onChange: (event: SyntheticEvent, newValue: CarrierVisitDirection) => void
}

const CarrierVisitDirectionTabs = ({ value, onChange }: CarrierVisitDirectionTabsProps) => {
  const { t } = useTranslate()

  const carrierVisitDirectionTabs = [
    { value: CarrierVisitDirection.Outbound, label: t('outbound', 'Outbound') },
    { value: CarrierVisitDirection.Inbound, label: t('inbound', 'Inbound') },
  ]

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={value}
        onChange={onChange}
        sx={{
          '& .MuiTab-root': {
            mr: '1rem !important',
            minWidth: 90,
          },
        }}
        TabIndicatorProps={{
          sx: {
            height: 2,
          },
        }}
      >
        {carrierVisitDirectionTabs.map(tab => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={tab.label}
            data-cy={'yard-planning-carrier-direction'}
          />
        ))}
      </Tabs>
    </Box>
  )
}

export default CarrierVisitDirectionTabs
