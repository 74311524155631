import { Alert, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { IsoCodeMappingUIStore } from '../stores/iso-code-mapping.ui-store'
import { IsoCodeMappingCard } from './IsoCodeMappingCard'

interface IProps {
  viewStore: IsoCodeMappingUIStore
}

export const IsoCodeMappingEmptyMessage: FC = observer(() => {
  const { t } = useTranslate()

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'70vh'}>
      <Box textAlign={'center'}>
        <Typography variant='subtitle1' mb={'1rem'}>
          {t('noResultFound', 'No result found')}
        </Typography>
        <Typography variant='body1'>
          {t('', ' Try adjusting your search to find a standard ')}
        </Typography>
        <Typography variant='body1' mb={'1rem'}>
          {t('orAddYourFirstContainer', 'or custom ISO code or create a new one.')}
        </Typography>
      </Box>
    </Box>
  )
})

export const IsoCodeMappingList: FC<IProps> = observer(({ viewStore }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const hasFilteredItems =
    viewStore.filter && (!viewStore.isoCodeMappings || viewStore.isoCodeMappings.length === 0)

  return (
    <Container
      sx={{
        paddingY: theme.customSpacing.m,
      }}
    >
      {(!viewStore.filter || viewStore.filter.length !== 4) && (
        <Alert severity='info' sx={{ marginBottom: theme.customSpacing.s }}>
          {t('onlyCustomIsoCodesAreShownHere', 'Only custom ISO codes are shown here')}
        </Alert>
      )}
      {hasFilteredItems && <IsoCodeMappingEmptyMessage />}
      {viewStore.isoCodeMappings.map(item => (
        <IsoCodeMappingCard
          key={item.id}
          {...item}
          onClickEdit={() => {
            viewStore.toggleFormDialog(true, item)
          }}
        />
      ))}
    </Container>
  )
})
