import { Box, Chip, Grid, Paper, Stack, TextField } from '@mui/material'
import { DangerousGoodsDefinitionDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { DangerousGoodAutoComplete } from '@planning/components/dangerous-goods/DangerousGoodsAutoComplete'
import { UnNumbersAutoComplete } from '@planning/components/dangerous-goods/UnNumbersAutoComplete'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { CustomInputAdornment, FieldBox } from 'modules/ui/src/theme/components'
import { FC, useCallback, useEffect, useState } from 'react'
import { Controller, useWatch } from 'react-hook-form'

interface IProps {
  control: any
  register: any
  errors: any
  getValues: (field: string) => any
  isReeferContainer: boolean
  isDangerousContainer: boolean
  isEmpty: boolean
  prefix: string
  setValue?: (value: any) => void
}

export const CreateOrderFormCargoStatusFieldBox: FC<IProps> = observer(
  ({
    control,
    register,
    errors,
    getValues,
    isReeferContainer,
    isDangerousContainer,
    isEmpty,
    prefix, // Use the prefix to differentiate fields
    setValue,
  }) => {
    const { t } = useTranslate()
    const imoClasses = useWatch({ control, name: `${prefix}.imoClasses` })
    const dangerousGoodsDefinitions = useWatch({
      control,
      name: `${prefix}.dangerousGoodsDefinitions`,
    })

    const { dangerousGoodsDefinitionDataStore } = usePlanningStore()

    useEffect(() => {
      dangerousGoodsDefinitionDataStore.setPageSize(25)
    }, [dangerousGoodsDefinitionDataStore])

    const handleUNNumbersInputChange = useCallback(
      (newValue?: string) => {
        dangerousGoodsDefinitionDataStore.setFilter(newValue)
      },
      [dangerousGoodsDefinitionDataStore],
    )

    const [isDangerous, setIsDangerous] = useState<boolean>(isDangerousContainer)
    const [isTemperatureControlled, setIsTemperatureControlled] = useState<boolean>(
      !!getValues(`${prefix}.temperature`),
    )
    const [imoClassMatchingUNNUmbersError, setImoClassMatchingUNNUmbersError] = useState<
      string | undefined
    >(undefined)

    const [showCargoDetails, setShowCargoDetails] = useState<boolean>(isEmpty)

    const [unNumbersSelected, setUnNumbers] = useState<DangerousGoodsDefinitionDto[] | undefined>(
      getValues(`${prefix}.dangerousGoodsDefinitions`),
    )

    const handleUnNumberChange = (UnNumber?: DangerousGoodsDefinitionDto[]) => {
      const currentAssignedCodes = getValues(`${prefix}.imoClasses`) ?? []
      const newImoClasses = UnNumber
        ? [
            ...UnNumber.flatMap(un => un.primaryImoClass || []),
            ...UnNumber.flatMap(un => un.secondaryImoClasses || []),
          ]
        : []

      // Combine and remove duplicates
      const updatedAssignedCodes = [...new Set([...currentAssignedCodes, ...newImoClasses])]

      setValue && setValue(updatedAssignedCodes)
    }

    useEffect(() => {
      if (isDangerous) {
        const imoClassesErrorMessages = {
          single: {
            id: 'missingImoClassesSingle',
            defaultValue:
              'Please add the following IMO class: {classes} to match the selected UN numbers.',
          },
          plural: {
            id: 'missingImoClassesMultiple',
            defaultValue:
              'Please add the following IMO classes: {classes} to match the selected UN numbers.',
          },
        }
        const missingImoClasses =
          dangerousGoodsDefinitions
            ?.map((dg: DangerousGoodsDefinitionDto) => dg.primaryImoClass)
            .filter((imoClass: string) => !imoClasses.includes(imoClass)) ?? []

        if (missingImoClasses.length > 0) {
          const errorKey = missingImoClasses.length === 1 ? 'single' : 'plural'
          setImoClassMatchingUNNUmbersError(
            t(
              imoClassesErrorMessages[errorKey].id,
              imoClassesErrorMessages[errorKey].defaultValue,
              {
                classes: missingImoClasses.join(', '),
              },
            ),
          )
        } else {
          setImoClassMatchingUNNUmbersError(undefined)
        }
      } else {
        setImoClassMatchingUNNUmbersError(undefined)
      }
    }, [isDangerous, imoClasses, dangerousGoodsDefinitions, t])

    return (
      <Box mt={'1rem'}>
        <FieldBox
          label={t(`${prefix}.cargoStatus`, 'Cargo status')}
          rightRender={
            <Controller
              control={control}
              name={`${prefix}.isEmpty`}
              render={({ field: { onChange } }) => (
                <Box>
                  <Chip
                    label={t('full', 'Full')}
                    color='primary'
                    sx={{ padding: '0 1rem', mr: '.75rem' }}
                    variant={!showCargoDetails ? 'outlined' : undefined}
                    onClick={() => {
                      onChange(false)
                      setShowCargoDetails(true)
                    }}
                  />
                  <Chip
                    label={t('empty', 'Empty')}
                    color='primary'
                    sx={{ padding: '0 1rem' }}
                    variant={showCargoDetails ? 'outlined' : undefined}
                    onClick={() => {
                      onChange(true)
                      setShowCargoDetails(false)
                    }}
                  />
                </Box>
              )}
            />
          }
        >
          {showCargoDetails && (
            <Stack gap={2}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label={`${t('grossWeight', 'Gross weight')}*`}
                    variant='outlined'
                    type='tel'
                    InputProps={{
                      endAdornment: (
                        <CustomInputAdornment position='end' color='secondary'>
                          kg
                        </CustomInputAdornment>
                      ),
                    }}
                    {...register(`${prefix}.grossWeight`, { required: true, valueAsNumber: true })}
                    name={`${prefix}.grossWeight`}
                    error={errors[prefix] && !!errors[prefix]?.grossWeight}
                    helperText={
                      errors[prefix]?.grossWeight ? t('fieldIsRequired', 'Field is required.') : ''
                    }
                    data-cy='gross-weight-input'
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label={`${t('vgm', 'VGM')}`}
                    variant='outlined'
                    type='tel'
                    InputProps={{
                      endAdornment: (
                        <CustomInputAdornment position='end' color='secondary'>
                          kg
                        </CustomInputAdornment>
                      ),
                    }}
                    {...register(`${prefix}.vgm`, { valueAsNumber: true })}
                    name={`${prefix}.vgm`}
                    error={!!errors[`${prefix}.vgm`]}
                    helperText={
                      errors[`${prefix}.vgm`] ? t('fieldIsRequired', 'Field is required.') : ''
                    }
                    data-cy='vgm-input'
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name={`${prefix}.content`}
                    defaultValue=''
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label={`${t('content', 'Content')}`}
                        variant='outlined'
                        type='text'
                        name={`${prefix}.content`}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Paper variant='outlined' sx={{ p: '1rem' }}>
                <Controller
                  control={control}
                  name={`${prefix}.imoClasses`}
                  rules={{ required: isDangerous }}
                  render={({ field: { onChange } }) => (
                    <DangerousGoodAutoComplete
                      assignedCodes={getValues(`${prefix}.imoClasses`) ?? []}
                      enableSelect={isDangerous}
                      onToggleSelectDangerousGoods={() => setIsDangerous(!isDangerous)}
                      onDangerousGoodChange={newValues => {
                        onChange(newValues as [])
                      }}
                      error={!!errors[`${prefix}.imoClasses`] || !!imoClassMatchingUNNUmbersError}
                      helperText={imoClassMatchingUNNUmbersError}
                    />
                  )}
                />

                {isDangerous && (
                  <Controller
                    control={control}
                    name={`${prefix}.dangerousGoodsDefinitions`}
                    render={({ field: { onChange } }) => (
                      <UnNumbersAutoComplete
                        enableSelect={isDangerous}
                        onInputChange={handleUNNumbersInputChange}
                        options={dangerousGoodsDefinitionDataStore.pageItems}
                        onUnNumbersChange={newValues => {
                          onChange(newValues as [])
                        }}
                        selectedUnNumbers={unNumbersSelected}
                        handleOnChange={UnNumber => {
                          setUnNumbers(UnNumber)
                          handleUnNumberChange(UnNumber)
                        }}
                      />
                    )}
                  />

                  /*<TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  inputProps={{ maxLength: 4 }}
                  label={`${t('unNumber', 'UN Number')}`}
                  {...register(`${prefix}.unNumber`)}
                  variant='outlined'
                  type='text'
                  name={`${prefix}.unNumber`}
                />*/
                )}
              </Paper>
            </Stack>
          )}
        </FieldBox>
      </Box>
    )
  },
)
