import {
  CreateCoolingOrdersCommand,
  CreateStrippingOrderCommand,
  CreateStuffingOrderCommand,
  ServiceOrdersApi,
  UpdateCargoItemActualAmountCommand,
  UpdateCoolingOrderCommand,
  UpdateStrippingOrderCargoItemActualAmountCommand,
  UpdateStrippingOrderCommand,
  UpdateStuffingOrderCommand,
} from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class ServiceOrdersService {
  httpClient = createApiClient(ServiceOrdersApi)

  async getByIds(ids: number[]) {
    const { data } = await this.httpClient.getByIds(ids)
    return data
  }

  async getStuffingOrdersByIds(ids: number[]) {
    const { data } = await this.httpClient.getByIds(ids)

    return data
  }

  async getCoolingOrdersByIds(ids: number[]) {
    const { data } = await this.httpClient.getCoolingOrdersByIds(ids)

    return data
  }

  async getStrippingOrdersByIds(ids: number[]) {
    const { data } = await this.httpClient.getStrippingOrdersByIds(ids)

    return data
  }

  async createStuffingOrder(cmd: CreateStuffingOrderCommand) {
    await this.httpClient.stuffingOrder(cmd)
  }

  async updateStuffingOrder(cmd: UpdateStuffingOrderCommand) {
    await this.httpClient.updateStuffingOrder(cmd)
  }

  async deleteStuffingOrder(id: number) {
    await this.httpClient.deleteStuffingOrder(id)
  }

  async createStrippingOrder(cmd: CreateStrippingOrderCommand) {
    await this.httpClient.strippingOrder(cmd)
  }

  async updateStrippingOrder(cmd: UpdateStrippingOrderCommand) {
    await this.httpClient.updateStrippingOrder(cmd)
  }

  async deleteStrippingOrder(id: number) {
    await this.httpClient.deleteStrippingOrder(id)
  }

  async createCoolingOrder(cmd: CreateCoolingOrdersCommand) {
    await this.httpClient.createCoolingOrders(cmd)
  }

  async updateCoolingOrder(cmd: UpdateCoolingOrderCommand) {
    await this.httpClient.updateCoolingOrder(cmd)
  }

  async deleteCoolingOrder(id: number) {
    await this.httpClient.deleteCoolingOrder(id)
  }

  async confirmCargoItemActualAmmount(cmd: UpdateCargoItemActualAmountCommand) {
    await this.httpClient.updateCargoItemActualAmount(cmd)
  }

  async confirmStrippingOrderCargoItemActualAmmount(
    cmd: UpdateStrippingOrderCargoItemActualAmountCommand,
  ) {
    await this.httpClient.updateStrippingOrderCargoItemActualAmount(cmd)
  }
}

const serviceOrdersService = new ServiceOrdersService()

export default serviceOrdersService
