import { Stack } from '@mui/material'
import { CoolingOrderDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { CoolingOrderTemperatureCheckWarning } from './CoolingOrderTemperatureCheckWarning'
import { CoolingOrderUnpluggedTooLongWarning } from './CoolingOrderUnpluggedTooLongWarning'

interface Props {
  order: CoolingOrderDto
}
export const CoolingOrderWarnings = observer(({ order }: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  return (
    <Stack flexDirection='row' gap={theme.customSpacing.xs}>
      {order.isPluggedIn && <ColoredLabel color='primary' label={t('pluggedIn', 'Plugged in')} />}
      {order.isPlugInRequired && (
        <ColoredLabel color='warning' label={t('plugInRequired', 'Plug in required')} />
      )}
      <CoolingOrderUnpluggedTooLongWarning order={order} />
      {order.isPlugOutRequired && (
        <ColoredLabel color='warning' label={t('plugOutRequired', 'Plug out required')} />
      )}
      <CoolingOrderTemperatureCheckWarning order={order} />

      {(order.temperatureDeviation || order.humidityDeviation || order.ventilationDeviation) && (
        <ColoredLabel color='warning' label={t('deviationRegistered', 'Deviation registered')} />
      )}

      {order.lastRecordedAdditionalInfo?.trim && (
        <ColoredLabel color='warning' label={t('hasError', 'Has error')} />
      )}
    </Stack>
  )
})
