import { Room } from '@mui/icons-material'
import { Accordion, AccordionSummary, Divider, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { CarrierType, CarrierVisitDirection } from '@storage/app/api'
import { useTranslate } from '@tolgee/react'
import { ArrowDownRightIcon, ArrowUpLeftIcon, GeneralIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { mapYardPositionDtoToYardPositionDescriptor } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'
import { AllocationRuleType } from '../interfaces'
import AllocationRuleTemplateFacetList from './AllocationRuleTemplateFacetList'
import IconActionMenu from './IconActionMenu'
import ListItemSelectionIndicator from './ListItemSelectionIndicator'
import { useStackingStrategyOptions } from '../hooks/use-stacking-strategy-options.hook'
import { AllocationRuleDto } from '@storage/types'
import { BaseAllocationRulesUIStore } from '@storage/stores/base-allocation-rules.ui-store'

interface CarrierVisitAllocationRulesListItemProps<T extends AllocationRuleDto> {
  uiStore: BaseAllocationRulesUIStore<T>
  allocationRule: T
  dragHandleProps?: any
  carrierVisitDirection?: CarrierVisitDirection
  variant: AllocationRuleType
  editable?: boolean
  showDragIndicator?: boolean
}

const CarrierVisitAllocationRulesListItem = observer(
  <T extends AllocationRuleDto>({
    allocationRule,
    dragHandleProps,
    carrierVisitDirection,
    variant,
    uiStore,
    editable = true,
    showDragIndicator = true,
  }: CarrierVisitAllocationRulesListItemProps<T>) => {
    const { t } = useTranslate()
    const { palette } = useMinimalsTheme()
    const { getStackingStrategyTranslationKey } = useStackingStrategyOptions()

    const [expanded, setExpanded] = useState(false)

    const destinations = useMemo(
      () =>
        mapYardPositionDtoToYardPositionDescriptor(allocationRule.destinationAndStackingStrategies),
      [allocationRule.destinationAndStackingStrategies],
    )

    const isSelected = allocationRule.id === uiStore.selectedAllocationRule?.id

    const handleOnClick = () => uiStore.toggleAllocationRule(allocationRule)

    const handleOnEdit = () => {
      uiStore.setOpenDialogForOnlyContainerNumber(!!allocationRule.facets.containerNumber)

      uiStore.setSelectedAllocationRule(allocationRule)
      uiStore.setAllocationRuleFormType('Edit')
      uiStore.setShowAllocationRuleForm(true)
    }

    const handleOnDelete = () => uiStore.toggleDeletionConfirmationDialog(allocationRule.id)

    const allocationSummary = uiStore.getAllocationSummary(allocationRule.id)

    return (
      <Stack
        sx={{
          '&:hover': {
            backgroundColor: !expanded ? palette.background.neutral : undefined,
          },
        }}
        direction='row'
        data-cy='allocation-rule'
      >
        <ListItemSelectionIndicator
          selected={isSelected}
          dragHandleProps={dragHandleProps}
          showDragIndicator={showDragIndicator}
        />

        <Stack flex={1} onClick={handleOnClick} sx={{ userSelect: 'none' }}>
          <Accordion
            expanded={expanded}
            onChange={(_, isExpanded) => setExpanded(isExpanded)}
            disableGutters
            sx={{
              '&.Mui-expanded': {
                boxShadow: 'none',
              },
            }}
          >
            <AccordionSummary
              sx={{
                pl: 1,
                '& .MuiAccordionSummary-content': {
                  my: 0,
                },
              }}
              onClick={e => e.stopPropagation()}
            >
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                sx={{
                  width: '100%',
                }}
              >
                <Typography noWrap variant='subtitle1' sx={{ flexGrow: 1 }}>
                  {allocationRule.name}
                </Typography>
                <GeneralIcon sx={{ color: palette.grey[600], mr: 0.5 }} />
                <Typography
                  noWrap
                  variant='subtitle1'
                  fontSize='small'
                  sx={{ color: palette.grey[600] }}
                  mr={1}
                >
                  x {allocationSummary?.length || 0}
                </Typography>
                {editable && (
                  <Box>
                    <IconActionMenu onEdit={handleOnEdit} onDelete={handleOnDelete} />
                  </Box>
                )}
              </Stack>
            </AccordionSummary>

            <Stack p={1} pt={0} sx={{ gap: 1 }}>
              <AllocationRuleTemplateFacetList facets={allocationRule.facets} />

              {variant === 'global' && editable && (
                <Box display='flex' alignItems='center' sx={{ color: palette.grey[600] }}>
                  {carrierVisitDirection === CarrierVisitDirection.Outbound ? (
                    <>
                      <ArrowUpLeftIcon />
                      {allocationRule.facets.outboundCarrierType !== CarrierType.Universal
                        ? allocationRule.facets.outboundCarrierType
                        : t('truckVesselRail', 'Truck Vessel Rail')}
                    </>
                  ) : (
                    <>
                      <ArrowDownRightIcon />
                      {allocationRule.facets.inboundCarrierType !== CarrierType.Universal
                        ? allocationRule.facets.inboundCarrierType
                        : t('truckVesselRail', 'Truck Vessel Rail')}
                    </>
                  )}
                </Box>
              )}

              <Divider sx={{ mt: 0 }} />

              <Stack
                gap={1}
                direction='column'
                alignItems='center'
                sx={{ color: palette.grey[600] }}
              >
                {destinations.map((destination, index) => (
                  <Stack
                    key={index}
                    direction='row'
                    alignItems='center'
                    spacing={0.5}
                    sx={{ width: '100%' }}
                  >
                    <Room fontSize='small' />
                    <Typography>{destination}</Typography>
                    <Box sx={{ flexGrow: 0.9 }} />
                    <Typography>
                      {t(
                        getStackingStrategyTranslationKey(
                          allocationRule.destinationAndStackingStrategies[index].stackingStrategy,
                        ),
                      )}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Accordion>
        </Stack>
      </Stack>
    )
  },
)

export default CarrierVisitAllocationRulesListItem
