import { Card } from '@mui/material'
import Box from '@mui/material/Box'
import Step from '@mui/material/Step'
import StepContent from '@mui/material/StepContent'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import Typography from '@mui/material/Typography'
import { useTranslate } from '@tolgee/react'
import { FC, useState } from 'react'
import { RailTallyViewStore } from '../Stores/RailTallyViewStore'

export interface IStepItemProps {
  label: string
  icon?: JSX.Element
  onSelect?: () => void
  testId?: string
}

export interface IStep {
  label: string
  items: IStepItemProps[]
}

export interface IStepperProps {
  steps: IStep[]
  store: RailTallyViewStore
}

const StepItem: FC<IStepItemProps> = ({ label, icon, onSelect, testId }) => (
  <Card
    sx={{
      p: '1rem',
      m: '.5rem 0',
      borderRadius: '8px',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
    }}
    onClick={onSelect}
    data-cy={testId}
  >
    <Typography fontWeight='bold'>{label}</Typography>
    {icon ?? <></>}
  </Card>
)

const SelectedItem: FC<{ item: string; label: string; handleBack: () => void }> = ({
  item,
  label,
  handleBack,
}) => {
  const { t } = useTranslate()

  return (
    <Card
      sx={{
        p: '1rem',
        m: '-2rem .5rem .5rem 2rem',
        borderRadius: '8px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
      }}
      onClick={handleBack}
    >
      <Box>
        <Typography fontSize={'.8rem'}>{label}</Typography>
        <Typography fontWeight='bold'>{item}</Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography>{t('edit', 'Edit')}</Typography>
      </Box>
    </Card>
  )
}

export const TallySelectionStepper: FC<IStepperProps> = ({ steps, store }) => {
  const [activeStep, setActiveStep] = useState(store.stepperSelectedItems.length === 3 ? 2 : 0)

  const handleStepChange = (stepIndex: number) => {
    if (stepIndex === 0) {
      store.setStepperSelectedItems([])
    } else if (stepIndex === 1) {
      const updatedItems = store.stepperSelectedItems.slice(0, 1)
      store.setStepperSelectedItems(updatedItems)
    }

    setActiveStep(stepIndex)
  }

  const handleSelectItem = (stepIndex: number, item: string) => {
    const newSelectedItems = [...store.stepperSelectedItems]
    newSelectedItems[stepIndex] = item
    store.setStepperSelectedItems(newSelectedItems)
    handleStepChange(stepIndex + 1)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} orientation='vertical' connector={null}>
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              {step.items.map((item, itemIndex) => (
                <StepItem
                  testId={item.testId}
                  key={itemIndex}
                  label={item.label}
                  icon={item.icon}
                  onSelect={() => {
                    if (item.onSelect) item.onSelect()

                    handleSelectItem(index, item.label)
                  }}
                />
              ))}
            </StepContent>
            {store.stepperSelectedItems[index] && activeStep !== index ? (
              <SelectedItem
                item={store.stepperSelectedItems[index]!}
                label={step.label}
                handleBack={() => handleStepChange(index)}
              />
            ) : null}
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}
