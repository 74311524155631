import { tolgee } from '@storage/app/translation'
import * as yup from 'yup'
import { DGStackingRuleFormProfile } from './dg-stacking-rules-table-form.profile'

export const schema: yup.ObjectSchema<DGStackingRuleFormProfile> = yup.object({
  id: yup.string().optional(),
  isStackingContainerOnTopAllowed: yup.boolean().default(null).optional(),
  isParentClass: yup.boolean().default(null).optional(),
  maxStack: yup
    .number()
    .transform((value, originalValue) => {
      if (originalValue === '' || originalValue === null) {
        return null
      }
      return value
    })
    .nullable()
    .test({
      name: 'is-valid-max-stack',
      test: value => value === null || (Number.isInteger(value) && (value as number) >= 1),
      message: ({ value }) => {
        if (value === null) return ''
        if (value < 1) return tolgee.t('maxStackMustBeAtLeastOne', 'Max stack must be at least 1')
        return ''
      },
    }),
})
