import { observer } from 'mobx-react-lite'

import { Box, Container, debounce, Stack, Typography } from '@mui/material'
import { useOperationsStore } from '@operations/AppProvider'
import { FilterChip } from '@operations/components/filter/filter.model'
import { FilterChips } from '@operations/components/filter/FilterChips'
import { useTranslate } from '@tolgee/react'
import { Header, useMinimalsTheme } from '@tom-ui/ui'
import { ReactNode, useEffect } from 'react'
import { AlertsCardList } from '../components/cards/AlertsCardList'
import { CoolingOrderList } from '../components/cards/CoolingOrderList'
import { ReeferMonitoringFilters } from '../components/filter/ReeferMonitoringFilters'
import { ReeferMonitoringDetailsUIStore } from '../stores/reefer-monitoring-details.ui-store'

interface Props {
  maxPluggedOutComponent?: ReactNode
  reeferMonitoringDetailsUIStore: ReeferMonitoringDetailsUIStore
}
export const ReeferMonitoringContainerV2 = observer(
  ({ maxPluggedOutComponent, reeferMonitoringDetailsUIStore }: Props) => {
    const { reeferMonitoringUIStoreV2, reeferMonitoringFiltersUIStoreV2 } = useOperationsStore()
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    useEffect(() => {
      reeferMonitoringUIStoreV2.startTimer()

      return () => {
        reeferMonitoringUIStoreV2.stopTimer()
        reeferMonitoringFiltersUIStoreV2.setContainerNumber()
      }
    }, [reeferMonitoringFiltersUIStoreV2, reeferMonitoringUIStoreV2])

    const handleSearch = debounce((containerNumber: string) => {
      reeferMonitoringFiltersUIStoreV2.setContainerNumber(containerNumber)
      reeferMonitoringUIStoreV2.getCoolingOrdersHistoryByContainer()
    }, 500)

    const handleReset = () => {
      reeferMonitoringFiltersUIStoreV2.setFilter()
      reeferMonitoringFiltersUIStoreV2.toggle()
    }

    const handleRemoveFilter = (value: FilterChip) => {
      reeferMonitoringFiltersUIStoreV2.removeFilter(value)
    }

    return (
      <Box
        sx={{
          minHeight: '100%',
          backgroundColor: theme.palette.grey[200],
        }}
      >
        <Header
          searchInputLabel={t('searchFor', 'Search for {label}', {
            label: t('aContainerNumber', 'a container number'),
          })}
          onSearchFieldChange={handleSearch}
          displaySearchIcon
          enableSearchField
          leftRenderOption={
            <Stack>
              <Typography variant='h3'>{t('reeferMonitoring', 'Reefer monitoring')}</Typography>
              {maxPluggedOutComponent}
            </Stack>
          }
          rightRenderOption={
            <Stack flexDirection='row' gap={theme.customSpacing.m}>
              <AlertsCardList
                coolingOrders={reeferMonitoringUIStoreV2.items}
                reeferMonitoringDetailsUIStore={reeferMonitoringDetailsUIStore}
              />
              <ReeferMonitoringFilters filterUIStore={reeferMonitoringFiltersUIStoreV2} />
            </Stack>
          }
        />

        <Container
          sx={{
            paddingY: theme.customSpacing.m,
          }}
        >
          <Stack gap={theme.customSpacing.xs}>
            <FilterChips
              filters={reeferMonitoringFiltersUIStoreV2.filterChips}
              onRemoveFilter={handleRemoveFilter}
              onClearAll={handleReset}
            />
            <CoolingOrderList
              coolingOrders={reeferMonitoringUIStoreV2.items}
              reeferMonitoringDetailsUIStore={reeferMonitoringDetailsUIStore}
            />
          </Stack>
        </Container>
      </Box>
    )
  },
)
