import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FC, useEffect, useState } from 'react'
export interface TabOption {
  [chave: string]: OptionDefinition
}

export interface OptionDefinition {
  defaultOption?: string
  options: Option[]
}
export interface Option {
  label: string
  value: string
}

interface FilterOptionProps {
  options: Option[]
  defaultOption?: any
  disabled?: boolean
  onChange: (value: string) => void
}

export const FilterOption: FC<FilterOptionProps> = ({
  options,
  defaultOption,
  disabled,
  onChange,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultOption ?? options[0].value)

  useEffect(() => {
    const d = defaultOption ?? options[0].value
    setSelectedValue(d)
    onChange(d)
  }, [defaultOption])

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string
    setSelectedValue(value)
    onChange(value)
  }

  return (
    <FormControl fullWidth>
      <Select
        disabled={disabled}
        sx={{
          backgroundColor: '#919EAB14',
        }}
        value={selectedValue}
        defaultValue={selectedValue}
        onChange={handleChange}
      >
        {options.map(option => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
