import { usePlanningStore } from '@planning/AppProvider'
import { IssueResponseDto, OrderResponseDto, OrderStatus } from '@planning/app/api'
import { ContainerHistoryList } from '@planning/components/ContainerHistoryList'
import { OrderListItem } from '@planning/components/organisms/OrderListItem'
import { useOrderNavigation } from '@planning/hooks/useOrderNavigation'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { IssuesPageLocationState } from '@planning/pages/Issues'
import { orderService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { Group } from '@tom-ui/ui'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Id } from 'react-beautiful-dnd'
import { useNavigate } from 'react-router'

export const OrderGroupListItem: FC<{
  group: Group
  item: { id: Id; content: OrderResponseDto }
  filter?: string
  hasThreeDotMenu?: boolean
  onOrderHistoryFn?: (containerNumber?: string | null) => void
  onDeleteOrderFn?: (orders: OrderResponseDto[]) => void
}> = observer(
  ({ group, item, filter, hasThreeDotMenu = false, onOrderHistoryFn, onDeleteOrderFn }) => {
    const { t } = useTranslate()
    const navigate = useNavigate()

    const {
      appViewStore,
      dialogStore,
      containerEventViewStore,
      containerEventItemStore,
      issueItemStore,
    } = usePlanningStore()

    const onResolve = async (selectedItemId: number) => {
      navigate(`${PATH_PLANNING.issues}`, {
        state: { filterByRailCarTrackPositionId: selectedItemId } as IssuesPageLocationState,
      })
    }

    const { openEditOrder } = useOrderNavigation()

    const onDeleteOrder = async (orders: OrderResponseDto[]) => {
      if (onDeleteOrderFn) {
        return onDeleteOrderFn(orders)
      } else {
        const count = orders.length
        const confirmationMessageKey =
          count === 1 ? 'orderWillBeDeletedContinue' : 'ordersWillBeDeletedContinue'
        const confirmationMessage =
          count === 1
            ? 'Order will be deleted. Continue?'
            : `The selected ${count} Orders will be deleted. Continue?`

        const hasServiceOrders = orders.some(item => item.hasServiceOrders)

        const isConfirmed = await appViewStore.setOpenConfirmDialog(
          true,
          t(confirmationMessageKey, confirmationMessage),
          t('confirmOrderDelete', 'Confirm order delete'),
          hasServiceOrders
            ? t(
                'anyAssociatedStrippingOrStuffingOrdersWillBeDeleted',
                'Any associated stripping/stuffing orders will be deleted',
              )
            : undefined,
        )

        if (!isConfirmed) return

        try {
          await orderService.deleteByIds(orders.map(item => item.id))
          appViewStore.setShowAlert(
            'success',
            t('successfullyDeletedOrder', 'Successfully deleted order'),
          )
        } catch (e) {
          appViewStore.setShowAlert('error', t('failedToDeleteOrder', 'Failed to delete order'))
        }
      }
    }

    const onOrderHistory = async (containerNumber?: string | null) => {
      if (onOrderHistoryFn) {
        onOrderHistoryFn(containerNumber)
      } else {
        containerEventItemStore.setContainerNumber(containerNumber!)
        await containerEventItemStore.fetch()

        dialogStore.openDialog(
          <ContainerHistoryList
            containerNumber={containerNumber}
            store={containerEventViewStore}
          />,
        )
      }
    }

    const orderIssues = computed<IssueResponseDto[]>(() => {
      const orderIssues = issueItemStore.orderIssues.filter(
        i => item.content.notResolvedIssueIds?.includes(i.id) ?? false,
      )

      return orderIssues
    }).get()

    const getItemOptions = () => {
      if (!hasThreeDotMenu) return []

      const actions = [
        {
          label: item.content.status === OrderStatus.Open ? t('edit', 'Edit') : t('view', 'View'),
          onClick: () => openEditOrder(item.content),
        },
        {
          label: t('history', 'History'),
          onClick: () => onOrderHistory(item.content.containerNumber),
        },
      ]

      if (item.content.status === OrderStatus.Open) {
        actions.push({
          label: t('delete', 'Delete'),
          onClick: () => onDeleteOrder([item.content]),
        })
      }

      if (orderIssues.length > 0 && item?.content?.railcarTrackPositionId) {
        actions.push({
          label: t('resolve', 'Resolve'),
          onClick: () => onResolve(item.content.railcarTrackPositionId!),
        })
      }

      return actions
    }

    return (
      <OrderListItem
        order={item.content}
        filter={filter}
        sx={{
          paddingLeft: group.sequenced ? 7.5 : 1.5,
        }}
        options={getItemOptions()}
      />
    )
  },
)
