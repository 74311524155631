import { Button, Grid, TextField } from '@mui/material'
import { IRailVisitItem } from '@planning/rt-stores/railVisit/RailVisitItem'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useNavigate } from 'react-router'
import { RailVisitHeaderContainer } from './RailVisitHeaderContainer'

interface Props {
  railVisit: IRailVisitItem
}

export const RailVisitHeaderTracks: FC<Props> = observer(({ railVisit }) => {
  if (!railVisit.railTracks) return <></>

  const { t } = useTranslate()
  const navigate = useNavigate()

  const handleUpdate = () => {
    navigate(`/rail-visits-planning/${railVisit.id}`)
  }

  const actions = (
    <Button color='primary' size='small' onClick={handleUpdate}>
      {t('update', 'Update')}
    </Button>
  )

  const railTrackNames = railVisit.railTracks.map(track => track.name).join(', ')

  return (
    <RailVisitHeaderContainer title={t('tracks', 'Tracks')} actions={actions}>
      <Grid container justifyContent='space-between' spacing={4}>
        <Grid item>
          <TextField disabled={true} type='text' value={railTrackNames} />
        </Grid>
      </Grid>
    </RailVisitHeaderContainer>
  )
})
