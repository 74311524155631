import { Typography } from '@mui/material'
import { Box } from '@mui/system'

import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { RailTallyViewStore } from '../../Stores/RailTallyViewStore'
import { CreateRailcarOrderButton } from './CreateRailcarOrderButton'

interface IProps {
  store: RailTallyViewStore
}

export const InspectRailcarEmptyMessage: FC<IProps> = observer(({ store }) => {
  const { t } = useTranslate()

  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'70vh'}>
      <Box textAlign={'center'}>
        <Typography variant='subtitle1' mb={'1rem'}>
          {t('noData', 'No data')}
        </Typography>
        <Typography variant='body1'>
          {t('submitAsEmptyRailcar', 'Submit as empty railcar')}
        </Typography>
        <Typography variant='body1' mb={'1rem'}>
          {t('orAddYourFirstContainer', 'or add your first container')}
        </Typography>

        <CreateRailcarOrderButton store={store} orders={store.uncheckedOrdersOnRailVisit} />
      </Box>
    </Box>
  )
})
