import Stack from '@mui/material/Stack'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { FilterButton } from '../atoms/FilterButton'

export type FilterButtonOption = {
  text: string
  count: number
  key: string
}

interface Props {
  filterOptions: FilterButtonOption[]
  selectedFilterKey?: string
  onSelectedFilterChange?: (key: string, index: number) => void
}

export const FilterButtonGroup: FC<Props> = observer(
  ({ filterOptions, selectedFilterKey, onSelectedFilterChange }) => {
    const [selected, setSelected] = useState(selectedFilterKey)

    useEffect(() => {
      setSelected(selectedFilterKey)
    }, [selectedFilterKey])

    const handleSelectedFilterChange = (key: string, index: number) => {
      setSelected(key)
      onSelectedFilterChange?.(key, index)
    }

    return (
      <Stack direction='row' alignItems='center' gap={1} padding={2}>
        {filterOptions.map((option, index) => (
          <FilterButton
            key={option.key}
            active={option.key === selected}
            text={option.text}
            count={option.count}
            onClick={() => handleSelectedFilterChange(option.key, index)}
          />
        ))}
      </Stack>
    )
  },
)
