import * as yup from 'yup'
import { TenantFormProfile } from './tenant-form.profile'

export const schema = (): yup.ObjectSchema<Pick<TenantFormProfile, 'id' | 'name' | 'unloCodes'>> =>
  yup.object({
    id: yup.number().optional(),
    name: yup.string().label('Name').required(),
    unloCodes: yup.array().of(
      yup.object({
        id: yup.number().optional(),
        name: yup.string().label('Location code').required(),
        isDefault: yup.boolean().required(),
      }),
    ),
  })
