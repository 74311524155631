import { YardPositionDto } from '@storage/app/api'
import { ContainerPlanningService } from '@storage/services/container-planning.service'
import { AlertStore } from '@storage/stores/alert.store'
import { SnackbarStore } from '@storage/stores/snackbar.store'
import { action, makeObservable, observable } from 'mobx'

export class OperatorContainerTurnoverStore {
  yardPosition?: YardPositionDto
  previousPageLocation = ''

  constructor(
    private readonly _containerPlanningService: ContainerPlanningService,
    private readonly _snackbarStore: SnackbarStore,
    private readonly _alertStore: AlertStore,
  ) {
    makeObservable(this, {
      yardPosition: observable,
      previousPageLocation: observable,
      setPreviousPageLocation: action,
      setYardPosition: action,
      updateContainerPosition: action,
    })
  }

  public setYardPosition(yardPosition?: YardPositionDto) {
    this.yardPosition = yardPosition
  }

  public setPreviousPageLocation(location: string) {
    this.previousPageLocation = location
  }

  public async updateContainerPosition(containerTurnoverId?: string) {
    if (this.yardPosition && containerTurnoverId) {
      await this._containerPlanningService.updateContainerPosition(
        containerTurnoverId,
        this.yardPosition,
      )
    }
  }

  get validationAlerts() {
    return this._alertStore.getAlerts()
  }

  clearAlerts() {
    this._alertStore.clearAlerts()
  }
}
