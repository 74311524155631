import { TenantDto } from '@admin/app/api'
import { FormProps } from '@admin/app/models'
import { ControlledNumberField, ControlledSelect } from '@admin/components/form-controls'
import { ControlledCheckbox } from '@admin/components/form-controls/ControlledCheckbox'
import { ControlledTextField } from '@admin/components/form-controls/ControlledTextField'
import useFormWithSchema from '@admin/hooks/use-form-with-schema.hook'
import { TimezonesStore } from '@admin/stores/timezones.store'
import { Box, Button, FormGroup } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment } from '@tom-ui/ui'
import { uniq } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { TenantLocationCodeField } from './TenantLocationCodeField'
import { TenantConfigFormMapper } from './tenant-config-form.mapper'
import { TenantConfigFormProfile, defaultValues } from './tenant-config-form.profile'
import { schema } from './tenant-config-form.scheme'

interface Props extends FormProps {
  tenant?: TenantDto
  timezonesStore: TimezonesStore
  onSubmit: (formValues: TenantConfigFormProfile) => Promise<void> | void
}

export const TenantConfigurationForm = observer(
  ({ id, tenant, timezonesStore, onSubmit }: Props) => {
    const { handleSubmit, formState, control, reset, setValue, getValues } =
      useFormWithSchema<TenantConfigFormProfile>(schema() as any, defaultValues)

    const { t } = useTranslate()

    const [formErrors, setFormErrors] = useState({
      hasDuplicateLocationCodes: false,
      isRequiredLocationCode: false,
    })

    useEffect(() => {
      if (tenant) {
        reset(TenantConfigFormMapper.mapDtoToFormValues(tenant))
      }
    }, [reset, tenant])

    const handleSubmitForm = async (formValues: TenantConfigFormProfile) => {
      const locationCodes = getValues('unloCodes')
      const uniqueLocationCodes = uniq(locationCodes?.map(lc => lc.name))

      if (!locationCodes || locationCodes.length === 0) {
        return setFormErrors({ ...formErrors, isRequiredLocationCode: true })
      }

      if (locationCodes?.length !== uniqueLocationCodes.length) {
        return setFormErrors({ ...formErrors, hasDuplicateLocationCodes: true })
      }

      setFormErrors({ hasDuplicateLocationCodes: false, isRequiredLocationCode: false })

      await onSubmit(formValues)
    }

    return (
      <Box
        id={id}
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(handleSubmitForm)}
      >
        <FormGroup sx={{ gap: theme => theme.customSpacing.l }}>
          <ControlledTextField<TenantConfigFormProfile>
            name='name'
            formState={formState}
            control={control}
            label={t('name', 'Name')}
            fullWidth
          />
          <ControlledSelect<TenantConfigFormProfile>
            name='timezone'
            formState={formState}
            control={control}
            label={t('timeZone', 'Time Zone')}
            options={timezonesStore.timezones.map(tz => ({
              label: tz.name,
              value: tz.id,
            }))}
          />
          <ControlledSelect<TenantConfigFormProfile>
            name='culture'
            formState={formState}
            control={control}
            label={t('culture', 'Culture')}
            options={timezonesStore.cultures.map(c => ({
              label: c.name,
              value: c.id,
            }))}
          />
          <ControlledTextField<TenantConfigFormProfile>
            name='address'
            formState={formState}
            control={control}
            label={t('address', 'Address')}
            fullWidth
          />

          <ControlledNumberField<TenantConfigFormProfile>
            name='reeferContainerMaxUnpluggedTime'
            formState={formState}
            control={control}
            label={t('reeferContainerMaxUnpluggedTime', 'Reefer container max unplugged time')}
            fullWidth
            endAdornment={
              <CustomInputAdornment position='end'>{t('hour', 'hour')}</CustomInputAdornment>
            }
            integerOnly
          />

          <TenantLocationCodeField
            tenant={tenant}
            formState={formState}
            control={control}
            setValue={setValue}
            getValues={getValues}
            formErrors={formErrors}
          />

          <ControlledCheckbox<TenantConfigFormProfile>
            name={'hasDamageCatalogue'}
            formState={formState}
            control={control}
            label={t('hasDamageCatalogue', 'Has damage catalogue')}
          />

          <Box display='flex' justifyContent='flex-end'>
            <Button variant='contained' type='submit'>
              {t('save', 'Save')}
            </Button>
          </Box>
        </FormGroup>
      </Box>
    )
  },
)
