import { Card, CardContent, Grid } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitPlanningDateAxis } from '@planning/components/carrier-visit-planning/CarrierVisitPlanningDateAxis'
import { CarrierVisitPlanningHeader } from '@planning/components/carrier-visit-planning/CarrierVisitPlanningHeader'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { BerthLayout } from './Components/BerthLayout'
import { BerthPlanningPierSelection } from './Components/BerthPlanningPierSelection'
import { BerthVisualization } from './Components/BerthVisualization'
import { BerthPlanningViewStore } from './Stores/BerthPlanningViewStore'

interface Props {
  store: BerthPlanningViewStore
}
export const BerthPlanning = observer(({ store }: Props) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()
  const { vesselVisitPlanningStore } = usePlanningStore()
  const navigate = useNavigate()

  useEffect(() => {
    const gettingBethViewSize = () => {
      const mainElement = document.querySelector(`#berth-visualization`)

      if (mainElement) {
        store.setBerthViewTotalWidth(Math.floor(mainElement.getBoundingClientRect().width))
      }
    }

    const handleResize = () => {
      gettingBethViewSize()
    }

    gettingBethViewSize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [store])

  return (
    <Card sx={{ overflowY: 'auto' }}>
      <CardContent>
        <Grid container sx={{ paddingBottom: 2 }}>
          <Grid
            item
            xs={12}
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <CarrierVisitPlanningHeader
              title={t('berthPlan', 'Berth plan')}
              visitDate={store.startDate}
              onClickToday={() => store.setStartDate(new Date())}
              onClickPrev={() => store.loadPrevDate()}
              onClickNext={() => store.loadNextDate()}
              onChangeDateRange={(dateRange: number) => store.setDaysVisualization(dateRange)}
            />
          </Grid>

          <Grid
            item
            xs={2}
            sx={{
              borderRight: `1px solid ${theme.palette.divider}`,
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <BerthPlanningPierSelection
              options={store.piers}
              selectedPierId={store.selectedPierId}
              onChange={(id: string) => store.setSelectPierId(id)}
            />
          </Grid>

          <Grid
            item
            xs={10}
            sx={{
              borderBottom: `1px solid ${theme.palette.divider}`,
            }}
          >
            <CarrierVisitPlanningDateAxis
              itemCount={store.daysVisualization}
              startDate={store.startDate}
              pixelPerHour={store.pixelPerHour}
            />
          </Grid>

          <Grid
            item
            xs={2}
            sx={{
              borderRight: `1px solid ${theme.palette.divider}`,
            }}
          >
            <BerthLayout
              berths={store.berths}
              pixelPerWaterMark={store.pixelPerWaterMark}
              startWaterMark={store.startWaterMark}
              berthViewTotalWidth={store.berthViewTotalWidth}
            />
          </Grid>
          <Grid item xs={10} id='berth-visualization' overflow='hidden'>
            <BerthVisualization
              pixelPerHour={store.pixelPerHour}
              visits={store.plannedVisits}
              onClick={(id: number) => {
                if (store.vesselVisitSelectedId === 0) {
                  return
                }

                if (
                  store.currentFormValues &&
                  store.vesselVisitSelectedId !== 0 &&
                  vesselVisitPlanningStore.vesselVisit?.id !== id &&
                  vesselVisitPlanningStore.isEditMode
                ) {
                  store.setNextVesselVisitIdToBeEdited(id)
                  store.toggleConfirmationDialog(true)
                } else {
                  store.setFormValues()
                  navigate(`/vessel-visits-planning/${id}`)
                }
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
})
