import { DGSegregationRuleDto, ImoClassPairDto } from '@storage/app/api'
import { tolgee } from '@storage/app/translation'
import { ImoClasses } from '@storage/components/types/ImoClasses'
import { DGSegregationRuleDataStore } from '@storage/stores/dg-segregation-rule.data-store'
import { DialogUtilStore } from '@storage/stores/dialog.util-store'
import { SnackbarStore } from '@storage/stores/snackbar.store'
import { makeAutoObservable, runInAction } from 'mobx'

type ImoClass = (typeof ImoClasses)[number]

interface Cell {
  row: ImoClass
  col: ImoClass
}

export class DGSegregationRulesTableUIStore {
  rules: Map<string, number> = new Map()
  isDragging = false
  startCell?: Cell
  endCell?: Cell

  constructor(
    public readonly _dgSegregationRuleDataStore: DGSegregationRuleDataStore,
    public readonly dialogUtilStore: DialogUtilStore,
    private readonly _snackbarStore: SnackbarStore,
  ) {
    makeAutoObservable(this)
    this.dialogUtilStore.setDialogConfigs({
      Edit: {
        title: tolgee.t('editDGSegregationRules', 'Edit DG Segregation Rules'),
      },
    })
  }

  getRule(row: ImoClass, col: ImoClass): number | null {
    return this.rules.get(this.getKey(row, col)) ?? null
  }

  setRule(row: ImoClass, col: ImoClass, distance: number | null) {
    if (distance === null) {
      this.rules.delete(this.getKey(row, col))
    } else {
      this.rules.set(this.getKey(row, col), distance)
    }
  }

  async loadAllDGSegregationRules() {
    const dgSegregationRules = await this._dgSegregationRuleDataStore.getAll()
    runInAction(() => {
      this.rules.clear()
      dgSegregationRules.forEach(rule => {
        this.setRule(rule.imoClass1, rule.imoClass2, rule.distance)
      })
    })
  }

  setIsDragging(dragging: boolean) {
    this.isDragging = dragging
  }

  setStartCell(row: ImoClass, col: ImoClass) {
    this.startCell = { row, col }
  }

  setEndCell(row: ImoClass, col: ImoClass) {
    this.endCell = { row, col }
  }

  handleMouseDown(row: ImoClass, col: ImoClass) {
    this.setIsDragging(true)
    this.setStartCell(row, col)
    this.setEndCell(row, col)
  }

  handleMouseEnter(row: ImoClass, col: ImoClass) {
    if (this.isDragging) {
      this.setEndCell(row, col)
    }
  }

  handleMouseUp() {
    if (this.isDragging) {
      this.setIsDragging(false)
      this.dialogUtilStore.toggleDialog('Edit')
    }
  }

  async clear() {
    const selectedCells = this.getSelectedCells()
    const classPairsToDelete = this.mapSelectedCellsToIMOClassPairDto(selectedCells)

    try {
      await this._dgSegregationRuleDataStore.deleteBulk(classPairsToDelete)

      runInAction(() => {
        selectedCells.forEach(({ row, col }) => {
          this.setRule(row, col, null)
        })
      })

      this._snackbarStore.showMessage(
        tolgee.t('dgSegregationRulesClearSuccess', 'DG segregation rules cleared successfully'),
        'success',
      )
    } catch (e) {
      this._snackbarStore.showMessage(
        tolgee.t(
          'dgSegregationRulesClearFailure',
          'An unexpected error occurred while clearing the DG segregation rules',
        ),
        'error',
      )
    } finally {
      this.dialogUtilStore.toggleDialog('Edit')
      this.clearSelection()
    }
  }

  clearSelection() {
    this.startCell = undefined
    this.endCell = undefined
  }

  closeDialog() {
    this.dialogUtilStore.toggleDialog('Edit')
    this.clearSelection()
  }

  getSelectedCells() {
    if (!this.startCell || !this.endCell) return []
    const startRow = Math.min(
      ImoClasses.indexOf(this.startCell.row),
      ImoClasses.indexOf(this.endCell.row),
    )
    const endRow = Math.max(
      ImoClasses.indexOf(this.startCell.row),
      ImoClasses.indexOf(this.endCell.row),
    )
    const startCol = Math.min(
      ImoClasses.indexOf(this.startCell.col),
      ImoClasses.indexOf(this.endCell.col),
    )
    const endCol = Math.max(
      ImoClasses.indexOf(this.startCell.col),
      ImoClasses.indexOf(this.endCell.col),
    )

    const cells = []
    for (let i = startRow; i <= endRow; i++) {
      for (let j = startCol; j <= endCol; j++) {
        cells.push({ row: ImoClasses[i], col: ImoClasses[j] })
      }
    }
    return cells
  }

  async save(distance: number) {
    const selectedCells = this.getSelectedCells()
    const rulesToUpsert = this.mapSelectedCellsToDGSegregationRuleDto(selectedCells, distance)

    try {
      const updatedRules = await this._dgSegregationRuleDataStore.upsertBulk(rulesToUpsert)

      runInAction(() => {
        updatedRules.forEach(rule => {
          this.setRule(rule.imoClass1, rule.imoClass2, rule.distance)
        })
      })

      this._snackbarStore.showMessage(
        tolgee.t('dgSegregationRulesUpdateSuccess', 'DG segregation rules updated successfully'),
        'success',
      )
    } catch (e) {
      this._snackbarStore.showMessage(
        tolgee.t(
          'dgSegregationRulesUpdateFailure',
          'An unexpected error occurred while editing the DG segregation rules',
        ),
        'error',
      )
    } finally {
      this.dialogUtilStore.toggleDialog('Edit')
      this.clearSelection()
    }
  }

  isCellSelected(row: ImoClass, col: ImoClass) {
    if (!this.startCell || !this.endCell) return false
    const rowIndex = ImoClasses.indexOf(row)
    const colIndex = ImoClasses.indexOf(col)
    const startRowIndex = ImoClasses.indexOf(this.startCell.row)
    const endRowIndex = ImoClasses.indexOf(this.endCell.row)
    const startColIndex = ImoClasses.indexOf(this.startCell.col)
    const endColIndex = ImoClasses.indexOf(this.endCell.col)

    return (
      rowIndex >= Math.min(startRowIndex, endRowIndex) &&
      rowIndex <= Math.max(startRowIndex, endRowIndex) &&
      colIndex >= Math.min(startColIndex, endColIndex) &&
      colIndex <= Math.max(startColIndex, endColIndex)
    )
  }

  private getKey(row: ImoClass, col: ImoClass): string {
    return row <= col ? `${row}-${col}` : `${col}-${row}`
  }

  private mapSelectedCellsToDGSegregationRuleDto(
    selectedCells: Cell[],
    distance: number,
  ): DGSegregationRuleDto[] {
    const uniquePairs = new Set<string>()
    const rules: DGSegregationRuleDto[] = []

    selectedCells.forEach(({ row, col }) => {
      const key = this.getKey(row, col)
      if (!uniquePairs.has(key)) {
        uniquePairs.add(key)
        const [imoClass1, imoClass2] = key.split('-')
        rules.push({
          imoClass1,
          imoClass2,
          distance,
        })
      }
    })

    return rules
  }

  private mapSelectedCellsToIMOClassPairDto(selectedCells: Cell[]): ImoClassPairDto[] {
    const uniquePairs = new Set<string>()
    const classPairs: ImoClassPairDto[] = []

    selectedCells.forEach(({ row, col }) => {
      const key = this.getKey(row, col)
      if (!uniquePairs.has(key)) {
        uniquePairs.add(key)
        const [imoClass1, imoClass2] = key.split('-')
        classPairs.push({
          imoClass1,
          imoClass2,
        })
      }
    })

    return classPairs
  }
}
