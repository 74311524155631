import { Build, Thermostat, Warning } from '@mui/icons-material'
import { Chip, Tabs } from '@mui/material'
import { CarrierType, CarrierVisitDirection, ContainerTurnoversFilterDto } from '@storage/app/api'
import {
  CarrierTypeIconProvider,
  HandlingDirectionIconProvider,
} from '@storage/app/carriers/providers'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'

interface Props {
  item: ContainerTurnoversFilterDto
}

export const ContainerTurnoverFilterAttributes = ({ item }: Props) => {
  const {
    containerIsoCode,
    weightClasses,
    imoClasses,
    inboundCarrier,
    inboundCarrierType,
    outboundCarrier,
    outboundCarrierType,
    isDangerous,
    locationStatus,
    portOfDischarge,
    portOfLoading,
    containerOperator,
    isDamaged,
    isReefer,
    isRestow,
    isEmpty,
    size,
  } = item

  const { t } = useTranslate()

  const { carrierVisitStore: vesselVisitStore } = useStores()

  const carrierTypesLabels: Record<CarrierType, string> = {
    Vessel: t('vessel', 'Vessel'),
    Truck: t('truck', 'Truck'),
    Train: t('train', 'Train'),
    Universal: t('universal', 'Universal'),
    Unknown: t('unknown', 'Unknown'),
  }

  const inboundCarrierLabel = inboundCarrierType
    ? carrierTypesLabels[inboundCarrierType]
    : undefined

  const outboundCarrierLabel = outboundCarrierType
    ? carrierTypesLabels[outboundCarrierType]
    : undefined

  return (
    <Tabs
      value={false}
      variant='scrollable'
      scrollButtons='auto'
      sx={{
        flexGrow: 1,
        minHeight: '0px',
        '.MuiTabs-flexContainer': {
          maxWidth: '200px',
          display: 'flex',
          gap: 2,
        },
        '.MuiTabs-scrollButtons': {
          width: '30px',
          height: '30px',
          alignSelf: 'self-end',
        },
        '.MuiTabs-scrollButtons.Mui-disabled': {
          opacity: 0.3,
        },
      }}
    >
      {containerIsoCode && <Chip label={containerIsoCode} />}
      {weightClasses && weightClasses.map(x => <Chip icon={<Warning />} label={x} key={x} />)}
      {isEmpty !== undefined && <Chip label={isEmpty ? t('empty', 'Empty') : t('full', 'Full')} />}
      {isDangerous && <Chip icon={<Warning />} label={t('dangerous', 'Dangerous')} />}
      {imoClasses && imoClasses.map(x => <Chip icon={<Warning />} label={x} key={x} />)}
      {isDamaged && <Chip icon={<Build />} label={t('damaged', 'Damaged')} />}
      {isRestow && <Chip label={t('restow', 'Restow')} />}
      {isReefer && <Chip icon={<Thermostat />} label={t('reefer', 'Reefer')} />}
      {!!size && <Chip label={`${size}'`} />}
      {inboundCarrierLabel && (
        <Chip
          icon={HandlingDirectionIconProvider.get(CarrierVisitDirection.Inbound)}
          label={inboundCarrierLabel}
        />
      )}
      {inboundCarrier && (
        <Chip
          icon={CarrierTypeIconProvider.get(CarrierType.Vessel)}
          label={vesselVisitStore.entries.find(x => x.id === inboundCarrier)?.name}
        />
      )}
      {outboundCarrierLabel && (
        <Chip
          icon={HandlingDirectionIconProvider.get(CarrierVisitDirection.Outbound)}
          label={outboundCarrierLabel}
        />
      )}
      {outboundCarrier && (
        <Chip
          icon={CarrierTypeIconProvider.get(CarrierType.Vessel)}
          label={vesselVisitStore.entries.find(x => x.id === outboundCarrier)?.name}
        />
      )}
      {portOfDischarge && (
        <Chip
          icon={HandlingDirectionIconProvider.get(CarrierVisitDirection.Inbound)}
          label={portOfDischarge}
        />
      )}
      {portOfLoading && (
        <Chip
          icon={HandlingDirectionIconProvider.get(CarrierVisitDirection.Outbound)}
          label={portOfLoading}
        />
      )}
      {containerOperator && <Chip label={containerOperator} />}
      {locationStatus && <Chip label={`${t(`containerStatus${locationStatus}`)}`} />}
    </Tabs>
  )
}
