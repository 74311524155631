import { Box } from '@mui/system'
import { usePlanningStore } from '@planning/AppProvider'
import { PaginatedList } from '@planning/components/PaginatedList'
import { VesselListCard } from '@planning/components/VesselListCard'
import Permission from '@planning/permission.enum'
import { IVesselItem } from '@planning/rt-stores/vessel/VesselItem'
import { vesselService } from '@planning/services'
import { IFilterDelegate, ISortDelegate } from '@planning/stores/PaginatedLocalStore'
import { IDeleteRecordActions } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { VesselForm } from './VesselForm'

export const vesselsSortingDelegate: ISortDelegate<IVesselItem> = (sortingModel, a, b) => {
  const sort = a.data.name.localeCompare(b.data.name)

  return sortingModel.isDescending ? -sort : sort
}

export const vesselsFilterDelegate: IFilterDelegate<IVesselItem> = (
  filter: string,
  item: IVesselItem,
) => {
  if (filter) {
    return item.data.name.toLowerCase().includes(filter.toLowerCase())
  }

  return true
}

export const VesselPage = observer(() => {
  const { vesselViewStore: viewStore, appViewStore, drawerStore, authStore } = usePlanningStore()
  const { t } = useTranslate()

  useEffect(() => {
    viewStore.loadCurrentPage()
  }, [viewStore])

  const actions: IDeleteRecordActions = {
    openConfirmDialog: appViewStore.setOpenConfirmDialog,
    showAlert: appViewStore.setShowAlert,
    deleteRecord: vesselService.delete,
    deletedCallback: viewStore.loadCurrentPage,
  }

  return (
    <Box padding='.75rem'>
      <PaginatedList
        title={t('vessels', `Vessels`)}
        filterable
        handleCreate={async () => {
          drawerStore.showView(<VesselForm />)
        }}
        canWrite={authStore.hasPermission(Permission.WriteVessels)}
        paginatedStore={viewStore}
      >
        {viewStore.pageItems.map(item => (
          <VesselListCard
            vessel={item.data}
            key={item.id}
            actions={actions}
            onEdit={() => {
              drawerStore.showView(<VesselForm editMode vessel={item.data} vesselId={item.id} />)
            }}
            canWrite={authStore.hasPermission(Permission.WriteVessels)}
          />
        ))}
      </PaginatedList>
    </Box>
  )
})
