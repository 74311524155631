import { Box, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import { CarrierVisitDirection } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { colorEmptyOccupancy, colorFullOccupancy } from '@storage/pages/yard-management/constants'
import { pickGradient } from '@storage/utils/colors'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { doesSlotHaveOccupiedTurnoverForVisitAndDirection } from '../utils/yard-planning-dashboard-stack.util'

interface LegendProps {
  title: string
  color: string
}

export const LegendItem = ({ title, color }: LegendProps) => {
  const { palette, customRadius } = useMinimalsTheme()

  return (
    <Stack flexDirection='row' gap='1rem' alignItems='center'>
      <Box
        sx={{
          width: '24px',
          height: '24px',
          border: `1px solid ${palette.grey[600]}`,
          background: color,
          borderRadius: customRadius.small,
        }}
      ></Box>
      <Typography variant='body2'>{title}</Typography>
    </Stack>
  )
}

interface YardPlanningDashboardLegendProps {
  carrierVisitId?: number
  carrierVisitDirection: CarrierVisitDirection
}
const YardPlanningDashboardLegend = observer(
  ({ carrierVisitId, carrierVisitDirection }: YardPlanningDashboardLegendProps) => {
    const { t } = useTranslate()
    const { palette, customSpacing, zIndex } = useMinimalsTheme()
    const [showContent, setShowContent] = useState(true)
    const { yardBlockSlotStore } = useStores()

    const hasOnTerminal = useMemo(() => {
      if (!carrierVisitId) return false

      return yardBlockSlotStore.entries.find(slot =>
        doesSlotHaveOccupiedTurnoverForVisitAndDirection(
          slot,
          carrierVisitId,
          carrierVisitDirection,
        ),
      )
    }, [carrierVisitDirection, carrierVisitId, yardBlockSlotStore.entries])

    return (
      <Card
        sx={{
          position: 'absolute',
          bottom: customSpacing.l,
          right: customSpacing.l,
          zIndex: zIndex.mobileStepper,
          cursor: 'pointer',
          width: '12rem',
        }}
        onClick={() => setShowContent(value => !value)}
      >
        <CardHeader
          title={t('legend', 'Legend')}
          sx={{ paddingBottom: !showContent ? customSpacing.s : undefined }}
        />
        {showContent && (
          <CardContent>
            <Stack gap={1}>
              <LegendItem title={t('empty', 'Empty')} color={colorEmptyOccupancy} />

              <Typography variant='subtitle1'>{t('thisVisit', 'This visit')}</Typography>

              <LegendItem title={t('allocated', 'Allocated')} color={palette.info.light} />

              {hasOnTerminal && (
                <LegendItem title={t('onterminal', 'On terminal')} color={palette.info.main} />
              )}

              <Typography variant='subtitle1'>{t('other', 'Other')}</Typography>

              <LegendItem
                title={t('occupied', 'Occupied')}
                color={`linear-gradient(90deg, ${colorFullOccupancy} 0%, 
                  ${colorFullOccupancy} 33%, 
                  ${pickGradient(colorEmptyOccupancy, colorFullOccupancy, 2 * (1 / 5))} 33%, 
                  ${pickGradient(colorEmptyOccupancy, colorFullOccupancy, 3 * (1 / 5))} 66%, 
                  ${pickGradient(colorEmptyOccupancy, colorFullOccupancy, 1 * (1 / 5))} 66%)`}
              />
            </Stack>
          </CardContent>
        )}
      </Card>
    )
  },
)

export default YardPlanningDashboardLegend
