import { Divider, Stack, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { FC, ReactNode } from 'react'

interface IProps {
  label?: string
  actions: ReactNode[]
  secondaryActions?: ReactNode[]
}

export const ActionBar: FC<IProps> = ({ label, actions, secondaryActions }) => {
  const theme = useMinimalsTheme()

  return (
    <Stack direction={'row'} alignItems={'center'} gap={theme.customSpacing.xs}>
      {label && (
        <Typography variant='subtitle2' align='left'>
          {label}
        </Typography>
      )}

      {actions}

      {secondaryActions && (
        <>
          <Divider
            orientation='vertical'
            variant='middle'
            flexItem
            sx={{ borderRightWidth: theme.customRadius.xs }}
          />
          {secondaryActions}
        </>
      )}
    </Stack>
  )
}
