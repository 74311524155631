import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import BayView from '../components/bayView/BayView'
import { CraneOperatorUIStore, filterDuplicatedTwenties } from '../stores/crane-operator.ui-store'

export interface Props {
  uiStore: CraneOperatorUIStore
}

const CraneOperatorItemView = observer(({ uiStore }: Props) => {
  const { t } = useTranslate()

  const [bays, bayName] = filterDuplicatedTwenties(uiStore.itemViewGeometry ?? [])

  return bays?.length ? (
    <>
      {bays.map(item => (
        <BayView
          key={item.bay + uiStore.itemViewOperationType!}
          bayGeometry={item}
          bayName={bayName}
          equipmentId={uiStore.selectedEquipmentId!}
          restowWorkInstructions={uiStore.restowWorkInstructions}
          zoom={0.8}
          operationType={uiStore.itemViewOperationType!}
          workStatusPerBay={uiStore.workStatusPerBay}
        ></BayView>
      ))}
    </>
  ) : (
    <h2>{t('noDataForThisBay', 'No Data for this bay')}</h2>
  )
})

export default CraneOperatorItemView
