import {
  CoolingOrderDto,
  CreateReeferTemperatureCommand,
  OrderStatus,
  ReeferTemperatureDto,
  ReeferTemperaturesApi,
  UpdateReeferTemperatureCommand,
  WorkInstructionStatus,
} from '@operations/app/api'

import { createApiClient } from '@operations/app/http-client'

import { EventTypes } from '@operations/messages/eventsTypes'
import { IEvent, IMessageBus } from '@operations/messages/messageBus'
import { AppStore } from '@tom-ui/utils'
import _ from 'lodash'
import { computed, makeObservable } from 'mobx'
import { BaseEntityStore } from './base/BaseEntityStore'

export class ReeferTemperatureStore extends BaseEntityStore<CoolingOrderDto> {
  loadingSignalR = false

  constructor(
    private messageBus: IMessageBus,
    private appStore: AppStore,
  ) {
    super()
    makeObservable(this, {
      coolingOrders: computed,
    })

    this.messageBus.subscribeEvent<ReeferTemperatureDto>(
      EventTypes.ReeferTemperatureUpserted,
      this.handleTemperatureRecordUpserted,
    )

    this.messageBus.subscribeEvent<CoolingOrderDto>(
      EventTypes.CoolingOrderUpserted,
      this.handleCoolingOrderUpserted,
    )

    this.messageBus.subscribeEvent(
      EventTypes.CarrierVisitRefresh,
      this.reloadInProgressCoolingOrdersBySignalR,
    )

    this.messageBus.subscribeEvent(EventTypes.WorkInstructionFinished, this.finishWorkInstruction)
  }

  handleTemperatureRecordUpserted = (res: IEvent<ReeferTemperatureDto>) => {
    const upsertedRecord = res.payload

    if (!upsertedRecord) return

    const coolingOrder = this.items.find(x => x.cargoUnitId === upsertedRecord.cargoUnitId)

    if (coolingOrder) {
      const newDto = { ...coolingOrder }

      newDto.reeferTemperatures = newDto.reeferTemperatures?.filter(x => x.id !== upsertedRecord.id)
      newDto.reeferTemperatures?.push(upsertedRecord)

      this.updateStoreItem(newDto, newDto.id)
    }
  }

  handleCoolingOrderUpserted = (res: IEvent<CoolingOrderDto>) => {
    const upsertedOrder = res.payload

    if (!upsertedOrder) return

    const coolingOrder = this.items.find(x => x.id === upsertedOrder?.id)

    if (coolingOrder) {
      upsertedOrder.reeferTemperatures = coolingOrder.reeferTemperatures
    }

    this.updateStoreItem(upsertedOrder, upsertedOrder.id)
  }

  reloadInProgressCoolingOrdersBySignalR = async () => {
    const currentHost = window.location.pathname

    if (
      !currentHost.startsWith('/reefer-operator') &&
      !currentHost.startsWith('/jobs/reeferMonitoring')
    )
      return

    let isItForOperator = undefined
    if (currentHost.startsWith('/reefer-operator')) {
      isItForOperator = true
    }

    if (!this.loadingSignalR) {
      this.loadingSignalR = true
      await this.appStore.triggerRequestWithoutLoader(
        async () => await this.loadInProgressCoolingOrders(isItForOperator),
      )
      this.loadingSignalR = false
    }
  }

  async loadInProgressCoolingOrders(isItForOperator?: boolean) {
    const { data } =
      await createApiClient(ReeferTemperaturesApi).getInProgressCoolingOrders(isItForOperator)

    this.updateStoreItems(data)
  }

  async create(createReeferTemperatureCommand: CreateReeferTemperatureCommand) {
    await createApiClient(ReeferTemperaturesApi).create(createReeferTemperatureCommand)
  }

  async update(updateReeferTemperatureCommand: UpdateReeferTemperatureCommand) {
    await createApiClient(ReeferTemperaturesApi).update(updateReeferTemperatureCommand)
  }

  async getCoolingOrdersHistoryByContainer(containerNumber?: string) {
    const { data } =
      await createApiClient(ReeferTemperaturesApi).getCoolingOrdersHistoryByContainer(
        containerNumber,
      )

    return data
  }

  finishWorkInstruction = (res: IEvent<number>) => {
    if (!res.payload) return

    const coolingOrders = this.items.filter(wi => wi.outboundWorkInstructionId === res.payload)

    coolingOrders.forEach(c => {
      c.isOutboundWorkInstructionFinished = true
      this.updateStoreItem(c, c.id)
    })
  }

  public get coolingOrders() {
    const sortItems = _.flow(
      items =>
        _.orderBy(
          items,
          [
            'isPlugInRequired',
            'isPlugOutRequired',
            'isTemperatureCheckRequired',
            'nextCheckDateTime',
          ],
          ['desc', 'desc', 'desc', 'asc'],
        ),
      items =>
        _.map(items, ({ reeferTemperatures, ...o }) => ({
          ...o,
          reeferTemperatures: _.orderBy(reeferTemperatures, ['recordedAt', 'id']),
        })),
    )

    return sortItems(
      this.items.filter(
        i =>
          (i.inboundWorkInstructionStatus === WorkInstructionStatus.Finished ||
            i.inboundWorkInstructionStatus === WorkInstructionStatus.WaitingForStackIn) &&
          i.status !== OrderStatus.Fulfilled,
      ),
    )
  }
}
