import { Box, Chip, FormControl, Stack, Tooltip, Typography } from '@mui/material'
import { EquipmentType, EquipmentTypes, YardBlockAssignmentDto } from '@operations/app/api'
import { SelectOption } from '@operations/app/models'
import { useTranslate } from '@tolgee/react'
import { InfoIcon, useMinimalsTheme } from '@tom-ui/ui'
import { useEffect, useState } from 'react'
import { YardBlockBay, YardBlockBayChip } from './YardBlockBayChip'

export interface YardBlockSelectOption extends SelectOption {
  otherEquipmentTypesAssignedToBlock?: EquipmentType[]
  yardBlockEquipmentTypes?: EquipmentTypes
  currentEquipmentType?: EquipmentType
  bays: string[]
}

interface Props {
  options?: YardBlockSelectOption[]
  label: string
  yardBlockAssignments?: YardBlockAssignmentDto[]
  onChange: (ids: YardBlockAssignmentDto[]) => void
}

export const ControlledYardBlockWithBayAssignment = ({
  label,
  options,
  yardBlockAssignments,
  onChange,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const [selectedYardBlocks, setSelectedYardBlocks] = useState<YardBlockBay[]>([])

  useEffect(() => {
    if (yardBlockAssignments) {
      setSelectedYardBlocks(
        yardBlockAssignments.map(yb => {
          const yardBlockOption = options?.find(x => x.value === yb.yardBlockId)

          return {
            id: yb.yardBlockId,
            name: yardBlockOption?.label ?? '',
            selectedBays: yb.bays,
            blockBays: yardBlockOption?.bays ?? [],
          }
        }),
      )
    }
  }, [])

  const handleOnChange = (newValue: YardBlockBay[]) => {
    setSelectedYardBlocks(newValue)
    onChange(newValue.map(x => ({ yardBlockId: x.id, bays: x.selectedBays })))
  }

  const handleAssign = (option: YardBlockSelectOption) => {
    handleOnChange([
      {
        id: option.value.toString(),
        name: option.label,
        selectedBays: [],
        blockBays: option.bays,
        recentlyAdded: true,
      },
      ...selectedYardBlocks,
    ])
  }

  const handleUnassign = (yardBlockId: string) => {
    const newYardBlocksSelected = [...selectedYardBlocks]

    if (newYardBlocksSelected.length > 0) {
      const index = newYardBlocksSelected.findIndex(x => x.id === yardBlockId)

      if (index >= 0) {
        newYardBlocksSelected.splice(index, 1)
        handleOnChange(newYardBlocksSelected)
      }
    }
  }

  const handleUpdateBays = (yardBlockBayId: string, bays: string[]) => {
    const index = selectedYardBlocks.findIndex(x => x.id === yardBlockBayId)

    if (index >= 0) {
      const newYardBlocksSelected = [...selectedYardBlocks]
      newYardBlocksSelected[index] = { ...newYardBlocksSelected[index], selectedBays: bays }

      handleOnChange(newYardBlocksSelected)
    }
  }

  return (
    <FormControl component='fieldset' variant='standard' fullWidth>
      <Typography variant='subtitle2' marginBottom={theme.customSpacing.m}>
        {label}
      </Typography>
      <Stack flexDirection='row' flexWrap='wrap' gap={theme.customSpacing.xs}>
        {options
          ?.filter(
            option =>
              !selectedYardBlocks.some(yardBlock => yardBlock.id === option.value.toString()),
          )
          .map(option => {
            let typeToValidate = undefined

            if (option.currentEquipmentType === EquipmentType.Rmg)
              typeToValidate = EquipmentType.Rtg
            else if (option.currentEquipmentType === EquipmentType.Rtg)
              typeToValidate = EquipmentType.Rmg

            if (
              option.yardBlockEquipmentTypes?.includes(EquipmentTypes.Rmg) &&
              typeToValidate &&
              option.otherEquipmentTypesAssignedToBlock?.includes(typeToValidate)
            ) {
              return (
                <Tooltip
                  key={option.value}
                  title={t(
                    'yardBlockInUseByAnotherEquipment',
                    'Yard block is in use by another equipment',
                  )}
                >
                  <Box>
                    <Chip label={option.label} icon={<InfoIcon />} disabled></Chip>
                  </Box>
                </Tooltip>
              )
            }

            return (
              <Chip
                key={option.value}
                label={option.label}
                onClick={() => {
                  handleAssign(option)
                }}
              ></Chip>
            )
          })}
      </Stack>
      <Stack
        flexDirection='row'
        flexWrap='wrap'
        gap={theme.customSpacing.xs}
        mt={theme.customSpacing.xs}
      >
        {selectedYardBlocks.map(yardBlock => (
          <YardBlockBayChip
            key={yardBlock.id}
            yardBlockBay={yardBlock}
            onUnassign={id => handleUnassign(id)}
            onBaySelection={(bays: string[]) => handleUpdateBays(yardBlock.id, bays)}
          />
        ))}

        {!options?.length && (
          <Typography variant='body2' color='secondary'>
            {t(
              'noYardBlockAvailableForEquipmentType',
              'No yard block available for this type of equipment',
            )}
          </Typography>
        )}
      </Stack>
    </FormControl>
  )
}
