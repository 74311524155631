/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import { CarrierType, IssueStatus } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { IRailVisitItem } from '@planning/rt-stores/railVisit/RailVisitItem'
import { IVesselVisitItem } from '@planning/rt-stores/vesselVisit/VesselVisitItem'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

interface Props {
  visitType: CarrierType
  vesselVisitItem?: IVesselVisitItem
  railVisitItem?: IRailVisitItem
  onViewClick: (e: any) => void
}

export const IssueDashboardCard = observer(
  ({ vesselVisitItem, railVisitItem, visitType, onViewClick }: Props) => {
    const { t } = useTranslate()
    const { issueItemStore } = usePlanningStore()

    const items = visitType === CarrierType.Vessel ? vesselVisitItem : railVisitItem

    const ordersIds = items?.orders?.map(o => o.id)
    const matchingIssues = issueItemStore.notResolvedItemsWithObjectTypeOrder.filter(issue =>
      ordersIds?.includes(issue.data.objectId),
    )

    const unresolvedCount = matchingIssues.filter(
      issue => issue.data.issueStatus === IssueStatus.Unresolved,
    ).length

    const needFollowUpCount = matchingIssues.filter(
      issue => issue.data.issueStatus === IssueStatus.FollowUp,
    ).length

    return (
      <Card sx={{ minWidth: 360 }}>
        <CardHeader
          action={
            <Stack direction='row'>
              <Button color='info' onClick={onViewClick}>
                {t('view', 'View')}
              </Button>
            </Stack>
          }
          title={t('issues', 'Issues')}
        />
        <CardContent>
          <Stack direction='row' spacing={2.5}>
            <Stack>
              <Typography variant='subtitle1'>{t(IssueStatus.Unresolved)}</Typography>
              <a>
                <Typography paragraph component='span' variant='h4'>
                  {unresolvedCount}
                </Typography>
              </a>
            </Stack>
            <Stack>
              <Typography variant='subtitle1'>{t(IssueStatus.FollowUp)}</Typography>
              <a>
                <Typography paragraph component='span' variant='h4'>
                  {needFollowUpCount}
                </Typography>
              </a>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    )
  },
)
