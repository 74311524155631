import { PATHS } from '@host/app/paths'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import {
  ControlledDamageReports,
  getDefaultContainerDamage,
  IContainerDamageFormData,
  usePlanningStore,
} from '@tom-ui/planning'
import { usePositionLabel, useStorageStore } from '@tom-ui/storage'
import { ContainerTurnoverDto, ErrorCodes, YardPositionDto } from '@tom-ui/storage/app/api'
import { isApplicationDomainException } from '@tom-ui/storage/app/http-client/interceptors/domain-exception.response-interceptor'
import { YardPositionInputContainer } from '@tom-ui/storage/features/yard-operation-control/Variants/YardPositionInput/YardPositionInputContainer'
import {
  CloseButton,
  ContainerMobile,
  EmptyIndicator,
  Header,
  StepperInfo,
  Tile,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { appStore, formatContainerNumber } from '@tom-ui/utils'
import { AxiosError } from 'axios'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router'

interface IContainerDamageForm extends IContainerDamageFormData {
  hasDamage: boolean
}

type Params = {
  containerTurnoverId: string
}
export const UpdateContainerByOperator = observer(() => {
  const { t } = useTranslate()
  const navigate = useNavigate()
  const theme = useMinimalsTheme()
  const { containerTurnoverId } = useParams<Params>()
  const [containerTurnover, setContainerTurnover] = useState<ContainerTurnoverDto>()
  const { control, formState, handleSubmit, watch, setValue } = useForm<IContainerDamageForm>({
    defaultValues: {
      hasDamage: false,
      damagesReported: [],
    },
  })

  const { operatorContainerTurnoverStore, containerTurnoverStore, alertStore, snackbarStore } =
    useStorageStore()
  const { containerDamageReportViewStore, containerItemStore } = usePlanningStore()

  const containerItem = computed(
    () => containerItemStore.elements[containerTurnover?.containerId ?? 0],
  ).get()

  useEffect(() => {
    const { unsubscribe } = watch(({ hasDamage }, { name, type }) => {
      if (type === 'change' && name === 'hasDamage') {
        const damages = []
        if (hasDamage) damages.push(getDefaultContainerDamage())

        setValue('damagesReported', damages)
      }
    })

    return () => unsubscribe()
  }, [setValue, watch])

  useEffect(() => {
    if (containerItem?.data.damages.length) {
      setValue('hasDamage', true)
      setValue('damagesReported', [])
    }
  }, [containerItem, setValue])

  useEffect(() => {
    containerDamageReportViewStore.fetch()
    const fetchTurnover = async () => {
      const response = await containerTurnoverStore.getContainerTurnover(containerTurnoverId!)

      setContainerTurnover(response)
      if (response?.containerId) {
        await containerItemStore.fetchById(response.containerId)
      }
    }
    fetchTurnover()
  }, [
    containerTurnoverId,
    containerTurnoverStore,
    containerDamageReportViewStore,
    containerItemStore,
  ])

  const onChange = (yardPosition?: YardPositionDto) => {
    operatorContainerTurnoverStore.clearAlerts()
    operatorContainerTurnoverStore.setYardPosition(yardPosition)
  }

  const onConfirm = async (form: IContainerDamageForm) => {
    const requests = []

    if (!form.damagesReported.length && !operatorContainerTurnoverStore.yardPosition) return

    if (form.damagesReported.length) {
      requests.push(
        containerDamageReportViewStore.saveDamage(
          form.damagesReported,
          containerTurnover?.containerId,
          containerTurnover?.containerNumber,
        ),
      )
    }

    if (operatorContainerTurnoverStore.yardPosition) {
      requests.push(operatorContainerTurnoverStore.updateContainerPosition(containerTurnoverId))
    }

    if (requests.length)
      await appStore
        .requestWithLoader(Promise.all(requests))
        .then(() =>
          snackbarStore.showMessage(
            t(
              'theContainerHasBeenSuccessfullyUpdated',
              `The container has been successfully updated`,
            ),
            'success',
          ),
        )
        .catch((error: AxiosError) => {
          // Check for dg stacking rules violations
          if (
            isApplicationDomainException(error, ErrorCodes.DgStackingRulesViolationStackingOnTop)
          ) {
            alertStore.showAlert(
              ErrorCodes.DgStackingRulesViolationStackingOnTop,
              t(
                'stackingValidationStackingOnTopOperator',
                `We can't use this location. There is a stacking rule that prohibits stacking on top.`,
              ),
            )
          } else if (
            isApplicationDomainException(error, ErrorCodes.DgStackingRulesViolationMaxAllowedTier)
          ) {
            alertStore.showAlert(
              ErrorCodes.DgStackingRulesViolationMaxAllowedTier,
              t(
                'stackingValidationMaxStackTierOperator',
                `We can't use this location. There is a maximum tier stacking rule in place for this position.`,
              ),
            )
          } else if (isApplicationDomainException(error, ErrorCodes.DgSegregationRulesViolation)) {
            alertStore.showAlert(
              ErrorCodes.DgSegregationRulesViolation,
              t(
                'segregationRulesViolationOperator',
                `We can't use this location, you action violates the current segreation rules.`,
              ),
            )
          } else {
            // Generic error
            snackbarStore.showMessage(t('somethingWentWrong', `Something went wrong.`), 'error')
          }
        })

    if (operatorContainerTurnoverStore.validationAlerts.length === 0)
      navigate(PATHS.operatorContainerTurnoversList.root)
  }

  const onClose = () => {
    operatorContainerTurnoverStore.clearAlerts()
    navigate(PATHS.operatorContainerTurnoversList.root)
  }

  return (
    <>
      <Header
        leftRenderOption={<CloseButton onClose={onClose} />}
        title={
          containerTurnover
            ? formatContainerNumber(containerTurnover.containerNumber)
            : EmptyIndicator
        }
        titleColor='secondary'
        rightRenderOption={
          <Button
            data-cy='check-container-btn'
            variant='contained'
            onClick={handleSubmit(onConfirm)}
            sx={{ flex: 'none' }}
          >
            {t('save', 'Save')}
          </Button>
        }
      />

      <StepperInfo title={t('checkInformation', 'Check information')} mobile></StepperInfo>

      <ContainerMobile>
        <Stack gap={theme.customSpacing.m} marginY={theme.customSpacing.m}>
          <Typography variant='subtitle1'>
            {t('generalInformation', 'General information')}
          </Typography>

          <Stack direction='row' spacing={2} sx={{ flexWrap: 'wrap' }}>
            {containerTurnover?.referenceNumber && (
              <Tile label={t('refNumber', 'Ref')} value={containerTurnover?.referenceNumber} />
            )}
            <Tile
              label={t('cargoStatus', 'Cargo status ')}
              value={
                containerTurnover?.isEmptyContainerCargoState
                  ? t('empty', 'Empty')
                  : t('full', 'Full')
              }
            />
            {containerTurnover?.size && (
              <Tile label={t('size', 'Size')} value={`${containerTurnover?.size}'`} />
            )}
            {containerTurnover?.consignee && (
              <Tile label={t('consignee', 'Consignee')} value={containerTurnover.consignee} />
            )}
            {!!containerTurnover?.outboundCarriers.length && (
              <Tile
                key={containerTurnover?.outboundCarriers[0]?.name}
                label={t('carrier', 'Carrier')}
                value={containerTurnover?.outboundCarriers[0]?.name}
              />
            )}
            {containerTurnover?.portOfDischarge && (
              <Tile label={t('dischargePort', 'POD')} value={containerTurnover?.portOfDischarge} />
            )}
          </Stack>

          <Typography variant='subtitle1'>{t('damageReport', 'Damage report')}</Typography>
          <Box component='form' noValidate autoComplete='off'>
            <ControlledDamageReports
              control={control}
              formState={formState}
              containerId={containerTurnover?.containerId ?? 0}
              damages={containerItem?.activeDamages ?? []}
              watch={watch}
            />
          </Box>
        </Stack>
        <Stack gap={theme.customSpacing.m} marginY={theme.customSpacing.m}>
          <Typography variant='subtitle1'>{t('journey', 'Journey')}</Typography>
          <TextField
            label={t('origin', 'Origin')}
            value={usePositionLabel(containerTurnover?.currentPosition)[0]}
            disabled
          />
          <YardPositionInputContainer
            onChange={onChange}
            turnover={containerTurnover}
            validationAlerts={operatorContainerTurnoverStore.validationAlerts}
          />
        </Stack>
      </ContainerMobile>
    </>
  )
})
