import { List, Typography } from '@mui/material'
import { HoldResponseDto } from '@planning/app/api'
import { VerticalOverflowBox } from '@planning/components/VerticalOverflowBox'
import { HoldWithDirection } from '@planning/stores/hold/HoldViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { SelectAllCard } from '../../pages/VesselVisit/Components/SelectAllCard'
import { HoldListCard } from './HoldListCard'
import { IssueHold } from './organisms/IssueHold'

interface Props {
  holds: HoldWithDirection[]
  issueHolds?: HoldResponseDto[]
  checkedIds?: number[]
  handleCheck?: (requestId: number) => void
  handleSelectAll?: () => void
  listMaxHeight?: string
  showDirection?: boolean
}

export const HoldListBox: FC<Props> = observer(
  ({
    holds,
    issueHolds,
    checkedIds,
    handleCheck,
    handleSelectAll,
    listMaxHeight,
    showDirection,
  }) => {
    const isSelectAll = () => !!checkedIds && holds.every(hold => checkedIds.includes(hold.id))
    const isChecked = (id: number) => (checkedIds ? checkedIds.includes(id) : undefined)

    const { t } = useTranslate()

    return (
      <VerticalOverflowBox sx={{ maxHeight: listMaxHeight ?? '60vh', minWidth: '20rem' }}>
        {((holds.length || issueHolds?.length) && (
          <List sx={{ width: '100%' }}>
            {issueHolds?.map(hold => {
              return <IssueHold key={hold.id} hold={hold} />
            })}
            {handleSelectAll && !!holds.length && (
              <SelectAllCard checked={isSelectAll()} onCheck={handleSelectAll} />
            )}
            {holds.map(hold => {
              return (
                <HoldListCard
                  key={`hold-${hold.id}`}
                  hold={hold}
                  checked={isChecked(hold.id)}
                  onCheck={handleCheck}
                  showDirection={showDirection}
                />
              )
            })}
          </List>
        )) || (
          <Typography variant='body2'>{t('noHoldsToDisplay', 'No holds to display')}</Typography>
        )}
      </VerticalOverflowBox>
    )
  },
)
