import { Box } from '@mui/system'
import { carrierVisitViewStatus, CarrierVisitViewStatus } from '@planning/constants'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, useMinimalsTheme } from '@tom-ui/ui'

export const CarrierVisitStatusChip = ({ status }: { status: CarrierVisitViewStatus }) => {
  const theme = useMinimalsTheme()

  const { t } = useTranslate()

  const chipColor = (value: CarrierVisitViewStatus) => {
    switch (value) {
      case CarrierVisitViewStatus.Expected:
        return 'info'
      case CarrierVisitViewStatus.Arrived:
        return 'primary'
      case CarrierVisitViewStatus.Berthed:
        return 'primary'
      case CarrierVisitViewStatus.InOperation:
        return 'busy'
      case CarrierVisitViewStatus.OperationCompleted:
      case CarrierVisitViewStatus.Completed:
        return 'primary'
      case CarrierVisitViewStatus.Departed:
        return 'secondary'

      default:
        return 'secondary'
    }
  }

  const color = chipColor(status)

  return (
    <Box data-cy='carrier-visit-status-chip'>
      <ColoredLabel
        label={t(carrierVisitViewStatus.find(cvs => cvs.id === status)?.value ?? status)}
        color={color}
        sx={{
          width: 'fit-content',
          // todo: switch color hack for visit details page that can be removed once we update to the new design
          backgroundColor: color === 'secondary' ? '#F9FAFB' : theme.palette[color].lighter,
        }}
      />
    </Box>
  )
}
