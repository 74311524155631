import * as yup from 'yup'
import { JobFormProfile } from './job-form.profile'

export const schema = (): yup.ObjectSchema<JobFormProfile> =>
  yup.object({
    cargoUnit: yup.object().label('Container').required(),
    destinationService: yup
      .object()
      .label('Destination')
      .when('isToService', ([isToService], schema) =>
        isToService ? schema.required() : schema.notRequired(),
      ),
    yardBlock: yup
      .object()
      .label('Destination')
      .when('isToService', ([isToService], schema) =>
        isToService ? schema.notRequired() : schema.required(),
      ),
    equipment: yup
      .object()
      .label('Equipment')
      .when('hasToSelectEquipment', ([hasToSelectEquipment], schema) =>
        hasToSelectEquipment ? schema.required() : schema.notRequired(),
      ),
    deadline: yup.date().label('Deadline').notRequired(),
    hasToSelectEquipment: yup.boolean().label('Has to select equipment').optional(),
    isToService: yup.boolean().label('Is to service').optional(),
    origin: yup.string().label('Origin').optional(),
  })
