import { Box, Radio, Stack, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import React, { FC } from 'react'
import { FieldboxSelectionGroupController } from '../molecules/FieldboxSelectionGroup'

interface IProps {
  selectionKey: string
  selectedKey?: string
  label: string
  children?: React.ReactNode
  onSelect?: (key: string) => void
  controller?: FieldboxSelectionGroupController
}

export const FieldboxWithRadio: FC<IProps> = ({
  selectionKey,
  selectedKey,
  label,
  children,
  onSelect,
  controller,
}) => {
  const theme = useMinimalsTheme()
  const isSelected = (controller?.selectedKey ?? selectedKey) === selectionKey

  const handleSelect = () => {
    onSelect?.(selectionKey)
    controller?.handleSelect(selectionKey)
  }

  return (
    <Stack
      mb={2}
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: '1rem',
        borderRadius: theme.customRadius.medium,
        cursor: 'pointer',
      }}
      onClick={handleSelect}
    >
      <Stack direction='row' alignItems='center'>
        <Radio
          checked={isSelected}
          onChange={handleSelect}
          value={selectionKey}
          onClick={e => e.stopPropagation()}
        />
        <Typography variant='body1'>{label}</Typography>
      </Stack>

      {isSelected && children && <Box mt={2}>{children}</Box>}
    </Stack>
  )
}
