import { Alert } from '@mui/material'
import { Stack } from '@mui/system'
import { HandlingDirection } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { Tile, useMinimalsTheme } from '@tom-ui/ui'
import { DamageReportDialog } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import GeneralCargoLocationContent from './components/GeneralCargoLocationContent'
import GeneralCargoLocationHeader from './components/GeneralCargoLocationHeader'
import GeneralCargoStorageTrackerSkeleton from './components/GeneralCargoStorageTrackerSkeleton'
import { GeneralCargoStorageTrackerProps } from './interfaces/general-cargo-storage-tracker-interface'

const GeneralCargoStorageTracker = observer(
  ({
    generalCargoOrderId,
    cargoItemId,
    handlingDirection,
    quantity,
    context,
    onUpdateQuantity,
  }: GeneralCargoStorageTrackerProps) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()
    const {
      generalCargoAreaStore,
      generalCargoStockStore,
      generalCargoPlanningStore,
      generalCargoStorageTrackerUIStore,
    } = useStores()

    // Load all necessary data on component mount
    useEffect(() => {
      const loadGeneralCargoAreas = () => {
        generalCargoAreaStore.loadAll()
      }

      const loadGeneralCargoStock = () => {
        if (generalCargoOrderId) {
          generalCargoStockStore.loadByOrderId(generalCargoOrderId, cargoItemId)
        }
      }

      const loadPlannedOccupancies = () => {
        if (handlingDirection === HandlingDirection.Inbound && generalCargoOrderId) {
          generalCargoPlanningStore.loadPlannedOccupanciesByOrderId(
            generalCargoOrderId,
            cargoItemId,
          )
        }
      }

      const loadPlannedIdentifiableItems = () => {
        if (context === 'occupancy' && generalCargoOrderId) {
          generalCargoStorageTrackerUIStore.loadPlannedIdentifiableItems(
            generalCargoOrderId,
            cargoItemId,
          )
        }
      }

      loadGeneralCargoStock()
      loadPlannedOccupancies()
      loadGeneralCargoAreas()
      loadPlannedIdentifiableItems()
    }, [
      context,
      generalCargoOrderId,
      handlingDirection,
      cargoItemId,
      generalCargoAreaStore,
      generalCargoStockStore,
      generalCargoPlanningStore,
      generalCargoStorageTrackerUIStore,
    ])

    //Send event whenever change the quantity
    useEffect(() => {
      if (onUpdateQuantity) {
        onUpdateQuantity(generalCargoStorageTrackerUIStore.totalNewQuantity)
      }
    }, [generalCargoStorageTrackerUIStore.totalNewQuantity, onUpdateQuantity])

    useEffect(() => {
      if (context && handlingDirection && generalCargoOrderId) {
        generalCargoStorageTrackerUIStore.setContext(context)
        generalCargoStorageTrackerUIStore.loadOccupancyItems(
          handlingDirection as HandlingDirection,
          generalCargoOrderId,
        )
      }
    }, [
      context,
      generalCargoOrderId,
      handlingDirection,
      generalCargoStorageTrackerUIStore,
      generalCargoStorageTrackerUIStore.generalCargoStock,
      generalCargoStorageTrackerUIStore.generalCargoPlannedOccupancies,
      generalCargoStorageTrackerUIStore.plannedIdentifiableItems,
    ])

    const quantityAlertMessage = generalCargoStorageTrackerUIStore.quantityAlertMessage(
      quantity,
      handlingDirection as HandlingDirection,
    )

    const imoClassesSorted = generalCargoStorageTrackerUIStore.getImoClasses()
    const isPlanning = context === 'planning'

    return (
      <>
        <Stack gap={theme.customSpacing.xl} sx={{ maxWidth: 877 }}>
          {generalCargoStorageTrackerUIStore.isLoading ? (
            <GeneralCargoStorageTrackerSkeleton />
          ) : (
            <>
              {Array.isArray(imoClassesSorted) && imoClassesSorted.length > 0 && isPlanning && (
                <Tile
                  nowrap
                  label={t('', 'IMO classes in this order:')}
                  value={imoClassesSorted.join(', ')}
                />
              )}
              <GeneralCargoLocationHeader
                occupancyItems={generalCargoStorageTrackerUIStore.occupancyItems}
                handlingDirection={handlingDirection as HandlingDirection}
                context={context}
                generalCargoOrderId={generalCargoOrderId}
              />
              {generalCargoStorageTrackerUIStore.activeOccupancyItem && (
                <GeneralCargoLocationContent
                  handlingDirection={handlingDirection as HandlingDirection}
                  context={context}
                  occupancyItem={generalCargoStorageTrackerUIStore.activeOccupancyItem}
                />
              )}
              {quantityAlertMessage && <Alert severity='warning'>{quantityAlertMessage}</Alert>}
            </>
          )}
        </Stack>

        <DamageReportDialog />
      </>
    )
  },
)

export default GeneralCargoStorageTracker
