import { Container } from '@mui/system'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { ServiceOrdersViewStore } from '../Stores/ServiceOrdersViewStore'
import { CoolingOrderDialog } from './CoolingOrders/CoolingOrderDialog'
import { StrippingOrderDialog } from './StrippingOrders/StrippingOrderDialog'
import { StuffingOrderDialog } from './StuffingOrders/StuffingOrderDialog'

export const ServiceOrderDialog: FC<{
  store: ServiceOrdersViewStore
  onClose: () => void
  children: React.ReactNode
  renderReservations?: (orderId: number, plannedAmout: number, cargoItemId: number) => JSX.Element
}> = observer(({ store, onClose, children, renderReservations }) => {
  const getContent = () => {
    switch (store.mainServiceType) {
      case 'stuffing':
        return <StuffingOrderDialog store={store} onClose={onClose} />
      case 'stripping':
        return (
          <StrippingOrderDialog
            store={store}
            onClose={onClose}
            renderReservations={renderReservations}
          />
        )
      case 'cooling':
        return <CoolingOrderDialog store={store.coolingOrderViewStore} onClose={onClose} />
    }
  }

  return (
    <Container>
      {getContent()}
      {children}
    </Container>
  )
})
