import { Box, Divider } from '@mui/material'
import { CarrierVisitDirection } from '@storage/app/api/api'
import { AccordionContainer } from '@storage/components/AccordionContainer'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import CarrierVisitAllocationRulesListItem from './CarrierVisitAllocationRulesListItem'
import { TextBox } from '@storage/components/TextBox'

interface CarrierVisitGlobalRulesAccordionContainerProps {
  tooltip: string
  carrierVisitDirection: CarrierVisitDirection
}

export const CarrierVisitGlobalRulesAccordionContainer = observer(
  ({ tooltip, carrierVisitDirection }: CarrierVisitGlobalRulesAccordionContainerProps) => {
    const { t } = useTranslate()
    const { carrierVisitAllocationRulesV2UIStore } = useStores()

    const renderLoadingState = () => (
      <TextBox
        text={t('loadingGlobalAllocationRules', 'Loading global allocation rules...')}
        isLoading
      />
    )

    const renderEmptyState = () => (
      <TextBox text={t('noGlobalAllocationRulesRunning', 'No global allocation rules running')} />
    )

    const renderAllocationRules = () =>
      carrierVisitAllocationRulesV2UIStore.carrierVisitGlobalAllocationRules.map(
        (allocationRule, index, list) => (
          <Box key={allocationRule.id}>
            <CarrierVisitAllocationRulesListItem
              uiStore={carrierVisitAllocationRulesV2UIStore}
              allocationRule={allocationRule}
              carrierVisitDirection={carrierVisitDirection}
              variant='global'
              editable={false}
              showDragIndicator={false}
            />
            {!(index === list.length - 1) && <Divider />}
          </Box>
        ),
      )

    const renderContent = () => {
      if (carrierVisitAllocationRulesV2UIStore.isCarrierVisitGlobalAllocationRulesRequestLoading) {
        return renderLoadingState()
      }

      return carrierVisitAllocationRulesV2UIStore.carrierVisitGlobalAllocationRules.length
        ? renderAllocationRules()
        : renderEmptyState()
    }

    return (
      <AccordionContainer
        title={t('globalRules', 'Global rules')}
        defaultExpanded={true}
        tooltip={tooltip}
      >
        {renderContent()}
      </AccordionContainer>
    )
  },
)
