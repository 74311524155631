import { StowagePositionDto } from '@planning/app/api'

/**
 * Formats a stowage position into a string representation.
 *
 * The position is formatted as `bay.row.tier`, where each component is
 * zero-padded to a fixed width:
 * - `bay` is padded to 3 digits
 * - `row` is padded to 2 digits
 * - `tier` is padded to 2 digits
 *
 * @param position - The stowage position to format.
 * @returns The formatted stowage position string.
 */
export const formatPosition = (position: StowagePositionDto) => {
  const bay = (position.bay ?? 0).toString().padStart(3, '0')
  const row = (position.row ?? 0).toString().padStart(2, '0')
  const tier = (position.tier ?? 0).toString().padStart(2, '0')

  return `${bay}.${row}.${tier}`
}
