import { Stack } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { NoListResult, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { CoolingOrderCardDto } from '../../models/cooling-order-model'
import { ReeferMonitoringDetailsUIStore } from '../../stores/reefer-monitoring-details.ui-store'
import { CoolingOrderCard } from './CoolingOrderCard'

interface Props {
  coolingOrders: CoolingOrderCardDto[]
  reeferMonitoringDetailsUIStore: ReeferMonitoringDetailsUIStore
}
export const CoolingOrderList = observer(
  ({ coolingOrders, reeferMonitoringDetailsUIStore }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    return (
      <>
        <Stack gap={theme.customSpacing.s}>
          {coolingOrders.map(coolingOrder => (
            <CoolingOrderCard
              key={coolingOrder.id}
              coolingOrder={coolingOrder}
              reeferMonitoringDetailsUIStore={reeferMonitoringDetailsUIStore}
            />
          ))}
          {coolingOrders.length === 0 && (
            <NoListResult result={t('noMonitoringFound', 'No monitoring found')} />
          )}
        </Stack>
      </>
    )
  },
)
