import { StackingStrategy } from '@storage/app/api'
import { useMemo } from 'react'

const KEY_PREFIX = 'ST_STRATEGY'

export const useStackingStrategyOptions = () => {
  const options = useMemo(
    () => [
      {
        value: StackingStrategy.BayRowTier,
        title: `${KEY_PREFIX}.BAY_ROW_TIER`,
        subtitle: `${KEY_PREFIX}.BAY_ROW_TIER_DESCRIPTION`,
      },
      {
        value: StackingStrategy.RowBayTier,
        title: `${KEY_PREFIX}.ROW_BAY_TIER`,
        subtitle: `${KEY_PREFIX}.ROW_BAY_TIER_DESCRIPTION`,
      },
      {
        value: StackingStrategy.TierBayRow,
        title: `${KEY_PREFIX}.TIER_BAY_ROW`,
        subtitle: `${KEY_PREFIX}.TIER_BAY_ROW_DESCRIPTION`,
      },
      {
        value: StackingStrategy.TierRowBay,
        title: `${KEY_PREFIX}.TIER_ROW_BAY`,
        subtitle: `${KEY_PREFIX}.TIER_ROW_BAY_DESCRIPTION`,
      },
    ],
    [],
  )

  const getStackingStrategyTranslationKey = (value?: StackingStrategy | null): string => {
    const option = options.find(opt => opt.value === value)
    return option?.title || ''
  }

  return {
    stackingStrategyOptions: options,
    getStackingStrategyTranslationKey,
  }
}
