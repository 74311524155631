import { Autocomplete, InputAdornment, TextField, debounce } from '@mui/material'
import { CargoType, VesselVisitDto } from '@planning/app/api'
import { vesselVisitService } from '@planning/services'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { VesselIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'

interface Props {
  selectedVesselVisit?: VesselVisitDto
  handleOnChange?: (value: VesselVisitDto | undefined) => void
  error?: boolean
  helperText?: React.ReactNode
  label?: string
  disabled?: boolean
  cargoType?: CargoType
}

export const VesselVisitAutoComplete: FC<Props> = observer(
  ({ selectedVesselVisit, handleOnChange, error, helperText, label, disabled, cargoType }) => {
    const { t } = useTranslate()

    const [open, setOpen] = useState<boolean>(false)
    const [options, setOptions] = useState<VesselVisitDto[]>([])

    const onSearchFilterChange = debounce(async (filter?: string) => {
      setOptions((await vesselVisitService.get(1, 10, filter, cargoType)).payload)
    }, 500)

    const getArrivalDate = (vesselVisit: VesselVisitDto) => {
      if (vesselVisit.ata) return `${t('ata', 'ATA')}: ${formatDateTime(vesselVisit.ata)}`
      else if (vesselVisit.eta) return `${t('eta', 'ETA')}: ${formatDateTime(vesselVisit.eta)}`

      return ''
    }

    useEffect(() => {
      if (open) {
        const fetch = async () => {
          return (await vesselVisitService.get(1, 10, undefined, cargoType)).payload
        }

        fetch().then(payload => setOptions(payload))
      } else {
        setOptions([])
      }
    }, [cargoType, open])

    return (
      <Autocomplete
        disablePortal
        disabled={disabled}
        getOptionLabel={vesselVisit => `${vesselVisit.identifier} - ${getArrivalDate(vesselVisit)}`}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={options}
        isOptionEqualToValue={(option: VesselVisitDto, value: VesselVisitDto) =>
          option.id === value?.id
        }
        value={selectedVesselVisit ?? null}
        onChange={(_, vesselVisit) => {
          if (handleOnChange) handleOnChange(vesselVisit ?? undefined)
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={label ?? t('vessel', 'Vessel')}
            error={error}
            helperText={helperText}
            onChange={e => {
              onSearchFilterChange(e.target.value)
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start'>
                  <VesselIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    )
  },
)
