import { Box, Button } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitStatus } from '@planning/app/api'
import { HeaderWithActions } from '@planning/components/HeaderWithActions'
import { GetTruckVisitsQuery } from '@planning/messages/queries'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { useEffect } from 'react'
import { GatePassageResultAlert } from '../GateClerk/Components/GatePassageResultAlert'
import { GateInDialog } from './Components/GateInDialog'
import { TabData, TabsList } from './Components/TabsList'

export const GateControlHome = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { truckVisitItemStore, gateInViewStore, generalCargoViewStore } = usePlanningStore()

  useEffect(() => {
    const todayStartDate = moment().startOf('d')
    const todayEndDate = moment().endOf('d')
    const includeCompleted = true
    const orderBy: 'eta' | 'ata' | 'atd' = 'ata'
    const isDescending = false

    truckVisitItemStore.fetch(
      new GetTruckVisitsQuery(
        todayStartDate.toDate(),
        todayEndDate.toDate(),
        includeCompleted,
        orderBy,
        isDescending,
        'eta',
      ),
    )

    truckVisitItemStore.fetch(
      new GetTruckVisitsQuery(
        todayStartDate.toDate(),
        todayEndDate.toDate(),
        includeCompleted,
        orderBy,
        isDescending,
        'atd',
      ),
    )

    truckVisitItemStore.fetch(
      new GetTruckVisitsQuery(
        undefined,
        undefined,
        includeCompleted,
        orderBy,
        isDescending,
        'eta',
        undefined,
        true,
      ),
    )
  }, [truckVisitItemStore])

  const tabs: TabData[] = [
    {
      title: t('expected', 'Expected'),
      key: 'expected',
      data: truckVisitItemStore.filteredExpectedTruckVisitsInDateRange,
      carrierVisitStatus: CarrierVisitStatus.Expected,
      count: tab => {
        return tab === 0 // 0 is the index of the 'expected' tab
          ? truckVisitItemStore.filteredExpectedTruckVisitsInDateRange.length
          : truckVisitItemStore.expectedTruckVisitsForToday.length
      },
    },
    {
      title: t('onTerminal', 'On Terminal'),
      key: 'onTerminal',
      data: truckVisitItemStore.filteredArrivedTruckVisits,
      carrierVisitStatus: CarrierVisitStatus.Arrived,
      count: tab => {
        return tab === 1 // 1 is the index of the 'onTerminal' tab
          ? truckVisitItemStore.filteredArrivedTruckVisits.length
          : truckVisitItemStore.arrivedTruckVisits.length
      },
    },
    {
      title: t('departed', 'Departed'),
      key: 'departed',
      data: truckVisitItemStore.filteredDepartedTruckVisitsInDateRange,
      carrierVisitStatus: CarrierVisitStatus.Departed,
      count: tab => {
        return tab === 2 // 2 is the index of the 'departed' tab
          ? truckVisitItemStore.filteredDepartedTruckVisitsInDateRange.length
          : truckVisitItemStore.departedTruckVisitsForToday.length
      },
    },
  ]

  const onClickGateIn = async () => {
    await generalCargoViewStore.getPackages()
    gateInViewStore.setIsTruckAppointment(false)
    gateInViewStore.toggleGateInDialog(true)
  }

  const onClickTruckAppointment = async () => {
    await generalCargoViewStore.getPackages()
    gateInViewStore.setIsTruckAppointment(true)
    gateInViewStore.toggleGateInDialog(true)
  }

  return (
    <Box
      sx={{ backgroundColor: theme.palette.grey[200], paddingBottom: '16px', minHeight: '100%' }}
    >
      <HeaderWithActions
        title={t('gateControl', 'Gate Control')}
        actions={[
          <Button
            key='createAppointment'
            variant='outlined'
            size='large'
            onClick={onClickTruckAppointment}
          >
            {t('createAppointmentButton', 'Create appointment')}
          </Button>,
          <Button
            data-cy='gate-control-gate-in-btn'
            key='gateInButton'
            variant='contained'
            size='large'
            onClick={onClickGateIn}
          >
            {t('gateIn', 'Gate In')}
          </Button>,
        ]}
      />

      <Box
        sx={{
          padding: `0 ${theme.customSpacing.l}`,
        }}
      >
        <TabsList tabs={tabs} />

        <GateInDialog store={gateInViewStore} />
        <GatePassageResultAlert store={gateInViewStore.notificationStore} />
      </Box>
    </Box>
  )
})
