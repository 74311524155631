import { Chip, Stack } from '@mui/material'
import { EquipmentType } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { EquipmentOperatorUIStore } from '../../stores/equipment-operator.ui-store'
import { JobFilterBays } from './JobFilterBays'
import { JobFilterByArea } from './JobFilterByArea'
import { JobFilterSizes } from './JobFilterSizes'
import { JobFilterTypes } from './JobFilterTypes'
import { YardAssignedEquipments } from './YardAssignedEquipments'

interface Props {
  store: EquipmentOperatorUIStore
}
export const JobsFilter = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const handleNonNumeric = (value?: boolean) => {
    store.setNonNumeric(value)
  }

  return (
    <Stack gap={theme.customSpacing.m}>
      {store.typeOfSelectedEquipment === EquipmentType.Sts && (
        <JobFilterBays
          bays={store.vesselBays}
          selectedBays={store.selectedVesselBays}
          onSelectBays={bays => store.setSelectedVesselBays(bays)}
        />
      )}

      {store.hasJobNavigation && (
        <>
          <Stack flexDirection='row' gap={theme.customSpacing.xs} flexWrap='wrap'>
            <JobFilterTypes
              jobs={store.jobs}
              selectedType={store.jobType}
              onSelectType={jobType => store.setJobType(jobType)}
            />

            <JobFilterByArea
              jobs={store.jobs}
              yardBlocks={store.yardBlocksAssignments}
              selectedYardBlockId={store.selectedYardBlockId}
              selectedBays={store.selectedBays}
              onSelect={id => {
                store.setYardBlockId(id)
              }}
              onSelectBays={bays => {
                store.setSelectedBays(bays)
              }}
            />

            <JobFilterSizes
              jobs={store.jobs}
              selectedSize={store.selectedContainerSize}
              onSelectSize={size => store.setSelectedContainerSize(size)}
            />

            <Chip
              label={t('nonNumerics', 'Non numerics')}
              color={store.nonNumeric ? 'primary' : undefined}
              onClick={() => handleNonNumeric(true)}
              onDelete={store.nonNumeric ? () => handleNonNumeric(false) : undefined}
            />
          </Stack>
          <YardAssignedEquipments
            equipments={
              store.yardBlocksAssignments.find(x => x.yardBlockId === store.selectedYardBlockId)
                ?.equipments
            }
          />
        </>
      )}
    </Stack>
  )
})
