import { Grid, Stack, Theme, Typography } from '@mui/material'
import { OperationType, VesselBayDto } from '@operations/app/api'
import { DefaultParamType, TFnType, TranslationKey, useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import BayView from '../components/bayView/BayView'
import { BayWorkStatus } from '../components/bayView/BayWorkStatus'
import { CraneOperatorUIStore, filterDuplicatedTwenties } from '../stores/crane-operator.ui-store'

export interface Props {
  uiStore: CraneOperatorUIStore
}

const CraneOperatorListView = observer(({ uiStore }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const inboundViews = uiStore.inboundBayViewsGroup
    .map((group, key) => buildGroupedList(theme, group, t, key, uiStore, OperationType.Inbound))
    .value()

  const outboundViews = uiStore.outboundBayViewsGroup
    .map((group, key) => buildGroupedList(theme, group, t, key, uiStore, OperationType.Outbound))
    .value()

  return (
    <Grid container>
      {inboundViews.length < 1 && outboundViews.length < 1 ? (
        <Grid item xs={12}>
          <h2>{t('noDataForThisVisit', 'No Data for this visit')}</h2>
        </Grid>
      ) : (
        <>
          {inboundViews.length >= 1 && (
            <Grid item xs={12}>
              <Typography variant='subtitle1' margin='8px'>
                {t('discharge', 'Discharge')}
              </Typography>
            </Grid>
          )}
          {inboundViews}
          {outboundViews.length >= 1 && (
            <Grid item xs={12}>
              <Typography variant='subtitle1' margin='8px'>
                {t('load', 'Load')}
              </Typography>
            </Grid>
          )}
          {outboundViews}
        </>
      )}
    </Grid>
  )
})

export default CraneOperatorListView

function buildGroupedList(
  theme: Theme,
  group: VesselBayDto[],
  t: TFnType<DefaultParamType, string, TranslationKey>,
  key: string,
  uiStore: CraneOperatorUIStore,
  operationType: OperationType,
): JSX.Element {
  const [bays, bayName] = filterDuplicatedTwenties(group)

  return (
    <Grid
      container
      item
      xs={5.9}
      sx={{
        borderRadius: '16px',
        boxShadow: '0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)',
        margin: theme.spacing(0.5),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
      }}
      key={bays[0].bay}
    >
      <Grid item xs={12}>
        <Stack justifyContent='center' alignItems='center'>
          <Link
            to={`/operatorViews/Crane/BayView/Item/${uiStore.selectedVesselVisitId}/${uiStore.selectedEquipmentId}/${key}/${operationType}`}
          >
            <Typography variant='captionBold'>
              {t('bay')} {key}
            </Typography>
          </Link>

          <BayWorkStatus
            operationType={operationType}
            bays={bays}
            workStatusPerBay={uiStore.workStatusPerBay}
          />
        </Stack>
      </Grid>
      {bays.map(i => (
        <Grid item xs={bays.length == 1 ? 12 : 6} key={i.bay}>
          <BayView
            key={i.bay + operationType}
            bayGeometry={i}
            bayName={bayName}
            equipmentId={uiStore.selectedEquipmentId!}
            restowWorkInstructions={uiStore.restowWorkInstructions}
            zoom={0.2}
            operationType={operationType}
            workStatusPerBay={uiStore.workStatusPerBay}
          ></BayView>
        </Grid>
      ))}
    </Grid>
  )
}
