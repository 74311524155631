import { TenantDto, UpdateTenantReeferContainerMaxUnpluggedTimeCommand } from '@admin/app/api'
import { TenantReeferMaxUnpluggedTimeFormProfile } from './tenant-reefer-max-unplugged-time-form.profile'

const mapDtoToFormValues = ({
  id,
  reeferContainerMaxUnpluggedTime,
}: TenantDto): TenantReeferMaxUnpluggedTimeFormProfile => ({
  id,
  reeferContainerMaxUnpluggedTime:
    reeferContainerMaxUnpluggedTime || reeferContainerMaxUnpluggedTime === 0
      ? reeferContainerMaxUnpluggedTime
      : undefined,
})

const mapFormValuesToUpdateDto = ({
  id,
  reeferContainerMaxUnpluggedTime,
}: TenantReeferMaxUnpluggedTimeFormProfile): UpdateTenantReeferContainerMaxUnpluggedTimeCommand => ({
  id: id!,
  reeferContainerMaxUnpluggedTime:
    reeferContainerMaxUnpluggedTime || reeferContainerMaxUnpluggedTime === 0
      ? reeferContainerMaxUnpluggedTime
      : undefined,
})

export const TenantReeferMaxUnpluggedTimeFormMapper = {
  mapDtoToFormValues,
  mapFormValuesToUpdateDto,
}
