export const unformatRailcarName = (name: string) => {
  if (/^[A-Za-z0-9]{4}[ ]?[A-Za-z0-9]{3}[ ]?[A-Za-z0-9]{4}[- ]?[A-Za-z0-9]$/.test(name)) {
    return name.replace(/[^A-Za-z0-9]/g, '')
  }
  return name
}

export const formatRailcarName = (name: string) => {
  if (/^[A-Za-z0-9]{12}$/.test(name)) {
    return name.replace(
      /^([A-Za-z0-9]{4})([A-Za-z0-9]{3})([A-Za-z0-9]{4})([A-Za-z0-9])$/,
      '$1 $2 $3-$4',
    )
  }
  return name
}
