import { Box, Fade, Grid, Stack, Tooltip, Typography } from '@mui/material'
import { OrderStatus } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { Breadcrumbs, EmptyIndicator, IconButton, PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { AlertsCard } from '../components/cards/AlertsCard'
import { CoolingOrderDate } from '../components/cards/CoolingOrderDate'
import { CoolingOrderRange } from '../components/cards/CoolingOrderRange'
import { ReeferHistoryTable } from '../components/table/ReeferHistoryTable'
import { ReeferMonitoringDetailsUIStore } from '../stores/reefer-monitoring-details.ui-store'

interface Props {
  reeferMonitoringDetailsUIStore: ReeferMonitoringDetailsUIStore
  maxPluggedOutTime?: number | null
}
export const ReeferMonitoringDetailsContainers = observer(
  ({ reeferMonitoringDetailsUIStore, maxPluggedOutTime }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const order = reeferMonitoringDetailsUIStore.editingEntity

    return (
      <Fade in={reeferMonitoringDetailsUIStore.isOpen} style={{ height: '100%' }}>
        <Stack width='100%' height='100%'>
          <Breadcrumbs
            items={[
              {
                label: t('reeferMonitoring', 'Reefer monitoring'),
                onClick: () => reeferMonitoringDetailsUIStore.toggle(),
              },
              {
                label: order?.container.containerNumber ?? EmptyIndicator,
              },
            ]}
          />

          <Box
            sx={{
              padding: theme.customSpacing.l,
              borderRadius: 'none',
              borderBottom: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <Typography variant='h3'>
              {t('containerMonitoringDetails', 'Container monitoring details')}
            </Typography>
          </Box>
          {!!order?.alerts?.length && (
            <Box
              sx={{
                backgroundColor: theme.palette.grey[200],
                padding: theme.customSpacing.l,
                paddingBottom: 0,
                paddingTop: theme.customSpacing.m,
              }}
            >
              <AlertsCard isForDetails coolingOrder={order} />
            </Box>
          )}
          <Stack
            sx={{
              backgroundColor: theme.palette.grey[200],
              height: '100%',
              padding: theme.customSpacing.l,
              gap: theme.customSpacing.m,
            }}
          >
            <Grid container spacing={theme.customSpacing.m}>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>
                  {t('generalDetails', 'General details')}:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} lg={2}>
                <InformationField label={t('customer', 'Customer')}>
                  <Typography variant='body2' color='secondary'>
                    {order?.customer ?? EmptyIndicator}
                  </Typography>
                </InformationField>
              </Grid>
              <Grid item xs={12} sm={3} lg={2}>
                <InformationField label={t('length', 'Length')}>
                  <Typography variant='body2' color='secondary'>
                    {order?.container.length ?? EmptyIndicator}
                  </Typography>
                </InformationField>
              </Grid>
              <Grid item xs={12} sm={3} lg={2}>
                <InformationField label={t('yardLocation', 'Yard location')}>
                  <Typography variant='body2' color='secondary'>
                    {order?.container.yardBlock ? order.container.locationName : EmptyIndicator}
                  </Typography>
                </InformationField>
              </Grid>

              <Grid item xs={12}>
                <Typography variant='subtitle1'>
                  {t('handlingRequirements', 'Handling requirements')}:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} lg={2}>
                <InformationField label={t('requestedTemperature', 'Requested temperature')}>
                  <CoolingOrderRange
                    value={order?.requestedTemperature}
                    min={order?.minRequestedTemperature}
                    max={order?.maxRequestedTemperature}
                  />
                </InformationField>
              </Grid>
              <Grid item xs={12} sm={3} lg={2}>
                <InformationField label={t('requestedVentilation', 'Requested ventilation')}>
                  <CoolingOrderRange
                    value={order?.idealVentilation}
                    min={order?.minIdealVentilation}
                    max={order?.maxIdealVentilation}
                    type={t('m³/h', 'm³/h')}
                  />
                </InformationField>
              </Grid>
              <Grid item xs={12} sm={3} lg={2}>
                <InformationField label={t('requestedHumidity', 'Requested humidity')}>
                  <CoolingOrderRange
                    value={order?.idealHumidity}
                    min={order?.minIdealHumidity}
                    max={order?.maxIdealHumidity}
                    type='%'
                  />
                </InformationField>
              </Grid>
              <Grid item xs={12} sm={3} lg={2}>
                <InformationField label={t('maxPlugOutTime', 'Max plug out time')}>
                  <Typography variant='body2' color='secondary'>
                    {maxPluggedOutTime ?? EmptyIndicator}
                  </Typography>
                </InformationField>
              </Grid>

              <Grid item xs={12}>
                <Typography variant='subtitle1'>
                  {t('lastRecordings', 'Last recordings')}:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} lg={2}>
                <InformationField label={t('lastPlugIn', 'Last plug in')}>
                  <CoolingOrderDate date={order?.lastPlugInDateTime} />
                </InformationField>
              </Grid>
              <Grid item xs={12} sm={3} lg={2}>
                <InformationField label={t('lastPlugOut', 'Last plug out')}>
                  <CoolingOrderDate date={order?.lastPlugOutDateTime} />
                </InformationField>
              </Grid>
              <Grid item xs={12} sm={3} lg={2}>
                <InformationField label={t('nextCheck', 'Next check')}>
                  <CoolingOrderDate date={order?.nextCheckDateTime} />
                </InformationField>
              </Grid>

              <Grid item xs={12}>
                <Typography variant='subtitle1'>{t('recordings', 'Recordings')}:</Typography>
              </Grid>
            </Grid>

            <ReeferHistoryTable
              coolingOrderId={order!.id}
              items={order?.reeferTemperatures}
              isFinished={order?.status === OrderStatus.Fulfilled}
              onEditItem={(_, recordId) => reeferMonitoringDetailsUIStore.toggleRecord(recordId)}
            />
            {order?.status !== OrderStatus.Fulfilled && (
              <Tooltip title={t('addNewRegister', 'Add new register')} sx={{ alignSelf: 'end' }}>
                <IconButton onClick={() => reeferMonitoringDetailsUIStore.toggleRecord()}>
                  <PlusIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </Fade>
    )
  },
)

interface InformationFieldProps {
  label: string
  children: ReactNode
}
const InformationField = ({ label, children }: InformationFieldProps) => {
  return (
    <Stack>
      <Typography variant='subtitle2'>{label}:</Typography>
      {children}
    </Stack>
  )
}
