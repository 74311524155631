import { CarrierVisitDirection, ContainerTurnoverDto, SlotDto } from '@storage/app/api'
import { WeightClassStore } from '@storage/features/weight-classes/stores/weight-class.store'
import { YardBlockSlotStore } from '@storage/stores/yard-block-slot.store'
import { makeAutoObservable } from 'mobx'
import { doesSlotHaveOccupiedTurnoverForVisitAndDirection } from '../utils/yard-planning-dashboard-stack.util'

export interface StackSlotDetails {
  tier: number
  containerNumber: string | null
  containerInfo: string | null
  nbrOfOccupancies?: number
  isOnTerminal?: boolean
}

export class StackDetailsTooltipContainerUIStore {
  constructor(
    private readonly _yardBlockSlotStore: YardBlockSlotStore,
    private readonly _weightClassStore: WeightClassStore,
  ) {
    makeAutoObservable(this)
  }

  yardBlockTierSlots(blockId: string, bayId: string, rowId: string): SlotDto[] {
    return this._yardBlockSlotStore.entries.filter(
      slot =>
        slot.yardBlockId === blockId &&
        slot.yardBlockBayId === bayId &&
        slot.yardBlockRowId === rowId,
    )
  }

  stackSlotDetails(
    blockId: string,
    bayId: string,
    rowId: string,
    maxTier: number,
    carrierVisitId: number,
    carrierVisitDirection: CarrierVisitDirection,
  ): StackSlotDetails[] {
    const slots = this.yardBlockTierSlots(blockId, bayId, rowId)
    return Array.from({ length: maxTier }, (_, index) => {
      const tier = maxTier - index
      const slot = slots.find(s => s.tier === tier)
      if (slot && slot.isOccupied && slot.occupiedTurnovers.length > 0) {
        const container = slot.occupiedTurnovers[0]
        return {
          tier,
          containerNumber: container.containerNumber,
          containerInfo: this.getContainerInfo(carrierVisitDirection, container),
          isOnTerminal: doesSlotHaveOccupiedTurnoverForVisitAndDirection(
            slot,
            carrierVisitId,
            carrierVisitDirection,
          ),
          nbrOfOccupancies: slot.occupiedTurnovers.length,
        }
      }
      return {
        tier,
        containerNumber: null,
        containerInfo: null,
      }
    })
  }

  private getContainerInfo(
    carrierVisitDirection: CarrierVisitDirection,
    container: ContainerTurnoverDto,
  ): string {
    const infoParts = [
      container.containerType,
      `${container.size}ft`,
      container.grossWeight ? this._weightClassStore.getWeightClassName(container.grossWeight) : '',
      carrierVisitDirection === CarrierVisitDirection.Inbound
        ? container.shippingLine
        : container.portOfDischarge,
    ]

    return infoParts.filter(Boolean).join(' ')
  }
}
