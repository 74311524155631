import * as yup from 'yup'
import { ContainerDamageCatalogueFormProfile } from './container-damage-form.profile'

export const schema = (): yup.ObjectSchema<ContainerDamageCatalogueFormProfile> =>
  yup.object().shape({
    id: yup.number().optional(),
    name: yup.string().label('Name').emptyAsUndefined().required(),
    code: yup.string().label('Code').emptyAsUndefined().optional(),
    isDefault: yup.boolean().optional(),
  })
