import { Card, CardContent, Typography } from '@mui/material'

interface Props {
  label: string
  value: number
  dataCy?: string
}

export const WorkInstructionInfoCard = ({ label, value, dataCy }: Props) => {
  return (
    <Card data-cy={dataCy}>
      <CardContent>
        <Typography textAlign='center' variant='h2' data-cy={`${dataCy}-value`}>
          {value}
        </Typography>
        <Typography textAlign='center' variant='body1' data-cy={`${dataCy}-text`}>
          {label}
        </Typography>
      </CardContent>
    </Card>
  )
}
