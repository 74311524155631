import { Stack } from '@mui/material'
import { useCarrierTypeOptions } from '@storage/app/carriers/hooks'
import {
  useCargoStatusOptions,
  useContainerSizeOptions,
  useDangerousOptions,
  useReeferOptions,
} from '@storage/app/container-turnovers/hooks'
import { useContainerHeightOptions } from '@storage/app/container-turnovers/hooks/use-container-height-options.hook'
import { useContainerTypeOptions } from '@storage/app/container-turnovers/hooks/use-container-type-options.hook'
import { FormProps } from '@storage/app/models'
import { ControlledTextField } from '@storage/components/form-controls'
import { WeightClassContainerUIStore } from '@storage/features/weight-classes/stores/weight-class-container-ui-store'
import {
  Hint,
  ManualInputValidationStore,
} from '@storage/pages/container-turnovers/stores/manual-input-validation.store'
import { CustomerStore } from '@storage/stores/customer.store'
import { UtilityMappers } from '@storage/utils/mappers'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useEffect, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { AllocationRuleCommonFields } from '../../components/AllocationRuleCommonFields'
import { AllocationRuleTemplateFormProfile } from '../allocation-rule-templates-form/allocation-rule-templates-form.profile'
import { ControlledRadioGroup } from '@storage/components/form-controls/ControlledRadioGroup'

interface Props extends FormProps {
  allocationRuleForm: any
  manualInputValidationStore: ManualInputValidationStore
  isATemplate?: boolean
  weightClassUIStore: WeightClassContainerUIStore
  customerStore: CustomerStore
  openWithOnlyContainerNumber?: boolean
  showCarrierTypeField?: boolean
  onSubmit: (formValues: AllocationRuleTemplateFormProfile) => Promise<void> | void
}

export const CarrierVisitAllocationRuleForm = ({
  allocationRuleForm,
  id,
  onSubmit,
  manualInputValidationStore,
  isATemplate,
  weightClassUIStore,
  customerStore,
  openWithOnlyContainerNumber,
  showCarrierTypeField,
}: Props) => {
  const { t } = useTranslate()

  const { handleSubmit, control, formState } = allocationRuleForm

  const destinationInputValue = useWatch({ control, name: 'destination' })

  const [isValidDangerousGoods, setIsValidDangerousGoods] = useState(true)
  const [isDangerousAlertMessage, setIsDangerousAlertMessage] = useState('')

  const dangerousSelected = useWatch({ control, name: 'facets.isDangerous' })

  const containerSizeOptions = useContainerSizeOptions()
  const containerTypeOptions = useContainerTypeOptions()
  const containerHeightOptions = useContainerHeightOptions()
  const cargoStatusOptions = useCargoStatusOptions()
  const reeferOptions = useReeferOptions()
  const dangerousOptions = useDangerousOptions()
  const carrierOptions = useCarrierTypeOptions(true)

  useEffect(() => {
    if (destinationInputValue) {
      manualInputValidationStore.validate(destinationInputValue.trim().toUpperCase())
      const validateDangerousGoodsSelection = () => {
        const { isValidDangerousGoods, isDangerousAlertMessage } =
          manualInputValidationStore.validateDGLocation(
            UtilityMappers.mapOptionalFieldValueToBoolean(dangerousSelected),
            true,
          )
        setIsValidDangerousGoods(isValidDangerousGoods)
        setIsDangerousAlertMessage(isDangerousAlertMessage)
      }
      validateDangerousGoodsSelection()
    }
  }, [dangerousSelected, destinationInputValue, manualInputValidationStore])

  useEffect(() => {
    weightClassUIStore.loadWeightClasses()
  }, [weightClassUIStore])

  return (
    <Stack
      gap={1}
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      onSubmit={handleSubmit(onSubmit)}
    >
      <ControlledTextField
        formState={formState}
        control={control}
        name='name'
        label={t('name', 'Name')}
        required
        dataCy='allocation-name-field'
      />

      <AllocationRuleCommonFields
        control={control}
        formState={formState}
        containerSizeOptions={containerSizeOptions}
        containerTypeOptions={containerTypeOptions}
        containerHeightOptions={containerHeightOptions}
        cargoStatusOptions={cargoStatusOptions}
        reeferOptions={reeferOptions}
        dangerousOptions={dangerousOptions}
        carrierOptions={carrierOptions}
        weightClassesOptions={weightClassUIStore.weightClassesOptions}
        customers={customerStore.customers}
        openWithOnlyContainerNumber={openWithOnlyContainerNumber}
        showCarrierTypeField={showCarrierTypeField}
      />
    </Stack>
  )
}
