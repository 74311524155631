import { ListItemText, MenuItem } from '@mui/material'
import { YardBlockSelectOption } from '@operations/features/equipments/components/form/controlledYardBlock/ControlledYardBlockWithBayAssignment'
import { useTranslate } from '@tolgee/react'
import { ChipWithOptions } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  selectedYardBlock?: string
  yardBlocks: YardBlockSelectOption[]
  onSelectYardBlock: (yardBlock?: string) => void
}
export const YardBlocksChip = observer(
  ({ selectedYardBlock, yardBlocks, onSelectYardBlock }: Props) => {
    const { t } = useTranslate()

    if (!yardBlocks.length) return <></>

    return (
      <ChipWithOptions
        value={selectedYardBlock}
        label={t('yardBlocks', 'Yard blocks')}
        options={yardBlocks
          .map(yb => yb.label)
          .map(yardBlock => (
            <MenuItem
              key={yardBlock}
              selected={yardBlock === selectedYardBlock}
              value={yardBlock}
              onClick={() => {
                onSelectYardBlock(yardBlock !== selectedYardBlock ? yardBlock : undefined)
              }}
            >
              <ListItemText primary={yardBlock} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          ))}
      />
    )
  },
)
