import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { ReeferTemperatureDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { EditIcon, EmptyIndicator, IconButton, useMinimalsTheme } from '@tom-ui/ui'
import { CoolingOrderDate } from '../cards/CoolingOrderDate'

interface Props {
  isFinished?: boolean
  coolingOrderId: number
  items?: ReeferTemperatureDto[] | null
  onEditItem?: (id: number, recordId: number) => Promise<void> | void
}

const CustomCell = styled(TableCell)(({ theme }) => ({
  paddingTop: theme.customSpacing.xxs,
  paddingBottom: theme.customSpacing.xxs,
}))

export const ReeferHistoryTable = ({ coolingOrderId, items, isFinished, onEditItem }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <>
      {items?.length ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: '100%' }}>
            <TableRow sx={{ backgroundColor: theme.palette.grey[300] }}>
              <TableCell>
                <Typography variant='subtitle2'>{t('timestamp', 'Timestamp')}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='subtitle2'>{t('pluggedIn', 'Plugged in')}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='subtitle2'>{t('temperature', 'Temperature')}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='subtitle2'>{t('ventilation', 'Ventilation')} </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='subtitle2'>{t('humidity', 'Humidity')}</Typography>
              </TableCell>
              <TableCell>
                <Typography variant='subtitle2'>
                  {t('additionalErrorInfo', 'Additional error info')}
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableBody>
              {items?.map(item => (
                <TableRow key={item.id} sx={{ borderTop: `1px solid ${theme.palette.grey[300]}` }}>
                  <CustomCell>
                    <CoolingOrderDate date={item.recordedAt} />
                  </CustomCell>
                  <CustomCell>{item.isPluggedIn ? t('yes', 'Yes') : t('no', 'No')}</CustomCell>
                  <CustomCell>{item.temperature ?? EmptyIndicator}</CustomCell>
                  <CustomCell>
                    {item.ventilation ? `${item.ventilation}${t('m³/h', 'm³/h')}` : EmptyIndicator}
                  </CustomCell>
                  <CustomCell>{item.humidity ? `${item.humidity}%` : EmptyIndicator}</CustomCell>
                  <CustomCell>{item.additionalInfo ?? EmptyIndicator}</CustomCell>
                  <CustomCell align='right' width='25px'>
                    {!isFinished && onEditItem && (
                      <Tooltip title={t('edit', 'Edit')}>
                        <IconButton onClick={() => onEditItem(coolingOrderId, item.id)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </CustomCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant='h4' textAlign='center'>
          {t('noTemperatureRecords', 'No temperature records')}
        </Typography>
      )}
    </>
  )
}
