import { Box, Stack } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'

interface PageHeaderProps {
  children: React.ReactNode
}

export const PageHeader = ({ children }: PageHeaderProps) => {
  const theme = useMinimalsTheme()
  return (
    <Stack
      direction='row'
      height='72px'
      p={theme.customSpacing.m}
      alignItems='center'
      justifyContent='space-between'
      component={Box}
      sx={{
        backgroundColor: 'background.paper',
        borderBottom: 1,
        borderColor: 'divider',
      }}
    >
      {children}
    </Stack>
  )
}
