import { Box, Typography } from '@mui/material'
import { CarrierVisitDirection, StackDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { colorEmptyOccupancy, colorFullOccupancy } from '@storage/pages/yard-management/constants'
import { pickGradient } from '@storage/utils/colors'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { doesSlotHaveOccupiedTurnoverForVisitAndDirection } from '../utils/yard-planning-dashboard-stack.util'
import { YardBlockStackTooltip } from './YardBlockStackTooltip'

interface YardPlanningDashboardStacksProps {
  stacks: StackDto[]
  stackWidthPixels: string
  maxTier: number
  bayCount: number
  carrierVisitId: number
  carrierVisitDirection: CarrierVisitDirection
}

export interface ValidStackDto extends StackDto {
  overlapAmount: number
}

export const YardPlanningDashboardStacks = observer(
  ({
    stacks,
    stackWidthPixels,
    maxTier,
    bayCount,
    carrierVisitId,
    carrierVisitDirection,
  }: YardPlanningDashboardStacksProps) => {
    const { palette } = useMinimalsTheme()
    const { yardBlockSlotStore } = useStores()

    const validStacks = useMemo(() => {
      let stackTotal = 0
      stacks.forEach(stack => (stackTotal += stack.stackSizeDigit))

      return stacks.map(stack => ({
        ...stack,
        overlapAmount: stackTotal - bayCount,
      }))
    }, [stacks, bayCount])

    const stackColor = useMemo(
      () =>
        validStacks.map(stack => {
          if (
            yardBlockSlotStore
              .getStackSlots(stack.bayId, stack.rowId)
              .some(slot =>
                doesSlotHaveOccupiedTurnoverForVisitAndDirection(
                  slot,
                  carrierVisitId,
                  carrierVisitDirection,
                ),
              )
          )
            return palette.info.main

          return stack.numberOfOccupiedSlots
            ? pickGradient(
                colorEmptyOccupancy,
                colorFullOccupancy,
                stack.numberOfOccupiedSlots * (1 / maxTier),
              )
            : palette.common.white
        }),
      [
        validStacks,
        yardBlockSlotStore.entries,
        palette,
        maxTier,
        carrierVisitId,
        carrierVisitDirection,
      ],
    )

    const filteredValidStacks = useMemo(() => {
      const filterUnnecessaryStacks = (
        stack: ValidStackDto,
        index: number,
        array: ValidStackDto[],
      ) =>
        !(
          index === array.length - 1 &&
          stack.overlapAmount > 0 &&
          stack.numberOfOccupiedSlots === 0 &&
          stack.stackSizeDigit === 1
        )

      return validStacks.filter(filterUnnecessaryStacks)
    }, [validStacks])

    return (
      <>
        {filteredValidStacks.map((stack, index) => (
          <YardBlockStackTooltip
            key={`${stack.bayId}_${stack.rowId}`}
            stack={stack}
            maxTier={maxTier}
            carrierVisitId={carrierVisitId}
            carrierVisitDirection={carrierVisitDirection}
          >
            <Box
              width={`calc(${stackWidthPixels} * ${index === validStacks.length - 1 && stack.overlapAmount > 0 ? 1 : stack.stackSizeDigit})`}
              textAlign='center'
              position='relative'
              sx={{
                backgroundColor: stackColor[index],
                border: `${palette.grey[600]} 1px solid`,
                borderLeft: index === 0 ? `${palette.grey[600]} 1px solid` : undefined,
                flex: 'none',
              }}
            >
              <Typography
                variant='body2'
                color={
                  stack.numberOfOccupiedSlots >= stack.numberOfSlots - 1
                    ? palette.common.white
                    : palette.common.black
                }
              >
                {stack.numberOfOccupiedSlots > 0 ? stack.numberOfOccupiedSlots : ''}
              </Typography>
            </Box>
          </YardBlockStackTooltip>
        ))}
      </>
    )
  },
)
