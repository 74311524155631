import { OrderUpdateResponseDto } from '@planning/app/api'
import { EntityItem } from '../base/EntityItem'
import { IEntity } from '../types'

export type IOrderUpdateItem = IEntity<OrderUpdateResponseDto>

export class OrderUpdateItem
  extends EntityItem<OrderUpdateResponseDto>
  implements IOrderUpdateItem
{
  constructor(id: number, data: OrderUpdateResponseDto) {
    super(id, data)
  }
}
