import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { emptyIndicator } from '@planning/constants'

interface CellWithTitleAndSubtitleProps {
  title?: string | null
  subtitle?: string | null
}

export const CellWithTitleAndSubtitle = ({ title, subtitle }: CellWithTitleAndSubtitleProps) => {
  return (
    <Stack>
      <Typography variant='subtitle2'>{title ?? emptyIndicator}</Typography>
      {subtitle && (
        <Typography variant='body2' color='secondary'>
          {subtitle}
        </Typography>
      )}
    </Stack>
  )
}
