import {
  BayOperability,
  BlockLabellingPattern,
  YardBlockBayDto,
  YardBlockDto,
} from '@storage/app/api'
import Block from '@storage/components/icons/svg/Block.svg'
import DoubleArrowDown from '@storage/components/icons/svg/DoubleArrowDown.svg'
import DoubleArrowUp from '@storage/components/icons/svg/DoubleArrowUp.svg'
import useContextMenu from '@storage/hooks/use-context-menu.hook'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useState } from 'react'
import { Group, Image, Rect, Text } from 'react-konva'
import { useImage } from 'react-konva-utils'
import { stackHeightInPixels, stackWidthInPixels } from '../constants'

interface Props {
  blockDto: YardBlockDto
  bays: YardBlockBayDto[]
  bay: YardBlockBayDto
  rowCount: number
  nextName?: string
  inEditMode: boolean
  onRedraw: () => void
  onBayLabelClicked: (bay: YardBlockBayDto) => void
}

export const YardBlockGridBay = ({
  blockDto,
  bays,
  bay,
  rowCount,
  nextName,
  inEditMode,
  onRedraw,
  onBayLabelClicked,
}: Props) => {
  const {
    yardBlockBayStore,
    yardBlockStore,
    yardBlockSlotStore,
    yardBlockStackStore,
    snackbarStore,
  } = useStores()
  const { palette } = useMinimalsTheme()
  const { t } = useTranslate()
  const bayContextMenu = useContextMenu()

  const [arrowDown] = useImage(DoubleArrowDown)
  const [arrowUp] = useImage(DoubleArrowUp)
  const [block] = useImage(Block)

  const [busy, setBusy] = useState<boolean>(false)

  const handleOnAddNewBay = async () => {
    if (busy) return

    setBusy(true)
    await yardBlockBayStore.add({
      name: nextName!,
      yardBlockId: blockDto.id,
      nextToFirstBay: false,
    })

    await yardBlockStackStore.loadForBlock(blockDto.id)

    onRedraw()

    setBusy(false)

    snackbarStore.showMessage(t('addBaySuccess', 'A new bay is successfully added'), 'success')
  }

  const lastBay = () => [...bays].reverse()[0]

  const bayDeleteAllowed = () => {
    const yardBlock = yardBlockStore.get(blockDto.id)
    const bay = lastBay()
    if (!yardBlock || !bay) return false

    const stacks = yardBlockStackStore.getForBay(bay.id)
    const used = !!stacks.find(s => s.numberOfOccupiedSlots > 0)
    return yardBlock.bayCount > 1 && !used
  }

  const handleOnDeleteBay = async () => {
    if (!lastBay()) return

    const bayId = lastBay().id
    await yardBlockBayStore.delete(bayId)

    const slotIds = yardBlockBayStore.getSlots(bayId).map(s => s.id)
    yardBlockSlotStore.delete(slotIds)

    const stackIds = yardBlockStackStore.getForBay(bayId).map(s => s.id)
    yardBlockStackStore.delete(stackIds)

    onRedraw()

    snackbarStore.showMessage(t('deleteBaySuccess', 'A bay is successfully deleted'), 'success')
  }

  const onToggleOperability = async (bay: YardBlockBayDto, lowSide: boolean) => {
    let operable: BayOperability
    if (bay.operable === BayOperability.NotRestricted) {
      operable = lowSide ? BayOperability.LowRowClosed : BayOperability.HighRowClosed
    } else if (bay.operable === BayOperability.HighRowClosed) {
      operable = lowSide ? BayOperability.LowRowClosed : BayOperability.NotRestricted
    } else {
      operable = lowSide ? BayOperability.NotRestricted : BayOperability.HighRowClosed
    }

    await yardBlockBayStore.update({
      yardBlockBayId: bay.id,
      yardBlockId: bay.yardBlockId,
      name: bay.name,
      operable: operable,
    })
  }

  return (
    <>
      <Group
        onContextMenu={({ evt }) => {
          evt.preventDefault()
          bayContextMenu.handleOpenMenu(evt.clientX, evt.clientY)
        }}
      >
        <Rect
          x={(bay.sequenceNumber - 1) * stackWidthInPixels + 2}
          y={-stackHeightInPixels * 1.5 + 2}
          width={stackWidthInPixels - 4}
          height={stackHeightInPixels - 4}
          cornerRadius={10}
          fill={palette.grey[300]}
        />
        <Text
          x={(bay.sequenceNumber - 1) * stackWidthInPixels}
          y={-stackHeightInPixels * 1.5}
          width={stackWidthInPixels}
          height={stackHeightInPixels}
          text={bay.name}
          align='center'
          verticalAlign='middle'
          ellipsis
          wrap='none'
          fill={palette.primary.dark}
          onClick={() => onBayLabelClicked(bay)}
          onMouseEnter={e => {
            const container = e.target.getStage()?.container()
            if (container) {
              container.style.cursor = 'pointer'
            }
          }}
          onMouseLeave={e => {
            const container = e.target.getStage()?.container()
            if (container) {
              container.style.cursor = 'default'
            }
          }}
        />
        <Image
          image={bay.operable !== BayOperability.LowRowClosed ? arrowDown : block}
          x={
            (bay.sequenceNumber - 1) * stackWidthInPixels +
            stackWidthInPixels / 2 -
            stackWidthInPixels / 8
          }
          y={-stackHeightInPixels + stackHeightInPixels / 2}
          width={stackWidthInPixels / 4}
          height={stackHeightInPixels / 2}
          onClick={() => onToggleOperability(bay, true)}
          shadowColor='green'
        />
        <Image
          image={bay.operable !== BayOperability.HighRowClosed ? arrowUp : block}
          x={
            (bay.sequenceNumber - 1) * stackWidthInPixels +
            stackWidthInPixels / 2 -
            stackWidthInPixels / 8
          }
          y={rowCount * stackHeightInPixels}
          width={stackWidthInPixels / 4}
          height={stackHeightInPixels / 2}
          onClick={() => onToggleOperability(bay, false)}
        />
      </Group>

      {inEditMode &&
        !blockDto.isFlexibleZone &&
        bay.id === lastBay().id &&
        blockDto.bayLabellingPattern !== BlockLabellingPattern.Custom && (
          <>
            <Group
              visible={!!nextName}
              onContextMenu={({ evt }) => {
                evt.preventDefault()
                bayContextMenu.handleOpenMenu(evt.clientX, evt.clientY)
              }}
            >
              <Rect
                x={bay.sequenceNumber * stackWidthInPixels + 2}
                y={-stackHeightInPixels + 2}
                width={stackWidthInPixels / 2 - 4}
                height={stackHeightInPixels - 4}
                cornerRadius={10}
                fill={palette.grey[300]}
              />
              <Text
                x={bay.sequenceNumber * stackWidthInPixels}
                y={-stackHeightInPixels}
                width={stackWidthInPixels / 2}
                height={stackHeightInPixels}
                text='+'
                align='center'
                verticalAlign='middle'
                ellipsis
                wrap='none'
                fill={palette.primary.dark}
                onClick={handleOnAddNewBay}
                onMouseEnter={e => {
                  const container = e.target.getStage()?.container()
                  if (container) {
                    container.style.cursor = 'pointer'
                  }
                }}
                onMouseLeave={e => {
                  const container = e.target.getStage()?.container()
                  if (container) {
                    container.style.cursor = 'default'
                  }
                }}
              />
            </Group>

            <Group
              visible={bayDeleteAllowed()}
              onContextMenu={({ evt }) => {
                evt.preventDefault()
                bayContextMenu.handleOpenMenu(evt.clientX, evt.clientY)
              }}
            >
              <Rect
                x={bay.sequenceNumber * stackWidthInPixels + stackWidthInPixels / 2}
                y={-stackHeightInPixels + 2}
                width={stackWidthInPixels / 2 - 4}
                height={stackHeightInPixels - 4}
                cornerRadius={10}
                fill={palette.grey[300]}
              />
              <Text
                x={bay.sequenceNumber * stackWidthInPixels + stackWidthInPixels / 2}
                y={-stackHeightInPixels}
                width={stackWidthInPixels / 2}
                height={stackHeightInPixels}
                text='-'
                align='center'
                verticalAlign='middle'
                ellipsis
                wrap='none'
                fill={palette.primary.dark}
                onClick={handleOnDeleteBay}
                onMouseEnter={e => {
                  const container = e.target.getStage()?.container()
                  if (container) {
                    container.style.cursor = 'pointer'
                  }
                }}
                onMouseLeave={e => {
                  const container = e.target.getStage()?.container()
                  if (container) {
                    container.style.cursor = 'default'
                  }
                }}
              />
            </Group>
          </>
        )}
    </>
  )
}
