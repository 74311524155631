import { Button, Chip, Stack } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { FilterChip } from './filter.model'

interface Props {
  filters: FilterChip[]
  onRemoveFilter: (filter: FilterChip) => void
  onClearAll?: () => void
}

export const FilterChips = ({ filters, onRemoveFilter, onClearAll }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <>
      {filters.length > 0 && (
        <Stack
          flexDirection='row'
          flexWrap='wrap'
          gap={theme.customSpacing.m}
          padding={theme.customSpacing.m}
          alignItems='center'
        >
          {filters.map(filter => (
            <Chip
              key={`${filter.key}_${filter.value}`}
              color='primary'
              label={filter.label ? t(filter.label) : filter.value}
              onDelete={() => onRemoveFilter(filter)}
            />
          ))}
          {onClearAll && (
            <Button color='secondary' onClick={onClearAll}>
              {t('clearAll', 'Clear all')}
            </Button>
          )}
        </Stack>
      )}
    </>
  )
}
