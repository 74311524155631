import { Button } from '@mui/material'
import { Box } from '@mui/system'
import { Permission } from '@storage/app/models/permission.enum'
import RailTracksTable from '@storage/features/rail-tracks/components/RailTracksTable'
import RailTracksTableDialog from '@storage/features/rail-tracks/components/RailTracksTableDialog'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { Header, PlusIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

const RailTracksPage = observer(() => {
  const { t } = useTranslate()

  const { railTracksUIStore, authStore } = useStores()
  const dialogUtilStore = railTracksUIStore.dialogUtilStore

  return (
    <>
      <Header
        title={t('railTracks', 'Rail Tracks')}
        searchInputLabel={t('searchForRailTrack', 'Search for rail track')}
        displaySearchIcon
        enableSearchField
        onSearchFieldChange={value => railTracksUIStore.setSearchQuery(value)}
        rightRenderOption={
          authStore.hasPermission(Permission.WriteRailTracks) && (
            <Box flex='none'>
              <Button
                color='inherit'
                variant='contained'
                startIcon={<PlusIcon />}
                onClick={() => dialogUtilStore.toggleDialog('Add')}
              >
                {t('addNew', 'Add new')}
              </Button>
            </Box>
          )
        }
      />
      <RailTracksTable />
      <RailTracksTableDialog />
    </>
  )
})

export default RailTracksPage
