import { useStores } from '@storage/hooks/use-stores.hook'
import { useState } from 'react'

interface UseNavigationWithUnsavedChangesProps {
  onConfirmNavigation: () => void
}

export const useNavigationWithUnsavedChanges = ({
  onConfirmNavigation,
}: UseNavigationWithUnsavedChangesProps) => {
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false)
  const { carrierVisitAllocationRulesV2Store } = useStores()

  const handleNavigationAttempt = () => {
    if (carrierVisitAllocationRulesV2Store.hasChanges) {
      setShowUnsavedChangesDialog(true)
      return
    }
    onConfirmNavigation()
  }

  const handleConfirmNavigation = () => {
    setShowUnsavedChangesDialog(false)
    onConfirmNavigation()
  }

  const handleCancelNavigation = () => {
    setShowUnsavedChangesDialog(false)
  }

  return {
    showUnsavedChangesDialog:
      showUnsavedChangesDialog && carrierVisitAllocationRulesV2Store.hasChanges,
    handleNavigationAttempt,
    handleConfirmNavigation,
    handleCancelNavigation,
  }
}
