import { CarrierType, RailVisitResponseDto, VesselVisitDto } from '@planning/app/api'
import { Dayjs } from 'dayjs'
import { action, makeObservable, observable } from 'mobx'
import { IIssueResolutionFormStore } from './IssueResolutionViewStore'
import { IssueTransportData } from './IssuesViewStore'

export class ChangeTransportFormStore implements IIssueResolutionFormStore<IssueTransportData> {
  showErrors = false
  transportData: IssueTransportData = {}
  carrierType = ''
  showDatetimeForPickUp = false
  releaseExpires: Dayjs | null = null
  selectedRailVisit?: RailVisitResponseDto
  selectedVesselVisit?: VesselVisitDto

  constructor() {
    makeObservable(this, {
      showErrors: observable,
      transportData: observable,
      carrierType: observable,
      showDatetimeForPickUp: observable,
      releaseExpires: observable,
      selectedRailVisit: observable,
      selectedVesselVisit: observable,
      validate: action,
      updateTransportData: action,
      setCarrierType: action,
      setShowDatetimeForPickUp: action,
      setReleaseExpires: action,
      setSelectedRailVisit: action,
      setSelectedVesselVisit: action,
    })
  }

  validate = () => {
    if (!this.transportData?.carrierType) return false
    if (
      this.transportData?.carrierType === CarrierType.Vessel &&
      !this.transportData.carrierVisitId
    ) {
      return false
    }

    if (
      this.transportData?.carrierType === CarrierType.Train &&
      (!this.transportData.carrierVisitId ||
        !this.transportData.railTrackId ||
        !this.transportData.railcarId)
    ) {
      return false
    }

    return true
  }

  updateTransportData = <K extends keyof IssueTransportData>(
    field: K,
    value: IssueTransportData[K],
  ) => {
    if (this.transportData) {
      this.transportData[field] = value
    }
  }

  setCarrierType = (value: string) => {
    this.carrierType = value
  }

  setShowDatetimeForPickUp = (value: boolean) => {
    this.showDatetimeForPickUp = value
  }

  setReleaseExpires = (value: Dayjs | null) => {
    this.releaseExpires = value
    this.transportData.releaseExpired = value?.toString()
  }

  setSelectedRailVisit = (value: RailVisitResponseDto | undefined) => {
    this.selectedRailVisit = value
  }

  setSelectedVesselVisit = (value: VesselVisitDto | undefined) => {
    this.selectedVesselVisit = value
  }

  get data() {
    return this.transportData
  }
}
