import { Box, Button, Dialog, Popover, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import {
  AllocationDestinationAndStackingStrategyDto,
  AllocationRuleTemplateDto,
} from '@storage/app/api'
import CustomTabsContainer from '@storage/components/CustomTabsContainer'
import { LabelBox } from '@storage/components/LabelBox'
import useFormWithSchema from '@storage/hooks/use-form-with-schema.hook'
import { ManualInputValidationStore } from '@storage/pages/container-turnovers/stores/manual-input-validation.store'
import { AllocationRulePanelFormDestination } from '@storage/pages/yard-planning-dashboard-details/components/allocation-rule-panel/AllocationRulePanelFormDestination'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useWatch } from 'react-hook-form'
import { mapYardPositionDtoToYardPositionDescriptor } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'
import {
  AllocationRuleTemplateFormProfile,
  defaultValues,
} from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.profile'
import { schema } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.scheme'
import AllocationRuleTemplateFacetChips from './AllocationRuleTemplateFacetChips'
import { CarrierVisitAllocationRulesV2UIStore } from '../stores/carrier-visit-allocation-rules-v2.ui-store'
import { GlobalAllocationRulesUIStore } from '@storage/pages/global-allocation-rules/stores/global-allocation-rules.ui-store'

interface AllocationRulesTemplatesListItemProps {
  destinationUIStore: CarrierVisitAllocationRulesV2UIStore | GlobalAllocationRulesUIStore
  allocationRuleTemplate: AllocationRuleTemplateDto
  manualInputValidationStore: ManualInputValidationStore
  onActionClick: (
    ruleId: string,
    destinations: AllocationDestinationAndStackingStrategyDto[],
  ) => void
}

export const AllocationRulesTemplatesListItem = observer(
  ({
    destinationUIStore,
    allocationRuleTemplate,
    onActionClick,
  }: AllocationRulesTemplatesListItemProps) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()
    const { palette } = useMinimalsTheme()
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [showDestinationForm, setShowDestinationForm] = useState(false)

    const [destinations] = useState(
      allocationRuleTemplate.destinationAndStackingStrategies
        ? mapYardPositionDtoToYardPositionDescriptor(
            allocationRuleTemplate.destinationAndStackingStrategies,
          )
        : [],
    )

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
      setAnchorEl(null)
    }

    const allocationRuleTemplateForm = useFormWithSchema<AllocationRuleTemplateFormProfile>(
      schema(false),
      defaultValues,
    )

    const destinationAndStackingStrategiesFormValue = useWatch({
      control: allocationRuleTemplateForm.control,
      name: `destinationAndStackingStrategies`,
    })

    const handleCopyToVisit = () => {
      if (destinations.length === 0) {
        allocationRuleTemplateForm.reset(defaultValues)
        setShowDestinationForm(true)
      } else {
        onActionClick(
          allocationRuleTemplate.id,
          allocationRuleTemplate.destinationAndStackingStrategies,
        )
      }
    }

    return (
      <>
        <Stack gap={1}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            p={2}
            gap={2}
            sx={{
              borderRadius: theme.customRadius.large,
              boxShadow: theme.shadows[3],
            }}
          >
            <Stack gap={1} flexGrow={1}>
              <Typography variant='subtitle1'>{allocationRuleTemplate.name}</Typography>

              <CustomTabsContainer maxWidth='350px'>
                <AllocationRuleTemplateFacetChips facets={allocationRuleTemplate.facets} />
              </CustomTabsContainer>
            </Stack>

            {destinations.length === 0 ? (
              <Typography variant='subtitle1'>
                {t('NoDestinations', 'No destination set')}
              </Typography>
            ) : destinations.length === 1 ? (
              <LabelBox
                label={destinations[0]}
                color={palette.info.main}
                bgColor={`${palette.info.main}14`}
              />
            ) : (
              <Box
                component='div'
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <LabelBox
                  label={`${destinations.length} ${t('destinations', 'destinations')}`}
                  color={palette.info.main}
                  bgColor={`${palette.info.main}14`}
                />
              </Box>
            )}

            <Popover
              id='mouse-over-popover'
              sx={{ pointerEvents: 'none' }}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              {destinations.map((destination, index) => (
                <Typography key={index} sx={{ p: 1 }}>
                  {destination}
                </Typography>
              ))}
            </Popover>

            <Button onClick={handleCopyToVisit} variant='contained' color='primary'>
              {t('copyToVisit', 'Copy to visit')}
            </Button>
          </Stack>
        </Stack>
        <Dialog
          open={showDestinationForm}
          title={''}
          onClose={() => setShowDestinationForm(false)}
          fullWidth
        >
          <AllocationRulePanelFormDestination
            uiStore={destinationUIStore}
            fieldIndex={0}
            allocationRuleForm={allocationRuleTemplateForm}
            goBack={() => {
              setShowDestinationForm(false)
              onActionClick(allocationRuleTemplate.id, destinationAndStackingStrategiesFormValue)
            }}
            destinationAndStackingStrategiesFormValue={destinationAndStackingStrategiesFormValue}
          />
        </Dialog>
      </>
    )
  },
)
