import {
  CarrierVisitDirection,
  HoldResponseDto,
  HoldStatus,
  HoldType,
  OrderResponseDto,
} from '@planning/app/api'
import { OrderItemStore } from '@planning/rt-stores/order/OrderItemStore'
import { action, computed, makeObservable, observable } from 'mobx'

export interface HoldWithDirection extends HoldResponseDto {
  direction: CarrierVisitDirection
}

const supportHoldTypes = new Set<HoldType>([HoldType.PendingCustoms, HoldType.RefusedCustoms])

export class HoldViewStore {
  selectedOrder?: OrderResponseDto
  selectedLinkedOrder?: OrderResponseDto
  selectedDirection: CarrierVisitDirection = CarrierVisitDirection.Inbound
  searchFilter?: string
  selectedHoldIds: number[] = []
  isCreateMode = false

  constructor(private orderStore: OrderItemStore) {
    makeObservable(this, {
      selectedOrder: observable,
      selectedLinkedOrder: observable,
      selectedDirection: observable,
      selectedHoldIds: observable,
      searchFilter: observable,
      isCreateMode: observable,

      holds: computed,
      filteredHolds: computed,
      activeHolds: computed,
      activeIssueHolds: computed,
      resolvedHolds: computed,
      resolvedIssueHolds: computed,
      isSelectAll: computed,
      ordersById: computed,
      isBothDirections: computed,

      selectOrder: action,
      selectDirection: action,
      toggleSelectAll: action,
      toggleSelectHoldId: action,
      setSearchFilter: action,
      setIsCreateMode: action,
    })
  }

  get isBothDirections() {
    return !!this.selectedOrder && !!this.selectedLinkedOrder
  }

  get orderForSelectedDirection() {
    if (!this.isBothDirections || this.selectedOrder?.direction === this.selectedDirection)
      return this.selectedOrder

    if (this.selectedLinkedOrder?.direction === this.selectedDirection)
      return this.selectedLinkedOrder

    return undefined
  }

  get ordersById() {
    return this.orderStore.elements
  }

  get holds() {
    return (
      this.selectedOrder?.holds
        .map(
          h =>
            ({
              ...h,
              direction: this.selectedOrder?.direction,
            }) as HoldWithDirection,
        )
        .concat(
          this.selectedLinkedOrder?.holds.map(
            h =>
              ({
                ...h,
                direction: this.selectedLinkedOrder?.direction,
              }) as HoldWithDirection,
          ) ?? [],
        ) ?? []
    )
  }

  get filteredHolds() {
    return this.holds.filter(h => this.match(h, this.searchFilter))
  }

  get activeHolds() {
    return this.filteredHolds.filter(
      h => h.status === HoldStatus.Active && !supportHoldTypes.has(h.type),
    )
  }

  get activeIssueHolds() {
    return this.filteredHolds.filter(
      h => h.status === HoldStatus.Active && supportHoldTypes.has(h.type),
    )
  }

  get resolvedHolds() {
    return this.filteredHolds.filter(
      h => h.status === HoldStatus.Resolved && !supportHoldTypes.has(h.type),
    )
  }

  get resolvedIssueHolds() {
    return this.filteredHolds.filter(
      h => h.status === HoldStatus.Resolved && supportHoldTypes.has(h.type),
    )
  }

  get isSelectAll() {
    return this.selectedHoldIds.length === this.activeHolds.length
  }

  toggleSelectHoldId = (id: number) => {
    if (this.selectedHoldIds.includes(id))
      this.selectedHoldIds = this.selectedHoldIds.filter(existingId => existingId !== id)
    else this.selectedHoldIds = [...this.selectedHoldIds, id]
  }

  toggleSelectAll = () => {
    if (this.isSelectAll) this.selectedHoldIds = []
    else this.selectedHoldIds = this.activeHolds.map(hold => hold.id)
  }

  selectOrder = (order: OrderResponseDto, linkedOrder?: OrderResponseDto) => {
    if (order.direction === linkedOrder?.direction)
      console.error('The linked order must have the opposite direction of the selectedOrder')

    this.selectedOrder = order
    this.selectedLinkedOrder = linkedOrder
  }

  selectDirection = (direction: CarrierVisitDirection) => {
    this.selectedDirection = direction
  }

  setSearchFilter = (filter?: string) => {
    this.searchFilter = filter
  }

  setIsCreateMode = (isCreateMode: boolean) => {
    this.isCreateMode = isCreateMode
  }

  match(hold: HoldResponseDto, search?: string) {
    if (!search) return true

    const filter = search.toLocaleLowerCase()

    if (hold.reason.toLocaleLowerCase().includes(filter)) return true
    if (hold.contact.toLocaleLowerCase().includes(filter)) return true

    return false
  }
}
