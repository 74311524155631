import { CreateTenantCommand, TenantDto, TerminalType, UpdateTenantCommand } from '@admin/app/api'
import { TenantFormProfile } from './tenant-form.profile'

const mapFormValuesToCreateDto = ({
  name,
  timezone,
  culture,
  address,
  hasAutoGeneratedCraneSplit,
  skipCraneSplit,
  skipVesselTally,
  skipRailTally,
  skipRailTallyLoadPreparation,
  skipGateOut,
  skipVesselVisitReadyForOperation,
  skipReleaseOrder,
  skipYardPlanning,
  skipBerthTimestamp,
  isInlandJobCreationFlow,
  bayRowUniqueIdentificationMode,
  showUnifiedOperatorPage,
  hasGeneralCargo,
  hasDamageCatalogue,
  unloCodes,
}: TenantFormProfile): CreateTenantCommand => ({
  name,
  timezoneId: timezone,
  cultureId: culture,
  address: address,
  hasAutoGeneratedCraneSplit: hasAutoGeneratedCraneSplit ?? false,
  skipCraneSplit: skipCraneSplit ?? false,
  skipVesselTally: skipVesselTally ?? false,
  skipRailTally: skipRailTally ?? false,
  skipRailTallyLoadPreparation: skipRailTallyLoadPreparation ?? false,
  skipGateOut: skipGateOut ?? false,
  skipVesselVisitReadyForOperation: skipVesselVisitReadyForOperation ?? false,
  skipReleaseOrder: skipReleaseOrder ?? false,
  skipYardPlanning: skipYardPlanning ?? false,
  skipBerthTimestamp: skipBerthTimestamp ?? false,
  isInlandJobCreationFlow: isInlandJobCreationFlow ?? false,
  bayRowUniqueIdentificationMode: bayRowUniqueIdentificationMode ?? false,
  showUnifiedOperatorPage: showUnifiedOperatorPage ?? false,
  hasGeneralCargo: hasGeneralCargo ?? false,
  hasDamageCatalogue: hasDamageCatalogue ?? false,
  unloCodes: unloCodes ?? [],
})

const mapFormValuesToUpdateDto = ({
  id,
  name,
  timezone,
  culture,
  address,
  hasAutoGeneratedCraneSplit,
  skipCraneSplit,
  skipVesselTally,
  skipRailTally,
  skipRailTallyLoadPreparation,
  skipGateOut,
  skipVesselVisitReadyForOperation,
  skipReleaseOrder,
  skipYardPlanning,
  skipBerthTimestamp,
  isInlandJobCreationFlow,
  bayRowUniqueIdentificationMode,
  showUnifiedOperatorPage,
  hasGeneralCargo,
  hasDamageCatalogue,
  operatorViewConfigs,
  unloCodes,
  reeferContainerMaxUnpluggedTime,
}: TenantFormProfile): UpdateTenantCommand => ({
  id: id!,
  name,
  timezoneId: timezone,
  cultureId: culture,
  address: address,
  hasAutoGeneratedCraneSplit: hasAutoGeneratedCraneSplit ?? false,
  skipCraneSplit: skipCraneSplit ?? false,
  skipVesselTally: skipVesselTally ?? false,
  skipRailTally: skipRailTally ?? false,
  skipRailTallyLoadPreparation: skipRailTallyLoadPreparation ?? false,
  skipGateOut: skipGateOut ?? false,
  skipVesselVisitReadyForOperation: skipVesselVisitReadyForOperation ?? false,
  skipReleaseOrder: skipReleaseOrder ?? false,
  skipYardPlanning: skipYardPlanning ?? false,
  skipBerthTimestamp: skipBerthTimestamp ?? false,
  isInlandJobCreationFlow: isInlandJobCreationFlow ?? false,
  bayRowUniqueIdentificationMode: bayRowUniqueIdentificationMode ?? false,
  showUnifiedOperatorPage: showUnifiedOperatorPage ?? false,
  hasGeneralCargo: hasGeneralCargo ?? false,
  hasDamageCatalogue: hasDamageCatalogue ?? false,
  operatorViewConfigs: operatorViewConfigs ?? TerminalType.Hig,
  unloCodes: unloCodes || [],
  reeferContainerMaxUnpluggedTime:
    reeferContainerMaxUnpluggedTime || reeferContainerMaxUnpluggedTime === 0
      ? reeferContainerMaxUnpluggedTime
      : undefined,
})

const mapDtoToFormValues = ({
  id,
  name,
  timezoneId,
  cultureId,
  address,
  unloCodes,
  configs,
  reeferContainerMaxUnpluggedTime,
}: TenantDto): TenantFormProfile => ({
  id,
  name,
  timezone: timezoneId ?? undefined,
  culture: cultureId ?? undefined,
  address,
  unloCodes,
  hasAutoGeneratedCraneSplit: configs?.hasAutoGeneratedCraneSplit,
  skipCraneSplit: configs?.skipCraneSplit,
  skipVesselTally: configs?.skipVesselTally,
  skipRailTally: configs?.skipRailTally,
  skipRailTallyLoadPreparation: configs?.skipRailTallyLoadPreparation,
  skipGateOut: configs?.skipGateOut,
  skipVesselVisitReadyForOperation: configs?.skipVesselVisitReadyForOperation,
  skipReleaseOrder: configs?.skipReleaseOrder,
  skipYardPlanning: configs?.skipYardPlanning,
  skipBerthTimestamp: configs?.skipBerthTimestamp,
  isInlandJobCreationFlow: configs?.isInlandJobCreationFlow,
  bayRowUniqueIdentificationMode: configs?.bayRowUniqueIdentificationMode,
  showUnifiedOperatorPage: configs?.showUnifiedOperatorPage,
  hasGeneralCargo: configs?.hasGeneralCargo,
  hasDamageCatalogue: configs?.hasDamageCatalogue,
  operatorViewConfigs: configs?.selectedOperatorViewConfigs,
  reeferContainerMaxUnpluggedTime: reeferContainerMaxUnpluggedTime,
})

export const TenantFormMapper = {
  mapFormValuesToCreateDto,
  mapFormValuesToUpdateDto,
  mapDtoToFormValues,
}
