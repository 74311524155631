import { Delete } from '@mui/icons-material'
import { Box, Button, FormControlLabel, Switch, Tooltip } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { getContainerTypeParameters } from '@planning/components/ContainerTypesMap'
import { GridColumn, StickyHeaderTable } from '@planning/components/PaginatedTable'
import { emptyIndicator } from '@planning/constants'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { INnrOrderItem } from '@planning/rt-stores/nnrOrder/NnrOrderItem'
import { nnrOrderService } from '@planning/services'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { EditIcon, Header, IconButton } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { CreateNNROrderForm } from './CreateNNROrderForm'
import { EditNNROrderForm } from './EditNNROrderForm'

interface Props {
  viewStore: IPaginatedStoreWithItems<INnrOrderItem>
}

export const NNROrdersTable = observer(({ viewStore }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslate()
  const { drawerStore, appViewStore } = usePlanningStore()

  const handleDelete = async (idToBeDeleted: number) => {
    const isConfirmed = await appViewStore.setOpenConfirmDialog(
      true,
      t(
        'areYouSureYouWantToDeleteThisItem',
        'Do you really want to delete this non-numeric release? The action is irreversible.',
      ),
      t('confirm', 'Confirm'),
    )

    if (isConfirmed) {
      try {
        await nnrOrderService.delete(idToBeDeleted)
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToDeleted', 'Failed to delete'))
      }
    }
  }

  const columns: GridColumn[] = [
    {
      field: 'shippingLine',
      headerName: t('shippingLine', ' Shipping Line'),
      flex: 1,
      minWidth: 100,
      valueGetter: params => params.row.data.shippingLine,
      sortable: true,
      orderBy: 'shippingLine',
    },
    {
      field: 'referenceNumber',
      headerName: t('referenceNumber', 'Reference no'),
      flex: 1,
      minWidth: 100,
      valueGetter: params => params.row.data.referenceNumber,
      sortable: true,
      orderBy: 'referenceNumber',
    },
    {
      field: 'containerLength',
      headerName: t('length', 'Length'),
      flex: 1,
      minWidth: 100,
      valueGetter: params =>
        params.row.data.containerLength ? `${params.row.data.containerLength}ft` : emptyIndicator,
      sortable: true,
      orderBy: 'containerLength',
    },
    {
      field: 'containerHeight',
      headerName: t('containerHeight', 'Height'),
      flex: 1,
      minWidth: 100,
      valueGetter: params => params.row.data.containerHeight,
      sortable: true,
      orderBy: 'containerHeight',
    },
    {
      field: 'containerType',
      headerName: 'Type',
      flex: 1,
      minWidth: 100,
      valueGetter: params => getContainerTypeParameters(params.row.data.containerType, t)['label'],
      sortable: true,
      orderBy: 'containerType',
    },
    {
      field: 'containerIsoCode',
      headerName: t('isoCode', 'ISO Code'),
      flex: 1,
      minWidth: 100,
      valueGetter: params => params.row.data.containerIsoCode,
      sortable: true,
      orderBy: 'containerIsoCode',
    },
    {
      field: 'containerMaxPayload',
      headerName: t('maxPayload', 'Max Payload(kg)'),
      flex: 1,
      minWidth: 100,
      valueGetter: params => params.row.data.containerMaxPayload,
      sortable: true,
      orderBy: 'containerMaxPayload',
    },
    {
      field: 'fulfilled',
      headerName: t('fulfilled', 'Fulfilled'),
      flex: 1,
      minWidth: 100,
      valueGetter: params => `${params.row.data.fulfilled} / ${params.row.data.amount}`,
      orderBy: 'fulfilled',
      sortable: false,
    },
    {
      field: 'actions',
      headerName: t('action', 'Action'),
      flex: 0.5,
      minWidth: 100,
      sortable: false,
      renderCell: params => {
        return (
          <Box sx={{ display: 'flex' }}>
            <Tooltip arrow placement='bottom' title={t('edit', 'Edit')}>
              <IconButton
                onClick={() =>
                  drawerStore.showView(<EditNNROrderForm order={params.row.data} />, {
                    title: t('edit', 'Edit'),
                    subTitle: t('non-numericReleases', 'Non-numeric releases'),
                    primaryActionLabel: t('submit', 'Submit'),
                    closeActionLabel: t('close', 'Close'),
                    formId: 'edit-non-numeric-release',
                  })
                }
              >
                <EditIcon />
              </IconButton>
            </Tooltip>

            {!params.row.data.fulfilled && (
              <Tooltip arrow placement='bottom' title={t('delete', 'Delete')}>
                <IconButton onClick={() => handleDelete(params.row.data.id)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )
      },
    },
  ]

  return (
    <>
      <Header
        title={t('non-numericReleases', 'Non-numeric releases')}
        searchInputLabel={t('search', 'Search')}
        onSearchFieldChange={(filter: string) => viewStore.setFilter(filter)}
        displaySearchIcon
        enableSearchField
        rightRenderOption={
          <Button
            variant='contained'
            type='submit'
            onClick={() =>
              drawerStore.showView(<CreateNNROrderForm />, {
                title: t('create', 'Create'),
                subTitle: t('non-numericReleases', 'Non-numeric releases'),
                primaryActionLabel: t('submit', 'Submit'),
                closeActionLabel: t('close', 'Close'),
                formId: 'create-non-numeric-release',
              })
            }
          >
            {t('create', 'Create')}
          </Button>
        }
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          placeContent: 'flex-end',
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={viewStore.showCompleted}
              onChange={() => viewStore.toggleShowCompleted()}
            />
          }
          label={t('showFulfilled', 'Show fulfilled')}
        />
      </Box>

      <StickyHeaderTable
        store={viewStore}
        columns={columns}
        rowsPerPageOptions={[10, 25, 50]}
        onCellClick={(column: any, event: any) => {
          if (column.field === 'actions') {
            event.stopPropagation()
          }
          event.preventDefault()
        }}
        onRowClick={(params: any) => {
          navigate(`${PATH_PLANNING.nnrOrders}/${params.id}`)
        }}
      />
    </>
  )
})
