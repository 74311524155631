import { IsoCodeMappingResponseDto } from '@admin/app/api'
import { Card, CardActionArea, Grid, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props extends IsoCodeMappingResponseDto {
  isoCodeMappingType?: 'Standard' | 'Custom'
  onClickEdit?: () => void
}

export const IsoCodeMappingCard = observer((props: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { isoCode, length, height, type, isoCodeMappingType, onClickEdit } = props

  return (
    <CardActionArea
      sx={{
        '.MuiCardActionArea-focusHighlight': {
          background: 'transparent',
        },
        mb: theme.customSpacing.m,
      }}
      onClick={onClickEdit}
    >
      <Card
        sx={{
          padding: theme.customSpacing.m,
        }}
      >
        <Grid container sx={{ placeContent: 'space-between' }}>
          <Typography variant='h2'>{isoCode.toUpperCase()}</Typography>
          {(!isoCodeMappingType || isoCodeMappingType === 'Custom') && (
            <ColoredLabel label={t('custom', 'Custom')} color={'info'} />
          )}
          {isoCodeMappingType === 'Standard' && (
            <ColoredLabel label={t('standard', 'Standard')} color={'warning'} />
          )}
        </Grid>
        <Grid container spacing={theme.customSpacing.xll}>
          <Grid item>
            <Stack mt={theme.customSpacing.s}>
              <Typography variant='body1'>{t('length', 'Length')}</Typography>
              <Typography variant='h4'>{length ?? t('unknown', 'Unknown')}</Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack mt={theme.customSpacing.s}>
              <Typography variant='body1'>{t('height', 'Height')}</Typography>
              <Typography variant='h4'>{height ?? t('unknown', 'Unknown')}</Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack mt={theme.customSpacing.s}>
              <Typography variant='body1'>{t('type', 'Type')}</Typography>
              <Typography variant='h4'>{type ?? t('unknown', 'Unknown')}</Typography>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </CardActionArea>
  )
})
