import { YardBlockSlotOccupanciesApi, YardPositionDto } from '@storage/app/api'

export class YardBlockSlotOccupancyService {
  constructor(private api: YardBlockSlotOccupanciesApi) {}

  async validateDGStackingAndSegregationRulesViolation(
    containerTurnoverId: string,
    location: YardPositionDto,
  ): Promise<void> {
    await this.api.validateDGStackingAndSegregationRulesViolation({
      yardBlockSlotOccupanciesUpdateContainerPositionRequest: {
        containerTurnoverId,
        location,
      },
    })
  }

  async updateContainerPosition(
    containerTurnoverId: string,
    location: YardPositionDto,
  ): Promise<void> {
    await this.api.updateContainerPosition({
      yardBlockSlotOccupanciesUpdateContainerPositionRequest: {
        containerTurnoverId,
        location,
      },
    })
  }
}
