import { Autocomplete, InputAdornment, TextField, debounce } from '@mui/material'
import { CargoType, RailVisitResponseDto } from '@planning/app/api'
import railVisitService from '@planning/services/railVisitService'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { TrainIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'

interface Props {
  selectedRailVisit?: RailVisitResponseDto
  handleOnChange?: (value: RailVisitResponseDto | undefined) => void
  error?: boolean
  helperText?: React.ReactNode
  label?: string
  disabled?: boolean
  cargoType?: CargoType
}

export const RailVisitAutoComplete: FC<Props> = observer(
  ({ selectedRailVisit, handleOnChange, error, helperText, label, disabled, cargoType }) => {
    const { t } = useTranslate()

    const [open, setOpen] = useState<boolean>(false)
    const [options, setOptions] = useState<RailVisitResponseDto[]>([])

    const onSearchFilterChange = debounce(async (filter?: string) => {
      setOptions((await railVisitService.get(1, 10, filter, cargoType)).payload)
    }, 500)

    const getArrivalDate = (visit: RailVisitResponseDto) => {
      if (visit.ata) return `${t('ata', 'ATA')}: ${formatDateTime(visit.ata)}`
      else if (visit.eta) return `${t('eta', 'ETA')}: ${formatDateTime(visit.eta)}`

      return ''
    }

    useEffect(() => {
      if (open) {
        const fetch = async () => {
          return (await railVisitService.get(1, 10, undefined, cargoType)).payload
        }

        fetch().then(payload => setOptions(payload))
      } else {
        setOptions([])
      }
    }, [open])

    return (
      <Autocomplete
        disablePortal
        disabled={disabled}
        getOptionLabel={railVisit => `${railVisit.identifier} - ${getArrivalDate(railVisit)}`}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={options}
        isOptionEqualToValue={(option: RailVisitResponseDto, value: RailVisitResponseDto) =>
          option.id === value?.id
        }
        value={selectedRailVisit ?? null}
        onChange={(_, railVisit) => {
          if (handleOnChange) handleOnChange(railVisit ?? undefined)
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={label ?? t('train', 'Train')}
            error={error}
            helperText={helperText}
            onChange={e => {
              onSearchFilterChange(e.target.value)
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position='start'>
                  <TrainIcon />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    )
  },
)
