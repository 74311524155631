import { Cached } from '@mui/icons-material'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

interface IProps {
  updateCount?: number
  useIcon?: boolean
}

export const UpdatesLabelChip: React.FC<IProps> = observer(({ updateCount, useIcon }) => {
  const { t } = useTranslate()

  const label = useIcon ? `${updateCount ?? 0}` : `${updateCount ?? 0} ${t('updates', 'Updates')}`

  return (
    <ColoredLabel
      color='secondary'
      label={label}
      endIcon={useIcon ? <Cached /> : <></>}
      sx={{
        width: 'fit-content',
      }}
    />
  )
})
