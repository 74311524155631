import { Box, Button, Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { usePlanningStore } from '@planning/AppProvider'
import {
  CarrierVisitDirection,
  CreateTruckAppointmentCommand,
  OrderResponseDto,
  OrderStatus,
} from '@planning/app/api'
import { ConfirmationDrawerMobile } from '@planning/components/ConfirmationDrawerMobile'
import { LoaderButton } from '@planning/components/LoaderButton'
import { TruckLicensePlateAutocomplete } from '@planning/components/TruckLicensePlateAutocomplete'
import { GateClerkTruckVisitEditStore } from '@planning/rt-stores/gateClerk/GateClerkTruckVisitEditStore'
import truckAppointmentService from '@planning/services/truckAppointmentService'
import truckVisitService from '@planning/services/truckVisitService'
import { useNavigationStore } from '@planning/stores/NavigationStack'
import { NonNumericOrderWithPickUpAmount } from '@planning/stores/truckAppointment/TruckAppointmentDetailsViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { FullWidthFooter } from '../../components/FullWidthFooter'
import { MobileAppBar } from '../Shell'
import { TruckAppointmentMobileViewEditOrderPage } from './TruckAppointmentMobileViewEditOrderPage'
import { TruckAppointmentOrderSelectionMobile } from './TruckAppointmentOrderSelectionPageMobile'
import { TruckAppointmentOrdersTabs } from './components/TruckAppointmentOrdersTabs'

interface Props {
  editStore: GateClerkTruckVisitEditStore
}

export const TruckAppointmentsDetailsMobile: FC<Props> = observer(({ editStore }: Props) => {
  if (!editStore.truckVisit) return <></>

  const { t } = useTranslate()
  const navigationStore = useNavigationStore()
  const { appViewStore, drawerStore } = usePlanningStore()

  const [isError, setIsError] = useState<boolean>(false)
  const [warningText, setWarningText] = useState<string | undefined>(undefined)
  const [openTabDirection, setOpenTabDirection] = useState<CarrierVisitDirection>(
    editStore.lastDirection,
  )

  const title = () => (editStore.isExit ? t('checkOut', 'Check Out') : t('checkIn', 'Check In'))

  const passageButtonText = () =>
    editStore.isExit ? t('allowExit', 'Allow Exit') : t('allowEntry', 'Allow Entry')

  const validateAndAlert = () => {
    if (!editStore.truckVisit) return

    if (editStore.isEntryWithoutOrders) {
      appViewStore.setShowAlert('error', t('addOrdersToAllowEntry', 'Add Orders to allow entry.'))
      return false
    }

    if (editStore.ordersWithoutContainerNumber.length) {
      appViewStore.setShowAlert(
        'error',
        t(
          'atLeastOneOutboundOrderDoesNotHaveAContainerAssignedConfirm',
          'At least one outbound order does not have a container assigned. Cannot allow exit.',
        ),
      )
      return false
    }

    if (editStore.ordersHaveActiveHolds) {
      appViewStore.setShowAlert(
        'error',
        editStore.passageDirection === CarrierVisitDirection.Inbound
          ? t(
              'cannotPermitEntryForOrderWithActiveHolds',
              'Cannot permit entry for order with active holds',
            )
          : t(
              'cannotPermitExitForOrderWithActiveHolds',
              'Cannot permit exit for order with active holds',
            ),
      )
      return false
    }

    if (editStore.outboundOrdersNotOnTerminal.length) {
      const ordersText =
        editStore.outboundOrdersNotOnTerminal.length > 1
          ? t('ordersAre', 'orders are')
          : t('orderIs', 'order is')
      const exitOrEntry = editStore.isExit ? t('exit', 'exit') : t('entry', 'entry')

      setWarningText(
        `${editStore.outboundOrdersNotOnTerminal.length} ${t(
          'outbound',
          'outbound',
        )} ${ordersText} ${t(
          'notRecordedAsOnTerminalConfirm',
          'not recorded as OnTerminal. Confirm',
        )} ${exitOrEntry} ${t('anyways', 'anyways?')}`,
      )
      return false
    }

    if (!editStore.truckVisit.identifier) {
      setIsError(true)
      return false
    }

    return true
  }

  const onGoBack = () => {
    editStore.reset()
    navigationStore.pop()
  }

  const onAllowButtonClick = () => {
    const isValid = validateAndAlert()
    if (!isValid) return

    onAllowPassage()
  }

  const onAllowPassage = async () => {
    if (!editStore.truckVisit) return

    try {
      if (editStore.truckVisit.id === 0) {
        const cmd: CreateTruckAppointmentCommand = {
          licensePlate: editStore.truckVisit.identifier!,
          ordersWithDirection: [...editStore.inboundOrders, ...editStore.outboundOrders].map(o => ({
            id: o.id,
            direction: o.direction,
          })),
          ordersToBeCreated: [],
          nnrOrders: editStore.nnrOrders.map(nnr => {
            return { nnrOrderId: nnr.id, amountToPickUp: nnr.pickUpAmount }
          }),
        }
        const { truckVisitId } = await truckAppointmentService.post(cmd)
        editStore.setTruckVisit({ ...editStore.truckVisit, id: truckVisitId })
      }

      if (editStore.passageDirection === CarrierVisitDirection.Inbound) {
        await truckVisitService.allowEntry({
          id: editStore.truckVisit.id,
          inboundOrderIds: editStore.inboundOrders.map(o => o.id),
          outboundOrderIds: editStore.outboundOrders.map(o => o.id),
        })
      } else {
        await truckVisitService.allowExit({
          id: editStore.truckVisit.id,
          orderIds: editStore.outboundOrders.map(o => o.id),
        })
      }

      editStore.reset()
      appViewStore.setShowAlert('success', t('passageAllowed', 'Passage allowed'))
      drawerStore.close()
      navigationStore.pop()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToAllowPassage', 'Failed to allow passage'))
    }
  }

  const onAddOrder = () => {
    editStore.setIsSelectingOrder(true)
    navigationStore.push(<TruckAppointmentOrderSelectionMobile editStore={editStore} />)
  }

  const onEditOrderHandler = (order: OrderResponseDto) => {
    editStore.setSelectedOrder(order)
    navigationStore.push(<TruckAppointmentMobileViewEditOrderPage order={order} />)
  }

  const onRemoveOrder = (order: OrderResponseDto) => {
    if (!editStore.truckVisit) return

    editStore.removeOrder(order)

    appViewStore.setShowAlert('success', t('orderHasBeenRemoved', 'Order has been removed'))
  }

  const onTextFieldChange = (value: string) => {
    if (!editStore.truckVisit) return

    editStore.setTruckVisit({
      ...editStore.truckVisit,
      identifier: value,
    })
  }

  const mapToNnrOrder = (nnrOrder: NonNumericOrderWithPickUpAmount) => {
    const orders = [...Array(nnrOrder.pickUpAmount).keys()].map(_ => {
      return {
        ...nnrOrder,
        id: 0,
        containerIsoCode: nnrOrder.containerIsoCode,
        containerType: nnrOrder.containerType,
        containerHeight: nnrOrder.containerHeight,
        operator: nnrOrder.shippingLine,
        isEmpty: true,
        isOnTerminal: true,
        direction: CarrierVisitDirection.Outbound,
        imoClasses: [],
        holds: [],
        isRestowed: false,
        status: OrderStatus.Open,
        hasServiceOrders: false,
        customs: [],
      } as OrderResponseDto
    })

    return orders
  }

  const mergedOutboundAndNnrOrders = [
    ...editStore.outboundOrders,
    ...(editStore.nnrOrders?.flatMap(mapToNnrOrder) ?? []),
  ]

  return (
    <Box sx={{ height: '100%' }}>
      <MobileAppBar
        title={title()}
        backButton={navigationStore.canGoBack}
        onGoBack={navigationStore.pop}
      />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '2rem',
          marginBottom: '2rem',
        }}
      >
        <TruckLicensePlateAutocomplete
          disabled={editStore.truckVisit?.id !== 0}
          fullWidth
          value={editStore.truckVisit.identifier ?? ''}
          sx={{ mb: '2rem' }}
          onChange={(_, value) => {
            if (value) setIsError(false)
            else setIsError(true)

            onTextFieldChange(value)
          }}
          error={isError}
          helperText={!isError ? '' : t('fieldIsRequired', 'Field is required.')}
        />
        <Grid container direction='row' spacing={10} sx={{ ml: '-64px' }}>
          <Grid item xs={7}>
            <Typography variant='h4'>Container(s)</Typography>
          </Grid>
          <Grid item xs={5}>
            <Button variant='outlined' onClick={onAddOrder}>
              + {t('add', 'Add')}
            </Button>
          </Grid>
        </Grid>
        <TruckAppointmentOrdersTabs
          appointment={editStore.truckVisit}
          inboundOrders={editStore.inboundOrders}
          outboundOrders={mergedOutboundAndNnrOrders}
          selectedDirection={openTabDirection}
          handleDirectionChange={setOpenTabDirection}
          handleEditOrder={onEditOrderHandler}
          handleRemoveOrder={onRemoveOrder}
        />
      </Container>
      <FullWidthFooter>
        <LoaderButton variant='contained' sx={{ mr: '0.5rem' }} onClick={onAllowButtonClick}>
          {passageButtonText()}
        </LoaderButton>
        <Button variant='outlined' color='inherit' onClick={onGoBack}>
          {t('back', 'Back')}
        </Button>
      </FullWidthFooter>
      <ConfirmationDrawerMobile
        open={!!warningText}
        onClose={() => setWarningText(undefined)}
        text={warningText ?? ''}
        onConfirm={async () => {
          await onAllowPassage()
          setWarningText(undefined)
        }}
      />
    </Box>
  )
})
