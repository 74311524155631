import { Cached } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { OrderResponseDto, OrderUpdateResponseDto, OrderUpdateType } from '@planning/app/api'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

interface UpdateCardProps {
  update: OrderUpdateResponseDto
  order: OrderResponseDto | null
  onMarkAsRead: () => void
}

export const ReviewUpdatesDialogCard: React.FC<UpdateCardProps> = observer(
  ({ update, order, onMarkAsRead }) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const containerNumber = order?.containerNumber

    const addedOrderText = t('newOrderHasBeenAdded', 'New order has been added')

    return (
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        mb={theme.customSpacing.l}
      >
        <Stack direction='row'>
          <Cached fontSize='large' />

          <Stack ml={theme.customSpacing.s}>
            <Typography
              variant='subtitle1'
              color={theme.palette.text.primary}
              mb={theme.customSpacing.xxs}
            >
              {containerNumber}
            </Typography>

            {update.type === OrderUpdateType.Create ? (
              <Typography variant='body1' fontSize='.875rem' color={theme.palette.text.secondary}>
                {addedOrderText}
              </Typography>
            ) : (
              update.entityFieldDataChanges.map((i, index) => (
                <Typography
                  key={index}
                  variant='body1'
                  fontSize='.875rem'
                  color={theme.palette.text.secondary}
                >
                  {i.name} has changed from <strong>{i.prevValue}</strong> to{' '}
                  <strong>{i.newValue}</strong>
                </Typography>
              ))
            )}

            <Typography variant='body2' fontSize='.75rem' color={theme.palette.text.secondary}>
              {formatDateTime(update.received)} - {update.source}
            </Typography>
          </Stack>
        </Stack>

        <Button color='inherit' variant='contained' onClick={onMarkAsRead}>
          {t('markAsRead', 'Mark as read')}
        </Button>
      </Stack>
    )
  },
)
