import {
  AllocationDestinationAndStackingStrategyDto,
  AllocationRuleTemplateDto,
  CarrierType,
  CarrierVisitDirection,
  GlobalAllocationRuleDto,
} from '@storage/app/api'
import {
  mapFacetsFormProfileToAllocationRuleTemplateDtoFacets,
  mapProfileCarrierTypeToDtoCarrierType,
} from '@storage/features/carrier-visit-allocation-rules/forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'
import { AllocationRuleTemplateFormProfile } from '@storage/features/carrier-visit-allocation-rules/forms/allocation-rule-templates-form/allocation-rule-templates-form.profile'
import { v4 as uuidv4 } from 'uuid'

export const mapAllocationRuleTemplateFormProfileToGlobalAllocationRuleDto = (
  { id, name, facets, destinationAndStackingStrategies }: AllocationRuleTemplateFormProfile,
  direction: CarrierVisitDirection,
): GlobalAllocationRuleDto => ({
  id: id || uuidv4(),
  name,
  carrierType: mapProfileCarrierTypeToDtoCarrierType(facets.carrierType),
  direction: direction,
  facets: mapFacetsFormProfileToAllocationRuleTemplateDtoFacets(facets, direction),
  destinationAndStackingStrategies,
})

export const mapAllocationRuleTemplateDtoToGlobalAllocationRuleDto = (
  { id, name, facets }: AllocationRuleTemplateDto,
  destinationAndStackingStrategies: AllocationDestinationAndStackingStrategyDto[],
  direction: CarrierVisitDirection,
): GlobalAllocationRuleDto => ({
  id: id || uuidv4(),
  name,
  direction: direction,
  // By default assign universal carrier type to rule templates copied into global rules context
  carrierType: CarrierType.Universal,
  facets: {
    ...facets,
    inboundCarrierType: direction === CarrierVisitDirection.Inbound ? CarrierType.Universal : null,
    outboundCarrierType:
      direction === CarrierVisitDirection.Outbound ? CarrierType.Universal : null,
  },
  destinationAndStackingStrategies,
})
