import { Alert, Box, Button, Typography } from '@mui/material'
import { OrderResponseDto, UnitListDto, UnitType } from '@planning/app/api'
import { emptyIndicator } from '@planning/constants'
import {
  IFieldDifference,
  IOrderAmendment,
} from '@planning/pages/Order/stores/OrderListUploadComparisonStore'
import { useTranslate } from '@tolgee/react'
import {
  ColorSchema,
  ColoredLabel,
  GeneralIcon,
  SwapBodyIcon,
  TrailerIcon,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { Dictionary } from 'lodash'
import { FC } from 'react'

interface IProps {
  order: OrderResponseDto | UnitListDto | IOrderAmendment
  onAcceptChange: (containerNumber: string, accept: boolean) => void
  type: 'New' | 'Amended' | 'Cancelled'
  accept: boolean
}

export const OrderListCard: FC<IProps> = ({ order, type, accept, onAcceptChange }) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const typeColorMaps: Dictionary<ColorSchema> = {
    New: 'success',
    Amended: 'info',
    Cancelled: 'warning',
  }

  const renderContainerAttr = (label: string) => (
    <span
      style={{
        fontSize: '.875rem',
        color: theme.palette.grey[600],
        marginRight: '.5rem',
      }}
    >
      {label ? label : emptyIndicator}
    </span>
  )

  const renderContainerInfo = (key: string, title: string, value?: string) => (
    <Box key={key} mr={theme.customSpacing.l}>
      <Typography variant='caption' color={theme.palette.grey[600]}>
        {title}
      </Typography>
      <Typography variant='subtitle2'>{value ? value : emptyIndicator}</Typography>
    </Box>
  )

  const ContainerContent = () => (
    <Box sx={{ display: 'flex', padding: '1rem' }}>
      {renderContainerInfo(
        `cargo-status-${order.containerNumber ?? ''}`,
        t('cargoStatus', 'Cargo status'),
        order ? (order.isEmpty ? 'Empty' : 'Full') : '',
      )}
      {renderContainerInfo(
        `gross-weight-${order.containerNumber ?? ''}`,
        t('grossWeight', 'Gross weight'),
        `${order?.grossWeight}`,
      )}
      {renderContainerInfo(
        `imo-class-${order.containerNumber ?? ''}`,
        t('IMOClass', 'IMO Class'),
        order?.imoClasses?.join(', '),
      )}
    </Box>
  )

  const AmendedContainer: FC<{ differences: IFieldDifference[] }> = ({ differences }) => (
    <Box width={'100%'} sx={{ borderTop: `1px solid ${theme.palette.grey[300]}` }}>
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            padding: '1rem',
            borderRight: `1px solid ${theme.palette.grey[300]}`,
            minWidth: '90px',
          }}
        >
          <Typography variant='subtitle1'>{t('existing', 'Existing')}</Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            paddingLeft: '1rem',
          }}
          display='flex'
          flexDirection='row'
        >
          {differences.map(d =>
            renderContainerInfo(
              `amend-existing-${d.fieldName}-${order.containerNumber ?? ''}`,
              t(d.fieldName, d.fieldName),
              d.existingValue,
            ),
          )}
        </Box>
      </Box>

      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            padding: '1rem',
            borderRight: `1px solid ${theme.palette.grey[300]}`,
            minWidth: '90px',
          }}
        >
          <Typography variant='subtitle1'>{t('update', 'Update')}</Typography>
        </Box>
        <Box sx={{ width: '100%', paddingLeft: '1rem' }} display='flex' flexDirection='row'>
          {differences.map(d =>
            renderContainerInfo(
              `amend-update-${d.fieldName}-${order.containerNumber ?? ''}`,
              t(d.fieldName, d.fieldName),
              d.updateValue,
            ),
          )}
        </Box>
      </Box>
    </Box>
  )

  if (!order) return <></>

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: theme.customRadius.medium,
        m: '1rem 0',
      }}
    >
      {/* Card Header */}
      <Box
        sx={{
          padding: '1rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Box sx={{ display: 'flex' }}>
            {order.unitType === UnitType.Container && <GeneralIcon />}
            {order.unitType === UnitType.Trailer && <TrailerIcon />}
            {order.unitType === UnitType.SwapBody && <SwapBodyIcon />}
            <Typography variant='h4' ml={theme.customSpacing.xs}>
              {order.containerNumber}
            </Typography>
          </Box>

          {'isoCode' in order && renderContainerAttr(order.isoCode ?? '')}
          {'containerIsoCode' in order && renderContainerAttr(order.containerIsoCode ?? '')}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
          {type === 'Cancelled' && 'hasServiceOrders' in order && order.hasServiceOrders && (
            <Alert severity='warning' sx={{ mx: '1rem' }}>
              {t(
                'anyAssociatedStrippingOrStuffingOrdersWillBeDeleted',
                'Any associated stripping/stuffing orders will be deleted',
              )}
            </Alert>
          )}
          <ColoredLabel label={t(type.toLowerCase())} color={typeColorMaps[type]} />
          <Box sx={{ display: 'flex', ml: '1rem' }}>
            <Button
              variant='contained'
              color={accept ? 'primary' : 'secondary'}
              sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              onClick={() => onAcceptChange(order.containerNumber ?? '', true)}
            >
              {t('accept', 'Accept')}
            </Button>
            <Button
              variant='contained'
              color={accept ? 'secondary' : 'primary'}
              sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={() => onAcceptChange(order.containerNumber ?? '', false)}
            >
              {t('refuse', 'Refuse')}
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Card Body */}
      <Box
        sx={{
          bgcolor: theme.palette.grey[200],
          display: 'flex',
        }}
        flexDirection='column'
      >
        {('differences' in order && <AmendedContainer differences={order.differences} />) || (
          <Box
            sx={{
              borderTop: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <ContainerContent />
          </Box>
        )}
      </Box>
    </Box>
  )
}
