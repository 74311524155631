import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import {
  ContainerTurnoverDto,
  ErrorCodes,
  YardBlockBayDto,
  YardBlockDto,
  YardPositionDto,
} from '@storage/app/api'
import { isApplicationDomainException } from '@storage/app/http-client/interceptors/domain-exception.response-interceptor'
import { tolgee } from '@storage/app/translation'
import { YardBlockBayInfo } from '@storage/components/yard-block-bay-info'
import { YardBlockBayOverview } from '@storage/components/yard-block-bay-overview'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { YardBayViewStore } from '../stores/YardBayViewStore'
import { YardBlockBayOverviewContainerInformation } from './YardBlockBayOverviewContainerInformation'

interface Props {
  open: boolean
  block: YardBlockDto
  bay: YardBlockBayDto
  onClose: () => void
  bayViewStore: YardBayViewStore
}

export const YardBlockBayOverviewDialog = observer(
  ({ open, block, bay, onClose, bayViewStore }: Props) => {
    const { yardBlockSlotStore, alertStore } = useStores()

    useEffect(() => {
      bayViewStore.setSelectedContext(block, bay)
    }, [bay, block, bayViewStore])

    useEffect(() => {
      const loadBaySlots = async () => {
        if (!bayViewStore.yardBlockBay) return

        await yardBlockSlotStore.loadForYardBlockBay(bayViewStore.yardBlockBay.id)
      }

      loadBaySlots()
    }, [bayViewStore.yardBlockBay, yardBlockSlotStore])

    const { t } = useTranslate()
    const { snackbarStore } = useStores()

    const handleMenuAction = (action: string, turnover: ContainerTurnoverDto) => {
      if (action.toUpperCase() === 'UPDATE') {
        alertStore.clearAlerts()
        bayViewStore.setSelectedTurnover(turnover)
      }
    }

    const handleUpdatePosition = async (location?: YardPositionDto) => {
      if (!location) {
        return
      }

      await bayViewStore
        .store(location)
        .then(() => {
          //continue
          yardBlockSlotStore.loadForYardBlockBay(bay.id)
          snackbarStore.showMessage(
            t('ContainerPositionUpdated', 'Container position updated'),
            'success',
          )
          bayViewStore.setLoading(false)
        })
        .catch(error => {
          // Check for dg stacking rules violations
          if (
            isApplicationDomainException(error, ErrorCodes.DgStackingRulesViolationStackingOnTop)
          ) {
            alertStore.showAlert(
              ErrorCodes.DgStackingRulesViolationStackingOnTop,
              tolgee.t(
                'stackingValidationStackingOnTopPlan',
                `We can't plan at this position. There is a stacking rule that prohibits stacking on top`,
              ),
            )
          } else if (
            isApplicationDomainException(error, ErrorCodes.DgStackingRulesViolationMaxAllowedTier)
          ) {
            alertStore.showAlert(
              ErrorCodes.DgStackingRulesViolationMaxAllowedTier,
              tolgee.t(
                'stackingValidationMaxStackTierPlan',
                `We can't plan at this position. There is a maximum tier stacking rule in place for this position.`,
              ),
            )
          } else if (isApplicationDomainException(error, ErrorCodes.DgSegregationRulesViolation)) {
            alertStore.showAlert(
              ErrorCodes.DgSegregationRulesViolation,
              tolgee.t(
                'segregationRulesViolationPlan',
                `Be aware, you action violates the current segreation rules.`,
              ),
            )
          } else {
            // Generic error
            snackbarStore.showMessage(
              tolgee.t(
                'cantPlanAtThisPosition',
                `Something went wrong. We can't plan at this position`,
              ),
              'error',
            )
          }
          bayViewStore.setLoading(false)
        })
    }

    return (
      <Dialog maxWidth='xl' fullWidth open={open} onClose={onClose}>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {bayViewStore.yardBlock && (
              <YardBlockBayInfo
                block={bayViewStore.yardBlock}
                containerSx={{ padding: '1rem' }}
                bayViewStore={bayViewStore}
              />
            )}
            <YardBlockBayOverviewContainerInformation
              containerSx={{
                width: 251,
                maxHeight: 109,
                margin: '1rem',
                padding: '1rem',
                overflowY: 'scroll',
              }}
              blockBaySummary={yardBlockSlotStore.blockBaySummary}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <YardBlockBayOverview
              bayDetailsMatrix={bayViewStore.bayViewData}
              selectedTurnover={bayViewStore.turnover}
              onMenuAction={handleMenuAction}
              onUpdatePosition={handleUpdatePosition}
              isLoading={bayViewStore.isLoading}
              validationAlerts={alertStore.getAlerts()}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='text' color='inherit' onClick={onClose}>
            {t('close', 'Close')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  },
)
