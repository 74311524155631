import { Autocomplete, TextField } from '@mui/material'
import _ from 'lodash'
import { useEffect, useState } from 'react'

interface Props {
  label?: string
  size?: 'small' | 'medium'
  value?: string[]
  options: string[]
  handleChange: (value?: string) => void
}

export const PositionRangeAutocomplete = ({ label, size, value, options, handleChange }: Props) => {
  const [fieldValues, setFieldValues] = useState<string[]>([])

  useEffect(() => {
    if (value) setFieldValues(value)
  }, [value])

  const handleValueChange = (value?: string[] | null) => {
    if (!value || value.length === 0) {
      setFieldValues([])
      handleChange()
      return
    }

    let newValue

    if (value && value.length > fieldValues.length) {
      const sortedValues = _.sortBy(value)
      const minValue = sortedValues[0]
      const maxValue = sortedValues[value.length - 1]

      const startIndex = options.indexOf(minValue)
      const endIndex = options.indexOf(maxValue) + 1

      newValue = options.slice(startIndex, endIndex)
    } else {
      const sortedValues = _.sortBy(fieldValues)
      const minValue = sortedValues[0]
      const maxValue = sortedValues[fieldValues.length - 1]

      newValue = value.includes(minValue) ? [minValue] : [maxValue]
    }

    setFieldValues(newValue)
    handleChange(getRangeDisplayValue(newValue))
  }

  const getRangeDisplayValue = (values: string[]) => {
    if (values.length === 1) return values[0]

    const sortedValues = _.sortBy(values)
    const minValue = sortedValues[0]
    const maxValue = sortedValues[values.length - 1]

    return `${minValue}-${maxValue}`
  }

  const getRangeDisabledOptions = (option: string) => {
    if (fieldValues.length <= 1) return false

    const sortedValues = fieldValues.sort()
    const minValue = sortedValues[0]
    const maxValue = sortedValues[fieldValues.length - 1]

    return option !== minValue && option !== maxValue
  }

  return (
    <Autocomplete
      options={options}
      multiple
      renderTags={getRangeDisplayValue}
      size={size ?? 'medium'}
      renderInput={params => <TextField {...params} label={label} />}
      value={fieldValues}
      onChange={(e, value) => handleValueChange(value)}
      getOptionDisabled={getRangeDisabledOptions}
      fullWidth
    />
  )
}
