import { Box, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface IProps {
  title: string
  actions?: JSX.Element[]
}

export const ListHeader = observer(({ title, actions }: IProps) => {
  const theme = useMinimalsTheme()

  return (
    <Box display='flex' alignItems='center' mb='1rem' justifyContent='space-between'>
      <Box>
        <Typography variant='h4'>{title}</Typography>
      </Box>
      <Box display='flex' gap={theme.customSpacing.xs}>
        {actions?.map((action, index) => {
          return <Box key={`searchable-list-action-${index}`}>{action}</Box>
        })}
      </Box>
    </Box>
  )
})
