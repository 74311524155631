import { BlockLabellingPattern, YardBlockDto, YardBlockRowDto } from '@storage/app/api'
import useContextMenu from '@storage/hooks/use-context-menu.hook'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useState } from 'react'
import { Group, Rect, Text } from 'react-konva'
import { stackHeightInPixels, stackWidthInPixels } from '../constants'

interface Props {
  blockDto: YardBlockDto
  rows: YardBlockRowDto[]
  row: YardBlockRowDto
  nextName?: string
  inEditMode: boolean
  onRedraw: () => void
  onRowLabelClicked: (row: YardBlockRowDto) => void
}

export const YardBlockGridRow = ({
  blockDto,
  rows,
  row,
  nextName,
  inEditMode,
  onRedraw,
  onRowLabelClicked,
}: Props) => {
  const {
    yardBlockRowStore,
    yardBlockStore,
    yardBlockSlotStore,
    yardBlockStackStore,
    snackbarStore,
  } = useStores()
  const { palette } = useMinimalsTheme()
  const { t } = useTranslate()
  const rowContextMenu = useContextMenu()

  const [busy, setBusy] = useState<boolean>(false)

  const handleOnAddNewRow = async () => {
    if (busy) return

    setBusy(true)
    await yardBlockRowStore.add({
      name: nextName!,
      yardBlockId: blockDto.id,
      nextToFirstRow: false,
    })

    await yardBlockStackStore.loadForBlock(blockDto.id)

    onRedraw()

    setBusy(false)

    snackbarStore.showMessage(t('addRowSuccess', 'A new row is successfully added'), 'success')
  }

  const lastRow = () => [...rows].reverse()[0]

  const rowDeleteAllowed = () => {
    const yardBlock = yardBlockStore.get(blockDto.id)
    const row = lastRow()
    if (!yardBlock || !row) return false

    const stacks = yardBlockStackStore.getForRow(row.id)
    const used = !!stacks.find(s => s.numberOfOccupiedSlots > 0)
    return yardBlock.rowCount > 1 && !used
  }

  const handleOnDeleteRow = async () => {
    if (!lastRow()) return

    const rowId = lastRow().id

    await yardBlockRowStore.delete(rowId)

    const slotIds = yardBlockRowStore.getSlots(rowId).map(s => s.id)
    yardBlockSlotStore.delete(slotIds)

    const stackIds = yardBlockStackStore.getForRow(rowId).map(s => s.id)
    yardBlockStackStore.delete(stackIds)

    onRedraw()

    snackbarStore.showMessage(t('deleteRowSuccess', 'A row is successfully deleted'), 'success')
  }

  return (
    <>
      <Group
        onContextMenu={({ evt }) => {
          evt.preventDefault()
          rowContextMenu.handleOpenMenu(evt.clientX, evt.clientY)
        }}
      >
        <Rect
          x={-stackWidthInPixels + 2}
          y={(row.sequenceNumber - 1) * stackHeightInPixels + 2}
          width={stackWidthInPixels - 4}
          height={stackHeightInPixels - 4}
          cornerRadius={10}
          fill={palette.grey[300]}
        />
        <Text
          x={-stackWidthInPixels}
          y={(row.sequenceNumber - 1) * stackHeightInPixels}
          width={stackWidthInPixels}
          height={stackHeightInPixels}
          text={row.name}
          align='center'
          verticalAlign='middle'
          ellipsis
          wrap='none'
          fill={palette.primary.dark}
          onClick={() => onRowLabelClicked(row)}
          onMouseEnter={e => {
            const container = e.target.getStage()?.container()
            if (container) {
              container.style.cursor = 'pointer'
            }
          }}
          onMouseLeave={e => {
            const container = e.target.getStage()?.container()
            if (container) {
              container.style.cursor = 'default'
            }
          }}
        />
      </Group>

      {inEditMode &&
        !blockDto.isFlexibleZone &&
        row.id === lastRow().id &&
        blockDto.rowLabellingPattern !== BlockLabellingPattern.Custom && (
          <>
            <Group visible={!!nextName}>
              <Rect
                x={-stackWidthInPixels + 2}
                y={row.sequenceNumber * stackHeightInPixels + 2}
                width={(stackWidthInPixels - 4) / 2}
                height={stackHeightInPixels - 4}
                cornerRadius={10}
                fill={palette.grey[300]}
              />
              <Text
                x={-stackWidthInPixels}
                y={row.sequenceNumber * stackHeightInPixels}
                width={stackWidthInPixels / 2}
                height={stackHeightInPixels}
                text='+'
                align='center'
                verticalAlign='middle'
                fill={palette.primary.dark}
                onClick={handleOnAddNewRow}
                onMouseEnter={e => {
                  const container = e.target.getStage()?.container()
                  if (container) {
                    container.style.cursor = 'pointer'
                  }
                }}
                onMouseLeave={e => {
                  const container = e.target.getStage()?.container()
                  if (container) {
                    container.style.cursor = 'default'
                  }
                }}
              />
            </Group>

            <Group visible={rowDeleteAllowed()}>
              <Rect
                x={-stackWidthInPixels / 2 + 2}
                y={row.sequenceNumber * stackHeightInPixels + 2}
                width={(stackWidthInPixels - 4) / 2}
                height={stackHeightInPixels - 4}
                cornerRadius={10}
                fill={palette.grey[300]}
              />
              <Text
                x={-stackWidthInPixels / 2}
                y={row.sequenceNumber * stackHeightInPixels}
                width={stackWidthInPixels / 2}
                height={stackHeightInPixels}
                text='-'
                align='center'
                verticalAlign='middle'
                fill={palette.primary.dark}
                onClick={handleOnDeleteRow}
                onMouseEnter={e => {
                  const container = e.target.getStage()?.container()
                  if (container) {
                    container.style.cursor = 'pointer'
                  }
                }}
                onMouseLeave={e => {
                  const container = e.target.getStage()?.container()
                  if (container) {
                    container.style.cursor = 'default'
                  }
                }}
              />
            </Group>
          </>
        )}
    </>
  )
}
