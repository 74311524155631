import { ExpandMore, Info } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { ReactNode, useState } from 'react'

interface AccordionContainerProps {
  title: string
  tooltip?: string
  action?: {
    icon: ReactNode
    onClick: (e: React.MouseEvent) => void
  }
  defaultExpanded?: boolean
  children: ReactNode
}

export const AccordionContainer = ({
  title,
  tooltip,
  action,
  defaultExpanded = true,
  children,
}: AccordionContainerProps) => {
  const [expanded, setExpanded] = useState(defaultExpanded)
  const { palette } = useMinimalsTheme()

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, isExpanded) => setExpanded(isExpanded)}
      disableGutters
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          backgroundColor: palette.grey[200],
          borderBottom: 1,
          borderColor: 'divider',
          '& .MuiAccordionSummary-content': {
            my: 0,
          },
        }}
      >
        <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
          <Stack direction='row' alignItems='center' spacing={1}>
            <Typography variant='subtitle1'>{title}</Typography>
            {tooltip && (
              <Tooltip title={tooltip} arrow onClick={e => e.stopPropagation()}>
                <Info fontSize='small' />
              </Tooltip>
            )}
          </Stack>
          {action && (
            <IconButton
              sx={{ mx: 0.5 }}
              color='inherit'
              onClick={e => {
                e.stopPropagation()
                action.onClick(e)
              }}
              size='small'
            >
              {action.icon}
            </IconButton>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>{children}</AccordionDetails>
    </Accordion>
  )
}
