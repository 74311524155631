import { Stack, Typography } from '@mui/material'
import { UnknownNumberValue } from '@storage/app/models'
import { ControlledSelect, ControlledTextField } from '@storage/components/form-controls'
import { ControlledMultipleChipSelect } from '@storage/components/form-controls/ControlledMultipleChipSelect'
import { ControlledRadioGroup } from '@storage/components/form-controls/ControlledRadioGroup'
import { useTranslate } from '@tolgee/react'
import { Control, FormState } from 'react-hook-form'
import { AllocationRuleTemplateFormProfile } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.profile'

interface AllocationRuleCommonFieldsProps {
  control: Control<AllocationRuleTemplateFormProfile>
  formState: FormState<AllocationRuleTemplateFormProfile>
  containerSizeOptions: any[]
  containerTypeOptions: any[]
  containerHeightOptions: any[]
  cargoStatusOptions: any[]
  reeferOptions: any[]
  dangerousOptions: any[]
  carrierOptions: any[]
  weightClassesOptions: any[]
  customers: any[]
  openWithOnlyContainerNumber?: boolean
  showCarrierTypeField?: boolean
}

export const AllocationRuleCommonFields = ({
  control,
  formState,
  containerSizeOptions,
  containerTypeOptions,
  containerHeightOptions,
  cargoStatusOptions,
  reeferOptions,
  dangerousOptions,
  carrierOptions,
  weightClassesOptions,
  customers,
  openWithOnlyContainerNumber,
  showCarrierTypeField = false,
}: AllocationRuleCommonFieldsProps) => {
  const { t } = useTranslate()

  if (openWithOnlyContainerNumber) {
    return (
      <ControlledTextField
        name='facets.containerNumber'
        formState={formState}
        control={control}
        fullWidth
        label={t('containerNumber', 'Container number')}
      />
    )
  }

  return (
    <>
      <Typography variant='subtitle1'>{t('facets', 'Facets')}</Typography>
      <Stack direction='row' gap={1}>
        <ControlledSelect
          formState={formState}
          control={control}
          fullWidth
          options={containerSizeOptions}
          label={t('size', 'Size')}
          name='facets.size'
        />
        <ControlledTextField
          name='facets.containerOperator'
          formState={formState}
          control={control}
          fullWidth
          label={t('containerOperator', 'Container Operator')}
        />
      </Stack>
      <Stack direction='row' gap={1}>
        <ControlledSelect
          allOption
          formState={formState}
          control={control}
          fullWidth
          options={containerTypeOptions}
          label={t('type', 'Type')}
          name='facets.containerType'
        />
        <ControlledSelect
          allOption
          formState={formState}
          control={control}
          fullWidth
          options={containerHeightOptions}
          label={t('height', 'Height')}
          name='facets.containerHeight'
        />
      </Stack>
      <Stack direction='row' gap={1}>
        <ControlledTextField
          name='facets.consignee'
          formState={formState}
          control={control}
          fullWidth
          label={t('consignee', 'Consignee')}
        />
        <ControlledTextField
          name='facets.portOfDischarge'
          formState={formState}
          control={control}
          fullWidth
          label={t('portOfDischarge', 'Port of discharge')}
        />
      </Stack>
      <ControlledSelect
        formState={formState}
        control={control}
        options={[
          ...customers.map(x => ({ label: x.name, value: x.id.toString() })),
          { label: t('unknown', 'Unknown'), value: UnknownNumberValue.toString() },
        ]}
        label={t('customer', 'Customer')}
        name='facets.customer'
      />
      {weightClassesOptions.length > 0 && (
        <>
          <Typography variant='captionBold'>{t('weightClasses', 'Weight Classes')}</Typography>
          <Stack direction='row'>
            <ControlledMultipleChipSelect
              formState={formState}
              control={control}
              options={weightClassesOptions}
              name='facets.weightClasses'
              hasTooltip
            />
          </Stack>
        </>
      )}
      <Stack direction='row' gap={1} justifyContent='space-between'>
        <ControlledRadioGroup
          formControlSx={{ mt: 1 }}
          allOption
          formState={formState}
          control={control}
          label={t('cargoStatus', 'Cargo Status')}
          name='facets.isEmpty'
          options={cargoStatusOptions}
          optionsOnColumnOrientation
        />
        <ControlledRadioGroup
          formControlSx={{ mt: 1 }}
          formState={formState}
          control={control}
          label={t('reefer', 'Reefer')}
          name='facets.isReefer'
          options={reeferOptions}
          optionsOnColumnOrientation
        />
        <ControlledRadioGroup
          formControlSx={{ mt: 1 }}
          formState={formState}
          control={control}
          label={t('dangerous', 'Dangerous')}
          name='facets.isDangerous'
          options={dangerousOptions}
          optionsOnColumnOrientation
        />
        {showCarrierTypeField && (
          <ControlledRadioGroup
            formControlSx={{ mt: 1 }}
            formState={formState}
            control={control}
            label={t('carrierType', 'Carrier Type')}
            name='facets.carrierType'
            options={carrierOptions}
            allOption
            optionsOnColumnOrientation
          />
        )}
      </Stack>
    </>
  )
}
