import { CustomsResponseDto, CustomsStatus } from '@planning/app/api'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

export class CustomsViewStore {
  items: CustomsResponseDto[] = []
  selectItemStates: boolean[] = []
  errors: string[] = []

  constructor(data: CustomsResponseDto[]) {
    makeObservable(this, {
      items: observable,
      selectItemStates: observable,
      errors: observable,
      addCustoms: action,
      updateCustoms: action,
      deleteCustoms: action,
      setSelectAll: action,
      setSelectItem: action,
      updateSelectedCustoms: action,
      deleteSelectedCustoms: action,
      setErrors: action,
      selectAllState: computed,
    })

    runInAction(() => {
      this.items = data
      for (let index = 0; index < data.length; index++) {
        this.setSelectItem(index, false)
      }
    })
  }

  addCustoms = (value: CustomsResponseDto) => {
    this.items.push(value)
    this.selectItemStates.push(false)
  }

  updateCustoms = (index: number, value: CustomsResponseDto) => {
    this.items[index] = value
  }

  deleteCustoms = (index: number) => {
    this.items.splice(index, 1)
    this.selectItemStates.splice(index, 1)
  }

  setSelectAll = (value: boolean) => {
    for (let index = 0; index < this.items.length; index++) {
      this.setSelectItem(index, value)
    }
  }

  setSelectItem = (index: number, value: boolean) => {
    this.selectItemStates[index] = value
  }

  updateSelectedCustoms = (value: CustomsStatus) => {
    for (let index = 0; index < this.selectItemStates.length; index++) {
      if (this.selectItemStates[index]) {
        this.updateCustoms(index, { ...this.items[index], status: value })
      }
    }
  }

  deleteSelectedCustoms = () => {
    for (let index = this.selectItemStates.length - 1; index >= 0; index--) {
      if (this.selectItemStates[index]) {
        this.deleteCustoms(index)
      }
    }
  }

  setErrors = (value: string[]) => {
    this.errors = value
  }

  get selectAllState(): boolean | 'indeterminate' {
    if (this.selectItemStates.includes(true) && this.selectItemStates.includes(false))
      return 'indeterminate'

    return this.selectItemStates.every(state => state)
  }

  validate = () => {
    let isValid = true
    const references = new Set<string>()
    const errors: string[] = []

    for (let index = 0; index < this.items.length; index++) {
      const reference = this.items[index].reference

      if (!reference) {
        errors[index] = 'fieldIsRequired'
        isValid = false
        continue
      }

      if (references.has(reference)) {
        errors[index] = 'duplicateValue'
        isValid = false
      } else {
        references.add(reference)
      }
    }

    this.setErrors(errors)

    return isValid
  }
}
