import { YardBlockAssignmentDto } from '@operations/app/api'

export interface EquipmentFormProfile {
  id?: number
  name: string
  equipmentType: number | string
  isOnMaintenance: boolean
  maxWeight?: number | null
  maxHeight?: number | null
  yardBlockIds?: string[] //REMOVE ONCE THE FEATURE FLAG IS NOT NEEDED
  yardBlockAssignments?: YardBlockAssignmentDto[]
}

export const defaultValues: EquipmentFormProfile = {
  name: '',
  equipmentType: '',
  isOnMaintenance: false,
  maxWeight: undefined,
  maxHeight: undefined,
  yardBlockIds: [],
  yardBlockAssignments: [],
}
