import { Theme } from '@mui/material'
import { IRailVisitItem } from 'modules/planning/src/rt-stores/railVisit/RailVisitItem'
import { IVesselVisitItem } from 'modules/planning/src/rt-stores/vesselVisit/VesselVisitItem'
import moment from 'moment'
import { ContainerSummary } from '../components/ContainerAggregation'
import { PieItem } from '../components/PieGraph'

export const getDefaultVisitStatusForPieChart = (theme: Theme, t: any) => [
  {
    id: 1,
    color: theme.palette.info.light,
    value: 0,
    label: t('expected', 'Expected'),
  },
  {
    id: 2,
    color: theme.palette.primary.light,
    value: 0,
    label: t('arrived', 'Arrived'),
  },
  {
    id: 3,
    color: theme.palette.busy.light,
    value: 0,
    label: t('inOperation', 'In Operation'),
  },
  {
    id: 4,
    color: theme.palette.secondary.light,
    value: 0,
    label: t('Departed', 'Departed'),
  },
  {
    id: 5,
    color: theme.palette.primary.dark,
    value: 0,
    label: t('completed', 'Completed'),
  },
]

export const updateVisitDashabordsByStatus = (visitDashboard: PieItem[], visitStatus: string) => {
  switch (visitStatus) {
    case 'Expected':
      visitDashboard[0].value++
      break
    case 'Arrived':
      visitDashboard[1].value++
      break
    case 'InOperation':
      visitDashboard[2].value++
      break
    case 'Departed':
      visitDashboard[3].value++
      break
    case 'Completed':
      visitDashboard[4].value++
      break
  }
}

export const getContainerSummaryFromVisits = (visits: IRailVisitItem[] | IVesselVisitItem[]) => {
  const summaryIn: ContainerSummary = {
    completed: 0,
    expected: 0,
  }
  const summaryOut: ContainerSummary = {
    completed: 0,
    expected: 0,
  }

  visits.forEach(visit => {
    let expectedIn = visit.getCount('total', 'Inbound', 'Container')
    if (expectedIn === null || expectedIn === 0) expectedIn = visit.discharge.orderCounts.estimated

    summaryIn.completed += visit.getCount('confirmed', 'Inbound', 'Container')
    summaryIn.expected += expectedIn

    let expectedOut = visit.getCount('total', 'Outbound', 'Container')
    if (expectedOut === null || expectedOut === 0) expectedOut = visit.load.orderCounts.estimated

    summaryOut.completed += visit.getCount('confirmed', 'Outbound', 'Container')
    summaryOut.expected += expectedOut
  })

  return {
    summaryIn,
    summaryOut,
  }
}

export const isDateBetweenFromAndTo = (
  date?: string | Date | null,
  from?: string | null,
  to?: string | null,
) => {
  return moment(date).isBetween(moment(from), moment(to), 'd', '[]')
}
