import _ from 'lodash'

export const GetRangeFromStringListByListWithMinAndMax = (
  list: string[],
  listWithMinAndMax: string[],
) => {
  const sortedValues = _.sortBy(listWithMinAndMax)
  const minValue = sortedValues[0]
  const maxValue = sortedValues[listWithMinAndMax.length - 1]

  const sortedList = _.sortBy(list)
  const startIndex = sortedList.indexOf(minValue)
  const endIndex = sortedList.indexOf(maxValue) + 1

  return sortedList.slice(startIndex, endIndex)
}
