import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitDirection } from '@planning/app/api'
import { PATH_PLANNING } from '@planning/page-url-paths'
import { IssuesPageLocationState } from '@planning/pages/Issues'
import { OrderGroupListItem } from '@planning/pages/VesselVisit/Components/Grouping/OrderGroupListItem'
import { orderService } from '@planning/services'
import { RailcarSequencingStore } from '@planning/stores/railcarSequencing/RailcarSequencingStore'
import { formatRailcarName } from '@planning/utils/railcar-utils'
import { useTranslate } from '@tolgee/react'
import { Group, Grouping, Item, Loader } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { GroupingContextAction } from 'modules/ui/src/theme/components/grouping/GroupingContextMenu'
import { MultiDragAwareReorderProps } from 'modules/ui/src/theme/components/grouping/utils/utils'
import { FC } from 'react'
import { Id } from 'react-beautiful-dnd'
import { useNavigate } from 'react-router'
import { RailcarGroupingItem } from './RailcarGroupingItem'

interface Props {
  store: RailcarSequencingStore
  direction: CarrierVisitDirection
}

export const ObserverGrouping: FC<Props> = observer(({ store, direction }) => {
  const { appViewStore } = usePlanningStore()
  const { t } = useTranslate()
  const navigate = useNavigate()

  const entities = direction === 'Inbound' ? store.entities.discharge : store.entities.load

  const filterStatus = store.filterStatus ? [{ key: 'status', value: store.filterStatus }] : []

  const railcarRowHeight = 60
  const ordersRowHeight = 60
  const calcRailcarGroupHeight = (items: Item[]) => items.length * railcarRowHeight
  const calcOrdersGroupHeight = (item: Item) =>
    ordersRowHeight * (item?.content?.orders?.length || 0)
  const calcTotalOrdersGroupHeight = (items: Item[]) =>
    items.reduce((acc, item) => acc + calcOrdersGroupHeight(item), 0)

  const onDelete = async (railcarTrackPositionIds: Id[]) => {
    const railcarTrackPositionsToBeDeletedIds = store.getRailcarTrackPositionsToBeDeletedIds(
      railcarTrackPositionIds,
      direction,
    )

    if (!railcarTrackPositionsToBeDeletedIds || railcarTrackPositionsToBeDeletedIds.length === 0)
      return

    const ordersToBeDeleted = store.getOrdersByRailcarTrackPositionIds(
      railcarTrackPositionsToBeDeletedIds,
    )

    const selectedContainersCount = railcarTrackPositionIds.length
    const orderIdsToBeDeleted = ordersToBeDeleted?.map(i => i.data.id) || []

    let confirmationMessage = ''

    if (!ordersToBeDeleted || ordersToBeDeleted.length === 0) {
      confirmationMessage =
        selectedContainersCount === 1
          ? 'Railcar will be deleted. Continue?'
          : `The selected ${selectedContainersCount} railcars will be deleted. Continue?`
    } else {
      const ordersToBeDeletedCount = ordersToBeDeleted.length ?? 0

      const inboundContainerNumberList =
        ordersToBeDeleted
          .filter(i => i.data.direction === CarrierVisitDirection.Inbound)
          .map(i => i.data.containerNumber) || []

      const outboundContainerNumberList =
        ordersToBeDeleted
          .filter(i => i.data.direction === CarrierVisitDirection.Outbound)
          .map(i => i.data.containerNumber) || []

      const inboundText =
        inboundContainerNumberList.length > 0
          ? `${inboundContainerNumberList.join(', ')} for discharge`
          : ''

      const outbondText =
        outboundContainerNumberList.length > 0
          ? `${outboundContainerNumberList.join(', ')} for load`
          : ''

      const separatorText = inboundText && outbondText ? ' and ' : ''

      const orderText = `${inboundText}${separatorText}${outbondText}`

      if (selectedContainersCount === 1) {
        confirmationMessage = `Deleting this railcar will also delete ${ordersToBeDeletedCount} associated order(s) from the visit, including ${orderText}. Continue?`
      } else {
        confirmationMessage = `Deleting the selected ${selectedContainersCount} railcars will also delete ${ordersToBeDeletedCount} associated order(s) from the visit, including ${orderText}. Continue?`
      }
    }

    const isConfirmed = await appViewStore.setOpenConfirmDialog(
      true,
      confirmationMessage,
      t('confirmRailcarDelete', 'Confirm railcar delete'),
    )

    if (!isConfirmed) return

    store.setIsLoading(true)

    try {
      if (orderIdsToBeDeleted.length > 0) {
        await Promise.all([
          orderService.deleteByIds(orderIdsToBeDeleted),
          store.deleteRailcar(railcarTrackPositionIds),
        ])
      } else {
        await store.deleteRailcar(railcarTrackPositionIds)
      }

      appViewStore.setShowAlert('success', t('railcarDeleted', 'Railcar deleted'))
    } catch (error) {
      appViewStore.setShowAlert(
        'error',
        t('railcarNotDeleted', 'An error occurred while trying to delete the Railcar'),
      )
    } finally {
      store.setIsLoading(false)
    }
  }

  const onResolve = async (selectedItemIds: Id[]) => {
    navigate(`${PATH_PLANNING.issues}`, {
      state: {
        filterByRailCarTrackPositionId: Number(selectedItemIds[0]),
      } as IssuesPageLocationState,
    })
  }

  const isUnassignOrderGroup = (groupId: string) => {
    const unassignedOrderGroupIds = store.unassignedLoadOrdersGroupItems
      ? Object.keys(store.unassignedLoadOrdersGroupItems.group)
      : []

    return unassignedOrderGroupIds.includes(groupId)
  }

  const getActions = (): GroupingContextAction[] => {
    return [
      {
        label: () => t('copy', 'Copy'),
        isActionDisabled: (selectedItemIds: Id[]) => selectedItemIds.length === 0,
        children: (group, items) => getRailcarOptions(group, items),
      },
      {
        label: () => t('delete', 'Delete'),
        onClick: onDelete,
      },
      {
        label: () => t('resolve', 'Resolve'),
        isActionHidden: (selectedItems: Item[]) => {
          return !(selectedItems.length === 1 && selectedItems[0].content.hasIssue)
        },
        onClick: onResolve,
      },
    ]
  }

  const getRailcarOptions = (group: Group, items: Item[]) => {
    const unassignedOrderGroupIds = store.unassignedLoadOrdersGroupItems
      ? Object.keys(store.unassignedLoadOrdersGroupItems.group)
      : []

    if (unassignedOrderGroupIds.includes(group.id)) return []

    return items.map(item => ({
      label: () => formatRailcarName(item.content['railCar']),
      children: () => getOrderOptions(item.content['orders'] ?? []),
      onClick: () => {
        const data = formatRailcarName(item.content['railCar'])
        if (data) navigator.clipboard.writeText(data)
      },
    }))
  }

  const getOrderOptions = (orders: any) =>
    orders.map((order: any) => ({
      label: () => order['containerNumber'],
      children: () => getCopyOptions(order),
    }))

  const getCopyOptions = (order: any) => {
    const props = ['containerNumber', 'referenceNumber']

    return props.map(prop => ({
      label: () => t(prop, prop),
      onClick: () => {
        const data = order[prop]
        if (data) navigator.clipboard.writeText(data)
      },
    }))
  }

  const groupChangeIsValidFn = async (args: MultiDragAwareReorderProps) => {
    let isConfirmed = true
    const willUpdateCheckedInRailcar = store.willUpdateCheckedInRailcar(args)

    if (willUpdateCheckedInRailcar) {
      isConfirmed = await appViewStore.setOpenConfirmDialog(
        true,
        t(
          'changeSequenceOfAlreadyCheckedInRailcarContinue',
          'You are about to change the sequence of an already checked-in railcar. Continue?',
        ),
        t('confirm', 'Confirm'),
      )
    }

    return isConfirmed
  }

  const notifyMovedRailcars = (sourceGroup: Group, destinationGroup: Group, railcars: Item[]) => {
    const sourceGroupName = sourceGroup.name
    const destinationGroupName = destinationGroup.name

    if (sourceGroupName === destinationGroupName) return

    const itemName = railcars
      .slice(0, 3)
      .map(r => r.content.railCar)
      .join(', ')

    appViewStore.setShowAlert(
      'success',
      `Railcars ${itemName} moved from ${sourceGroupName} to ${destinationGroupName}`,
    )
  }

  if (store.isLoading) {
    return <Loader show />
  }

  return (
    <Grouping
      ignoreGroupChanges={true}
      entities={entities}
      groupChangeFn={async (
        _,
        sourceGroup,
        destinationGroup,
        railcars,
        destinationItemSequence,
      ) => {
        const groupChanged = await store.onGroupChange(
          direction,
          sourceGroup,
          destinationGroup,
          railcars,
          destinationItemSequence,
        )

        if (groupChanged) {
          notifyMovedRailcars(sourceGroup, destinationGroup, railcars)
        } else {
          appViewStore.setShowAlert(
            'error',
            t(
              'errorWhileTryingToMoveTheRailcars',
              'An error occurred while trying to move the railcars.',
            ),
          )
        }
      }}
      sequenceChangeFn={(_entities, item, _previousSequence, newSequence, destinationGroupId) => {
        const railcarIds = [parseInt(item.id)]
        store.saveChanges(direction, destinationGroupId, railcarIds, newSequence)
      }}
      groupChangeIsValidFn={groupChangeIsValidFn}
      allowGroupAdd={false}
      allowGroupOptions={false}
      allowGroupNotes={false}
      hasDefaultGroup={false}
      actions={getActions()}
      heightFn={items => calcRailcarGroupHeight(items) + calcTotalOrdersGroupHeight(items)}
      itemSizeFn={item => railcarRowHeight + calcOrdersGroupHeight(item)}
      genericFilter={{
        keys: ['railCar', 'referenceNumber', 'consignee', 'containerNumber'],
        value: store.filter ?? '',
      }}
      filters={filterStatus}
      renderData={(item: { id: Id; content: any }, group) =>
        (isUnassignOrderGroup(group.id) && (
          <OrderGroupListItem
            hasThreeDotMenu={true}
            filter={store.filter}
            group={group}
            item={item}
          />
        )) || <RailcarGroupingItem store={store} item={item} group={group} direction={direction} />
      }
    />
  )
})
