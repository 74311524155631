import {
  YardBlocksApi,
  YardBlocksApiCreateRequest,
  YardBlocksApiDeleteRequest,
} from '@storage/app/api'
import { createApiClient } from '@storage/app/http-client'

export class YardBlockService {
  public async deleteYardBlock(yardBlocksApiDeleteRequest: YardBlocksApiDeleteRequest) {
    return createApiClient(YardBlocksApi)._delete(yardBlocksApiDeleteRequest)
  }

  public async createYardBlock(yardBlocksApiCreateRequest: YardBlocksApiCreateRequest) {
    return createApiClient(YardBlocksApi).create(yardBlocksApiCreateRequest)
  }

  public async getYardBlockSummaries() {
    return createApiClient(YardBlocksApi).getYardBlockSummaries()
  }

  public async getYardBlockSummary(id: string) {
    return createApiClient(YardBlocksApi).getYardBlockSummary({ id })
  }

  public async loadYardBlocks() {
    const { data: yardBlocks } = await createApiClient(YardBlocksApi).getAll()
    return yardBlocks
  }
}
