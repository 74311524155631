import { FormControl, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { DetailedSelectOption } from '@storage/app/models'
import { useTranslate } from '@tolgee/react'

interface DetailedSelectProps {
  options: DetailedSelectOption[]
  value: string
  onChange: (value: string) => void
  label?: string
}

const StyledMenuItem = styled(MenuItem)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

const SubtitleTypography = styled(Typography)(({ theme }) => ({
  whiteSpace: 'normal',
  marginTop: theme.spacing(0.5),
}))

export const DetailedSelect = ({ options, value, onChange, label }: DetailedSelectProps) => {
  const { t } = useTranslate()
  const handleChange = (event: SelectChangeEvent<string>) => {
    onChange(event.target.value)
  }

  return (
    <FormControl fullWidth>
      <Select value={value} onChange={handleChange} label={label}>
        {options.map(option => (
          <StyledMenuItem key={option.value} value={option.value}>
            <Typography variant='subtitle1'>{t(option.title, option.title)}</Typography>
            <SubtitleTypography variant='body2' color='text.secondary'>
              {t(option.subtitle, option.subtitle)}
            </SubtitleTypography>
          </StyledMenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
