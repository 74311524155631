import { ListItemText, MenuItem } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { ChipWithOptions } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  bays: string[]
  selectedBay?: string
  onSelectBay: (bay?: string) => void
}
export const YardBaysChip = observer(({ bays, selectedBay, onSelectBay }: Props) => {
  const { t } = useTranslate()

  if (!bays.length) return <></>

  return (
    <ChipWithOptions
      value={selectedBay ? `${t('bay', 'Bay')} ${selectedBay}` : undefined}
      label={t('bays', 'Bays')}
      options={bays.map(bay => (
        <MenuItem
          key={bay}
          selected={selectedBay === bay}
          value={bay}
          onClick={() => {
            onSelectBay(selectedBay !== bay ? bay : undefined)
          }}
        >
          <ListItemText primary={bay} primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      ))}
    />
  )
})
