import { Box } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { AdvancedFilter } from '@tom-ui/ui'
import { useEffect, useState } from 'react'
import { ReeferMonitoringFiltersUIStore } from '../../stores/reefer-monitoring-filters.ui-store'
import { ReeferMonitoringFiltersUIStoreV2 } from '../../stores/reefer-monitoring-filters.ui-storeV2'
import { ReeferMonitoringFilter } from './reefer-monitoring-filter.model'
import { ReeferMonitoringFiltersForm } from './ReeferMonitoringFiltersForm'

interface Props {
  filterUIStore: ReeferMonitoringFiltersUIStore | ReeferMonitoringFiltersUIStoreV2
}

export const ReeferMonitoringFilters = ({ filterUIStore }: Props) => {
  const { t } = useTranslate()
  const [open, setOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const openFilter = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSubmit = (filter: ReeferMonitoringFilter) => {
    filterUIStore.setFilter(filter)
    filterUIStore.toggle()
  }

  const handleReset = () => {
    filterUIStore.setFilter()
    filterUIStore.toggle()
    handleClose()
  }

  return (
    <Box position={isMobile ? undefined : 'relative'}>
      <AdvancedFilter
        open={open}
        formId='reefer-monitoring-filters'
        width={isMobile ? '98vw' : 'calc(95vw - 240px)'}
        filterTooltip={t('advancedFilter', 'Advanced filter')}
        onOpenFilter={openFilter}
        onClose={handleClose}
        filterActionLabel={t('showResults', 'Show results')}
        resetActionLabel={t('reset', 'Reset')}
        onFilter={handleClose}
        onReset={handleReset}
      >
        <ReeferMonitoringFiltersForm
          id='reefer-monitoring-filters'
          filter={filterUIStore.filter}
          yardBlocks={filterUIStore.yardBlocks}
          customers={filterUIStore.customers}
          onSubmit={handleSubmit}
        />
      </AdvancedFilter>
    </Box>
  )
}
