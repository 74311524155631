import { Checkbox, FormControl, FormControlLabel, Typography } from '@mui/material'
import { SelectOption } from '@operations/app/models'

interface Props {
  label: string
  options: SelectOption[]
  selectedCheckboxes?: (string | number)[] | null
  onChange: (options: (string | number)[]) => void
}
export const CheckboxSelector = ({ label, options, selectedCheckboxes, onChange }: Props) => {
  const handleOnChange = (option: SelectOption) => {
    const newValue = selectedCheckboxes ? [...selectedCheckboxes] : []

    if (newValue.length > 0) {
      const index = newValue.findIndex(x => x === option.value)

      if (index === -1) {
        newValue.push(option.value)
      } else {
        newValue.splice(index, 1)
      }
    } else {
      newValue.push(option.value)
    }

    onChange(newValue)
  }

  return (
    <FormControl component='fieldset' variant='standard'>
      <Typography variant='subtitle2'>{label}</Typography>
      {options?.map(option => (
        <FormControlLabel
          key={option.value}
          control={
            <Checkbox
              checked={selectedCheckboxes?.some(x => x === option.value)}
              onChange={() => {
                handleOnChange(option)
              }}
            />
          }
          label={option.label}
        />
      ))}
    </FormControl>
  )
}
