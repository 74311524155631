import { Button, Container, Stack, TextField } from '@mui/material'
import { IssueType, ReportRailcarTrackPositionMissingCommand } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { railVisitService } from '@planning/services'
import { useNavigationStore } from '@planning/stores'
import { useTranslate } from '@tolgee/react'
import { CloseButton, Header, StepperInfo, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { IInspectionRailCar } from '../../Stores/RailTallyViewStore'
import { ReportIssueTypeOption } from './ReportIssueTypeOption'

interface IProps {
  railcar: IInspectionRailCar
}

export const ReportRailcarIssue: FC<IProps> = observer(({ railcar }) => {
  const navigationStore = useNavigationStore()
  const { appViewStore } = usePlanningStore()
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const [selectedIssueType, setSelectedIssueType] = useState<IssueType>(IssueType.MissingRailcar)
  const { name, railcarTrackPositionId } = railcar

  const onSubmit = () => {
    try {
      if (selectedIssueType === IssueType.MissingRailcar) {
        const cmd: ReportRailcarTrackPositionMissingCommand = {
          railcarTrackPositionId: railcarTrackPositionId,
        }
        railVisitService.reportMissingRailcarTrackPosition(cmd)
      } else {
        appViewStore.setShowAlert('error', t('doNotSupport', 'Do not support'))
        return
      }

      appViewStore.setShowAlert('success', t('savedSuccessfully', 'Saved Successfully'))
      navigationStore.pop()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  return (
    <>
      <Header
        leftRenderOption={
          <CloseButton onClose={() => navigationStore.pop()} tooltip={t('close', 'Close')} />
        }
        title={t('reportIssue', 'Report issue')}
        rightRenderOption={
          <Button
            data-cy='submit-report-railcar-issue'
            variant='contained'
            onClick={onSubmit}
            sx={{ width: '15%', minWidth: '110px' }}
          >
            {t('submit', 'Submit')}
          </Button>
        }
      />

      <StepperInfo title={t('enterDetails', 'Enter details')} />

      <Container sx={{ py: theme.customSpacing.m }}>
        <Stack gap={theme.customSpacing.l}>
          <ReportIssueTypeOption
            options={[IssueType.MissingRailcar]}
            defaultValue={selectedIssueType}
            onSelect={(issueType: IssueType) => setSelectedIssueType(issueType)}
          />

          <TextField disabled label={t('railcarNo', 'Railcar no')} defaultValue={name}></TextField>
        </Stack>
      </Container>
    </>
  )
})
