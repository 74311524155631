import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Permission } from '@storage/app/models/permission.enum'
import { ImoClasses } from '@storage/components/types/ImoClasses'
import { useStores } from '@storage/hooks/use-stores.hook'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

interface StyledBodyCellProps extends TableCellProps {
  isSelected?: boolean
  canWrite?: boolean
}

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
  textAlign: 'center',
  padding: 0,
  minWidth: '80px',
  userSelect: 'none',
  width: '4px',
}))

const StyledBodyCell = styled(({ canWrite, isSelected, ...rest }: StyledBodyCellProps) => (
  <TableCell {...rest} />
))(({ theme, isSelected, canWrite }) => ({
  cursor: canWrite ? 'pointer' : 'default',
  backgroundColor: isSelected ? theme.palette.action.selected : theme.palette.background.paper,
  userSelect: 'none',
  fontWeight: 'bold',
  textAlign: 'center',
  padding: '10px',
  '&:hover': {
    backgroundColor: canWrite ? theme.palette.action.hover : 'inherit',
  },
  width: '40px',
}))

const DGSegregationRulesTable = observer(() => {
  const { dgSegregationRulesTableUIStore, authStore } = useStores()

  useEffect(() => {
    dgSegregationRulesTableUIStore.loadAllDGSegregationRules()
  }, [dgSegregationRulesTableUIStore])

  const handleMouseDown = (rowClass: string, colClass: string) => {
    authStore.hasPermission(Permission.WriteDangerousGoodsSettings) &&
      dgSegregationRulesTableUIStore.handleMouseDown(rowClass, colClass)
  }

  const handleMouseEnter = (rowClass: string, colClass: string) => {
    authStore.hasPermission(Permission.WriteDangerousGoodsSettings) &&
      dgSegregationRulesTableUIStore.handleMouseEnter(rowClass, colClass)
  }

  const handleMouseUp = () =>
    authStore.hasPermission(Permission.WriteDangerousGoodsSettings) &&
    dgSegregationRulesTableUIStore.handleMouseUp()

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledHeaderCell>IMO classes</StyledHeaderCell>
              {ImoClasses.map(cls => (
                <StyledHeaderCell key={cls}>{cls}</StyledHeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ImoClasses.map(rowClass => (
              <TableRow key={rowClass}>
                <StyledHeaderCell>{rowClass}</StyledHeaderCell>
                {ImoClasses.map(colClass => (
                  <StyledBodyCell
                    key={colClass}
                    onMouseDown={() => handleMouseDown(rowClass, colClass)}
                    onMouseEnter={() => handleMouseEnter(rowClass, colClass)}
                    onMouseUp={handleMouseUp}
                    isSelected={dgSegregationRulesTableUIStore.isCellSelected(rowClass, colClass)}
                    canWrite={authStore.hasPermission(Permission.WriteDangerousGoodsSettings)}
                  >
                    {dgSegregationRulesTableUIStore.getRule(rowClass, colClass) ?? '-'}
                  </StyledBodyCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
})

export default DGSegregationRulesTable
