import { Divider, Stack, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { Fragment } from 'react/jsx-runtime'
import { StackSlotDetails } from '../stores/stack-details-tooltip-container.ui-store'
import StackSlotDetailItem from './StackSlotDetailItem'

interface StackDetailsTooltipContainerProps {
  stackPosition: string
  stackSlotDetails: StackSlotDetails[]
}

const StackDetailsTooltipContainer = ({
  stackPosition,
  stackSlotDetails,
}: StackDetailsTooltipContainerProps) => {
  const { customSpacing } = useMinimalsTheme()

  return (
    <Stack minWidth='250px' divider={<Divider />}>
      <Typography variant='h4' py={customSpacing.xs} pl={customSpacing.xs}>
        {stackPosition}
      </Typography>

      {stackSlotDetails.map(slotDetails => (
        <Fragment key={slotDetails.tier}>
          <StackSlotDetailItem slotDetails={slotDetails} />
        </Fragment>
      ))}
    </Stack>
  )
}

export default StackDetailsTooltipContainer
