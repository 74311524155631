import { Box, Palette } from '@mui/material'
import { ReactNode } from 'react'
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { useMinimalsTheme } from '../../../hooks'
import { ButtonOption } from '../button/button.model'
import { OptionsButton } from '../button/OptionsButton'
import { GroupingContextAction } from './GroupingContextMenu'
import { Group, Id, Item } from './types'

interface GetBackgroundColorArgs {
  isSelected: boolean
  isGhosting: boolean
}

interface IProps {
  provided: DraggableProvided
  item: Item
  group: Group
  renderData?: (item: Item, group: Group) => ReactNode
}

interface NodeItemProps extends IProps {
  selectedItemIds: Id[]
}

interface GroupingNodeItemProps extends IProps {
  palette: Palette
  selectionCount: number
  snapshot?: DraggableStateSnapshot
  isDragging?: boolean
  isGhosting?: boolean
  isSelected?: boolean
  shouldShowSelection?: boolean
  actions?: GroupingContextAction[]
  onClick?: (event: any) => void
  onTouchEnd?: (event: any) => void
  onKeyDown?: (event: any, snapshot: DraggableStateSnapshot) => void
}

const getBackgroundColor = ({ isSelected, isGhosting }: GetBackgroundColorArgs): string => {
  if (isGhosting) {
    return 'blue'
  }

  if (isSelected) {
    return '#54D62C1F'
  }

  return 'white'
}

const getColor = ({ isSelected, isGhosting }: GetBackgroundColorArgs): string => {
  if (isGhosting) {
    return 'darkgrey'
  }
  if (isSelected) {
    return 'green'
  }
  return 'blue'
}

export const NodeItem = ({ selectedItemIds, provided, item, group, renderData }: NodeItemProps) => {
  const { palette } = useMinimalsTheme()
  const selectionCount = selectedItemIds.length

  return GroupingNodeItem({
    provided,
    item,
    group,
    palette,
    selectionCount,
    isDragging: true,
    isSelected: true,
    shouldShowSelection: selectionCount > 1,
    renderData,
  })
}

export const GroupingNodeItem = ({
  provided,
  item,
  group,
  palette,
  selectionCount,
  snapshot,
  isDragging = false,
  isGhosting = false,
  isSelected = false,
  shouldShowSelection = false,
  actions,
  renderData,
  onClick,
  onTouchEnd,
  onKeyDown,
}: GroupingNodeItemProps) => {
  const mapButtonOptions = (options?: GroupingContextAction[]): ButtonOption[] =>
    options
      ?.filter(action => !(action.isActionHidden && action.isActionHidden([item])))
      .map(action => {
        const selectedIds = [item.id]

        return {
          label: action.label(selectedIds),
          subtitle: action.subtitle?.(selectedIds),
          onClick: () => {
            if (action.onClick) action.onClick(selectedIds)
          },
          openToLeft: true,
          children: action.children
            ? mapButtonOptions(action.children(group, [item], selectedIds))
            : [],
        }
      }) || []

  return (
    <Box
      component='div'
      key={`grp-node-${item.id}`}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      onClick={(event: any) => (onClick ? onClick(event) : null)}
      onTouchEnd={(event: any) => (onTouchEnd ? onTouchEnd(event) : null)}
      onKeyDown={(event: any) => (onKeyDown && snapshot ? onKeyDown(event, snapshot) : null)}
      sx={{
        boxShadow: isDragging ? '2px 2px 1px lightgray ' : '',
        opacity: isGhosting ? '0.8' : '',
        position: 'relative',
        backgroundColor: getBackgroundColor({ isSelected, isGhosting }),
        color: getColor({ isSelected, isGhosting }),
      }}
    >
      {renderData ? renderData(item, group) : ''}
      {shouldShowSelection ? (
        <Box
          sx={{
            left: '-8px',
            top: '-8px',
            color: 'blue',
            background: 'white',
            height: '30px',
            width: '30px',
            lineHeight: '30px',
            position: 'absolute',
            textAlign: 'center',
            fontSize: '0.8rem',
            border: `1px solid ${palette.info.main}`,
          }}
        >
          {selectionCount}
        </Box>
      ) : null}
      <Box
        sx={{
          right: '8px',
          top: '8px',
          position: 'absolute',
        }}
        onClick={event => event.stopPropagation()}
      >
        {!group.hideOptions && (
          <OptionsButton position='bottom' options={mapButtonOptions(actions)} />
        )}
      </Box>
    </Box>
  )
}
