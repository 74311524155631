import {
  CoolingOrderResponseDto,
  CreateCoolingOrdersCommand,
  UpdateCoolingOrderCommand,
} from '@planning/app/api'
import { serviceOrdersService } from '@planning/services'
import { ContainerJourney } from '../Stores/ContainerJourneyDataStore'
import { CoolingOrderDialogViewStore } from '../Stores/CoolingOrderDialogViewStore'
import { ICoolingOrder } from '../Stores/ServiceOrdersViewStore'

export const mapCoolingOrder = (coolingOrder: CoolingOrderResponseDto): ICoolingOrder => {
  return {
    id: coolingOrder.id,
    isMonitoringRequired: coolingOrder.isMonitoringRequired,
    isPlugInRequired: coolingOrder.isPlugInRequired,
    requestedTemperature: coolingOrder.requestedTemperature,
    minRequestedTemperature: coolingOrder.minRequestedTemperature,
    maxRequestedTemperature: coolingOrder.maxRequestedTemperature,
    idealHumidity: coolingOrder.idealHumidity,
    minIdealHumidity: coolingOrder.minIdealHumidity,
    maxIdealHumidity: coolingOrder.maxIdealHumidity,
    idealVentilation: coolingOrder.idealVentilation,
    minIdealVentilation: coolingOrder.minIdealVentilation,
    maxIdealVentilation: coolingOrder.maxIdealVentilation,
    monitoringFrequency: coolingOrder.monitoringFrequency!,
  }
}

export const submitCoolingOrder = async (
  coolingOrder: ICoolingOrder,
  store: CoolingOrderDialogViewStore,
) => {
  const selectedJourneys = store.selectedContainerJourneys

  if (store.isMultiSelectMode) {
    await showDialogOrSubmit(selectedJourneys, coolingOrder, store)
  } else {
    const journey = selectedJourneys[0]
    await upsertCoolingOrder(journey, coolingOrder)
  }
}

const showDialogOrSubmit = async (
  selectedJourneys: ContainerJourney[],
  coolingOrder: ICoolingOrder,
  store: CoolingOrderDialogViewStore,
) => {
  const existingCoolingOrders = selectedJourneys.filter(journey => journey.coolingOrderId)
  if (existingCoolingOrders.length > 0) {
    store.setDialogOpen('overwrite')
  } else {
    const journeyIds = selectedJourneys.map(journey => journey.id)
    await submitCreateCoolingOrders(coolingOrder, journeyIds)
  }
}

const upsertCoolingOrder = async (journey: ContainerJourney, coolingOrder: ICoolingOrder) => {
  if (journey.coolingOrderId) {
    await submitUpdateCoolingOrder(coolingOrder)
  } else {
    await submitCreateCoolingOrders(coolingOrder, [journey.id])
  }
}

export const submitCreateCoolingOrders = async (
  coolingOrder: ICoolingOrder,
  containerJourneyIds: number[],
) => {
  const cmd = mapToCreateCoolingOrdersCommand(coolingOrder, containerJourneyIds)
  await serviceOrdersService.createCoolingOrder(cmd)
}

export const mapToCreateCoolingOrdersCommand = (
  coolingOrder: ICoolingOrder,
  journeyIds: number[],
) => {
  const cmd: CreateCoolingOrdersCommand = {
    containerJourneyIds: journeyIds,
    isMonitoringRequired: coolingOrder.isMonitoringRequired ?? false,
    isPlugInRequired: coolingOrder.isPlugInRequired ?? false,
    requestedTemperature: coolingOrder.requestedTemperature!,
    minRequestedTemperature: coolingOrder.minRequestedTemperature,
    maxRequestedTemperature: coolingOrder.maxRequestedTemperature,
    idealHumidity: coolingOrder.idealHumidity,
    minIdealHumidity: coolingOrder.minIdealHumidity,
    maxIdealHumidity: coolingOrder.maxIdealHumidity,
    idealVentilation: coolingOrder.idealVentilation,
    minIdealVentilation: coolingOrder.minIdealVentilation,
    maxIdealVentilation: coolingOrder.maxIdealVentilation,
    monitoringFrequency: coolingOrder.monitoringFrequency,
  }

  return cmd
}

const submitUpdateCoolingOrder = async (coolingOrder: ICoolingOrder) => {
  const cmd = mapToUpdateCoolingOrderCommand(coolingOrder)
  await serviceOrdersService.updateCoolingOrder(cmd)
}

export const mapToUpdateCoolingOrderCommand = (coolingOrder: ICoolingOrder) => {
  const cmd: UpdateCoolingOrderCommand = {
    id: coolingOrder.id!,
    isMonitoringRequired: coolingOrder.isMonitoringRequired ?? false,
    isPlugInRequired: coolingOrder.isPlugInRequired ?? false,
    requestedTemperature: coolingOrder.requestedTemperature!,
    minRequestedTemperature: coolingOrder.minRequestedTemperature,
    maxRequestedTemperature: coolingOrder.maxRequestedTemperature,
    idealHumidity: coolingOrder.idealHumidity,
    minIdealHumidity: coolingOrder.minIdealHumidity,
    maxIdealHumidity: coolingOrder.maxIdealHumidity,
    idealVentilation: coolingOrder.idealVentilation,
    minIdealVentilation: coolingOrder.minIdealVentilation,
    maxIdealVentilation: coolingOrder.maxIdealVentilation,
    monitoringFrequency: coolingOrder.monitoringFrequency,
  }

  return cmd
}
