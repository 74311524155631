import { Button } from '@mui/material'
import { FC } from 'react'

type Props = {
  text: string
  onClick: () => void
  size?: 'small' | 'medium' | 'large'
}

export const NonPrimaryButton: FC<Props> = ({ text, size, onClick }) => {
  return (
    <Button variant='contained' size={size} color={'inherit'} onClick={onClick}>
      {text}
    </Button>
  )
}
