import { CarrierType } from '@planning/app/api'
import { RailcarTrackPositionItemStore } from '@planning/rt-stores/railTrack/RailcarTrackPositionItemStore'
import { RailTrackItemStore } from '@planning/rt-stores/railTrack/RailTrackItemStore'
import { orderListParsingService } from '@planning/services'
import { action, makeObservable, observable } from 'mobx'
import { OrderListUploadViewStoreV2 } from './OrderListUploadViewStoreV2'

// [JH] TODO: Test store
export class OrderListUploadParsingStore {
  unloCodes: string[] = []

  file?: File
  uploadFailedReason?: string
  uploadWarningMessages: string[] = []

  constructor(
    private parentStore: OrderListUploadViewStoreV2,
    private railTrackStore: RailTrackItemStore,
    private railcarTrackPositionItemStore: RailcarTrackPositionItemStore,
  ) {
    makeObservable(this, {
      unloCodes: observable,
      file: observable,
      uploadFailedReason: observable,
      uploadWarningMessages: observable,
      setUNLOCodes: action,
      setFile: action,
      setUploadFailedReason: action,
      setUploadWarningMessage: action,
    })
  }

  setUNLOCodes = (unloCodes: string[]) => {
    this.unloCodes = unloCodes
  }

  setFile = (file?: File) => {
    this.file = file
  }

  setUploadFailedReason = (reason?: string) => {
    this.uploadFailedReason = reason
  }

  // [JH] TODO: test
  parseOrderList = async (skipTallyLoadPrepFF: boolean, isRestow?: boolean) => {
    if (
      (!this.parentStore.vesselVisitId && !this.parentStore.railVisitId) ||
      !this.parentStore.direction ||
      !this.file
    )
      return

    let orderList
    let visitType
    let relatedRailcars
    let restowList
    const visitId = this.parentStore.vesselVisitId
      ? this.parentStore.vesselVisitId
      : (this.parentStore.railVisitId as number)

    if (this.parentStore.vesselVisitId) {
      visitType = CarrierType.Vessel
    } else if (this.parentStore.railVisitId) {
      visitType = CarrierType.Train
    } else {
      visitType = CarrierType.Universal
    }

    if (visitType === CarrierType.Train) {
      relatedRailcars = this.railcarTrackPositionItemStore.getRailcarsTrackPositionByRailVisitId(
        visitId,
        this.parentStore.direction,
      )
    }

    if (this.file.name.toLocaleLowerCase().endsWith('.csv')) {
      if (isRestow) {
        const list = await orderListParsingService.parseRestowCsv(this.file)
        restowList = list
      } else {
        const { orderList: list, warningMessages } = await orderListParsingService.parseCsv(
          visitId,
          this.parentStore.direction,
          this.file,
          visitType,
          skipTallyLoadPrepFF,
          this.railTrackStore.activeRailTrackNameMap,
          this.parentStore.railVisit?.assignedRailTrackIds,
          relatedRailcars,
        )

        orderList = list
        this.setUploadWarningMessage(warningMessages)
      }
    } else {
      if (this.unloCodes.length)
        orderList = await orderListParsingService.parseBaplie(
          visitId,
          this.parentStore.direction,
          this.unloCodes,
          this.file,
        )
    }

    if (isRestow) {
      this.parentStore.dataStore.setRestowUnits(restowList ?? [])
    } else {
      this.parentStore.dataStore.setOrdersFromUpdate(orderList?.orders ?? [])
      this.parentStore.dataStore.setRemainOnBoard(orderList?.remainOnboard ?? [])
    }
  }

  reset = () => {
    this.setUNLOCodes([])
    this.setFile()
    this.setUploadFailedReason()
    this.setUploadWarningMessage()
  }

  setUploadWarningMessage = (messages?: string[]) => {
    this.uploadWarningMessages = messages ?? []
  }
}
