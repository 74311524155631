import { ContainerType } from '@planning/app/api'
import { action, computed, makeObservable, observable } from 'mobx'
import { mapCoolingOrder } from '../Helpers/CoolingOrderDialogUtils'
import { ContainerJourney, ContainerJourneyDataStore } from './ContainerJourneyDataStore'
import { ICoolingOrder } from './ServiceOrdersViewStore'

type CoolingOrderConfirmationDialogType = 'delete' | 'overwrite'

export class CoolingOrderDialogViewStore {
  selectedContainerJourneyIds: number[] = []
  coolingOrder?: ICoolingOrder
  isEditMode = false
  isMultiSelectMode = false
  isLoading = false
  activeDialogType?: CoolingOrderConfirmationDialogType
  isError = false

  constructor(public containerJourneyStore: ContainerJourneyDataStore) {
    makeObservable(this, {
      selectedContainerJourneyIds: observable,

      coolingOrder: observable,
      isEditMode: observable,
      isMultiSelectMode: observable,
      activeDialogType: observable,
      isLoading: observable,
      isError: observable,

      fetchContainerVisits: action,
      setSelectedContainerJourneys: action,
      reset: action,
      setCoolingOrder: action,
      setIsEditMode: action,
      setIsMultiSelectMode: action,
      setDialogOpen: action,
      setIsLoading: action,
      setIsError: action,

      containerJourneys: computed,
      isFulfilled: computed,
      showDeleteConfirmationDialog: computed,
      showOverwriteConfirmationDialog: computed,
      numberOfContainerJourneys: computed,
      numberOfContainerJourneysWithExistingCoolingOrders: computed,
      selectedContainerJourneys: computed, //This need to be computed, because containerJourneyStore.items could be reloaded, then the same journey object in selectedContainerJourneys and containerJourneyStore.items is not equal, so we have to use id for matching
    })
  }

  get selectedContainerJourneys() {
    return this.containerJourneyStore.items.filter(journey =>
      this.selectedContainerJourneyIds.includes(journey.id),
    )
  }

  get containerJourneys() {
    return this.containerJourneyStore.items.filter(
      journey => journey.container.type === ContainerType.Reefer,
    )
  }

  get numberOfContainerJourneys() {
    return this.containerJourneys.length
  }

  get numberOfContainerJourneysWithExistingCoolingOrders() {
    return this.containerJourneys.filter(journey => journey.coolingOrderId).length
  }

  get isFulfilled() {
    if (this.isMultiSelectMode || this.selectedContainerJourneys.length !== 1) return false

    const coolingOrder = this.selectedContainerJourneys[0].coolingOrder
    return !!coolingOrder && coolingOrder.status === 'Fulfilled'
  }

  get showDeleteConfirmationDialog() {
    return this.activeDialogType === 'delete'
  }

  get showOverwriteConfirmationDialog() {
    return this.activeDialogType === 'overwrite'
  }

  setIsEditMode = (isEditMode: boolean) => {
    this.isEditMode = isEditMode
  }

  setIsMultiSelectMode = (isMultiSelectMode: boolean) => {
    this.isMultiSelectMode = isMultiSelectMode
  }

  setCoolingOrder = (coolingOrder?: ICoolingOrder) => {
    this.coolingOrder = coolingOrder
  }

  setDialogOpen = (activeDialogType?: CoolingOrderConfirmationDialogType) => {
    this.activeDialogType = activeDialogType
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  setIsError = (isError: boolean) => {
    this.isError = isError
  }

  fetchContainerVisits = async (containerId: number) =>
    this.containerJourneyStore.fetch({
      containerId,
      activeOrderIds: [],
      includeCompleted: false,
      includeUnlinkedOutboundOrders: false,
    })

  fetchContainerVisitsByContainerNumbers = async (containerNumbers: string[]) => {
    this.setIsLoading(true)
    await this.containerJourneyStore.fetchByContainerNumbers(containerNumbers)
    this.setIsLoading(false)
  }

  setSelectedContainerJourneys = (selectedContainerJourneys: ContainerJourney[] = []) => {
    this.setIsEditMode(false)
    this.selectedContainerJourneyIds = selectedContainerJourneys.map(journey => journey.id)

    if (this.selectedContainerJourneys.length === 1 && !this.isMultiSelectMode) {
      const coolingOrder = this.selectedContainerJourneys[0].coolingOrder

      if (coolingOrder) {
        this.setCoolingOrder(mapCoolingOrder(coolingOrder))
        this.setIsEditMode(true)
      } else {
        this.setCoolingOrder()
      }
    } else {
      this.setCoolingOrder()
    }
  }

  reset = () => {
    this.containerJourneyStore.reset()
    this.setSelectedContainerJourneys()
    this.setCoolingOrder()
    this.setIsMultiSelectMode(false)
    this.setIsError(false)
    this.setIsLoading(false)
    this.setDialogOpen()
  }
}
