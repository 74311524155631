import { LANGUAGE_LOCALSTORAGE } from '@host/components/LangSelector'
import { DevTools, FormatSimple, Tolgee, TolgeeProvider } from '@tolgee/react'
import { AdminStoreProvider, WebAppConfigurationApi, createApiClient } from '@tom-ui/admin'
import { BillingServiceStoreProvider } from '@tom-ui/billing'
import { OperationsStoreProvider } from '@tom-ui/operations'
import { PlanningStoreProvider } from '@tom-ui/planning/AppProvider'
import {
  RootStore as StorageRootStore,
  StoreProvider as StorageStoreProvider,
} from '@tom-ui/storage'
import { ThemeProvider } from '@tom-ui/ui'
import {
  AppConfigurationProvider,
  Auth0ProviderWithHistory,
  UtilsStoreProvider,
} from '@tom-ui/utils'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import { App } from './App'
import deLang from './i18n/de.json'
import enLang from './i18n/en.json'

const defaultLanguage = localStorage.getItem(LANGUAGE_LOCALSTORAGE)

const tolgee = Tolgee()
  .use(DevTools())
  .use(FormatSimple())
  .init({
    language: defaultLanguage ?? 'en',

    // for development
    apiUrl: 'https://app.tolgee.io',
    apiKey: 'tgpak_gi2tsms7gi3ha23gnntdgnzunnrdo4teofrximbtgnsheojynu',

    // for production
    staticData: {
      en: enLang,
      de: deLang,
    },
  })

export const RoutedApp = () => (
  <ThemeProvider>
    <AppConfigurationProvider
      getAppConfig={async () =>
        await createApiClient(WebAppConfigurationApi).webAppConfigurationGet()
      }
    >
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <HelmetProvider>
            <Helmet>
              <title>Tom</title>
            </Helmet>

            <StorageStoreProvider store={new StorageRootStore()}>
              <OperationsStoreProvider>
                <PlanningStoreProvider>
                  <AdminStoreProvider>
                    <BillingServiceStoreProvider>
                      <UtilsStoreProvider>
                        <TolgeeProvider
                          tolgee={tolgee}
                          fallback='Loading...' // loading fallback
                        >
                          <App />
                        </TolgeeProvider>
                      </UtilsStoreProvider>
                    </BillingServiceStoreProvider>
                  </AdminStoreProvider>
                </PlanningStoreProvider>
              </OperationsStoreProvider>
            </StorageStoreProvider>
          </HelmetProvider>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </AppConfigurationProvider>
  </ThemeProvider>
)
