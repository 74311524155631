import { Button, Checkbox, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { getContainerTypeParameters } from '@planning/components/ContainerTypesMap'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { ErrorIcon } from 'modules/ui/src/theme/overrides/CustomIcons'
import { FC } from 'react'
import { ContainerJourney } from '../Stores/ContainerJourneyDataStore'

export interface IContainerJourneyOptionsProps {
  containerNumber?: string
  isEdited?: boolean
  options: ContainerJourney[]
  onSelect: (containerJourneys?: ContainerJourney[]) => void
  showCoolingOrderWarning?: boolean
  isMultiSelect?: boolean
  selectedOptions?: ContainerJourney[]
}

export const ContainerJourneyOptions: FC<IContainerJourneyOptionsProps> = observer(
  ({
    options,
    containerNumber,
    onSelect,
    isEdited,
    showCoolingOrderWarning,
    isMultiSelect,
    selectedOptions,
  }) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()
    const { icon } = getContainerTypeParameters('General', t)

    const renderContainerAttr = (attribute: string) => (
      <Typography variant='body2' color={theme.palette.grey[600]}>
        {attribute}
      </Typography>
    )

    return (
      <>
        {options.map(journey => {
          const inbound = journey.inboundOrder

          if (!inbound) return <></>

          if (
            containerNumber &&
            !inbound.order?.containerNumber?.toLowerCase().includes(containerNumber.toLowerCase())
          )
            return <></>

          const outbound = journey.outboundOrder

          return (
            <Stack
              sx={{
                width: '100%',
              }}
              key={journey.id}
              direction='row'
              justifyContent='space-between'
              py={1}
            >
              <Box
                sx={{
                  border: `1px solid ${theme.palette.grey[300]}`,
                  borderRadius: theme.customRadius.medium,
                  bgcolor: theme.palette.grey[200],
                  padding: theme.customSpacing.m,
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                {isMultiSelect && (
                  <Checkbox
                    checked={selectedOptions?.includes(journey)}
                    onClick={() =>
                      onSelect(
                        selectedOptions?.includes(journey)
                          ? selectedOptions.filter(s => s !== journey)
                          : [...(selectedOptions ?? []), journey],
                      )
                    }
                  />
                )}
                <Box
                  sx={{
                    maxWidth: '85%',
                    width: isMultiSelect ? '85%' : undefined,
                  }}
                >
                  <Stack direction={'row'} gap={1} alignItems={'center'}>
                    {icon}
                    <Typography variant='subtitle1'>{inbound.order?.containerNumber}</Typography>
                    {inbound.order?.containerIsoCode &&
                      renderContainerAttr(`${inbound.order?.containerIsoCode}`)}
                    {inbound.order?.containerLength &&
                      renderContainerAttr(`${inbound.order?.containerLength + 'ft'}`)}
                    {inbound.order?.containerType &&
                      renderContainerAttr(inbound.order?.containerType)}
                    {inbound.order?.containerHeight &&
                      renderContainerAttr(`${inbound.order?.containerHeight}`)}
                  </Stack>

                  <Stack direction={'row'} mt={1}>
                    <Typography variant='body2' color={theme.palette.grey[600]}>
                      <b>{t('customer', 'Customer')}: </b>
                      {inbound.order?.customerName}
                    </Typography>
                  </Stack>

                  <Stack direction={'row'} gap={1} mt={1} divider={<>|</>}>
                    <Typography variant='body2' color={theme.palette.grey[600]}>
                      <b>{t('inbound', 'Inbound')}: </b>
                      {inbound.order?.carrierVisitType === 'Truck'
                        ? inbound.order?.carrierVisitType
                        : inbound.visit?.identifier}
                    </Typography>

                    {(inbound.visit?.eta || inbound.visit?.ata) && (
                      <Typography variant='body2' color={theme.palette.grey[600]}>
                        {t('arrival', 'Arrival')}
                        {': '}
                        {formatDateTime(inbound.visit?.ata ?? inbound.visit?.eta)}
                      </Typography>
                    )}
                  </Stack>

                  {outbound && (
                    <Stack direction={'row'} gap={1} mt={1} divider={<>|</>}>
                      <Typography variant='body2' color={theme.palette.grey[600]}>
                        <b>{t('outbound', 'Outbound')}: </b>
                        {outbound.order?.carrierVisitType === 'Truck'
                          ? outbound.order?.carrierVisitType
                          : outbound.visit?.identifier}
                      </Typography>
                      {(outbound.visit?.etd || outbound.visit?.atd) && (
                        <Typography variant='body2' color={theme.palette.grey[600]}>
                          {t('departure', 'departure')}
                          {': '}
                          {formatDateTime(outbound.visit?.atd ?? outbound.visit?.etd)}
                        </Typography>
                      )}
                    </Stack>
                  )}

                  {showCoolingOrderWarning && journey.coolingOrderId && (
                    <Stack direction={'row'} gap={1} mt={1}>
                      <ErrorIcon sx={{ color: theme.palette.grey[600] }} />
                      <Typography variant='body2' color={theme.palette.grey[600]}>
                        {`${t('hasCoolingDetails', 'Has cooling details')}.`}
                      </Typography>
                    </Stack>
                  )}
                </Box>
                {!isMultiSelect && (
                  <Box>
                    <Button
                      variant='outlined'
                      color='secondary'
                      size='small'
                      onClick={() => onSelect([journey])}
                    >
                      {isEdited ? t('change', 'Change') : t('select', 'Select')}
                    </Button>
                  </Box>
                )}
              </Box>
            </Stack>
          )
        })}
      </>
    )
  },
)
