import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { CustomsResponseDto, CustomsStatus } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { FC } from 'react'
import { DeleteIconButton } from '../atoms/DeleteIconButton'
import { MultiselectCheckbox } from '../atoms/MultiselectCheckbox'

type IProps = {
  state: boolean
  customs: CustomsResponseDto
  onChangeCustoms: (customs: CustomsResponseDto) => void
  onChangeState: (checked: boolean) => void
  onDelete?: () => void
  error?: string
}

export const CustomsItem: FC<IProps> = ({
  state,
  customs,
  onChangeCustoms,
  onChangeState,
  onDelete,
  error,
}) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  return (
    <Stack direction={'row'} justifyContent={'space-between'} gap={theme.customSpacing.xs}>
      <Stack flex={1} direction={'row'} alignItems={'center'} gap={theme.customSpacing.xs}>
        <MultiselectCheckbox value={state} handleOnChange={onChangeState} />
        <TextField
          label={t('reference', 'Reference')}
          variant='outlined'
          fullWidth
          value={customs.reference}
          onChange={event =>
            onChangeCustoms({
              ...customs,
              reference: event.target.value,
            })
          }
          error={!!error}
          helperText={error ? t(error) : undefined}
        />
        {customs.status === CustomsStatus.Refused && (
          <TextField
            label={t('reason', 'Reason')}
            variant='outlined'
            fullWidth
            value={customs.reason ?? ''}
            onChange={event =>
              onChangeCustoms({
                ...customs,
                reason: event.target.value,
              })
            }
          />
        )}
        <FormControl sx={{ minWidth: 110 }}>
          <Select
            sx={{
              backgroundColor: theme.palette.background.neutral,
            }}
            size='small'
            defaultValue={customs.status}
            value={customs.status}
            onChange={(event: SelectChangeEvent) => {
              const status = event.target.value as CustomsStatus
              onChangeCustoms({
                ...customs,
                status,
                reason: undefined,
              })
            }}
          >
            <MenuItem value={CustomsStatus.Pending}>
              <Typography variant='body2' color={theme.palette.secondary.dark}>
                {t('pending', 'Pending')}
              </Typography>
            </MenuItem>
            <MenuItem value={CustomsStatus.Cleared}>
              <Typography variant='body2' color={theme.palette.secondary.dark}>
                {t('cleared', 'Cleared')}
              </Typography>
            </MenuItem>
            <MenuItem value={CustomsStatus.Refused}>
              <Typography variant='body2' color={theme.palette.secondary.dark}>
                {t('refused', 'Refused')}
              </Typography>
            </MenuItem>
          </Select>
        </FormControl>
      </Stack>
      {onDelete && <DeleteIconButton onClick={onDelete} />}
    </Stack>
  )
}
