import { Box, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { FC } from 'react'

interface IProps {
  data: Array<{ title: string; value: string | number }>
}

export const OrderListUploadAttributes: FC<IProps> = ({ data }) => {
  const theme = useMinimalsTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        padding: theme.customSpacing.m,
        mb: theme.customSpacing.l,
        bgcolor: theme.palette.grey[200],
        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
      }}
    >
      {data.map(
        (item, index) =>
          item.value && (
            <Box key={index} sx={{ mr: theme.customSpacing.m }}>
              <Typography variant='caption' color={theme.palette.grey[600]}>
                {item.title}
              </Typography>
              <Typography variant='body1' color={theme.palette.grey[600]}>
                {item.value}
              </Typography>
            </Box>
          ),
      )}
    </Box>
  )
}
