import { observer } from 'mobx-react-lite'
import { Controller } from 'react-hook-form'
import { RailcarAutocomplete } from './RailcarAutocomplete'

interface IProps {
  control: any
  errors: any
  name: string
  label: string
}

export const RailcarControlledAutocomplete = observer(
  ({ control, errors, name, label }: IProps) => {
    return (
      <Controller
        control={control}
        name={name}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <RailcarAutocomplete
            hasError={!!errors[name]}
            label={label}
            onChange={id => onChange(id ?? null)}
            value={value}
          />
        )}
      />
    )
  },
)
