import { TextFieldProps } from '@mui/material'
import { FieldValues, FormState, UseControllerProps, UseFormWatch } from 'react-hook-form'

type FieldProps = Pick<
  TextFieldProps,
  'label' | 'disabled' | 'fullWidth' | 'variant' | 'multiline' | 'maxRows' | 'minRows' | 'autoFocus'
>

export interface IControlledIsoCodeMappingInputProps<T extends FieldValues>
  extends UseControllerProps<T>,
    FieldProps {
  type?: string
  uppercase?: boolean
  endAdornment?: React.ReactNode
  formState: FormState<T>
  formArrayIndex?: number
  maxLength?: number
  required?: boolean
  watch: UseFormWatch<T>
}

export class IsoCodeMappingInputStore {
  renderIsoCodeMappingInput?: (
    props: IControlledIsoCodeMappingInputProps<any>,
  ) => React.ReactElement
}
