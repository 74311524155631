import { OrderResponseDto, OrderUpdateResponseDto } from '@planning/app/api'
import { OrderUpdateItemStore } from '@planning/rt-stores/orderUpdate/OrderUpdateItemStore'
import { action, computed, makeObservable, observable } from 'mobx'

export interface IOrderWithUpdates {
  order: OrderResponseDto | null
  update: OrderUpdateResponseDto
}

export class ReviewUpdatesDialogViewStore {
  orderIds: number[] = []
  orders: OrderResponseDto[] = []
  page = 1
  updatesPerPage = 4
  isOpen = false

  constructor(private orderUpdateItemStore?: OrderUpdateItemStore) {
    makeObservable(this, {
      orderIds: observable,
      page: observable,
      updatesPerPage: observable,
      isOpen: observable,

      setOrders: action,
      openDialog: action,
      closeDialog: action,
      setPage: action,
      markAllAsRead: action,

      updates: computed,
      paginatedUpdates: computed,
      totalPages: computed,
      updatesCount: computed,
      orderAndUpdates: computed,
      orderedOrderAndUpdates: computed,
    })
  }

  setOrders(orders: OrderResponseDto[]) {
    this.orders = orders
    this.orderIds = orders.map(i => i.id)
  }

  setPage(page: number) {
    this.page = page
  }

  markAllAsRead() {
    this.setPage(1)
    this.closeDialog()
    this.orderIds = []
    this.orders = []
  }

  openDialog() {
    this.isOpen = true
  }

  closeDialog() {
    this.isOpen = false
  }

  get updates() {
    return (
      this.orderUpdateItemStore?.unreadOrderUpdates.filter(update =>
        this.orderIds.includes(update.orderId),
      ) || []
    )
  }

  get paginatedUpdates() {
    const start = (this.page - 1) * this.updatesPerPage
    return this.orderedOrderAndUpdates.slice(start, start + this.updatesPerPage)
  }

  get totalPages() {
    return Math.ceil(this.updates.length / this.updatesPerPage)
  }

  get updatesCount() {
    return this.updates.length
  }

  get orderAndUpdates(): IOrderWithUpdates[] {
    return this.updates.map(update => {
      const order = this.orders.find(order => order.id === update.orderId)
      return {
        order: order || null,
        update,
      }
    })
  }

  get orderedOrderAndUpdates(): IOrderWithUpdates[] {
    return this.orderAndUpdates.sort((a, b) => {
      if (a.update.received !== b.update.received) {
        return a.update.received > b.update.received ? -1 : 1
      }

      const containerA = a.order?.containerNumber ?? ''
      const containerB = b.order?.containerNumber ?? ''

      return containerA.localeCompare(containerB)
    })
  }
}
