import { Button, Card, CardContent, Stack, Typography } from '@mui/material'
import { VesselVisitConflictChip } from '@planning/components/VesselVisitConflictChip'
import { IVesselVisitItem } from '@planning/rt-stores/vesselVisit/VesselVisitItem'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

interface IProps {
  vesselVisitItem: IVesselVisitItem
}

export const BerthPlanningCard = observer(({ vesselVisitItem }: IProps) => {
  const { t } = useTranslate()
  const navigate = useNavigate()

  const handleUpdate = () => {
    navigate(`/vessel-visits-planning/${vesselVisitItem.id}`)
  }

  const { berths } = vesselVisitItem

  const minBerthQuayMeterMark = vesselVisitItem.data.qmmFrom
  const maxBerthQuayMeterMark = vesselVisitItem.data.qmmTo
  const isConflicted = vesselVisitItem.data.isConflicted

  return (
    <Card>
      <CardContent>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
          <Typography variant='h4'>{t('berthPlanning', 'Berth planning')}</Typography>
          <Stack direction='row' spacing={2} alignItems={'center'}>
            {isConflicted && <VesselVisitConflictChip />}
            <Button onClick={handleUpdate}>{t('update', 'Update')}</Button>
          </Stack>
        </Stack>
        <Stack direction='column' spacing='0.25rem'>
          {!berths.length && (
            <Typography variant='body1'>
              {t('noBerthForChosenQmm', 'No existing berth for chosen QMM {qmmFrom} to {qmmTo}', {
                qmmFrom: minBerthQuayMeterMark,
                qmmTo: maxBerthQuayMeterMark,
              })}
            </Typography>
          )}
          {!!berths.length && (
            <>
              <Typography variant='body1'>
                {vesselVisitItem.berths.map(b => b.name).join(', ')}
              </Typography>
              <Typography variant='body1'>
                {t('qmm', 'QMM')}: {minBerthQuayMeterMark} {t('to', 'to')} {maxBerthQuayMeterMark}
              </Typography>
            </>
          )}
          <Typography variant='body1'>
            {vesselVisitItem.data.berthSide ?? t('unassigned', 'Unassigned')} {t('side', 'Side')}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  )
})
