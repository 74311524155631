import { Box, Button, Container, Stack } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { IRailcarItem } from '@planning/rt-stores/railcar/RailcarItem'
import { formatRailcarName } from '@planning/utils/railcar-utils'
import { useTranslate } from '@tolgee/react'
import { Header, NoListResult, PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { RailcarsCard } from './components/RailcarsCard'
import { RailcarsFormDialog } from './components/RailcarsFormDialog'
import { RailcarsViewStore } from './stores/RailcarsViewStore'

export const RailcarsPage = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { railcarItemStore, appViewStore, authStore } = usePlanningStore()

  const railcarsViewStore = useMemo(
    () => new RailcarsViewStore(railcarItemStore, appViewStore),
    [railcarItemStore, appViewStore],
  )

  useEffect(() => {
    railcarsViewStore.fetch()
  }, [railcarsViewStore])

  return (
    <Box
      sx={{
        height: '100%',
        bgcolor: theme.palette.grey[100],
      }}
    >
      <Header
        title={t('railcars', 'Railcars')}
        searchInputLabel={t('searchRailcar', 'Search railcar')}
        onSearchFieldChange={(value: string) => {
          railcarsViewStore.setFilter(value)
        }}
        displaySearchIcon
        enableSearchField
        rightRenderOption={
          authStore.hasPermission('write:rail-cars') && (
            <Stack flexDirection='row' gap={theme.customSpacing.xs}>
              <Button
                color='inherit'
                variant='contained'
                sx={{ flex: 'none' }}
                startIcon={<PlusIcon />}
                onClick={() => railcarsViewStore.toggleDialog(true)}
              >
                {t('addNew', 'Add new')}
              </Button>
            </Stack>
          )
        }
      />

      <Container
        sx={{
          paddingY: theme.customSpacing.m,
        }}
      >
        {railcarsViewStore.data
          .sort((a, b) => a.data.name.localeCompare(b.data.name))
          .map((item: IRailcarItem) => (
            <RailcarsCard
              key={item.id}
              railcarNumber={formatRailcarName(item.data.name)}
              railcarLength={item.data.length}
              onClickEdit={() => {
                railcarsViewStore.toggleDialog(true, item.data)
              }}
            />
          ))}
        {railcarsViewStore.data.length === 0 && (
          <NoListResult
            result={t('noRailcarsFound', 'No railcars found')}
            filtering={railcarsViewStore.isFiltering}
          />
        )}
      </Container>

      <RailcarsFormDialog store={railcarsViewStore} />
    </Box>
  )
})
