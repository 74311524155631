import { CustomerDto, CustomersApi } from '@storage/app/api'

export class CustomerService {
  constructor(private api: CustomersApi) {}

  async getAll(): Promise<CustomerDto[]> {
    const { data: customers } = await this.api.getAll()
    return customers
  }
}
