import { Box, Button, Paper, Stack, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { IssueWithJourney } from '@tom-ui/operations'
import { IssueStatusChip } from '@tom-ui/planning/pages/Issues/Components/atoms/IssueStatusChip'
import { ContainerVisitsCard } from '@tom-ui/planning/pages/Order/components/ContainerVisitsCard/ContainerVisitsCard'
import { observer } from 'mobx-react-lite'
import { AggregateIssuesViewStore } from '../Stores/AggregateIssuesViewStore'

interface Props {
  store: AggregateIssuesViewStore
  issue: IssueWithJourney
  hasResolve?: boolean
}
export const OperationsIssueCard = observer(({ issue, store, hasResolve }: Props) => {
  const { t } = useTranslate()

  return (
    <Paper variant='outlined'>
      <Box sx={{ padding: '1rem', display: 'flex' }}>
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          justifyContent='space-between'
          width='100%'
        >
          <Typography variant='h4'>
            {t(
              issue.issueType === 'WorkInstructionUnfinished'
                ? t('missingPosition', 'Missing position')
                : t('unknown', 'Unknown'),
            )}
          </Typography>
          <Stack direction='row' spacing={1} alignItems='center'>
            <IssueStatusChip status={issue.issueStatus} />
            {hasResolve && (
              <Button
                size='medium'
                variant='contained'
                onClick={() => store.toggleMissingPositionIssue(issue)}
              >
                {t('resolve', 'Resolve')}
              </Button>
            )}
          </Stack>
        </Stack>
      </Box>

      <ContainerVisitsCard
        containerVisit={issue.containerJourney}
        announcedVisits={[]}
        isInsideIssueCard
      />
    </Paper>
  )
})
