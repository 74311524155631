import { IssueResponseDto, IssuesApi } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'
import { Event, IEvent, IQueryHandler, IQueryMessage } from '../messageBus'

export class GetIssuesQuery implements IQueryMessage {
  static type = 'GetIssuesQuery'
  type = GetIssuesQuery.type

  getKey = () => {
    return JSON.stringify(this)
  }
}

const httpClient = createApiClient(IssuesApi)

export const getIssuesQueryHandler: IQueryHandler<
  GetIssuesQuery,
  IEvent<IssueResponseDto[]>
> = async () => {
  const { data } = await httpClient.getAll()

  return new Event(GetIssuesQuery.type, data)
}
