import { Box, Button, Grid } from '@mui/material'
import { useOpenFeatureClient } from '@openfeature/react-sdk'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitStatus, VesselVisitDto } from '@planning/app/api'
import { CustomDateTimePicker } from '@planning/components/CustomDateTimePicker'
import { CustomDateTimePickerButton } from '@planning/components/CustomDateTimePickerButton'
import { RemoveOrFinishOpenOrders } from '@planning/pages/Order'
import { IVesselVisitItem } from '@planning/rt-stores/vesselVisit/VesselVisitItem'
import { vesselVisitService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import dayjs, { Dayjs } from 'dayjs'
import { observer } from 'mobx-react-lite'
import { FC, useMemo } from 'react'
import { EditVesselVisitTimesForm } from '../Forms/EditVesselVisitTimesForm'
import { VesselVisitHeaderContainer } from '../Header/VesselVisitHeaderContainer'
import { VesselVisitTimeDetailsBox } from '../VesselVisitTimeDetailsBox'

interface Props {
  vesselVisitItem: IVesselVisitItem
}

const VesselVisitTimestamps: FC<Props> = observer(({ vesselVisitItem }) => {
  const { tenantStore } = usePlanningStore()
  const vesselVisit = vesselVisitItem.data

  if (vesselVisit.atd) {
    return <DepartedTimestamp atd={vesselVisit.atd} />
  } else if (vesselVisit.atb && !tenantStore.skipBerthTimestamp) {
    return <BerthedTimestamp vesselVisitItem={vesselVisitItem} atb={vesselVisit.atb} />
  } else if (vesselVisit.ata) {
    return <ArrivedTimestamp vesselVisitItem={vesselVisitItem} ata={vesselVisit.ata} />
  } else {
    return <EstimatedTimestamp vesselVisit={vesselVisit} />
  }
})

const DepartedTimestamp: FC<{ atd: string }> = observer(({ atd }) => {
  const { t } = useTranslate()

  return (
    <Box>
      <VesselVisitTimeDetailsBox message={t('vesselDeparted', 'Vessel departed')} time={atd} />
    </Box>
  )
})

const BerthedTimestamp: FC<{ vesselVisitItem: IVesselVisitItem; atb: string }> = observer(
  ({ vesselVisitItem, atb }) => {
    const { appViewStore, tenantStore, dialogStore } = usePlanningStore()
    const { t } = useTranslate()
    const featureFlagClient = useOpenFeatureClient()
    const isCloseOrFinishOpenOrdersWhenDepartureOn = useMemo(
      () => featureFlagClient.getBooleanValue('close-or-finish-open-orders-when-departure', false),
      [featureFlagClient],
    )
    const vesselVisit = vesselVisitItem.data

    const skipVesselVisitReadyForOperation = useMemo(
      () => tenantStore.configs?.skipVesselVisitReadyForOperation ?? false,
      [tenantStore.configs?.skipVesselVisitReadyForOperation],
    )

    return (
      <Grid container justifyContent='space-between' spacing={4}>
        <Grid item xs={12} lg={7}>
          <VesselVisitTimeDetailsBox message={t('vesselBerthed', 'Vessel berthed')} time={atb} />
        </Grid>
        <Grid item xs={12} lg={5} display='flex' justifyContent='flex-end'>
          <CustomDateTimePickerButton
            pickerButtonTestId='set-atb-btn'
            confirmButtonTestId='set-atb-confirm-btn'
            label={t('atd', 'ATD')}
            onChange={async (value: Dayjs | null) => {
              if (
                skipVesselVisitReadyForOperation === false &&
                vesselVisit.status !== CarrierVisitStatus.Completed
              ) {
                const isConfirmed = await appViewStore.setOpenConfirmDialog(
                  true,
                  t(
                    'endOfOperationsHasNotNeenRecordedAndItWillBeRecoredAsATDConfirmation',
                    'End of operations has not been recorded. The system will therefore set end of operations to the same date and time as ATD.',
                  ),
                  undefined,
                )
                if (!isConfirmed) return
              }

              const atd = dayjs(value) as any

              if (vesselVisitItem?.hasOpenOrders) {
                if (isCloseOrFinishOpenOrdersWhenDepartureOn) {
                  dialogStore.openDialog(
                    <RemoveOrFinishOpenOrders
                      visitItem={vesselVisitItem}
                      callback={async () => {
                        await vesselVisitService.put({
                          ...vesselVisit,
                          atd,
                          vesselIds: vesselVisit.carrierIds,
                        })
                      }}
                    />,
                  )
                  return
                }
              }

              await vesselVisitService.put({
                ...vesselVisit,
                atd,
                vesselIds: vesselVisit.carrierIds,
              })
            }}
            value={vesselVisit.atd}
            popperPlacement='bottom-end'
          />
        </Grid>
      </Grid>
    )
  },
)

const ArrivedTimestamp: FC<{ vesselVisitItem: IVesselVisitItem; ata: string }> = observer(
  ({ vesselVisitItem, ata }) => {
    const { t } = useTranslate()
    const { tenantStore, dialogStore, appViewStore } = usePlanningStore()
    const featureFlagClient = useOpenFeatureClient()
    const isCloseOrFinishOpenOrdersWhenDepartureOn = useMemo(
      () => featureFlagClient.getBooleanValue('close-or-finish-open-orders-when-departure', false),
      [featureFlagClient],
    )
    const skipVesselVisitReadyForOperation = useMemo(
      () => tenantStore.configs?.skipVesselVisitReadyForOperation ?? false,
      [tenantStore.configs?.skipVesselVisitReadyForOperation],
    )
    const vesselVisit = vesselVisitItem.data

    return (
      <Grid container justifyContent='space-between' spacing={4}>
        <Grid item xs={12} lg={7}>
          <VesselVisitTimeDetailsBox message={t('vesselArrived', 'Vessel arrived')} time={ata} />
        </Grid>
        <Grid item xs={12} lg={5} display='flex' justifyContent='flex-end'>
          <CustomDateTimePickerButton
            pickerButtonTestId='set-atd-btn'
            confirmButtonTestId='set-atd-confirm-btn'
            label={tenantStore.skipBerthTimestamp ? t('atd', 'ATD') : t('atb', 'ATB')}
            onChange={async (value: Dayjs | null) => {
              if (tenantStore.skipBerthTimestamp) {
                if (
                  skipVesselVisitReadyForOperation === false &&
                  vesselVisit.status !== CarrierVisitStatus.Completed
                ) {
                  const isConfirmed = await appViewStore.setOpenConfirmDialog(
                    true,
                    t(
                      'endOfOperationsHasNotNeenRecordedAndItWillBeRecoredAsATDConfirmation',
                      'End of operations has not been recorded. The system will therefore set end of operations to the same date and time as ATD.',
                    ),
                    undefined,
                  )
                  if (!isConfirmed) return
                }

                const atd = dayjs(value) as any

                if (vesselVisitItem?.hasOpenOrders) {
                  if (isCloseOrFinishOpenOrdersWhenDepartureOn) {
                    dialogStore.openDialog(
                      <RemoveOrFinishOpenOrders
                        visitItem={vesselVisitItem}
                        callback={async () => {
                          await vesselVisitService.put({
                            ...vesselVisit,
                            atd,
                            vesselIds: vesselVisit.carrierIds,
                          })
                        }}
                      />,
                    )
                    return
                  }
                }

                await vesselVisitService.put({
                  ...vesselVisit,
                  atd,
                  vesselIds: vesselVisit.carrierIds,
                })
              } else {
                const atb = dayjs(value) as any
                await vesselVisitService.put({
                  ...vesselVisit,
                  atb,
                  vesselIds: vesselVisit.carrierIds,
                })
              }
            }}
            value={vesselVisit.atd}
            popperPlacement='bottom-end'
          />
        </Grid>
      </Grid>
    )
  },
)

const EstimatedTimestamp: FC<{ vesselVisit: VesselVisitDto }> = observer(({ vesselVisit }) => {
  const { t } = useTranslate()

  return (
    <Grid container justifyContent='space-between' spacing={4}>
      <Grid item xs={12} lg={7}>
        <CustomDateTimePicker
          label={t('eta', 'ETA')}
          value={vesselVisit.eta}
          onChange={async (value: Dayjs | null) => {
            const eta = dayjs(value) as any
            await vesselVisitService.put({
              ...vesselVisit,
              eta,
              vesselIds: vesselVisit.carrierIds,
            })
          }}
          popperPlacement='bottom-end'
        />
      </Grid>
      <Grid item xs={12} lg={5} display='flex' justifyContent='flex-end'>
        <CustomDateTimePickerButton
          pickerButtonTestId='set-ata-btn'
          confirmButtonTestId='set-ata-confirm-btn'
          label={t('ata', 'ATA')}
          onChange={async (value: Dayjs | null) => {
            const ata = dayjs(value) as any
            await vesselVisitService.put({
              ...vesselVisit,
              ata,
              vesselIds: vesselVisit.carrierIds,
            })
          }}
          value={vesselVisit.ata}
          popperPlacement='bottom-end'
        />
      </Grid>
    </Grid>
  )
})

export const VesselVisitHeaderTime: FC<Props> = observer(({ vesselVisitItem }) => {
  const { drawerStore } = usePlanningStore()
  const { t } = useTranslate()
  const vesselVisit = vesselVisitItem.data

  const actions = (
    <Button
      color='info'
      size='small'
      onClick={() =>
        drawerStore.showView(<EditVesselVisitTimesForm vesselVisit={vesselVisit} />, {
          title: t('timings', 'Timings'),
          subTitle: t('vesselVisits', 'Vessel Visits'),
        })
      }
    >
      {t('view', 'View')}
    </Button>
  )

  if (!vesselVisit) return <></>

  return (
    <VesselVisitHeaderContainer title={t('timings', 'Timings')} actions={actions}>
      <VesselVisitTimestamps vesselVisitItem={vesselVisitItem} />
    </VesselVisitHeaderContainer>
  )
})
