import { Grid, Stack, TextField, Typography } from '@mui/material'
import { CustomerResponseDto } from '@planning/app/api'
import { CustomerAutoComplete } from '@planning/components/customer/CustomerAutoComplete'
import customerService from '@planning/services/customerService'
import { GeneralCargoViewStore } from '@planning/stores/generalCargo/GeneralCargoViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ICreateGeneralCargoOrderFormData } from '../CreateGeneralCargoOrderPageForm'

interface IProps {
  store: GeneralCargoViewStore
  isOrderFulfilled?: boolean
  onValidationParamsChanged: (params: any) => void
}

export const CustomerControl: FC<IProps> = observer(
  ({ store, isOrderFulfilled, onValidationParamsChanged }) => {
    const { t } = useTranslate()

    const {
      control,
      register,
      formState: { errors },
    } = useFormContext<ICreateGeneralCargoOrderFormData>()

    const [customer, setCustomer] = useState<CustomerResponseDto | undefined>()

    useEffect(() => {
      const fetch = async () => {
        const { selectedOrder } = store
        if (selectedOrder) {
          const customerId = selectedOrder.customerId
          if (customerId) {
            return await customerService.getById(customerId)
          }
        }
      }

      fetch()
        .then(customer => {
          if (customer) setCustomer(customer)
        })
        .catch(error => console.log(error.message))
    }, [store])

    return (
      <Stack gap={1}>
        <Typography variant='subtitle1'>{t('clientDetails', 'Client Details')}</Typography>
        <Grid container direction='row' columnSpacing={{ xs: 1 }}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name='customer'
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <CustomerAutoComplete
                  selectedCustomer={customer}
                  handleOnChange={customer => {
                    onChange(customer)
                    setCustomer(customer)
                    onValidationParamsChanged({ customerId: customer?.referenceId })
                  }}
                  error={!!errors.customer}
                  helperText={errors.customer ? t('fieldIsRequired', 'Field is required.') : ''}
                  disable={isOrderFulfilled}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={`${t('referenceNumber', 'Reference number')}`}
              {...register('referenceNumber', { required: true })}
              error={!!errors.referenceNumber}
              helperText={errors.referenceNumber ? t('fieldIsRequired', 'Field is required.') : ''}
              variant='outlined'
              type='text'
              name='referenceNumber'
              disabled={isOrderFulfilled}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label={`${t('lotNo', 'LOT Number')}`}
              {...register('lotNumber')}
              variant='outlined'
              type='text'
              name='lotNumber'
              disabled={isOrderFulfilled}
              onBlur={e => onValidationParamsChanged({ lotNumber: e.currentTarget.value })}
            />
          </Grid>
        </Grid>
      </Stack>
    )
  },
)
