import { TenantDto } from '@admin/app/api'
import { FormProps } from '@admin/app/models'
import { Box, FormGroup, TextField } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { notAllowPlusMinusPointSign } from '@tom-ui/utils'
import { Controller, useForm } from 'react-hook-form'
import { TenantReeferMaxUnpluggedTimeFormMapper } from './tenant-reefer-max-unplugged-time-form.mapper'
import {
  defaultValues,
  TenantReeferMaxUnpluggedTimeFormProfile,
} from './tenant-reefer-max-unplugged-time-form.profile'

interface Props extends FormProps {
  tenant?: TenantDto
  onSubmit: (formValues: TenantReeferMaxUnpluggedTimeFormProfile) => Promise<void> | void
}

export const TenantReeferMaxUnpluggedTimeForm = observer(({ id, tenant, onSubmit }: Props) => {
  const { handleSubmit, formState, control, reset } =
    useForm<TenantReeferMaxUnpluggedTimeFormProfile>({ defaultValues })

  const { t } = useTranslate()

  useEffect(() => {
    if (tenant) {
      reset(TenantReeferMaxUnpluggedTimeFormMapper.mapDtoToFormValues(tenant))
    }
  }, [reset, tenant])

  return (
    <Box
      id={id}
      component='form'
      noValidate
      autoComplete='off'
      my={1}
      onSubmit={handleSubmit(async (formValues: TenantReeferMaxUnpluggedTimeFormProfile) => {
        await onSubmit(formValues)
      })}
    >
      <FormGroup sx={{ gap: theme => theme.customSpacing.l }}>
        <Controller
          control={control}
          name='reeferContainerMaxUnpluggedTime'
          rules={{ min: 0, max: 720 }}
          render={({ field }) => (
            <TextField
              {...field}
              label={t('reeferContainerMaxUnpluggedTime', 'Reefer container max unplugged time')}
              value={field.value ?? ''}
              fullWidth
              type='number'
              onChange={event => {
                if (event.target.value.match(/[^0-9]/)) {
                  event.target.value = Math.floor(Number(event.target.value)).toString()
                }
                field.onChange(event)
              }}
              onKeyDown={notAllowPlusMinusPointSign}
              InputProps={{
                endAdornment: <CustomInputAdornment position='end'>hour</CustomInputAdornment>,
              }}
              error={!!formState.errors.reeferContainerMaxUnpluggedTime}
              helperText={
                formState.errors.reeferContainerMaxUnpluggedTime
                  ? t(
                      'fieldIsHasToBeBetweenInterval',
                      'Field has to be between interval {min} and {max}',
                      { min: 0, max: 720 },
                    )
                  : ''
              }
            />
          )}
        />
      </FormGroup>
    </Box>
  )
})
