import { tolgee } from '@storage/app/translation'
import { railTracksService } from '@storage/services'
import * as yup from 'yup'
import { RailTrackFormProfile } from './rail-tracks-form.profile'

const uniqueNameValidator = async (name: string, id?: string): Promise<boolean> => {
  try {
    const { data: isUnique } = await railTracksService.isNameUnique(name, id)
    return isUnique
  } catch (error) {
    return false
  }
}

export const schema: yup.ObjectSchema<RailTrackFormProfile> = yup.object({
  id: yup.string().optional(),
  name: yup
    .string()
    .required(tolgee.t('nameIsRequired', 'Name field is required'))
    .test(
      'is-unique-name',
      tolgee.t('RailTrackNameMustBeUnique', 'A rail track with the same name already exists'),
      async (name, context) => {
        const id = (context.parent as RailTrackFormProfile).id
        return await uniqueNameValidator(name, id)
      },
    ),
  length: yup
    .number()
    .integer(tolgee.t('lengthMustBeInteger', 'Length must be an integer'))
    .min(0, tolgee.t('lengthMustBePositive', 'Length must be a positive number'))
    .nullable(),
})
