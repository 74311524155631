import { CoolingOrderDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

interface Props {
  order: CoolingOrderDto
}

const setTimeReached = (
  setNextCheckTimeReached: React.Dispatch<React.SetStateAction<boolean>>,
  nextCheckDateTime?: string | null,
) => {
  if (!nextCheckDateTime) {
    setNextCheckTimeReached(false)
    return
  }

  if (Date.now() >= new Date(nextCheckDateTime!).getTime()) {
    setNextCheckTimeReached(true)
  } else {
    setNextCheckTimeReached(false)
  }
}

export const CoolingOrderTemperatureCheckWarning = observer(({ order }: Props) => {
  const { t } = useTranslate()
  const [nextCheckTimeReached, setNextCheckTimeReached] = useState(false)

  useEffect(
    //use for setNextCheckTimeReached immediately when nextCheckDateTime changes
    () => setTimeReached(setNextCheckTimeReached, order.nextCheckDateTime),
    [order.nextCheckDateTime],
  )

  useEffect(() => {
    //use for setNextCheckTimeReached every 5 seconds
    const intervalId = setInterval(() => {
      setTimeReached(setNextCheckTimeReached, order.nextCheckDateTime)
    }, 5000)

    return () => clearInterval(intervalId)
  }, [order])

  return (
    order.isTemperatureRequired &&
    !order.isOutboundReadyForOperations &&
    nextCheckTimeReached && (
      <ColoredLabel color='warning' label={t('temperatureCheck', 'Temperature check')} />
    )
  )
})
