import { Chip, FormControl, Stack, SxProps, Theme } from '@mui/material'
import { UnitType } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface IProps {
  value: UnitType
  sx?: SxProps<Theme>
  onChange: (unitType: UnitType) => Promise<void> | void
}

/**
 * Renders a selection of chips representing different unit types.
 * It allows users to select a unit type by clicking on the corresponding chip.
 *
 * @component
 * @param {UnitType} props.value - The currently selected unit type.
 * @param {object} [props.sx] - Optional styling to be applied to the FormControl component.
 * @param {function} props.onChange - Callback function to handle the change of the selected unit type.
 * @returns {JSX.Element} The rendered component.
 */
export const UnitTypeSelectChip: FC<IProps> = observer(({ value, sx, onChange }) => {
  const { t } = useTranslate()

  return (
    <FormControl fullWidth sx={sx}>
      <Stack direction='row' spacing={1} mb={1}>
        <Chip
          label={t('container', 'Container')}
          variant={value === UnitType.Container ? 'filled' : 'outlined'}
          color={value === UnitType.Container ? 'primary' : 'default'}
          onClick={() => onChange(UnitType.Container)}
        />
        <Chip
          label={t('swapBody', 'Swap Body')}
          variant={value === UnitType.SwapBody ? 'filled' : 'outlined'}
          color={value === UnitType.SwapBody ? 'primary' : 'default'}
          onClick={() => onChange(UnitType.SwapBody)}
        />
        <Chip
          label={t('trailer', 'Trailer')}
          variant={value === UnitType.Trailer ? 'filled' : 'outlined'}
          color={value === UnitType.Trailer ? 'primary' : 'default'}
          onClick={() => onChange(UnitType.Trailer)}
        />
      </Stack>
    </FormControl>
  )
})
