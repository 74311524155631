import { BlockLabellingPattern } from '@storage/app/api'
import { FormType } from '@storage/app/models'
import { tolgee } from '@storage/app/translation'
import * as yup from 'yup'
import IsUniqueValidator from './is-unique.validator'
import { StackingStrategy, YardBlockFormProfile } from './yard-block-form.profile'

const createAlphabeticOrNumericValidation = (patternTypeKey: string) => {
  return yup.string().when(patternTypeKey, {
    is: BlockLabellingPattern.Alphabetic,
    then: schema =>
      schema.matches(/^[A-Za-z]$/, {
        message: tolgee.t('valueMustBeASingleLetter', 'The value must be a single letter'),
        excludeEmptyString: true,
      }),
  })
}

const conditionalRequired = <T extends yup.AnySchema>(schema: T) =>
  schema.when('$type', {
    is: (type: FormType) => type === 'Add',
    then: builder => builder.optional(),
  }) as T

export const schema: yup.ObjectSchema<YardBlockFormProfile> = yup.object({
  id: yup.string().optional(),
  name: yup
    .string()
    .label('Name')
    .emptyAsUndefined()
    .required()
    .matches(/^[a-zA-Z0-9]+$/, 'yardBlockNameIncorrect')
    .test(IsUniqueValidator.name, IsUniqueValidator.errorMessage, async (name, context) => {
      const id = (context.parent as YardBlockFormProfile).id

      return await IsUniqueValidator.validate({ id, name })
    }),
  maxTier: yup.number().label('Max tier').min(1).emptyAsUndefined().required(),
  rowsPatternType: conditionalRequired(
    yup.mixed<BlockLabellingPattern>().oneOf(Object.values(BlockLabellingPattern)),
  ),
  rowsFrom: conditionalRequired(createAlphabeticOrNumericValidation('rowsPatternType')),
  rowsTo: conditionalRequired(createAlphabeticOrNumericValidation('rowsPatternType')),
  customRows: conditionalRequired(
    yup.string().when('rowsPatternType', {
      is: BlockLabellingPattern.Custom,
      then: () => yup.string(),
    }),
  ),
  baysPatternType: conditionalRequired(
    yup.mixed<BlockLabellingPattern>().oneOf(Object.values(BlockLabellingPattern)),
  ),
  baysFrom: conditionalRequired(createAlphabeticOrNumericValidation('baysPatternType')),
  baysTo: conditionalRequired(createAlphabeticOrNumericValidation('baysPatternType')),
  customBays: conditionalRequired(
    yup.string().when('baysPatternType', {
      is: BlockLabellingPattern.Custom,
      then: () => yup.string(),
    }),
  ),
  equipmentTypes: yup.array().required(),
  stackingStrategy: yup.mixed<StackingStrategy>().oneOf(Object.values(StackingStrategy)).required(),
  usageOptions: yup.array().of(yup.string().required()).optional(),
})
