import { Autocomplete, TextField } from '@mui/material'
import { RailTrackResponseDto } from '@planning/app/api'
import { observer } from 'mobx-react-lite'
import { Controller } from 'react-hook-form'

interface IProps {
  control: any
  errors: any
  name: string
  label: string
  options: RailTrackResponseDto[]
}

const RailTrackControlledAutocomplete = observer(
  ({ control, errors, name, label, options }: IProps) => {
    const singleOption = options.length === 1 ? options[0] : null

    return (
      <Controller
        control={control}
        name={name}
        defaultValue={singleOption ? singleOption.id : null}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => {
          const selectedValue = options.find(option => option.id === value) || null

          return (
            <Autocomplete
              disablePortal
              getOptionLabel={railTrack => railTrack?.name || ''}
              options={options}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              value={selectedValue}
              onChange={(_, railTrack) => {
                onChange(railTrack?.id || null)
              }}
              disabled={!!singleOption}
              renderInput={params => (
                <TextField
                  {...params}
                  data-cy='rail-track-autocomplete'
                  label={label}
                  error={!!errors[name]}
                  helperText={errors[name] ? 'Field is required.' : undefined}
                />
              )}
            />
          )
        }}
      />
    )
  },
)

export default RailTrackControlledAutocomplete
