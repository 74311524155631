import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useBooleanFlagValue } from '@openfeature/react-sdk'
import { EquipmentDto, EquipmentType, EquipmentTypes, YardBlockDto } from '@operations/app/api'
import { FormProps, SelectOption } from '@operations/app/models'
import useFormWithSchema from '@operations/hooks/use-form-with-schema.hook'
import { canBeAssignedToYardBlock } from '@operations/utils/helper'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment, useMinimalsTheme } from '@tom-ui/ui'
import _ from 'lodash'
import { useEffect, useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { ControlledYardBlock } from '../controlledYardBlock/ControlledYardBlock'
import {
  ControlledYardBlockWithBayAssignment,
  YardBlockSelectOption,
} from '../controlledYardBlock/ControlledYardBlockWithBayAssignment'
import { EquipmentFormMapper } from './equipment-form.mapper'
import { EquipmentFormProfile, defaultValues } from './equipment-form.profile'
import { schema } from './equipment-form.scheme'

interface Props extends FormProps {
  equipment?: EquipmentDto
  yardBlocks: YardBlockDto[]
  yardBlocksWithEquipmentsDictionary: Record<string, EquipmentDto[]>
  onSubmit: (formValues: EquipmentFormProfile) => Promise<void> | void
}

export const EquipmentForm = ({
  id,
  equipment,
  yardBlocks,
  yardBlocksWithEquipmentsDictionary,
  onSubmit,
}: Props) => {
  const { handleSubmit, formState, control, reset, watch } =
    useFormWithSchema<EquipmentFormProfile>(schema(), defaultValues)
  const hasEquipmentBlockAssignmentToBay = useBooleanFlagValue(
    'equipment-block-assignment-to-bay',
    false,
  )
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const equipmentType = watch('equipmentType')

  const equipmentTypeOptions: SelectOption[] = useMemo(
    () =>
      _(
        Object.values(EquipmentType)
          .filter(x => x !== EquipmentType.Sc)
          .map(x => ({
            label: t(x),
            value: x,
          })),
      )
        .sortBy(i => i.label)
        .value(),
    [],
  )

  const yardBlockOptions: YardBlockSelectOption[] = useMemo(
    () =>
      _(
        yardBlocks
          .filter(
            x =>
              x.equipmentTypes.includes(equipmentType.toString()) ||
              (x.equipmentTypes.includes(EquipmentTypes.Rs) && equipmentType === EquipmentType.Ech),
          )
          .map(({ id, name, equipmentTypes, bays }) => {
            const otherEquipmentTypesAssignedToBlock: EquipmentType[] = []
            if (
              yardBlocksWithEquipmentsDictionary[id]?.length > 0 &&
              (!equipment?.id ||
                !yardBlocksWithEquipmentsDictionary[id].some(x => x.id === equipment.id))
            ) {
              otherEquipmentTypesAssignedToBlock.push(
                ..._(yardBlocksWithEquipmentsDictionary[id].map(x => x.equipmentType))
                  .uniq()
                  .value(),
              )
            }

            return {
              label: name,
              value: id,
              yardBlockEquipmentTypes: equipmentTypes,
              otherEquipmentTypesAssignedToBlock: otherEquipmentTypesAssignedToBlock,
              currentEquipmentType: equipmentType.toString() as EquipmentType,
              bays: bays,
            }
          }),
      )
        .sortBy(i => i.label)
        .value(),
    [equipment, equipmentType, yardBlocks, yardBlocksWithEquipmentsDictionary],
  )

  useEffect(() => {
    if (equipment) {
      reset(EquipmentFormMapper.mapDtoToFormValues(equipment))
    }
  }, [reset, equipment])

  return (
    <Box id={id} component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      <FormGroup sx={{ gap: theme.customSpacing.l }}>
        <Controller
          control={control}
          name='name'
          render={({ field }) => (
            <TextField
              {...field}
              error={!!formState.errors['name']}
              label={t('name', 'Name')}
              fullWidth
              helperText={
                formState.errors['name'] ? t('fieldIsRequired', 'Field is required') : undefined
              }
            />
          )}
        />

        <FormControl fullWidth>
          <InputLabel>{t('equipmentType', 'Equipment type')}</InputLabel>
          <Controller
            control={control}
            name='equipmentType'
            render={({ field }) => (
              <Select
                {...field}
                error={!!formState.errors['equipmentType']}
                onChange={event => {
                  field.onChange(event.target.value)
                }}
                label={t('equipmentType', 'Equipment type')}
                disabled={!!equipment}
              >
                {equipmentTypeOptions.map(({ value, label }) => (
                  <MenuItem key={label} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {formState.errors['equipmentType'] && (
            <FormHelperText error>{t('fieldIsRequired', 'Field is required')}</FormHelperText>
          )}
        </FormControl>

        <Grid container spacing={theme.customSpacing.l}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name='maxWeight'
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!formState.errors['maxWeight']}
                  label={t('maxWeight', 'Max weight')}
                  value={field.value ?? ''}
                  fullWidth
                  type='number'
                  helperText={
                    formState.errors['maxWeight']
                      ? t('fieldCannotHaveANegativeValue', 'Field cannot have a negative value')
                      : undefined
                  }
                  InputProps={{
                    endAdornment: <CustomInputAdornment position='end'>kg</CustomInputAdornment>,
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              control={control}
              name='maxHeight'
              render={({ field }) => (
                <TextField
                  {...field}
                  error={!!formState.errors['maxHeight']}
                  label={t('maxHeight', 'Max height')}
                  value={field.value ?? ''}
                  fullWidth
                  type='number'
                  helperText={
                    formState.errors['maxHeight']
                      ? t('fieldCannotHaveANegativeValue', 'Field cannot have a negative value')
                      : undefined
                  }
                  InputProps={{
                    endAdornment: <CustomInputAdornment position='end'>m</CustomInputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        {hasEquipmentBlockAssignmentToBay ? (
          canBeAssignedToYardBlock(equipmentType) && (
            <Controller
              control={control}
              name={'yardBlockAssignments'}
              render={({ field }) => (
                <ControlledYardBlockWithBayAssignment
                  label={t('workAreas', 'Work areas')}
                  options={yardBlockOptions}
                  yardBlockAssignments={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          )
        ) : (
          <ControlledYardBlock<EquipmentFormProfile>
            name='yardBlockIds'
            formState={formState}
            control={control}
            label={t('workAreas', 'Work areas')}
            options={yardBlockOptions}
          />
        )}

        <Stack>
          <Typography variant='subtitle2'>{t('status', 'Status')}</Typography>
          <Controller
            control={control}
            name={'isOnMaintenance'}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} checked={field.value} />}
                label={t('unavailableForOperations', 'Unavailable for operations')}
              />
            )}
          />
        </Stack>
      </FormGroup>
    </Box>
  )
}
