import { IssueResponseDto, IssueStatus, ObjectType } from '@planning/app/api'
import { IEvent, IMessageBus } from '@planning/messages'
import { EventTypes } from '@planning/messages/eventsTypes'
import { GetIssuesQuery } from '@planning/messages/queries/getIssuesQueryHandler'
import { ContainerJourneyDataStore } from '@planning/pages/ServiceOrders/Stores/ContainerJourneyDataStore'
import { ItemStore } from '@planning/rt-stores/base/ItemStore'
import { IEntityStore } from '@planning/rt-stores/types'
import _ from 'lodash'
import { action, computed, makeObservable } from 'mobx'
import { IIssueItem, IssueItem } from './IssueItem'

export class IssueItemStore
  extends ItemStore<IssueResponseDto, IIssueItem>
  implements IEntityStore<IIssueItem>
{
  private hasBeenLoaded = false

  constructor(
    private messageBus: IMessageBus,
    private containerJourneyDataStore: ContainerJourneyDataStore,
  ) {
    super((key, data) => new IssueItem(this, containerJourneyDataStore, key, data))
    makeObservable(this, {
      orderIssues: computed,
      notResolvedItemsWithObjectTypeOrder: computed,

      receiveIssuesMessage: action,
      receiveIssueUpsertedEventMessage: action,
    })

    messageBus.subscribeEvent(GetIssuesQuery.type, this.receiveIssuesMessage)
    messageBus.subscribeEvent(EventTypes.IssueUpsertedEvent, this.receiveIssueUpsertedEventMessage)
  }

  fetch = async () => {
    if (this.hasBeenLoaded) return

    this.hasBeenLoaded = true

    await this.messageBus.dispatchQuery(new GetIssuesQuery())
  }

  get notResolvedItemsWithObjectTypeOrder() {
    return _(this.elements)
      .filter(
        i => i.data.objectType === ObjectType.Order && i.data.issueStatus !== IssueStatus.Resolved,
      )
      .value()
  }

  receiveIssuesMessage = (event: IEvent<IssueResponseDto[]>): void => {
    if (event.payload) {
      this.upsertBulk(event.payload)
    }
  }

  receiveIssueUpsertedEventMessage = (event: IEvent<IssueResponseDto>) => {
    if (event.payload) {
      this.upsert(event.payload)
    }
  }

  get orderIssues(): IssueResponseDto[] {
    return _(this.elements)
      .filter(i => i.data.objectType === ObjectType.Order)
      .map(i => i.data)
      .value()
  }
}
