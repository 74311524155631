import { createSvgIcon } from '@mui/material'

export const TrailerIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='4' y='14' width='16' height='3' rx='1' fill='currentColor' />
    <circle cx='7' cy='18' r='2.25' fill='currentColor' stroke='currentColor' strokeWidth='0.5' />
    <circle cx='12' cy='18' r='2.25' fill='currentColor' stroke='currentColor' strokeWidth='0.5' />
    <rect x='18' y='16' width='1' height='4' rx='0.5' fill='currentColor' />
    <mask id='path-5-inside-1_4419_5063' fill='white'>
      <path d='M1 6C1 5.44772 1.44772 5 2 5H22C22.5523 5 23 5.44772 23 6V14C23 14.5523 22.5523 15 22 15H2C1.44772 15 1 14.5523 1 14V6Z' />
    </mask>
    <path
      d='M1 6C1 5.44772 1.44772 5 2 5H22C22.5523 5 23 5.44772 23 6V14C23 14.5523 22.5523 15 22 15H2C1.44772 15 1 14.5523 1 14V6Z'
      fill='currentColor'
    />
    <path
      d='M2 6H22V4H2V6ZM22 6V14H24V6H22ZM22 14H2V16H22V14ZM2 14V6H0V14H2ZM2 14H2H0C0 15.1046 0.895431 16 2 16V14ZM22 14V16C23.1046 16 24 15.1046 24 14H22ZM22 6H24C24 4.89543 23.1046 4 22 4V6ZM2 4C0.89543 4 0 4.89543 0 6H2V6V4Z'
      fill='currentColor'
      mask='url(#path-5-inside-1_4419_5063)'
    />
  </svg>,
  'TrailerIcon',
)
