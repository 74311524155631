import { FormType } from '@storage/app/models'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

const CarrierVisitAllocationRuleListItemDialog = observer(() => {
  const { t } = useTranslate()
  const {
    carrierVisitAllocationRulesV2UIStore,
    manualInputValidationStore,
    weightClassContainerUIStore,
  } = useStores()

  const dialogUtilStore = carrierVisitAllocationRulesV2UIStore.listItemDialogUtilStore

  useEffect(() => {
    weightClassContainerUIStore.loadWeightClasses()
  }, [weightClassContainerUIStore])

  const formId = 'allocation-rules-form'
  const saveLabel = t('save', 'Save')
  const cancelLabel = t('cancel', 'Cancel')
  const deletionConfirmationMessage = t(
    'allocationRuleWillBeRemoved',
    'This allocation rule will be removed and you cannot undo this action!',
  )

  const getDialogLabels = (action: FormType) => {
    switch (action) {
      case 'Add':
        return {
          title: t('addAllocationRule', 'Add Allocation Rule'),
          buttons: { primary: saveLabel, secondary: cancelLabel },
        }
      case 'Edit':
        return {
          title: t('updateAllocationRule', 'Edit Allocation Rule'),
          buttons: { primary: saveLabel, secondary: cancelLabel },
        }
      case 'Delete':
        return {
          title: t('removeAllocationRule', 'Remove Allocation Rule?'),
          buttons: { primary: t('remove', 'Remove'), secondary: cancelLabel },
        }
      default:
        return { title: '', buttons: { primary: '', secondary: '' } }
    }
  }

  const dialogLabels = getDialogLabels(dialogUtilStore.dialogAction)

  const handleOnClose = () => dialogUtilStore.toggleDialog()

  const handleOnDelete = () => carrierVisitAllocationRulesV2UIStore.deleteAllocationRule()

  return (
    <ConfirmationDialog
      open={dialogUtilStore.isDialogOpen}
      title={dialogLabels.title}
      primaryActionText={dialogLabels.buttons.primary}
      closeLabel={dialogLabels.buttons.secondary}
      formId={formId}
      maxWidth='sm'
      primaryActionDisabled={
        dialogUtilStore.dialogAction !== 'Delete' && !manualInputValidationStore.isAccepted
      }
      onClose={handleOnClose}
      hasBorderTop={dialogUtilStore.dialogAction !== 'Delete'}
      blockClickOutside={dialogUtilStore.dialogAction === 'Delete'}
      message={dialogUtilStore.dialogAction === 'Delete' ? deletionConfirmationMessage : undefined}
      onConfirm={dialogUtilStore.dialogAction === 'Delete' ? handleOnDelete : undefined}
      primaryActionDataCy='allocation-confirm-btn'
    ></ConfirmationDialog>
  )
})

export default CarrierVisitAllocationRuleListItemDialog
