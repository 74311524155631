import { IssueStatus } from '@planning/app/api'
import { FilterButtonGroup } from '@planning/components/molecules/FilterButtonGroup'
import { useTranslate } from '@tolgee/react'
import { computed } from 'mobx'
import { observer } from 'mobx-react-lite'

interface Props {
  store: {
    setIssueFilterStatus: (type?: IssueStatus) => void
    issueFilterStatus?: IssueStatus
  }
  handleSetIssueFilterStatus?: (type?: IssueStatus) => void
  issuesTypeCount: {
    all: number
    unresolved: number
    followUp: number
  }
}

export const IssuesTypeFilter = observer(
  ({ store, handleSetIssueFilterStatus, issuesTypeCount }: Props) => {
    const { t } = useTranslate()

    const handleFilterChange = (key: string) => {
      if (key === 'all') {
        store.setIssueFilterStatus()
        handleSetIssueFilterStatus?.()
      } else if (key === IssueStatus.Unresolved) {
        store.setIssueFilterStatus(IssueStatus.Unresolved)
        handleSetIssueFilterStatus?.(IssueStatus.Unresolved)
      } else if (key === IssueStatus.FollowUp) {
        store.setIssueFilterStatus(IssueStatus.FollowUp)
        handleSetIssueFilterStatus?.(IssueStatus.FollowUp)
      }
    }

    const filterOptions = computed(() => {
      return [
        {
          text: t('all', 'All'),
          count: issuesTypeCount.all,
          key: 'all',
        },
        {
          text: t('unresolved', 'Unresolved'),
          count: issuesTypeCount.unresolved,
          key: IssueStatus.Unresolved,
        },
        {
          text: t('needFollowUp', 'Need follow up'),
          count: issuesTypeCount.followUp,
          key: IssueStatus.FollowUp,
        },
      ]
    }).get()

    return (
      <FilterButtonGroup
        filterOptions={filterOptions}
        selectedFilterKey={store.issueFilterStatus ?? 'all'}
        onSelectedFilterChange={handleFilterChange}
      />
    )
  },
)
