import { Box, DialogContent, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitDirection } from '@planning/app/api'
import { HoldsDrawerButton } from '@planning/components/hold/HoldsDrawerButton'
import { containerService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { ConfirmationActions, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { CreateOrderPageForm, IOrderFormData } from './components/CreateOrderPageForm'
import { SelectOrderViewStore } from './stores/SelectOrderViewStore'

interface IProps {
  forcedDirection?: CarrierVisitDirection
}

export interface IOrderRailInfo {
  railcarTrackPositionId?: number
  railTrackId?: string
  railcarId?: number
}

export const OrderDetails: FC<{
  selectOrderViewStore: SelectOrderViewStore
}> = ({ selectOrderViewStore }) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  return (
    <Box
      sx={{
        display: 'flex',
        mb: '2rem',
        p: '1rem 2rem',
        alignItems: 'center',
        bgcolor: theme.palette.grey[200],
        borderTop: '1px solid rgba(145, 158, 171, 0.24)',
        borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
      }}
    >
      <Box mr='1rem'>
        <Typography variant='subtitle1'>{t('orderDetails', 'Order Details')}</Typography>
      </Box>
      <Box>
        <ContainerVisitHoldsDrawerButton selectOrderViewStore={selectOrderViewStore} />
      </Box>
    </Box>
  )
}

export const ContainerVisitHoldsDrawerButton: FC<{
  selectOrderViewStore: SelectOrderViewStore
}> = observer(({ selectOrderViewStore }) => {
  const { inbound, outbound } = selectOrderViewStore.visitOrders

  if (!inbound && !outbound) return <></>

  if (!inbound && outbound) return <HoldsDrawerButton order={outbound} />

  if (inbound && !outbound) return <HoldsDrawerButton order={inbound} />

  return <HoldsDrawerButton order={inbound!} linkedOrder={outbound} />
})

export const EditOrdersPage: FC<IProps> = observer(({ forcedDirection }) => {
  //TODO Refactor to use a new SelectOrderViewStore with useMemo
  const { appViewStore, selectOrderViewStore, dialogStore } = usePlanningStore()
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const onEditOrderFormSubmit = async (data: IOrderFormData) => {
    const { inbound, outbound } = selectOrderViewStore.visitOrders

    const cmd = selectOrderViewStore.mapOrderFormDataToUpdateContainerVisitCommand(
      data,
      inbound,
      outbound,
    )

    try {
      await containerService.updateContainerVisit(cmd)
      appViewStore.setShowAlert('success', t('savedSuccessfully', 'Saved Successfully'))

      selectOrderViewStore.reset()
      dialogStore.close()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  return (
    <>
      <Typography variant='h4' m='1rem 0' align='center'>
        {t('editOrder', 'Edit Order')}
      </Typography>
      <DialogContent sx={{ paddingX: '0 !important' }}>
        <OrderDetails selectOrderViewStore={selectOrderViewStore} />
        <Box paddingX={theme.customSpacing.l}>
          <CreateOrderPageForm
            forcedDirection={forcedDirection}
            store={selectOrderViewStore}
            onSubmit={onEditOrderFormSubmit}
          />
        </Box>
      </DialogContent>
      <ConfirmationActions
        formId='order-form'
        primaryActionText={t('submit', 'Submit')}
        closeLabel={t('close', 'Close')}
        onClose={() => {
          dialogStore.close()
        }}
      />
    </>
  )
})
