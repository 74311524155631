import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Pagination,
  Typography,
} from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { orderService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { ReviewUpdatesDialogViewStore } from '../../Stores/ReviewUpdatesDialogViewStore'
import { ReviewUpdatesDialogCard } from '../molecules/ReviewUpdatesDialogCard'

interface ReviewUpdatesDialogProps {
  store: ReviewUpdatesDialogViewStore
}

export const ReviewUpdatesDialog: React.FC<ReviewUpdatesDialogProps> = observer(({ store }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const { appViewStore } = usePlanningStore()

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    store.setPage(value)
  }

  const onMarkAsRead = async (ids: number[]) => {
    try {
      const cmd = {
        ids,
      }

      await orderService.markOrderUpdatesAsRead(cmd)

      appViewStore.setShowAlert(
        'success',
        t('markAsReadSuccessfully', 'Marked as read successfully'),
      )

      if (store.updatesCount === 0) {
        store.markAllAsRead()
      }
    } catch (error) {
      appViewStore.setShowAlert(
        'error',
        t('failedToMarkUpdateAsRead', 'Failed to mark update as read'),
      )
    }
  }

  return (
    <Dialog open={store.isOpen} onClose={() => store.closeDialog} maxWidth='md' fullWidth>
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.grey[300]}` }}>
        <Typography variant='subtitle1'>{t('reviewUpdates', 'Review Updates')}</Typography>
        <Typography variant='body1'>
          {t('youHave', 'You have')} {store.orderAndUpdates.length}{' '}
          {store.orderAndUpdates.length === 1
            ? t('unreadUpdate', 'unread update')
            : t('unreadUpdates', 'unread updates')}
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ margin: `${theme.customSpacing.m} 0` }}>
        {store.orderAndUpdates.length === 0 ? (
          <Typography variant='body2' align='center'>
            {t('noUpdates', 'No updates available')}
          </Typography>
        ) : (
          store.paginatedUpdates.map(item => {
            return (
              <ReviewUpdatesDialogCard
                key={item.update.id}
                update={item.update}
                order={item.order}
                onMarkAsRead={() => onMarkAsRead([item.update.id])}
              />
            )
          })
        )}

        {store.orderAndUpdates.length > store.updatesPerPage && (
          <Box display='flex' justifyContent='center' mt={2}>
            <Pagination count={store.totalPages} page={store.page} onChange={handlePageChange} />
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.grey[300]}` }}>
        <Button
          onClick={() => {
            onMarkAsRead(store.updates.map(i => i.id))
            store.markAllAsRead()
          }}
          variant='contained'
          color='primary'
        >
          {t('markAllAsRead', 'Mark all as read')}
        </Button>
        <Button onClick={() => store.closeDialog()} color='secondary'>
          {t('cancel', 'Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
})
