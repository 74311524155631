import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'

interface UnsavedChangesDialogProps {
  open: boolean
  message: string
  confirmLabel: string
  cancelLabel: string
  onConfirm: () => void
  onCancel: () => void
}

const UnsavedChangesDialog = ({
  open,
  message,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
}: UnsavedChangesDialogProps) => {
  const { t } = useTranslate()

  return (
    <ConfirmationDialog
      open={open}
      title={t('unsavedChanges', 'Unsaved Changes')}
      message={message}
      primaryActionText={confirmLabel}
      closeLabel={cancelLabel}
      onClose={onConfirm}
      onConfirm={onCancel}
      maxWidth='xs'
      blockClickOutside
    />
  )
}

export default UnsavedChangesDialog
