import {
  Container,
  Fab,
  Grid,
  InputAdornment,
  List as MuiList,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import { usePlanningStore } from '@planning/AppProvider'
import { IPaginatedStore } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { IconButton, Loader, PlusIcon, SearchIcon } from '@tom-ui/ui'
import { debounce } from 'lodash'
import { observer } from 'mobx-react-lite'
import { ReactElement, ReactNode, useEffect } from 'react'
import { NoResultsView } from './NoResultsView'
import { SearchForResultsView } from './SearchForResultsView'

interface Props {
  handleCreate?: () => Promise<void> | void
  canWrite?: boolean
  children: ReactNode
  paginatedStore: IPaginatedStore<any>
  title?: string
  titleVariant?: Variant | 'inherit'
  filterable?: boolean
  customFilters?: ReactNode
  hidePaginationControl?: boolean
  startEmpty?: boolean
  defaultPageSize?: number
  noResultsText?: string
  searchForResultsText?: string
  searchPlaceholderText?: string
  isNoResults?: () => boolean
  searchField?: ReactElement
}

export const PaginatedList = observer(
  ({
    children,
    handleCreate,
    canWrite,
    paginatedStore,
    title,
    titleVariant,
    filterable,
    customFilters,
    hidePaginationControl,
    startEmpty,
    defaultPageSize,
    noResultsText,
    searchForResultsText,
    searchPlaceholderText,
    isNoResults,
    searchField,
  }: Props) => {
    const isTextFieldEmpty = () => {
      return !paginatedStore.filter?.length
    }

    const defaultOnFilterChange = debounce(async (filter: string) => {
      await paginatedStore.setFilter(filter)
    }, 300)

    const { appViewStore } = usePlanningStore()
    const { t } = useTranslate()
    useEffect(() => {
      if (defaultPageSize) paginatedStore.setPageSize(defaultPageSize)
    }, [paginatedStore, defaultPageSize])

    const defaultSearchField = () => {
      return (
        <TextField
          fullWidth
          label={t('search', 'Search')}
          placeholder={searchPlaceholderText}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          defaultValue={paginatedStore.filter}
          onChange={e => defaultOnFilterChange(e.target.value)}
        />
      )
    }

    const header = () => {
      return (
        (title && !filterable && (
          <Container
            sx={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Typography variant='h3'>{title}</Typography>
          </Container>
        )) ||
        (!title && filterable && (
          <Container
            sx={{
              display: 'flex',
              width: '100%',
              marginTop: '1rem',
            }}
          >
            {searchField ? searchField : defaultSearchField()}
          </Container>
        )) ||
        (title && filterable && (
          <Container
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              flexDirection: 'row',
              marginTop: '0.5rem',
            }}
          >
            <Grid item xs={8}>
              <Typography variant={titleVariant ?? 'h3'}>{title}</Typography>
            </Grid>
            <Grid item xs={4}>
              {searchField ? searchField : defaultSearchField()}
            </Grid>
          </Container>
        ))
      )
    }

    const emptyState = () => {
      return (
        (startEmpty && isTextFieldEmpty() && (
          <SearchForResultsView text={searchForResultsText} />
        )) || <NoResultsView text={noResultsText} />
      )
    }

    const body = () => {
      return (
        (((startEmpty && isTextFieldEmpty()) ||
          (isNoResults !== undefined ? isNoResults() : paginatedStore.totalCount === 0)) &&
          emptyState()) || (
          <div>
            <MuiList sx={{ minHeight: 200 }}>{children}</MuiList>
            {!hidePaginationControl && (
              <TablePagination
                component='div'
                onPageChange={async (_: any, newPageIndex: any) => {
                  await paginatedStore.setCurrentPageIndex(newPageIndex)
                }}
                page={paginatedStore.currentPageIndex}
                count={paginatedStore.totalCount}
                rowsPerPage={paginatedStore.pageSize}
                onRowsPerPageChange={async (event: { target: { value: string } }) => {
                  await paginatedStore.setPageSize(parseInt(event.target.value))
                }}
              />
            )}
          </div>
        )
      )
    }

    return (
      <>
        {header()}
        {customFilters && <Container sx={{ padding: '20px' }}>{customFilters}</Container>}

        <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
          {appViewStore.isLoading && <Loader show />}
          {body()}
          {handleCreate && canWrite && (
            <Fab color='primary' onClick={handleCreate}>
              <PlusIcon fontSize='large' />
            </Fab>
          )}
        </Container>
      </>
    )
  },
)
