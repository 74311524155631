import { YardBlockDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { YardSetupStore } from '@storage/pages/yard-setup/stores/yard-setup.store'
import { YardElement, YardElementType } from '@storage/pages/yard-setup/types/yard-setup.types'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Layer } from 'react-konva'
import { YardViewStore } from '../stores/YardViewStore'
import { FlexibleZoneElement } from './FlexibleZoneElement'
interface Props {
  yardStore: YardViewStore | YardSetupStore
  editable?: boolean
}

export const FlexibleZoneLayer = observer(({ yardStore, editable }: Props) => {
  const handleGeometryChange = (element: YardElement) => {
    ;(yardStore as YardSetupStore).saveTransformation(element)
  }

  const { yardBlockStackStore } = useStores()

  const handleEdit = (flexibleZone: YardBlockDto) => {
    yardStore.setSelectedFlexibleZone(flexibleZone)
    yardStore.toggleFlexibleZoneDialog()
    runInAction(() => {
      yardStore.drawerAction = 'Edit'
    })
  }

  const handleDelete = (flexibleZone: YardBlockDto) => {
    ;(yardStore as YardSetupStore).setYardBlockId(flexibleZone.id)
    ;(yardStore as YardSetupStore).toggleDeletionConfirmationDialog(YardElementType.FlexibleZone)
  }

  const handleResize = (flexibleZone: YardBlockDto, newWidth: number) => {
    ;(yardStore as YardSetupStore).saveTransformation({
      type: YardElementType.FlexibleZone,
      id: flexibleZone.id,
      geometry: {
        width: Math.round(newWidth),
      },
    })
  }

  return (
    <Layer>
      {yardStore.visibleFlexibleZones.map(flexibleZone => (
        <FlexibleZoneElement
          key={flexibleZone.id}
          flexibleZone={flexibleZone}
          inEditMode={!!editable}
          isSelected={yardStore.selectedFlexibleZone?.id === flexibleZone.id}
          stagePosition={yardStore.yardStageStore.position}
          onSelect={() => yardStore.setSelectedFlexibleZone(flexibleZone)}
          onGeometryChange={handleGeometryChange}
          onEdit={() => handleEdit(flexibleZone)}
          onDelete={() => handleDelete(flexibleZone)}
          onResize={newWidth => handleResize(flexibleZone, newWidth)}
          stacksFromYardFilter={
            !editable
              ? yardBlockStackStore.stacksMatchingFilter.filter(
                  stack => stack.blockId === flexibleZone.id,
                )
              : undefined
          }
        />
      ))}
    </Layer>
  )
})
