import { usePlanningStore } from '@planning/AppProvider'
import { NavigationStack } from '@planning/stores'
import { ContainerStackOutFunc } from '@planning/stores/gateControl/ContainerStackOutDto'
import { ValidateOutboundOrderFunc } from '@planning/stores/gateControl/ValidateOutboundDto'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { IControlledIsoCodeMappingInputProps } from '../Order/stores/IsoCodeMappingInputStore'
import { GateControlHome } from './GateControlHome'

interface IProps {
  containerStackOutFunc?: ContainerStackOutFunc
  validateOutboundRequest?: ValidateOutboundOrderFunc
  renderIsoCodeMappingInput?: (
    props: IControlledIsoCodeMappingInputProps<any>,
  ) => React.ReactElement
}

export const GateControlPage = observer(
  ({ containerStackOutFunc, validateOutboundRequest, renderIsoCodeMappingInput }: IProps) => {
    const { gateInViewStore, containerDamageReportViewStore, isoCodeMappingInputStore } =
      usePlanningStore()

    gateInViewStore.getContainersWithStackOutSequenceByReference = containerStackOutFunc
    gateInViewStore.validateOutboundRequest = validateOutboundRequest

    isoCodeMappingInputStore.renderIsoCodeMappingInput = renderIsoCodeMappingInput

    useEffect(() => {
      containerDamageReportViewStore.fetch()
    }, [containerDamageReportViewStore])

    return (
      <NavigationStack>
        <GateControlHome />
      </NavigationStack>
    )
  },
)
