import { Grid, Paper, Typography } from '@mui/material'
import { RestowResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import restowService from '@planning/services/restowService'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { RestowListItem } from '../molecules/RestowListItem'
interface Props {
  store: IPaginatedStoreWithItems<RestowResponseDto>
}

export const RestowListGroup = observer(({ store }: Props) => {
  const { t } = useTranslate()
  const { appViewStore } = usePlanningStore()

  const onDelete = async (id: number) => {
    try {
      const confirmed = await appViewStore.setOpenConfirmDialog(
        true,
        t(
          'areYouSureYouWantToDeleteThisRestowThisActionCannotBeUndone.',
          'Are you sure you want to delete this restow? This action cannot be undone.',
        ),
        t('deleteRestow', 'Delete restow'),
      )
      if (confirmed) {
        restowService.delete([id])
        appViewStore.setShowAlert(
          'success',
          t('RestowWasDeletedSuccessfully', 'Restow was deleted successfully'),
        )
      }
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToCancel', 'Failed to cancel'))
    }
  }

  return (
    <Grid container direction='column'>
      <Grid item>
        <Typography variant='h3' mb={1}>
          {t('restowList', 'Restow list')}
        </Typography>
      </Grid>

      <Paper elevation={1} style={{ borderRadius: '8px' }}>
        {store.pageItems.length > 0 ? (
          <Grid container direction='column'>
            {store.pageItems.map(item => (
              <Grid item key={`restow-item-${item.id}`}>
                <RestowListItem data={item} highlightText={store.filter} onDelete={onDelete} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant='body2' align='center' color='textSecondary' padding={'1rem'}>
            {t('thereAreNoItemsInTheList', 'There are no items in the list')}
          </Typography>
        )}
      </Paper>
    </Grid>
  )
})
