import { ContainerMovementDialogStore, ContainerMovementInfo } from '@tom-ui/utils'
import _ from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { YardBlockSlotLocation } from 'modules/operations/src/app/api'
import { ErrorCodes, InternalMoveResultDto, YardPositionDto } from 'modules/storage/src/app/api'
import { isApplicationDomainException } from 'modules/storage/src/app/http-client/interceptors/domain-exception.response-interceptor'

export enum ContainerMovementAlertType {
  DgStackingRulesViolationStackingOnTop,
  DgStackingRulesViolationMaxAllowedTier,
  DgSegregationRulesViolation,
  MixedFullAndEmpties,
  MixedDangerousAndNonDangerous,
  MixedDangerousareaNonDangerouscontainers,
  MixedNonDangerousareaDangerouscontainers,
  AllocationSpaceConflict,
  GenericError,
}

export interface InternalMovementServiceCalls {
  storageCreateInternalMoveManual: (allocationsCreateInternalMoveManualRequest: {
    containersNumbers: string[]
    yardPosition: YardPositionDto
    forceCreation: boolean
  }) => Promise<InternalMoveResultDto>

  operationsCreateInternalWorkInstruction: (createInternalWorkInstructionCommand: {
    containerNumbers: string[]
    destination: YardBlockSlotLocation[]
  }) => Promise<void>
}

export class ContainerMovementDialogViewStore {
  destination?: YardPositionDto
  isDangerousYardBlock = false
  submitAlerts: ContainerMovementAlertType[] = []
  forceCreation = false

  constructor(
    private readonly containerMovementDialogStore: ContainerMovementDialogStore,
    private readonly internalMovementServiceCalls: InternalMovementServiceCalls,
  ) {
    makeObservable(this, {
      destination: observable,
      isDangerousYardBlock: observable,
      submitAlerts: observable,
      forceCreation: observable,

      setDestination: action,
      submit: action,
      reset: action,

      hasMixedSizes: computed,
      isMovementInvalid: computed,
      alerts: computed,
    })
  }

  setDestination(destinationDto: YardPositionDto, isDangerousYardBlock: boolean) {
    this.destination = destinationDto
    this.isDangerousYardBlock = isDangerousYardBlock
  }

  get hasMixedSizes() {
    return hasMixedSizes(this.containerMovementDialogStore.containers.map(x => x.size))
  }

  get isMovementInvalid() {
    return (
      this.containerMovementDialogStore.selectedContainerNumbers.length === 0 ||
      this.containerMovementDialogStore.hasSelectedMixedSizes ||
      !this.destination?.block
    )
  }

  get alerts() {
    const alerts = getInternalMovementAlerts(
      this.containerMovementDialogStore.selectedContainers,
      this.isDangerousYardBlock,
      this.destination?.block,
    )

    return [...this.submitAlerts, ...alerts]
  }

  async submit() {
    if (!this.destination) {
      throw new Error()
    }

    const alerts = await this.submitInternalMovement(
      this.containerMovementDialogStore.selectedContainerNumbers,
      this.destination,
      this.forceCreation,
    )

    if (alerts.length > 0) {
      this.submitAlerts = alerts
      this.forceCreation = true
      throw new Error()
    }
  }

  reset() {
    this.forceCreation = false
    this.submitAlerts = []
    this.destination = undefined
    this.isDangerousYardBlock = false
  }

  submitInternalMovement = async (
    containerNumbers: string[],
    destination: YardPositionDto,
    forceCreation: boolean,
  ) => {
    const alerts = []
    try {
      //Storage
      const storageResponse =
        await this.internalMovementServiceCalls.storageCreateInternalMoveManual({
          containersNumbers: containerNumbers,
          yardPosition: destination,
          forceCreation: forceCreation,
        })

      //Operations
      const destinationDto = storageResponse.locations.map<YardBlockSlotLocation>(x => ({
        block: x.block ?? '',
        bay: x.bay ?? '',
        row: x.row ?? '',
        tier: x.tier ?? 0,
      }))

      await this.internalMovementServiceCalls.operationsCreateInternalWorkInstruction({
        containerNumbers,
        destination: destinationDto,
      })
    } catch (error: any) {
      if (isApplicationDomainException(error, ErrorCodes.DgStackingRulesViolationStackingOnTop)) {
        alerts.push(ContainerMovementAlertType.DgStackingRulesViolationStackingOnTop)
      } else if (
        isApplicationDomainException(error, ErrorCodes.DgStackingRulesViolationMaxAllowedTier)
      ) {
        alerts.push(ContainerMovementAlertType.DgStackingRulesViolationMaxAllowedTier)
      } else if (isApplicationDomainException(error, ErrorCodes.DgSegregationRulesViolation)) {
        alerts.push(ContainerMovementAlertType.DgSegregationRulesViolation)
      } else if (isApplicationDomainException(error, ErrorCodes.AllocationSpaceConflict)) {
        alerts.push(ContainerMovementAlertType.AllocationSpaceConflict)
      } else {
        alerts.push(ContainerMovementAlertType.GenericError)
      }
    }

    return alerts
  }
}

//Test functions
export const hasMixedSizes = (sizes: number[]) => {
  return _.uniq(sizes).length > 1
}

export const getInternalMovementAlerts = (
  containers: ContainerMovementInfo[],
  isDangerousYardBlock: boolean,
  yardBlock: string | null | undefined,
) => {
  const alerts = []

  //State
  if (containers.find(x => x.isEmpty) && containers.find(x => !x.isEmpty)) {
    alerts.push(ContainerMovementAlertType.MixedFullAndEmpties)
  }

  //Mix Dangerous
  if (containers.find(x => x.isDangerous) && containers.find(x => !x.isDangerous)) {
    alerts.push(ContainerMovementAlertType.MixedDangerousAndNonDangerous)
  }

  //Mix Dangerous yard and Non dangerous containers
  if (yardBlock && isDangerousYardBlock && containers.some(x => !x.isDangerous)) {
    alerts.push(ContainerMovementAlertType.MixedDangerousareaNonDangerouscontainers)
  }

  //Mix Non dangerous yard and dangerous containers
  if (yardBlock && !isDangerousYardBlock && containers.some(x => x.isDangerous)) {
    alerts.push(ContainerMovementAlertType.MixedNonDangerousareaDangerouscontainers)
  }

  return alerts
}
