import {
  AllocationDestinationAndStackingStrategyDto,
  CarrierVisitAllocationRuleDto,
  CarrierVisitDirection,
  ContainerTurnoverDto,
  CreateAllocationRuleDto,
  GlobalAllocationRuleDto,
} from '@storage/app/api'
import { AllocationRuleTemplatesV2Store } from '@storage/features/carrier-visit-allocation-rules/stores/allocation-rule-templates-v2.store'
import { DialogUtilStore } from '@storage/stores/dialog.util-store'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { GlobalAllocationRuleStore } from './global-allocation-rules.store'
import { getFilteredTurnovers } from '@storage/pages/yard-planning-dashboard-details/utils/turnovers-filtering.util'
import { WeightClassStore } from '@storage/features/weight-classes/stores/weight-class.store'
import { AllocationRuleTemplateFormProfile } from '@storage/features/carrier-visit-allocation-rules/forms/allocation-rule-templates-form/allocation-rule-templates-form.profile'
import { mapAllocationRuleDtoToCreateAllocationRuleDto } from '@storage/features/carrier-visit-allocation-rules/mappers'
import {
  mapAllocationRuleTemplateDtoToGlobalAllocationRuleDto,
  mapAllocationRuleTemplateFormProfileToGlobalAllocationRuleDto,
} from '../mappers'
import { BaseAllocationRulesUIStore } from '@storage/stores/base-allocation-rules.ui-store'
import { UnallocatedTurnoversStore } from '@storage/stores/unallocated-turnovers.store'

type UnsavedChangesDialogContext = 'direction' | 'generic'
export class GlobalAllocationRulesUIStore extends BaseAllocationRulesUIStore<GlobalAllocationRuleDto> {
  activeHandlingDirection: CarrierVisitDirection = CarrierVisitDirection.Outbound

  isDeletionConfirmationDialogOpen = false
  deletionDialogEntityId?: string

  isUnsavedChangesDialogOpen = false
  unsavedChangesDialogContext?: UnsavedChangesDialogContext

  isRequestLoading = false

  constructor(
    private readonly _globalAllocationRuleStore: GlobalAllocationRuleStore,
    private readonly _unallocatedTurnoversStore: UnallocatedTurnoversStore,
    private readonly _allocationRuleTemplatesStore: AllocationRuleTemplatesV2Store,
    private readonly _weightClassStore: WeightClassStore,
    public readonly allocationRuleTemplatesDialogUtilStore: DialogUtilStore,
  ) {
    super()
    makeObservable(this, {
      activeHandlingDirection: observable,
      isDeletionConfirmationDialogOpen: observable,
      deletionDialogEntityId: observable,
      isUnsavedChangesDialogOpen: observable,
      unsavedChangesDialogContext: observable,
      isRequestLoading: observable,

      setActiveHandlingDirection: action,
      toggleUnsavedChangesDialog: action,
      addAllocationRule: action,
      updateAllocationRule: action,
      deleteAllocationRule: action,
      toggleDeletionConfirmationDialog: action,
      setRequestLoadingState: action,
      reorderGlobalAllocationRules: action,
      copyAllocationRuleTemplateToGlobalRule: action,
      filterTurnoversByAllocationRule: action,
    })
  }

  async loadTurnoversByDirection() {
    await this._unallocatedTurnoversStore.loadTurnovers(this.activeHandlingDirection)
  }
  async loadAllocationRules() {
    await this._globalAllocationRuleStore.loadAll(this.activeHandlingDirection)
  }

  get allocationRules(): GlobalAllocationRuleDto[] {
    return this._globalAllocationRuleStore.entries
  }

  getAllocationRuleById(id: string) {
    return this._globalAllocationRuleStore.getById(id)
  }

  handleDestinationsHighlight() {
    super.handleDestinationsHighlight(this.allocationRules)
  }

  toggleUnsavedChangesDialog(context?: UnsavedChangesDialogContext) {
    this.isUnsavedChangesDialogOpen = !this.isUnsavedChangesDialogOpen
    this.unsavedChangesDialogContext = context
  }

  addAllocationRule(formValues: AllocationRuleTemplateFormProfile) {
    const globalAllocationRule = mapAllocationRuleTemplateFormProfileToGlobalAllocationRuleDto(
      formValues,
      this.activeHandlingDirection,
    )

    this._globalAllocationRuleStore.addOrUpdate(globalAllocationRule)

    this.filterTurnoversByAllocationRule(
      globalAllocationRule,
      this._unallocatedTurnoversStore.tempContainerTurnovers,
    )

    this.setSelectedAllocationRule(globalAllocationRule)

    this.hideAllocationRuleForm()
  }

  updateAllocationRule(formValues: AllocationRuleTemplateFormProfile) {
    const globalAllocationRule = mapAllocationRuleTemplateFormProfileToGlobalAllocationRuleDto(
      formValues,
      this.activeHandlingDirection,
    )

    this._globalAllocationRuleStore.addOrUpdate(globalAllocationRule)

    const allocatedTurnovers = this._unallocatedTurnoversStore.containerTurnovers.filter(
      x => x.isAllocated,
    )

    this._unallocatedTurnoversStore.resetTempTurnovers(allocatedTurnovers)
    let turnovers = this._unallocatedTurnoversStore.tempContainerTurnovers
    this.allocationRules.forEach(rule => {
      turnovers = this.filterTurnoversByAllocationRule(rule, turnovers)
    })

    this.setSelectedAllocationRule(globalAllocationRule)

    this.hideAllocationRuleForm()
  }

  toggleDeletionConfirmationDialog = (dialogEntityId?: string) => {
    this.deletionDialogEntityId = dialogEntityId
    this.isDeletionConfirmationDialogOpen = !this.isDeletionConfirmationDialogOpen
  }

  deleteAllocationRule() {
    if (!this.deletionDialogEntityId) return

    this._globalAllocationRuleStore.remove(this.deletionDialogEntityId)

    const allocationRuleTurnovers = this.getAllocationSummary(this.deletionDialogEntityId)

    if (allocationRuleTurnovers) {
      this._unallocatedTurnoversStore.resetTempTurnovers(allocationRuleTurnovers)
    }

    let turnovers = this._unallocatedTurnoversStore.tempContainerTurnovers

    this.allocationRules.forEach(rule => {
      turnovers = this.filterTurnoversByAllocationRule(rule, turnovers)
    })

    // Deselect the allocation rule
    this.setSelectedAllocationRule()

    // Close the dialog
    this.toggleDeletionConfirmationDialog()
  }

  setRequestLoadingState(isLoading: boolean) {
    this.isRequestLoading = isLoading
  }

  async persistAllChanges() {
    this.setRequestLoadingState(true)

    const allocationRules: CreateAllocationRuleDto[] = this.allocationRules.map((rule, index) => {
      const turnovers = this.allocationRuleSummaries.get(rule.id)
      return mapAllocationRuleDtoToCreateAllocationRuleDto(rule, index + 1, turnovers ?? [])
    })

    return await this._globalAllocationRuleStore.saveChanges(
      allocationRules,
      this.activeHandlingDirection,
    )
  }

  reorderGlobalAllocationRules(startIndex: number, endIndex: number) {
    this._globalAllocationRuleStore.reorder(startIndex, endIndex)
  }

  async setActiveHandlingDirection(direction?: CarrierVisitDirection) {
    this.activeHandlingDirection = direction ?? CarrierVisitDirection.Outbound
    await this.loadTurnoversByDirection()
  }

  getAllocationSummary(allocationRuleId: string) {
    return this.allocationRuleSummaries.get(allocationRuleId)
  }

  copyAllocationRuleTemplateToGlobalRule(
    allocationRuleId: string,
    destinationAndStackingStrategies: AllocationDestinationAndStackingStrategyDto[],
  ) {
    const allocationRuleTemplate = this._allocationRuleTemplatesStore.getById(allocationRuleId)
    if (!allocationRuleTemplate) return

    const globalAllocationRule = mapAllocationRuleTemplateDtoToGlobalAllocationRuleDto(
      allocationRuleTemplate,
      destinationAndStackingStrategies,
      this.activeHandlingDirection,
    )

    this._globalAllocationRuleStore.addOrUpdate(globalAllocationRule)

    // Filter turnovers
    let turnovers = this._unallocatedTurnoversStore.containerTurnovers

    this.allocationRules.forEach(rule => {
      turnovers = this.filterTurnoversByAllocationRule(rule, turnovers)
    })

    this.setSelectedAllocationRule(globalAllocationRule)

    // Close the dialog
    this.allocationRuleTemplatesDialogUtilStore.toggleDialog()
  }

  get isGlobalAllocationRulesRequestLoading() {
    return this._globalAllocationRuleStore.isLoading
  }

  get unallocatedTurnovers() {
    return this._unallocatedTurnoversStore.unAllocatedTurnovers
  }

  filterTurnoversByAllocationRule(
    allocationRule: CarrierVisitAllocationRuleDto,
    containerTurnovers: ContainerTurnoverDto[],
  ) {
    const filteredTurnovers = getFilteredTurnovers(
      allocationRule,
      containerTurnovers,
      this._weightClassStore.entries,
    )
    const remainingTurnovers = containerTurnovers.filter(
      turnover => !filteredTurnovers.includes(turnover),
    )

    this.setAllocationRuleSummary(allocationRule.id, filteredTurnovers)
    this._unallocatedTurnoversStore.setTempTurnovers(remainingTurnovers)
    return remainingTurnovers
  }

  reset() {
    runInAction(() => {
      this.activeHandlingDirection = CarrierVisitDirection.Outbound
    })

    this._globalAllocationRuleStore.resetData()
    this._unallocatedTurnoversStore.resetTurnovers()
    this.setShowDestinationForm(false)
    this.setShowAllocationRuleForm(false)
  }
}
