import { Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'

interface Props {
  label: string
  value?: string | number
  children?: ReactNode
  nowrap?: boolean
  dataCy?: string
}
export const Tile = ({ label, value, children, nowrap, dataCy }: Props) => (
  <Stack>
    <Typography variant='caption' whiteSpace={nowrap ? 'nowrap' : 'normal'}>
      {label}
    </Typography>
    {children ?? (
      <Typography variant='subtitle2' whiteSpace={nowrap ? 'nowrap' : 'normal'} data-cy={dataCy}>
        {value}
      </Typography>
    )}
  </Stack>
)
