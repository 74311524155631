import { CreateInternalWorkInstructionCommand, WorkInstructionsApi } from '@operations/app/api'
import { createApiClient } from '@operations/app/http-client'

class WorkInstructionService {
  httpClient = createApiClient(WorkInstructionsApi)

  createInternalWorkInstruction = async (
    createInternalWorkInstructionCommand: CreateInternalWorkInstructionCommand,
  ) => {
    await this.httpClient.createInternalWorkInstruction(createInternalWorkInstructionCommand)
  }
}

const workInstructionService = new WorkInstructionService()

export default workInstructionService
