import { ImportContacts } from '@mui/icons-material'
import { Box, LinearProgress, Typography } from '@mui/material'
import { CarrierVisitDirection } from '@storage/app/api/api'
import { AccordionContainer } from '@storage/components/AccordionContainer'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import DraggableAllocationRuleListItem from './DraggableAllocationRuleListItem'
import { Stack } from '@mui/system'
import { useMinimalsTheme } from '@tom-ui/ui'
import { TextBox } from '@storage/components/TextBox'

interface GlobalRulesAccordionContainerProps {
  tooltip: string
  carrierVisitDirection: CarrierVisitDirection
  onImport?: () => void
  editable?: boolean
}

export const GlobalRulesAccordionContainer = observer(
  ({ tooltip, onImport, editable, carrierVisitDirection }: GlobalRulesAccordionContainerProps) => {
    const { t } = useTranslate()
    const { globalAllocationRulesUIStore } = useStores()
    const theme = useMinimalsTheme()

    // Troubleshoot mobx reactivity with dnd
    useEffect(() => undefined, [globalAllocationRulesUIStore.allocationRules])

    const onDragEnd = (result: DropResult) => {
      if (!result.destination) return

      globalAllocationRulesUIStore.reorderGlobalAllocationRules(
        result.source.index,
        result.destination.index,
      )
    }

    const renderLoadingState = () => (
      <TextBox
        text={t('loadingGlobalAllocationRules', 'Loading global allocation rules...')}
        isLoading
      />
    )

    const renderEmptyState = () => (
      <TextBox text={t('noGlobalAllocationRulesRunning', 'No global allocation rules running')} />
    )

    const renderAllocationRules = () => (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId='allocationRulesList'>
          {provided => (
            <Box
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{
                overflowY: 'auto',
                overflowX: 'hidden',
                maxHeight: '35vh',
              }}
            >
              {globalAllocationRulesUIStore.allocationRules.map((allocationRule, index, list) => (
                <DraggableAllocationRuleListItem
                  uiStore={globalAllocationRulesUIStore}
                  key={allocationRule.id}
                  allocationRule={allocationRule}
                  index={index}
                  isLast={index === list.length - 1}
                  variant='global'
                  editable={editable}
                  carrierVisitDirection={carrierVisitDirection}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    )

    const renderContent = () => {
      if (globalAllocationRulesUIStore.isGlobalAllocationRulesRequestLoading) {
        return renderLoadingState()
      }

      return globalAllocationRulesUIStore.allocationRules.length
        ? renderAllocationRules()
        : renderEmptyState()
    }

    return (
      <AccordionContainer
        title={t('globalRules', 'Global rules')}
        defaultExpanded={true}
        tooltip={tooltip}
        action={
          onImport
            ? {
                icon: <ImportContacts />,
                onClick: onImport,
              }
            : undefined
        }
      >
        {renderContent()}
      </AccordionContainer>
    )
  },
)
