import { Button, Stack, Typography } from '@mui/material'
import { CarrierVisitDirection, CarrierVisitDto, ErrorCodes } from '@storage/app/api'
import { isApplicationDomainException } from '@storage/app/http-client/interceptors/domain-exception.response-interceptor'
import UnsavedChangesDialog from '@storage/features/carrier-visit-allocation-rules/components/UnsavedChangesDialog'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { ChevronRightIcon } from '@tom-ui/ui'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'
import { useGoBack } from '../hooks/use-go-back.hook'
import { useNavigationWithUnsavedChanges } from '../hooks/use-navigation-with-unsaved-changes.hook'
import InlineTextValue from '@storage/components/InlineTextValue'
import { PageHeader } from '@storage/components/PageHeader'

interface Props {
  carrierVisit: CarrierVisitDto
  direction: CarrierVisitDirection
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString)
  return format(date, 'H:mm, dd/MM/yyyy')
}

const CarrierVisitDetails = observer(({ carrierVisit, direction }: Props) => {
  const { t } = useTranslate()
  const { carrierVisitAllocationRulesV2UIStore, snackbarStore } = useStores()

  const showActionButtons =
    !carrierVisitAllocationRulesV2UIStore.showAllocationRuleForm &&
    !carrierVisitAllocationRulesV2UIStore.showDestinationForm

  const handleGoBack = useGoBack()

  const {
    showUnsavedChangesDialog,
    handleNavigationAttempt,
    handleConfirmNavigation,
    handleCancelNavigation,
  } = useNavigationWithUnsavedChanges({
    onConfirmNavigation: handleGoBack,
  })

  const handleSave = () => {
    carrierVisitAllocationRulesV2UIStore
      .persistAllChanges(carrierVisit.id, direction)
      .then(() => {
        snackbarStore.showMessage(
          t('changesSaveSuccess', 'All changes are saved successfully'),
          'success',
        )
        handleGoBack()
      })
      .catch(error => {
        if (isApplicationDomainException(error, ErrorCodes.AllocationSpaceConflict)) {
          snackbarStore.showMessage(
            t('insufficientAllocationSpace', 'Insufficient allocation space'),
            'error',
          )
        } else {
          // Generic error
          snackbarStore.showMessage(
            t('persistAllChangesFailure', 'An unexpected error occurred while saving the changes'),
            'error',
          )
        }
      })
      .finally(() => carrierVisitAllocationRulesV2UIStore.setPersistAllChangesLoadingState(false))
  }

  const getDateDetail = () => {
    if (carrierVisit?.status === 'Expected' && carrierVisit?.eta) {
      return <InlineTextValue title={t('carrierEta', 'ETA')} value={formatDate(carrierVisit.eta)} />
    }
    if (carrierVisit?.status === 'Arrived' && carrierVisit?.ata) {
      return <InlineTextValue title={t('carrierAta', 'ATA')} value={formatDate(carrierVisit.ata)} />
    }
    if (carrierVisit?.status === 'InOperation' && carrierVisit.etd) {
      return <InlineTextValue title={t('carrierEtd', 'ETD')} value={formatDate(carrierVisit.etd)} />
    }
    return null
  }

  return (
    <>
      <PageHeader>
        <Stack direction='row' gap={1} alignItems='center'>
          <Typography variant='subtitle1'>{t('yardPlanning', 'Yard Planning')}</Typography>

          <ChevronRightIcon />

          <Typography variant='subtitle1' data-cy='yard-planning-carrier-name'>
            {carrierVisit?.name}
          </Typography>

          {getDateDetail()}

          {carrierVisit?.berths.length > 0 && (
            <InlineTextValue title={t('berth', 'Berth')} value={carrierVisit?.berths.join(', ')} />
          )}

          {carrierVisit?.qmmFrom && carrierVisit?.qmmTo && (
            <InlineTextValue
              title={t('meterMarks', 'MM')}
              value={`${carrierVisit.qmmFrom}-${carrierVisit.qmmTo}m`}
            />
          )}
        </Stack>

        {showActionButtons && (
          <Stack flexDirection='row' gap={1}>
            <Button onClick={handleNavigationAttempt}>{t('cancel', 'Cancel')}</Button>
            <Button
              onClick={handleSave}
              variant='contained'
              data-cy='yard-planning-btn-save'
              disabled={carrierVisitAllocationRulesV2UIStore.persistAllChangesIsLoading}
            >
              {carrierVisitAllocationRulesV2UIStore.persistAllChangesIsLoading
                ? t('savingChanges', 'Saving Changes...')
                : t('save', 'Save')}
            </Button>
          </Stack>
        )}
      </PageHeader>

      <UnsavedChangesDialog
        open={showUnsavedChangesDialog}
        message={t(
          'unsavedChangesLeaveMessage',
          'You have unsaved changes. Are you sure you want to leave? Your changes will be lost.',
        )}
        confirmLabel={t('stay', 'Stay')}
        cancelLabel={t('leaveAndDiscard', 'Leave & Discard')}
        onConfirm={handleConfirmNavigation}
        onCancel={handleCancelNavigation}
      />
    </>
  )
})

export default CarrierVisitDetails
