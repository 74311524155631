import { Paper, Stack, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, ReactNode } from 'react'

interface Props {
  title: string
  body?: string
  actions?: ReactNode[]
}

export const ItemCard: FC<Props> = observer(({ title, body, actions }) => {
  const theme = useMinimalsTheme()

  return (
    <Paper variant='outlined' sx={{ mt: 1, p: theme.customSpacing.xs }}>
      <Stack sx={{ gap: theme.customSpacing.xs }}>
        <Typography variant='subtitle1'>{title}</Typography>
        {body && <Typography variant='body2'>{body}</Typography>}
        {/* <Box> */}
        <Stack direction={'row'} gap={theme.customSpacing.xs}>
          {actions}
        </Stack>
      </Stack>
    </Paper>
  )
})
