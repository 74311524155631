import {
  CarrierType,
  CarrierVisitDirection,
  ContainerDto,
  OrderResponseDto,
  RailVisitResponseDto,
  TransportData,
  TruckVisitDto,
  UpdateContainerVisitCommand,
  VesselVisitDto,
} from '@planning/app/api'
import { ContainerJourneyDataStore } from '@planning/pages/ServiceOrders/Stores/ContainerJourneyDataStore'
import { OrderItemStore } from '@planning/rt-stores/order/OrderItemStore'
import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { IFormCargoData, IOrderFormData } from '../components/CreateOrderPageForm'
import { EditOrCreateOrderViewStore } from './EditOrCreateOrderViewStore'

export interface IOrderRailInfo {
  railcarTrackPositionId?: number
  railTrackId?: string
  railcarId?: number
}

export interface IOrderWithVisit {
  order?: OrderResponseDto
  visit?: TruckVisitDto | VesselVisitDto | RailVisitResponseDto
}

// @deprecated Use containerJourneyDataStore instead
export type IAmAContainerVisitToo = [
  inboundOrder?: IOrderWithVisit,
  outboundOrder?: IOrderWithVisit,
]

export class SelectOrderViewStore {
  selectedContainer?: ContainerDto
  selectedContainerId = 0
  selectedContainerVisit?: IAmAContainerVisitToo

  editOrCreateOrderStore: EditOrCreateOrderViewStore

  constructor(
    // [TODO]: Remove OrderItemStore
    private dataStore: OrderItemStore,
    public containerJourneyDataStore: ContainerJourneyDataStore,
  ) {
    makeObservable(this, {
      selectedContainer: observable,
      selectedContainerId: observable,
      selectedContainerVisit: observable,

      visitOrders: computed,
      isNoMatches: computed,

      selectContainer: action,
      setContainerId: action,
      selectContainerVisit: action,
    })

    reaction(
      () => this.selectedContainer,
      async container => {
        if (container?.number) {
          this.dataStore.setActiveOrderIdsByContainerNumber(
            container.number,
            container.activeOrderIds,
          )
        }

        if (container?.activeOrderIds.length) {
          this.dataStore.fetchByIds(container.activeOrderIds).catch(error => {
            // TODO: Handle error properly
            console.log(error)
          })
        }
      },
    )

    this.editOrCreateOrderStore = new EditOrCreateOrderViewStore(this)
  }

  get isNoMatches() {
    return !this.selectedContainer?.activeOrderIds.length
  }

  get containerJourneyListItems() {
    return [...this.containerJourneyDataStore.items]
  }

  get visitOrders() {
    if (!this.selectedContainerVisit) return { inbound: undefined, outbound: undefined }

    return {
      inbound: this.selectedContainerVisit[0]?.order,
      outbound: this.selectedContainerVisit[1]?.order,
    }
  }

  selectContainer = (container?: ContainerDto) => {
    this.selectedContainer = container
    this.setContainerId(container?.id ?? 0)
  }

  setContainerId = (id: number) => {
    this.selectedContainerId = id
  }

  selectContainerVisit = (containerVisit?: IAmAContainerVisitToo) => {
    this.selectedContainerVisit = containerVisit
  }

  reset = () => {
    this.selectContainer()
    this.selectContainerVisit()
    this.setContainerId(0)
    // this.containerJourneyDataStore.reset()
  }

  mapOrderFormDataToUpdateContainerVisitCommand(
    formData: IOrderFormData,
    inbound?: OrderResponseDto,
    outbound?: OrderResponseDto,
  ) {
    const cmd: UpdateContainerVisitCommand = {
      inboundOrderId: formData.inboundCarrierType ? formData.inboundOrderId : undefined,
      outboundOrderId: formData.outboundCarrierType ? formData.outboundOrderId : undefined,
      containerId: formData.containerId,
      containerNumber: formData.containerNumber ?? '',
      containerIsoCode: formData.containerIsoCode ?? '',
      referenceNumber: formData.referenceNumber,
      notes: formData.notes,
      operationalInstructions: formData?.operationalInstructions,
      consignee: formData.consignee,
      customerId: formData.customer?.id,
      inboundTransportData: this.getTransportData(CarrierVisitDirection.Inbound, formData, inbound),
      outboundTransportData: this.getTransportData(
        CarrierVisitDirection.Outbound,
        formData,
        outbound,
      ),
      inboundCargo: this.getCargoData(formData.inboundCargo),
      outboundCargo: this.getCargoData(formData.outboundCargo),
      inboundHasSeals: formData.inboundHasSeals,
      outboundHasSeals: formData.outboundHasSeals,
      inboundSeals: formData.inboundSeals,
      outboundSeals: formData.outboundSeals,
      outboundCustoms: formData.outboundCustoms?.map(customs => ({ ...customs })),
    }

    return cmd
  }

  getOrderRailInfo(
    direction: CarrierVisitDirection,
    formData: IOrderFormData,
  ): IOrderRailInfo | null {
    if (
      direction === CarrierVisitDirection.Inbound &&
      formData.inboundCarrierType === CarrierType.Train
    ) {
      return {
        railcarTrackPositionId: formData.inboundRailcarTrackPositionId,
        railTrackId: formData.inboundRailTrackId ?? undefined,
        railcarId: formData.inboundRailcarId ?? undefined,
      }
    }
    if (
      direction === CarrierVisitDirection.Outbound &&
      formData.outboundCarrierType === CarrierType.Train
    ) {
      return {
        railcarTrackPositionId: formData.outboundRailcarTrackPositionId,
        railTrackId: formData.outboundRailTrackId ?? undefined,
        railcarId: formData.outboundRailcarId ?? undefined,
      }
    }

    return null
  }

  getCargoData(cargo?: IFormCargoData) {
    if (!cargo || cargo.isEmpty) {
      return undefined
    }

    return {
      imoClasses: cargo?.imoClasses,
      temperature: cargo?.temperature,
      dangerousGoodsDefinitionIds:
        cargo?.dangerousGoodsDefinitions?.map(dgd => dgd.id as number) ?? [],
      grossWeight: cargo?.grossWeight,
      content: cargo?.content,
      vgm: cargo?.vgm,
    }
  }

  getTransportData(
    direction: CarrierVisitDirection,
    formData: IOrderFormData,
    order?: OrderResponseDto,
  ): TransportData | undefined {
    const carrierType =
      direction === CarrierVisitDirection.Inbound
        ? formData.inboundCarrierType
        : formData.outboundCarrierType

    if (!carrierType) return

    const visitId =
      direction === CarrierVisitDirection.Inbound
        ? formData.inboundVisit?.id
        : formData.outboundVisit?.id

    const orderRailInfo = this.getOrderRailInfo(direction, formData)

    return {
      carrierType: carrierType,
      carrierVisitId: this.getVisitId(carrierType, order, visitId),
      releaseExpired:
        direction === CarrierVisitDirection.Outbound ? formData.releaseExpires : undefined,
      railcarTrackPositionId: orderRailInfo?.railcarTrackPositionId,
      railTrackId: orderRailInfo?.railTrackId,
      railcarId: orderRailInfo?.railcarId,
      doorDirection:
        direction === CarrierVisitDirection.Outbound ? formData?.doorDirection : undefined,
    }
  }

  getVisitId(carrierType: CarrierType, order?: OrderResponseDto, visitId?: number) {
    if (carrierType === CarrierType.Truck) {
      // Keep truck id if already truck. Remove id for other types of carriers bacause we switched types
      if (order?.carrierVisitType === CarrierType.Truck) {
        return order.carrierVisitId
      }
      return undefined
    }

    return visitId
  }
}
