import { ContainerPositionType, JobDto } from '@operations/app/api'
import { DoorDirectionInfo } from '../JobInformation/DoorDirectionInfo'
import { JobInformationJourney } from '../JobInformation/JobInformationJourney'
import { JobInformationOperationalInstructions } from '../JobInformation/JobInformationOperationalInstructions'
import { JobInformationWrapper } from '../JobInformation/JobInformationWrapper'
import { ConfirmPicks } from './ConfirmPicks'
import { ConfirmPickUpProps } from './confirm-pickup.interface'

interface Props {
  job: JobDto
  stepsTotal?: number
  currentStep?: number
  handlePicks?: (id: string) => void
  DestinationComponent?: React.ComponentType<ConfirmPickUpProps>
  showDoorDirectIfExists?: boolean
}
export const CheckInformation = ({
  job,
  stepsTotal,
  currentStep,
  handlePicks,
  DestinationComponent,
  showDoorDirectIfExists,
}: Props) => {
  return (
    <JobInformationWrapper job={job} currentStep={currentStep} stepsTotal={stepsTotal}>
      {(!currentStep || currentStep === 1) && <JobInformationOperationalInstructions job={job} />}

      <JobInformationJourney job={job} />
      {showDoorDirectIfExists && <DoorDirectionInfo job={job} />}
      {job.from.type === ContainerPositionType.Yard && (
        <ConfirmPicks
          handlePicks={handlePicks}
          DestinationComponent={DestinationComponent}
          containerNumber={job.cargoUnit?.displayName ?? ''}
        />
      )}
    </JobInformationWrapper>
  )
}
