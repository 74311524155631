import {
  AllocationDestinationAndStackingStrategyDto,
  CarrierType,
  ContainerHeight,
  ContainerType,
  StackingStrategy,
} from '@storage/app/api'
import { AllSelectOption, AllStringValue, BooleanSelectOption } from '@storage/app/models'

export interface FacetsFormProfile {
  size: string
  containerOperator: string
  isEmpty: BooleanSelectOption | AllSelectOption
  isReefer: BooleanSelectOption
  isDangerous: BooleanSelectOption
  containerHeight: ContainerHeight | AllSelectOption
  containerType: ContainerType | AllSelectOption
  weightClasses: string[]
  consignee: string
  portOfDischarge: string
  carrierType: CarrierType | AllSelectOption
  customer: string
  containerNumber: string
}

export interface AllocationRuleTemplateFormProfile {
  id?: string
  name: string
  facets: FacetsFormProfile
  destination?: string
  destinationAndStackingStrategies: AllocationDestinationAndStackingStrategyDto[]
}

export const defaultDestinationAndStackingStrategy: AllocationDestinationAndStackingStrategyDto = {
  destination: {
    block: '',
    bay: '',
    row: '',
  },
  stackingStrategy: StackingStrategy.BayRowTier,
  position: 1,
}

export const defaultValues: AllocationRuleTemplateFormProfile = {
  name: '',
  destination: '',
  facets: {
    size: '20',
    containerOperator: '',
    isEmpty: 'All',
    isReefer: BooleanSelectOption.False,
    isDangerous: BooleanSelectOption.False,
    containerHeight: 'All',
    containerType: 'All',
    weightClasses: [],
    consignee: '',
    carrierType: AllStringValue,
    portOfDischarge: '',
    customer: '',
    containerNumber: '',
  },
  destinationAndStackingStrategies: [],
}
