import { Button, Container, Stack, TextField } from '@mui/material'
import { IssueType } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import { useNavigationStore } from '@planning/stores'
import { useTranslate } from '@tolgee/react'
import { CloseButton, Header, StepperInfo, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { ReportIssueTypeOption } from './ReportIssueTypeOption'

interface IProps {
  order: IOrderItem
}

export const ReportShortlandedIssue: FC<IProps> = observer(({ order }) => {
  const navigationStore = useNavigationStore()
  const { appViewStore, reportIssueViewStore } = usePlanningStore()
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const [selectedIssueType, setSelectedIssueType] = useState<IssueType>(IssueType.Shortlanded)
  const { container } = order

  const onSubmit = () => {
    if (selectedIssueType === IssueType.Shortlanded) {
      reportIssueViewStore.reportShortlandedContainer(order.id)
    } else {
      appViewStore.setShowAlert('error', t('doNotSupport', 'Issue type not supported'))
      return
    }
    navigationStore.pop()
  }

  return (
    <>
      <Header
        leftRenderOption={
          <CloseButton onClose={() => navigationStore.pop()} tooltip={t('close', 'Close')} />
        }
        title={t('reportIssue', 'Report issue')}
        rightRenderOption={
          <Button
            data-cy='submit-report-container-issue'
            variant='contained'
            onClick={onSubmit}
            sx={{ width: '15%', minWidth: '110px' }}
          >
            {t('submit', 'Submit')}
          </Button>
        }
      />

      <StepperInfo title={t('enterDetails', 'Enter details')} />

      <Container sx={{ py: theme.customSpacing.m }}>
        <Stack gap={theme.customSpacing.l}>
          <ReportIssueTypeOption
            options={[IssueType.Shortlanded]}
            defaultValue={selectedIssueType}
            onSelect={(issueType: IssueType) => setSelectedIssueType(issueType)}
          />

          <TextField
            disabled
            label={t('containerNo', 'Container no.')}
            defaultValue={container?.data.number}
          />
        </Stack>
      </Container>
    </>
  )
})
