import { DialogFormContainer } from '@storage/components/DialogFormContainer'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { BerthFormMapper } from '../components/berth-form/berth-form.mapper'
import { BerthFormProfile } from '../components/berth-form/berth-form.profile'
import { BerthForm } from '../components/berth-form/BerthForm'
import { BerthsFeatureStore } from '../stores'

interface Props {
  featureStore: BerthsFeatureStore
}

export const BerthsDialogContainer = observer(({ featureStore: { berthsDialog } }: Props) => {
  const { berthStore, pierStore } = useStores()
  const { t } = useTranslate()

  const handleSubmit = async (formValues: BerthFormProfile) => {
    if (berthsDialog.formType === 'Add') {
      await berthStore.add(BerthFormMapper.mapFormValuesToCreateDto(formValues))
    } else {
      await berthStore.update(BerthFormMapper.mapFormValuesToUpdateDto(formValues))
    }

    berthsDialog.toggle()
  }

  return (
    <DialogFormContainer
      open={berthsDialog.isOpen}
      formType={berthsDialog.formType}
      form={
        <BerthForm
          id='berth-form'
          pierOptions={pierStore.entriesAsOptions}
          berth={berthsDialog.editingEntity}
          onSubmit={handleSubmit}
        />
      }
      entityTitle={t('berth', 'berth')}
      onClose={() => berthsDialog.toggle()}
    />
  )
})
