import { Badge, Box, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { FilterIcon, FilterPopUp, Header } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

interface Props {
  store: {
    setCarrierNameFilter: (value?: string) => void
    enableFilters: (value: boolean) => void
    isFilterEnabled: boolean
    carrierNameFilter?: string
    containerNumberFilter?: string
    setContainerNumberFilter: (value: string) => void
  }
  handleSetContainerNumberFilter?: (value: string) => void
  handleSetCarrierNameFilter?: (value?: string) => void
  handleSetEnableFilters?: (value: boolean) => void
}

export const IssuesPageHeader = observer(
  ({
    store,
    handleSetContainerNumberFilter,
    handleSetCarrierNameFilter,
    handleSetEnableFilters,
  }: Props) => {
    const { t } = useTranslate()

    const [openFilter, setOpenFilter] = useState<boolean>(false)

    const handleClose = () => {
      setOpenFilter(false)

      store.setCarrierNameFilter()
      store.enableFilters(false)
      handleSetCarrierNameFilter?.()
      handleSetEnableFilters?.(false)
    }

    return (
      <Header
        title={t('issues', 'Issues')}
        rightRenderOption={
          <Stack flexDirection='row' alignItems='center' gap={2}>
            <Box width='300px'>
              <TextField
                label={t('searchContainerNumber', 'Search container number')}
                sx={{ width: '100%', marginTop: 0 }}
                data-cy='issues-page-search-field'
                value={store.containerNumberFilter ?? ''}
                onChange={event => {
                  store.setContainerNumberFilter(event.currentTarget.value.toUpperCase())
                  handleSetContainerNumberFilter?.(event.currentTarget.value.toUpperCase())
                }}
              />
            </Box>

            <Box flex='none'>
              <Tooltip title={t('filter', 'Filter')}>
                <IconButton
                  onClick={() => {
                    setOpenFilter(true)
                  }}
                  size='small'
                  sx={{ ml: 2 }}
                >
                  <Badge color='primary' variant='dot' invisible={!store.isFilterEnabled}>
                    <FilterIcon />
                  </Badge>
                </IconButton>
              </Tooltip>
              <FilterPopUp
                open={openFilter}
                onClose={handleClose}
                filterActionLabel={t('showResults', 'Show results')}
                resetActionLabel={t('reset', 'Reset')}
                onFilter={() => {
                  setOpenFilter(false)
                  store.enableFilters(true)
                  handleSetEnableFilters?.(true)
                }}
                onReset={handleClose}
              >
                <Stack spacing={2}>
                  <Typography variant='subtitle2'>{t('filterBy', 'Filter by')}:</Typography>
                  <TextField
                    label={t('carrierName', 'Carrier name')}
                    sx={{ width: '100%' }}
                    size='small'
                    value={store.carrierNameFilter}
                    onChange={event => {
                      store.enableFilters(false)
                      store.setCarrierNameFilter(event.currentTarget.value)
                      handleSetCarrierNameFilter?.(event.currentTarget.value)
                      handleSetEnableFilters?.(false)
                    }}
                  />
                </Stack>
              </FilterPopUp>
            </Box>
          </Stack>
        }
      />
    )
  },
)
