import { Box, ClickAwayListener, Fade, Paper, Stack } from '@mui/material'
import { ReactNode } from 'react'
import { useMinimalsTheme } from '../../../hooks'
import { FilterActions, FilterActionsProps } from './FilterActions'

export interface FilterProps extends FilterActionsProps {
  open: boolean
  children: ReactNode
  width?: string
  popUpMarginTop?: string
  onClose: () => void
}

export const FilterPopUp = ({
  open,
  children,
  width,
  popUpMarginTop,
  filterActionLabel,
  resetActionLabel,
  formId,
  onFilter,
  onClose,
  onReset,
}: FilterProps) => {
  const theme = useMinimalsTheme()

  return (
    <>
      <Fade in={open} mountOnEnter unmountOnExit>
        <Paper
          elevation={4}
          sx={{
            marginTop: popUpMarginTop ?? '1rem',
            position: 'absolute',
            right: 0,
            zIndex: theme.zIndex.appBar - 1,
            borderRadius: theme.customRadius.large,
            width: width ?? 'inherit',
          }}
        >
          <ClickAwayListener onClickAway={onClose}>
            <Stack
              padding={theme.customSpacing.m}
              justifyContent='space-between'
              position='relative'
            >
              <Box maxHeight='75vh' sx={{ overflowY: 'auto' }}>
                {children}
              </Box>

              <FilterActions
                filterActionLabel={filterActionLabel}
                resetActionLabel={resetActionLabel}
                formId={formId}
                onFilter={onFilter}
                onReset={onReset}
              />
            </Stack>
          </ClickAwayListener>
        </Paper>
      </Fade>
    </>
  )
}
