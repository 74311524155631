/* tslint:disable */
/* eslint-disable */
/**
 * TOM STORAGE SERVICE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Allocation
 */
export interface Allocation {
    /**
     * 
     * @type {string}
     * @memberof Allocation
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Allocation
     */
    'tenantId': number;
    /**
     * 
     * @type {Array<AllocationDescriptor>}
     * @memberof Allocation
     */
    'descriptors': Array<AllocationDescriptor>;
    /**
     * 
     * @type {number}
     * @memberof Allocation
     */
    'length'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Allocation
     */
    'carrierVisitAllocationRuleId'?: string | null;
    /**
     * 
     * @type {CarrierVisitAllocationRule}
     * @memberof Allocation
     */
    'carrierVisitAllocationRule'?: CarrierVisitAllocationRule | null;
    /**
     * 
     * @type {string}
     * @memberof Allocation
     */
    'globalAllocationRuleId'?: string | null;
    /**
     * 
     * @type {GlobalAllocationRule}
     * @memberof Allocation
     */
    'globalAllocationRule'?: GlobalAllocationRule | null;
    /**
     * 
     * @type {string}
     * @memberof Allocation
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Allocation
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {Array<ContainerTurnover>}
     * @memberof Allocation
     */
    'containerTurnovers': Array<ContainerTurnover>;
    /**
     * 
     * @type {Array<AllocationSpace>}
     * @memberof Allocation
     */
    'allocationSpace': Array<AllocationSpace>;
}
/**
 * 
 * @export
 * @interface AllocationDescriptor
 */
export interface AllocationDescriptor {
    /**
     * 
     * @type {string}
     * @memberof AllocationDescriptor
     */
    'block'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationDescriptor
     */
    'bayMin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationDescriptor
     */
    'bayMax'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationDescriptor
     */
    'rowMin'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationDescriptor
     */
    'rowMax'?: string | null;
}
/**
 * 
 * @export
 * @interface AllocationDestinationAndStackingStrategyDto
 */
export interface AllocationDestinationAndStackingStrategyDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationDestinationAndStackingStrategyDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {AllocationDestinationAndStackingStrategyDtoDestination}
     * @memberof AllocationDestinationAndStackingStrategyDto
     */
    'destination': AllocationDestinationAndStackingStrategyDtoDestination;
    /**
     * 
     * @type {StackingStrategy}
     * @memberof AllocationDestinationAndStackingStrategyDto
     */
    'stackingStrategy'?: StackingStrategy | null;
    /**
     * 
     * @type {number}
     * @memberof AllocationDestinationAndStackingStrategyDto
     */
    'position': number;
}
/**
 * 
 * @export
 * @interface AllocationDestinationAndStackingStrategyDtoDestination
 */
export interface AllocationDestinationAndStackingStrategyDtoDestination {
    /**
     * 
     * @type {string}
     * @memberof AllocationDestinationAndStackingStrategyDtoDestination
     */
    'block'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationDestinationAndStackingStrategyDtoDestination
     */
    'bay'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationDestinationAndStackingStrategyDtoDestination
     */
    'row'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AllocationDestinationAndStackingStrategyDtoDestination
     */
    'tier'?: number | null;
}
/**
 * 
 * @export
 * @interface AllocationDto
 */
export interface AllocationDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof AllocationDto
     */
    'tenantId': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllocationDto
     */
    'containerTurnovers': Array<string>;
    /**
     * 
     * @type {Array<AllocationSpaceDto>}
     * @memberof AllocationDto
     */
    'allocationSpace': Array<AllocationSpaceDto>;
}
/**
 * 
 * @export
 * @interface AllocationRulePropertiesDto
 */
export interface AllocationRulePropertiesDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationRulePropertiesDto
     */
    'allocationRuleId': string;
    /**
     * 
     * @type {number}
     * @memberof AllocationRulePropertiesDto
     */
    'carrierVisitId'?: number | null;
    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof AllocationRulePropertiesDto
     */
    'carrierVisitDirection': CarrierVisitDirection;
    /**
     * 
     * @type {AllocationRulePropertiesDtoFacets}
     * @memberof AllocationRulePropertiesDto
     */
    'facets': AllocationRulePropertiesDtoFacets;
}
/**
 * 
 * @export
 * @interface AllocationRulePropertiesDtoFacets
 */
export interface AllocationRulePropertiesDtoFacets {
    /**
     * 
     * @type {number}
     * @memberof AllocationRulePropertiesDtoFacets
     */
    'size'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllocationRulePropertiesDtoFacets
     */
    'isEmpty'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllocationRulePropertiesDtoFacets
     */
    'isReefer'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AllocationRulePropertiesDtoFacets
     */
    'isDangerous'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationRulePropertiesDtoFacets
     */
    'containerOperator'?: string | null;
    /**
     * 
     * @type {ContainerHeight}
     * @memberof AllocationRulePropertiesDtoFacets
     */
    'containerHeight'?: ContainerHeight | null;
    /**
     * 
     * @type {ContainerType}
     * @memberof AllocationRulePropertiesDtoFacets
     */
    'containerType'?: ContainerType | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AllocationRulePropertiesDtoFacets
     */
    'weightClasses': Array<string>;
    /**
     * 
     * @type {CarrierType}
     * @memberof AllocationRulePropertiesDtoFacets
     */
    'inboundCarrierType'?: CarrierType | null;
    /**
     * 
     * @type {CarrierType}
     * @memberof AllocationRulePropertiesDtoFacets
     */
    'outboundCarrierType'?: CarrierType | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationRulePropertiesDtoFacets
     */
    'consignee'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationRulePropertiesDtoFacets
     */
    'portOfDischarge'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationRulePropertiesDtoFacets
     */
    'containerNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AllocationRulePropertiesDtoFacets
     */
    'customerId'?: number | null;
}
/**
 * 
 * @export
 * @interface AllocationRuleSummaryDto
 */
export interface AllocationRuleSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationRuleSummaryDto
     */
    'allocationRuleId': string;
    /**
     * 
     * @type {number}
     * @memberof AllocationRuleSummaryDto
     */
    'nbrOfContainers': number;
}
/**
 * 
 * @export
 * @interface AllocationRuleTemplateDto
 */
export interface AllocationRuleTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationRuleTemplateDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof AllocationRuleTemplateDto
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof AllocationRuleTemplateDto
     */
    'name': string;
    /**
     * 
     * @type {AllocationRulePropertiesDtoFacets}
     * @memberof AllocationRuleTemplateDto
     */
    'facets': AllocationRulePropertiesDtoFacets;
    /**
     * 
     * @type {Array<AllocationDestinationAndStackingStrategyDto>}
     * @memberof AllocationRuleTemplateDto
     */
    'destinationAndStackingStrategies': Array<AllocationDestinationAndStackingStrategyDto>;
}
/**
 * 
 * @export
 * @interface AllocationRulesTemplateCreateRequest
 */
export interface AllocationRulesTemplateCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof AllocationRulesTemplateCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {AllocationRulePropertiesDtoFacets}
     * @memberof AllocationRulesTemplateCreateRequest
     */
    'facets': AllocationRulePropertiesDtoFacets;
    /**
     * 
     * @type {Array<AllocationDestinationAndStackingStrategyDto>}
     * @memberof AllocationRulesTemplateCreateRequest
     */
    'destinationAndStackingStrategies': Array<AllocationDestinationAndStackingStrategyDto>;
}
/**
 * 
 * @export
 * @interface AllocationRulesTemplateUpdateRequest
 */
export interface AllocationRulesTemplateUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof AllocationRulesTemplateUpdateRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationRulesTemplateUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {AllocationRulePropertiesDtoFacets}
     * @memberof AllocationRulesTemplateUpdateRequest
     */
    'facets': AllocationRulePropertiesDtoFacets;
    /**
     * 
     * @type {Array<AllocationDestinationAndStackingStrategyDto>}
     * @memberof AllocationRulesTemplateUpdateRequest
     */
    'destinationAndStackingStrategies': Array<AllocationDestinationAndStackingStrategyDto>;
}
/**
 * 
 * @export
 * @interface AllocationSpace
 */
export interface AllocationSpace {
    /**
     * 
     * @type {string}
     * @memberof AllocationSpace
     */
    'allocationId': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationSpace
     */
    'locationId': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationSpace
     */
    'slotId': string;
    /**
     * 
     * @type {number}
     * @memberof AllocationSpace
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof AllocationSpace
     */
    'carrierVisitRuleDestinationId'?: string | null;
    /**
     * 
     * @type {CarrierVisitRuleDestination}
     * @memberof AllocationSpace
     */
    'carrierVisitRuleDestination'?: CarrierVisitRuleDestination | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationSpace
     */
    'globalRuleDestinationId'?: string | null;
    /**
     * 
     * @type {GlobalRuleDestination}
     * @memberof AllocationSpace
     */
    'globalRuleDestination'?: GlobalRuleDestination | null;
    /**
     * 
     * @type {ContainerSection}
     * @memberof AllocationSpace
     */
    'section': ContainerSection;
    /**
     * 
     * @type {AllocationSpaceSlot}
     * @memberof AllocationSpace
     */
    'slot': AllocationSpaceSlot;
    /**
     * 
     * @type {AllocationSpaceAllocation}
     * @memberof AllocationSpace
     */
    'allocation': AllocationSpaceAllocation;
}
/**
 * 
 * @export
 * @interface AllocationSpaceAllocation
 */
export interface AllocationSpaceAllocation {
    /**
     * 
     * @type {string}
     * @memberof AllocationSpaceAllocation
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof AllocationSpaceAllocation
     */
    'tenantId': number;
    /**
     * 
     * @type {Array<AllocationDescriptor>}
     * @memberof AllocationSpaceAllocation
     */
    'descriptors': Array<AllocationDescriptor>;
    /**
     * 
     * @type {number}
     * @memberof AllocationSpaceAllocation
     */
    'length'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationSpaceAllocation
     */
    'carrierVisitAllocationRuleId'?: string | null;
    /**
     * 
     * @type {CarrierVisitAllocationRule}
     * @memberof AllocationSpaceAllocation
     */
    'carrierVisitAllocationRule'?: CarrierVisitAllocationRule | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationSpaceAllocation
     */
    'globalAllocationRuleId'?: string | null;
    /**
     * 
     * @type {GlobalAllocationRule}
     * @memberof AllocationSpaceAllocation
     */
    'globalAllocationRule'?: GlobalAllocationRule | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationSpaceAllocation
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationSpaceAllocation
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {Array<ContainerTurnover>}
     * @memberof AllocationSpaceAllocation
     */
    'containerTurnovers': Array<ContainerTurnover>;
    /**
     * 
     * @type {Array<AllocationSpace>}
     * @memberof AllocationSpaceAllocation
     */
    'allocationSpace': Array<AllocationSpace>;
}
/**
 * 
 * @export
 * @interface AllocationSpaceDto
 */
export interface AllocationSpaceDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationSpaceDto
     */
    'locationId': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationSpaceDto
     */
    'slotId': string;
    /**
     * 
     * @type {number}
     * @memberof AllocationSpaceDto
     */
    'tenantId': number;
    /**
     * 
     * @type {ContainerSection}
     * @memberof AllocationSpaceDto
     */
    'section': ContainerSection;
}
/**
 * 
 * @export
 * @interface AllocationSpaceSlot
 */
export interface AllocationSpaceSlot {
    /**
     * 
     * @type {string}
     * @memberof AllocationSpaceSlot
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof AllocationSpaceSlot
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof AllocationSpaceSlot
     */
    'tier': number;
    /**
     * 
     * @type {boolean}
     * @memberof AllocationSpaceSlot
     */
    'locked': boolean;
    /**
     * 
     * @type {string}
     * @memberof AllocationSpaceSlot
     */
    'yardBlockRowId': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationSpaceSlot
     */
    'yardBlockBayId': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationSpaceSlot
     */
    'yardBlockId': string;
    /**
     * 
     * @type {YardBlockBayYardBlock}
     * @memberof AllocationSpaceSlot
     */
    'yardBlock': YardBlockBayYardBlock;
    /**
     * 
     * @type {YardBlockSlotYardBlockBay}
     * @memberof AllocationSpaceSlot
     */
    'yardBlockBay': YardBlockSlotYardBlockBay;
    /**
     * 
     * @type {YardBlockSlotYardBlockRow}
     * @memberof AllocationSpaceSlot
     */
    'yardBlockRow': YardBlockSlotYardBlockRow;
    /**
     * 
     * @type {Array<YardBlockSlotOccupancy>}
     * @memberof AllocationSpaceSlot
     */
    'occupancies': Array<YardBlockSlotOccupancy>;
    /**
     * 
     * @type {Array<AllocationSpace>}
     * @memberof AllocationSpaceSlot
     */
    'allocations': Array<AllocationSpace>;
    /**
     * 
     * @type {string}
     * @memberof AllocationSpaceSlot
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationSpaceSlot
     */
    'deletedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface AllocationStackDto
 */
export interface AllocationStackDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationStackDto
     */
    'yardBlockId': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStackDto
     */
    'yardBlockBayId': string;
    /**
     * 
     * @type {string}
     * @memberof AllocationStackDto
     */
    'yardBlockRowId': string;
    /**
     * 
     * @type {ContainerSection}
     * @memberof AllocationStackDto
     */
    'section': ContainerSection;
    /**
     * 
     * @type {RuleDestinationBase}
     * @memberof AllocationStackDto
     */
    'ruleDestination'?: RuleDestinationBase | null;
}
/**
 * 
 * @export
 * @interface AllocationStatisticsDto
 */
export interface AllocationStatisticsDto {
    /**
     * 
     * @type {string}
     * @memberof AllocationStatisticsDto
     */
    'allocationId': string;
    /**
     * 
     * @type {number}
     * @memberof AllocationStatisticsDto
     */
    'turnovers': number;
    /**
     * 
     * @type {number}
     * @memberof AllocationStatisticsDto
     */
    'locationsTotal': number;
    /**
     * 
     * @type {number}
     * @memberof AllocationStatisticsDto
     */
    'locationsAvailable': number;
    /**
     * 
     * @type {boolean}
     * @memberof AllocationStatisticsDto
     */
    'isFlexibleZone': boolean;
}
/**
 * 
 * @export
 * @interface AllocationsCreateInternalMoveManualRequest
 */
export interface AllocationsCreateInternalMoveManualRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AllocationsCreateInternalMoveManualRequest
     */
    'containersNumbers': Array<string>;
    /**
     * 
     * @type {AllocationDestinationAndStackingStrategyDtoDestination}
     * @memberof AllocationsCreateInternalMoveManualRequest
     */
    'yardPosition': AllocationDestinationAndStackingStrategyDtoDestination;
    /**
     * 
     * @type {boolean}
     * @memberof AllocationsCreateInternalMoveManualRequest
     */
    'forceCreation': boolean;
}
/**
 * 
 * @export
 * @interface AllocationsCreateManualRequest
 */
export interface AllocationsCreateManualRequest {
    /**
     * 
     * @type {ContainerTurnoversFilterDto}
     * @memberof AllocationsCreateManualRequest
     */
    'filter'?: ContainerTurnoversFilterDto | null;
    /**
     * 
     * @type {string}
     * @memberof AllocationsCreateManualRequest
     */
    'containerTurnoverId'?: string | null;
    /**
     * 
     * @type {AllocationDestinationAndStackingStrategyDtoDestination}
     * @memberof AllocationsCreateManualRequest
     */
    'yardPosition': AllocationDestinationAndStackingStrategyDtoDestination;
    /**
     * 
     * @type {boolean}
     * @memberof AllocationsCreateManualRequest
     */
    'forceCreation': boolean;
}
/**
 * 
 * @export
 * @interface AllocationsCreateRequest
 */
export interface AllocationsCreateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof AllocationsCreateRequest
     */
    'containerTurnoverIds': Array<string>;
    /**
     * 
     * @type {Array<AllocationStackDto>}
     * @memberof AllocationsCreateRequest
     */
    'allocationStacks': Array<AllocationStackDto>;
}
/**
 * 
 * @export
 * @interface AllocationsDangerousGoodsValidationRequest
 */
export interface AllocationsDangerousGoodsValidationRequest {
    /**
     * 
     * @type {DangerousGoodsValidationDtoFilter}
     * @memberof AllocationsDangerousGoodsValidationRequest
     */
    'filter': DangerousGoodsValidationDtoFilter;
    /**
     * 
     * @type {AllocationDestinationAndStackingStrategyDtoDestination}
     * @memberof AllocationsDangerousGoodsValidationRequest
     */
    'yardPosition': AllocationDestinationAndStackingStrategyDtoDestination;
}
/**
 * 
 * @export
 * @interface AllocationsPrePlanningValidationRequest
 */
export interface AllocationsPrePlanningValidationRequest {
    /**
     * 
     * @type {DangerousGoodsValidationDtoFilter}
     * @memberof AllocationsPrePlanningValidationRequest
     */
    'filter': DangerousGoodsValidationDtoFilter;
}
/**
 * 
 * @export
 * @interface AuthConfiguration
 */
export interface AuthConfiguration {
    /**
     * 
     * @type {string}
     * @memberof AuthConfiguration
     */
    'audience': string;
    /**
     * 
     * @type {string}
     * @memberof AuthConfiguration
     */
    'domain': string;
    /**
     * 
     * @type {string}
     * @memberof AuthConfiguration
     */
    'clientId': string;
}
/**
 * 
 * @export
 * @interface BaseEventWrapper
 */
export interface BaseEventWrapper {
    /**
     * 
     * @type {EventType}
     * @memberof BaseEventWrapper
     */
    'type': EventType;
    /**
     * 
     * @type {any}
     * @memberof BaseEventWrapper
     */
    'payload': any;
    /**
     * 
     * @type {number}
     * @memberof BaseEventWrapper
     */
    'tenantId'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BayOperability = {
    NotRestricted: 'NotRestricted',
    HighRowClosed: 'HighRowClosed',
    LowRowClosed: 'LowRowClosed'
} as const;

export type BayOperability = typeof BayOperability[keyof typeof BayOperability];


/**
 * 
 * @export
 * @interface BerthDto
 */
export interface BerthDto {
    /**
     * 
     * @type {string}
     * @memberof BerthDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BerthDto
     */
    'pierId': string;
    /**
     * 
     * @type {string}
     * @memberof BerthDto
     */
    'pierName': string;
    /**
     * 
     * @type {string}
     * @memberof BerthDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof BerthDto
     */
    'quayMeterMarkFrom': number;
    /**
     * 
     * @type {number}
     * @memberof BerthDto
     */
    'quayMeterMarkTo': number;
    /**
     * 
     * @type {number}
     * @memberof BerthDto
     */
    'maxDraft'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof BerthDto
     */
    'cleatMeterMarks': Array<number>;
    /**
     * 
     * @type {YardElementGeometryDto}
     * @memberof BerthDto
     */
    'geometry'?: YardElementGeometryDto | null;
    /**
     * 
     * @type {Array<BollardDto>}
     * @memberof BerthDto
     */
    'bollards': Array<BollardDto>;
    /**
     * 
     * @type {string}
     * @memberof BerthDto
     */
    'referenceId'?: string | null;
}
/**
 * 
 * @export
 * @interface BerthGeometriesSetRequest
 */
export interface BerthGeometriesSetRequest {
    /**
     * 
     * @type {number}
     * @memberof BerthGeometriesSetRequest
     */
    'top': number;
    /**
     * 
     * @type {number}
     * @memberof BerthGeometriesSetRequest
     */
    'left': number;
    /**
     * 
     * @type {number}
     * @memberof BerthGeometriesSetRequest
     */
    'rotation': number;
    /**
     * 
     * @type {number}
     * @memberof BerthGeometriesSetRequest
     */
    'width': number;
    /**
     * 
     * @type {string}
     * @memberof BerthGeometriesSetRequest
     */
    'berthId': string;
}
/**
 * 
 * @export
 * @interface BerthsCreateRequest
 */
export interface BerthsCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof BerthsCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BerthsCreateRequest
     */
    'pierId': string;
    /**
     * 
     * @type {number}
     * @memberof BerthsCreateRequest
     */
    'quayMeterMarkFrom': number;
    /**
     * 
     * @type {number}
     * @memberof BerthsCreateRequest
     */
    'quayMeterMarkTo': number;
    /**
     * 
     * @type {number}
     * @memberof BerthsCreateRequest
     */
    'maxDraft'?: number | null;
    /**
     * 
     * @type {Array<BollardDto>}
     * @memberof BerthsCreateRequest
     */
    'bollards': Array<BollardDto>;
}
/**
 * 
 * @export
 * @interface BerthsPutRequest
 */
export interface BerthsPutRequest {
    /**
     * 
     * @type {string}
     * @memberof BerthsPutRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof BerthsPutRequest
     */
    'pierId': string;
    /**
     * 
     * @type {string}
     * @memberof BerthsPutRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof BerthsPutRequest
     */
    'quayMeterMarkFrom': number;
    /**
     * 
     * @type {number}
     * @memberof BerthsPutRequest
     */
    'quayMeterMarkTo': number;
    /**
     * 
     * @type {number}
     * @memberof BerthsPutRequest
     */
    'maxDraft'?: number | null;
    /**
     * 
     * @type {Array<Bollard>}
     * @memberof BerthsPutRequest
     */
    'bollards': Array<Bollard>;
    /**
     * 
     * @type {YardElementGeometryDto}
     * @memberof BerthsPutRequest
     */
    'geometry'?: YardElementGeometryDto | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BlockLabellingPattern = {
    Numeric: 'Numeric',
    Alphabetic: 'Alphabetic',
    Custom: 'Custom'
} as const;

export type BlockLabellingPattern = typeof BlockLabellingPattern[keyof typeof BlockLabellingPattern];


/**
 * 
 * @export
 * @interface Bollard
 */
export interface Bollard {
    /**
     * 
     * @type {string}
     * @memberof Bollard
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Bollard
     */
    'meterMark': number;
}
/**
 * 
 * @export
 * @interface BollardDto
 */
export interface BollardDto {
    /**
     * 
     * @type {string}
     * @memberof BollardDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof BollardDto
     */
    'meterMark': number;
}
/**
 * 
 * @export
 * @interface Carrier
 */
export interface Carrier {
    /**
     * 
     * @type {string}
     * @memberof Carrier
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CarrierType = {
    Universal: 'Universal',
    Truck: 'Truck',
    Vessel: 'Vessel',
    Train: 'Train',
    Unknown: 'Unknown'
} as const;

export type CarrierType = typeof CarrierType[keyof typeof CarrierType];


/**
 * 
 * @export
 * @interface CarrierVisit
 */
export interface CarrierVisit {
    /**
     * 
     * @type {number}
     * @memberof CarrierVisit
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisit
     */
    'id': number;
    /**
     * 
     * @type {Array<Carrier>}
     * @memberof CarrierVisit
     */
    'carriers': Array<Carrier>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CarrierVisit
     */
    'berthIds': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisit
     */
    'qmmFrom'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisit
     */
    'qmmTo'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisit
     */
    'eta'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisit
     */
    'etd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisit
     */
    'ata'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisit
     */
    'atd'?: string | null;
    /**
     * 
     * @type {CarrierVisitStatus}
     * @memberof CarrierVisit
     */
    'status': CarrierVisitStatus;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisit
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisit
     */
    'deletedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface CarrierVisitAllocationRule
 */
export interface CarrierVisitAllocationRule {
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitAllocationRule
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitAllocationRule
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitAllocationRule
     */
    'carrierVisitId': number;
    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof CarrierVisitAllocationRule
     */
    'carrierVisitDirection': CarrierVisitDirection;
    /**
     * 
     * @type {Array<CarrierVisitRuleDestination>}
     * @memberof CarrierVisitAllocationRule
     */
    'carrierVisitRuleDestinations': Array<CarrierVisitRuleDestination>;
    /**
     * 
     * @type {AllocationRulePropertiesDtoFacets}
     * @memberof CarrierVisitAllocationRule
     */
    'facets': AllocationRulePropertiesDtoFacets;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitAllocationRule
     */
    'templateName': string;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitAllocationRule
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitAllocationRule
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {CarrierVisitAllocationRuleCarrierVisit}
     * @memberof CarrierVisitAllocationRule
     */
    'carrierVisit': CarrierVisitAllocationRuleCarrierVisit;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitAllocationRule
     */
    'rulePosition': number;
}
/**
 * 
 * @export
 * @interface CarrierVisitAllocationRuleCarrierVisit
 */
export interface CarrierVisitAllocationRuleCarrierVisit {
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitAllocationRuleCarrierVisit
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitAllocationRuleCarrierVisit
     */
    'id': number;
    /**
     * 
     * @type {Array<Carrier>}
     * @memberof CarrierVisitAllocationRuleCarrierVisit
     */
    'carriers': Array<Carrier>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CarrierVisitAllocationRuleCarrierVisit
     */
    'berthIds': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitAllocationRuleCarrierVisit
     */
    'qmmFrom'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitAllocationRuleCarrierVisit
     */
    'qmmTo'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitAllocationRuleCarrierVisit
     */
    'eta'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitAllocationRuleCarrierVisit
     */
    'etd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitAllocationRuleCarrierVisit
     */
    'ata'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitAllocationRuleCarrierVisit
     */
    'atd'?: string | null;
    /**
     * 
     * @type {CarrierVisitStatus}
     * @memberof CarrierVisitAllocationRuleCarrierVisit
     */
    'status': CarrierVisitStatus;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitAllocationRuleCarrierVisit
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitAllocationRuleCarrierVisit
     */
    'deletedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface CarrierVisitAllocationRuleDto
 */
export interface CarrierVisitAllocationRuleDto {
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitAllocationRuleDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitAllocationRuleDto
     */
    'name': string;
    /**
     * 
     * @type {AllocationRulePropertiesDtoFacets}
     * @memberof CarrierVisitAllocationRuleDto
     */
    'facets': AllocationRulePropertiesDtoFacets;
    /**
     * 
     * @type {Array<AllocationDestinationAndStackingStrategyDto>}
     * @memberof CarrierVisitAllocationRuleDto
     */
    'destinationAndStackingStrategies': Array<AllocationDestinationAndStackingStrategyDto>;
}
/**
 * 
 * @export
 * @interface CarrierVisitContainerDataDto
 */
export interface CarrierVisitContainerDataDto {
    /**
     * 
     * @type {CarrierType}
     * @memberof CarrierVisitContainerDataDto
     */
    'type'?: CarrierType | null;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitContainerDataDto
     */
    'expectedDischargeVolume': number;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitContainerDataDto
     */
    'completedDischargeVolume': number;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitContainerDataDto
     */
    'expectedLoadVolume': number;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitContainerDataDto
     */
    'completedLoadVolume': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CarrierVisitDirection = {
    Inbound: 'Inbound',
    Outbound: 'Outbound'
} as const;

export type CarrierVisitDirection = typeof CarrierVisitDirection[keyof typeof CarrierVisitDirection];


/**
 * 
 * @export
 * @interface CarrierVisitDto
 */
export interface CarrierVisitDto {
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitDto
     */
    'eta'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitDto
     */
    'etd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitDto
     */
    'ata'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitDto
     */
    'atd'?: string | null;
    /**
     * 
     * @type {CarrierVisitStatus}
     * @memberof CarrierVisitDto
     */
    'status': CarrierVisitStatus;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitDto
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CarrierVisitDto
     */
    'berthIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CarrierVisitDto
     */
    'berths': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitDto
     */
    'qmmFrom'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitDto
     */
    'qmmTo'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitDto
     */
    'discriminator': string;
}
/**
 * 
 * @export
 * @interface CarrierVisitRuleDestination
 */
export interface CarrierVisitRuleDestination {
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitRuleDestination
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitRuleDestination
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitRuleDestination
     */
    'block'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitRuleDestination
     */
    'bay'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitRuleDestination
     */
    'row'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitRuleDestination
     */
    'carrierVisitAllocationRuleId': string;
    /**
     * 
     * @type {Array<AllocationSpace>}
     * @memberof CarrierVisitRuleDestination
     */
    'allocationSpaces': Array<AllocationSpace>;
    /**
     * 
     * @type {CarrierVisitRuleDestinationCarrierVisitAllocationRule}
     * @memberof CarrierVisitRuleDestination
     */
    'carrierVisitAllocationRule': CarrierVisitRuleDestinationCarrierVisitAllocationRule;
    /**
     * 
     * @type {StackingStrategy}
     * @memberof CarrierVisitRuleDestination
     */
    'stackingStrategy'?: StackingStrategy | null;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitRuleDestination
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitRuleDestination
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitRuleDestination
     */
    'deletedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface CarrierVisitRuleDestinationCarrierVisitAllocationRule
 */
export interface CarrierVisitRuleDestinationCarrierVisitAllocationRule {
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitRuleDestinationCarrierVisitAllocationRule
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitRuleDestinationCarrierVisitAllocationRule
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitRuleDestinationCarrierVisitAllocationRule
     */
    'carrierVisitId': number;
    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof CarrierVisitRuleDestinationCarrierVisitAllocationRule
     */
    'carrierVisitDirection': CarrierVisitDirection;
    /**
     * 
     * @type {Array<CarrierVisitRuleDestination>}
     * @memberof CarrierVisitRuleDestinationCarrierVisitAllocationRule
     */
    'carrierVisitRuleDestinations': Array<CarrierVisitRuleDestination>;
    /**
     * 
     * @type {AllocationRulePropertiesDtoFacets}
     * @memberof CarrierVisitRuleDestinationCarrierVisitAllocationRule
     */
    'facets': AllocationRulePropertiesDtoFacets;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitRuleDestinationCarrierVisitAllocationRule
     */
    'templateName': string;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitRuleDestinationCarrierVisitAllocationRule
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitRuleDestinationCarrierVisitAllocationRule
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {CarrierVisitAllocationRuleCarrierVisit}
     * @memberof CarrierVisitRuleDestinationCarrierVisitAllocationRule
     */
    'carrierVisit': CarrierVisitAllocationRuleCarrierVisit;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitRuleDestinationCarrierVisitAllocationRule
     */
    'rulePosition': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CarrierVisitStatus = {
    Expected: 'Expected',
    Arrived: 'Arrived',
    InOperation: 'InOperation',
    Departed: 'Departed',
    Completed: 'Completed'
} as const;

export type CarrierVisitStatus = typeof CarrierVisitStatus[keyof typeof CarrierVisitStatus];


/**
 * 
 * @export
 * @interface CarrierVisitSummaryDto
 */
export interface CarrierVisitSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitSummaryDto
     */
    'plannedLoad': number;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitSummaryDto
     */
    'unplannedLoad': number;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitSummaryDto
     */
    'inYardLoad': number;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitSummaryDto
     */
    'plannedDischarge': number;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitSummaryDto
     */
    'unplannedDischarge': number;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitSummaryDto
     */
    'inYardDischarge': number;
    /**
     * 
     * @type {Array<CarrierVisitSummaryFilterDto>}
     * @memberof CarrierVisitSummaryDto
     */
    'filters': Array<CarrierVisitSummaryFilterDto>;
}
/**
 * 
 * @export
 * @interface CarrierVisitSummaryFilterDto
 */
export interface CarrierVisitSummaryFilterDto {
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitSummaryFilterDto
     */
    'planned': number;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitSummaryFilterDto
     */
    'unplanned': number;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof CarrierVisitSummaryFilterDto
     */
    'settings'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface CarrierVisitsCreateCarrierVisitAllocationAndRulesRequest
 */
export interface CarrierVisitsCreateCarrierVisitAllocationAndRulesRequest {
    /**
     * 
     * @type {Array<CreateAllocationRuleDto>}
     * @memberof CarrierVisitsCreateCarrierVisitAllocationAndRulesRequest
     */
    'allocationRules': Array<CreateAllocationRuleDto>;
    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof CarrierVisitsCreateCarrierVisitAllocationAndRulesRequest
     */
    'direction': CarrierVisitDirection;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitsCreateCarrierVisitAllocationAndRulesRequest
     */
    'carrierVisitId': number;
}
/**
 * 
 * @export
 * @interface CarrierVisitsUpsertRequest
 */
export interface CarrierVisitsUpsertRequest {
    /**
     * 
     * @type {EventType}
     * @memberof CarrierVisitsUpsertRequest
     */
    'type': EventType;
    /**
     * 
     * @type {any}
     * @memberof CarrierVisitsUpsertRequest
     */
    'payload': any;
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitsUpsertRequest
     */
    'tenantId'?: number | null;
}
/**
 * 
 * @export
 * @interface Commodity
 */
export interface Commodity {
    /**
     * 
     * @type {number}
     * @memberof Commodity
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof Commodity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Commodity
     */
    'name': string;
    /**
     * 
     * @type {Array<CommodityAttribute>}
     * @memberof Commodity
     */
    'attributes': Array<CommodityAttribute>;
    /**
     * 
     * @type {string}
     * @memberof Commodity
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Commodity
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {Array<GeneralCargoStock>}
     * @memberof Commodity
     */
    'stocks': Array<GeneralCargoStock>;
}
/**
 * 
 * @export
 * @interface CommodityAttribute
 */
export interface CommodityAttribute {
    /**
     * 
     * @type {string}
     * @memberof CommodityAttribute
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CommodityAttribute
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface CommodityDto
 */
export interface CommodityDto {
    /**
     * 
     * @type {number}
     * @memberof CommodityDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CommodityDto
     */
    'name': string;
    /**
     * 
     * @type {Array<CommodityAttribute>}
     * @memberof CommodityDto
     */
    'attributes': Array<CommodityAttribute>;
}
/**
 * 
 * @export
 * @interface Container
 */
export interface Container {
    /**
     * 
     * @type {number}
     * @memberof Container
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof Container
     */
    'id': number;
    /**
     * 
     * @type {ContainerType}
     * @memberof Container
     */
    'type'?: ContainerType | null;
    /**
     * 
     * @type {ContainerHeight}
     * @memberof Container
     */
    'height'?: ContainerHeight | null;
    /**
     * 
     * @type {number}
     * @memberof Container
     */
    'length'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    'number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    'isoCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Container
     */
    'isReefer'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Container
     */
    'isDamaged': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Container
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    'operator'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    'typeCode'?: string | null;
    /**
     * 
     * @type {Array<ContainerTurnover>}
     * @memberof Container
     */
    'turnovers': Array<ContainerTurnover>;
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Container
     */
    'deletedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ContainerBlockInfoDto
 */
export interface ContainerBlockInfoDto {
    /**
     * 
     * @type {string}
     * @memberof ContainerBlockInfoDto
     */
    'blockName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerBlockInfoDto
     */
    'containerNumber': string;
}
/**
 * 
 * @export
 * @interface ContainerDto
 */
export interface ContainerDto {
    /**
     * 
     * @type {number}
     * @memberof ContainerDto
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof ContainerDto
     */
    'id': number;
    /**
     * 
     * @type {ContainerType}
     * @memberof ContainerDto
     */
    'type'?: ContainerType | null;
    /**
     * 
     * @type {ContainerHeight}
     * @memberof ContainerDto
     */
    'height'?: ContainerHeight | null;
    /**
     * 
     * @type {number}
     * @memberof ContainerDto
     */
    'length': number;
    /**
     * 
     * @type {string}
     * @memberof ContainerDto
     */
    'number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerDto
     */
    'isoCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerDto
     */
    'isReefer'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerDto
     */
    'isDamaged': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerDto
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {string}
     * @memberof ContainerDto
     */
    'operator'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerDto
     */
    'typeCode'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ContainerHeight = {
    Standard: 'Standard',
    High: 'High',
    Low: 'Low'
} as const;

export type ContainerHeight = typeof ContainerHeight[keyof typeof ContainerHeight];


/**
 * 
 * @export
 * @enum {string}
 */

export const ContainerPositionType = {
    Vessel: 'Vessel',
    Truck: 'Truck',
    Yard: 'Yard',
    Equipment: 'Equipment',
    Handover: 'Handover',
    Unknown: 'Unknown',
    Berth: 'Berth',
    Repair: 'Repair',
    Warehouse: 'Warehouse',
    Customs: 'Customs',
    Weights: 'Weights',
    RequireYardReservation: 'RequireYardReservation',
    Train: 'Train'
} as const;

export type ContainerPositionType = typeof ContainerPositionType[keyof typeof ContainerPositionType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ContainerSection = {
    Head: 'Head',
    Body: 'Body',
    Tail: 'Tail'
} as const;

export type ContainerSection = typeof ContainerSection[keyof typeof ContainerSection];


/**
 * 
 * @export
 * @interface ContainerStackOutSequenceItemDto
 */
export interface ContainerStackOutSequenceItemDto {
    /**
     * 
     * @type {string}
     * @memberof ContainerStackOutSequenceItemDto
     */
    'containerNumber': string;
    /**
     * 
     * @type {Array<YardPositionDto>}
     * @memberof ContainerStackOutSequenceItemDto
     */
    'location': Array<YardPositionDto>;
    /**
     * 
     * @type {number}
     * @memberof ContainerStackOutSequenceItemDto
     */
    'additionalMovesByYardStatus': number;
    /**
     * 
     * @type {number}
     * @memberof ContainerStackOutSequenceItemDto
     */
    'additionalMovesByStackOutSequence': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ContainerStatus = {
    Reserved: 'Reserved',
    NotReserved: 'NotReserved',
    OnTheYard: 'OnTheYard',
    NotStackedIn: 'NotStackedIn'
} as const;

export type ContainerStatus = typeof ContainerStatus[keyof typeof ContainerStatus];


/**
 * 
 * @export
 * @interface ContainerTurnover
 */
export interface ContainerTurnover {
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnover
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnover
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnover
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnover
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnover
     */
    'containerId'?: number | null;
    /**
     * 
     * @type {ContainerTurnoverContainer}
     * @memberof ContainerTurnover
     */
    'container': ContainerTurnoverContainer;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnover
     */
    'inboundCarrierVisitId'?: number | null;
    /**
     * 
     * @type {CarrierVisitAllocationRuleCarrierVisit}
     * @memberof ContainerTurnover
     */
    'inboundCarrierVisit': CarrierVisitAllocationRuleCarrierVisit;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnover
     */
    'outboundCarrierVisitId'?: number | null;
    /**
     * 
     * @type {CarrierVisitAllocationRuleCarrierVisit}
     * @memberof ContainerTurnover
     */
    'outboundCarrierVisit': CarrierVisitAllocationRuleCarrierVisit;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnover
     */
    'inboundOrderId'?: number | null;
    /**
     * 
     * @type {CarrierType}
     * @memberof ContainerTurnover
     */
    'inboundCarrierType'?: CarrierType | null;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnover
     */
    'outboundOrderId'?: number | null;
    /**
     * 
     * @type {CarrierType}
     * @memberof ContainerTurnover
     */
    'outboundCarrierType'?: CarrierType | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnover
     */
    'isEmpty'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnover
     */
    'grossWeight'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnover
     */
    'isDangerous': boolean;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnover
     */
    'portOfLoading'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnover
     */
    'portOfDischarge'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnover
     */
    'temperature'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnover
     */
    'releaseReference'?: string | null;
    /**
     * 
     * @type {Array<ImoDeclaration>}
     * @memberof ContainerTurnover
     */
    'imoDeclarations': Array<ImoDeclaration>;
    /**
     * 
     * @type {ContainerPositionType}
     * @memberof ContainerTurnover
     */
    'positionType'?: ContainerPositionType | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnover
     */
    'allocationId'?: string | null;
    /**
     * 
     * @type {Allocation}
     * @memberof ContainerTurnover
     */
    'allocation'?: Allocation | null;
    /**
     * 
     * @type {Array<YardBlockSlotOccupancy>}
     * @memberof ContainerTurnover
     */
    'occupancies': Array<YardBlockSlotOccupancy>;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnover
     */
    'isAllocated': boolean;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnover
     */
    'consignee'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnover
     */
    'yardBlockEntry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnover
     */
    'yardBlockExit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnover
     */
    'terminalEntry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnover
     */
    'terminalExit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnover
     */
    'customerId'?: number | null;
}
/**
 * 
 * @export
 * @interface ContainerTurnoverBasicInfoDto
 */
export interface ContainerTurnoverBasicInfoDto {
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverBasicInfoDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverBasicInfoDto
     */
    'containerNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoverBasicInfoDto
     */
    'isEmpty'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoverBasicInfoDto
     */
    'isDangerous': boolean;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoverBasicInfoDto
     */
    'size': number;
    /**
     * 
     * @type {ContainerStatus}
     * @memberof ContainerTurnoverBasicInfoDto
     */
    'status': ContainerStatus;
    /**
     * 
     * @type {Array<YardPositionDto>}
     * @memberof ContainerTurnoverBasicInfoDto
     */
    'currentPosition': Array<YardPositionDto>;
}
/**
 * 
 * @export
 * @interface ContainerTurnoverContainer
 */
export interface ContainerTurnoverContainer {
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoverContainer
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoverContainer
     */
    'id': number;
    /**
     * 
     * @type {ContainerType}
     * @memberof ContainerTurnoverContainer
     */
    'type'?: ContainerType | null;
    /**
     * 
     * @type {ContainerHeight}
     * @memberof ContainerTurnoverContainer
     */
    'height'?: ContainerHeight | null;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoverContainer
     */
    'length'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverContainer
     */
    'number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverContainer
     */
    'isoCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoverContainer
     */
    'isReefer'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoverContainer
     */
    'isDamaged': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoverContainer
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverContainer
     */
    'operator'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverContainer
     */
    'typeCode'?: string | null;
    /**
     * 
     * @type {Array<ContainerTurnover>}
     * @memberof ContainerTurnoverContainer
     */
    'turnovers': Array<ContainerTurnover>;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverContainer
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverContainer
     */
    'deletedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface ContainerTurnoverDto
 */
export interface ContainerTurnoverDto {
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoverDto
     */
    'inboundOrderId'?: number | null;
    /**
     * 
     * @type {CarrierType}
     * @memberof ContainerTurnoverDto
     */
    'inboundCarrierType'?: CarrierType | null;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoverDto
     */
    'inboundCarrierVisitId'?: number | null;
    /**
     * 
     * @type {Array<Carrier>}
     * @memberof ContainerTurnoverDto
     */
    'inboundCarriers': Array<Carrier>;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoverDto
     */
    'outboundOrderId'?: number | null;
    /**
     * 
     * @type {CarrierType}
     * @memberof ContainerTurnoverDto
     */
    'outboundCarrierType'?: CarrierType | null;
    /**
     * 
     * @type {ContainerType}
     * @memberof ContainerTurnoverDto
     */
    'containerType'?: ContainerType | null;
    /**
     * 
     * @type {ContainerHeight}
     * @memberof ContainerTurnoverDto
     */
    'containerHeight'?: ContainerHeight | null;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoverDto
     */
    'outboundCarrierVisitId'?: number | null;
    /**
     * 
     * @type {Array<Carrier>}
     * @memberof ContainerTurnoverDto
     */
    'outboundCarriers': Array<Carrier>;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverDto
     */
    'containerNumber': string;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoverDto
     */
    'containerId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverDto
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverDto
     */
    'containerIsoCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoverDto
     */
    'isEmptyContainerCargoState': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoverDto
     */
    'isEmpty'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoverDto
     */
    'isReefer'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoverDto
     */
    'grossWeight'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoverDto
     */
    'isDamaged': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoverDto
     */
    'isDangerous': boolean;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoverDto
     */
    'size': number;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverDto
     */
    'portOfDischarge'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverDto
     */
    'portOfLoading'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverDto
     */
    'containerOperator'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverDto
     */
    'temperature'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverDto
     */
    'typeCode'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContainerTurnoverDto
     */
    'imoClasses'?: Array<string> | null;
    /**
     * 
     * @type {ContainerPositionType}
     * @memberof ContainerTurnoverDto
     */
    'positionType'?: ContainerPositionType | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoverDto
     */
    'isRestow': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoverDto
     */
    'isAllocated': boolean;
    /**
     * 
     * @type {ContainerStatus}
     * @memberof ContainerTurnoverDto
     */
    'status': ContainerStatus;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverDto
     */
    'shippingLine'?: string | null;
    /**
     * 
     * @type {Array<YardPositionDto>}
     * @memberof ContainerTurnoverDto
     */
    'currentPosition': Array<YardPositionDto>;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverDto
     */
    'currentPositionHeadSlotId'?: string | null;
    /**
     * 
     * @type {Array<YardPositionDto>}
     * @memberof ContainerTurnoverDto
     */
    'targetPosition': Array<YardPositionDto>;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverDto
     */
    'targetPositionHeadSlotId'?: string | null;
    /**
     * 
     * @type {Array<YardPositionDto>}
     * @memberof ContainerTurnoverDto
     */
    'targetAllocation': Array<YardPositionDto>;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverDto
     */
    'terminalEntry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverDto
     */
    'terminalExit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverDto
     */
    'yardBlockEntry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverDto
     */
    'yardBlockExit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverDto
     */
    'consignee'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoverDto
     */
    'customerId'?: number | null;
}
/**
 * 
 * @export
 * @interface ContainerTurnoverDtoPaginatedList
 */
export interface ContainerTurnoverDtoPaginatedList {
    /**
     * 
     * @type {Array<ContainerTurnoverDto>}
     * @memberof ContainerTurnoverDtoPaginatedList
     */
    'items': Array<ContainerTurnoverDto>;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoverDtoPaginatedList
     */
    'pageNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoverDtoPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoverDtoPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoverDtoPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoverDtoPaginatedList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @interface ContainerTurnoverGroupDto
 */
export interface ContainerTurnoverGroupDto {
    /**
     * 
     * @type {ContainerTurnoverGroupSelector}
     * @memberof ContainerTurnoverGroupDto
     */
    'selector': ContainerTurnoverGroupSelector;
    /**
     * 
     * @type {Array<ContainerTurnoverGroupItemDto>}
     * @memberof ContainerTurnoverGroupDto
     */
    'items': Array<ContainerTurnoverGroupItemDto>;
}
/**
 * 
 * @export
 * @interface ContainerTurnoverGroupItemDto
 */
export interface ContainerTurnoverGroupItemDto {
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverGroupItemDto
     */
    'key': string;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoverGroupItemDto
     */
    'value': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ContainerTurnoverGroupSelector = {
    Size: 'Size',
    PortOfDischarge: 'PortOfDischarge',
    CargoStatus: 'CargoStatus',
    ContainerOperator: 'ContainerOperator'
} as const;

export type ContainerTurnoverGroupSelector = typeof ContainerTurnoverGroupSelector[keyof typeof ContainerTurnoverGroupSelector];


/**
 * 
 * @export
 * @interface ContainerTurnoverYardPositionDto
 */
export interface ContainerTurnoverYardPositionDto {
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverYardPositionDto
     */
    'containerTurnoverId': string;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoverYardPositionDto
     */
    'containerNumber': string;
    /**
     * 
     * @type {YardPositionDto}
     * @memberof ContainerTurnoverYardPositionDto
     */
    'currentPosition'?: YardPositionDto | null;
}
/**
 * 
 * @export
 * @interface ContainerTurnoversFilterDto
 */
export interface ContainerTurnoversFilterDto {
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversFilterDto
     */
    'containerOrReferenceNumber'?: string | null;
    /**
     * 
     * @type {CarrierType}
     * @memberof ContainerTurnoversFilterDto
     */
    'inboundCarrierType'?: CarrierType | null;
    /**
     * 
     * @type {CarrierType}
     * @memberof ContainerTurnoversFilterDto
     */
    'outboundCarrierType'?: CarrierType | null;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversFilterDto
     */
    'inboundCarrier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversFilterDto
     */
    'outboundCarrier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversFilterDto
     */
    'isEmpty'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversFilterDto
     */
    'size'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversFilterDto
     */
    'containerIsoCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversFilterDto
     */
    'typeCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversFilterDto
     */
    'portOfLoading'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversFilterDto
     */
    'portOfDischarge'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContainerTurnoversFilterDto
     */
    'weightClasses': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversFilterDto
     */
    'isReefer'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversFilterDto
     */
    'isDamaged'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContainerTurnoversFilterDto
     */
    'imoClasses': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversFilterDto
     */
    'isRestow'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversFilterDto
     */
    'containerOperator'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversFilterDto
     */
    'consignee'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversFilterDto
     */
    'isDangerous'?: boolean | null;
    /**
     * 
     * @type {ContainerStatus}
     * @memberof ContainerTurnoversFilterDto
     */
    'locationStatus'?: ContainerStatus | null;
    /**
     * 
     * @type {ContainerType}
     * @memberof ContainerTurnoversFilterDto
     */
    'containerType'?: ContainerType | null;
    /**
     * 
     * @type {ContainerHeight}
     * @memberof ContainerTurnoversFilterDto
     */
    'containerHeight'?: ContainerHeight | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversFilterDto
     */
    'isInTerminal'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversFilterDto
     */
    'customerId'?: number | null;
}
/**
 * 
 * @export
 * @interface ContainerTurnoversGetAllAsOptionsRequest
 */
export interface ContainerTurnoversGetAllAsOptionsRequest {
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversGetAllAsOptionsRequest
     */
    'pageNumber': number;
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversGetAllAsOptionsRequest
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ContainerType = {
    Unknown: 'Unknown',
    General: 'General',
    GeneralVentilated: 'GeneralVentilated',
    Reefer: 'Reefer',
    OpenTop: 'OpenTop',
    Platform: 'Platform',
    Tank: 'Tank',
    Bulk: 'Bulk',
    Foldable: 'Foldable',
    AirSurface: 'AirSurface',
    Livestock: 'Livestock',
    Auto: 'Auto',
    Fish: 'Fish',
    NonCargoCarrying: 'NonCargoCarrying'
} as const;

export type ContainerType = typeof ContainerType[keyof typeof ContainerType];


/**
 * 
 * @export
 * @interface ContainerYardOperationDto
 */
export interface ContainerYardOperationDto {
    /**
     * 
     * @type {TurnoverDto}
     * @memberof ContainerYardOperationDto
     */
    'turnover'?: TurnoverDto | null;
    /**
     * 
     * @type {number}
     * @memberof ContainerYardOperationDto
     */
    'locationSize': number;
    /**
     * 
     * @type {YardPositionDto}
     * @memberof ContainerYardOperationDto
     */
    'currentPosition'?: YardPositionDto | null;
    /**
     * 
     * @type {YardPositionDto}
     * @memberof ContainerYardOperationDto
     */
    'plannedPosition'?: YardPositionDto | null;
    /**
     * 
     * @type {Array<ContainerTurnoverDto>}
     * @memberof ContainerYardOperationDto
     */
    'shuffleContainers'?: Array<ContainerTurnoverDto> | null;
}
/**
 * 
 * @export
 * @interface CreateAllocationRuleDto
 */
export interface CreateAllocationRuleDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAllocationRuleDto
     */
    'name': string;
    /**
     * 
     * @type {Array<AllocationDestinationAndStackingStrategyDto>}
     * @memberof CreateAllocationRuleDto
     */
    'destinationAndStackingStrategies': Array<AllocationDestinationAndStackingStrategyDto>;
    /**
     * 
     * @type {AllocationRulePropertiesDtoFacets}
     * @memberof CreateAllocationRuleDto
     */
    'facets': AllocationRulePropertiesDtoFacets;
    /**
     * 
     * @type {number}
     * @memberof CreateAllocationRuleDto
     */
    'rulePosition': number;
    /**
     * 
     * @type {Array<ContainerTurnoverDto>}
     * @memberof CreateAllocationRuleDto
     * @deprecated
     */
    'containerTurnovers': Array<ContainerTurnoverDto>;
}
/**
 * 
 * @export
 * @interface CreateAllocationRuleTemplateDto
 */
export interface CreateAllocationRuleTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof CreateAllocationRuleTemplateDto
     */
    'name': string;
    /**
     * 
     * @type {AllocationRulePropertiesDtoFacets}
     * @memberof CreateAllocationRuleTemplateDto
     */
    'facets': AllocationRulePropertiesDtoFacets;
    /**
     * 
     * @type {Array<AllocationDestinationAndStackingStrategyDto>}
     * @memberof CreateAllocationRuleTemplateDto
     */
    'destinationAndStackingStrategies': Array<AllocationDestinationAndStackingStrategyDto>;
}
/**
 * 
 * @export
 * @interface CreateAllocationsDto
 */
export interface CreateAllocationsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateAllocationsDto
     */
    'containerTurnoverIds': Array<string>;
    /**
     * 
     * @type {Array<AllocationStackDto>}
     * @memberof CreateAllocationsDto
     */
    'allocationStacks': Array<AllocationStackDto>;
}
/**
 * 
 * @export
 * @interface CreateBerthDto
 */
export interface CreateBerthDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBerthDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBerthDto
     */
    'pierId': string;
    /**
     * 
     * @type {number}
     * @memberof CreateBerthDto
     */
    'quayMeterMarkFrom': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBerthDto
     */
    'quayMeterMarkTo': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBerthDto
     */
    'maxDraft'?: number | null;
    /**
     * 
     * @type {Array<BollardDto>}
     * @memberof CreateBerthDto
     */
    'bollards': Array<BollardDto>;
}
/**
 * 
 * @export
 * @interface CreateCarrierVisitAllocationAndRulesDto
 */
export interface CreateCarrierVisitAllocationAndRulesDto {
    /**
     * 
     * @type {Array<CreateAllocationRuleDto>}
     * @memberof CreateCarrierVisitAllocationAndRulesDto
     */
    'allocationRules': Array<CreateAllocationRuleDto>;
    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof CreateCarrierVisitAllocationAndRulesDto
     */
    'direction': CarrierVisitDirection;
    /**
     * 
     * @type {number}
     * @memberof CreateCarrierVisitAllocationAndRulesDto
     */
    'carrierVisitId': number;
}
/**
 * 
 * @export
 * @interface CreateEquipmentTypeDto
 */
export interface CreateEquipmentTypeDto {
    /**
     * 
     * @type {string}
     * @memberof CreateEquipmentTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEquipmentTypeDto
     */
    'remarks'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateGeneralCargoAreaDto
 */
export interface CreateGeneralCargoAreaDto {
    /**
     * 
     * @type {string}
     * @memberof CreateGeneralCargoAreaDto
     */
    'name': string;
    /**
     * 
     * @type {GeneralCargoAreaType}
     * @memberof CreateGeneralCargoAreaDto
     */
    'type': GeneralCargoAreaType;
    /**
     * 
     * @type {CreateGeneralCargoAreaDtoGeometry}
     * @memberof CreateGeneralCargoAreaDto
     */
    'geometry': CreateGeneralCargoAreaDtoGeometry;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateGeneralCargoAreaDto
     */
    'locations': Array<string>;
}
/**
 * 
 * @export
 * @interface CreateGeneralCargoAreaDtoGeometry
 */
export interface CreateGeneralCargoAreaDtoGeometry {
    /**
     * 
     * @type {number}
     * @memberof CreateGeneralCargoAreaDtoGeometry
     */
    'top': number;
    /**
     * 
     * @type {number}
     * @memberof CreateGeneralCargoAreaDtoGeometry
     */
    'left': number;
    /**
     * 
     * @type {number}
     * @memberof CreateGeneralCargoAreaDtoGeometry
     */
    'rotation': number;
    /**
     * 
     * @type {number}
     * @memberof CreateGeneralCargoAreaDtoGeometry
     */
    'width': number;
}
/**
 * 
 * @export
 * @interface CreateGlobalAllocationAndRulesDto
 */
export interface CreateGlobalAllocationAndRulesDto {
    /**
     * 
     * @type {Array<CreateAllocationRuleDto>}
     * @memberof CreateGlobalAllocationAndRulesDto
     */
    'allocationRules': Array<CreateAllocationRuleDto>;
    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof CreateGlobalAllocationAndRulesDto
     */
    'direction': CarrierVisitDirection;
}
/**
 * 
 * @export
 * @interface CreateInternalMoveDto
 */
export interface CreateInternalMoveDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateInternalMoveDto
     */
    'containersNumbers': Array<string>;
    /**
     * 
     * @type {AllocationDestinationAndStackingStrategyDtoDestination}
     * @memberof CreateInternalMoveDto
     */
    'yardPosition': AllocationDestinationAndStackingStrategyDtoDestination;
    /**
     * 
     * @type {boolean}
     * @memberof CreateInternalMoveDto
     */
    'forceCreation': boolean;
}
/**
 * 
 * @export
 * @interface CreateManualAllocationDto
 */
export interface CreateManualAllocationDto {
    /**
     * 
     * @type {ContainerTurnoversFilterDto}
     * @memberof CreateManualAllocationDto
     */
    'filter'?: ContainerTurnoversFilterDto | null;
    /**
     * 
     * @type {string}
     * @memberof CreateManualAllocationDto
     */
    'containerTurnoverId'?: string | null;
    /**
     * 
     * @type {AllocationDestinationAndStackingStrategyDtoDestination}
     * @memberof CreateManualAllocationDto
     */
    'yardPosition': AllocationDestinationAndStackingStrategyDtoDestination;
    /**
     * 
     * @type {boolean}
     * @memberof CreateManualAllocationDto
     */
    'forceCreation': boolean;
}
/**
 * 
 * @export
 * @interface CreateMovesDto
 */
export interface CreateMovesDto {
    /**
     * 
     * @type {ContainerTurnoversFilterDto}
     * @memberof CreateMovesDto
     */
    'filter'?: ContainerTurnoversFilterDto | null;
    /**
     * 
     * @type {string}
     * @memberof CreateMovesDto
     */
    'containerTurnoverId'?: string | null;
}
/**
 * 
 * @export
 * @interface CreatePierDto
 */
export interface CreatePierDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePierDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateRailTrackDto
 */
export interface CreateRailTrackDto {
    /**
     * 
     * @type {string}
     * @memberof CreateRailTrackDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateRailTrackDto
     */
    'length'?: number | null;
}
/**
 * 
 * @export
 * @interface CreateWeightClassDto
 */
export interface CreateWeightClassDto {
    /**
     * 
     * @type {string}
     * @memberof CreateWeightClassDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateWeightClassDto
     */
    'minWeight': number;
    /**
     * 
     * @type {number}
     * @memberof CreateWeightClassDto
     */
    'maxWeight'?: number | null;
}
/**
 * 
 * @export
 * @interface CreateYardBlockBayDto
 */
export interface CreateYardBlockBayDto {
    /**
     * 
     * @type {string}
     * @memberof CreateYardBlockBayDto
     */
    'yardBlockId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateYardBlockBayDto
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateYardBlockBayDto
     */
    'nextToFirstBay': boolean;
}
/**
 * 
 * @export
 * @interface CreateYardBlockDto
 */
export interface CreateYardBlockDto {
    /**
     * 
     * @type {string}
     * @memberof CreateYardBlockDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateYardBlockDto
     */
    'maxTier': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateYardBlockDto
     */
    'isFlexibleZone': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateYardBlockDto
     */
    'bayNames': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateYardBlockDto
     */
    'rowNames': Array<string>;
    /**
     * 
     * @type {Array<EquipmentType>}
     * @memberof CreateYardBlockDto
     */
    'equipmentTypes': Array<EquipmentType>;
    /**
     * 
     * @type {YardBlockUsageOptions}
     * @memberof CreateYardBlockDto
     */
    'usageOptions'?: YardBlockUsageOptions | null;
    /**
     * 
     * @type {StackingStrategy}
     * @memberof CreateYardBlockDto
     */
    'stackingStrategy': StackingStrategy;
    /**
     * 
     * @type {BlockLabellingPattern}
     * @memberof CreateYardBlockDto
     */
    'bayLabellingPattern': BlockLabellingPattern;
    /**
     * 
     * @type {BlockLabellingPattern}
     * @memberof CreateYardBlockDto
     */
    'rowLabellingPattern': BlockLabellingPattern;
}
/**
 * 
 * @export
 * @interface CreateYardBlockRowDto
 */
export interface CreateYardBlockRowDto {
    /**
     * 
     * @type {string}
     * @memberof CreateYardBlockRowDto
     */
    'yardBlockId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateYardBlockRowDto
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateYardBlockRowDto
     */
    'nextToFirstRow': boolean;
}
/**
 * 
 * @export
 * @interface CreateYardInternalMoveDto
 */
export interface CreateYardInternalMoveDto {
    /**
     * 
     * @type {string}
     * @memberof CreateYardInternalMoveDto
     */
    'containerTurnoverId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateYardInternalMoveDto
     */
    'targetSlotId': string;
}
/**
 * 
 * @export
 * @interface CreateYardInternalMovesDto
 */
export interface CreateYardInternalMovesDto {
    /**
     * 
     * @type {Array<CreateYardInternalMoveDto>}
     * @memberof CreateYardInternalMovesDto
     */
    'moves': Array<CreateYardInternalMoveDto>;
}
/**
 * 
 * @export
 * @interface CreateYardPositionDto
 */
export interface CreateYardPositionDto {
    /**
     * 
     * @type {string}
     * @memberof CreateYardPositionDto
     */
    'containerTurnoverId': string;
    /**
     * 
     * @type {AllocationDestinationAndStackingStrategyDtoDestination}
     * @memberof CreateYardPositionDto
     */
    'location': AllocationDestinationAndStackingStrategyDtoDestination;
}
/**
 * 
 * @export
 * @interface CreateYardReservationDto
 */
export interface CreateYardReservationDto {
    /**
     * 
     * @type {string}
     * @memberof CreateYardReservationDto
     */
    'containerTurnoverId': string;
    /**
     * 
     * @type {AllocationDestinationAndStackingStrategyDtoDestination}
     * @memberof CreateYardReservationDto
     */
    'yardPosition': AllocationDestinationAndStackingStrategyDtoDestination;
    /**
     * 
     * @type {boolean}
     * @memberof CreateYardReservationDto
     */
    'forceCreation': boolean;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {Array<GeneralCargoStock>}
     * @memberof Customer
     */
    'stocks': Array<GeneralCargoStock>;
}
/**
 * 
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
    /**
     * 
     * @type {number}
     * @memberof CustomerDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface DGSegregationRuleDeleteBulkRequest
 */
export interface DGSegregationRuleDeleteBulkRequest {
    /**
     * 
     * @type {Array<ImoClassPairDto>}
     * @memberof DGSegregationRuleDeleteBulkRequest
     */
    'classPairs': Array<ImoClassPairDto>;
}
/**
 * 
 * @export
 * @interface DGSegregationRuleDto
 */
export interface DGSegregationRuleDto {
    /**
     * 
     * @type {string}
     * @memberof DGSegregationRuleDto
     */
    'imoClass1': string;
    /**
     * 
     * @type {string}
     * @memberof DGSegregationRuleDto
     */
    'imoClass2': string;
    /**
     * 
     * @type {number}
     * @memberof DGSegregationRuleDto
     */
    'distance': number;
}
/**
 * 
 * @export
 * @interface DGSegregationRuleUpsertBulkRequest
 */
export interface DGSegregationRuleUpsertBulkRequest {
    /**
     * 
     * @type {Array<DGSegregationRuleDto>}
     * @memberof DGSegregationRuleUpsertBulkRequest
     */
    'rules': Array<DGSegregationRuleDto>;
}
/**
 * 
 * @export
 * @interface DangerousGoodsStackingRuleDto
 */
export interface DangerousGoodsStackingRuleDto {
    /**
     * 
     * @type {string}
     * @memberof DangerousGoodsStackingRuleDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DangerousGoodsStackingRuleDto
     */
    'imoClass': string;
    /**
     * 
     * @type {number}
     * @memberof DangerousGoodsStackingRuleDto
     */
    'maxStack'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DangerousGoodsStackingRuleDto
     */
    'isStackingContainerOnTopAllowed': boolean;
    /**
     * 
     * @type {Array<DangerousGoodsStackingRuleDto>}
     * @memberof DangerousGoodsStackingRuleDto
     */
    'subClasses': Array<DangerousGoodsStackingRuleDto>;
}
/**
 * 
 * @export
 * @interface DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest
 */
export interface DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest {
    /**
     * 
     * @type {string}
     * @memberof DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest
     */
    'isStackingContainerOnTopAllowed': boolean;
    /**
     * 
     * @type {number}
     * @memberof DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest
     */
    'maxStack'?: number | null;
}
/**
 * 
 * @export
 * @interface DangerousGoodsValidationDto
 */
export interface DangerousGoodsValidationDto {
    /**
     * 
     * @type {DangerousGoodsValidationDtoFilter}
     * @memberof DangerousGoodsValidationDto
     */
    'filter': DangerousGoodsValidationDtoFilter;
    /**
     * 
     * @type {AllocationDestinationAndStackingStrategyDtoDestination}
     * @memberof DangerousGoodsValidationDto
     */
    'yardPosition': AllocationDestinationAndStackingStrategyDtoDestination;
}
/**
 * 
 * @export
 * @interface DangerousGoodsValidationDtoFilter
 */
export interface DangerousGoodsValidationDtoFilter {
    /**
     * 
     * @type {string}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'containerOrReferenceNumber'?: string | null;
    /**
     * 
     * @type {CarrierType}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'inboundCarrierType'?: CarrierType | null;
    /**
     * 
     * @type {CarrierType}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'outboundCarrierType'?: CarrierType | null;
    /**
     * 
     * @type {number}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'inboundCarrier'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'outboundCarrier'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'isEmpty'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'size'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'containerIsoCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'typeCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'portOfLoading'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'portOfDischarge'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'weightClasses': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'isReefer'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'isDamaged'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'imoClasses': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'isRestow'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'containerOperator'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'consignee'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'isDangerous'?: boolean | null;
    /**
     * 
     * @type {ContainerStatus}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'locationStatus'?: ContainerStatus | null;
    /**
     * 
     * @type {ContainerType}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'containerType'?: ContainerType | null;
    /**
     * 
     * @type {ContainerHeight}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'containerHeight'?: ContainerHeight | null;
    /**
     * 
     * @type {boolean}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'isInTerminal'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof DangerousGoodsValidationDtoFilter
     */
    'customerId'?: number | null;
}
/**
 * 
 * @export
 * @interface DeleteDGSegregationRulesDto
 */
export interface DeleteDGSegregationRulesDto {
    /**
     * 
     * @type {Array<ImoClassPairDto>}
     * @memberof DeleteDGSegregationRulesDto
     */
    'classPairs': Array<ImoClassPairDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DeviceOperationMode = {
    Top: 'Top',
    Bay: 'Bay'
} as const;

export type DeviceOperationMode = typeof DeviceOperationMode[keyof typeof DeviceOperationMode];


/**
 * 
 * @export
 * @enum {string}
 */

export const EquipmentType = {
    Sts: 'STS',
    Rtg: 'RTG',
    Rs: 'RS',
    Ech: 'ECH',
    Fl: 'FL',
    Rmg: 'RMG'
} as const;

export type EquipmentType = typeof EquipmentType[keyof typeof EquipmentType];


/**
 * 
 * @export
 * @interface EquipmentTypeDto
 */
export interface EquipmentTypeDto {
    /**
     * 
     * @type {string}
     * @memberof EquipmentTypeDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentTypeDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentTypeDto
     */
    'remarks'?: string | null;
}
/**
 * 
 * @export
 * @interface EquipmentTypesCreateRequest
 */
export interface EquipmentTypesCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentTypesCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentTypesCreateRequest
     */
    'remarks'?: string | null;
}
/**
 * 
 * @export
 * @interface EquipmentTypesUpdateRequest
 */
export interface EquipmentTypesUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentTypesUpdateRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentTypesUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentTypesUpdateRequest
     */
    'remarks'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ErrorCodes = {
    EntityNotFound: 'ENTITY_NOT_FOUND',
    ContainerAlreadyHasPosition: 'CONTAINER_ALREADY_HAS_POSITION',
    YardBlockDoesNotExist: 'YARD_BLOCK_DOES_NOT_EXIST',
    YardBlockIsNotEmpty: 'YARD_BLOCK_IS_NOT_EMPTY',
    BerthDoesNotExist: 'BERTH_DOES_NOT_EXIST',
    CannotCreateGroupingWithoutSizeAttribute: 'CANNOT_CREATE_GROUPING_WITHOUT_SIZE_ATTRIBUTE',
    CannotCreateGroupingWithoutContainers: 'CANNOT_CREATE_GROUPING_WITHOUT_CONTAINERS',
    FortyContainerSizeBaysRequireTwoBays: 'FORTY_CONTAINER_SIZE_BAYS_REQUIRE_TWO_BAYS',
    BaysCannotBeTransformedToContainerSizeTwenty: 'BAYS_CANNOT_BE_TRANSFORMED_TO_CONTAINER_SIZE_TWENTY',
    YardBlockNameAlreadyExists: 'YARD_BLOCK_NAME_ALREADY_EXISTS',
    AllocationSpaceConflict: 'ALLOCATION_SPACE_CONFLICT',
    PreplanningMixedDangerousAndNonDangerous: 'PREPLANNING_MIXED_DANGEROUS_AND_NON_DANGEROUS',
    PreplanningMixedFullAndEmpties: 'PREPLANNING_MIXED_FULL_AND_EMPTIES',
    PreplanningHasReserved: 'PREPLANNING_HAS_RESERVED',
    PreplanningMixedContainerTurnoverSizes: 'PREPLANNING_MIXED_CONTAINER_TURNOVER_SIZES',
    PreplanningMixedDangerousareaNonDangerouscontainers: 'PREPLANNING_MIXED_DANGEROUSAREA_NON_DANGEROUSCONTAINERS',
    PreplanningMixedNonDangerousareaDangerouscontainers: 'PREPLANNING_MIXED_NON_DANGEROUSAREA_DANGEROUSCONTAINERS',
    YardBlockBayRowNonUnique: 'YARD_BLOCK_BAY_ROW_NON_UNIQUE',
    GeneralCargoLocationHasOccupancy: 'GENERAL_CARGO_LOCATION_HAS_OCCUPANCY',
    GeneralCargoOccupancyDoesNotExist: 'GENERAL_CARGO_OCCUPANCY_DOES_NOT_EXIST',
    PlanningAndUnitPropertiesQuantityMismatch: 'PLANNING_AND_UNIT_PROPERTIES_QUANTITY_MISMATCH',
    WeightClassRangeOverlap: 'WEIGHT_CLASS_RANGE_OVERLAP',
    UnNumberMustBe4CharactersLong: 'UN_NUMBER_MUST_BE_4_CHARACTERS_LONG',
    DgStackingRulesViolationStackingOnTop: 'DG_STACKING_RULES_VIOLATION_STACKING_ON_TOP',
    DgStackingRulesViolationMaxAllowedTier: 'DG_STACKING_RULES_VIOLATION_MAX_ALLOWED_TIER',
    DgSegregationRulesViolation: 'DG_SEGREGATION_RULES_VIOLATION',
    CannotCreateInternalMove: 'CANNOT_CREATE_INTERNAL_MOVE'
} as const;

export type ErrorCodes = typeof ErrorCodes[keyof typeof ErrorCodes];


/**
 * 
 * @export
 * @enum {string}
 */

export const EventType = {
    Unknown: 'UNKNOWN',
    TestString: 'TEST_STRING',
    OrdersUpserted: 'ORDERS_UPSERTED',
    OrdersDeleted: 'ORDERS_DELETED',
    OrderUpdatesUpsertedEvent: 'ORDER_UPDATES_UPSERTED_EVENT',
    WorkInstructionCreated: 'WORK_INSTRUCTION_CREATED',
    YardBlockSlotReservationCreated: 'YARD_BLOCK_SLOT_RESERVATION_CREATED',
    ContainerMoved: 'CONTAINER_MOVED',
    WorkInstructionFinished: 'WORK_INSTRUCTION_FINISHED',
    VesselReadyForOperation: 'VESSEL_READY_FOR_OPERATION',
    TrainReadyForOperation: 'TRAIN_READY_FOR_OPERATION',
    TruckHasBeenAllowedEntry: 'TRUCK_HAS_BEEN_ALLOWED_ENTRY',
    OrderHasBeenAllowedEntry: 'ORDER_HAS_BEEN_ALLOWED_ENTRY',
    OrderHasBeenAllowedExit: 'ORDER_HAS_BEEN_ALLOWED_EXIT',
    TruckHasBeenAllowedExit: 'TRUCK_HAS_BEEN_ALLOWED_EXIT',
    VesselVisitUpserted: 'VESSEL_VISIT_UPSERTED',
    YardBlockCreated: 'YARD_BLOCK_CREATED',
    YardBlockUpdated: 'YARD_BLOCK_UPDATED',
    YardBlockDeleted: 'YARD_BLOCK_DELETED',
    YardBlockSlotsCreated: 'YARD_BLOCK_SLOTS_CREATED',
    YardBlockSlotsUpdated: 'YARD_BLOCK_SLOTS_UPDATED',
    YardBlockSlotsDeleted: 'YARD_BLOCK_SLOTS_DELETED',
    WorkInstructionStarted: 'WORK_INSTRUCTION_STARTED',
    CancelWorkInstructionStarted: 'CANCEL_WORK_INSTRUCTION_STARTED',
    YardInternalMovesPlanned: 'YARD_INTERNAL_MOVES_PLANNED',
    BerthModified: 'BERTH_MODIFIED',
    ContainerPositionUpdated: 'CONTAINER_POSITION_UPDATED',
    ContainerStackIn: 'CONTAINER_STACK_IN',
    YardBlockSlotReservationFailed: 'YARD_BLOCK_SLOT_RESERVATION_FAILED',
    CarrierVisitsDeleted: 'CARRIER_VISITS_DELETED',
    ReeferTemperatureUpserted: 'REEFER_TEMPERATURE_UPSERTED',
    ContainersUpserted: 'CONTAINERS_UPSERTED',
    CustomerUpserted: 'CUSTOMER_UPSERTED',
    CustomerDeleted: 'CUSTOMER_DELETED',
    TenantUpserted: 'TENANT_UPSERTED',
    RailVisitUpserted: 'RAIL_VISIT_UPSERTED',
    MoveTriggered: 'MOVE_TRIGGERED',
    CommodityUpserted: 'COMMODITY_UPSERTED',
    CommodityDeleted: 'COMMODITY_DELETED',
    PackageUpserted: 'PACKAGE_UPSERTED',
    PackageDeleted: 'PACKAGE_DELETED',
    StuffingOrderUpserted: 'STUFFING_ORDER_UPSERTED',
    StuffingOrderDeleted: 'STUFFING_ORDER_DELETED',
    StrippingOrderUpserted: 'STRIPPING_ORDER_UPSERTED',
    StrippingOrderDeleted: 'STRIPPING_ORDER_DELETED',
    TenantDeleted: 'TENANT_DELETED',
    CoolingOrderUpserted: 'COOLING_ORDER_UPSERTED',
    CoolingOrderDeleted: 'COOLING_ORDER_DELETED',
    ContainersPlannedBlockUpserted: 'CONTAINERS_PLANNED_BLOCK_UPSERTED',
    RailTrackUpserted: 'RAIL_TRACK_UPSERTED',
    RailTrackDeleted: 'RAIL_TRACK_DELETED',
    RailcarTrackPositionsUpserted: 'RAILCAR_TRACK_POSITIONS_UPSERTED',
    RailcarTrackPositionsDeleted: 'RAILCAR_TRACK_POSITIONS_DELETED',
    RailcarTrackPositionReadyForOperation: 'RAILCAR_TRACK_POSITION_READY_FOR_OPERATION',
    TruckVisitUpserted: 'TRUCK_VISIT_UPSERTED',
    ContainerDamageLocationUpserted: 'CONTAINER_DAMAGE_LOCATION_UPSERTED',
    ContainerDamageLocationDeleted: 'CONTAINER_DAMAGE_LOCATION_DELETED',
    ContainerDamageTypeUpserted: 'CONTAINER_DAMAGE_TYPE_UPSERTED',
    ContainerDamageTypeDeleted: 'CONTAINER_DAMAGE_TYPE_DELETED',
    RailcarTrackPositionDepartureCheck: 'RAILCAR_TRACK_POSITION_DEPARTURE_CHECK',
    IsoCodeMappingDeleted: 'ISO_CODE_MAPPING_DELETED',
    IsoCodeMappingUpserted: 'ISO_CODE_MAPPING_UPSERTED',
    ContainerAnnouncementReceived: 'CONTAINER_ANNOUNCEMENT_RECEIVED',
    OrderAnnouncementsUpserted: 'ORDER_ANNOUNCEMENTS_UPSERTED',
    OrderAnnouncementsDeleted: 'ORDER_ANNOUNCEMENTS_DELETED',
    NumericReleaseOrdersUpserted: 'NUMERIC_RELEASE_ORDERS_UPSERTED',
    NumericReleaseOrdersDeleted: 'NUMERIC_RELEASE_ORDERS_DELETED',
    NonNumericReleaseOrdersUpserted: 'NON_NUMERIC_RELEASE_ORDERS_UPSERTED',
    NonNumericReleaseOrdersDeleted: 'NON_NUMERIC_RELEASE_ORDERS_DELETED',
    InboundMessageParsed: 'INBOUND_MESSAGE_PARSED',
    OutboundMessageRequested: 'OUTBOUND_MESSAGE_REQUESTED',
    RemainOnBoardUnitsUpserted: 'REMAIN_ON_BOARD_UNITS_UPSERTED',
    RemainOnBoardUnitsDeleted: 'REMAIN_ON_BOARD_UNITS_DELETED'
} as const;

export type EventType = typeof EventType[keyof typeof EventType];


/**
 * 
 * @export
 * @interface GeneralCargoArea
 */
export interface GeneralCargoArea {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoArea
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoArea
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoArea
     */
    'name': string;
    /**
     * 
     * @type {GeneralCargoAreaType}
     * @memberof GeneralCargoArea
     */
    'type': GeneralCargoAreaType;
    /**
     * 
     * @type {GeneralCargoAreaGeometry}
     * @memberof GeneralCargoArea
     */
    'geometry'?: GeneralCargoAreaGeometry | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoArea
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoArea
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {Array<GeneralCargoLocation>}
     * @memberof GeneralCargoArea
     */
    'locations': Array<GeneralCargoLocation>;
}
/**
 * 
 * @export
 * @interface GeneralCargoAreaDto
 */
export interface GeneralCargoAreaDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoAreaDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoAreaDto
     */
    'name': string;
    /**
     * 
     * @type {GeneralCargoAreaType}
     * @memberof GeneralCargoAreaDto
     */
    'type': GeneralCargoAreaType;
    /**
     * 
     * @type {CreateGeneralCargoAreaDtoGeometry}
     * @memberof GeneralCargoAreaDto
     */
    'geometry': CreateGeneralCargoAreaDtoGeometry;
    /**
     * 
     * @type {Array<GeneralCargoLocationDto>}
     * @memberof GeneralCargoAreaDto
     */
    'locations': Array<GeneralCargoLocationDto>;
}
/**
 * 
 * @export
 * @interface GeneralCargoAreaGeometry
 */
export interface GeneralCargoAreaGeometry {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoAreaGeometry
     */
    'top': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoAreaGeometry
     */
    'left': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoAreaGeometry
     */
    'rotation': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoAreaGeometry
     */
    'width': number;
}
/**
 * 
 * @export
 * @interface GeneralCargoAreaGeometryDto
 */
export interface GeneralCargoAreaGeometryDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoAreaGeometryDto
     */
    'top': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoAreaGeometryDto
     */
    'left': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoAreaGeometryDto
     */
    'rotation': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoAreaGeometryDto
     */
    'width': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GeneralCargoAreaType = {
    Warehouse: 'Warehouse',
    OpenArea: 'OpenArea'
} as const;

export type GeneralCargoAreaType = typeof GeneralCargoAreaType[keyof typeof GeneralCargoAreaType];


/**
 * 
 * @export
 * @interface GeneralCargoAreasCreateRequest
 */
export interface GeneralCargoAreasCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoAreasCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {GeneralCargoAreaType}
     * @memberof GeneralCargoAreasCreateRequest
     */
    'type': GeneralCargoAreaType;
    /**
     * 
     * @type {CreateGeneralCargoAreaDtoGeometry}
     * @memberof GeneralCargoAreasCreateRequest
     */
    'geometry': CreateGeneralCargoAreaDtoGeometry;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralCargoAreasCreateRequest
     */
    'locations': Array<string>;
}
/**
 * 
 * @export
 * @interface GeneralCargoAreasUpdateRequest
 */
export interface GeneralCargoAreasUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoAreasUpdateRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoAreasUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {GeneralCargoAreaType}
     * @memberof GeneralCargoAreasUpdateRequest
     */
    'type': GeneralCargoAreaType;
    /**
     * 
     * @type {Array<GeneralCargoLocationDto>}
     * @memberof GeneralCargoAreasUpdateRequest
     */
    'locations': Array<GeneralCargoLocationDto>;
}
/**
 * 
 * @export
 * @interface GeneralCargoInventoryDto
 */
export interface GeneralCargoInventoryDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoInventoryDto
     */
    'id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralCargoInventoryDto
     */
    'stockIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoInventoryDto
     */
    'lotNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoInventoryDto
     */
    'commodityId': number;
    /**
     * 
     * @type {GeneralCargoInventoryDtoCommodity}
     * @memberof GeneralCargoInventoryDto
     */
    'commodity': GeneralCargoInventoryDtoCommodity;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoInventoryDto
     */
    'customerId': number;
    /**
     * 
     * @type {GeneralCargoInventoryDtoCustomer}
     * @memberof GeneralCargoInventoryDto
     */
    'customer': GeneralCargoInventoryDtoCustomer;
    /**
     * 
     * @type {Array<GeneralCargoInventoryOccupancyDto>}
     * @memberof GeneralCargoInventoryDto
     */
    'occupancies': Array<GeneralCargoInventoryOccupancyDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralCargoInventoryDto
     */
    'imoClasses': Array<string>;
}
/**
 * 
 * @export
 * @interface GeneralCargoInventoryDtoCommodity
 */
export interface GeneralCargoInventoryDtoCommodity {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoInventoryDtoCommodity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoInventoryDtoCommodity
     */
    'name': string;
    /**
     * 
     * @type {Array<CommodityAttribute>}
     * @memberof GeneralCargoInventoryDtoCommodity
     */
    'attributes': Array<CommodityAttribute>;
}
/**
 * 
 * @export
 * @interface GeneralCargoInventoryDtoCustomer
 */
export interface GeneralCargoInventoryDtoCustomer {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoInventoryDtoCustomer
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoInventoryDtoCustomer
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GeneralCargoInventoryOccupancyDto
 */
export interface GeneralCargoInventoryOccupancyDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoInventoryOccupancyDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoInventoryOccupancyDto
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoInventoryOccupancyDto
     */
    'generalCargoLocation': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoInventoryOccupancyDto
     */
    'generalCargoArea': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoInventoryOccupancyDto
     */
    'stockId': string;
    /**
     * 
     * @type {GeneralCargoInventoryOccupancyDtoPackage}
     * @memberof GeneralCargoInventoryOccupancyDto
     */
    'package': GeneralCargoInventoryOccupancyDtoPackage;
    /**
     * 
     * @type {Array<GeneralCargoStockOccupancyDamageDto>}
     * @memberof GeneralCargoInventoryOccupancyDto
     */
    'damages': Array<GeneralCargoStockOccupancyDamageDto>;
    /**
     * 
     * @type {Array<GeneralCargoItemPropertiesDto>}
     * @memberof GeneralCargoInventoryOccupancyDto
     */
    'properties': Array<GeneralCargoItemPropertiesDto>;
}
/**
 * 
 * @export
 * @interface GeneralCargoInventoryOccupancyDtoPackage
 */
export interface GeneralCargoInventoryOccupancyDtoPackage {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoInventoryOccupancyDtoPackage
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoInventoryOccupancyDtoPackage
     */
    'name': string;
    /**
     * 
     * @type {WeightMeasurementUnit}
     * @memberof GeneralCargoInventoryOccupancyDtoPackage
     */
    'weightUnit': WeightMeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoInventoryOccupancyDtoPackage
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoInventoryOccupancyDtoPackage
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoInventoryOccupancyDtoPackage
     */
    'length'?: number | null;
    /**
     * 
     * @type {LengthMeasurementUnit}
     * @memberof GeneralCargoInventoryOccupancyDtoPackage
     */
    'lengthUnit'?: LengthMeasurementUnit | null;
}
/**
 * 
 * @export
 * @interface GeneralCargoItem
 */
export interface GeneralCargoItem {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItem
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItem
     */
    'cargoItemId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItem
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItem
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItem
     */
    'imoClasses'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItem
     */
    'instructions'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItem
     */
    'grossWeight'?: number | null;
    /**
     * 
     * @type {WeightMeasurementUnit}
     * @memberof GeneralCargoItem
     */
    'weightUnit'?: WeightMeasurementUnit | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItem
     */
    'generalCargoOrderId': string;
    /**
     * 
     * @type {GeneralCargoItemOrder}
     * @memberof GeneralCargoItem
     */
    'order': GeneralCargoItemOrder;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItem
     */
    'generalCargoStockId': string;
    /**
     * 
     * @type {GeneralCargoItemStock}
     * @memberof GeneralCargoItem
     */
    'stock': GeneralCargoItemStock;
    /**
     * 
     * @type {Array<GeneralCargoItemProperties>}
     * @memberof GeneralCargoItem
     */
    'properties': Array<GeneralCargoItemProperties>;
    /**
     * 
     * @type {Array<GeneralCargoItemPlanning>}
     * @memberof GeneralCargoItem
     */
    'planned': Array<GeneralCargoItemPlanning>;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItem
     */
    'plannedItemQuantity': number;
}
/**
 * 
 * @export
 * @interface GeneralCargoItemDto
 */
export interface GeneralCargoItemDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemDto
     */
    'cargoItemId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemDto
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemDto
     */
    'imoClasses'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemDto
     */
    'instructions'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemDto
     */
    'grossWeight'?: number | null;
    /**
     * 
     * @type {WeightMeasurementUnit}
     * @memberof GeneralCargoItemDto
     */
    'weightUnit'?: WeightMeasurementUnit | null;
    /**
     * 
     * @type {Array<GeneralCargoItemPropertiesDto>}
     * @memberof GeneralCargoItemDto
     */
    'properties': Array<GeneralCargoItemPropertiesDto>;
}
/**
 * 
 * @export
 * @interface GeneralCargoItemOrder
 */
export interface GeneralCargoItemOrder {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemOrder
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemOrder
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemOrder
     */
    'orderId': number;
    /**
     * 
     * @type {GeneralCargoOrderType}
     * @memberof GeneralCargoItemOrder
     */
    'orderType': GeneralCargoOrderType;
    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof GeneralCargoItemOrder
     */
    'direction': CarrierVisitDirection;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemOrder
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof GeneralCargoItemOrder
     */
    'status': OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemOrder
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemOrder
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {Array<GeneralCargoItem>}
     * @memberof GeneralCargoItemOrder
     */
    'items': Array<GeneralCargoItem>;
}
/**
 * 
 * @export
 * @interface GeneralCargoItemPlanning
 */
export interface GeneralCargoItemPlanning {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemPlanning
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemPlanning
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemPlanning
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemPlanning
     */
    'generalCargoItemId': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemPlanning
     */
    'generalCargoLocationId': string;
    /**
     * 
     * @type {GeneralCargoItemPlanningItem}
     * @memberof GeneralCargoItemPlanning
     */
    'item': GeneralCargoItemPlanningItem;
    /**
     * 
     * @type {GeneralCargoItemPlanningLocation}
     * @memberof GeneralCargoItemPlanning
     */
    'location': GeneralCargoItemPlanningLocation;
}
/**
 * 
 * @export
 * @interface GeneralCargoItemPlanningDto
 */
export interface GeneralCargoItemPlanningDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemPlanningDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemPlanningDto
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemPlanningDto
     */
    'generalCargoLocation': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemPlanningDto
     */
    'generalCargoArea': string;
}
/**
 * 
 * @export
 * @interface GeneralCargoItemPlanningItem
 */
export interface GeneralCargoItemPlanningItem {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemPlanningItem
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemPlanningItem
     */
    'cargoItemId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemPlanningItem
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemPlanningItem
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemPlanningItem
     */
    'imoClasses'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemPlanningItem
     */
    'instructions'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemPlanningItem
     */
    'grossWeight'?: number | null;
    /**
     * 
     * @type {WeightMeasurementUnit}
     * @memberof GeneralCargoItemPlanningItem
     */
    'weightUnit'?: WeightMeasurementUnit | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemPlanningItem
     */
    'generalCargoOrderId': string;
    /**
     * 
     * @type {GeneralCargoItemOrder}
     * @memberof GeneralCargoItemPlanningItem
     */
    'order': GeneralCargoItemOrder;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemPlanningItem
     */
    'generalCargoStockId': string;
    /**
     * 
     * @type {GeneralCargoItemStock}
     * @memberof GeneralCargoItemPlanningItem
     */
    'stock': GeneralCargoItemStock;
    /**
     * 
     * @type {Array<GeneralCargoItemProperties>}
     * @memberof GeneralCargoItemPlanningItem
     */
    'properties': Array<GeneralCargoItemProperties>;
    /**
     * 
     * @type {Array<GeneralCargoItemPlanning>}
     * @memberof GeneralCargoItemPlanningItem
     */
    'planned': Array<GeneralCargoItemPlanning>;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemPlanningItem
     */
    'plannedItemQuantity': number;
}
/**
 * 
 * @export
 * @interface GeneralCargoItemPlanningLocation
 */
export interface GeneralCargoItemPlanningLocation {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemPlanningLocation
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemPlanningLocation
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemPlanningLocation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemPlanningLocation
     */
    'generalCargoAreaId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemPlanningLocation
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemPlanningLocation
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {GeneralCargoLocationGeneralCargoArea}
     * @memberof GeneralCargoItemPlanningLocation
     */
    'generalCargoArea': GeneralCargoLocationGeneralCargoArea;
    /**
     * 
     * @type {Array<GeneralCargoStockOccupancy>}
     * @memberof GeneralCargoItemPlanningLocation
     */
    'occupancies': Array<GeneralCargoStockOccupancy>;
    /**
     * 
     * @type {Array<GeneralCargoItemPlanning>}
     * @memberof GeneralCargoItemPlanningLocation
     */
    'planned': Array<GeneralCargoItemPlanning>;
}
/**
 * 
 * @export
 * @interface GeneralCargoItemProperties
 */
export interface GeneralCargoItemProperties {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemProperties
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemProperties
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemProperties
     */
    'unitId': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemProperties
     */
    'generalCargoItemId'?: string | null;
    /**
     * 
     * @type {GeneralCargoItem}
     * @memberof GeneralCargoItemProperties
     */
    'item'?: GeneralCargoItem | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemProperties
     */
    'generalCargoOrderPlanningId'?: string | null;
    /**
     * 
     * @type {GeneralCargoItemPlanning}
     * @memberof GeneralCargoItemProperties
     */
    'planning'?: GeneralCargoItemPlanning | null;
}
/**
 * 
 * @export
 * @interface GeneralCargoItemPropertiesDto
 */
export interface GeneralCargoItemPropertiesDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemPropertiesDto
     */
    'unitId': string;
}
/**
 * 
 * @export
 * @interface GeneralCargoItemStock
 */
export interface GeneralCargoItemStock {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemStock
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemStock
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemStock
     */
    'commodityId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemStock
     */
    'customerId': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemStock
     */
    'lotNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemStock
     */
    'packageId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemStock
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoItemStock
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {GeneralCargoStockCommodity}
     * @memberof GeneralCargoItemStock
     */
    'commodity': GeneralCargoStockCommodity;
    /**
     * 
     * @type {GeneralCargoStockCustomer}
     * @memberof GeneralCargoItemStock
     */
    'customer': GeneralCargoStockCustomer;
    /**
     * 
     * @type {Package}
     * @memberof GeneralCargoItemStock
     */
    'package'?: Package | null;
    /**
     * 
     * @type {Array<GeneralCargoItem>}
     * @memberof GeneralCargoItemStock
     */
    'items': Array<GeneralCargoItem>;
    /**
     * 
     * @type {Array<GeneralCargoStockOccupancy>}
     * @memberof GeneralCargoItemStock
     */
    'occupancies': Array<GeneralCargoStockOccupancy>;
    /**
     * 
     * @type {Array<ImoDeclaration>}
     * @memberof GeneralCargoItemStock
     */
    'imoDeclarations': Array<ImoDeclaration>;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemStock
     */
    'totalQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemStock
     */
    'expectedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemStock
     */
    'plannedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoItemStock
     */
    'requestedQuantity': number;
}
/**
 * 
 * @export
 * @interface GeneralCargoLocation
 */
export interface GeneralCargoLocation {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoLocation
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoLocation
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoLocation
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoLocation
     */
    'generalCargoAreaId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoLocation
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoLocation
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {GeneralCargoLocationGeneralCargoArea}
     * @memberof GeneralCargoLocation
     */
    'generalCargoArea': GeneralCargoLocationGeneralCargoArea;
    /**
     * 
     * @type {Array<GeneralCargoStockOccupancy>}
     * @memberof GeneralCargoLocation
     */
    'occupancies': Array<GeneralCargoStockOccupancy>;
    /**
     * 
     * @type {Array<GeneralCargoItemPlanning>}
     * @memberof GeneralCargoLocation
     */
    'planned': Array<GeneralCargoItemPlanning>;
}
/**
 * 
 * @export
 * @interface GeneralCargoLocationDto
 */
export interface GeneralCargoLocationDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoLocationDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoLocationDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface GeneralCargoLocationGeneralCargoArea
 */
export interface GeneralCargoLocationGeneralCargoArea {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoLocationGeneralCargoArea
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoLocationGeneralCargoArea
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoLocationGeneralCargoArea
     */
    'name': string;
    /**
     * 
     * @type {GeneralCargoAreaType}
     * @memberof GeneralCargoLocationGeneralCargoArea
     */
    'type': GeneralCargoAreaType;
    /**
     * 
     * @type {GeneralCargoAreaGeometry}
     * @memberof GeneralCargoLocationGeneralCargoArea
     */
    'geometry'?: GeneralCargoAreaGeometry | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoLocationGeneralCargoArea
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoLocationGeneralCargoArea
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {Array<GeneralCargoLocation>}
     * @memberof GeneralCargoLocationGeneralCargoArea
     */
    'locations': Array<GeneralCargoLocation>;
}
/**
 * 
 * @export
 * @interface GeneralCargoOccupancyActionDto
 */
export interface GeneralCargoOccupancyActionDto {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoOccupancyActionDto
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoOccupancyActionDto
     */
    'locationName': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoOccupancyActionDto
     */
    'areaName': string;
    /**
     * 
     * @type {boolean}
     * @memberof GeneralCargoOccupancyActionDto
     */
    'isDamaged': boolean;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoOccupancyActionDto
     */
    'damageDescription': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralCargoOccupancyActionDto
     */
    'damageImagePaths': Array<string>;
    /**
     * 
     * @type {Array<GeneralCargoItemPropertiesDto>}
     * @memberof GeneralCargoOccupancyActionDto
     */
    'properties': Array<GeneralCargoItemPropertiesDto>;
}
/**
 * 
 * @export
 * @interface GeneralCargoOrder
 */
export interface GeneralCargoOrder {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoOrder
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoOrder
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoOrder
     */
    'orderId': number;
    /**
     * 
     * @type {GeneralCargoOrderType}
     * @memberof GeneralCargoOrder
     */
    'orderType': GeneralCargoOrderType;
    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof GeneralCargoOrder
     */
    'direction': CarrierVisitDirection;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoOrder
     */
    'referenceNumber'?: string | null;
    /**
     * 
     * @type {OrderStatus}
     * @memberof GeneralCargoOrder
     */
    'status': OrderStatus;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoOrder
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoOrder
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {Array<GeneralCargoItem>}
     * @memberof GeneralCargoOrder
     */
    'items': Array<GeneralCargoItem>;
}
/**
 * 
 * @export
 * @interface GeneralCargoOrderDto
 */
export interface GeneralCargoOrderDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoOrderDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoOrderDto
     */
    'orderId': number;
    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof GeneralCargoOrderDto
     */
    'direction': CarrierVisitDirection;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoOrderDto
     */
    'referenceNumber': string;
    /**
     * 
     * @type {OrderStatus}
     * @memberof GeneralCargoOrderDto
     */
    'status': OrderStatus;
    /**
     * 
     * @type {Array<GeneralCargoItemDto>}
     * @memberof GeneralCargoOrderDto
     */
    'items': Array<GeneralCargoItemDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const GeneralCargoOrderType = {
    Standard: 'Standard',
    Stripping: 'Stripping',
    Stuffing: 'Stuffing'
} as const;

export type GeneralCargoOrderType = typeof GeneralCargoOrderType[keyof typeof GeneralCargoOrderType];


/**
 * 
 * @export
 * @interface GeneralCargoOutboundValidationFailsDto
 */
export interface GeneralCargoOutboundValidationFailsDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralCargoOutboundValidationFailsDto
     */
    'unavailableUnitIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralCargoOutboundValidationFailsDto
     */
    'stockImoClasses': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoOutboundValidationFailsDto
     */
    'requestedOrderQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoOutboundValidationFailsDto
     */
    'requestedTotalQuantity'?: number | null;
}
/**
 * 
 * @export
 * @interface GeneralCargoOutboundValidationResultDto
 */
export interface GeneralCargoOutboundValidationResultDto {
    /**
     * 
     * @type {boolean}
     * @memberof GeneralCargoOutboundValidationResultDto
     */
    'status': boolean;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoOutboundValidationResultDto
     */
    'stockId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoOutboundValidationResultDto
     */
    'availableQuantity'?: number | null;
    /**
     * 
     * @type {GeneralCargoOutboundValidationResultDtoFails}
     * @memberof GeneralCargoOutboundValidationResultDto
     */
    'fails': GeneralCargoOutboundValidationResultDtoFails;
}
/**
 * 
 * @export
 * @interface GeneralCargoOutboundValidationResultDtoFails
 */
export interface GeneralCargoOutboundValidationResultDtoFails {
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralCargoOutboundValidationResultDtoFails
     */
    'unavailableUnitIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralCargoOutboundValidationResultDtoFails
     */
    'stockImoClasses': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoOutboundValidationResultDtoFails
     */
    'requestedOrderQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoOutboundValidationResultDtoFails
     */
    'requestedTotalQuantity'?: number | null;
}
/**
 * 
 * @export
 * @interface GeneralCargoStock
 */
export interface GeneralCargoStock {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStock
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStock
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStock
     */
    'commodityId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStock
     */
    'customerId': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStock
     */
    'lotNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStock
     */
    'packageId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStock
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStock
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {GeneralCargoStockCommodity}
     * @memberof GeneralCargoStock
     */
    'commodity': GeneralCargoStockCommodity;
    /**
     * 
     * @type {GeneralCargoStockCustomer}
     * @memberof GeneralCargoStock
     */
    'customer': GeneralCargoStockCustomer;
    /**
     * 
     * @type {Package}
     * @memberof GeneralCargoStock
     */
    'package'?: Package | null;
    /**
     * 
     * @type {Array<GeneralCargoItem>}
     * @memberof GeneralCargoStock
     */
    'items': Array<GeneralCargoItem>;
    /**
     * 
     * @type {Array<GeneralCargoStockOccupancy>}
     * @memberof GeneralCargoStock
     */
    'occupancies': Array<GeneralCargoStockOccupancy>;
    /**
     * 
     * @type {Array<ImoDeclaration>}
     * @memberof GeneralCargoStock
     */
    'imoDeclarations': Array<ImoDeclaration>;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStock
     */
    'totalQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStock
     */
    'expectedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStock
     */
    'plannedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStock
     */
    'requestedQuantity': number;
}
/**
 * 
 * @export
 * @interface GeneralCargoStockCommodity
 */
export interface GeneralCargoStockCommodity {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockCommodity
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockCommodity
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockCommodity
     */
    'name': string;
    /**
     * 
     * @type {Array<CommodityAttribute>}
     * @memberof GeneralCargoStockCommodity
     */
    'attributes': Array<CommodityAttribute>;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockCommodity
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockCommodity
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {Array<GeneralCargoStock>}
     * @memberof GeneralCargoStockCommodity
     */
    'stocks': Array<GeneralCargoStock>;
}
/**
 * 
 * @export
 * @interface GeneralCargoStockCustomer
 */
export interface GeneralCargoStockCustomer {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockCustomer
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockCustomer
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockCustomer
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockCustomer
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockCustomer
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {Array<GeneralCargoStock>}
     * @memberof GeneralCargoStockCustomer
     */
    'stocks': Array<GeneralCargoStock>;
}
/**
 * 
 * @export
 * @interface GeneralCargoStockDeletedNotification
 */
export interface GeneralCargoStockDeletedNotification {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockDeletedNotification
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface GeneralCargoStockDto
 */
export interface GeneralCargoStockDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockDto
     */
    'lotNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockDto
     */
    'commodityId': number;
    /**
     * 
     * @type {GeneralCargoInventoryDtoCommodity}
     * @memberof GeneralCargoStockDto
     */
    'commodity': GeneralCargoInventoryDtoCommodity;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockDto
     */
    'customerId': number;
    /**
     * 
     * @type {GeneralCargoInventoryDtoCustomer}
     * @memberof GeneralCargoStockDto
     */
    'customer': GeneralCargoInventoryDtoCustomer;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockDto
     */
    'packageId'?: number | null;
    /**
     * 
     * @type {GeneralCargoInventoryOccupancyDtoPackage}
     * @memberof GeneralCargoStockDto
     */
    'package': GeneralCargoInventoryOccupancyDtoPackage;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockDto
     */
    'totalQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockDto
     */
    'expectedQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockDto
     */
    'plannedQuantity': number;
    /**
     * 
     * @type {Array<GeneralCargoStockOccupancyDto>}
     * @memberof GeneralCargoStockDto
     */
    'occupancies': Array<GeneralCargoStockOccupancyDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralCargoStockDto
     */
    'imoClasses'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface GeneralCargoStockItemProperty
 */
export interface GeneralCargoStockItemProperty {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockItemProperty
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockItemProperty
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockItemProperty
     */
    'unitId': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockItemProperty
     */
    'generalCargoStockOccupancyId': string;
    /**
     * 
     * @type {GeneralCargoStockItemPropertyOccupancy}
     * @memberof GeneralCargoStockItemProperty
     */
    'occupancy': GeneralCargoStockItemPropertyOccupancy;
}
/**
 * 
 * @export
 * @interface GeneralCargoStockItemPropertyOccupancy
 */
export interface GeneralCargoStockItemPropertyOccupancy {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockItemPropertyOccupancy
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockItemPropertyOccupancy
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockItemPropertyOccupancy
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockItemPropertyOccupancy
     */
    'generalCargoStockId': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockItemPropertyOccupancy
     */
    'generalCargoLocationId': string;
    /**
     * 
     * @type {GeneralCargoItemStock}
     * @memberof GeneralCargoStockItemPropertyOccupancy
     */
    'stock': GeneralCargoItemStock;
    /**
     * 
     * @type {GeneralCargoItemPlanningLocation}
     * @memberof GeneralCargoStockItemPropertyOccupancy
     */
    'location': GeneralCargoItemPlanningLocation;
    /**
     * 
     * @type {Array<GeneralCargoStockOccupancyDamage>}
     * @memberof GeneralCargoStockItemPropertyOccupancy
     */
    'damages': Array<GeneralCargoStockOccupancyDamage>;
    /**
     * 
     * @type {Array<GeneralCargoStockItemProperty>}
     * @memberof GeneralCargoStockItemPropertyOccupancy
     */
    'properties': Array<GeneralCargoStockItemProperty>;
}
/**
 * 
 * @export
 * @interface GeneralCargoStockOccupanciesPlanInboundOrderRequest
 */
export interface GeneralCargoStockOccupanciesPlanInboundOrderRequest {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockOccupanciesPlanInboundOrderRequest
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockOccupanciesPlanInboundOrderRequest
     */
    'cargoItemId'?: number | null;
    /**
     * 
     * @type {Array<GeneralCargoOccupancyActionDto>}
     * @memberof GeneralCargoStockOccupanciesPlanInboundOrderRequest
     */
    'actions': Array<GeneralCargoOccupancyActionDto>;
}
/**
 * 
 * @export
 * @interface GeneralCargoStockOccupanciesPutToStockRequest
 */
export interface GeneralCargoStockOccupanciesPutToStockRequest {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockOccupanciesPutToStockRequest
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockOccupanciesPutToStockRequest
     */
    'cargoItemId'?: number | null;
    /**
     * 
     * @type {Array<GeneralCargoOccupancyActionDto>}
     * @memberof GeneralCargoStockOccupanciesPutToStockRequest
     */
    'actions': Array<GeneralCargoOccupancyActionDto>;
}
/**
 * 
 * @export
 * @interface GeneralCargoStockOccupancy
 */
export interface GeneralCargoStockOccupancy {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockOccupancy
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockOccupancy
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockOccupancy
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockOccupancy
     */
    'generalCargoStockId': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockOccupancy
     */
    'generalCargoLocationId': string;
    /**
     * 
     * @type {GeneralCargoItemStock}
     * @memberof GeneralCargoStockOccupancy
     */
    'stock': GeneralCargoItemStock;
    /**
     * 
     * @type {GeneralCargoItemPlanningLocation}
     * @memberof GeneralCargoStockOccupancy
     */
    'location': GeneralCargoItemPlanningLocation;
    /**
     * 
     * @type {Array<GeneralCargoStockOccupancyDamage>}
     * @memberof GeneralCargoStockOccupancy
     */
    'damages': Array<GeneralCargoStockOccupancyDamage>;
    /**
     * 
     * @type {Array<GeneralCargoStockItemProperty>}
     * @memberof GeneralCargoStockOccupancy
     */
    'properties': Array<GeneralCargoStockItemProperty>;
}
/**
 * 
 * @export
 * @interface GeneralCargoStockOccupancyDamage
 */
export interface GeneralCargoStockOccupancyDamage {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockOccupancyDamage
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockOccupancyDamage
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockOccupancyDamage
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockOccupancyDamage
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockOccupancyDamage
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockOccupancyDamage
     */
    'createdAt': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralCargoStockOccupancyDamage
     */
    'imagePaths'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockOccupancyDamage
     */
    'generalCargoStockOccupancyId': string;
    /**
     * 
     * @type {GeneralCargoStockItemPropertyOccupancy}
     * @memberof GeneralCargoStockOccupancyDamage
     */
    'occupancy': GeneralCargoStockItemPropertyOccupancy;
}
/**
 * 
 * @export
 * @interface GeneralCargoStockOccupancyDamageDto
 */
export interface GeneralCargoStockOccupancyDamageDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockOccupancyDamageDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockOccupancyDamageDto
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockOccupancyDamageDto
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockOccupancyDamageDto
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockOccupancyDamageDto
     */
    'createdAt': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralCargoStockOccupancyDamageDto
     */
    'imagePaths': Array<string>;
}
/**
 * 
 * @export
 * @interface GeneralCargoStockOccupancyDto
 */
export interface GeneralCargoStockOccupancyDto {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockOccupancyDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockOccupancyDto
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockOccupancyDto
     */
    'generalCargoLocation': string;
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStockOccupancyDto
     */
    'generalCargoArea': string;
    /**
     * 
     * @type {Array<GeneralCargoStockOccupancyDamageDto>}
     * @memberof GeneralCargoStockOccupancyDto
     */
    'damages': Array<GeneralCargoStockOccupancyDamageDto>;
    /**
     * 
     * @type {Array<GeneralCargoItemPropertiesDto>}
     * @memberof GeneralCargoStockOccupancyDto
     */
    'properties': Array<GeneralCargoItemPropertiesDto>;
}
/**
 * 
 * @export
 * @interface GeneralCargoStockUpsertedNotification
 */
export interface GeneralCargoStockUpsertedNotification {
    /**
     * 
     * @type {GeneralCargoItemStock}
     * @memberof GeneralCargoStockUpsertedNotification
     */
    'stock': GeneralCargoItemStock;
}
/**
 * 
 * @export
 * @interface GlobalAllocationRule
 */
export interface GlobalAllocationRule {
    /**
     * 
     * @type {string}
     * @memberof GlobalAllocationRule
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GlobalAllocationRule
     */
    'tenantId': number;
    /**
     * 
     * @type {CarrierType}
     * @memberof GlobalAllocationRule
     */
    'carrierType': CarrierType;
    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof GlobalAllocationRule
     */
    'direction': CarrierVisitDirection;
    /**
     * 
     * @type {AllocationRulePropertiesDtoFacets}
     * @memberof GlobalAllocationRule
     */
    'facets': AllocationRulePropertiesDtoFacets;
    /**
     * 
     * @type {string}
     * @memberof GlobalAllocationRule
     */
    'templateName': string;
    /**
     * 
     * @type {number}
     * @memberof GlobalAllocationRule
     */
    'rulePosition': number;
    /**
     * 
     * @type {string}
     * @memberof GlobalAllocationRule
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GlobalAllocationRule
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {Array<GlobalRuleDestination>}
     * @memberof GlobalAllocationRule
     */
    'globalRuleDestinations': Array<GlobalRuleDestination>;
}
/**
 * 
 * @export
 * @interface GlobalAllocationRuleDto
 */
export interface GlobalAllocationRuleDto {
    /**
     * 
     * @type {string}
     * @memberof GlobalAllocationRuleDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GlobalAllocationRuleDto
     */
    'name': string;
    /**
     * 
     * @type {CarrierType}
     * @memberof GlobalAllocationRuleDto
     */
    'carrierType': CarrierType;
    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof GlobalAllocationRuleDto
     */
    'direction': CarrierVisitDirection;
    /**
     * 
     * @type {AllocationRulePropertiesDtoFacets}
     * @memberof GlobalAllocationRuleDto
     */
    'facets': AllocationRulePropertiesDtoFacets;
    /**
     * 
     * @type {Array<AllocationDestinationAndStackingStrategyDto>}
     * @memberof GlobalAllocationRuleDto
     */
    'destinationAndStackingStrategies': Array<AllocationDestinationAndStackingStrategyDto>;
}
/**
 * 
 * @export
 * @interface GlobalAllocationRulesCreateGlobalRuleAndAllocationRequest
 */
export interface GlobalAllocationRulesCreateGlobalRuleAndAllocationRequest {
    /**
     * 
     * @type {Array<CreateAllocationRuleDto>}
     * @memberof GlobalAllocationRulesCreateGlobalRuleAndAllocationRequest
     */
    'allocationRules': Array<CreateAllocationRuleDto>;
    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof GlobalAllocationRulesCreateGlobalRuleAndAllocationRequest
     */
    'direction': CarrierVisitDirection;
}
/**
 * 
 * @export
 * @interface GlobalRuleDestination
 */
export interface GlobalRuleDestination {
    /**
     * 
     * @type {string}
     * @memberof GlobalRuleDestination
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GlobalRuleDestination
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof GlobalRuleDestination
     */
    'block'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalRuleDestination
     */
    'bay'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalRuleDestination
     */
    'row'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GlobalRuleDestination
     */
    'globalAllocationRuleId': string;
    /**
     * 
     * @type {GlobalRuleDestinationGlobalAllocationRule}
     * @memberof GlobalRuleDestination
     */
    'globalAllocationRule': GlobalRuleDestinationGlobalAllocationRule;
    /**
     * 
     * @type {StackingStrategy}
     * @memberof GlobalRuleDestination
     */
    'stackingStrategy'?: StackingStrategy | null;
    /**
     * 
     * @type {number}
     * @memberof GlobalRuleDestination
     */
    'position': number;
    /**
     * 
     * @type {string}
     * @memberof GlobalRuleDestination
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GlobalRuleDestination
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {Array<AllocationSpace>}
     * @memberof GlobalRuleDestination
     */
    'allocationSpaces': Array<AllocationSpace>;
}
/**
 * 
 * @export
 * @interface GlobalRuleDestinationGlobalAllocationRule
 */
export interface GlobalRuleDestinationGlobalAllocationRule {
    /**
     * 
     * @type {string}
     * @memberof GlobalRuleDestinationGlobalAllocationRule
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof GlobalRuleDestinationGlobalAllocationRule
     */
    'tenantId': number;
    /**
     * 
     * @type {CarrierType}
     * @memberof GlobalRuleDestinationGlobalAllocationRule
     */
    'carrierType': CarrierType;
    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof GlobalRuleDestinationGlobalAllocationRule
     */
    'direction': CarrierVisitDirection;
    /**
     * 
     * @type {AllocationRulePropertiesDtoFacets}
     * @memberof GlobalRuleDestinationGlobalAllocationRule
     */
    'facets': AllocationRulePropertiesDtoFacets;
    /**
     * 
     * @type {string}
     * @memberof GlobalRuleDestinationGlobalAllocationRule
     */
    'templateName': string;
    /**
     * 
     * @type {number}
     * @memberof GlobalRuleDestinationGlobalAllocationRule
     */
    'rulePosition': number;
    /**
     * 
     * @type {string}
     * @memberof GlobalRuleDestinationGlobalAllocationRule
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GlobalRuleDestinationGlobalAllocationRule
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {Array<GlobalRuleDestination>}
     * @memberof GlobalRuleDestinationGlobalAllocationRule
     */
    'globalRuleDestinations': Array<GlobalRuleDestination>;
}
/**
 * 
 * @export
 * @interface GuidOptionDto
 */
export interface GuidOptionDto {
    /**
     * 
     * @type {string}
     * @memberof GuidOptionDto
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof GuidOptionDto
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface GuidOptionDtoPaginatedList
 */
export interface GuidOptionDtoPaginatedList {
    /**
     * 
     * @type {Array<GuidOptionDto>}
     * @memberof GuidOptionDtoPaginatedList
     */
    'items': Array<GuidOptionDto>;
    /**
     * 
     * @type {number}
     * @memberof GuidOptionDtoPaginatedList
     */
    'pageNumber': number;
    /**
     * 
     * @type {number}
     * @memberof GuidOptionDtoPaginatedList
     */
    'totalPages': number;
    /**
     * 
     * @type {number}
     * @memberof GuidOptionDtoPaginatedList
     */
    'totalCount': number;
    /**
     * 
     * @type {boolean}
     * @memberof GuidOptionDtoPaginatedList
     */
    'hasPreviousPage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GuidOptionDtoPaginatedList
     */
    'hasNextPage': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const HandlingDirection = {
    Inbound: 'Inbound',
    Outbound: 'Outbound',
    Internal: 'Internal'
} as const;

export type HandlingDirection = typeof HandlingDirection[keyof typeof HandlingDirection];


/**
 * 
 * @export
 * @interface IApplicationDomainException
 */
export interface IApplicationDomainException {
    /**
     * 
     * @type {ErrorCodes}
     * @memberof IApplicationDomainException
     */
    'errorCode': ErrorCodes;
    /**
     * 
     * @type {any}
     * @memberof IApplicationDomainException
     */
    'payload'?: any | null;
}
/**
 * 
 * @export
 * @interface ImoClassPairDto
 */
export interface ImoClassPairDto {
    /**
     * 
     * @type {string}
     * @memberof ImoClassPairDto
     */
    'imoClass1': string;
    /**
     * 
     * @type {string}
     * @memberof ImoClassPairDto
     */
    'imoClass2': string;
}
/**
 * 
 * @export
 * @interface ImoDeclaration
 */
export interface ImoDeclaration {
    /**
     * 
     * @type {string}
     * @memberof ImoDeclaration
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ImoDeclaration
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof ImoDeclaration
     */
    'imoClass': string;
    /**
     * 
     * @type {string}
     * @memberof ImoDeclaration
     */
    'containerTurnoverId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImoDeclaration
     */
    'generalCargoStockId'?: string | null;
    /**
     * 
     * @type {ImoDeclarationContainerTurnover}
     * @memberof ImoDeclaration
     */
    'containerTurnover': ImoDeclarationContainerTurnover;
    /**
     * 
     * @type {GeneralCargoItemStock}
     * @memberof ImoDeclaration
     */
    'generalCargoStock': GeneralCargoItemStock;
}
/**
 * 
 * @export
 * @interface ImoDeclarationContainerTurnover
 */
export interface ImoDeclarationContainerTurnover {
    /**
     * 
     * @type {string}
     * @memberof ImoDeclarationContainerTurnover
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ImoDeclarationContainerTurnover
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof ImoDeclarationContainerTurnover
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ImoDeclarationContainerTurnover
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImoDeclarationContainerTurnover
     */
    'containerId'?: number | null;
    /**
     * 
     * @type {ContainerTurnoverContainer}
     * @memberof ImoDeclarationContainerTurnover
     */
    'container': ContainerTurnoverContainer;
    /**
     * 
     * @type {number}
     * @memberof ImoDeclarationContainerTurnover
     */
    'inboundCarrierVisitId'?: number | null;
    /**
     * 
     * @type {CarrierVisitAllocationRuleCarrierVisit}
     * @memberof ImoDeclarationContainerTurnover
     */
    'inboundCarrierVisit': CarrierVisitAllocationRuleCarrierVisit;
    /**
     * 
     * @type {number}
     * @memberof ImoDeclarationContainerTurnover
     */
    'outboundCarrierVisitId'?: number | null;
    /**
     * 
     * @type {CarrierVisitAllocationRuleCarrierVisit}
     * @memberof ImoDeclarationContainerTurnover
     */
    'outboundCarrierVisit': CarrierVisitAllocationRuleCarrierVisit;
    /**
     * 
     * @type {number}
     * @memberof ImoDeclarationContainerTurnover
     */
    'inboundOrderId'?: number | null;
    /**
     * 
     * @type {CarrierType}
     * @memberof ImoDeclarationContainerTurnover
     */
    'inboundCarrierType'?: CarrierType | null;
    /**
     * 
     * @type {number}
     * @memberof ImoDeclarationContainerTurnover
     */
    'outboundOrderId'?: number | null;
    /**
     * 
     * @type {CarrierType}
     * @memberof ImoDeclarationContainerTurnover
     */
    'outboundCarrierType'?: CarrierType | null;
    /**
     * 
     * @type {boolean}
     * @memberof ImoDeclarationContainerTurnover
     */
    'isEmpty'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ImoDeclarationContainerTurnover
     */
    'grossWeight'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ImoDeclarationContainerTurnover
     */
    'isDangerous': boolean;
    /**
     * 
     * @type {string}
     * @memberof ImoDeclarationContainerTurnover
     */
    'portOfLoading'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImoDeclarationContainerTurnover
     */
    'portOfDischarge'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImoDeclarationContainerTurnover
     */
    'temperature'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImoDeclarationContainerTurnover
     */
    'releaseReference'?: string | null;
    /**
     * 
     * @type {Array<ImoDeclaration>}
     * @memberof ImoDeclarationContainerTurnover
     */
    'imoDeclarations': Array<ImoDeclaration>;
    /**
     * 
     * @type {ContainerPositionType}
     * @memberof ImoDeclarationContainerTurnover
     */
    'positionType'?: ContainerPositionType | null;
    /**
     * 
     * @type {string}
     * @memberof ImoDeclarationContainerTurnover
     */
    'allocationId'?: string | null;
    /**
     * 
     * @type {Allocation}
     * @memberof ImoDeclarationContainerTurnover
     */
    'allocation'?: Allocation | null;
    /**
     * 
     * @type {Array<YardBlockSlotOccupancy>}
     * @memberof ImoDeclarationContainerTurnover
     */
    'occupancies': Array<YardBlockSlotOccupancy>;
    /**
     * 
     * @type {boolean}
     * @memberof ImoDeclarationContainerTurnover
     */
    'isAllocated': boolean;
    /**
     * 
     * @type {string}
     * @memberof ImoDeclarationContainerTurnover
     */
    'consignee'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImoDeclarationContainerTurnover
     */
    'yardBlockEntry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImoDeclarationContainerTurnover
     */
    'yardBlockExit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImoDeclarationContainerTurnover
     */
    'terminalEntry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImoDeclarationContainerTurnover
     */
    'terminalExit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImoDeclarationContainerTurnover
     */
    'customerId'?: number | null;
}
/**
 * 
 * @export
 * @interface InternalMoveResultDto
 */
export interface InternalMoveResultDto {
    /**
     * 
     * @type {Array<YardPositionDto>}
     * @memberof InternalMoveResultDto
     */
    'locations': Array<YardPositionDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LengthMeasurementUnit = {
    Meter: 'Meter',
    Centimeter: 'Centimeter',
    Millimeters: 'Millimeters',
    Feet: 'Feet',
    Inches: 'Inches'
} as const;

export type LengthMeasurementUnit = typeof LengthMeasurementUnit[keyof typeof LengthMeasurementUnit];


/**
 * 
 * @export
 * @interface OrderDamageQuantityDto
 */
export interface OrderDamageQuantityDto {
    /**
     * 
     * @type {number}
     * @memberof OrderDamageQuantityDto
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof OrderDamageQuantityDto
     */
    'totalQuantity': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const OrderStatus = {
    Open: 'Open',
    Fulfilled: 'Fulfilled'
} as const;

export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];


/**
 * 
 * @export
 * @interface Package
 */
export interface Package {
    /**
     * 
     * @type {number}
     * @memberof Package
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof Package
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    'name': string;
    /**
     * 
     * @type {WeightMeasurementUnit}
     * @memberof Package
     */
    'weightUnit': WeightMeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof Package
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Package
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Package
     */
    'length'?: number | null;
    /**
     * 
     * @type {LengthMeasurementUnit}
     * @memberof Package
     */
    'lengthUnit'?: LengthMeasurementUnit | null;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {Array<GeneralCargoStock>}
     * @memberof Package
     */
    'stocks': Array<GeneralCargoStock>;
}
/**
 * 
 * @export
 * @interface PackageDto
 */
export interface PackageDto {
    /**
     * 
     * @type {number}
     * @memberof PackageDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PackageDto
     */
    'name': string;
    /**
     * 
     * @type {WeightMeasurementUnit}
     * @memberof PackageDto
     */
    'weightUnit': WeightMeasurementUnit;
    /**
     * 
     * @type {number}
     * @memberof PackageDto
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageDto
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PackageDto
     */
    'length'?: number | null;
    /**
     * 
     * @type {LengthMeasurementUnit}
     * @memberof PackageDto
     */
    'lengthUnit'?: LengthMeasurementUnit | null;
}
/**
 * 
 * @export
 * @interface PaginationQuery
 */
export interface PaginationQuery {
    /**
     * 
     * @type {number}
     * @memberof PaginationQuery
     */
    'pageNumber': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationQuery
     */
    'pageSize': number;
}
/**
 * 
 * @export
 * @interface PierDto
 */
export interface PierDto {
    /**
     * 
     * @type {string}
     * @memberof PierDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PierDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PiersCreateRequest
 */
export interface PiersCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof PiersCreateRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PiersUpdateRequest
 */
export interface PiersUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof PiersUpdateRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PiersUpdateRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PreplanningValidationDto
 */
export interface PreplanningValidationDto {
    /**
     * 
     * @type {DangerousGoodsValidationDtoFilter}
     * @memberof PreplanningValidationDto
     */
    'filter': DangerousGoodsValidationDtoFilter;
}
/**
 * 
 * @export
 * @interface RailTrackDto
 */
export interface RailTrackDto {
    /**
     * 
     * @type {string}
     * @memberof RailTrackDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RailTrackDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof RailTrackDto
     */
    'length'?: number | null;
    /**
     * 
     * @type {LengthMeasurementUnit}
     * @memberof RailTrackDto
     */
    'lengthUnit': LengthMeasurementUnit;
}
/**
 * 
 * @export
 * @interface RailTracksCreateRequest
 */
export interface RailTracksCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof RailTracksCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof RailTracksCreateRequest
     */
    'length'?: number | null;
}
/**
 * 
 * @export
 * @interface RailTracksUpdateRequest
 */
export interface RailTracksUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof RailTracksUpdateRequest
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RailTracksUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof RailTracksUpdateRequest
     */
    'length'?: number | null;
    /**
     * 
     * @type {LengthMeasurementUnit}
     * @memberof RailTracksUpdateRequest
     */
    'lengthUnit': LengthMeasurementUnit;
}
/**
 * 
 * @export
 * @interface ReloadStacksNotification
 */
export interface ReloadStacksNotification {
    /**
     * 
     * @type {YardUnit}
     * @memberof ReloadStacksNotification
     */
    'unit': YardUnit;
    /**
     * 
     * @type {string}
     * @memberof ReloadStacksNotification
     */
    'unitId'?: string | null;
}
/**
 * 
 * @export
 * @interface RuleDestinationBase
 */
export interface RuleDestinationBase {
    /**
     * 
     * @type {string}
     * @memberof RuleDestinationBase
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof RuleDestinationBase
     */
    'isForCarrierVisitRule': boolean;
    /**
     * 
     * @type {string}
     * @memberof RuleDestinationBase
     */
    'block': string;
    /**
     * 
     * @type {string}
     * @memberof RuleDestinationBase
     */
    'bay'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RuleDestinationBase
     */
    'row'?: string | null;
}
/**
 * 
 * @export
 * @interface RuleDestinationDto
 */
export interface RuleDestinationDto {
    /**
     * 
     * @type {string}
     * @memberof RuleDestinationDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RuleDestinationDto
     */
    'block': string;
    /**
     * 
     * @type {string}
     * @memberof RuleDestinationDto
     */
    'bay'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RuleDestinationDto
     */
    'row'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RuleDestinationDto
     */
    'position': number;
    /**
     * 
     * @type {StackingStrategy}
     * @memberof RuleDestinationDto
     */
    'stackingStrategy'?: StackingStrategy | null;
}
/**
 * 
 * @export
 * @interface RuleFacets
 */
export interface RuleFacets {
    /**
     * 
     * @type {number}
     * @memberof RuleFacets
     */
    'size'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof RuleFacets
     */
    'isEmpty'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RuleFacets
     */
    'isReefer'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof RuleFacets
     */
    'isDangerous'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof RuleFacets
     */
    'containerOperator'?: string | null;
    /**
     * 
     * @type {ContainerHeight}
     * @memberof RuleFacets
     */
    'containerHeight'?: ContainerHeight | null;
    /**
     * 
     * @type {ContainerType}
     * @memberof RuleFacets
     */
    'containerType'?: ContainerType | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RuleFacets
     */
    'weightClasses': Array<string>;
    /**
     * 
     * @type {CarrierType}
     * @memberof RuleFacets
     */
    'inboundCarrierType'?: CarrierType | null;
    /**
     * 
     * @type {CarrierType}
     * @memberof RuleFacets
     */
    'outboundCarrierType'?: CarrierType | null;
    /**
     * 
     * @type {string}
     * @memberof RuleFacets
     */
    'consignee'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RuleFacets
     */
    'portOfDischarge'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RuleFacets
     */
    'containerNumber'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RuleFacets
     */
    'customerId'?: number | null;
}
/**
 * 
 * @export
 * @interface SetBerthGeometryCommand
 */
export interface SetBerthGeometryCommand {
    /**
     * 
     * @type {number}
     * @memberof SetBerthGeometryCommand
     */
    'top': number;
    /**
     * 
     * @type {number}
     * @memberof SetBerthGeometryCommand
     */
    'left': number;
    /**
     * 
     * @type {number}
     * @memberof SetBerthGeometryCommand
     */
    'rotation': number;
    /**
     * 
     * @type {number}
     * @memberof SetBerthGeometryCommand
     */
    'width': number;
    /**
     * 
     * @type {string}
     * @memberof SetBerthGeometryCommand
     */
    'berthId': string;
}
/**
 * 
 * @export
 * @interface SetYardBlockGeometryCommand
 */
export interface SetYardBlockGeometryCommand {
    /**
     * 
     * @type {number}
     * @memberof SetYardBlockGeometryCommand
     */
    'top'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SetYardBlockGeometryCommand
     */
    'left'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SetYardBlockGeometryCommand
     */
    'rotation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SetYardBlockGeometryCommand
     */
    'width'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SetYardBlockGeometryCommand
     */
    'yardBlockId': string;
}
/**
 * 
 * @export
 * @interface SlotDto
 */
export interface SlotDto {
    /**
     * 
     * @type {string}
     * @memberof SlotDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SlotDto
     */
    'yardBlockId': string;
    /**
     * 
     * @type {string}
     * @memberof SlotDto
     */
    'yardBlockBayId': string;
    /**
     * 
     * @type {number}
     * @memberof SlotDto
     */
    'yardBlockBaySequenceNumber': number;
    /**
     * 
     * @type {string}
     * @memberof SlotDto
     */
    'yardBlockRowId': string;
    /**
     * 
     * @type {number}
     * @memberof SlotDto
     */
    'yardBlockRowSequenceNumber': number;
    /**
     * 
     * @type {number}
     * @memberof SlotDto
     */
    'tier': number;
    /**
     * 
     * @type {boolean}
     * @memberof SlotDto
     */
    'locked': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SlotDto
     */
    'isAllocated': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SlotDto
     */
    'isReserved': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SlotDto
     */
    'isOccupied': boolean;
    /**
     * 
     * @type {Array<ContainerTurnoverDto>}
     * @memberof SlotDto
     */
    'occupiedTurnovers': Array<ContainerTurnoverDto>;
    /**
     * 
     * @type {Array<ContainerTurnoverDto>}
     * @memberof SlotDto
     */
    'reservedTurnovers': Array<ContainerTurnoverDto>;
    /**
     * 
     * @type {SlotDtoLocation}
     * @memberof SlotDto
     */
    'location': SlotDtoLocation;
}
/**
 * 
 * @export
 * @interface SlotDtoLocation
 */
export interface SlotDtoLocation {
    /**
     * 
     * @type {string}
     * @memberof SlotDtoLocation
     */
    'id': string;
    /**
     * 
     * @type {AllocationDestinationAndStackingStrategyDtoDestination}
     * @memberof SlotDtoLocation
     */
    'yardPosition': AllocationDestinationAndStackingStrategyDtoDestination;
}
/**
 * 
 * @export
 * @interface StackDto
 */
export interface StackDto {
    /**
     * 
     * @type {string}
     * @memberof StackDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof StackDto
     */
    'blockId': string;
    /**
     * 
     * @type {boolean}
     * @memberof StackDto
     */
    'isFlexibleZone': boolean;
    /**
     * 
     * @type {AllocationDestinationAndStackingStrategyDtoDestination}
     * @memberof StackDto
     */
    'yardPosition': AllocationDestinationAndStackingStrategyDtoDestination;
    /**
     * 
     * @type {string}
     * @memberof StackDto
     */
    'bayId': string;
    /**
     * 
     * @type {number}
     * @memberof StackDto
     */
    'baySequenceNumber': number;
    /**
     * 
     * @type {string}
     * @memberof StackDto
     */
    'nextBayId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StackDto
     */
    'rowId': string;
    /**
     * 
     * @type {number}
     * @memberof StackDto
     */
    'rowSequenceNumber': number;
    /**
     * 
     * @type {number}
     * @memberof StackDto
     */
    'stackSizeDigit': number;
    /**
     * 
     * @type {StackSize}
     * @memberof StackDto
     */
    'stackSize': StackSize;
    /**
     * 
     * @type {ContainerSection}
     * @memberof StackDto
     */
    'section': ContainerSection;
    /**
     * 
     * @type {boolean}
     * @memberof StackDto
     */
    'isLocked': boolean;
    /**
     * 
     * @type {number}
     * @memberof StackDto
     */
    'numberOfOccupiedSlots': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StackDto
     */
    'turnoverIds': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof StackDto
     */
    'numberOfSlots': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StackInconsistencyType = {
    Flying: 'Flying',
    MultipleStacking: 'MultipleStacking',
    InvalidLocation: 'InvalidLocation',
    SizeMismatch: 'SizeMismatch',
    IllegalStacking: 'IllegalStacking',
    DangerousContainerStackedInNonDangerousBlock: 'DangerousContainerStackedInNonDangerousBlock',
    NonDangerousContainerStackedInDangerousBlock: 'NonDangerousContainerStackedInDangerousBlock'
} as const;

export type StackInconsistencyType = typeof StackInconsistencyType[keyof typeof StackInconsistencyType];


/**
 * 
 * @export
 * @enum {string}
 */

export const StackSize = {
    SingleSlot: 'SingleSlot',
    DoubleSlot: 'DoubleSlot',
    TripleSlot: 'TripleSlot'
} as const;

export type StackSize = typeof StackSize[keyof typeof StackSize];


/**
 * 
 * @export
 * @interface StackedInContainerDto
 */
export interface StackedInContainerDto {
    /**
     * 
     * @type {string}
     * @memberof StackedInContainerDto
     */
    'containerNumber': string;
    /**
     * 
     * @type {string}
     * @memberof StackedInContainerDto
     */
    'isoCode': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const StackingStrategy = {
    BayRowTier: 'BayRowTier',
    RowBayTier: 'RowBayTier',
    TierBayRow: 'TierBayRow',
    TierRowBay: 'TierRowBay'
} as const;

export type StackingStrategy = typeof StackingStrategy[keyof typeof StackingStrategy];


/**
 * 
 * @export
 * @interface SyncBerthDto
 */
export interface SyncBerthDto {
    /**
     * 
     * @type {number}
     * @memberof SyncBerthDto
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof SyncBerthDto
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SyncBerthDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SyncBerthDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SyncBerthDto
     */
    'quayMeterMarkFrom': number;
    /**
     * 
     * @type {number}
     * @memberof SyncBerthDto
     */
    'quayMeterMarkTo': number;
    /**
     * 
     * @type {number}
     * @memberof SyncBerthDto
     */
    'maxDraft'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SyncBerthDto
     */
    'pierId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SyncBerthDto
     */
    'pierName'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof SyncBerthDto
     */
    'cleatMeterMarks': Array<number>;
    /**
     * 
     * @type {Array<Bollard>}
     * @memberof SyncBerthDto
     */
    'bollards': Array<Bollard>;
    /**
     * 
     * @type {string}
     * @memberof SyncBerthDto
     */
    'referenceId'?: string | null;
}
/**
 * 
 * @export
 * @interface SyncBerthsDto
 */
export interface SyncBerthsDto {
    /**
     * 
     * @type {Array<SyncBerthDto>}
     * @memberof SyncBerthsDto
     */
    'updated': Array<SyncBerthDto>;
    /**
     * 
     * @type {Array<SyncDeletedDto>}
     * @memberof SyncBerthsDto
     */
    'deleted': Array<SyncDeletedDto>;
}
/**
 * 
 * @export
 * @interface SyncContainerPositionDto
 */
export interface SyncContainerPositionDto {
    /**
     * 
     * @type {number}
     * @memberof SyncContainerPositionDto
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof SyncContainerPositionDto
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SyncContainerPositionDto
     */
    'containerNumber': string;
    /**
     * 
     * @type {Array<YardBlockSlotLocation>}
     * @memberof SyncContainerPositionDto
     */
    'location': Array<YardBlockSlotLocation>;
}
/**
 * 
 * @export
 * @interface SyncContainerPositionsDto
 */
export interface SyncContainerPositionsDto {
    /**
     * 
     * @type {Array<SyncContainerPositionDto>}
     * @memberof SyncContainerPositionsDto
     */
    'created': Array<SyncContainerPositionDto>;
}
/**
 * 
 * @export
 * @interface SyncContainersPlannedYardBlockDto
 */
export interface SyncContainersPlannedYardBlockDto {
    /**
     * 
     * @type {Array<SyncYardBlockAllocationDto>}
     * @memberof SyncContainersPlannedYardBlockDto
     */
    'updated': Array<SyncYardBlockAllocationDto>;
}
/**
 * 
 * @export
 * @interface SyncDeletedDto
 */
export interface SyncDeletedDto {
    /**
     * 
     * @type {string}
     * @memberof SyncDeletedDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof SyncDeletedDto
     */
    'tenantId': number;
}
/**
 * 
 * @export
 * @interface SyncRailTrackDto
 */
export interface SyncRailTrackDto {
    /**
     * 
     * @type {number}
     * @memberof SyncRailTrackDto
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof SyncRailTrackDto
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SyncRailTrackDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SyncRailTrackDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof SyncRailTrackDto
     */
    'length'?: number | null;
    /**
     * 
     * @type {LengthMeasurementUnit}
     * @memberof SyncRailTrackDto
     */
    'lengthUnit': LengthMeasurementUnit;
}
/**
 * 
 * @export
 * @interface SyncRailTracksDto
 */
export interface SyncRailTracksDto {
    /**
     * 
     * @type {Array<SyncRailTrackDto>}
     * @memberof SyncRailTracksDto
     */
    'updated': Array<SyncRailTrackDto>;
    /**
     * 
     * @type {Array<SyncDeletedDto>}
     * @memberof SyncRailTracksDto
     */
    'deleted': Array<SyncDeletedDto>;
}
/**
 * 
 * @export
 * @interface SyncYardBlockAllocationDto
 */
export interface SyncYardBlockAllocationDto {
    /**
     * 
     * @type {number}
     * @memberof SyncYardBlockAllocationDto
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof SyncYardBlockAllocationDto
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SyncYardBlockAllocationDto
     */
    'containerNumber': string;
    /**
     * 
     * @type {number}
     * @memberof SyncYardBlockAllocationDto
     */
    'orderId': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SyncYardBlockAllocationDto
     */
    'yardBlockIds': Array<string>;
}
/**
 * 
 * @export
 * @interface SyncYardBlockDto
 */
export interface SyncYardBlockDto {
    /**
     * 
     * @type {number}
     * @memberof SyncYardBlockDto
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof SyncYardBlockDto
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SyncYardBlockDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SyncYardBlockDto
     */
    'name': string;
    /**
     * 
     * @type {Array<EquipmentType>}
     * @memberof SyncYardBlockDto
     */
    'equipmentTypes': Array<EquipmentType>;
    /**
     * 
     * @type {boolean}
     * @memberof SyncYardBlockDto
     */
    'isFlexibleZone': boolean;
    /**
     * 
     * @type {SyncYardBlockDtoUsageOptions}
     * @memberof SyncYardBlockDto
     */
    'usageOptions': SyncYardBlockDtoUsageOptions;
}
/**
 * 
 * @export
 * @interface SyncYardBlockDtoUsageOptions
 */
export interface SyncYardBlockDtoUsageOptions {
    /**
     * 
     * @type {boolean}
     * @memberof SyncYardBlockDtoUsageOptions
     */
    'railHandover': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyncYardBlockDtoUsageOptions
     */
    'quayHandover': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyncYardBlockDtoUsageOptions
     */
    'truckHandover': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyncYardBlockDtoUsageOptions
     */
    'temporaryStowage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SyncYardBlockDtoUsageOptions
     */
    'dangerousGoodsHandling': boolean;
}
/**
 * 
 * @export
 * @interface SyncYardBlockSlotDto
 */
export interface SyncYardBlockSlotDto {
    /**
     * 
     * @type {number}
     * @memberof SyncYardBlockSlotDto
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof SyncYardBlockSlotDto
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SyncYardBlockSlotDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof SyncYardBlockSlotDto
     */
    'tier': number;
    /**
     * 
     * @type {string}
     * @memberof SyncYardBlockSlotDto
     */
    'yardBlockName': string;
    /**
     * 
     * @type {string}
     * @memberof SyncYardBlockSlotDto
     */
    'yardBlockId': string;
    /**
     * 
     * @type {string}
     * @memberof SyncYardBlockSlotDto
     */
    'bay': string;
    /**
     * 
     * @type {string}
     * @memberof SyncYardBlockSlotDto
     */
    'row': string;
}
/**
 * 
 * @export
 * @interface SyncYardBlockSlotsDto
 */
export interface SyncYardBlockSlotsDto {
    /**
     * 
     * @type {Array<SyncYardBlockSlotDto>}
     * @memberof SyncYardBlockSlotsDto
     */
    'updated': Array<SyncYardBlockSlotDto>;
    /**
     * 
     * @type {Array<SyncDeletedDto>}
     * @memberof SyncYardBlockSlotsDto
     */
    'deleted': Array<SyncDeletedDto>;
}
/**
 * 
 * @export
 * @interface SyncYardBlocksDto
 */
export interface SyncYardBlocksDto {
    /**
     * 
     * @type {Array<SyncYardBlockDto>}
     * @memberof SyncYardBlocksDto
     */
    'updated': Array<SyncYardBlockDto>;
    /**
     * 
     * @type {Array<SyncDeletedDto>}
     * @memberof SyncYardBlocksDto
     */
    'deleted': Array<SyncDeletedDto>;
}
/**
 * 
 * @export
 * @interface SyncYardReservationDto
 */
export interface SyncYardReservationDto {
    /**
     * 
     * @type {number}
     * @memberof SyncYardReservationDto
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof SyncYardReservationDto
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof SyncYardReservationDto
     */
    'containerNumber': string;
    /**
     * 
     * @type {number}
     * @memberof SyncYardReservationDto
     */
    'orderId': number;
    /**
     * 
     * @type {Array<YardBlockSlotLocation>}
     * @memberof SyncYardReservationDto
     */
    'location': Array<YardBlockSlotLocation>;
}
/**
 * 
 * @export
 * @interface SyncYardReservationsDto
 */
export interface SyncYardReservationsDto {
    /**
     * 
     * @type {Array<SyncYardReservationDto>}
     * @memberof SyncYardReservationsDto
     */
    'created': Array<SyncYardReservationDto>;
}
/**
 * 
 * @export
 * @interface TenantAppConfigUpsertRequest
 */
export interface TenantAppConfigUpsertRequest {
    /**
     * 
     * @type {string}
     * @memberof TenantAppConfigUpsertRequest
     */
    'name': string;
    /**
     * 
     * @type {TenantConfigKey}
     * @memberof TenantAppConfigUpsertRequest
     */
    'key': TenantConfigKey;
    /**
     * 
     * @type {boolean}
     * @memberof TenantAppConfigUpsertRequest
     */
    'value': boolean;
}
/**
 * 
 * @export
 * @interface TenantConfigDto
 */
export interface TenantConfigDto {
    /**
     * 
     * @type {boolean}
     * @memberof TenantConfigDto
     */
    'skipYardPlanning': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TenantConfigDto
     */
    'bayRowUniqueIdentificationMode': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TenantConfigKey = {
    SkipYardPlanning: 'SkipYardPlanning',
    BayRowUniqueIdentificationMode: 'BayRowUniqueIdentificationMode'
} as const;

export type TenantConfigKey = typeof TenantConfigKey[keyof typeof TenantConfigKey];


/**
 * 
 * @export
 * @interface TurnoverDto
 */
export interface TurnoverDto {
    /**
     * 
     * @type {string}
     * @memberof TurnoverDto
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof TurnoverDto
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof TurnoverDto
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof TurnoverDto
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TurnoverDto
     */
    'inboundOrderId'?: number | null;
    /**
     * 
     * @type {CarrierType}
     * @memberof TurnoverDto
     */
    'inboundCarrierType'?: CarrierType | null;
    /**
     * 
     * @type {number}
     * @memberof TurnoverDto
     */
    'inboundVesselVisitId'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TurnoverDto
     */
    'outboundOrderId'?: number | null;
    /**
     * 
     * @type {CarrierType}
     * @memberof TurnoverDto
     */
    'outboundCarrierType'?: CarrierType | null;
    /**
     * 
     * @type {number}
     * @memberof TurnoverDto
     */
    'outboundVesselVisitId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TurnoverDto
     */
    'isEmpty'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof TurnoverDto
     */
    'grossWeight'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TurnoverDto
     */
    'isDangerous': boolean;
    /**
     * 
     * @type {string}
     * @memberof TurnoverDto
     */
    'portOfLoading'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TurnoverDto
     */
    'portOfDischarge'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TurnoverDto
     */
    'temperature'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TurnoverDto
     */
    'imoClasses': Array<string>;
    /**
     * 
     * @type {ContainerPositionType}
     * @memberof TurnoverDto
     */
    'positionType'?: ContainerPositionType | null;
    /**
     * 
     * @type {string}
     * @memberof TurnoverDto
     */
    'allocationId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TurnoverDto
     */
    'isAllocated': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TurnoverDto
     */
    'isRestow': boolean;
    /**
     * 
     * @type {string}
     * @memberof TurnoverDto
     */
    'yardBlockEntry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TurnoverDto
     */
    'yardBlockExit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TurnoverDto
     */
    'terminalEntry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TurnoverDto
     */
    'terminalExit'?: string | null;
    /**
     * 
     * @type {TurnoverDtoContainer}
     * @memberof TurnoverDto
     */
    'container': TurnoverDtoContainer;
}
/**
 * 
 * @export
 * @interface TurnoverDtoContainer
 */
export interface TurnoverDtoContainer {
    /**
     * 
     * @type {number}
     * @memberof TurnoverDtoContainer
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof TurnoverDtoContainer
     */
    'id': number;
    /**
     * 
     * @type {ContainerType}
     * @memberof TurnoverDtoContainer
     */
    'type'?: ContainerType | null;
    /**
     * 
     * @type {ContainerHeight}
     * @memberof TurnoverDtoContainer
     */
    'height'?: ContainerHeight | null;
    /**
     * 
     * @type {number}
     * @memberof TurnoverDtoContainer
     */
    'length': number;
    /**
     * 
     * @type {string}
     * @memberof TurnoverDtoContainer
     */
    'number'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TurnoverDtoContainer
     */
    'isoCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TurnoverDtoContainer
     */
    'isReefer'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TurnoverDtoContainer
     */
    'isDamaged': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TurnoverDtoContainer
     */
    'isEmpty': boolean;
    /**
     * 
     * @type {string}
     * @memberof TurnoverDtoContainer
     */
    'operator'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TurnoverDtoContainer
     */
    'typeCode'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAllocationRuleTemplateDto
 */
export interface UpdateAllocationRuleTemplateDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAllocationRuleTemplateDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAllocationRuleTemplateDto
     */
    'name': string;
    /**
     * 
     * @type {AllocationRulePropertiesDtoFacets}
     * @memberof UpdateAllocationRuleTemplateDto
     */
    'facets': AllocationRulePropertiesDtoFacets;
    /**
     * 
     * @type {Array<AllocationDestinationAndStackingStrategyDto>}
     * @memberof UpdateAllocationRuleTemplateDto
     */
    'destinationAndStackingStrategies': Array<AllocationDestinationAndStackingStrategyDto>;
}
/**
 * 
 * @export
 * @interface UpdateBerthDto
 */
export interface UpdateBerthDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateBerthDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBerthDto
     */
    'pierId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBerthDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateBerthDto
     */
    'quayMeterMarkFrom': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBerthDto
     */
    'quayMeterMarkTo': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateBerthDto
     */
    'maxDraft'?: number | null;
    /**
     * 
     * @type {Array<Bollard>}
     * @memberof UpdateBerthDto
     */
    'bollards': Array<Bollard>;
    /**
     * 
     * @type {YardElementGeometryDto}
     * @memberof UpdateBerthDto
     */
    'geometry'?: YardElementGeometryDto | null;
}
/**
 * 
 * @export
 * @interface UpdateDGStackingRuleDto
 */
export interface UpdateDGStackingRuleDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDGStackingRuleDto
     */
    'id': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDGStackingRuleDto
     */
    'isStackingContainerOnTopAllowed': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateDGStackingRuleDto
     */
    'maxStack'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateGeneralCargoAreaDto
 */
export interface UpdateGeneralCargoAreaDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateGeneralCargoAreaDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGeneralCargoAreaDto
     */
    'name': string;
    /**
     * 
     * @type {GeneralCargoAreaType}
     * @memberof UpdateGeneralCargoAreaDto
     */
    'type': GeneralCargoAreaType;
    /**
     * 
     * @type {Array<GeneralCargoLocationDto>}
     * @memberof UpdateGeneralCargoAreaDto
     */
    'locations': Array<GeneralCargoLocationDto>;
}
/**
 * 
 * @export
 * @interface UpdateGeneralCargoOrderPlanningDto
 */
export interface UpdateGeneralCargoOrderPlanningDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateGeneralCargoOrderPlanningDto
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateGeneralCargoOrderPlanningDto
     */
    'cargoItemId'?: number | null;
    /**
     * 
     * @type {Array<GeneralCargoOccupancyActionDto>}
     * @memberof UpdateGeneralCargoOrderPlanningDto
     */
    'actions': Array<GeneralCargoOccupancyActionDto>;
}
/**
 * 
 * @export
 * @interface UpdateGeneralCargoStockOccupancyDto
 */
export interface UpdateGeneralCargoStockOccupancyDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateGeneralCargoStockOccupancyDto
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateGeneralCargoStockOccupancyDto
     */
    'cargoItemId'?: number | null;
    /**
     * 
     * @type {Array<GeneralCargoOccupancyActionDto>}
     * @memberof UpdateGeneralCargoStockOccupancyDto
     */
    'actions': Array<GeneralCargoOccupancyActionDto>;
}
/**
 * 
 * @export
 * @interface UpdatePierDto
 */
export interface UpdatePierDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePierDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePierDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateYardBlockBayDto
 */
export interface UpdateYardBlockBayDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateYardBlockBayDto
     */
    'yardBlockBayId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateYardBlockBayDto
     */
    'yardBlockId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateYardBlockBayDto
     */
    'name': string;
    /**
     * 
     * @type {BayOperability}
     * @memberof UpdateYardBlockBayDto
     */
    'operable'?: BayOperability | null;
}
/**
 * 
 * @export
 * @interface UpdateYardBlockDto
 */
export interface UpdateYardBlockDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateYardBlockDto
     */
    'yardBlockId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateYardBlockDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateYardBlockDto
     */
    'maxTier': number;
    /**
     * 
     * @type {Array<EquipmentType>}
     * @memberof UpdateYardBlockDto
     */
    'equipmentTypes'?: Array<EquipmentType> | null;
    /**
     * 
     * @type {YardBlockUsageOptions}
     * @memberof UpdateYardBlockDto
     */
    'usageOptions'?: YardBlockUsageOptions | null;
    /**
     * 
     * @type {StackingStrategy}
     * @memberof UpdateYardBlockDto
     */
    'stackingStrategy': StackingStrategy;
}
/**
 * 
 * @export
 * @interface UpdateYardBlockRowDto
 */
export interface UpdateYardBlockRowDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateYardBlockRowDto
     */
    'yardBlockRowId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateYardBlockRowDto
     */
    'yardBlockId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateYardBlockRowDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpsertDGSegregationRulesDto
 */
export interface UpsertDGSegregationRulesDto {
    /**
     * 
     * @type {Array<DGSegregationRuleDto>}
     * @memberof UpsertDGSegregationRulesDto
     */
    'rules': Array<DGSegregationRuleDto>;
}
/**
 * 
 * @export
 * @interface UpsertTenantConfigCommand
 */
export interface UpsertTenantConfigCommand {
    /**
     * 
     * @type {string}
     * @memberof UpsertTenantConfigCommand
     */
    'name': string;
    /**
     * 
     * @type {TenantConfigKey}
     * @memberof UpsertTenantConfigCommand
     */
    'key': TenantConfigKey;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertTenantConfigCommand
     */
    'value': boolean;
}
/**
 * 
 * @export
 * @interface WebAppConfiguration
 */
export interface WebAppConfiguration {
    /**
     * 
     * @type {WebAppConfigurationAuth}
     * @memberof WebAppConfiguration
     */
    'auth': WebAppConfigurationAuth;
    /**
     * 
     * @type {string}
     * @memberof WebAppConfiguration
     */
    'environment': string;
}
/**
 * 
 * @export
 * @interface WebAppConfigurationAuth
 */
export interface WebAppConfigurationAuth {
    /**
     * 
     * @type {string}
     * @memberof WebAppConfigurationAuth
     */
    'audience': string;
    /**
     * 
     * @type {string}
     * @memberof WebAppConfigurationAuth
     */
    'domain': string;
    /**
     * 
     * @type {string}
     * @memberof WebAppConfigurationAuth
     */
    'clientId': string;
}
/**
 * 
 * @export
 * @interface WeightClassDto
 */
export interface WeightClassDto {
    /**
     * 
     * @type {string}
     * @memberof WeightClassDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof WeightClassDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof WeightClassDto
     */
    'minWeight': number;
    /**
     * 
     * @type {number}
     * @memberof WeightClassDto
     */
    'maxWeight'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WeightMeasurementUnit = {
    Kilogram: 'Kilogram',
    Ton: 'Ton'
} as const;

export type WeightMeasurementUnit = typeof WeightMeasurementUnit[keyof typeof WeightMeasurementUnit];


/**
 * 
 * @export
 * @interface YardBlock
 */
export interface YardBlock {
    /**
     * 
     * @type {string}
     * @memberof YardBlock
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlock
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof YardBlock
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlock
     */
    'maxTier': number;
    /**
     * 
     * @type {BlockLabellingPattern}
     * @memberof YardBlock
     */
    'bayLabellingPattern': BlockLabellingPattern;
    /**
     * 
     * @type {BlockLabellingPattern}
     * @memberof YardBlock
     */
    'rowLabellingPattern': BlockLabellingPattern;
    /**
     * 
     * @type {boolean}
     * @memberof YardBlock
     */
    'isFlexibleZone': boolean;
    /**
     * 
     * @type {SyncYardBlockDtoUsageOptions}
     * @memberof YardBlock
     */
    'usageOptions': SyncYardBlockDtoUsageOptions;
    /**
     * 
     * @type {Array<YardBlockSlot>}
     * @memberof YardBlock
     */
    'slots': Array<YardBlockSlot>;
    /**
     * 
     * @type {Array<YardBlockBay>}
     * @memberof YardBlock
     */
    'bays': Array<YardBlockBay>;
    /**
     * 
     * @type {Array<YardBlockRow>}
     * @memberof YardBlock
     */
    'rows': Array<YardBlockRow>;
    /**
     * 
     * @type {Array<YardBlockEquipmentType>}
     * @memberof YardBlock
     */
    'equipmentTypes': Array<YardBlockEquipmentType>;
    /**
     * 
     * @type {YardBlockGeometry}
     * @memberof YardBlock
     */
    'yardBlockGeometry'?: YardBlockGeometry | null;
    /**
     * 
     * @type {string}
     * @memberof YardBlock
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlock
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {StackingStrategy}
     * @memberof YardBlock
     */
    'stackingStrategy': StackingStrategy;
}
/**
 * 
 * @export
 * @interface YardBlockBay
 */
export interface YardBlockBay {
    /**
     * 
     * @type {string}
     * @memberof YardBlockBay
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockBay
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof YardBlockBay
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockBay
     */
    'sequenceNumber': number;
    /**
     * 
     * @type {BayOperability}
     * @memberof YardBlockBay
     */
    'operable': BayOperability;
    /**
     * 
     * @type {string}
     * @memberof YardBlockBay
     */
    'yardBlockId': string;
    /**
     * 
     * @type {YardBlockBayYardBlock}
     * @memberof YardBlockBay
     */
    'yardBlock': YardBlockBayYardBlock;
    /**
     * 
     * @type {Array<YardBlockSlot>}
     * @memberof YardBlockBay
     */
    'slots': Array<YardBlockSlot>;
    /**
     * 
     * @type {string}
     * @memberof YardBlockBay
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockBay
     */
    'deletedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface YardBlockBayDto
 */
export interface YardBlockBayDto {
    /**
     * 
     * @type {string}
     * @memberof YardBlockBayDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockBayDto
     */
    'yardBlockId': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockBayDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockBayDto
     */
    'sequenceNumber': number;
    /**
     * 
     * @type {BayOperability}
     * @memberof YardBlockBayDto
     */
    'operable': BayOperability;
    /**
     * 
     * @type {boolean}
     * @memberof YardBlockBayDto
     */
    'isLocked': boolean;
    /**
     * 
     * @type {number}
     * @memberof YardBlockBayDto
     */
    'capacityTotal': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockBayDto
     */
    'capacityUtilized': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof YardBlockBayDto
     */
    'slotIds': Array<string>;
}
/**
 * 
 * @export
 * @interface YardBlockBayYardBlock
 */
export interface YardBlockBayYardBlock {
    /**
     * 
     * @type {string}
     * @memberof YardBlockBayYardBlock
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockBayYardBlock
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof YardBlockBayYardBlock
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockBayYardBlock
     */
    'maxTier': number;
    /**
     * 
     * @type {BlockLabellingPattern}
     * @memberof YardBlockBayYardBlock
     */
    'bayLabellingPattern': BlockLabellingPattern;
    /**
     * 
     * @type {BlockLabellingPattern}
     * @memberof YardBlockBayYardBlock
     */
    'rowLabellingPattern': BlockLabellingPattern;
    /**
     * 
     * @type {boolean}
     * @memberof YardBlockBayYardBlock
     */
    'isFlexibleZone': boolean;
    /**
     * 
     * @type {SyncYardBlockDtoUsageOptions}
     * @memberof YardBlockBayYardBlock
     */
    'usageOptions': SyncYardBlockDtoUsageOptions;
    /**
     * 
     * @type {Array<YardBlockSlot>}
     * @memberof YardBlockBayYardBlock
     */
    'slots': Array<YardBlockSlot>;
    /**
     * 
     * @type {Array<YardBlockBay>}
     * @memberof YardBlockBayYardBlock
     */
    'bays': Array<YardBlockBay>;
    /**
     * 
     * @type {Array<YardBlockRow>}
     * @memberof YardBlockBayYardBlock
     */
    'rows': Array<YardBlockRow>;
    /**
     * 
     * @type {Array<YardBlockEquipmentType>}
     * @memberof YardBlockBayYardBlock
     */
    'equipmentTypes': Array<YardBlockEquipmentType>;
    /**
     * 
     * @type {YardBlockGeometry}
     * @memberof YardBlockBayYardBlock
     */
    'yardBlockGeometry'?: YardBlockGeometry | null;
    /**
     * 
     * @type {string}
     * @memberof YardBlockBayYardBlock
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockBayYardBlock
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {StackingStrategy}
     * @memberof YardBlockBayYardBlock
     */
    'stackingStrategy': StackingStrategy;
}
/**
 * 
 * @export
 * @interface YardBlockBaysCreateRequest
 */
export interface YardBlockBaysCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockBaysCreateRequest
     */
    'yardBlockId': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockBaysCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof YardBlockBaysCreateRequest
     */
    'nextToFirstBay': boolean;
}
/**
 * 
 * @export
 * @interface YardBlockBaysUpdateRequest
 */
export interface YardBlockBaysUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockBaysUpdateRequest
     */
    'yardBlockBayId': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockBaysUpdateRequest
     */
    'yardBlockId': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockBaysUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {BayOperability}
     * @memberof YardBlockBaysUpdateRequest
     */
    'operable'?: BayOperability | null;
}
/**
 * 
 * @export
 * @interface YardBlockDetailsDto
 */
export interface YardBlockDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof YardBlockDetailsDto
     */
    'blockId': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockDetailsDto
     */
    'blockName': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockDetailsDto
     */
    'totalSpace': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockDetailsDto
     */
    'occupiedSpace': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockDetailsDto
     */
    'filterMatchedContainers': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockDetailsDto
     */
    'nonFilterMatchedContainer': number;
}
/**
 * 
 * @export
 * @interface YardBlockDto
 */
export interface YardBlockDto {
    /**
     * 
     * @type {string}
     * @memberof YardBlockDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockDto
     */
    'rowCount': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockDto
     */
    'bayCount': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockDto
     */
    'maxTier': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockDto
     */
    'capacityTotal': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockDto
     */
    'capacityUtilized': number;
    /**
     * 
     * @type {Array<StackedInContainerDto>}
     * @memberof YardBlockDto
     */
    'stackedInContainers': Array<StackedInContainerDto>;
    /**
     * 
     * @type {number}
     * @memberof YardBlockDto
     */
    'capacityOccupied': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockDto
     */
    'capacityReserved': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockDto
     */
    'capacityLocked': number;
    /**
     * 
     * @type {boolean}
     * @memberof YardBlockDto
     */
    'isFlexibleZone': boolean;
    /**
     * 
     * @type {YardBlockUsageOptions}
     * @memberof YardBlockDto
     */
    'usageOptions'?: YardBlockUsageOptions | null;
    /**
     * 
     * @type {Array<EquipmentType>}
     * @memberof YardBlockDto
     */
    'equipmentTypes': Array<EquipmentType>;
    /**
     * 
     * @type {YardElementGeometryDto}
     * @memberof YardBlockDto
     */
    'geometry'?: YardElementGeometryDto | null;
    /**
     * 
     * @type {StackingStrategy}
     * @memberof YardBlockDto
     */
    'stackingStrategy': StackingStrategy;
    /**
     * 
     * @type {BlockLabellingPattern}
     * @memberof YardBlockDto
     */
    'rowLabellingPattern': BlockLabellingPattern;
    /**
     * 
     * @type {BlockLabellingPattern}
     * @memberof YardBlockDto
     */
    'bayLabellingPattern': BlockLabellingPattern;
}
/**
 * 
 * @export
 * @interface YardBlockEquipmentType
 */
export interface YardBlockEquipmentType {
    /**
     * 
     * @type {string}
     * @memberof YardBlockEquipmentType
     */
    'yardBlockId': string;
    /**
     * 
     * @type {EquipmentType}
     * @memberof YardBlockEquipmentType
     */
    'equipmentType': EquipmentType;
    /**
     * 
     * @type {number}
     * @memberof YardBlockEquipmentType
     */
    'tenantId': number;
    /**
     * 
     * @type {YardBlockBayYardBlock}
     * @memberof YardBlockEquipmentType
     */
    'block': YardBlockBayYardBlock;
}
/**
 * 
 * @export
 * @interface YardBlockGeometriesSetRequest
 */
export interface YardBlockGeometriesSetRequest {
    /**
     * 
     * @type {number}
     * @memberof YardBlockGeometriesSetRequest
     */
    'top'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof YardBlockGeometriesSetRequest
     */
    'left'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof YardBlockGeometriesSetRequest
     */
    'rotation'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof YardBlockGeometriesSetRequest
     */
    'width'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof YardBlockGeometriesSetRequest
     */
    'yardBlockId': string;
}
/**
 * 
 * @export
 * @interface YardBlockGeometry
 */
export interface YardBlockGeometry {
    /**
     * 
     * @type {string}
     * @memberof YardBlockGeometry
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockGeometry
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockGeometry
     */
    'top': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockGeometry
     */
    'left': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockGeometry
     */
    'rotation': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockGeometry
     */
    'width'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof YardBlockGeometry
     */
    'yardBlockId': string;
    /**
     * 
     * @type {YardBlockBayYardBlock}
     * @memberof YardBlockGeometry
     */
    'yardBlock': YardBlockBayYardBlock;
}
/**
 * 
 * @export
 * @interface YardBlockInconsistencyDto
 */
export interface YardBlockInconsistencyDto {
    /**
     * 
     * @type {StackInconsistencyType}
     * @memberof YardBlockInconsistencyDto
     */
    'type': StackInconsistencyType;
    /**
     * 
     * @type {string}
     * @memberof YardBlockInconsistencyDto
     */
    'containerNumber': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockInconsistencyDto
     */
    'size'?: number | null;
    /**
     * 
     * @type {Array<YardPositionDto>}
     * @memberof YardBlockInconsistencyDto
     */
    'location': Array<YardPositionDto>;
}
/**
 * 
 * @export
 * @interface YardBlockInformationDto
 */
export interface YardBlockInformationDto {
    /**
     * 
     * @type {string}
     * @memberof YardBlockInformationDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockInformationDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockInformationDto
     */
    'stackHeight': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockInformationDto
     */
    'slots': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockInformationDto
     */
    'available': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockInformationDto
     */
    'occupiedByContainer': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockInformationDto
     */
    'occupiedByReservation': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockInformationDto
     */
    'free': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockInformationDto
     */
    'occupied': number;
    /**
     * 
     * @type {Array<ContainerTurnoverGroupDto>}
     * @memberof YardBlockInformationDto
     */
    'groupedBy': Array<ContainerTurnoverGroupDto>;
}
/**
 * 
 * @export
 * @interface YardBlockRow
 */
export interface YardBlockRow {
    /**
     * 
     * @type {string}
     * @memberof YardBlockRow
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockRow
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof YardBlockRow
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockRow
     */
    'sequenceNumber': number;
    /**
     * 
     * @type {string}
     * @memberof YardBlockRow
     */
    'yardBlockId': string;
    /**
     * 
     * @type {YardBlockBayYardBlock}
     * @memberof YardBlockRow
     */
    'yardBlock': YardBlockBayYardBlock;
    /**
     * 
     * @type {Array<YardBlockSlot>}
     * @memberof YardBlockRow
     */
    'slots': Array<YardBlockSlot>;
    /**
     * 
     * @type {string}
     * @memberof YardBlockRow
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockRow
     */
    'deletedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface YardBlockRowDto
 */
export interface YardBlockRowDto {
    /**
     * 
     * @type {string}
     * @memberof YardBlockRowDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockRowDto
     */
    'yardBlockId': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockRowDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockRowDto
     */
    'sequenceNumber': number;
    /**
     * 
     * @type {boolean}
     * @memberof YardBlockRowDto
     */
    'isLocked': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof YardBlockRowDto
     */
    'slotIds': Array<string>;
}
/**
 * 
 * @export
 * @interface YardBlockRowsCreateRequest
 */
export interface YardBlockRowsCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockRowsCreateRequest
     */
    'yardBlockId': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockRowsCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof YardBlockRowsCreateRequest
     */
    'nextToFirstRow': boolean;
}
/**
 * 
 * @export
 * @interface YardBlockRowsUpdateRequest
 */
export interface YardBlockRowsUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockRowsUpdateRequest
     */
    'yardBlockRowId': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockRowsUpdateRequest
     */
    'yardBlockId': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockRowsUpdateRequest
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface YardBlockSlot
 */
export interface YardBlockSlot {
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlot
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockSlot
     */
    'tenantId': number;
    /**
     * 
     * @type {number}
     * @memberof YardBlockSlot
     */
    'tier': number;
    /**
     * 
     * @type {boolean}
     * @memberof YardBlockSlot
     */
    'locked': boolean;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlot
     */
    'yardBlockRowId': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlot
     */
    'yardBlockBayId': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlot
     */
    'yardBlockId': string;
    /**
     * 
     * @type {YardBlockBayYardBlock}
     * @memberof YardBlockSlot
     */
    'yardBlock': YardBlockBayYardBlock;
    /**
     * 
     * @type {YardBlockSlotYardBlockBay}
     * @memberof YardBlockSlot
     */
    'yardBlockBay': YardBlockSlotYardBlockBay;
    /**
     * 
     * @type {YardBlockSlotYardBlockRow}
     * @memberof YardBlockSlot
     */
    'yardBlockRow': YardBlockSlotYardBlockRow;
    /**
     * 
     * @type {Array<YardBlockSlotOccupancy>}
     * @memberof YardBlockSlot
     */
    'occupancies': Array<YardBlockSlotOccupancy>;
    /**
     * 
     * @type {Array<AllocationSpace>}
     * @memberof YardBlockSlot
     */
    'allocations': Array<AllocationSpace>;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlot
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlot
     */
    'deletedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface YardBlockSlotLocation
 */
export interface YardBlockSlotLocation {
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotLocation
     */
    'block': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotLocation
     */
    'bay': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotLocation
     */
    'row': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockSlotLocation
     */
    'tier': number;
}
/**
 * 
 * @export
 * @interface YardBlockSlotLocationDto
 */
export interface YardBlockSlotLocationDto {
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotLocationDto
     */
    'id': string;
    /**
     * 
     * @type {AllocationDestinationAndStackingStrategyDtoDestination}
     * @memberof YardBlockSlotLocationDto
     */
    'yardPosition': AllocationDestinationAndStackingStrategyDtoDestination;
}
/**
 * 
 * @export
 * @interface YardBlockSlotOccupanciesCreateReservationRequest
 */
export interface YardBlockSlotOccupanciesCreateReservationRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotOccupanciesCreateReservationRequest
     */
    'containerTurnoverId': string;
    /**
     * 
     * @type {AllocationDestinationAndStackingStrategyDtoDestination}
     * @memberof YardBlockSlotOccupanciesCreateReservationRequest
     */
    'yardPosition': AllocationDestinationAndStackingStrategyDtoDestination;
    /**
     * 
     * @type {boolean}
     * @memberof YardBlockSlotOccupanciesCreateReservationRequest
     */
    'forceCreation': boolean;
}
/**
 * 
 * @export
 * @interface YardBlockSlotOccupanciesUpdateContainerPositionRequest
 */
export interface YardBlockSlotOccupanciesUpdateContainerPositionRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotOccupanciesUpdateContainerPositionRequest
     */
    'containerTurnoverId': string;
    /**
     * 
     * @type {AllocationDestinationAndStackingStrategyDtoDestination}
     * @memberof YardBlockSlotOccupanciesUpdateContainerPositionRequest
     */
    'location': AllocationDestinationAndStackingStrategyDtoDestination;
}
/**
 * 
 * @export
 * @interface YardBlockSlotOccupancy
 */
export interface YardBlockSlotOccupancy {
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotOccupancy
     */
    'slotId': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotOccupancy
     */
    'containerTurnoverId': string;
    /**
     * 
     * @type {ContainerSection}
     * @memberof YardBlockSlotOccupancy
     */
    'section': ContainerSection;
    /**
     * 
     * @type {number}
     * @memberof YardBlockSlotOccupancy
     */
    'tenantId': number;
    /**
     * 
     * @type {boolean}
     * @memberof YardBlockSlotOccupancy
     */
    'isReserved': boolean;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotOccupancy
     */
    'createdAt'?: string | null;
    /**
     * 
     * @type {AllocationSpaceSlot}
     * @memberof YardBlockSlotOccupancy
     */
    'slot': AllocationSpaceSlot;
    /**
     * 
     * @type {ImoDeclarationContainerTurnover}
     * @memberof YardBlockSlotOccupancy
     */
    'containerTurnover': ImoDeclarationContainerTurnover;
}
/**
 * 
 * @export
 * @interface YardBlockSlotOccupancyDto
 */
export interface YardBlockSlotOccupancyDto {
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotOccupancyDto
     */
    'slotId': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotOccupancyDto
     */
    'containerNumber': string;
    /**
     * 
     * @type {ContainerSection}
     * @memberof YardBlockSlotOccupancyDto
     */
    'section': ContainerSection;
}
/**
 * 
 * @export
 * @interface YardBlockSlotYardBlockBay
 */
export interface YardBlockSlotYardBlockBay {
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotYardBlockBay
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockSlotYardBlockBay
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotYardBlockBay
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockSlotYardBlockBay
     */
    'sequenceNumber': number;
    /**
     * 
     * @type {BayOperability}
     * @memberof YardBlockSlotYardBlockBay
     */
    'operable': BayOperability;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotYardBlockBay
     */
    'yardBlockId': string;
    /**
     * 
     * @type {YardBlockBayYardBlock}
     * @memberof YardBlockSlotYardBlockBay
     */
    'yardBlock': YardBlockBayYardBlock;
    /**
     * 
     * @type {Array<YardBlockSlot>}
     * @memberof YardBlockSlotYardBlockBay
     */
    'slots': Array<YardBlockSlot>;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotYardBlockBay
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotYardBlockBay
     */
    'deletedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface YardBlockSlotYardBlockRow
 */
export interface YardBlockSlotYardBlockRow {
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotYardBlockRow
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockSlotYardBlockRow
     */
    'tenantId': number;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotYardBlockRow
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlockSlotYardBlockRow
     */
    'sequenceNumber': number;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotYardBlockRow
     */
    'yardBlockId': string;
    /**
     * 
     * @type {YardBlockBayYardBlock}
     * @memberof YardBlockSlotYardBlockRow
     */
    'yardBlock': YardBlockBayYardBlock;
    /**
     * 
     * @type {Array<YardBlockSlot>}
     * @memberof YardBlockSlotYardBlockRow
     */
    'slots': Array<YardBlockSlot>;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotYardBlockRow
     */
    'modifiedAt': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotYardBlockRow
     */
    'deletedAt'?: string | null;
}
/**
 * 
 * @export
 * @interface YardBlockUsageOptions
 */
export interface YardBlockUsageOptions {
    /**
     * 
     * @type {boolean}
     * @memberof YardBlockUsageOptions
     */
    'railHandover': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof YardBlockUsageOptions
     */
    'quayHandover': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof YardBlockUsageOptions
     */
    'truckHandover': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof YardBlockUsageOptions
     */
    'temporaryStowage': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof YardBlockUsageOptions
     */
    'dangerousGoodsHandling': boolean;
}
/**
 * 
 * @export
 * @interface YardBlocksCreateRequest
 */
export interface YardBlocksCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlocksCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlocksCreateRequest
     */
    'maxTier': number;
    /**
     * 
     * @type {boolean}
     * @memberof YardBlocksCreateRequest
     */
    'isFlexibleZone': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof YardBlocksCreateRequest
     */
    'bayNames': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof YardBlocksCreateRequest
     */
    'rowNames': Array<string>;
    /**
     * 
     * @type {Array<EquipmentType>}
     * @memberof YardBlocksCreateRequest
     */
    'equipmentTypes': Array<EquipmentType>;
    /**
     * 
     * @type {YardBlockUsageOptions}
     * @memberof YardBlocksCreateRequest
     */
    'usageOptions'?: YardBlockUsageOptions | null;
    /**
     * 
     * @type {StackingStrategy}
     * @memberof YardBlocksCreateRequest
     */
    'stackingStrategy': StackingStrategy;
    /**
     * 
     * @type {BlockLabellingPattern}
     * @memberof YardBlocksCreateRequest
     */
    'bayLabellingPattern': BlockLabellingPattern;
    /**
     * 
     * @type {BlockLabellingPattern}
     * @memberof YardBlocksCreateRequest
     */
    'rowLabellingPattern': BlockLabellingPattern;
}
/**
 * 
 * @export
 * @interface YardBlocksUpdateRequest
 */
export interface YardBlocksUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlocksUpdateRequest
     */
    'yardBlockId': string;
    /**
     * 
     * @type {string}
     * @memberof YardBlocksUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof YardBlocksUpdateRequest
     */
    'maxTier': number;
    /**
     * 
     * @type {Array<EquipmentType>}
     * @memberof YardBlocksUpdateRequest
     */
    'equipmentTypes'?: Array<EquipmentType> | null;
    /**
     * 
     * @type {YardBlockUsageOptions}
     * @memberof YardBlocksUpdateRequest
     */
    'usageOptions'?: YardBlockUsageOptions | null;
    /**
     * 
     * @type {StackingStrategy}
     * @memberof YardBlocksUpdateRequest
     */
    'stackingStrategy': StackingStrategy;
}
/**
 * 
 * @export
 * @interface YardElementGeometryDto
 */
export interface YardElementGeometryDto {
    /**
     * 
     * @type {number}
     * @memberof YardElementGeometryDto
     */
    'top': number;
    /**
     * 
     * @type {number}
     * @memberof YardElementGeometryDto
     */
    'left': number;
    /**
     * 
     * @type {number}
     * @memberof YardElementGeometryDto
     */
    'rotation': number;
    /**
     * 
     * @type {number}
     * @memberof YardElementGeometryDto
     */
    'width'?: number | null;
}
/**
 * 
 * @export
 * @interface YardInconsistencyDto
 */
export interface YardInconsistencyDto {
    /**
     * 
     * @type {string}
     * @memberof YardInconsistencyDto
     */
    'yardBlockId': string;
    /**
     * 
     * @type {string}
     * @memberof YardInconsistencyDto
     */
    'yardBlock': string;
    /**
     * 
     * @type {Array<YardBlockInconsistencyDto>}
     * @memberof YardInconsistencyDto
     */
    'inconsistencies': Array<YardBlockInconsistencyDto>;
}
/**
 * 
 * @export
 * @interface YardInternalMoveDto
 */
export interface YardInternalMoveDto {
    /**
     * 
     * @type {string}
     * @memberof YardInternalMoveDto
     */
    'containerNumber': string;
    /**
     * 
     * @type {Array<YardBlockSlotLocation>}
     * @memberof YardInternalMoveDto
     */
    'sourceLocation': Array<YardBlockSlotLocation>;
    /**
     * 
     * @type {Array<YardBlockSlotLocation>}
     * @memberof YardInternalMoveDto
     */
    'targetLocation': Array<YardBlockSlotLocation>;
}
/**
 * 
 * @export
 * @interface YardInternalMovesCreateMoveRequest
 */
export interface YardInternalMovesCreateMoveRequest {
    /**
     * 
     * @type {ContainerTurnoversFilterDto}
     * @memberof YardInternalMovesCreateMoveRequest
     */
    'filter'?: ContainerTurnoversFilterDto | null;
    /**
     * 
     * @type {string}
     * @memberof YardInternalMovesCreateMoveRequest
     */
    'containerTurnoverId'?: string | null;
}
/**
 * 
 * @export
 * @interface YardInternalMovesCreateRequest
 */
export interface YardInternalMovesCreateRequest {
    /**
     * 
     * @type {Array<CreateYardInternalMoveDto>}
     * @memberof YardInternalMovesCreateRequest
     */
    'moves': Array<CreateYardInternalMoveDto>;
}
/**
 * 
 * @export
 * @interface YardPositionDto
 */
export interface YardPositionDto {
    /**
     * 
     * @type {string}
     * @memberof YardPositionDto
     */
    'block'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof YardPositionDto
     */
    'bay'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof YardPositionDto
     */
    'row'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof YardPositionDto
     */
    'tier'?: number | null;
}
/**
 * 
 * @export
 * @interface YardSummaryDto
 */
export interface YardSummaryDto {
    /**
     * 
     * @type {number}
     * @memberof YardSummaryDto
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof YardSummaryDto
     */
    'occupied': number;
    /**
     * 
     * @type {number}
     * @memberof YardSummaryDto
     */
    'reserved': number;
    /**
     * 
     * @type {number}
     * @memberof YardSummaryDto
     */
    'free': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const YardUnit = {
    Yard: 'Yard',
    Block: 'Block',
    Bay: 'Bay',
    Row: 'Row',
    Stack: 'Stack',
    Slot: 'Slot'
} as const;

export type YardUnit = typeof YardUnit[keyof typeof YardUnit];



/**
 * AllocationRulesTemplateApi - axios parameter creator
 * @export
 */
export const AllocationRulesTemplateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/AllocationRulesTemplate/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AllocationRulesTemplateCreateRequest} [allocationRulesTemplateCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (allocationRulesTemplateCreateRequest?: AllocationRulesTemplateCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/AllocationRulesTemplate/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocationRulesTemplateCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/AllocationRulesTemplate/Get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/AllocationRulesTemplate/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isNameUnique: async (name?: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/AllocationRulesTemplate/IsNameUnique`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AllocationRulesTemplateUpdateRequest} [allocationRulesTemplateUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (allocationRulesTemplateUpdateRequest?: AllocationRulesTemplateUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/AllocationRulesTemplate/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocationRulesTemplateUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllocationRulesTemplateApi - functional programming interface
 * @export
 */
export const AllocationRulesTemplateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllocationRulesTemplateApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AllocationRulesTemplateCreateRequest} [allocationRulesTemplateCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(allocationRulesTemplateCreateRequest?: AllocationRulesTemplateCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationRuleTemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(allocationRulesTemplateCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationRuleTemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AllocationRuleTemplateDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isNameUnique(name?: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isNameUnique(name, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AllocationRulesTemplateUpdateRequest} [allocationRulesTemplateUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(allocationRulesTemplateUpdateRequest?: AllocationRulesTemplateUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationRuleTemplateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(allocationRulesTemplateUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AllocationRulesTemplateApi - factory interface
 * @export
 */
export const AllocationRulesTemplateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllocationRulesTemplateApiFp(configuration)
    return {
        /**
         * 
         * @param {AllocationRulesTemplateApiDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(requestParameters: AllocationRulesTemplateApiDeleteRequest = {}, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp._delete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AllocationRulesTemplateApiCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestParameters: AllocationRulesTemplateApiCreateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AllocationRuleTemplateDto> {
            return localVarFp.create(requestParameters.allocationRulesTemplateCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AllocationRulesTemplateApiGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(requestParameters: AllocationRulesTemplateApiGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AllocationRuleTemplateDto> {
            return localVarFp.get(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: AxiosRequestConfig): AxiosPromise<Array<AllocationRuleTemplateDto>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AllocationRulesTemplateApiIsNameUniqueRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isNameUnique(requestParameters: AllocationRulesTemplateApiIsNameUniqueRequest = {}, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.isNameUnique(requestParameters.name, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AllocationRulesTemplateApiUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(requestParameters: AllocationRulesTemplateApiUpdateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AllocationRuleTemplateDto> {
            return localVarFp.update(requestParameters.allocationRulesTemplateUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in AllocationRulesTemplateApi.
 * @export
 * @interface AllocationRulesTemplateApiDeleteRequest
 */
export interface AllocationRulesTemplateApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof AllocationRulesTemplateApiDelete
     */
    readonly id?: string
}

/**
 * Request parameters for create operation in AllocationRulesTemplateApi.
 * @export
 * @interface AllocationRulesTemplateApiCreateRequest
 */
export interface AllocationRulesTemplateApiCreateRequest {
    /**
     * 
     * @type {AllocationRulesTemplateCreateRequest}
     * @memberof AllocationRulesTemplateApiCreate
     */
    readonly allocationRulesTemplateCreateRequest?: AllocationRulesTemplateCreateRequest
}

/**
 * Request parameters for get operation in AllocationRulesTemplateApi.
 * @export
 * @interface AllocationRulesTemplateApiGetRequest
 */
export interface AllocationRulesTemplateApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AllocationRulesTemplateApiGet
     */
    readonly id?: string
}

/**
 * Request parameters for isNameUnique operation in AllocationRulesTemplateApi.
 * @export
 * @interface AllocationRulesTemplateApiIsNameUniqueRequest
 */
export interface AllocationRulesTemplateApiIsNameUniqueRequest {
    /**
     * 
     * @type {string}
     * @memberof AllocationRulesTemplateApiIsNameUnique
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof AllocationRulesTemplateApiIsNameUnique
     */
    readonly id?: string
}

/**
 * Request parameters for update operation in AllocationRulesTemplateApi.
 * @export
 * @interface AllocationRulesTemplateApiUpdateRequest
 */
export interface AllocationRulesTemplateApiUpdateRequest {
    /**
     * 
     * @type {AllocationRulesTemplateUpdateRequest}
     * @memberof AllocationRulesTemplateApiUpdate
     */
    readonly allocationRulesTemplateUpdateRequest?: AllocationRulesTemplateUpdateRequest
}

/**
 * AllocationRulesTemplateApi - object-oriented interface
 * @export
 * @class AllocationRulesTemplateApi
 * @extends {BaseAPI}
 */
export class AllocationRulesTemplateApi extends BaseAPI {
    /**
     * 
     * @param {AllocationRulesTemplateApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationRulesTemplateApi
     */
    public _delete(requestParameters: AllocationRulesTemplateApiDeleteRequest = {}, options?: AxiosRequestConfig) {
        return AllocationRulesTemplateApiFp(this.configuration)._delete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AllocationRulesTemplateApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationRulesTemplateApi
     */
    public create(requestParameters: AllocationRulesTemplateApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return AllocationRulesTemplateApiFp(this.configuration).create(requestParameters.allocationRulesTemplateCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AllocationRulesTemplateApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationRulesTemplateApi
     */
    public get(requestParameters: AllocationRulesTemplateApiGetRequest = {}, options?: AxiosRequestConfig) {
        return AllocationRulesTemplateApiFp(this.configuration).get(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationRulesTemplateApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return AllocationRulesTemplateApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AllocationRulesTemplateApiIsNameUniqueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationRulesTemplateApi
     */
    public isNameUnique(requestParameters: AllocationRulesTemplateApiIsNameUniqueRequest = {}, options?: AxiosRequestConfig) {
        return AllocationRulesTemplateApiFp(this.configuration).isNameUnique(requestParameters.name, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AllocationRulesTemplateApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationRulesTemplateApi
     */
    public update(requestParameters: AllocationRulesTemplateApiUpdateRequest = {}, options?: AxiosRequestConfig) {
        return AllocationRulesTemplateApiFp(this.configuration).update(requestParameters.allocationRulesTemplateUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AllocationRulesTemplateDestinationApi - axios parameter creator
 * @export
 */
export const AllocationRulesTemplateDestinationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/AllocationRulesTemplateDestination/Get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllocationRulesTemplateDestinationApi - functional programming interface
 * @export
 */
export const AllocationRulesTemplateDestinationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllocationRulesTemplateDestinationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuleDestinationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AllocationRulesTemplateDestinationApi - factory interface
 * @export
 */
export const AllocationRulesTemplateDestinationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllocationRulesTemplateDestinationApiFp(configuration)
    return {
        /**
         * 
         * @param {AllocationRulesTemplateDestinationApiGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(requestParameters: AllocationRulesTemplateDestinationApiGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<RuleDestinationDto> {
            return localVarFp.get(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for get operation in AllocationRulesTemplateDestinationApi.
 * @export
 * @interface AllocationRulesTemplateDestinationApiGetRequest
 */
export interface AllocationRulesTemplateDestinationApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof AllocationRulesTemplateDestinationApiGet
     */
    readonly id?: string
}

/**
 * AllocationRulesTemplateDestinationApi - object-oriented interface
 * @export
 * @class AllocationRulesTemplateDestinationApi
 * @extends {BaseAPI}
 */
export class AllocationRulesTemplateDestinationApi extends BaseAPI {
    /**
     * 
     * @param {AllocationRulesTemplateDestinationApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationRulesTemplateDestinationApi
     */
    public get(requestParameters: AllocationRulesTemplateDestinationApiGetRequest = {}, options?: AxiosRequestConfig) {
        return AllocationRulesTemplateDestinationApiFp(this.configuration).get(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AllocationsApi - axios parameter creator
 * @export
 */
export const AllocationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AllocationsCreateRequest} [allocationsCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (allocationsCreateRequest?: AllocationsCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Allocations/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocationsCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AllocationsCreateInternalMoveManualRequest} [allocationsCreateInternalMoveManualRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInternalMoveManual: async (allocationsCreateInternalMoveManualRequest?: AllocationsCreateInternalMoveManualRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Allocations/CreateInternalMoveManual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocationsCreateInternalMoveManualRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AllocationsCreateManualRequest} [allocationsCreateManualRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManual: async (allocationsCreateManualRequest?: AllocationsCreateManualRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Allocations/CreateManual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocationsCreateManualRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AllocationsCreateManualRequest} [allocationsCreateManualRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManualForSingleTurnover: async (allocationsCreateManualRequest?: AllocationsCreateManualRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Allocations/CreateManualForSingleTurnover`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocationsCreateManualRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AllocationsDangerousGoodsValidationRequest} [allocationsDangerousGoodsValidationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dangerousGoodsValidation: async (allocationsDangerousGoodsValidationRequest?: AllocationsDangerousGoodsValidationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Allocations/DangerousGoodsValidation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocationsDangerousGoodsValidationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocationStatistics: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Allocations/GetAllocationStatistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AllocationsPrePlanningValidationRequest} [allocationsPrePlanningValidationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prePlanningValidation: async (allocationsPrePlanningValidationRequest?: AllocationsPrePlanningValidationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Allocations/PrePlanningValidation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocationsPrePlanningValidationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AllocationsApi - functional programming interface
 * @export
 */
export const AllocationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AllocationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AllocationsCreateRequest} [allocationsCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(allocationsCreateRequest?: AllocationsCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(allocationsCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AllocationsCreateInternalMoveManualRequest} [allocationsCreateInternalMoveManualRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInternalMoveManual(allocationsCreateInternalMoveManualRequest?: AllocationsCreateInternalMoveManualRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InternalMoveResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInternalMoveManual(allocationsCreateInternalMoveManualRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AllocationsCreateManualRequest} [allocationsCreateManualRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManual(allocationsCreateManualRequest?: AllocationsCreateManualRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createManual(allocationsCreateManualRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AllocationsCreateManualRequest} [allocationsCreateManualRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createManualForSingleTurnover(allocationsCreateManualRequest?: AllocationsCreateManualRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createManualForSingleTurnover(allocationsCreateManualRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AllocationsDangerousGoodsValidationRequest} [allocationsDangerousGoodsValidationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dangerousGoodsValidation(allocationsDangerousGoodsValidationRequest?: AllocationsDangerousGoodsValidationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dangerousGoodsValidation(allocationsDangerousGoodsValidationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllocationStatistics(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AllocationStatisticsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllocationStatistics(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AllocationsPrePlanningValidationRequest} [allocationsPrePlanningValidationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prePlanningValidation(allocationsPrePlanningValidationRequest?: AllocationsPrePlanningValidationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prePlanningValidation(allocationsPrePlanningValidationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AllocationsApi - factory interface
 * @export
 */
export const AllocationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AllocationsApiFp(configuration)
    return {
        /**
         * 
         * @param {AllocationsApiCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestParameters: AllocationsApiCreateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AllocationDto> {
            return localVarFp.create(requestParameters.allocationsCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AllocationsApiCreateInternalMoveManualRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInternalMoveManual(requestParameters: AllocationsApiCreateInternalMoveManualRequest = {}, options?: AxiosRequestConfig): AxiosPromise<InternalMoveResultDto> {
            return localVarFp.createInternalMoveManual(requestParameters.allocationsCreateInternalMoveManualRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AllocationsApiCreateManualRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManual(requestParameters: AllocationsApiCreateManualRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AllocationDto> {
            return localVarFp.createManual(requestParameters.allocationsCreateManualRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AllocationsApiCreateManualForSingleTurnoverRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createManualForSingleTurnover(requestParameters: AllocationsApiCreateManualForSingleTurnoverRequest = {}, options?: AxiosRequestConfig): AxiosPromise<AllocationDto> {
            return localVarFp.createManualForSingleTurnover(requestParameters.allocationsCreateManualRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AllocationsApiDangerousGoodsValidationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dangerousGoodsValidation(requestParameters: AllocationsApiDangerousGoodsValidationRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.dangerousGoodsValidation(requestParameters.allocationsDangerousGoodsValidationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocationStatistics(options?: AxiosRequestConfig): AxiosPromise<Array<AllocationStatisticsDto>> {
            return localVarFp.getAllocationStatistics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AllocationsApiPrePlanningValidationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prePlanningValidation(requestParameters: AllocationsApiPrePlanningValidationRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.prePlanningValidation(requestParameters.allocationsPrePlanningValidationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for create operation in AllocationsApi.
 * @export
 * @interface AllocationsApiCreateRequest
 */
export interface AllocationsApiCreateRequest {
    /**
     * 
     * @type {AllocationsCreateRequest}
     * @memberof AllocationsApiCreate
     */
    readonly allocationsCreateRequest?: AllocationsCreateRequest
}

/**
 * Request parameters for createInternalMoveManual operation in AllocationsApi.
 * @export
 * @interface AllocationsApiCreateInternalMoveManualRequest
 */
export interface AllocationsApiCreateInternalMoveManualRequest {
    /**
     * 
     * @type {AllocationsCreateInternalMoveManualRequest}
     * @memberof AllocationsApiCreateInternalMoveManual
     */
    readonly allocationsCreateInternalMoveManualRequest?: AllocationsCreateInternalMoveManualRequest
}

/**
 * Request parameters for createManual operation in AllocationsApi.
 * @export
 * @interface AllocationsApiCreateManualRequest
 */
export interface AllocationsApiCreateManualRequest {
    /**
     * 
     * @type {AllocationsCreateManualRequest}
     * @memberof AllocationsApiCreateManual
     */
    readonly allocationsCreateManualRequest?: AllocationsCreateManualRequest
}

/**
 * Request parameters for createManualForSingleTurnover operation in AllocationsApi.
 * @export
 * @interface AllocationsApiCreateManualForSingleTurnoverRequest
 */
export interface AllocationsApiCreateManualForSingleTurnoverRequest {
    /**
     * 
     * @type {AllocationsCreateManualRequest}
     * @memberof AllocationsApiCreateManualForSingleTurnover
     */
    readonly allocationsCreateManualRequest?: AllocationsCreateManualRequest
}

/**
 * Request parameters for dangerousGoodsValidation operation in AllocationsApi.
 * @export
 * @interface AllocationsApiDangerousGoodsValidationRequest
 */
export interface AllocationsApiDangerousGoodsValidationRequest {
    /**
     * 
     * @type {AllocationsDangerousGoodsValidationRequest}
     * @memberof AllocationsApiDangerousGoodsValidation
     */
    readonly allocationsDangerousGoodsValidationRequest?: AllocationsDangerousGoodsValidationRequest
}

/**
 * Request parameters for prePlanningValidation operation in AllocationsApi.
 * @export
 * @interface AllocationsApiPrePlanningValidationRequest
 */
export interface AllocationsApiPrePlanningValidationRequest {
    /**
     * 
     * @type {AllocationsPrePlanningValidationRequest}
     * @memberof AllocationsApiPrePlanningValidation
     */
    readonly allocationsPrePlanningValidationRequest?: AllocationsPrePlanningValidationRequest
}

/**
 * AllocationsApi - object-oriented interface
 * @export
 * @class AllocationsApi
 * @extends {BaseAPI}
 */
export class AllocationsApi extends BaseAPI {
    /**
     * 
     * @param {AllocationsApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationsApi
     */
    public create(requestParameters: AllocationsApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return AllocationsApiFp(this.configuration).create(requestParameters.allocationsCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AllocationsApiCreateInternalMoveManualRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationsApi
     */
    public createInternalMoveManual(requestParameters: AllocationsApiCreateInternalMoveManualRequest = {}, options?: AxiosRequestConfig) {
        return AllocationsApiFp(this.configuration).createInternalMoveManual(requestParameters.allocationsCreateInternalMoveManualRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AllocationsApiCreateManualRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationsApi
     */
    public createManual(requestParameters: AllocationsApiCreateManualRequest = {}, options?: AxiosRequestConfig) {
        return AllocationsApiFp(this.configuration).createManual(requestParameters.allocationsCreateManualRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AllocationsApiCreateManualForSingleTurnoverRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationsApi
     */
    public createManualForSingleTurnover(requestParameters: AllocationsApiCreateManualForSingleTurnoverRequest = {}, options?: AxiosRequestConfig) {
        return AllocationsApiFp(this.configuration).createManualForSingleTurnover(requestParameters.allocationsCreateManualRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AllocationsApiDangerousGoodsValidationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationsApi
     */
    public dangerousGoodsValidation(requestParameters: AllocationsApiDangerousGoodsValidationRequest = {}, options?: AxiosRequestConfig) {
        return AllocationsApiFp(this.configuration).dangerousGoodsValidation(requestParameters.allocationsDangerousGoodsValidationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationsApi
     */
    public getAllocationStatistics(options?: AxiosRequestConfig) {
        return AllocationsApiFp(this.configuration).getAllocationStatistics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AllocationsApiPrePlanningValidationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AllocationsApi
     */
    public prePlanningValidation(requestParameters: AllocationsApiPrePlanningValidationRequest = {}, options?: AxiosRequestConfig) {
        return AllocationsApiFp(this.configuration).prePlanningValidation(requestParameters.allocationsPrePlanningValidationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BerthGeometriesApi - axios parameter creator
 * @export
 */
export const BerthGeometriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BerthGeometriesSetRequest} [berthGeometriesSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        set: async (berthGeometriesSetRequest?: BerthGeometriesSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/BerthGeometries/Set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(berthGeometriesSetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BerthGeometriesApi - functional programming interface
 * @export
 */
export const BerthGeometriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BerthGeometriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BerthGeometriesSetRequest} [berthGeometriesSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async set(berthGeometriesSetRequest?: BerthGeometriesSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BerthDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.set(berthGeometriesSetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BerthGeometriesApi - factory interface
 * @export
 */
export const BerthGeometriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BerthGeometriesApiFp(configuration)
    return {
        /**
         * 
         * @param {BerthGeometriesApiSetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        set(requestParameters: BerthGeometriesApiSetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<BerthDto> {
            return localVarFp.set(requestParameters.berthGeometriesSetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for set operation in BerthGeometriesApi.
 * @export
 * @interface BerthGeometriesApiSetRequest
 */
export interface BerthGeometriesApiSetRequest {
    /**
     * 
     * @type {BerthGeometriesSetRequest}
     * @memberof BerthGeometriesApiSet
     */
    readonly berthGeometriesSetRequest?: BerthGeometriesSetRequest
}

/**
 * BerthGeometriesApi - object-oriented interface
 * @export
 * @class BerthGeometriesApi
 * @extends {BaseAPI}
 */
export class BerthGeometriesApi extends BaseAPI {
    /**
     * 
     * @param {BerthGeometriesApiSetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BerthGeometriesApi
     */
    public set(requestParameters: BerthGeometriesApiSetRequest = {}, options?: AxiosRequestConfig) {
        return BerthGeometriesApiFp(this.configuration).set(requestParameters.berthGeometriesSetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BerthsApi - axios parameter creator
 * @export
 */
export const BerthsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [berthId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (berthId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Berths/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (berthId !== undefined) {
                localVarQueryParameter['berthId'] = berthId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BerthsCreateRequest} [berthsCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (berthsCreateRequest?: BerthsCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Berths/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(berthsCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/storage/api/Berths/Get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Berths/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUnique: async (name?: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Berths/IsUnique`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BerthsPutRequest} [berthsPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        put: async (berthsPutRequest?: BerthsPutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Berths/Put`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(berthsPutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BerthsApi - functional programming interface
 * @export
 */
export const BerthsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BerthsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [berthId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(berthId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(berthId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BerthsCreateRequest} [berthsCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(berthsCreateRequest?: BerthsCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BerthDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(berthsCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BerthDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BerthDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isUnique(name?: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isUnique(name, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BerthsPutRequest} [berthsPutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async put(berthsPutRequest?: BerthsPutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BerthDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.put(berthsPutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BerthsApi - factory interface
 * @export
 */
export const BerthsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BerthsApiFp(configuration)
    return {
        /**
         * 
         * @param {BerthsApiDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(requestParameters: BerthsApiDeleteRequest = {}, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp._delete(requestParameters.berthId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BerthsApiCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestParameters: BerthsApiCreateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<BerthDto> {
            return localVarFp.create(requestParameters.berthsCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BerthsApiGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(requestParameters: BerthsApiGetRequest, options?: AxiosRequestConfig): AxiosPromise<BerthDto> {
            return localVarFp.get(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: AxiosRequestConfig): AxiosPromise<Array<BerthDto>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BerthsApiIsUniqueRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUnique(requestParameters: BerthsApiIsUniqueRequest = {}, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.isUnique(requestParameters.name, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BerthsApiPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        put(requestParameters: BerthsApiPutRequest = {}, options?: AxiosRequestConfig): AxiosPromise<BerthDto> {
            return localVarFp.put(requestParameters.berthsPutRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in BerthsApi.
 * @export
 * @interface BerthsApiDeleteRequest
 */
export interface BerthsApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof BerthsApiDelete
     */
    readonly berthId?: string
}

/**
 * Request parameters for create operation in BerthsApi.
 * @export
 * @interface BerthsApiCreateRequest
 */
export interface BerthsApiCreateRequest {
    /**
     * 
     * @type {BerthsCreateRequest}
     * @memberof BerthsApiCreate
     */
    readonly berthsCreateRequest?: BerthsCreateRequest
}

/**
 * Request parameters for get operation in BerthsApi.
 * @export
 * @interface BerthsApiGetRequest
 */
export interface BerthsApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof BerthsApiGet
     */
    readonly id: string
}

/**
 * Request parameters for isUnique operation in BerthsApi.
 * @export
 * @interface BerthsApiIsUniqueRequest
 */
export interface BerthsApiIsUniqueRequest {
    /**
     * 
     * @type {string}
     * @memberof BerthsApiIsUnique
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof BerthsApiIsUnique
     */
    readonly id?: string
}

/**
 * Request parameters for put operation in BerthsApi.
 * @export
 * @interface BerthsApiPutRequest
 */
export interface BerthsApiPutRequest {
    /**
     * 
     * @type {BerthsPutRequest}
     * @memberof BerthsApiPut
     */
    readonly berthsPutRequest?: BerthsPutRequest
}

/**
 * BerthsApi - object-oriented interface
 * @export
 * @class BerthsApi
 * @extends {BaseAPI}
 */
export class BerthsApi extends BaseAPI {
    /**
     * 
     * @param {BerthsApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BerthsApi
     */
    public _delete(requestParameters: BerthsApiDeleteRequest = {}, options?: AxiosRequestConfig) {
        return BerthsApiFp(this.configuration)._delete(requestParameters.berthId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BerthsApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BerthsApi
     */
    public create(requestParameters: BerthsApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return BerthsApiFp(this.configuration).create(requestParameters.berthsCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BerthsApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BerthsApi
     */
    public get(requestParameters: BerthsApiGetRequest, options?: AxiosRequestConfig) {
        return BerthsApiFp(this.configuration).get(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BerthsApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return BerthsApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BerthsApiIsUniqueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BerthsApi
     */
    public isUnique(requestParameters: BerthsApiIsUniqueRequest = {}, options?: AxiosRequestConfig) {
        return BerthsApiFp(this.configuration).isUnique(requestParameters.name, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BerthsApiPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BerthsApi
     */
    public put(requestParameters: BerthsApiPutRequest = {}, options?: AxiosRequestConfig) {
        return BerthsApiFp(this.configuration).put(requestParameters.berthsPutRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CarrierVisitRuleDestinationApi - axios parameter creator
 * @export
 */
export const CarrierVisitRuleDestinationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/CarrierVisitRuleDestination/Get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CarrierVisitRuleDestinationApi - functional programming interface
 * @export
 */
export const CarrierVisitRuleDestinationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CarrierVisitRuleDestinationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuleDestinationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CarrierVisitRuleDestinationApi - factory interface
 * @export
 */
export const CarrierVisitRuleDestinationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CarrierVisitRuleDestinationApiFp(configuration)
    return {
        /**
         * 
         * @param {CarrierVisitRuleDestinationApiGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(requestParameters: CarrierVisitRuleDestinationApiGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<RuleDestinationDto> {
            return localVarFp.get(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for get operation in CarrierVisitRuleDestinationApi.
 * @export
 * @interface CarrierVisitRuleDestinationApiGetRequest
 */
export interface CarrierVisitRuleDestinationApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitRuleDestinationApiGet
     */
    readonly id?: string
}

/**
 * CarrierVisitRuleDestinationApi - object-oriented interface
 * @export
 * @class CarrierVisitRuleDestinationApi
 * @extends {BaseAPI}
 */
export class CarrierVisitRuleDestinationApi extends BaseAPI {
    /**
     * 
     * @param {CarrierVisitRuleDestinationApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierVisitRuleDestinationApi
     */
    public get(requestParameters: CarrierVisitRuleDestinationApiGetRequest = {}, options?: AxiosRequestConfig) {
        return CarrierVisitRuleDestinationApiFp(this.configuration).get(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CarrierVisitsApi - axios parameter creator
 * @export
 */
export const CarrierVisitsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<AllocationRulePropertiesDto>} [allocationRulePropertiesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationSummary: async (allocationRulePropertiesDto?: Array<AllocationRulePropertiesDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/CarrierVisits/AllocationSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(allocationRulePropertiesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CarrierVisitsCreateCarrierVisitAllocationAndRulesRequest} [carrierVisitsCreateCarrierVisitAllocationAndRulesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCarrierVisitAllocationAndRules: async (carrierVisitsCreateCarrierVisitAllocationAndRulesRequest?: CarrierVisitsCreateCarrierVisitAllocationAndRulesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/CarrierVisits/CreateCarrierVisitAllocationAndRules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(carrierVisitsCreateCarrierVisitAllocationAndRulesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/CarrierVisits/Get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<CarrierVisitStatus>} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (query?: Array<CarrierVisitStatus>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/CarrierVisits/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (query) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} carrierVisitId 
         * @param {CarrierVisitDirection} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocationRules: async (carrierVisitId: number, direction?: CarrierVisitDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'carrierVisitId' is not null or undefined
            assertParamExists('getAllocationRules', 'carrierVisitId', carrierVisitId)
            const localVarPath = `/storage/api/CarrierVisits/GetAllocationRules/{carrierVisitId}`
                .replace(`{${"carrierVisitId"}}`, encodeURIComponent(String(carrierVisitId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarrierVisitContainerData: async (date?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/CarrierVisits/GetCarrierVisitContainerData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any instanceof Date) ?
                    (date as any).toISOString().substr(0,10) :
                    date;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {CarrierVisitDirection} [visitDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarrierVisitSummary: async (id?: number, visitDirection?: CarrierVisitDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/CarrierVisits/GetCarrierVisitSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (visitDirection !== undefined) {
                localVarQueryParameter['visitDirection'] = visitDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [id] 
         * @param {CarrierVisitDirection} [visitDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarrierVisitSummaryV2: async (id?: number, visitDirection?: CarrierVisitDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/CarrierVisits/GetCarrierVisitSummaryV2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (visitDirection !== undefined) {
                localVarQueryParameter['visitDirection'] = visitDirection;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CarrierVisitsUpsertRequest} [carrierVisitsUpsertRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsert: async (carrierVisitsUpsertRequest?: CarrierVisitsUpsertRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/CarrierVisits/Upsert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(carrierVisitsUpsertRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CarrierVisitsApi - functional programming interface
 * @export
 */
export const CarrierVisitsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CarrierVisitsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<AllocationRulePropertiesDto>} [allocationRulePropertiesDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allocationSummary(allocationRulePropertiesDto?: Array<AllocationRulePropertiesDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AllocationRuleSummaryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.allocationSummary(allocationRulePropertiesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CarrierVisitsCreateCarrierVisitAllocationAndRulesRequest} [carrierVisitsCreateCarrierVisitAllocationAndRulesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCarrierVisitAllocationAndRules(carrierVisitsCreateCarrierVisitAllocationAndRulesRequest?: CarrierVisitsCreateCarrierVisitAllocationAndRulesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCarrierVisitAllocationAndRules(carrierVisitsCreateCarrierVisitAllocationAndRulesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarrierVisitDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<CarrierVisitStatus>} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(query?: Array<CarrierVisitStatus>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarrierVisitDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} carrierVisitId 
         * @param {CarrierVisitDirection} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllocationRules(carrierVisitId: number, direction?: CarrierVisitDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarrierVisitAllocationRuleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllocationRules(carrierVisitId, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [date] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCarrierVisitContainerData(date?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarrierVisitContainerDataDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCarrierVisitContainerData(date, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {CarrierVisitDirection} [visitDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCarrierVisitSummary(id?: number, visitDirection?: CarrierVisitDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarrierVisitSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCarrierVisitSummary(id, visitDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [id] 
         * @param {CarrierVisitDirection} [visitDirection] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCarrierVisitSummaryV2(id?: number, visitDirection?: CarrierVisitDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CarrierVisitSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCarrierVisitSummaryV2(id, visitDirection, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CarrierVisitsUpsertRequest} [carrierVisitsUpsertRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsert(carrierVisitsUpsertRequest?: CarrierVisitsUpsertRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsert(carrierVisitsUpsertRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CarrierVisitsApi - factory interface
 * @export
 */
export const CarrierVisitsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CarrierVisitsApiFp(configuration)
    return {
        /**
         * 
         * @param {CarrierVisitsApiAllocationSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allocationSummary(requestParameters: CarrierVisitsApiAllocationSummaryRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<AllocationRuleSummaryDto>> {
            return localVarFp.allocationSummary(requestParameters.allocationRulePropertiesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CarrierVisitsApiCreateCarrierVisitAllocationAndRulesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCarrierVisitAllocationAndRules(requestParameters: CarrierVisitsApiCreateCarrierVisitAllocationAndRulesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createCarrierVisitAllocationAndRules(requestParameters.carrierVisitsCreateCarrierVisitAllocationAndRulesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CarrierVisitsApiGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(requestParameters: CarrierVisitsApiGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CarrierVisitDto> {
            return localVarFp.get(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CarrierVisitsApiGetAllRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(requestParameters: CarrierVisitsApiGetAllRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<CarrierVisitDto>> {
            return localVarFp.getAll(requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CarrierVisitsApiGetAllocationRulesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllocationRules(requestParameters: CarrierVisitsApiGetAllocationRulesRequest, options?: AxiosRequestConfig): AxiosPromise<Array<CarrierVisitAllocationRuleDto>> {
            return localVarFp.getAllocationRules(requestParameters.carrierVisitId, requestParameters.direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CarrierVisitsApiGetCarrierVisitContainerDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarrierVisitContainerData(requestParameters: CarrierVisitsApiGetCarrierVisitContainerDataRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<CarrierVisitContainerDataDto>> {
            return localVarFp.getCarrierVisitContainerData(requestParameters.date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CarrierVisitsApiGetCarrierVisitSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarrierVisitSummary(requestParameters: CarrierVisitsApiGetCarrierVisitSummaryRequest = {}, options?: AxiosRequestConfig): AxiosPromise<CarrierVisitSummaryDto> {
            return localVarFp.getCarrierVisitSummary(requestParameters.id, requestParameters.visitDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CarrierVisitsApiGetCarrierVisitSummaryV2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarrierVisitSummaryV2(requestParameters: CarrierVisitsApiGetCarrierVisitSummaryV2Request = {}, options?: AxiosRequestConfig): AxiosPromise<CarrierVisitSummaryDto> {
            return localVarFp.getCarrierVisitSummaryV2(requestParameters.id, requestParameters.visitDirection, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CarrierVisitsApiUpsertRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsert(requestParameters: CarrierVisitsApiUpsertRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.upsert(requestParameters.carrierVisitsUpsertRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for allocationSummary operation in CarrierVisitsApi.
 * @export
 * @interface CarrierVisitsApiAllocationSummaryRequest
 */
export interface CarrierVisitsApiAllocationSummaryRequest {
    /**
     * 
     * @type {Array<AllocationRulePropertiesDto>}
     * @memberof CarrierVisitsApiAllocationSummary
     */
    readonly allocationRulePropertiesDto?: Array<AllocationRulePropertiesDto>
}

/**
 * Request parameters for createCarrierVisitAllocationAndRules operation in CarrierVisitsApi.
 * @export
 * @interface CarrierVisitsApiCreateCarrierVisitAllocationAndRulesRequest
 */
export interface CarrierVisitsApiCreateCarrierVisitAllocationAndRulesRequest {
    /**
     * 
     * @type {CarrierVisitsCreateCarrierVisitAllocationAndRulesRequest}
     * @memberof CarrierVisitsApiCreateCarrierVisitAllocationAndRules
     */
    readonly carrierVisitsCreateCarrierVisitAllocationAndRulesRequest?: CarrierVisitsCreateCarrierVisitAllocationAndRulesRequest
}

/**
 * Request parameters for get operation in CarrierVisitsApi.
 * @export
 * @interface CarrierVisitsApiGetRequest
 */
export interface CarrierVisitsApiGetRequest {
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitsApiGet
     */
    readonly id?: number
}

/**
 * Request parameters for getAll operation in CarrierVisitsApi.
 * @export
 * @interface CarrierVisitsApiGetAllRequest
 */
export interface CarrierVisitsApiGetAllRequest {
    /**
     * 
     * @type {Array<CarrierVisitStatus>}
     * @memberof CarrierVisitsApiGetAll
     */
    readonly query?: Array<CarrierVisitStatus>
}

/**
 * Request parameters for getAllocationRules operation in CarrierVisitsApi.
 * @export
 * @interface CarrierVisitsApiGetAllocationRulesRequest
 */
export interface CarrierVisitsApiGetAllocationRulesRequest {
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitsApiGetAllocationRules
     */
    readonly carrierVisitId: number

    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof CarrierVisitsApiGetAllocationRules
     */
    readonly direction?: CarrierVisitDirection
}

/**
 * Request parameters for getCarrierVisitContainerData operation in CarrierVisitsApi.
 * @export
 * @interface CarrierVisitsApiGetCarrierVisitContainerDataRequest
 */
export interface CarrierVisitsApiGetCarrierVisitContainerDataRequest {
    /**
     * 
     * @type {string}
     * @memberof CarrierVisitsApiGetCarrierVisitContainerData
     */
    readonly date?: string
}

/**
 * Request parameters for getCarrierVisitSummary operation in CarrierVisitsApi.
 * @export
 * @interface CarrierVisitsApiGetCarrierVisitSummaryRequest
 */
export interface CarrierVisitsApiGetCarrierVisitSummaryRequest {
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitsApiGetCarrierVisitSummary
     */
    readonly id?: number

    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof CarrierVisitsApiGetCarrierVisitSummary
     */
    readonly visitDirection?: CarrierVisitDirection
}

/**
 * Request parameters for getCarrierVisitSummaryV2 operation in CarrierVisitsApi.
 * @export
 * @interface CarrierVisitsApiGetCarrierVisitSummaryV2Request
 */
export interface CarrierVisitsApiGetCarrierVisitSummaryV2Request {
    /**
     * 
     * @type {number}
     * @memberof CarrierVisitsApiGetCarrierVisitSummaryV2
     */
    readonly id?: number

    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof CarrierVisitsApiGetCarrierVisitSummaryV2
     */
    readonly visitDirection?: CarrierVisitDirection
}

/**
 * Request parameters for upsert operation in CarrierVisitsApi.
 * @export
 * @interface CarrierVisitsApiUpsertRequest
 */
export interface CarrierVisitsApiUpsertRequest {
    /**
     * 
     * @type {CarrierVisitsUpsertRequest}
     * @memberof CarrierVisitsApiUpsert
     */
    readonly carrierVisitsUpsertRequest?: CarrierVisitsUpsertRequest
}

/**
 * CarrierVisitsApi - object-oriented interface
 * @export
 * @class CarrierVisitsApi
 * @extends {BaseAPI}
 */
export class CarrierVisitsApi extends BaseAPI {
    /**
     * 
     * @param {CarrierVisitsApiAllocationSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierVisitsApi
     */
    public allocationSummary(requestParameters: CarrierVisitsApiAllocationSummaryRequest = {}, options?: AxiosRequestConfig) {
        return CarrierVisitsApiFp(this.configuration).allocationSummary(requestParameters.allocationRulePropertiesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CarrierVisitsApiCreateCarrierVisitAllocationAndRulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierVisitsApi
     */
    public createCarrierVisitAllocationAndRules(requestParameters: CarrierVisitsApiCreateCarrierVisitAllocationAndRulesRequest = {}, options?: AxiosRequestConfig) {
        return CarrierVisitsApiFp(this.configuration).createCarrierVisitAllocationAndRules(requestParameters.carrierVisitsCreateCarrierVisitAllocationAndRulesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CarrierVisitsApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierVisitsApi
     */
    public get(requestParameters: CarrierVisitsApiGetRequest = {}, options?: AxiosRequestConfig) {
        return CarrierVisitsApiFp(this.configuration).get(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CarrierVisitsApiGetAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierVisitsApi
     */
    public getAll(requestParameters: CarrierVisitsApiGetAllRequest = {}, options?: AxiosRequestConfig) {
        return CarrierVisitsApiFp(this.configuration).getAll(requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CarrierVisitsApiGetAllocationRulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierVisitsApi
     */
    public getAllocationRules(requestParameters: CarrierVisitsApiGetAllocationRulesRequest, options?: AxiosRequestConfig) {
        return CarrierVisitsApiFp(this.configuration).getAllocationRules(requestParameters.carrierVisitId, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CarrierVisitsApiGetCarrierVisitContainerDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierVisitsApi
     */
    public getCarrierVisitContainerData(requestParameters: CarrierVisitsApiGetCarrierVisitContainerDataRequest = {}, options?: AxiosRequestConfig) {
        return CarrierVisitsApiFp(this.configuration).getCarrierVisitContainerData(requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CarrierVisitsApiGetCarrierVisitSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierVisitsApi
     */
    public getCarrierVisitSummary(requestParameters: CarrierVisitsApiGetCarrierVisitSummaryRequest = {}, options?: AxiosRequestConfig) {
        return CarrierVisitsApiFp(this.configuration).getCarrierVisitSummary(requestParameters.id, requestParameters.visitDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CarrierVisitsApiGetCarrierVisitSummaryV2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierVisitsApi
     */
    public getCarrierVisitSummaryV2(requestParameters: CarrierVisitsApiGetCarrierVisitSummaryV2Request = {}, options?: AxiosRequestConfig) {
        return CarrierVisitsApiFp(this.configuration).getCarrierVisitSummaryV2(requestParameters.id, requestParameters.visitDirection, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CarrierVisitsApiUpsertRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarrierVisitsApi
     */
    public upsert(requestParameters: CarrierVisitsApiUpsertRequest = {}, options?: AxiosRequestConfig) {
        return CarrierVisitsApiFp(this.configuration).upsert(requestParameters.carrierVisitsUpsertRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContainerTurnoversApi - axios parameter creator
 * @export
 */
export const ContainerTurnoversApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [containerOrReferenceNumber] 
         * @param {CarrierType} [inboundCarrierType] 
         * @param {CarrierType} [outboundCarrierType] 
         * @param {number} [inboundCarrier] 
         * @param {number} [outboundCarrier] 
         * @param {boolean} [isEmpty] 
         * @param {number} [size] 
         * @param {string} [containerIsoCode] 
         * @param {string} [typeCode] 
         * @param {string} [portOfLoading] 
         * @param {string} [portOfDischarge] 
         * @param {Array<string>} [weightClasses] 
         * @param {boolean} [isReefer] 
         * @param {boolean} [isDamaged] 
         * @param {Array<string>} [imoClasses] 
         * @param {boolean} [isRestow] 
         * @param {string} [containerOperator] 
         * @param {string} [consignee] 
         * @param {boolean} [isDangerous] 
         * @param {ContainerStatus} [locationStatus] 
         * @param {ContainerType} [containerType] 
         * @param {ContainerHeight} [containerHeight] 
         * @param {boolean} [isInTerminal] 
         * @param {number} [customerId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (containerOrReferenceNumber?: string, inboundCarrierType?: CarrierType, outboundCarrierType?: CarrierType, inboundCarrier?: number, outboundCarrier?: number, isEmpty?: boolean, size?: number, containerIsoCode?: string, typeCode?: string, portOfLoading?: string, portOfDischarge?: string, weightClasses?: Array<string>, isReefer?: boolean, isDamaged?: boolean, imoClasses?: Array<string>, isRestow?: boolean, containerOperator?: string, consignee?: string, isDangerous?: boolean, locationStatus?: ContainerStatus, containerType?: ContainerType, containerHeight?: ContainerHeight, isInTerminal?: boolean, customerId?: number, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/ContainerTurnovers/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (containerOrReferenceNumber !== undefined) {
                localVarQueryParameter['ContainerOrReferenceNumber'] = containerOrReferenceNumber;
            }

            if (inboundCarrierType !== undefined) {
                localVarQueryParameter['InboundCarrierType'] = inboundCarrierType;
            }

            if (outboundCarrierType !== undefined) {
                localVarQueryParameter['OutboundCarrierType'] = outboundCarrierType;
            }

            if (inboundCarrier !== undefined) {
                localVarQueryParameter['InboundCarrier'] = inboundCarrier;
            }

            if (outboundCarrier !== undefined) {
                localVarQueryParameter['OutboundCarrier'] = outboundCarrier;
            }

            if (isEmpty !== undefined) {
                localVarQueryParameter['IsEmpty'] = isEmpty;
            }

            if (size !== undefined) {
                localVarQueryParameter['Size'] = size;
            }

            if (containerIsoCode !== undefined) {
                localVarQueryParameter['ContainerIsoCode'] = containerIsoCode;
            }

            if (typeCode !== undefined) {
                localVarQueryParameter['TypeCode'] = typeCode;
            }

            if (portOfLoading !== undefined) {
                localVarQueryParameter['PortOfLoading'] = portOfLoading;
            }

            if (portOfDischarge !== undefined) {
                localVarQueryParameter['PortOfDischarge'] = portOfDischarge;
            }

            if (weightClasses) {
                localVarQueryParameter['WeightClasses'] = weightClasses;
            }

            if (isReefer !== undefined) {
                localVarQueryParameter['IsReefer'] = isReefer;
            }

            if (isDamaged !== undefined) {
                localVarQueryParameter['IsDamaged'] = isDamaged;
            }

            if (imoClasses) {
                localVarQueryParameter['ImoClasses'] = imoClasses;
            }

            if (isRestow !== undefined) {
                localVarQueryParameter['IsRestow'] = isRestow;
            }

            if (containerOperator !== undefined) {
                localVarQueryParameter['ContainerOperator'] = containerOperator;
            }

            if (consignee !== undefined) {
                localVarQueryParameter['Consignee'] = consignee;
            }

            if (isDangerous !== undefined) {
                localVarQueryParameter['IsDangerous'] = isDangerous;
            }

            if (locationStatus !== undefined) {
                localVarQueryParameter['LocationStatus'] = locationStatus;
            }

            if (containerType !== undefined) {
                localVarQueryParameter['ContainerType'] = containerType;
            }

            if (containerHeight !== undefined) {
                localVarQueryParameter['ContainerHeight'] = containerHeight;
            }

            if (isInTerminal !== undefined) {
                localVarQueryParameter['isInTerminal'] = isInTerminal;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [containerOrReferenceNumber] 
         * @param {CarrierType} [inboundCarrierType] 
         * @param {CarrierType} [outboundCarrierType] 
         * @param {number} [inboundCarrier] 
         * @param {number} [outboundCarrier] 
         * @param {boolean} [isEmpty] 
         * @param {number} [size] 
         * @param {string} [containerIsoCode] 
         * @param {string} [typeCode] 
         * @param {string} [portOfLoading] 
         * @param {string} [portOfDischarge] 
         * @param {Array<string>} [weightClasses] 
         * @param {boolean} [isReefer] 
         * @param {boolean} [isDamaged] 
         * @param {Array<string>} [imoClasses] 
         * @param {boolean} [isRestow] 
         * @param {string} [containerOperator] 
         * @param {string} [consignee] 
         * @param {boolean} [isDangerous] 
         * @param {ContainerStatus} [locationStatus] 
         * @param {ContainerType} [containerType] 
         * @param {ContainerHeight} [containerHeight] 
         * @param {boolean} [isInTerminal] 
         * @param {number} [customerId] 
         * @param {ContainerTurnoversGetAllAsOptionsRequest} [containerTurnoversGetAllAsOptionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAsOptions: async (containerOrReferenceNumber?: string, inboundCarrierType?: CarrierType, outboundCarrierType?: CarrierType, inboundCarrier?: number, outboundCarrier?: number, isEmpty?: boolean, size?: number, containerIsoCode?: string, typeCode?: string, portOfLoading?: string, portOfDischarge?: string, weightClasses?: Array<string>, isReefer?: boolean, isDamaged?: boolean, imoClasses?: Array<string>, isRestow?: boolean, containerOperator?: string, consignee?: string, isDangerous?: boolean, locationStatus?: ContainerStatus, containerType?: ContainerType, containerHeight?: ContainerHeight, isInTerminal?: boolean, customerId?: number, containerTurnoversGetAllAsOptionsRequest?: ContainerTurnoversGetAllAsOptionsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/ContainerTurnovers/GetAllAsOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (containerOrReferenceNumber !== undefined) {
                localVarQueryParameter['ContainerOrReferenceNumber'] = containerOrReferenceNumber;
            }

            if (inboundCarrierType !== undefined) {
                localVarQueryParameter['InboundCarrierType'] = inboundCarrierType;
            }

            if (outboundCarrierType !== undefined) {
                localVarQueryParameter['OutboundCarrierType'] = outboundCarrierType;
            }

            if (inboundCarrier !== undefined) {
                localVarQueryParameter['InboundCarrier'] = inboundCarrier;
            }

            if (outboundCarrier !== undefined) {
                localVarQueryParameter['OutboundCarrier'] = outboundCarrier;
            }

            if (isEmpty !== undefined) {
                localVarQueryParameter['IsEmpty'] = isEmpty;
            }

            if (size !== undefined) {
                localVarQueryParameter['Size'] = size;
            }

            if (containerIsoCode !== undefined) {
                localVarQueryParameter['ContainerIsoCode'] = containerIsoCode;
            }

            if (typeCode !== undefined) {
                localVarQueryParameter['TypeCode'] = typeCode;
            }

            if (portOfLoading !== undefined) {
                localVarQueryParameter['PortOfLoading'] = portOfLoading;
            }

            if (portOfDischarge !== undefined) {
                localVarQueryParameter['PortOfDischarge'] = portOfDischarge;
            }

            if (weightClasses) {
                localVarQueryParameter['WeightClasses'] = weightClasses;
            }

            if (isReefer !== undefined) {
                localVarQueryParameter['IsReefer'] = isReefer;
            }

            if (isDamaged !== undefined) {
                localVarQueryParameter['IsDamaged'] = isDamaged;
            }

            if (imoClasses) {
                localVarQueryParameter['ImoClasses'] = imoClasses;
            }

            if (isRestow !== undefined) {
                localVarQueryParameter['IsRestow'] = isRestow;
            }

            if (containerOperator !== undefined) {
                localVarQueryParameter['ContainerOperator'] = containerOperator;
            }

            if (consignee !== undefined) {
                localVarQueryParameter['Consignee'] = consignee;
            }

            if (isDangerous !== undefined) {
                localVarQueryParameter['IsDangerous'] = isDangerous;
            }

            if (locationStatus !== undefined) {
                localVarQueryParameter['LocationStatus'] = locationStatus;
            }

            if (containerType !== undefined) {
                localVarQueryParameter['ContainerType'] = containerType;
            }

            if (containerHeight !== undefined) {
                localVarQueryParameter['ContainerHeight'] = containerHeight;
            }

            if (isInTerminal !== undefined) {
                localVarQueryParameter['isInTerminal'] = isInTerminal;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(containerTurnoversGetAllAsOptionsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [containerOrReferenceNumber] 
         * @param {CarrierType} [inboundCarrierType] 
         * @param {CarrierType} [outboundCarrierType] 
         * @param {number} [inboundCarrier] 
         * @param {number} [outboundCarrier] 
         * @param {boolean} [isEmpty] 
         * @param {number} [size] 
         * @param {string} [containerIsoCode] 
         * @param {string} [typeCode] 
         * @param {string} [portOfLoading] 
         * @param {string} [portOfDischarge] 
         * @param {Array<string>} [weightClasses] 
         * @param {boolean} [isReefer] 
         * @param {boolean} [isDamaged] 
         * @param {Array<string>} [imoClasses] 
         * @param {boolean} [isRestow] 
         * @param {string} [containerOperator] 
         * @param {string} [consignee] 
         * @param {boolean} [isDangerous] 
         * @param {ContainerStatus} [locationStatus] 
         * @param {ContainerType} [containerType] 
         * @param {ContainerHeight} [containerHeight] 
         * @param {boolean} [isInTerminal] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBasicInfo: async (containerOrReferenceNumber?: string, inboundCarrierType?: CarrierType, outboundCarrierType?: CarrierType, inboundCarrier?: number, outboundCarrier?: number, isEmpty?: boolean, size?: number, containerIsoCode?: string, typeCode?: string, portOfLoading?: string, portOfDischarge?: string, weightClasses?: Array<string>, isReefer?: boolean, isDamaged?: boolean, imoClasses?: Array<string>, isRestow?: boolean, containerOperator?: string, consignee?: string, isDangerous?: boolean, locationStatus?: ContainerStatus, containerType?: ContainerType, containerHeight?: ContainerHeight, isInTerminal?: boolean, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/ContainerTurnovers/GetAllBasicInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (containerOrReferenceNumber !== undefined) {
                localVarQueryParameter['ContainerOrReferenceNumber'] = containerOrReferenceNumber;
            }

            if (inboundCarrierType !== undefined) {
                localVarQueryParameter['InboundCarrierType'] = inboundCarrierType;
            }

            if (outboundCarrierType !== undefined) {
                localVarQueryParameter['OutboundCarrierType'] = outboundCarrierType;
            }

            if (inboundCarrier !== undefined) {
                localVarQueryParameter['InboundCarrier'] = inboundCarrier;
            }

            if (outboundCarrier !== undefined) {
                localVarQueryParameter['OutboundCarrier'] = outboundCarrier;
            }

            if (isEmpty !== undefined) {
                localVarQueryParameter['IsEmpty'] = isEmpty;
            }

            if (size !== undefined) {
                localVarQueryParameter['Size'] = size;
            }

            if (containerIsoCode !== undefined) {
                localVarQueryParameter['ContainerIsoCode'] = containerIsoCode;
            }

            if (typeCode !== undefined) {
                localVarQueryParameter['TypeCode'] = typeCode;
            }

            if (portOfLoading !== undefined) {
                localVarQueryParameter['PortOfLoading'] = portOfLoading;
            }

            if (portOfDischarge !== undefined) {
                localVarQueryParameter['PortOfDischarge'] = portOfDischarge;
            }

            if (weightClasses) {
                localVarQueryParameter['WeightClasses'] = weightClasses;
            }

            if (isReefer !== undefined) {
                localVarQueryParameter['IsReefer'] = isReefer;
            }

            if (isDamaged !== undefined) {
                localVarQueryParameter['IsDamaged'] = isDamaged;
            }

            if (imoClasses) {
                localVarQueryParameter['ImoClasses'] = imoClasses;
            }

            if (isRestow !== undefined) {
                localVarQueryParameter['IsRestow'] = isRestow;
            }

            if (containerOperator !== undefined) {
                localVarQueryParameter['ContainerOperator'] = containerOperator;
            }

            if (consignee !== undefined) {
                localVarQueryParameter['Consignee'] = consignee;
            }

            if (isDangerous !== undefined) {
                localVarQueryParameter['IsDangerous'] = isDangerous;
            }

            if (locationStatus !== undefined) {
                localVarQueryParameter['LocationStatus'] = locationStatus;
            }

            if (containerType !== undefined) {
                localVarQueryParameter['ContainerType'] = containerType;
            }

            if (containerHeight !== undefined) {
                localVarQueryParameter['ContainerHeight'] = containerHeight;
            }

            if (isInTerminal !== undefined) {
                localVarQueryParameter['isInTerminal'] = isInTerminal;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [containerOrReferenceNumber] 
         * @param {CarrierType} [inboundCarrierType] 
         * @param {CarrierType} [outboundCarrierType] 
         * @param {number} [inboundCarrier] 
         * @param {number} [outboundCarrier] 
         * @param {boolean} [isEmpty] 
         * @param {number} [size] 
         * @param {string} [containerIsoCode] 
         * @param {string} [typeCode] 
         * @param {string} [portOfLoading] 
         * @param {string} [portOfDischarge] 
         * @param {Array<string>} [weightClasses] 
         * @param {boolean} [isReefer] 
         * @param {boolean} [isDamaged] 
         * @param {Array<string>} [imoClasses] 
         * @param {boolean} [isRestow] 
         * @param {string} [containerOperator] 
         * @param {string} [consignee] 
         * @param {boolean} [isDangerous] 
         * @param {ContainerStatus} [locationStatus] 
         * @param {ContainerType} [containerType] 
         * @param {ContainerHeight} [containerHeight] 
         * @param {boolean} [isInTerminal] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainerPositionByNumber: async (containerOrReferenceNumber?: string, inboundCarrierType?: CarrierType, outboundCarrierType?: CarrierType, inboundCarrier?: number, outboundCarrier?: number, isEmpty?: boolean, size?: number, containerIsoCode?: string, typeCode?: string, portOfLoading?: string, portOfDischarge?: string, weightClasses?: Array<string>, isReefer?: boolean, isDamaged?: boolean, imoClasses?: Array<string>, isRestow?: boolean, containerOperator?: string, consignee?: string, isDangerous?: boolean, locationStatus?: ContainerStatus, containerType?: ContainerType, containerHeight?: ContainerHeight, isInTerminal?: boolean, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/ContainerTurnovers/GetContainerPositionByNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (containerOrReferenceNumber !== undefined) {
                localVarQueryParameter['ContainerOrReferenceNumber'] = containerOrReferenceNumber;
            }

            if (inboundCarrierType !== undefined) {
                localVarQueryParameter['InboundCarrierType'] = inboundCarrierType;
            }

            if (outboundCarrierType !== undefined) {
                localVarQueryParameter['OutboundCarrierType'] = outboundCarrierType;
            }

            if (inboundCarrier !== undefined) {
                localVarQueryParameter['InboundCarrier'] = inboundCarrier;
            }

            if (outboundCarrier !== undefined) {
                localVarQueryParameter['OutboundCarrier'] = outboundCarrier;
            }

            if (isEmpty !== undefined) {
                localVarQueryParameter['IsEmpty'] = isEmpty;
            }

            if (size !== undefined) {
                localVarQueryParameter['Size'] = size;
            }

            if (containerIsoCode !== undefined) {
                localVarQueryParameter['ContainerIsoCode'] = containerIsoCode;
            }

            if (typeCode !== undefined) {
                localVarQueryParameter['TypeCode'] = typeCode;
            }

            if (portOfLoading !== undefined) {
                localVarQueryParameter['PortOfLoading'] = portOfLoading;
            }

            if (portOfDischarge !== undefined) {
                localVarQueryParameter['PortOfDischarge'] = portOfDischarge;
            }

            if (weightClasses) {
                localVarQueryParameter['WeightClasses'] = weightClasses;
            }

            if (isReefer !== undefined) {
                localVarQueryParameter['IsReefer'] = isReefer;
            }

            if (isDamaged !== undefined) {
                localVarQueryParameter['IsDamaged'] = isDamaged;
            }

            if (imoClasses) {
                localVarQueryParameter['ImoClasses'] = imoClasses;
            }

            if (isRestow !== undefined) {
                localVarQueryParameter['IsRestow'] = isRestow;
            }

            if (containerOperator !== undefined) {
                localVarQueryParameter['ContainerOperator'] = containerOperator;
            }

            if (consignee !== undefined) {
                localVarQueryParameter['Consignee'] = consignee;
            }

            if (isDangerous !== undefined) {
                localVarQueryParameter['IsDangerous'] = isDangerous;
            }

            if (locationStatus !== undefined) {
                localVarQueryParameter['LocationStatus'] = locationStatus;
            }

            if (containerType !== undefined) {
                localVarQueryParameter['ContainerType'] = containerType;
            }

            if (containerHeight !== undefined) {
                localVarQueryParameter['ContainerHeight'] = containerHeight;
            }

            if (isInTerminal !== undefined) {
                localVarQueryParameter['isInTerminal'] = isInTerminal;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [containerNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainerTurnoverByContainerNumber: async (containerNumber?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/ContainerTurnovers/GetContainerTurnoverByContainerNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (containerNumber !== undefined) {
                localVarQueryParameter['containerNumber'] = containerNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [carrierVisitId] 
         * @param {CarrierVisitDirection} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainerTurnoversByCarrierVisit: async (carrierVisitId?: number, direction?: CarrierVisitDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/ContainerTurnovers/GetContainerTurnoversByCarrierVisit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (carrierVisitId !== undefined) {
                localVarQueryParameter['CarrierVisitId'] = carrierVisitId;
            }

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetails: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/ContainerTurnovers/GetDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {DeviceOperationMode} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStackOutSequence: async (referenceNumber?: string, mode?: DeviceOperationMode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/ContainerTurnovers/GetStackOutSequence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (referenceNumber !== undefined) {
                localVarQueryParameter['referenceNumber'] = referenceNumber;
            }

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CarrierVisitDirection} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTurnoversByCarrierDirection: async (direction?: CarrierVisitDirection, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/ContainerTurnovers/GetTurnoversByCarrierDirection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (direction !== undefined) {
                localVarQueryParameter['Direction'] = direction;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [containerNumber] 
         * @param {number} [orderId] 
         * @param {boolean} [isStackIn] 
         * @param {DeviceOperationMode} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYardOperationData: async (containerNumber?: string, orderId?: number, isStackIn?: boolean, mode?: DeviceOperationMode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/ContainerTurnovers/GetYardOperationData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (containerNumber !== undefined) {
                localVarQueryParameter['containerNumber'] = containerNumber;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (isStackIn !== undefined) {
                localVarQueryParameter['isStackIn'] = isStackIn;
            }

            if (mode !== undefined) {
                localVarQueryParameter['mode'] = mode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContainerTurnoversApi - functional programming interface
 * @export
 */
export const ContainerTurnoversApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContainerTurnoversApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [containerOrReferenceNumber] 
         * @param {CarrierType} [inboundCarrierType] 
         * @param {CarrierType} [outboundCarrierType] 
         * @param {number} [inboundCarrier] 
         * @param {number} [outboundCarrier] 
         * @param {boolean} [isEmpty] 
         * @param {number} [size] 
         * @param {string} [containerIsoCode] 
         * @param {string} [typeCode] 
         * @param {string} [portOfLoading] 
         * @param {string} [portOfDischarge] 
         * @param {Array<string>} [weightClasses] 
         * @param {boolean} [isReefer] 
         * @param {boolean} [isDamaged] 
         * @param {Array<string>} [imoClasses] 
         * @param {boolean} [isRestow] 
         * @param {string} [containerOperator] 
         * @param {string} [consignee] 
         * @param {boolean} [isDangerous] 
         * @param {ContainerStatus} [locationStatus] 
         * @param {ContainerType} [containerType] 
         * @param {ContainerHeight} [containerHeight] 
         * @param {boolean} [isInTerminal] 
         * @param {number} [customerId] 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(containerOrReferenceNumber?: string, inboundCarrierType?: CarrierType, outboundCarrierType?: CarrierType, inboundCarrier?: number, outboundCarrier?: number, isEmpty?: boolean, size?: number, containerIsoCode?: string, typeCode?: string, portOfLoading?: string, portOfDischarge?: string, weightClasses?: Array<string>, isReefer?: boolean, isDamaged?: boolean, imoClasses?: Array<string>, isRestow?: boolean, containerOperator?: string, consignee?: string, isDangerous?: boolean, locationStatus?: ContainerStatus, containerType?: ContainerType, containerHeight?: ContainerHeight, isInTerminal?: boolean, customerId?: number, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContainerTurnoverDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(containerOrReferenceNumber, inboundCarrierType, outboundCarrierType, inboundCarrier, outboundCarrier, isEmpty, size, containerIsoCode, typeCode, portOfLoading, portOfDischarge, weightClasses, isReefer, isDamaged, imoClasses, isRestow, containerOperator, consignee, isDangerous, locationStatus, containerType, containerHeight, isInTerminal, customerId, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [containerOrReferenceNumber] 
         * @param {CarrierType} [inboundCarrierType] 
         * @param {CarrierType} [outboundCarrierType] 
         * @param {number} [inboundCarrier] 
         * @param {number} [outboundCarrier] 
         * @param {boolean} [isEmpty] 
         * @param {number} [size] 
         * @param {string} [containerIsoCode] 
         * @param {string} [typeCode] 
         * @param {string} [portOfLoading] 
         * @param {string} [portOfDischarge] 
         * @param {Array<string>} [weightClasses] 
         * @param {boolean} [isReefer] 
         * @param {boolean} [isDamaged] 
         * @param {Array<string>} [imoClasses] 
         * @param {boolean} [isRestow] 
         * @param {string} [containerOperator] 
         * @param {string} [consignee] 
         * @param {boolean} [isDangerous] 
         * @param {ContainerStatus} [locationStatus] 
         * @param {ContainerType} [containerType] 
         * @param {ContainerHeight} [containerHeight] 
         * @param {boolean} [isInTerminal] 
         * @param {number} [customerId] 
         * @param {ContainerTurnoversGetAllAsOptionsRequest} [containerTurnoversGetAllAsOptionsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAsOptions(containerOrReferenceNumber?: string, inboundCarrierType?: CarrierType, outboundCarrierType?: CarrierType, inboundCarrier?: number, outboundCarrier?: number, isEmpty?: boolean, size?: number, containerIsoCode?: string, typeCode?: string, portOfLoading?: string, portOfDischarge?: string, weightClasses?: Array<string>, isReefer?: boolean, isDamaged?: boolean, imoClasses?: Array<string>, isRestow?: boolean, containerOperator?: string, consignee?: string, isDangerous?: boolean, locationStatus?: ContainerStatus, containerType?: ContainerType, containerHeight?: ContainerHeight, isInTerminal?: boolean, customerId?: number, containerTurnoversGetAllAsOptionsRequest?: ContainerTurnoversGetAllAsOptionsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GuidOptionDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAsOptions(containerOrReferenceNumber, inboundCarrierType, outboundCarrierType, inboundCarrier, outboundCarrier, isEmpty, size, containerIsoCode, typeCode, portOfLoading, portOfDischarge, weightClasses, isReefer, isDamaged, imoClasses, isRestow, containerOperator, consignee, isDangerous, locationStatus, containerType, containerHeight, isInTerminal, customerId, containerTurnoversGetAllAsOptionsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [containerOrReferenceNumber] 
         * @param {CarrierType} [inboundCarrierType] 
         * @param {CarrierType} [outboundCarrierType] 
         * @param {number} [inboundCarrier] 
         * @param {number} [outboundCarrier] 
         * @param {boolean} [isEmpty] 
         * @param {number} [size] 
         * @param {string} [containerIsoCode] 
         * @param {string} [typeCode] 
         * @param {string} [portOfLoading] 
         * @param {string} [portOfDischarge] 
         * @param {Array<string>} [weightClasses] 
         * @param {boolean} [isReefer] 
         * @param {boolean} [isDamaged] 
         * @param {Array<string>} [imoClasses] 
         * @param {boolean} [isRestow] 
         * @param {string} [containerOperator] 
         * @param {string} [consignee] 
         * @param {boolean} [isDangerous] 
         * @param {ContainerStatus} [locationStatus] 
         * @param {ContainerType} [containerType] 
         * @param {ContainerHeight} [containerHeight] 
         * @param {boolean} [isInTerminal] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBasicInfo(containerOrReferenceNumber?: string, inboundCarrierType?: CarrierType, outboundCarrierType?: CarrierType, inboundCarrier?: number, outboundCarrier?: number, isEmpty?: boolean, size?: number, containerIsoCode?: string, typeCode?: string, portOfLoading?: string, portOfDischarge?: string, weightClasses?: Array<string>, isReefer?: boolean, isDamaged?: boolean, imoClasses?: Array<string>, isRestow?: boolean, containerOperator?: string, consignee?: string, isDangerous?: boolean, locationStatus?: ContainerStatus, containerType?: ContainerType, containerHeight?: ContainerHeight, isInTerminal?: boolean, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerTurnoverBasicInfoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBasicInfo(containerOrReferenceNumber, inboundCarrierType, outboundCarrierType, inboundCarrier, outboundCarrier, isEmpty, size, containerIsoCode, typeCode, portOfLoading, portOfDischarge, weightClasses, isReefer, isDamaged, imoClasses, isRestow, containerOperator, consignee, isDangerous, locationStatus, containerType, containerHeight, isInTerminal, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [containerOrReferenceNumber] 
         * @param {CarrierType} [inboundCarrierType] 
         * @param {CarrierType} [outboundCarrierType] 
         * @param {number} [inboundCarrier] 
         * @param {number} [outboundCarrier] 
         * @param {boolean} [isEmpty] 
         * @param {number} [size] 
         * @param {string} [containerIsoCode] 
         * @param {string} [typeCode] 
         * @param {string} [portOfLoading] 
         * @param {string} [portOfDischarge] 
         * @param {Array<string>} [weightClasses] 
         * @param {boolean} [isReefer] 
         * @param {boolean} [isDamaged] 
         * @param {Array<string>} [imoClasses] 
         * @param {boolean} [isRestow] 
         * @param {string} [containerOperator] 
         * @param {string} [consignee] 
         * @param {boolean} [isDangerous] 
         * @param {ContainerStatus} [locationStatus] 
         * @param {ContainerType} [containerType] 
         * @param {ContainerHeight} [containerHeight] 
         * @param {boolean} [isInTerminal] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContainerPositionByNumber(containerOrReferenceNumber?: string, inboundCarrierType?: CarrierType, outboundCarrierType?: CarrierType, inboundCarrier?: number, outboundCarrier?: number, isEmpty?: boolean, size?: number, containerIsoCode?: string, typeCode?: string, portOfLoading?: string, portOfDischarge?: string, weightClasses?: Array<string>, isReefer?: boolean, isDamaged?: boolean, imoClasses?: Array<string>, isRestow?: boolean, containerOperator?: string, consignee?: string, isDangerous?: boolean, locationStatus?: ContainerStatus, containerType?: ContainerType, containerHeight?: ContainerHeight, isInTerminal?: boolean, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerTurnoverYardPositionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContainerPositionByNumber(containerOrReferenceNumber, inboundCarrierType, outboundCarrierType, inboundCarrier, outboundCarrier, isEmpty, size, containerIsoCode, typeCode, portOfLoading, portOfDischarge, weightClasses, isReefer, isDamaged, imoClasses, isRestow, containerOperator, consignee, isDangerous, locationStatus, containerType, containerHeight, isInTerminal, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [containerNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContainerTurnoverByContainerNumber(containerNumber?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContainerTurnoverDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContainerTurnoverByContainerNumber(containerNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [carrierVisitId] 
         * @param {CarrierVisitDirection} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContainerTurnoversByCarrierVisit(carrierVisitId?: number, direction?: CarrierVisitDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerTurnoverDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContainerTurnoversByCarrierVisit(carrierVisitId, direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDetails(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContainerTurnoverDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [referenceNumber] 
         * @param {DeviceOperationMode} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStackOutSequence(referenceNumber?: string, mode?: DeviceOperationMode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerStackOutSequenceItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStackOutSequence(referenceNumber, mode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CarrierVisitDirection} [direction] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTurnoversByCarrierDirection(direction?: CarrierVisitDirection, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerTurnoverDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTurnoversByCarrierDirection(direction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [containerNumber] 
         * @param {number} [orderId] 
         * @param {boolean} [isStackIn] 
         * @param {DeviceOperationMode} [mode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getYardOperationData(containerNumber?: string, orderId?: number, isStackIn?: boolean, mode?: DeviceOperationMode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContainerYardOperationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getYardOperationData(containerNumber, orderId, isStackIn, mode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContainerTurnoversApi - factory interface
 * @export
 */
export const ContainerTurnoversApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContainerTurnoversApiFp(configuration)
    return {
        /**
         * 
         * @param {ContainerTurnoversApiGetAllRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(requestParameters: ContainerTurnoversApiGetAllRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ContainerTurnoverDtoPaginatedList> {
            return localVarFp.getAll(requestParameters.containerOrReferenceNumber, requestParameters.inboundCarrierType, requestParameters.outboundCarrierType, requestParameters.inboundCarrier, requestParameters.outboundCarrier, requestParameters.isEmpty, requestParameters.size, requestParameters.containerIsoCode, requestParameters.typeCode, requestParameters.portOfLoading, requestParameters.portOfDischarge, requestParameters.weightClasses, requestParameters.isReefer, requestParameters.isDamaged, requestParameters.imoClasses, requestParameters.isRestow, requestParameters.containerOperator, requestParameters.consignee, requestParameters.isDangerous, requestParameters.locationStatus, requestParameters.containerType, requestParameters.containerHeight, requestParameters.isInTerminal, requestParameters.customerId, requestParameters.pageNumber, requestParameters.pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContainerTurnoversApiGetAllAsOptionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAsOptions(requestParameters: ContainerTurnoversApiGetAllAsOptionsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GuidOptionDtoPaginatedList> {
            return localVarFp.getAllAsOptions(requestParameters.containerOrReferenceNumber, requestParameters.inboundCarrierType, requestParameters.outboundCarrierType, requestParameters.inboundCarrier, requestParameters.outboundCarrier, requestParameters.isEmpty, requestParameters.size, requestParameters.containerIsoCode, requestParameters.typeCode, requestParameters.portOfLoading, requestParameters.portOfDischarge, requestParameters.weightClasses, requestParameters.isReefer, requestParameters.isDamaged, requestParameters.imoClasses, requestParameters.isRestow, requestParameters.containerOperator, requestParameters.consignee, requestParameters.isDangerous, requestParameters.locationStatus, requestParameters.containerType, requestParameters.containerHeight, requestParameters.isInTerminal, requestParameters.customerId, requestParameters.containerTurnoversGetAllAsOptionsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContainerTurnoversApiGetAllBasicInfoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBasicInfo(requestParameters: ContainerTurnoversApiGetAllBasicInfoRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ContainerTurnoverBasicInfoDto>> {
            return localVarFp.getAllBasicInfo(requestParameters.containerOrReferenceNumber, requestParameters.inboundCarrierType, requestParameters.outboundCarrierType, requestParameters.inboundCarrier, requestParameters.outboundCarrier, requestParameters.isEmpty, requestParameters.size, requestParameters.containerIsoCode, requestParameters.typeCode, requestParameters.portOfLoading, requestParameters.portOfDischarge, requestParameters.weightClasses, requestParameters.isReefer, requestParameters.isDamaged, requestParameters.imoClasses, requestParameters.isRestow, requestParameters.containerOperator, requestParameters.consignee, requestParameters.isDangerous, requestParameters.locationStatus, requestParameters.containerType, requestParameters.containerHeight, requestParameters.isInTerminal, requestParameters.customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContainerTurnoversApiGetContainerPositionByNumberRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainerPositionByNumber(requestParameters: ContainerTurnoversApiGetContainerPositionByNumberRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ContainerTurnoverYardPositionDto>> {
            return localVarFp.getContainerPositionByNumber(requestParameters.containerOrReferenceNumber, requestParameters.inboundCarrierType, requestParameters.outboundCarrierType, requestParameters.inboundCarrier, requestParameters.outboundCarrier, requestParameters.isEmpty, requestParameters.size, requestParameters.containerIsoCode, requestParameters.typeCode, requestParameters.portOfLoading, requestParameters.portOfDischarge, requestParameters.weightClasses, requestParameters.isReefer, requestParameters.isDamaged, requestParameters.imoClasses, requestParameters.isRestow, requestParameters.containerOperator, requestParameters.consignee, requestParameters.isDangerous, requestParameters.locationStatus, requestParameters.containerType, requestParameters.containerHeight, requestParameters.isInTerminal, requestParameters.customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContainerTurnoversApiGetContainerTurnoverByContainerNumberRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainerTurnoverByContainerNumber(requestParameters: ContainerTurnoversApiGetContainerTurnoverByContainerNumberRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ContainerTurnoverDto> {
            return localVarFp.getContainerTurnoverByContainerNumber(requestParameters.containerNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContainerTurnoversApiGetContainerTurnoversByCarrierVisitRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainerTurnoversByCarrierVisit(requestParameters: ContainerTurnoversApiGetContainerTurnoversByCarrierVisitRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ContainerTurnoverDto>> {
            return localVarFp.getContainerTurnoversByCarrierVisit(requestParameters.carrierVisitId, requestParameters.direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContainerTurnoversApiGetDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDetails(requestParameters: ContainerTurnoversApiGetDetailsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ContainerTurnoverDto> {
            return localVarFp.getDetails(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContainerTurnoversApiGetStackOutSequenceRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStackOutSequence(requestParameters: ContainerTurnoversApiGetStackOutSequenceRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ContainerStackOutSequenceItemDto>> {
            return localVarFp.getStackOutSequence(requestParameters.referenceNumber, requestParameters.mode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContainerTurnoversApiGetTurnoversByCarrierDirectionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTurnoversByCarrierDirection(requestParameters: ContainerTurnoversApiGetTurnoversByCarrierDirectionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ContainerTurnoverDto>> {
            return localVarFp.getTurnoversByCarrierDirection(requestParameters.direction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ContainerTurnoversApiGetYardOperationDataRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYardOperationData(requestParameters: ContainerTurnoversApiGetYardOperationDataRequest = {}, options?: AxiosRequestConfig): AxiosPromise<ContainerYardOperationDto> {
            return localVarFp.getYardOperationData(requestParameters.containerNumber, requestParameters.orderId, requestParameters.isStackIn, requestParameters.mode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAll operation in ContainerTurnoversApi.
 * @export
 * @interface ContainerTurnoversApiGetAllRequest
 */
export interface ContainerTurnoversApiGetAllRequest {
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly containerOrReferenceNumber?: string

    /**
     * 
     * @type {CarrierType}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly inboundCarrierType?: CarrierType

    /**
     * 
     * @type {CarrierType}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly outboundCarrierType?: CarrierType

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly inboundCarrier?: number

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly outboundCarrier?: number

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly isEmpty?: boolean

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly containerIsoCode?: string

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly typeCode?: string

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly portOfLoading?: string

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly portOfDischarge?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly weightClasses?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly isReefer?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly isDamaged?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly imoClasses?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly isRestow?: boolean

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly containerOperator?: string

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly consignee?: string

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly isDangerous?: boolean

    /**
     * 
     * @type {ContainerStatus}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly locationStatus?: ContainerStatus

    /**
     * 
     * @type {ContainerType}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly containerType?: ContainerType

    /**
     * 
     * @type {ContainerHeight}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly containerHeight?: ContainerHeight

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly isInTerminal?: boolean

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetAll
     */
    readonly pageSize?: number
}

/**
 * Request parameters for getAllAsOptions operation in ContainerTurnoversApi.
 * @export
 * @interface ContainerTurnoversApiGetAllAsOptionsRequest
 */
export interface ContainerTurnoversApiGetAllAsOptionsRequest {
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly containerOrReferenceNumber?: string

    /**
     * 
     * @type {CarrierType}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly inboundCarrierType?: CarrierType

    /**
     * 
     * @type {CarrierType}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly outboundCarrierType?: CarrierType

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly inboundCarrier?: number

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly outboundCarrier?: number

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly isEmpty?: boolean

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly containerIsoCode?: string

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly typeCode?: string

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly portOfLoading?: string

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly portOfDischarge?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly weightClasses?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly isReefer?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly isDamaged?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly imoClasses?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly isRestow?: boolean

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly containerOperator?: string

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly consignee?: string

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly isDangerous?: boolean

    /**
     * 
     * @type {ContainerStatus}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly locationStatus?: ContainerStatus

    /**
     * 
     * @type {ContainerType}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly containerType?: ContainerType

    /**
     * 
     * @type {ContainerHeight}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly containerHeight?: ContainerHeight

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly isInTerminal?: boolean

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly customerId?: number

    /**
     * 
     * @type {ContainerTurnoversGetAllAsOptionsRequest}
     * @memberof ContainerTurnoversApiGetAllAsOptions
     */
    readonly containerTurnoversGetAllAsOptionsRequest?: ContainerTurnoversGetAllAsOptionsRequest
}

/**
 * Request parameters for getAllBasicInfo operation in ContainerTurnoversApi.
 * @export
 * @interface ContainerTurnoversApiGetAllBasicInfoRequest
 */
export interface ContainerTurnoversApiGetAllBasicInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly containerOrReferenceNumber?: string

    /**
     * 
     * @type {CarrierType}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly inboundCarrierType?: CarrierType

    /**
     * 
     * @type {CarrierType}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly outboundCarrierType?: CarrierType

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly inboundCarrier?: number

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly outboundCarrier?: number

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly isEmpty?: boolean

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly containerIsoCode?: string

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly typeCode?: string

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly portOfLoading?: string

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly portOfDischarge?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly weightClasses?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly isReefer?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly isDamaged?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly imoClasses?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly isRestow?: boolean

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly containerOperator?: string

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly consignee?: string

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly isDangerous?: boolean

    /**
     * 
     * @type {ContainerStatus}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly locationStatus?: ContainerStatus

    /**
     * 
     * @type {ContainerType}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly containerType?: ContainerType

    /**
     * 
     * @type {ContainerHeight}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly containerHeight?: ContainerHeight

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly isInTerminal?: boolean

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetAllBasicInfo
     */
    readonly customerId?: number
}

/**
 * Request parameters for getContainerPositionByNumber operation in ContainerTurnoversApi.
 * @export
 * @interface ContainerTurnoversApiGetContainerPositionByNumberRequest
 */
export interface ContainerTurnoversApiGetContainerPositionByNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly containerOrReferenceNumber?: string

    /**
     * 
     * @type {CarrierType}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly inboundCarrierType?: CarrierType

    /**
     * 
     * @type {CarrierType}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly outboundCarrierType?: CarrierType

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly inboundCarrier?: number

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly outboundCarrier?: number

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly isEmpty?: boolean

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly containerIsoCode?: string

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly typeCode?: string

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly portOfLoading?: string

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly portOfDischarge?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly weightClasses?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly isReefer?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly isDamaged?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly imoClasses?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly isRestow?: boolean

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly containerOperator?: string

    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly consignee?: string

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly isDangerous?: boolean

    /**
     * 
     * @type {ContainerStatus}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly locationStatus?: ContainerStatus

    /**
     * 
     * @type {ContainerType}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly containerType?: ContainerType

    /**
     * 
     * @type {ContainerHeight}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly containerHeight?: ContainerHeight

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly isInTerminal?: boolean

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetContainerPositionByNumber
     */
    readonly customerId?: number
}

/**
 * Request parameters for getContainerTurnoverByContainerNumber operation in ContainerTurnoversApi.
 * @export
 * @interface ContainerTurnoversApiGetContainerTurnoverByContainerNumberRequest
 */
export interface ContainerTurnoversApiGetContainerTurnoverByContainerNumberRequest {
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetContainerTurnoverByContainerNumber
     */
    readonly containerNumber?: string
}

/**
 * Request parameters for getContainerTurnoversByCarrierVisit operation in ContainerTurnoversApi.
 * @export
 * @interface ContainerTurnoversApiGetContainerTurnoversByCarrierVisitRequest
 */
export interface ContainerTurnoversApiGetContainerTurnoversByCarrierVisitRequest {
    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetContainerTurnoversByCarrierVisit
     */
    readonly carrierVisitId?: number

    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof ContainerTurnoversApiGetContainerTurnoversByCarrierVisit
     */
    readonly direction?: CarrierVisitDirection
}

/**
 * Request parameters for getDetails operation in ContainerTurnoversApi.
 * @export
 * @interface ContainerTurnoversApiGetDetailsRequest
 */
export interface ContainerTurnoversApiGetDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetDetails
     */
    readonly id?: string
}

/**
 * Request parameters for getStackOutSequence operation in ContainerTurnoversApi.
 * @export
 * @interface ContainerTurnoversApiGetStackOutSequenceRequest
 */
export interface ContainerTurnoversApiGetStackOutSequenceRequest {
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetStackOutSequence
     */
    readonly referenceNumber?: string

    /**
     * 
     * @type {DeviceOperationMode}
     * @memberof ContainerTurnoversApiGetStackOutSequence
     */
    readonly mode?: DeviceOperationMode
}

/**
 * Request parameters for getTurnoversByCarrierDirection operation in ContainerTurnoversApi.
 * @export
 * @interface ContainerTurnoversApiGetTurnoversByCarrierDirectionRequest
 */
export interface ContainerTurnoversApiGetTurnoversByCarrierDirectionRequest {
    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof ContainerTurnoversApiGetTurnoversByCarrierDirection
     */
    readonly direction?: CarrierVisitDirection
}

/**
 * Request parameters for getYardOperationData operation in ContainerTurnoversApi.
 * @export
 * @interface ContainerTurnoversApiGetYardOperationDataRequest
 */
export interface ContainerTurnoversApiGetYardOperationDataRequest {
    /**
     * 
     * @type {string}
     * @memberof ContainerTurnoversApiGetYardOperationData
     */
    readonly containerNumber?: string

    /**
     * 
     * @type {number}
     * @memberof ContainerTurnoversApiGetYardOperationData
     */
    readonly orderId?: number

    /**
     * 
     * @type {boolean}
     * @memberof ContainerTurnoversApiGetYardOperationData
     */
    readonly isStackIn?: boolean

    /**
     * 
     * @type {DeviceOperationMode}
     * @memberof ContainerTurnoversApiGetYardOperationData
     */
    readonly mode?: DeviceOperationMode
}

/**
 * ContainerTurnoversApi - object-oriented interface
 * @export
 * @class ContainerTurnoversApi
 * @extends {BaseAPI}
 */
export class ContainerTurnoversApi extends BaseAPI {
    /**
     * 
     * @param {ContainerTurnoversApiGetAllRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerTurnoversApi
     */
    public getAll(requestParameters: ContainerTurnoversApiGetAllRequest = {}, options?: AxiosRequestConfig) {
        return ContainerTurnoversApiFp(this.configuration).getAll(requestParameters.containerOrReferenceNumber, requestParameters.inboundCarrierType, requestParameters.outboundCarrierType, requestParameters.inboundCarrier, requestParameters.outboundCarrier, requestParameters.isEmpty, requestParameters.size, requestParameters.containerIsoCode, requestParameters.typeCode, requestParameters.portOfLoading, requestParameters.portOfDischarge, requestParameters.weightClasses, requestParameters.isReefer, requestParameters.isDamaged, requestParameters.imoClasses, requestParameters.isRestow, requestParameters.containerOperator, requestParameters.consignee, requestParameters.isDangerous, requestParameters.locationStatus, requestParameters.containerType, requestParameters.containerHeight, requestParameters.isInTerminal, requestParameters.customerId, requestParameters.pageNumber, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContainerTurnoversApiGetAllAsOptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerTurnoversApi
     */
    public getAllAsOptions(requestParameters: ContainerTurnoversApiGetAllAsOptionsRequest = {}, options?: AxiosRequestConfig) {
        return ContainerTurnoversApiFp(this.configuration).getAllAsOptions(requestParameters.containerOrReferenceNumber, requestParameters.inboundCarrierType, requestParameters.outboundCarrierType, requestParameters.inboundCarrier, requestParameters.outboundCarrier, requestParameters.isEmpty, requestParameters.size, requestParameters.containerIsoCode, requestParameters.typeCode, requestParameters.portOfLoading, requestParameters.portOfDischarge, requestParameters.weightClasses, requestParameters.isReefer, requestParameters.isDamaged, requestParameters.imoClasses, requestParameters.isRestow, requestParameters.containerOperator, requestParameters.consignee, requestParameters.isDangerous, requestParameters.locationStatus, requestParameters.containerType, requestParameters.containerHeight, requestParameters.isInTerminal, requestParameters.customerId, requestParameters.containerTurnoversGetAllAsOptionsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContainerTurnoversApiGetAllBasicInfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerTurnoversApi
     */
    public getAllBasicInfo(requestParameters: ContainerTurnoversApiGetAllBasicInfoRequest = {}, options?: AxiosRequestConfig) {
        return ContainerTurnoversApiFp(this.configuration).getAllBasicInfo(requestParameters.containerOrReferenceNumber, requestParameters.inboundCarrierType, requestParameters.outboundCarrierType, requestParameters.inboundCarrier, requestParameters.outboundCarrier, requestParameters.isEmpty, requestParameters.size, requestParameters.containerIsoCode, requestParameters.typeCode, requestParameters.portOfLoading, requestParameters.portOfDischarge, requestParameters.weightClasses, requestParameters.isReefer, requestParameters.isDamaged, requestParameters.imoClasses, requestParameters.isRestow, requestParameters.containerOperator, requestParameters.consignee, requestParameters.isDangerous, requestParameters.locationStatus, requestParameters.containerType, requestParameters.containerHeight, requestParameters.isInTerminal, requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContainerTurnoversApiGetContainerPositionByNumberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerTurnoversApi
     */
    public getContainerPositionByNumber(requestParameters: ContainerTurnoversApiGetContainerPositionByNumberRequest = {}, options?: AxiosRequestConfig) {
        return ContainerTurnoversApiFp(this.configuration).getContainerPositionByNumber(requestParameters.containerOrReferenceNumber, requestParameters.inboundCarrierType, requestParameters.outboundCarrierType, requestParameters.inboundCarrier, requestParameters.outboundCarrier, requestParameters.isEmpty, requestParameters.size, requestParameters.containerIsoCode, requestParameters.typeCode, requestParameters.portOfLoading, requestParameters.portOfDischarge, requestParameters.weightClasses, requestParameters.isReefer, requestParameters.isDamaged, requestParameters.imoClasses, requestParameters.isRestow, requestParameters.containerOperator, requestParameters.consignee, requestParameters.isDangerous, requestParameters.locationStatus, requestParameters.containerType, requestParameters.containerHeight, requestParameters.isInTerminal, requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContainerTurnoversApiGetContainerTurnoverByContainerNumberRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerTurnoversApi
     */
    public getContainerTurnoverByContainerNumber(requestParameters: ContainerTurnoversApiGetContainerTurnoverByContainerNumberRequest = {}, options?: AxiosRequestConfig) {
        return ContainerTurnoversApiFp(this.configuration).getContainerTurnoverByContainerNumber(requestParameters.containerNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContainerTurnoversApiGetContainerTurnoversByCarrierVisitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerTurnoversApi
     */
    public getContainerTurnoversByCarrierVisit(requestParameters: ContainerTurnoversApiGetContainerTurnoversByCarrierVisitRequest = {}, options?: AxiosRequestConfig) {
        return ContainerTurnoversApiFp(this.configuration).getContainerTurnoversByCarrierVisit(requestParameters.carrierVisitId, requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContainerTurnoversApiGetDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerTurnoversApi
     */
    public getDetails(requestParameters: ContainerTurnoversApiGetDetailsRequest = {}, options?: AxiosRequestConfig) {
        return ContainerTurnoversApiFp(this.configuration).getDetails(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContainerTurnoversApiGetStackOutSequenceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerTurnoversApi
     */
    public getStackOutSequence(requestParameters: ContainerTurnoversApiGetStackOutSequenceRequest = {}, options?: AxiosRequestConfig) {
        return ContainerTurnoversApiFp(this.configuration).getStackOutSequence(requestParameters.referenceNumber, requestParameters.mode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContainerTurnoversApiGetTurnoversByCarrierDirectionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerTurnoversApi
     */
    public getTurnoversByCarrierDirection(requestParameters: ContainerTurnoversApiGetTurnoversByCarrierDirectionRequest = {}, options?: AxiosRequestConfig) {
        return ContainerTurnoversApiFp(this.configuration).getTurnoversByCarrierDirection(requestParameters.direction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ContainerTurnoversApiGetYardOperationDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContainerTurnoversApi
     */
    public getYardOperationData(requestParameters: ContainerTurnoversApiGetYardOperationDataRequest = {}, options?: AxiosRequestConfig) {
        return ContainerTurnoversApiFp(this.configuration).getYardOperationData(requestParameters.containerNumber, requestParameters.orderId, requestParameters.isStackIn, requestParameters.mode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Customers/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: AxiosRequestConfig): AxiosPromise<Array<CustomerDto>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return CustomersApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DGSegregationRuleApi - axios parameter creator
 * @export
 */
export const DGSegregationRuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DGSegregationRuleDeleteBulkRequest} [dGSegregationRuleDeleteBulkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBulk: async (dGSegregationRuleDeleteBulkRequest?: DGSegregationRuleDeleteBulkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/DGSegregationRule/DeleteBulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dGSegregationRuleDeleteBulkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/DGSegregationRule/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DGSegregationRuleUpsertBulkRequest} [dGSegregationRuleUpsertBulkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertBulk: async (dGSegregationRuleUpsertBulkRequest?: DGSegregationRuleUpsertBulkRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/DGSegregationRule/UpsertBulk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dGSegregationRuleUpsertBulkRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DGSegregationRuleApi - functional programming interface
 * @export
 */
export const DGSegregationRuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DGSegregationRuleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DGSegregationRuleDeleteBulkRequest} [dGSegregationRuleDeleteBulkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBulk(dGSegregationRuleDeleteBulkRequest?: DGSegregationRuleDeleteBulkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBulk(dGSegregationRuleDeleteBulkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DGSegregationRuleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DGSegregationRuleUpsertBulkRequest} [dGSegregationRuleUpsertBulkRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertBulk(dGSegregationRuleUpsertBulkRequest?: DGSegregationRuleUpsertBulkRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DGSegregationRuleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertBulk(dGSegregationRuleUpsertBulkRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DGSegregationRuleApi - factory interface
 * @export
 */
export const DGSegregationRuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DGSegregationRuleApiFp(configuration)
    return {
        /**
         * 
         * @param {DGSegregationRuleApiDeleteBulkRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBulk(requestParameters: DGSegregationRuleApiDeleteBulkRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteBulk(requestParameters.dGSegregationRuleDeleteBulkRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: AxiosRequestConfig): AxiosPromise<Array<DGSegregationRuleDto>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DGSegregationRuleApiUpsertBulkRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertBulk(requestParameters: DGSegregationRuleApiUpsertBulkRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<DGSegregationRuleDto>> {
            return localVarFp.upsertBulk(requestParameters.dGSegregationRuleUpsertBulkRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteBulk operation in DGSegregationRuleApi.
 * @export
 * @interface DGSegregationRuleApiDeleteBulkRequest
 */
export interface DGSegregationRuleApiDeleteBulkRequest {
    /**
     * 
     * @type {DGSegregationRuleDeleteBulkRequest}
     * @memberof DGSegregationRuleApiDeleteBulk
     */
    readonly dGSegregationRuleDeleteBulkRequest?: DGSegregationRuleDeleteBulkRequest
}

/**
 * Request parameters for upsertBulk operation in DGSegregationRuleApi.
 * @export
 * @interface DGSegregationRuleApiUpsertBulkRequest
 */
export interface DGSegregationRuleApiUpsertBulkRequest {
    /**
     * 
     * @type {DGSegregationRuleUpsertBulkRequest}
     * @memberof DGSegregationRuleApiUpsertBulk
     */
    readonly dGSegregationRuleUpsertBulkRequest?: DGSegregationRuleUpsertBulkRequest
}

/**
 * DGSegregationRuleApi - object-oriented interface
 * @export
 * @class DGSegregationRuleApi
 * @extends {BaseAPI}
 */
export class DGSegregationRuleApi extends BaseAPI {
    /**
     * 
     * @param {DGSegregationRuleApiDeleteBulkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DGSegregationRuleApi
     */
    public deleteBulk(requestParameters: DGSegregationRuleApiDeleteBulkRequest = {}, options?: AxiosRequestConfig) {
        return DGSegregationRuleApiFp(this.configuration).deleteBulk(requestParameters.dGSegregationRuleDeleteBulkRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DGSegregationRuleApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return DGSegregationRuleApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DGSegregationRuleApiUpsertBulkRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DGSegregationRuleApi
     */
    public upsertBulk(requestParameters: DGSegregationRuleApiUpsertBulkRequest = {}, options?: AxiosRequestConfig) {
        return DGSegregationRuleApiFp(this.configuration).upsertBulk(requestParameters.dGSegregationRuleUpsertBulkRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DangerousGoodsStackingRulesApi - axios parameter creator
 * @export
 */
export const DangerousGoodsStackingRulesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/DangerousGoodsStackingRules/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest} [dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrimaryImoClassStackingRule: async (dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest?: DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/DangerousGoodsStackingRules/UpdatePrimaryImoClassStackingRule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest} [dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSecondaryImoClassStackingRule: async (dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest?: DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/DangerousGoodsStackingRules/UpdateSecondaryImoClassStackingRule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DangerousGoodsStackingRulesApi - functional programming interface
 * @export
 */
export const DangerousGoodsStackingRulesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DangerousGoodsStackingRulesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DangerousGoodsStackingRuleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest} [dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePrimaryImoClassStackingRule(dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest?: DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePrimaryImoClassStackingRule(dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest} [dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSecondaryImoClassStackingRule(dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest?: DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSecondaryImoClassStackingRule(dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DangerousGoodsStackingRulesApi - factory interface
 * @export
 */
export const DangerousGoodsStackingRulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DangerousGoodsStackingRulesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: AxiosRequestConfig): AxiosPromise<Array<DangerousGoodsStackingRuleDto>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DangerousGoodsStackingRulesApiUpdatePrimaryImoClassStackingRuleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePrimaryImoClassStackingRule(requestParameters: DangerousGoodsStackingRulesApiUpdatePrimaryImoClassStackingRuleRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updatePrimaryImoClassStackingRule(requestParameters.dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DangerousGoodsStackingRulesApiUpdateSecondaryImoClassStackingRuleRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSecondaryImoClassStackingRule(requestParameters: DangerousGoodsStackingRulesApiUpdateSecondaryImoClassStackingRuleRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateSecondaryImoClassStackingRule(requestParameters.dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for updatePrimaryImoClassStackingRule operation in DangerousGoodsStackingRulesApi.
 * @export
 * @interface DangerousGoodsStackingRulesApiUpdatePrimaryImoClassStackingRuleRequest
 */
export interface DangerousGoodsStackingRulesApiUpdatePrimaryImoClassStackingRuleRequest {
    /**
     * 
     * @type {DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest}
     * @memberof DangerousGoodsStackingRulesApiUpdatePrimaryImoClassStackingRule
     */
    readonly dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest?: DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest
}

/**
 * Request parameters for updateSecondaryImoClassStackingRule operation in DangerousGoodsStackingRulesApi.
 * @export
 * @interface DangerousGoodsStackingRulesApiUpdateSecondaryImoClassStackingRuleRequest
 */
export interface DangerousGoodsStackingRulesApiUpdateSecondaryImoClassStackingRuleRequest {
    /**
     * 
     * @type {DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest}
     * @memberof DangerousGoodsStackingRulesApiUpdateSecondaryImoClassStackingRule
     */
    readonly dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest?: DangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest
}

/**
 * DangerousGoodsStackingRulesApi - object-oriented interface
 * @export
 * @class DangerousGoodsStackingRulesApi
 * @extends {BaseAPI}
 */
export class DangerousGoodsStackingRulesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DangerousGoodsStackingRulesApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return DangerousGoodsStackingRulesApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DangerousGoodsStackingRulesApiUpdatePrimaryImoClassStackingRuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DangerousGoodsStackingRulesApi
     */
    public updatePrimaryImoClassStackingRule(requestParameters: DangerousGoodsStackingRulesApiUpdatePrimaryImoClassStackingRuleRequest = {}, options?: AxiosRequestConfig) {
        return DangerousGoodsStackingRulesApiFp(this.configuration).updatePrimaryImoClassStackingRule(requestParameters.dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DangerousGoodsStackingRulesApiUpdateSecondaryImoClassStackingRuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DangerousGoodsStackingRulesApi
     */
    public updateSecondaryImoClassStackingRule(requestParameters: DangerousGoodsStackingRulesApiUpdateSecondaryImoClassStackingRuleRequest = {}, options?: AxiosRequestConfig) {
        return DangerousGoodsStackingRulesApiFp(this.configuration).updateSecondaryImoClassStackingRule(requestParameters.dangerousGoodsStackingRulesUpdatePrimaryImoClassStackingRuleRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EquipmentTypesApi - axios parameter creator
 * @export
 */
export const EquipmentTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [equipmentTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (equipmentTypeId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/EquipmentTypes/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (equipmentTypeId !== undefined) {
                localVarQueryParameter['equipmentTypeId'] = equipmentTypeId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EquipmentTypesCreateRequest} [equipmentTypesCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (equipmentTypesCreateRequest?: EquipmentTypesCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/EquipmentTypes/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentTypesCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/storage/api/EquipmentTypes/Get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/EquipmentTypes/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUnique: async (name?: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/EquipmentTypes/IsUnique`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {EquipmentTypesUpdateRequest} [equipmentTypesUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (equipmentTypesUpdateRequest?: EquipmentTypesUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/EquipmentTypes/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(equipmentTypesUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EquipmentTypesApi - functional programming interface
 * @export
 */
export const EquipmentTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EquipmentTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [equipmentTypeId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(equipmentTypeId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(equipmentTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EquipmentTypesCreateRequest} [equipmentTypesCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(equipmentTypesCreateRequest?: EquipmentTypesCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(equipmentTypesCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EquipmentTypeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isUnique(name?: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isUnique(name, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {EquipmentTypesUpdateRequest} [equipmentTypesUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(equipmentTypesUpdateRequest?: EquipmentTypesUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EquipmentTypeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(equipmentTypesUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EquipmentTypesApi - factory interface
 * @export
 */
export const EquipmentTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EquipmentTypesApiFp(configuration)
    return {
        /**
         * 
         * @param {EquipmentTypesApiDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(requestParameters: EquipmentTypesApiDeleteRequest = {}, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp._delete(requestParameters.equipmentTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EquipmentTypesApiCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestParameters: EquipmentTypesApiCreateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<EquipmentTypeDto> {
            return localVarFp.create(requestParameters.equipmentTypesCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EquipmentTypesApiGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(requestParameters: EquipmentTypesApiGetRequest, options?: AxiosRequestConfig): AxiosPromise<EquipmentTypeDto> {
            return localVarFp.get(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: AxiosRequestConfig): AxiosPromise<Array<EquipmentTypeDto>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EquipmentTypesApiIsUniqueRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUnique(requestParameters: EquipmentTypesApiIsUniqueRequest = {}, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.isUnique(requestParameters.name, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {EquipmentTypesApiUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(requestParameters: EquipmentTypesApiUpdateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<EquipmentTypeDto> {
            return localVarFp.update(requestParameters.equipmentTypesUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in EquipmentTypesApi.
 * @export
 * @interface EquipmentTypesApiDeleteRequest
 */
export interface EquipmentTypesApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentTypesApiDelete
     */
    readonly equipmentTypeId?: string
}

/**
 * Request parameters for create operation in EquipmentTypesApi.
 * @export
 * @interface EquipmentTypesApiCreateRequest
 */
export interface EquipmentTypesApiCreateRequest {
    /**
     * 
     * @type {EquipmentTypesCreateRequest}
     * @memberof EquipmentTypesApiCreate
     */
    readonly equipmentTypesCreateRequest?: EquipmentTypesCreateRequest
}

/**
 * Request parameters for get operation in EquipmentTypesApi.
 * @export
 * @interface EquipmentTypesApiGetRequest
 */
export interface EquipmentTypesApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentTypesApiGet
     */
    readonly id: string
}

/**
 * Request parameters for isUnique operation in EquipmentTypesApi.
 * @export
 * @interface EquipmentTypesApiIsUniqueRequest
 */
export interface EquipmentTypesApiIsUniqueRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentTypesApiIsUnique
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof EquipmentTypesApiIsUnique
     */
    readonly id?: string
}

/**
 * Request parameters for update operation in EquipmentTypesApi.
 * @export
 * @interface EquipmentTypesApiUpdateRequest
 */
export interface EquipmentTypesApiUpdateRequest {
    /**
     * 
     * @type {EquipmentTypesUpdateRequest}
     * @memberof EquipmentTypesApiUpdate
     */
    readonly equipmentTypesUpdateRequest?: EquipmentTypesUpdateRequest
}

/**
 * EquipmentTypesApi - object-oriented interface
 * @export
 * @class EquipmentTypesApi
 * @extends {BaseAPI}
 */
export class EquipmentTypesApi extends BaseAPI {
    /**
     * 
     * @param {EquipmentTypesApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentTypesApi
     */
    public _delete(requestParameters: EquipmentTypesApiDeleteRequest = {}, options?: AxiosRequestConfig) {
        return EquipmentTypesApiFp(this.configuration)._delete(requestParameters.equipmentTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentTypesApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentTypesApi
     */
    public create(requestParameters: EquipmentTypesApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return EquipmentTypesApiFp(this.configuration).create(requestParameters.equipmentTypesCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentTypesApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentTypesApi
     */
    public get(requestParameters: EquipmentTypesApiGetRequest, options?: AxiosRequestConfig) {
        return EquipmentTypesApiFp(this.configuration).get(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentTypesApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return EquipmentTypesApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentTypesApiIsUniqueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentTypesApi
     */
    public isUnique(requestParameters: EquipmentTypesApiIsUniqueRequest = {}, options?: AxiosRequestConfig) {
        return EquipmentTypesApiFp(this.configuration).isUnique(requestParameters.name, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {EquipmentTypesApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentTypesApi
     */
    public update(requestParameters: EquipmentTypesApiUpdateRequest = {}, options?: AxiosRequestConfig) {
        return EquipmentTypesApiFp(this.configuration).update(requestParameters.equipmentTypesUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GcsApi - axios parameter creator
 * @export
 */
export const GcsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (path?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Gcs/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download: async (path?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Gcs/Download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GcsApi - functional programming interface
 * @export
 */
export const GcsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GcsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(path?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(path, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [path] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download(path?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.download(path, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GcsApi - factory interface
 * @export
 */
export const GcsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GcsApiFp(configuration)
    return {
        /**
         * 
         * @param {GcsApiDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(requestParameters: GcsApiDeleteRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp._delete(requestParameters.path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GcsApiDownloadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download(requestParameters: GcsApiDownloadRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.download(requestParameters.path, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in GcsApi.
 * @export
 * @interface GcsApiDeleteRequest
 */
export interface GcsApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof GcsApiDelete
     */
    readonly path?: string
}

/**
 * Request parameters for download operation in GcsApi.
 * @export
 * @interface GcsApiDownloadRequest
 */
export interface GcsApiDownloadRequest {
    /**
     * 
     * @type {string}
     * @memberof GcsApiDownload
     */
    readonly path?: string
}

/**
 * GcsApi - object-oriented interface
 * @export
 * @class GcsApi
 * @extends {BaseAPI}
 */
export class GcsApi extends BaseAPI {
    /**
     * 
     * @param {GcsApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GcsApi
     */
    public _delete(requestParameters: GcsApiDeleteRequest = {}, options?: AxiosRequestConfig) {
        return GcsApiFp(this.configuration)._delete(requestParameters.path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GcsApiDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GcsApi
     */
    public download(requestParameters: GcsApiDownloadRequest = {}, options?: AxiosRequestConfig) {
        return GcsApiFp(this.configuration).download(requestParameters.path, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GeneralCargoAreasApi - axios parameter creator
 * @export
 */
export const GeneralCargoAreasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoAreas/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GeneralCargoAreasCreateRequest} [generalCargoAreasCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (generalCargoAreasCreateRequest?: GeneralCargoAreasCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoAreas/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalCargoAreasCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [generalCargoAreaId] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation: async (generalCargoAreaId?: string, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoAreas/CreateLocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (generalCargoAreaId !== undefined) {
                localVarQueryParameter['generalCargoAreaId'] = generalCargoAreaId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoAreas/DeleteLocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/storage/api/GeneralCargoAreas/Get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoAreas/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isNameUnique: async (name?: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoAreas/IsNameUnique`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {GeneralCargoAreasUpdateRequest} [generalCargoAreasUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, generalCargoAreasUpdateRequest?: GeneralCargoAreasUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id)
            const localVarPath = `/storage/api/GeneralCargoAreas/Update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalCargoAreasUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation: async (id?: string, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoAreas/UpdateLocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralCargoAreasApi - functional programming interface
 * @export
 */
export const GeneralCargoAreasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralCargoAreasApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GeneralCargoAreasCreateRequest} [generalCargoAreasCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(generalCargoAreasCreateRequest?: GeneralCargoAreasCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralCargoAreaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(generalCargoAreasCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [generalCargoAreaId] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLocation(generalCargoAreaId?: string, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralCargoLocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLocation(generalCargoAreaId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLocation(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLocation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralCargoAreaDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralCargoAreaDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isNameUnique(name?: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isNameUnique(name, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {GeneralCargoAreasUpdateRequest} [generalCargoAreasUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(id: string, generalCargoAreasUpdateRequest?: GeneralCargoAreasUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, generalCargoAreasUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocation(id?: string, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocation(id, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeneralCargoAreasApi - factory interface
 * @export
 */
export const GeneralCargoAreasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralCargoAreasApiFp(configuration)
    return {
        /**
         * 
         * @param {GeneralCargoAreasApiDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(requestParameters: GeneralCargoAreasApiDeleteRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp._delete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoAreasApiCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestParameters: GeneralCargoAreasApiCreateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GeneralCargoAreaDto> {
            return localVarFp.create(requestParameters.generalCargoAreasCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoAreasApiCreateLocationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocation(requestParameters: GeneralCargoAreasApiCreateLocationRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GeneralCargoLocationDto> {
            return localVarFp.createLocation(requestParameters.generalCargoAreaId, requestParameters.name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoAreasApiDeleteLocationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocation(requestParameters: GeneralCargoAreasApiDeleteLocationRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteLocation(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoAreasApiGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(requestParameters: GeneralCargoAreasApiGetRequest, options?: AxiosRequestConfig): AxiosPromise<GeneralCargoAreaDto> {
            return localVarFp.get(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: AxiosRequestConfig): AxiosPromise<Array<GeneralCargoAreaDto>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoAreasApiIsNameUniqueRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isNameUnique(requestParameters: GeneralCargoAreasApiIsNameUniqueRequest = {}, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.isNameUnique(requestParameters.name, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoAreasApiUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(requestParameters: GeneralCargoAreasApiUpdateRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.update(requestParameters.id, requestParameters.generalCargoAreasUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoAreasApiUpdateLocationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation(requestParameters: GeneralCargoAreasApiUpdateLocationRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.updateLocation(requestParameters.id, requestParameters.name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in GeneralCargoAreasApi.
 * @export
 * @interface GeneralCargoAreasApiDeleteRequest
 */
export interface GeneralCargoAreasApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoAreasApiDelete
     */
    readonly id?: string
}

/**
 * Request parameters for create operation in GeneralCargoAreasApi.
 * @export
 * @interface GeneralCargoAreasApiCreateRequest
 */
export interface GeneralCargoAreasApiCreateRequest {
    /**
     * 
     * @type {GeneralCargoAreasCreateRequest}
     * @memberof GeneralCargoAreasApiCreate
     */
    readonly generalCargoAreasCreateRequest?: GeneralCargoAreasCreateRequest
}

/**
 * Request parameters for createLocation operation in GeneralCargoAreasApi.
 * @export
 * @interface GeneralCargoAreasApiCreateLocationRequest
 */
export interface GeneralCargoAreasApiCreateLocationRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoAreasApiCreateLocation
     */
    readonly generalCargoAreaId?: string

    /**
     * 
     * @type {string}
     * @memberof GeneralCargoAreasApiCreateLocation
     */
    readonly name?: string
}

/**
 * Request parameters for deleteLocation operation in GeneralCargoAreasApi.
 * @export
 * @interface GeneralCargoAreasApiDeleteLocationRequest
 */
export interface GeneralCargoAreasApiDeleteLocationRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoAreasApiDeleteLocation
     */
    readonly id?: string
}

/**
 * Request parameters for get operation in GeneralCargoAreasApi.
 * @export
 * @interface GeneralCargoAreasApiGetRequest
 */
export interface GeneralCargoAreasApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoAreasApiGet
     */
    readonly id: string
}

/**
 * Request parameters for isNameUnique operation in GeneralCargoAreasApi.
 * @export
 * @interface GeneralCargoAreasApiIsNameUniqueRequest
 */
export interface GeneralCargoAreasApiIsNameUniqueRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoAreasApiIsNameUnique
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof GeneralCargoAreasApiIsNameUnique
     */
    readonly id?: string
}

/**
 * Request parameters for update operation in GeneralCargoAreasApi.
 * @export
 * @interface GeneralCargoAreasApiUpdateRequest
 */
export interface GeneralCargoAreasApiUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoAreasApiUpdate
     */
    readonly id: string

    /**
     * 
     * @type {GeneralCargoAreasUpdateRequest}
     * @memberof GeneralCargoAreasApiUpdate
     */
    readonly generalCargoAreasUpdateRequest?: GeneralCargoAreasUpdateRequest
}

/**
 * Request parameters for updateLocation operation in GeneralCargoAreasApi.
 * @export
 * @interface GeneralCargoAreasApiUpdateLocationRequest
 */
export interface GeneralCargoAreasApiUpdateLocationRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoAreasApiUpdateLocation
     */
    readonly id?: string

    /**
     * 
     * @type {string}
     * @memberof GeneralCargoAreasApiUpdateLocation
     */
    readonly name?: string
}

/**
 * GeneralCargoAreasApi - object-oriented interface
 * @export
 * @class GeneralCargoAreasApi
 * @extends {BaseAPI}
 */
export class GeneralCargoAreasApi extends BaseAPI {
    /**
     * 
     * @param {GeneralCargoAreasApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoAreasApi
     */
    public _delete(requestParameters: GeneralCargoAreasApiDeleteRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoAreasApiFp(this.configuration)._delete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoAreasApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoAreasApi
     */
    public create(requestParameters: GeneralCargoAreasApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoAreasApiFp(this.configuration).create(requestParameters.generalCargoAreasCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoAreasApiCreateLocationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoAreasApi
     */
    public createLocation(requestParameters: GeneralCargoAreasApiCreateLocationRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoAreasApiFp(this.configuration).createLocation(requestParameters.generalCargoAreaId, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoAreasApiDeleteLocationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoAreasApi
     */
    public deleteLocation(requestParameters: GeneralCargoAreasApiDeleteLocationRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoAreasApiFp(this.configuration).deleteLocation(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoAreasApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoAreasApi
     */
    public get(requestParameters: GeneralCargoAreasApiGetRequest, options?: AxiosRequestConfig) {
        return GeneralCargoAreasApiFp(this.configuration).get(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoAreasApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return GeneralCargoAreasApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoAreasApiIsNameUniqueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoAreasApi
     */
    public isNameUnique(requestParameters: GeneralCargoAreasApiIsNameUniqueRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoAreasApiFp(this.configuration).isNameUnique(requestParameters.name, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoAreasApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoAreasApi
     */
    public update(requestParameters: GeneralCargoAreasApiUpdateRequest, options?: AxiosRequestConfig) {
        return GeneralCargoAreasApiFp(this.configuration).update(requestParameters.id, requestParameters.generalCargoAreasUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoAreasApiUpdateLocationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoAreasApi
     */
    public updateLocation(requestParameters: GeneralCargoAreasApiUpdateLocationRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoAreasApiFp(this.configuration).updateLocation(requestParameters.id, requestParameters.name, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GeneralCargoOrdersApi - axios parameter creator
 * @export
 */
export const GeneralCargoOrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByOrderId: async (orderId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoOrders/GetByOrderId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralCargoOrdersApi - functional programming interface
 * @export
 */
export const GeneralCargoOrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralCargoOrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByOrderId(orderId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralCargoOrderDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByOrderId(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeneralCargoOrdersApi - factory interface
 * @export
 */
export const GeneralCargoOrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralCargoOrdersApiFp(configuration)
    return {
        /**
         * 
         * @param {GeneralCargoOrdersApiGetByOrderIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByOrderId(requestParameters: GeneralCargoOrdersApiGetByOrderIdRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GeneralCargoOrderDto> {
            return localVarFp.getByOrderId(requestParameters.orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getByOrderId operation in GeneralCargoOrdersApi.
 * @export
 * @interface GeneralCargoOrdersApiGetByOrderIdRequest
 */
export interface GeneralCargoOrdersApiGetByOrderIdRequest {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoOrdersApiGetByOrderId
     */
    readonly orderId?: number
}

/**
 * GeneralCargoOrdersApi - object-oriented interface
 * @export
 * @class GeneralCargoOrdersApi
 * @extends {BaseAPI}
 */
export class GeneralCargoOrdersApi extends BaseAPI {
    /**
     * 
     * @param {GeneralCargoOrdersApiGetByOrderIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoOrdersApi
     */
    public getByOrderId(requestParameters: GeneralCargoOrdersApiGetByOrderIdRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoOrdersApiFp(this.configuration).getByOrderId(requestParameters.orderId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GeneralCargoStockOccupanciesApi - axios parameter creator
 * @export
 */
export const GeneralCargoStockOccupanciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDamageInfoByOrderId: async (orderId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoStockOccupancies/GetDamageInfoByOrderId/getDamageInfoByOrderId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['OrderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<number>} [orderIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDamagesByOrderIds: async (orderIds?: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoStockOccupancies/GetDamagesByOrderIds/getDamagesByOrderIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (orderIds) {
                localVarQueryParameter['OrderIds'] = orderIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [orderId] 
         * @param {number} [cargoItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboundOrderPlanning: async (orderId?: number, cargoItemId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoStockOccupancies/GetInboundOrderPlanning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (cargoItemId !== undefined) {
                localVarQueryParameter['cargoItemId'] = cargoItemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GeneralCargoStockOccupanciesPlanInboundOrderRequest} [generalCargoStockOccupanciesPlanInboundOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInboundOrder: async (generalCargoStockOccupanciesPlanInboundOrderRequest?: GeneralCargoStockOccupanciesPlanInboundOrderRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoStockOccupancies/PlanInboundOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalCargoStockOccupanciesPlanInboundOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GeneralCargoStockOccupanciesPutToStockRequest} [generalCargoStockOccupanciesPutToStockRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putToStock: async (generalCargoStockOccupanciesPutToStockRequest?: GeneralCargoStockOccupanciesPutToStockRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoStockOccupancies/PutToStock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalCargoStockOccupanciesPutToStockRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GeneralCargoStockOccupanciesPutToStockRequest} [generalCargoStockOccupanciesPutToStockRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeFromStock: async (generalCargoStockOccupanciesPutToStockRequest?: GeneralCargoStockOccupanciesPutToStockRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoStockOccupancies/TakeFromStock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generalCargoStockOccupanciesPutToStockRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDamageFile: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoStockOccupancies/UploadDamageFile/uploadDamageFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('File', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralCargoStockOccupanciesApi - functional programming interface
 * @export
 */
export const GeneralCargoStockOccupanciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralCargoStockOccupanciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDamageInfoByOrderId(orderId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralCargoStockOccupancyDamageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDamageInfoByOrderId(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<number>} [orderIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDamagesByOrderIds(orderIds?: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDamageQuantityDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDamagesByOrderIds(orderIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [orderId] 
         * @param {number} [cargoItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInboundOrderPlanning(orderId?: number, cargoItemId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralCargoItemPlanningDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInboundOrderPlanning(orderId, cargoItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GeneralCargoStockOccupanciesPlanInboundOrderRequest} [generalCargoStockOccupanciesPlanInboundOrderRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async planInboundOrder(generalCargoStockOccupanciesPlanInboundOrderRequest?: GeneralCargoStockOccupanciesPlanInboundOrderRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralCargoItemPlanningDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.planInboundOrder(generalCargoStockOccupanciesPlanInboundOrderRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GeneralCargoStockOccupanciesPutToStockRequest} [generalCargoStockOccupanciesPutToStockRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putToStock(generalCargoStockOccupanciesPutToStockRequest?: GeneralCargoStockOccupanciesPutToStockRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralCargoStockDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putToStock(generalCargoStockOccupanciesPutToStockRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GeneralCargoStockOccupanciesPutToStockRequest} [generalCargoStockOccupanciesPutToStockRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async takeFromStock(generalCargoStockOccupanciesPutToStockRequest?: GeneralCargoStockOccupanciesPutToStockRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralCargoStockDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.takeFromStock(generalCargoStockOccupanciesPutToStockRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDamageFile(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDamageFile(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeneralCargoStockOccupanciesApi - factory interface
 * @export
 */
export const GeneralCargoStockOccupanciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralCargoStockOccupanciesApiFp(configuration)
    return {
        /**
         * 
         * @param {GeneralCargoStockOccupanciesApiGetDamageInfoByOrderIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDamageInfoByOrderId(requestParameters: GeneralCargoStockOccupanciesApiGetDamageInfoByOrderIdRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<GeneralCargoStockOccupancyDamageDto>> {
            return localVarFp.getDamageInfoByOrderId(requestParameters.orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoStockOccupanciesApiGetDamagesByOrderIdsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDamagesByOrderIds(requestParameters: GeneralCargoStockOccupanciesApiGetDamagesByOrderIdsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<OrderDamageQuantityDto>> {
            return localVarFp.getDamagesByOrderIds(requestParameters.orderIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoStockOccupanciesApiGetInboundOrderPlanningRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInboundOrderPlanning(requestParameters: GeneralCargoStockOccupanciesApiGetInboundOrderPlanningRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<GeneralCargoItemPlanningDto>> {
            return localVarFp.getInboundOrderPlanning(requestParameters.orderId, requestParameters.cargoItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoStockOccupanciesApiPlanInboundOrderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        planInboundOrder(requestParameters: GeneralCargoStockOccupanciesApiPlanInboundOrderRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<GeneralCargoItemPlanningDto>> {
            return localVarFp.planInboundOrder(requestParameters.generalCargoStockOccupanciesPlanInboundOrderRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoStockOccupanciesApiPutToStockRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putToStock(requestParameters: GeneralCargoStockOccupanciesApiPutToStockRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GeneralCargoStockDto> {
            return localVarFp.putToStock(requestParameters.generalCargoStockOccupanciesPutToStockRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoStockOccupanciesApiTakeFromStockRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeFromStock(requestParameters: GeneralCargoStockOccupanciesApiTakeFromStockRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GeneralCargoStockDto> {
            return localVarFp.takeFromStock(requestParameters.generalCargoStockOccupanciesPutToStockRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoStockOccupanciesApiUploadDamageFileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDamageFile(requestParameters: GeneralCargoStockOccupanciesApiUploadDamageFileRequest = {}, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.uploadDamageFile(requestParameters.file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getDamageInfoByOrderId operation in GeneralCargoStockOccupanciesApi.
 * @export
 * @interface GeneralCargoStockOccupanciesApiGetDamageInfoByOrderIdRequest
 */
export interface GeneralCargoStockOccupanciesApiGetDamageInfoByOrderIdRequest {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockOccupanciesApiGetDamageInfoByOrderId
     */
    readonly orderId?: number
}

/**
 * Request parameters for getDamagesByOrderIds operation in GeneralCargoStockOccupanciesApi.
 * @export
 * @interface GeneralCargoStockOccupanciesApiGetDamagesByOrderIdsRequest
 */
export interface GeneralCargoStockOccupanciesApiGetDamagesByOrderIdsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof GeneralCargoStockOccupanciesApiGetDamagesByOrderIds
     */
    readonly orderIds?: Array<number>
}

/**
 * Request parameters for getInboundOrderPlanning operation in GeneralCargoStockOccupanciesApi.
 * @export
 * @interface GeneralCargoStockOccupanciesApiGetInboundOrderPlanningRequest
 */
export interface GeneralCargoStockOccupanciesApiGetInboundOrderPlanningRequest {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockOccupanciesApiGetInboundOrderPlanning
     */
    readonly orderId?: number

    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStockOccupanciesApiGetInboundOrderPlanning
     */
    readonly cargoItemId?: number
}

/**
 * Request parameters for planInboundOrder operation in GeneralCargoStockOccupanciesApi.
 * @export
 * @interface GeneralCargoStockOccupanciesApiPlanInboundOrderRequest
 */
export interface GeneralCargoStockOccupanciesApiPlanInboundOrderRequest {
    /**
     * 
     * @type {GeneralCargoStockOccupanciesPlanInboundOrderRequest}
     * @memberof GeneralCargoStockOccupanciesApiPlanInboundOrder
     */
    readonly generalCargoStockOccupanciesPlanInboundOrderRequest?: GeneralCargoStockOccupanciesPlanInboundOrderRequest
}

/**
 * Request parameters for putToStock operation in GeneralCargoStockOccupanciesApi.
 * @export
 * @interface GeneralCargoStockOccupanciesApiPutToStockRequest
 */
export interface GeneralCargoStockOccupanciesApiPutToStockRequest {
    /**
     * 
     * @type {GeneralCargoStockOccupanciesPutToStockRequest}
     * @memberof GeneralCargoStockOccupanciesApiPutToStock
     */
    readonly generalCargoStockOccupanciesPutToStockRequest?: GeneralCargoStockOccupanciesPutToStockRequest
}

/**
 * Request parameters for takeFromStock operation in GeneralCargoStockOccupanciesApi.
 * @export
 * @interface GeneralCargoStockOccupanciesApiTakeFromStockRequest
 */
export interface GeneralCargoStockOccupanciesApiTakeFromStockRequest {
    /**
     * 
     * @type {GeneralCargoStockOccupanciesPutToStockRequest}
     * @memberof GeneralCargoStockOccupanciesApiTakeFromStock
     */
    readonly generalCargoStockOccupanciesPutToStockRequest?: GeneralCargoStockOccupanciesPutToStockRequest
}

/**
 * Request parameters for uploadDamageFile operation in GeneralCargoStockOccupanciesApi.
 * @export
 * @interface GeneralCargoStockOccupanciesApiUploadDamageFileRequest
 */
export interface GeneralCargoStockOccupanciesApiUploadDamageFileRequest {
    /**
     * 
     * @type {File}
     * @memberof GeneralCargoStockOccupanciesApiUploadDamageFile
     */
    readonly file?: File
}

/**
 * GeneralCargoStockOccupanciesApi - object-oriented interface
 * @export
 * @class GeneralCargoStockOccupanciesApi
 * @extends {BaseAPI}
 */
export class GeneralCargoStockOccupanciesApi extends BaseAPI {
    /**
     * 
     * @param {GeneralCargoStockOccupanciesApiGetDamageInfoByOrderIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoStockOccupanciesApi
     */
    public getDamageInfoByOrderId(requestParameters: GeneralCargoStockOccupanciesApiGetDamageInfoByOrderIdRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoStockOccupanciesApiFp(this.configuration).getDamageInfoByOrderId(requestParameters.orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoStockOccupanciesApiGetDamagesByOrderIdsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoStockOccupanciesApi
     */
    public getDamagesByOrderIds(requestParameters: GeneralCargoStockOccupanciesApiGetDamagesByOrderIdsRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoStockOccupanciesApiFp(this.configuration).getDamagesByOrderIds(requestParameters.orderIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoStockOccupanciesApiGetInboundOrderPlanningRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoStockOccupanciesApi
     */
    public getInboundOrderPlanning(requestParameters: GeneralCargoStockOccupanciesApiGetInboundOrderPlanningRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoStockOccupanciesApiFp(this.configuration).getInboundOrderPlanning(requestParameters.orderId, requestParameters.cargoItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoStockOccupanciesApiPlanInboundOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoStockOccupanciesApi
     */
    public planInboundOrder(requestParameters: GeneralCargoStockOccupanciesApiPlanInboundOrderRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoStockOccupanciesApiFp(this.configuration).planInboundOrder(requestParameters.generalCargoStockOccupanciesPlanInboundOrderRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoStockOccupanciesApiPutToStockRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoStockOccupanciesApi
     */
    public putToStock(requestParameters: GeneralCargoStockOccupanciesApiPutToStockRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoStockOccupanciesApiFp(this.configuration).putToStock(requestParameters.generalCargoStockOccupanciesPutToStockRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoStockOccupanciesApiTakeFromStockRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoStockOccupanciesApi
     */
    public takeFromStock(requestParameters: GeneralCargoStockOccupanciesApiTakeFromStockRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoStockOccupanciesApiFp(this.configuration).takeFromStock(requestParameters.generalCargoStockOccupanciesPutToStockRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoStockOccupanciesApiUploadDamageFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoStockOccupanciesApi
     */
    public uploadDamageFile(requestParameters: GeneralCargoStockOccupanciesApiUploadDamageFileRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoStockOccupanciesApiFp(this.configuration).uploadDamageFile(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GeneralCargoStocksApi - axios parameter creator
 * @export
 */
export const GeneralCargoStocksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [commodityId] 
         * @param {string} [lotNumber] 
         * @param {number} [packageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (customerId?: number, commodityId?: number, lotNumber?: string, packageId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoStocks/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (customerId !== undefined) {
                localVarQueryParameter['customerId'] = customerId;
            }

            if (commodityId !== undefined) {
                localVarQueryParameter['commodityId'] = commodityId;
            }

            if (lotNumber !== undefined) {
                localVarQueryParameter['lotNumber'] = lotNumber;
            }

            if (packageId !== undefined) {
                localVarQueryParameter['packageId'] = packageId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoStocks/Get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoStocks/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [orderId] 
         * @param {number} [cargoItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByOrderId: async (orderId?: number, cargoItemId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoStocks/GetByOrderId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }

            if (cargoItemId !== undefined) {
                localVarQueryParameter['cargoItemId'] = cargoItemId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [commodityId] 
         * @param {number} [customerId] 
         * @param {string} [lotNumber] 
         * @param {number} [packageId] 
         * @param {boolean} [withOccupancyOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByQueryParams: async (commodityId?: number, customerId?: number, lotNumber?: string, packageId?: number, withOccupancyOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoStocks/GetByQueryParams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (commodityId !== undefined) {
                localVarQueryParameter['CommodityId'] = commodityId;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }

            if (lotNumber !== undefined) {
                localVarQueryParameter['LotNumber'] = lotNumber;
            }

            if (packageId !== undefined) {
                localVarQueryParameter['PackageId'] = packageId;
            }

            if (withOccupancyOnly !== undefined) {
                localVarQueryParameter['WithOccupancyOnly'] = withOccupancyOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [commodityId] 
         * @param {number} [customerId] 
         * @param {string} [lotNumber] 
         * @param {number} [packageId] 
         * @param {boolean} [withOccupancyOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInventory: async (commodityId?: number, customerId?: number, lotNumber?: string, packageId?: number, withOccupancyOnly?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoStocks/GetInventory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (commodityId !== undefined) {
                localVarQueryParameter['CommodityId'] = commodityId;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }

            if (lotNumber !== undefined) {
                localVarQueryParameter['LotNumber'] = lotNumber;
            }

            if (packageId !== undefined) {
                localVarQueryParameter['PackageId'] = packageId;
            }

            if (withOccupancyOnly !== undefined) {
                localVarQueryParameter['WithOccupancyOnly'] = withOccupancyOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [orderId] 
         * @param {number} [commodityId] 
         * @param {number} [customerId] 
         * @param {string} [lotNumber] 
         * @param {number} [packageId] 
         * @param {number} [quantity] 
         * @param {Array<string>} [unitIds] 
         * @param {Array<string>} [imoClasses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateOutboundOrder: async (orderId?: number, commodityId?: number, customerId?: number, lotNumber?: string, packageId?: number, quantity?: number, unitIds?: Array<string>, imoClasses?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GeneralCargoStocks/ValidateOutboundOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (orderId !== undefined) {
                localVarQueryParameter['OrderId'] = orderId;
            }

            if (commodityId !== undefined) {
                localVarQueryParameter['CommodityId'] = commodityId;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }

            if (lotNumber !== undefined) {
                localVarQueryParameter['LotNumber'] = lotNumber;
            }

            if (packageId !== undefined) {
                localVarQueryParameter['PackageId'] = packageId;
            }

            if (quantity !== undefined) {
                localVarQueryParameter['Quantity'] = quantity;
            }

            if (unitIds) {
                localVarQueryParameter['UnitIds'] = unitIds;
            }

            if (imoClasses) {
                localVarQueryParameter['ImoClasses'] = imoClasses;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralCargoStocksApi - functional programming interface
 * @export
 */
export const GeneralCargoStocksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralCargoStocksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [customerId] 
         * @param {number} [commodityId] 
         * @param {string} [lotNumber] 
         * @param {number} [packageId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(customerId?: number, commodityId?: number, lotNumber?: string, packageId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralCargoStockDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(customerId, commodityId, lotNumber, packageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralCargoStockDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralCargoStockDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [orderId] 
         * @param {number} [cargoItemId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByOrderId(orderId?: number, cargoItemId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralCargoStockDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByOrderId(orderId, cargoItemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [commodityId] 
         * @param {number} [customerId] 
         * @param {string} [lotNumber] 
         * @param {number} [packageId] 
         * @param {boolean} [withOccupancyOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getByQueryParams(commodityId?: number, customerId?: number, lotNumber?: string, packageId?: number, withOccupancyOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralCargoStockDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getByQueryParams(commodityId, customerId, lotNumber, packageId, withOccupancyOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [commodityId] 
         * @param {number} [customerId] 
         * @param {string} [lotNumber] 
         * @param {number} [packageId] 
         * @param {boolean} [withOccupancyOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInventory(commodityId?: number, customerId?: number, lotNumber?: string, packageId?: number, withOccupancyOnly?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GeneralCargoInventoryDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInventory(commodityId, customerId, lotNumber, packageId, withOccupancyOnly, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [orderId] 
         * @param {number} [commodityId] 
         * @param {number} [customerId] 
         * @param {string} [lotNumber] 
         * @param {number} [packageId] 
         * @param {number} [quantity] 
         * @param {Array<string>} [unitIds] 
         * @param {Array<string>} [imoClasses] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateOutboundOrder(orderId?: number, commodityId?: number, customerId?: number, lotNumber?: string, packageId?: number, quantity?: number, unitIds?: Array<string>, imoClasses?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralCargoOutboundValidationResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateOutboundOrder(orderId, commodityId, customerId, lotNumber, packageId, quantity, unitIds, imoClasses, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GeneralCargoStocksApi - factory interface
 * @export
 */
export const GeneralCargoStocksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralCargoStocksApiFp(configuration)
    return {
        /**
         * 
         * @param {GeneralCargoStocksApiCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestParameters: GeneralCargoStocksApiCreateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GeneralCargoStockDto> {
            return localVarFp.create(requestParameters.customerId, requestParameters.commodityId, requestParameters.lotNumber, requestParameters.packageId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoStocksApiGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(requestParameters: GeneralCargoStocksApiGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GeneralCargoStockDto> {
            return localVarFp.get(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: AxiosRequestConfig): AxiosPromise<Array<GeneralCargoStockDto>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoStocksApiGetByOrderIdRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByOrderId(requestParameters: GeneralCargoStocksApiGetByOrderIdRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GeneralCargoStockDto> {
            return localVarFp.getByOrderId(requestParameters.orderId, requestParameters.cargoItemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoStocksApiGetByQueryParamsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getByQueryParams(requestParameters: GeneralCargoStocksApiGetByQueryParamsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<GeneralCargoStockDto>> {
            return localVarFp.getByQueryParams(requestParameters.commodityId, requestParameters.customerId, requestParameters.lotNumber, requestParameters.packageId, requestParameters.withOccupancyOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoStocksApiGetInventoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInventory(requestParameters: GeneralCargoStocksApiGetInventoryRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<GeneralCargoInventoryDto>> {
            return localVarFp.getInventory(requestParameters.commodityId, requestParameters.customerId, requestParameters.lotNumber, requestParameters.packageId, requestParameters.withOccupancyOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeneralCargoStocksApiValidateOutboundOrderRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateOutboundOrder(requestParameters: GeneralCargoStocksApiValidateOutboundOrderRequest = {}, options?: AxiosRequestConfig): AxiosPromise<GeneralCargoOutboundValidationResultDto> {
            return localVarFp.validateOutboundOrder(requestParameters.orderId, requestParameters.commodityId, requestParameters.customerId, requestParameters.lotNumber, requestParameters.packageId, requestParameters.quantity, requestParameters.unitIds, requestParameters.imoClasses, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for create operation in GeneralCargoStocksApi.
 * @export
 * @interface GeneralCargoStocksApiCreateRequest
 */
export interface GeneralCargoStocksApiCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStocksApiCreate
     */
    readonly customerId?: number

    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStocksApiCreate
     */
    readonly commodityId?: number

    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStocksApiCreate
     */
    readonly lotNumber?: string

    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStocksApiCreate
     */
    readonly packageId?: number
}

/**
 * Request parameters for get operation in GeneralCargoStocksApi.
 * @export
 * @interface GeneralCargoStocksApiGetRequest
 */
export interface GeneralCargoStocksApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStocksApiGet
     */
    readonly id?: string
}

/**
 * Request parameters for getByOrderId operation in GeneralCargoStocksApi.
 * @export
 * @interface GeneralCargoStocksApiGetByOrderIdRequest
 */
export interface GeneralCargoStocksApiGetByOrderIdRequest {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStocksApiGetByOrderId
     */
    readonly orderId?: number

    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStocksApiGetByOrderId
     */
    readonly cargoItemId?: number
}

/**
 * Request parameters for getByQueryParams operation in GeneralCargoStocksApi.
 * @export
 * @interface GeneralCargoStocksApiGetByQueryParamsRequest
 */
export interface GeneralCargoStocksApiGetByQueryParamsRequest {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStocksApiGetByQueryParams
     */
    readonly commodityId?: number

    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStocksApiGetByQueryParams
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStocksApiGetByQueryParams
     */
    readonly lotNumber?: string

    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStocksApiGetByQueryParams
     */
    readonly packageId?: number

    /**
     * 
     * @type {boolean}
     * @memberof GeneralCargoStocksApiGetByQueryParams
     */
    readonly withOccupancyOnly?: boolean
}

/**
 * Request parameters for getInventory operation in GeneralCargoStocksApi.
 * @export
 * @interface GeneralCargoStocksApiGetInventoryRequest
 */
export interface GeneralCargoStocksApiGetInventoryRequest {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStocksApiGetInventory
     */
    readonly commodityId?: number

    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStocksApiGetInventory
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStocksApiGetInventory
     */
    readonly lotNumber?: string

    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStocksApiGetInventory
     */
    readonly packageId?: number

    /**
     * 
     * @type {boolean}
     * @memberof GeneralCargoStocksApiGetInventory
     */
    readonly withOccupancyOnly?: boolean
}

/**
 * Request parameters for validateOutboundOrder operation in GeneralCargoStocksApi.
 * @export
 * @interface GeneralCargoStocksApiValidateOutboundOrderRequest
 */
export interface GeneralCargoStocksApiValidateOutboundOrderRequest {
    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStocksApiValidateOutboundOrder
     */
    readonly orderId?: number

    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStocksApiValidateOutboundOrder
     */
    readonly commodityId?: number

    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStocksApiValidateOutboundOrder
     */
    readonly customerId?: number

    /**
     * 
     * @type {string}
     * @memberof GeneralCargoStocksApiValidateOutboundOrder
     */
    readonly lotNumber?: string

    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStocksApiValidateOutboundOrder
     */
    readonly packageId?: number

    /**
     * 
     * @type {number}
     * @memberof GeneralCargoStocksApiValidateOutboundOrder
     */
    readonly quantity?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralCargoStocksApiValidateOutboundOrder
     */
    readonly unitIds?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof GeneralCargoStocksApiValidateOutboundOrder
     */
    readonly imoClasses?: Array<string>
}

/**
 * GeneralCargoStocksApi - object-oriented interface
 * @export
 * @class GeneralCargoStocksApi
 * @extends {BaseAPI}
 */
export class GeneralCargoStocksApi extends BaseAPI {
    /**
     * 
     * @param {GeneralCargoStocksApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoStocksApi
     */
    public create(requestParameters: GeneralCargoStocksApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoStocksApiFp(this.configuration).create(requestParameters.customerId, requestParameters.commodityId, requestParameters.lotNumber, requestParameters.packageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoStocksApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoStocksApi
     */
    public get(requestParameters: GeneralCargoStocksApiGetRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoStocksApiFp(this.configuration).get(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoStocksApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return GeneralCargoStocksApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoStocksApiGetByOrderIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoStocksApi
     */
    public getByOrderId(requestParameters: GeneralCargoStocksApiGetByOrderIdRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoStocksApiFp(this.configuration).getByOrderId(requestParameters.orderId, requestParameters.cargoItemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoStocksApiGetByQueryParamsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoStocksApi
     */
    public getByQueryParams(requestParameters: GeneralCargoStocksApiGetByQueryParamsRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoStocksApiFp(this.configuration).getByQueryParams(requestParameters.commodityId, requestParameters.customerId, requestParameters.lotNumber, requestParameters.packageId, requestParameters.withOccupancyOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoStocksApiGetInventoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoStocksApi
     */
    public getInventory(requestParameters: GeneralCargoStocksApiGetInventoryRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoStocksApiFp(this.configuration).getInventory(requestParameters.commodityId, requestParameters.customerId, requestParameters.lotNumber, requestParameters.packageId, requestParameters.withOccupancyOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeneralCargoStocksApiValidateOutboundOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralCargoStocksApi
     */
    public validateOutboundOrder(requestParameters: GeneralCargoStocksApiValidateOutboundOrderRequest = {}, options?: AxiosRequestConfig) {
        return GeneralCargoStocksApiFp(this.configuration).validateOutboundOrder(requestParameters.orderId, requestParameters.commodityId, requestParameters.customerId, requestParameters.lotNumber, requestParameters.packageId, requestParameters.quantity, requestParameters.unitIds, requestParameters.imoClasses, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GlobalAllocationRulesApi - axios parameter creator
 * @export
 */
export const GlobalAllocationRulesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GlobalAllocationRulesCreateGlobalRuleAndAllocationRequest} [globalAllocationRulesCreateGlobalRuleAndAllocationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGlobalRuleAndAllocation: async (globalAllocationRulesCreateGlobalRuleAndAllocationRequest?: GlobalAllocationRulesCreateGlobalRuleAndAllocationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GlobalAllocationRules/CreateGlobalRuleAndAllocation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(globalAllocationRulesCreateGlobalRuleAndAllocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CarrierVisitDirection} [direction] 
         * @param {CarrierType} [carrierType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (direction?: CarrierVisitDirection, carrierType?: CarrierType, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/GlobalAllocationRules/Get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (direction !== undefined) {
                localVarQueryParameter['direction'] = direction;
            }

            if (carrierType !== undefined) {
                localVarQueryParameter['carrierType'] = carrierType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalAllocationRulesApi - functional programming interface
 * @export
 */
export const GlobalAllocationRulesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GlobalAllocationRulesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GlobalAllocationRulesCreateGlobalRuleAndAllocationRequest} [globalAllocationRulesCreateGlobalRuleAndAllocationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGlobalRuleAndAllocation(globalAllocationRulesCreateGlobalRuleAndAllocationRequest?: GlobalAllocationRulesCreateGlobalRuleAndAllocationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGlobalRuleAndAllocation(globalAllocationRulesCreateGlobalRuleAndAllocationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CarrierVisitDirection} [direction] 
         * @param {CarrierType} [carrierType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(direction?: CarrierVisitDirection, carrierType?: CarrierType, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GlobalAllocationRuleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(direction, carrierType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GlobalAllocationRulesApi - factory interface
 * @export
 */
export const GlobalAllocationRulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GlobalAllocationRulesApiFp(configuration)
    return {
        /**
         * 
         * @param {GlobalAllocationRulesApiCreateGlobalRuleAndAllocationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGlobalRuleAndAllocation(requestParameters: GlobalAllocationRulesApiCreateGlobalRuleAndAllocationRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createGlobalRuleAndAllocation(requestParameters.globalAllocationRulesCreateGlobalRuleAndAllocationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GlobalAllocationRulesApiGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(requestParameters: GlobalAllocationRulesApiGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<GlobalAllocationRuleDto>> {
            return localVarFp.get(requestParameters.direction, requestParameters.carrierType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createGlobalRuleAndAllocation operation in GlobalAllocationRulesApi.
 * @export
 * @interface GlobalAllocationRulesApiCreateGlobalRuleAndAllocationRequest
 */
export interface GlobalAllocationRulesApiCreateGlobalRuleAndAllocationRequest {
    /**
     * 
     * @type {GlobalAllocationRulesCreateGlobalRuleAndAllocationRequest}
     * @memberof GlobalAllocationRulesApiCreateGlobalRuleAndAllocation
     */
    readonly globalAllocationRulesCreateGlobalRuleAndAllocationRequest?: GlobalAllocationRulesCreateGlobalRuleAndAllocationRequest
}

/**
 * Request parameters for get operation in GlobalAllocationRulesApi.
 * @export
 * @interface GlobalAllocationRulesApiGetRequest
 */
export interface GlobalAllocationRulesApiGetRequest {
    /**
     * 
     * @type {CarrierVisitDirection}
     * @memberof GlobalAllocationRulesApiGet
     */
    readonly direction?: CarrierVisitDirection

    /**
     * 
     * @type {CarrierType}
     * @memberof GlobalAllocationRulesApiGet
     */
    readonly carrierType?: CarrierType
}

/**
 * GlobalAllocationRulesApi - object-oriented interface
 * @export
 * @class GlobalAllocationRulesApi
 * @extends {BaseAPI}
 */
export class GlobalAllocationRulesApi extends BaseAPI {
    /**
     * 
     * @param {GlobalAllocationRulesApiCreateGlobalRuleAndAllocationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAllocationRulesApi
     */
    public createGlobalRuleAndAllocation(requestParameters: GlobalAllocationRulesApiCreateGlobalRuleAndAllocationRequest = {}, options?: AxiosRequestConfig) {
        return GlobalAllocationRulesApiFp(this.configuration).createGlobalRuleAndAllocation(requestParameters.globalAllocationRulesCreateGlobalRuleAndAllocationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GlobalAllocationRulesApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalAllocationRulesApi
     */
    public get(requestParameters: GlobalAllocationRulesApiGetRequest = {}, options?: AxiosRequestConfig) {
        return GlobalAllocationRulesApiFp(this.configuration).get(requestParameters.direction, requestParameters.carrierType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalApiApi - axios parameter creator
 * @export
 */
export const InternalApiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportJsonSnapshot: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/InternalApi/ExportJsonSnapshot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/InternalApi/ResetData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetDataFromJsonSnapshot: async (file?: File, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/InternalApi/ResetDataFromJsonSnapshot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetDataFromTenant: async (body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/InternalApi/ResetDataFromTenant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDemoData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/InternalApi/SaveDemoData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalApiApi - functional programming interface
 * @export
 */
export const InternalApiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalApiApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportJsonSnapshot(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportJsonSnapshot(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {File} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetDataFromJsonSnapshot(file?: File, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetDataFromJsonSnapshot(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetDataFromTenant(body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetDataFromTenant(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveDemoData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveDemoData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalApiApi - factory interface
 * @export
 */
export const InternalApiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalApiApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportJsonSnapshot(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.exportJsonSnapshot(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetData(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.resetData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InternalApiApiResetDataFromJsonSnapshotRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetDataFromJsonSnapshot(requestParameters: InternalApiApiResetDataFromJsonSnapshotRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.resetDataFromJsonSnapshot(requestParameters.file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InternalApiApiResetDataFromTenantRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetDataFromTenant(requestParameters: InternalApiApiResetDataFromTenantRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.resetDataFromTenant(requestParameters.body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveDemoData(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.saveDemoData(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for resetDataFromJsonSnapshot operation in InternalApiApi.
 * @export
 * @interface InternalApiApiResetDataFromJsonSnapshotRequest
 */
export interface InternalApiApiResetDataFromJsonSnapshotRequest {
    /**
     * 
     * @type {File}
     * @memberof InternalApiApiResetDataFromJsonSnapshot
     */
    readonly file?: File
}

/**
 * Request parameters for resetDataFromTenant operation in InternalApiApi.
 * @export
 * @interface InternalApiApiResetDataFromTenantRequest
 */
export interface InternalApiApiResetDataFromTenantRequest {
    /**
     * 
     * @type {number}
     * @memberof InternalApiApiResetDataFromTenant
     */
    readonly body?: number
}

/**
 * InternalApiApi - object-oriented interface
 * @export
 * @class InternalApiApi
 * @extends {BaseAPI}
 */
export class InternalApiApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApiApi
     */
    public exportJsonSnapshot(options?: AxiosRequestConfig) {
        return InternalApiApiFp(this.configuration).exportJsonSnapshot(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApiApi
     */
    public resetData(options?: AxiosRequestConfig) {
        return InternalApiApiFp(this.configuration).resetData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternalApiApiResetDataFromJsonSnapshotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApiApi
     */
    public resetDataFromJsonSnapshot(requestParameters: InternalApiApiResetDataFromJsonSnapshotRequest = {}, options?: AxiosRequestConfig) {
        return InternalApiApiFp(this.configuration).resetDataFromJsonSnapshot(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InternalApiApiResetDataFromTenantRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApiApi
     */
    public resetDataFromTenant(requestParameters: InternalApiApiResetDataFromTenantRequest = {}, options?: AxiosRequestConfig) {
        return InternalApiApiFp(this.configuration).resetDataFromTenant(requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApiApi
     */
    public saveDemoData(options?: AxiosRequestConfig) {
        return InternalApiApiFp(this.configuration).saveDemoData(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PiersApi - axios parameter creator
 * @export
 */
export const PiersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Piers/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PiersCreateRequest} [piersCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (piersCreateRequest?: PiersCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Piers/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(piersCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Piers/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUnique: async (name?: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Piers/IsUnique`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PiersUpdateRequest} [piersUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (piersUpdateRequest?: PiersUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/Piers/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(piersUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PiersApi - functional programming interface
 * @export
 */
export const PiersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PiersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PiersCreateRequest} [piersCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(piersCreateRequest?: PiersCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PierDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(piersCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PierDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isUnique(name?: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isUnique(name, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PiersUpdateRequest} [piersUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(piersUpdateRequest?: PiersUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PierDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(piersUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PiersApi - factory interface
 * @export
 */
export const PiersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PiersApiFp(configuration)
    return {
        /**
         * 
         * @param {PiersApiDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(requestParameters: PiersApiDeleteRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp._delete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PiersApiCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestParameters: PiersApiCreateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PierDto> {
            return localVarFp.create(requestParameters.piersCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: AxiosRequestConfig): AxiosPromise<Array<PierDto>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PiersApiIsUniqueRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUnique(requestParameters: PiersApiIsUniqueRequest = {}, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.isUnique(requestParameters.name, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PiersApiUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(requestParameters: PiersApiUpdateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<PierDto> {
            return localVarFp.update(requestParameters.piersUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in PiersApi.
 * @export
 * @interface PiersApiDeleteRequest
 */
export interface PiersApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof PiersApiDelete
     */
    readonly id?: string
}

/**
 * Request parameters for create operation in PiersApi.
 * @export
 * @interface PiersApiCreateRequest
 */
export interface PiersApiCreateRequest {
    /**
     * 
     * @type {PiersCreateRequest}
     * @memberof PiersApiCreate
     */
    readonly piersCreateRequest?: PiersCreateRequest
}

/**
 * Request parameters for isUnique operation in PiersApi.
 * @export
 * @interface PiersApiIsUniqueRequest
 */
export interface PiersApiIsUniqueRequest {
    /**
     * 
     * @type {string}
     * @memberof PiersApiIsUnique
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof PiersApiIsUnique
     */
    readonly id?: string
}

/**
 * Request parameters for update operation in PiersApi.
 * @export
 * @interface PiersApiUpdateRequest
 */
export interface PiersApiUpdateRequest {
    /**
     * 
     * @type {PiersUpdateRequest}
     * @memberof PiersApiUpdate
     */
    readonly piersUpdateRequest?: PiersUpdateRequest
}

/**
 * PiersApi - object-oriented interface
 * @export
 * @class PiersApi
 * @extends {BaseAPI}
 */
export class PiersApi extends BaseAPI {
    /**
     * 
     * @param {PiersApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PiersApi
     */
    public _delete(requestParameters: PiersApiDeleteRequest = {}, options?: AxiosRequestConfig) {
        return PiersApiFp(this.configuration)._delete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PiersApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PiersApi
     */
    public create(requestParameters: PiersApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return PiersApiFp(this.configuration).create(requestParameters.piersCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PiersApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return PiersApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PiersApiIsUniqueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PiersApi
     */
    public isUnique(requestParameters: PiersApiIsUniqueRequest = {}, options?: AxiosRequestConfig) {
        return PiersApiFp(this.configuration).isUnique(requestParameters.name, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PiersApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PiersApi
     */
    public update(requestParameters: PiersApiUpdateRequest = {}, options?: AxiosRequestConfig) {
        return PiersApiFp(this.configuration).update(requestParameters.piersUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RailTracksApi - axios parameter creator
 * @export
 */
export const RailTracksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/RailTracks/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RailTracksCreateRequest} [railTracksCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (railTracksCreateRequest?: RailTracksCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/RailTracks/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(railTracksCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/RailTracks/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUnique: async (name?: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/RailTracks/IsUnique`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RailTracksUpdateRequest} [railTracksUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (railTracksUpdateRequest?: RailTracksUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/RailTracks/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(railTracksUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RailTracksApi - functional programming interface
 * @export
 */
export const RailTracksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RailTracksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RailTracksCreateRequest} [railTracksCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(railTracksCreateRequest?: RailTracksCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RailTrackDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(railTracksCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RailTrackDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isUnique(name?: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isUnique(name, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RailTracksUpdateRequest} [railTracksUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(railTracksUpdateRequest?: RailTracksUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RailTrackDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(railTracksUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RailTracksApi - factory interface
 * @export
 */
export const RailTracksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RailTracksApiFp(configuration)
    return {
        /**
         * 
         * @param {RailTracksApiDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(requestParameters: RailTracksApiDeleteRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp._delete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RailTracksApiCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestParameters: RailTracksApiCreateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<RailTrackDto> {
            return localVarFp.create(requestParameters.railTracksCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: AxiosRequestConfig): AxiosPromise<Array<RailTrackDto>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RailTracksApiIsUniqueRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUnique(requestParameters: RailTracksApiIsUniqueRequest = {}, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.isUnique(requestParameters.name, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RailTracksApiUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(requestParameters: RailTracksApiUpdateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<RailTrackDto> {
            return localVarFp.update(requestParameters.railTracksUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in RailTracksApi.
 * @export
 * @interface RailTracksApiDeleteRequest
 */
export interface RailTracksApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof RailTracksApiDelete
     */
    readonly id?: string
}

/**
 * Request parameters for create operation in RailTracksApi.
 * @export
 * @interface RailTracksApiCreateRequest
 */
export interface RailTracksApiCreateRequest {
    /**
     * 
     * @type {RailTracksCreateRequest}
     * @memberof RailTracksApiCreate
     */
    readonly railTracksCreateRequest?: RailTracksCreateRequest
}

/**
 * Request parameters for isUnique operation in RailTracksApi.
 * @export
 * @interface RailTracksApiIsUniqueRequest
 */
export interface RailTracksApiIsUniqueRequest {
    /**
     * 
     * @type {string}
     * @memberof RailTracksApiIsUnique
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof RailTracksApiIsUnique
     */
    readonly id?: string
}

/**
 * Request parameters for update operation in RailTracksApi.
 * @export
 * @interface RailTracksApiUpdateRequest
 */
export interface RailTracksApiUpdateRequest {
    /**
     * 
     * @type {RailTracksUpdateRequest}
     * @memberof RailTracksApiUpdate
     */
    readonly railTracksUpdateRequest?: RailTracksUpdateRequest
}

/**
 * RailTracksApi - object-oriented interface
 * @export
 * @class RailTracksApi
 * @extends {BaseAPI}
 */
export class RailTracksApi extends BaseAPI {
    /**
     * 
     * @param {RailTracksApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RailTracksApi
     */
    public _delete(requestParameters: RailTracksApiDeleteRequest = {}, options?: AxiosRequestConfig) {
        return RailTracksApiFp(this.configuration)._delete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RailTracksApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RailTracksApi
     */
    public create(requestParameters: RailTracksApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return RailTracksApiFp(this.configuration).create(requestParameters.railTracksCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RailTracksApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return RailTracksApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RailTracksApiIsUniqueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RailTracksApi
     */
    public isUnique(requestParameters: RailTracksApiIsUniqueRequest = {}, options?: AxiosRequestConfig) {
        return RailTracksApiFp(this.configuration).isUnique(requestParameters.name, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RailTracksApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RailTracksApi
     */
    public update(requestParameters: RailTracksApiUpdateRequest = {}, options?: AxiosRequestConfig) {
        return RailTracksApiFp(this.configuration).update(requestParameters.railTracksUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SyncDataConsumerApi - axios parameter creator
 * @export
 */
export const SyncDataConsumerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCommodities: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/SyncDataConsumer/SyncCommodities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncContainers: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/SyncDataConsumer/SyncContainers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCustomers: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/SyncDataConsumer/SyncCustomers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrders: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/SyncDataConsumer/SyncOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPackages: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/SyncDataConsumer/SyncPackages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncRailVisits: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/SyncDataConsumer/SyncRailVisits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncStrippingOrders: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/SyncDataConsumer/SyncStrippingOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncStuffingOrders: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/SyncDataConsumer/SyncStuffingOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTenants: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/SyncDataConsumer/SyncTenants`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTruckVisits: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/SyncDataConsumer/SyncTruckVisits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncVesselVisits: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/SyncDataConsumer/SyncVesselVisits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SyncDataConsumerApi - functional programming interface
 * @export
 */
export const SyncDataConsumerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SyncDataConsumerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncCommodities(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncCommodities(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncContainers(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncContainers(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncCustomers(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncCustomers(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncOrders(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOrders(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncPackages(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncPackages(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncRailVisits(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncRailVisits(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncStrippingOrders(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncStrippingOrders(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncStuffingOrders(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncStuffingOrders(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncTenants(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncTenants(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncTruckVisits(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncTruckVisits(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncVesselVisits(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncVesselVisits(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SyncDataConsumerApi - factory interface
 * @export
 */
export const SyncDataConsumerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SyncDataConsumerApiFp(configuration)
    return {
        /**
         * 
         * @param {SyncDataConsumerApiSyncCommoditiesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCommodities(requestParameters: SyncDataConsumerApiSyncCommoditiesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncCommodities(requestParameters.from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncDataConsumerApiSyncContainersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncContainers(requestParameters: SyncDataConsumerApiSyncContainersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncContainers(requestParameters.from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncDataConsumerApiSyncCustomersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCustomers(requestParameters: SyncDataConsumerApiSyncCustomersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncCustomers(requestParameters.from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncDataConsumerApiSyncOrdersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrders(requestParameters: SyncDataConsumerApiSyncOrdersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncOrders(requestParameters.from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncDataConsumerApiSyncPackagesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPackages(requestParameters: SyncDataConsumerApiSyncPackagesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncPackages(requestParameters.from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncDataConsumerApiSyncRailVisitsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncRailVisits(requestParameters: SyncDataConsumerApiSyncRailVisitsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncRailVisits(requestParameters.from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncDataConsumerApiSyncStrippingOrdersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncStrippingOrders(requestParameters: SyncDataConsumerApiSyncStrippingOrdersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncStrippingOrders(requestParameters.from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncDataConsumerApiSyncStuffingOrdersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncStuffingOrders(requestParameters: SyncDataConsumerApiSyncStuffingOrdersRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncStuffingOrders(requestParameters.from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncDataConsumerApiSyncTenantsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTenants(requestParameters: SyncDataConsumerApiSyncTenantsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncTenants(requestParameters.from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncDataConsumerApiSyncTruckVisitsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncTruckVisits(requestParameters: SyncDataConsumerApiSyncTruckVisitsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncTruckVisits(requestParameters.from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncDataConsumerApiSyncVesselVisitsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncVesselVisits(requestParameters: SyncDataConsumerApiSyncVesselVisitsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncVesselVisits(requestParameters.from, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for syncCommodities operation in SyncDataConsumerApi.
 * @export
 * @interface SyncDataConsumerApiSyncCommoditiesRequest
 */
export interface SyncDataConsumerApiSyncCommoditiesRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataConsumerApiSyncCommodities
     */
    readonly from?: string
}

/**
 * Request parameters for syncContainers operation in SyncDataConsumerApi.
 * @export
 * @interface SyncDataConsumerApiSyncContainersRequest
 */
export interface SyncDataConsumerApiSyncContainersRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataConsumerApiSyncContainers
     */
    readonly from?: string
}

/**
 * Request parameters for syncCustomers operation in SyncDataConsumerApi.
 * @export
 * @interface SyncDataConsumerApiSyncCustomersRequest
 */
export interface SyncDataConsumerApiSyncCustomersRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataConsumerApiSyncCustomers
     */
    readonly from?: string
}

/**
 * Request parameters for syncOrders operation in SyncDataConsumerApi.
 * @export
 * @interface SyncDataConsumerApiSyncOrdersRequest
 */
export interface SyncDataConsumerApiSyncOrdersRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataConsumerApiSyncOrders
     */
    readonly from?: string
}

/**
 * Request parameters for syncPackages operation in SyncDataConsumerApi.
 * @export
 * @interface SyncDataConsumerApiSyncPackagesRequest
 */
export interface SyncDataConsumerApiSyncPackagesRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataConsumerApiSyncPackages
     */
    readonly from?: string
}

/**
 * Request parameters for syncRailVisits operation in SyncDataConsumerApi.
 * @export
 * @interface SyncDataConsumerApiSyncRailVisitsRequest
 */
export interface SyncDataConsumerApiSyncRailVisitsRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataConsumerApiSyncRailVisits
     */
    readonly from?: string
}

/**
 * Request parameters for syncStrippingOrders operation in SyncDataConsumerApi.
 * @export
 * @interface SyncDataConsumerApiSyncStrippingOrdersRequest
 */
export interface SyncDataConsumerApiSyncStrippingOrdersRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataConsumerApiSyncStrippingOrders
     */
    readonly from?: string
}

/**
 * Request parameters for syncStuffingOrders operation in SyncDataConsumerApi.
 * @export
 * @interface SyncDataConsumerApiSyncStuffingOrdersRequest
 */
export interface SyncDataConsumerApiSyncStuffingOrdersRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataConsumerApiSyncStuffingOrders
     */
    readonly from?: string
}

/**
 * Request parameters for syncTenants operation in SyncDataConsumerApi.
 * @export
 * @interface SyncDataConsumerApiSyncTenantsRequest
 */
export interface SyncDataConsumerApiSyncTenantsRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataConsumerApiSyncTenants
     */
    readonly from?: string
}

/**
 * Request parameters for syncTruckVisits operation in SyncDataConsumerApi.
 * @export
 * @interface SyncDataConsumerApiSyncTruckVisitsRequest
 */
export interface SyncDataConsumerApiSyncTruckVisitsRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataConsumerApiSyncTruckVisits
     */
    readonly from?: string
}

/**
 * Request parameters for syncVesselVisits operation in SyncDataConsumerApi.
 * @export
 * @interface SyncDataConsumerApiSyncVesselVisitsRequest
 */
export interface SyncDataConsumerApiSyncVesselVisitsRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataConsumerApiSyncVesselVisits
     */
    readonly from?: string
}

/**
 * SyncDataConsumerApi - object-oriented interface
 * @export
 * @class SyncDataConsumerApi
 * @extends {BaseAPI}
 */
export class SyncDataConsumerApi extends BaseAPI {
    /**
     * 
     * @param {SyncDataConsumerApiSyncCommoditiesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataConsumerApi
     */
    public syncCommodities(requestParameters: SyncDataConsumerApiSyncCommoditiesRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataConsumerApiFp(this.configuration).syncCommodities(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncDataConsumerApiSyncContainersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataConsumerApi
     */
    public syncContainers(requestParameters: SyncDataConsumerApiSyncContainersRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataConsumerApiFp(this.configuration).syncContainers(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncDataConsumerApiSyncCustomersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataConsumerApi
     */
    public syncCustomers(requestParameters: SyncDataConsumerApiSyncCustomersRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataConsumerApiFp(this.configuration).syncCustomers(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncDataConsumerApiSyncOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataConsumerApi
     */
    public syncOrders(requestParameters: SyncDataConsumerApiSyncOrdersRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataConsumerApiFp(this.configuration).syncOrders(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncDataConsumerApiSyncPackagesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataConsumerApi
     */
    public syncPackages(requestParameters: SyncDataConsumerApiSyncPackagesRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataConsumerApiFp(this.configuration).syncPackages(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncDataConsumerApiSyncRailVisitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataConsumerApi
     */
    public syncRailVisits(requestParameters: SyncDataConsumerApiSyncRailVisitsRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataConsumerApiFp(this.configuration).syncRailVisits(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncDataConsumerApiSyncStrippingOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataConsumerApi
     */
    public syncStrippingOrders(requestParameters: SyncDataConsumerApiSyncStrippingOrdersRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataConsumerApiFp(this.configuration).syncStrippingOrders(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncDataConsumerApiSyncStuffingOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataConsumerApi
     */
    public syncStuffingOrders(requestParameters: SyncDataConsumerApiSyncStuffingOrdersRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataConsumerApiFp(this.configuration).syncStuffingOrders(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncDataConsumerApiSyncTenantsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataConsumerApi
     */
    public syncTenants(requestParameters: SyncDataConsumerApiSyncTenantsRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataConsumerApiFp(this.configuration).syncTenants(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncDataConsumerApiSyncTruckVisitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataConsumerApi
     */
    public syncTruckVisits(requestParameters: SyncDataConsumerApiSyncTruckVisitsRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataConsumerApiFp(this.configuration).syncTruckVisits(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncDataConsumerApiSyncVesselVisitsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataConsumerApi
     */
    public syncVesselVisits(requestParameters: SyncDataConsumerApiSyncVesselVisitsRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataConsumerApiFp(this.configuration).syncVesselVisits(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SyncDataProviderApi - axios parameter creator
 * @export
 */
export const SyncDataProviderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        berths: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/internal/sync/Berths`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        containerAllocations: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/internal/sync/ContainerAllocations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        containerPositions: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/internal/sync/ContainerPositions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railTracks: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/internal/sync/RailTracks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yardBlockSlots: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/internal/sync/YardBlockSlots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yardBlocks: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/internal/sync/YardBlocks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yardReservations: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/internal/sync/YardReservations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SyncDataProviderApi - functional programming interface
 * @export
 */
export const SyncDataProviderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SyncDataProviderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async berths(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncBerthsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.berths(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async containerAllocations(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncContainersPlannedYardBlockDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.containerAllocations(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async containerPositions(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncContainerPositionsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.containerPositions(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async railTracks(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncRailTracksDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.railTracks(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async yardBlockSlots(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncYardBlockSlotsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.yardBlockSlots(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async yardBlocks(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncYardBlocksDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.yardBlocks(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async yardReservations(from?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncYardReservationsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.yardReservations(from, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SyncDataProviderApi - factory interface
 * @export
 */
export const SyncDataProviderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SyncDataProviderApiFp(configuration)
    return {
        /**
         * 
         * @param {SyncDataProviderApiBerthsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        berths(requestParameters: SyncDataProviderApiBerthsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SyncBerthsDto> {
            return localVarFp.berths(requestParameters.from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncDataProviderApiContainerAllocationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        containerAllocations(requestParameters: SyncDataProviderApiContainerAllocationsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SyncContainersPlannedYardBlockDto> {
            return localVarFp.containerAllocations(requestParameters.from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncDataProviderApiContainerPositionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        containerPositions(requestParameters: SyncDataProviderApiContainerPositionsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SyncContainerPositionsDto> {
            return localVarFp.containerPositions(requestParameters.from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncDataProviderApiRailTracksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        railTracks(requestParameters: SyncDataProviderApiRailTracksRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SyncRailTracksDto> {
            return localVarFp.railTracks(requestParameters.from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncDataProviderApiYardBlockSlotsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yardBlockSlots(requestParameters: SyncDataProviderApiYardBlockSlotsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SyncYardBlockSlotsDto> {
            return localVarFp.yardBlockSlots(requestParameters.from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncDataProviderApiYardBlocksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yardBlocks(requestParameters: SyncDataProviderApiYardBlocksRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SyncYardBlocksDto> {
            return localVarFp.yardBlocks(requestParameters.from, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SyncDataProviderApiYardReservationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yardReservations(requestParameters: SyncDataProviderApiYardReservationsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<SyncYardReservationsDto> {
            return localVarFp.yardReservations(requestParameters.from, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for berths operation in SyncDataProviderApi.
 * @export
 * @interface SyncDataProviderApiBerthsRequest
 */
export interface SyncDataProviderApiBerthsRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataProviderApiBerths
     */
    readonly from?: string
}

/**
 * Request parameters for containerAllocations operation in SyncDataProviderApi.
 * @export
 * @interface SyncDataProviderApiContainerAllocationsRequest
 */
export interface SyncDataProviderApiContainerAllocationsRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataProviderApiContainerAllocations
     */
    readonly from?: string
}

/**
 * Request parameters for containerPositions operation in SyncDataProviderApi.
 * @export
 * @interface SyncDataProviderApiContainerPositionsRequest
 */
export interface SyncDataProviderApiContainerPositionsRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataProviderApiContainerPositions
     */
    readonly from?: string
}

/**
 * Request parameters for railTracks operation in SyncDataProviderApi.
 * @export
 * @interface SyncDataProviderApiRailTracksRequest
 */
export interface SyncDataProviderApiRailTracksRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataProviderApiRailTracks
     */
    readonly from?: string
}

/**
 * Request parameters for yardBlockSlots operation in SyncDataProviderApi.
 * @export
 * @interface SyncDataProviderApiYardBlockSlotsRequest
 */
export interface SyncDataProviderApiYardBlockSlotsRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataProviderApiYardBlockSlots
     */
    readonly from?: string
}

/**
 * Request parameters for yardBlocks operation in SyncDataProviderApi.
 * @export
 * @interface SyncDataProviderApiYardBlocksRequest
 */
export interface SyncDataProviderApiYardBlocksRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataProviderApiYardBlocks
     */
    readonly from?: string
}

/**
 * Request parameters for yardReservations operation in SyncDataProviderApi.
 * @export
 * @interface SyncDataProviderApiYardReservationsRequest
 */
export interface SyncDataProviderApiYardReservationsRequest {
    /**
     * 
     * @type {string}
     * @memberof SyncDataProviderApiYardReservations
     */
    readonly from?: string
}

/**
 * SyncDataProviderApi - object-oriented interface
 * @export
 * @class SyncDataProviderApi
 * @extends {BaseAPI}
 */
export class SyncDataProviderApi extends BaseAPI {
    /**
     * 
     * @param {SyncDataProviderApiBerthsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataProviderApi
     */
    public berths(requestParameters: SyncDataProviderApiBerthsRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataProviderApiFp(this.configuration).berths(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncDataProviderApiContainerAllocationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataProviderApi
     */
    public containerAllocations(requestParameters: SyncDataProviderApiContainerAllocationsRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataProviderApiFp(this.configuration).containerAllocations(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncDataProviderApiContainerPositionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataProviderApi
     */
    public containerPositions(requestParameters: SyncDataProviderApiContainerPositionsRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataProviderApiFp(this.configuration).containerPositions(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncDataProviderApiRailTracksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataProviderApi
     */
    public railTracks(requestParameters: SyncDataProviderApiRailTracksRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataProviderApiFp(this.configuration).railTracks(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncDataProviderApiYardBlockSlotsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataProviderApi
     */
    public yardBlockSlots(requestParameters: SyncDataProviderApiYardBlockSlotsRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataProviderApiFp(this.configuration).yardBlockSlots(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncDataProviderApiYardBlocksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataProviderApi
     */
    public yardBlocks(requestParameters: SyncDataProviderApiYardBlocksRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataProviderApiFp(this.configuration).yardBlocks(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SyncDataProviderApiYardReservationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncDataProviderApi
     */
    public yardReservations(requestParameters: SyncDataProviderApiYardReservationsRequest = {}, options?: AxiosRequestConfig) {
        return SyncDataProviderApiFp(this.configuration).yardReservations(requestParameters.from, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TenantAppConfigApi - axios parameter creator
 * @export
 */
export const TenantAppConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/TenantAppConfig/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TenantAppConfigUpsertRequest} [tenantAppConfigUpsertRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsert: async (tenantAppConfigUpsertRequest?: TenantAppConfigUpsertRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/TenantAppConfig/Upsert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tenantAppConfigUpsertRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantAppConfigApi - functional programming interface
 * @export
 */
export const TenantAppConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TenantAppConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantConfigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {TenantAppConfigUpsertRequest} [tenantAppConfigUpsertRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsert(tenantAppConfigUpsertRequest?: TenantAppConfigUpsertRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsert(tenantAppConfigUpsertRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TenantAppConfigApi - factory interface
 * @export
 */
export const TenantAppConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TenantAppConfigApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: AxiosRequestConfig): AxiosPromise<TenantConfigDto> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TenantAppConfigApiUpsertRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsert(requestParameters: TenantAppConfigApiUpsertRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.upsert(requestParameters.tenantAppConfigUpsertRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for upsert operation in TenantAppConfigApi.
 * @export
 * @interface TenantAppConfigApiUpsertRequest
 */
export interface TenantAppConfigApiUpsertRequest {
    /**
     * 
     * @type {TenantAppConfigUpsertRequest}
     * @memberof TenantAppConfigApiUpsert
     */
    readonly tenantAppConfigUpsertRequest?: TenantAppConfigUpsertRequest
}

/**
 * TenantAppConfigApi - object-oriented interface
 * @export
 * @class TenantAppConfigApi
 * @extends {BaseAPI}
 */
export class TenantAppConfigApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantAppConfigApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return TenantAppConfigApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TenantAppConfigApiUpsertRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantAppConfigApi
     */
    public upsert(requestParameters: TenantAppConfigApiUpsertRequest = {}, options?: AxiosRequestConfig) {
        return TenantAppConfigApiFp(this.configuration).upsert(requestParameters.tenantAppConfigUpsertRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebAppConfigurationApi - axios parameter creator
 * @export
 */
export const WebAppConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/WebAppConfiguration/Get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebAppConfigurationApi - functional programming interface
 * @export
 */
export const WebAppConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebAppConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebAppConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebAppConfigurationApi - factory interface
 * @export
 */
export const WebAppConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebAppConfigurationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: AxiosRequestConfig): AxiosPromise<WebAppConfiguration> {
            return localVarFp.get(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebAppConfigurationApi - object-oriented interface
 * @export
 * @class WebAppConfigurationApi
 * @extends {BaseAPI}
 */
export class WebAppConfigurationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebAppConfigurationApi
     */
    public get(options?: AxiosRequestConfig) {
        return WebAppConfigurationApiFp(this.configuration).get(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WeightClassesApi - axios parameter creator
 * @export
 */
export const WeightClassesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<CreateWeightClassDto>} [createWeightClassDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (createWeightClassDto?: Array<CreateWeightClassDto>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/WeightClasses/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createWeightClassDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/WeightClasses/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WeightClassesApi - functional programming interface
 * @export
 */
export const WeightClassesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WeightClassesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Array<CreateWeightClassDto>} [createWeightClassDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(createWeightClassDto?: Array<CreateWeightClassDto>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(createWeightClassDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WeightClassDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WeightClassesApi - factory interface
 * @export
 */
export const WeightClassesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WeightClassesApiFp(configuration)
    return {
        /**
         * 
         * @param {WeightClassesApiCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestParameters: WeightClassesApiCreateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.create(requestParameters.createWeightClassDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: AxiosRequestConfig): AxiosPromise<Array<WeightClassDto>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for create operation in WeightClassesApi.
 * @export
 * @interface WeightClassesApiCreateRequest
 */
export interface WeightClassesApiCreateRequest {
    /**
     * 
     * @type {Array<CreateWeightClassDto>}
     * @memberof WeightClassesApiCreate
     */
    readonly createWeightClassDto?: Array<CreateWeightClassDto>
}

/**
 * WeightClassesApi - object-oriented interface
 * @export
 * @class WeightClassesApi
 * @extends {BaseAPI}
 */
export class WeightClassesApi extends BaseAPI {
    /**
     * 
     * @param {WeightClassesApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeightClassesApi
     */
    public create(requestParameters: WeightClassesApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return WeightClassesApiFp(this.configuration).create(requestParameters.createWeightClassDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WeightClassesApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return WeightClassesApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * YardBlockBaysApi - axios parameter creator
 * @export
 */
export const YardBlockBaysApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [yardBlockBayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (yardBlockBayId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockBays/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (yardBlockBayId !== undefined) {
                localVarQueryParameter['yardBlockBayId'] = yardBlockBayId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {YardBlockBaysCreateRequest} [yardBlockBaysCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (yardBlockBaysCreateRequest?: YardBlockBaysCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockBays/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(yardBlockBaysCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [yardBlockId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (yardBlockId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockBays/Get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (yardBlockId !== undefined) {
                localVarQueryParameter['YardBlockId'] = yardBlockId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [yardBlockId] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUnique: async (name?: string, yardBlockId?: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockBays/IsUnique`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (yardBlockId !== undefined) {
                localVarQueryParameter['yardBlockId'] = yardBlockId;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [yardBlockBayId] 
         * @param {boolean} [lockStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLockStatus: async (yardBlockBayId?: string, lockStatus?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockBays/SetLockStatus/lock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (yardBlockBayId !== undefined) {
                localVarQueryParameter['yardBlockBayId'] = yardBlockBayId;
            }

            if (lockStatus !== undefined) {
                localVarQueryParameter['lockStatus'] = lockStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {YardBlockBaysUpdateRequest} [yardBlockBaysUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (yardBlockBaysUpdateRequest?: YardBlockBaysUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockBays/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(yardBlockBaysUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * YardBlockBaysApi - functional programming interface
 * @export
 */
export const YardBlockBaysApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = YardBlockBaysApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [yardBlockBayId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(yardBlockBayId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(yardBlockBayId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {YardBlockBaysCreateRequest} [yardBlockBaysCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(yardBlockBaysCreateRequest?: YardBlockBaysCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YardBlockBayDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(yardBlockBaysCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [yardBlockId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(yardBlockId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<YardBlockBayDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(yardBlockId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [yardBlockId] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isUnique(name?: string, yardBlockId?: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isUnique(name, yardBlockId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [yardBlockBayId] 
         * @param {boolean} [lockStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setLockStatus(yardBlockBayId?: string, lockStatus?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlotDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setLockStatus(yardBlockBayId, lockStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {YardBlockBaysUpdateRequest} [yardBlockBaysUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(yardBlockBaysUpdateRequest?: YardBlockBaysUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YardBlockBayDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(yardBlockBaysUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * YardBlockBaysApi - factory interface
 * @export
 */
export const YardBlockBaysApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = YardBlockBaysApiFp(configuration)
    return {
        /**
         * 
         * @param {YardBlockBaysApiDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(requestParameters: YardBlockBaysApiDeleteRequest = {}, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp._delete(requestParameters.yardBlockBayId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlockBaysApiCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestParameters: YardBlockBaysApiCreateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<YardBlockBayDto> {
            return localVarFp.create(requestParameters.yardBlockBaysCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlockBaysApiGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(requestParameters: YardBlockBaysApiGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<YardBlockBayDto>> {
            return localVarFp.get(requestParameters.yardBlockId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlockBaysApiIsUniqueRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUnique(requestParameters: YardBlockBaysApiIsUniqueRequest = {}, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.isUnique(requestParameters.name, requestParameters.yardBlockId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlockBaysApiSetLockStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLockStatus(requestParameters: YardBlockBaysApiSetLockStatusRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<SlotDto>> {
            return localVarFp.setLockStatus(requestParameters.yardBlockBayId, requestParameters.lockStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlockBaysApiUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(requestParameters: YardBlockBaysApiUpdateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<YardBlockBayDto> {
            return localVarFp.update(requestParameters.yardBlockBaysUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in YardBlockBaysApi.
 * @export
 * @interface YardBlockBaysApiDeleteRequest
 */
export interface YardBlockBaysApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockBaysApiDelete
     */
    readonly yardBlockBayId?: string
}

/**
 * Request parameters for create operation in YardBlockBaysApi.
 * @export
 * @interface YardBlockBaysApiCreateRequest
 */
export interface YardBlockBaysApiCreateRequest {
    /**
     * 
     * @type {YardBlockBaysCreateRequest}
     * @memberof YardBlockBaysApiCreate
     */
    readonly yardBlockBaysCreateRequest?: YardBlockBaysCreateRequest
}

/**
 * Request parameters for get operation in YardBlockBaysApi.
 * @export
 * @interface YardBlockBaysApiGetRequest
 */
export interface YardBlockBaysApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockBaysApiGet
     */
    readonly yardBlockId?: string
}

/**
 * Request parameters for isUnique operation in YardBlockBaysApi.
 * @export
 * @interface YardBlockBaysApiIsUniqueRequest
 */
export interface YardBlockBaysApiIsUniqueRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockBaysApiIsUnique
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlockBaysApiIsUnique
     */
    readonly yardBlockId?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlockBaysApiIsUnique
     */
    readonly id?: string
}

/**
 * Request parameters for setLockStatus operation in YardBlockBaysApi.
 * @export
 * @interface YardBlockBaysApiSetLockStatusRequest
 */
export interface YardBlockBaysApiSetLockStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockBaysApiSetLockStatus
     */
    readonly yardBlockBayId?: string

    /**
     * 
     * @type {boolean}
     * @memberof YardBlockBaysApiSetLockStatus
     */
    readonly lockStatus?: boolean
}

/**
 * Request parameters for update operation in YardBlockBaysApi.
 * @export
 * @interface YardBlockBaysApiUpdateRequest
 */
export interface YardBlockBaysApiUpdateRequest {
    /**
     * 
     * @type {YardBlockBaysUpdateRequest}
     * @memberof YardBlockBaysApiUpdate
     */
    readonly yardBlockBaysUpdateRequest?: YardBlockBaysUpdateRequest
}

/**
 * YardBlockBaysApi - object-oriented interface
 * @export
 * @class YardBlockBaysApi
 * @extends {BaseAPI}
 */
export class YardBlockBaysApi extends BaseAPI {
    /**
     * 
     * @param {YardBlockBaysApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockBaysApi
     */
    public _delete(requestParameters: YardBlockBaysApiDeleteRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockBaysApiFp(this.configuration)._delete(requestParameters.yardBlockBayId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlockBaysApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockBaysApi
     */
    public create(requestParameters: YardBlockBaysApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockBaysApiFp(this.configuration).create(requestParameters.yardBlockBaysCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlockBaysApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockBaysApi
     */
    public get(requestParameters: YardBlockBaysApiGetRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockBaysApiFp(this.configuration).get(requestParameters.yardBlockId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlockBaysApiIsUniqueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockBaysApi
     */
    public isUnique(requestParameters: YardBlockBaysApiIsUniqueRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockBaysApiFp(this.configuration).isUnique(requestParameters.name, requestParameters.yardBlockId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlockBaysApiSetLockStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockBaysApi
     */
    public setLockStatus(requestParameters: YardBlockBaysApiSetLockStatusRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockBaysApiFp(this.configuration).setLockStatus(requestParameters.yardBlockBayId, requestParameters.lockStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlockBaysApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockBaysApi
     */
    public update(requestParameters: YardBlockBaysApiUpdateRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockBaysApiFp(this.configuration).update(requestParameters.yardBlockBaysUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * YardBlockGeometriesApi - axios parameter creator
 * @export
 */
export const YardBlockGeometriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {YardBlockGeometriesSetRequest} [yardBlockGeometriesSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        set: async (yardBlockGeometriesSetRequest?: YardBlockGeometriesSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockGeometries/Set`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(yardBlockGeometriesSetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * YardBlockGeometriesApi - functional programming interface
 * @export
 */
export const YardBlockGeometriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = YardBlockGeometriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {YardBlockGeometriesSetRequest} [yardBlockGeometriesSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async set(yardBlockGeometriesSetRequest?: YardBlockGeometriesSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YardBlockDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.set(yardBlockGeometriesSetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * YardBlockGeometriesApi - factory interface
 * @export
 */
export const YardBlockGeometriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = YardBlockGeometriesApiFp(configuration)
    return {
        /**
         * 
         * @param {YardBlockGeometriesApiSetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        set(requestParameters: YardBlockGeometriesApiSetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<YardBlockDto> {
            return localVarFp.set(requestParameters.yardBlockGeometriesSetRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for set operation in YardBlockGeometriesApi.
 * @export
 * @interface YardBlockGeometriesApiSetRequest
 */
export interface YardBlockGeometriesApiSetRequest {
    /**
     * 
     * @type {YardBlockGeometriesSetRequest}
     * @memberof YardBlockGeometriesApiSet
     */
    readonly yardBlockGeometriesSetRequest?: YardBlockGeometriesSetRequest
}

/**
 * YardBlockGeometriesApi - object-oriented interface
 * @export
 * @class YardBlockGeometriesApi
 * @extends {BaseAPI}
 */
export class YardBlockGeometriesApi extends BaseAPI {
    /**
     * 
     * @param {YardBlockGeometriesApiSetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockGeometriesApi
     */
    public set(requestParameters: YardBlockGeometriesApiSetRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockGeometriesApiFp(this.configuration).set(requestParameters.yardBlockGeometriesSetRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * YardBlockRowsApi - axios parameter creator
 * @export
 */
export const YardBlockRowsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [yardBlockRowId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (yardBlockRowId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockRows/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (yardBlockRowId !== undefined) {
                localVarQueryParameter['yardBlockRowId'] = yardBlockRowId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {YardBlockRowsCreateRequest} [yardBlockRowsCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (yardBlockRowsCreateRequest?: YardBlockRowsCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockRows/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(yardBlockRowsCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [yardBlockId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (yardBlockId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockRows/Get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (yardBlockId !== undefined) {
                localVarQueryParameter['yardBlockId'] = yardBlockId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockRows/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [yardBlockId] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUnique: async (name?: string, yardBlockId?: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockRows/IsUnique`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (yardBlockId !== undefined) {
                localVarQueryParameter['yardBlockId'] = yardBlockId;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [yardBlockRowId] 
         * @param {boolean} [lockStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLockStatus: async (yardBlockRowId?: string, lockStatus?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockRows/SetLockStatus/lock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (yardBlockRowId !== undefined) {
                localVarQueryParameter['yardBlockRowId'] = yardBlockRowId;
            }

            if (lockStatus !== undefined) {
                localVarQueryParameter['lockStatus'] = lockStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {YardBlockRowsUpdateRequest} [yardBlockRowsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (yardBlockRowsUpdateRequest?: YardBlockRowsUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockRows/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(yardBlockRowsUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * YardBlockRowsApi - functional programming interface
 * @export
 */
export const YardBlockRowsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = YardBlockRowsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [yardBlockRowId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(yardBlockRowId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(yardBlockRowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {YardBlockRowsCreateRequest} [yardBlockRowsCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(yardBlockRowsCreateRequest?: YardBlockRowsCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YardBlockRowDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(yardBlockRowsCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [yardBlockId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(yardBlockId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<YardBlockRowDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(yardBlockId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<YardBlockRowDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [yardBlockId] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isUnique(name?: string, yardBlockId?: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isUnique(name, yardBlockId, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [yardBlockRowId] 
         * @param {boolean} [lockStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setLockStatus(yardBlockRowId?: string, lockStatus?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlotDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setLockStatus(yardBlockRowId, lockStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {YardBlockRowsUpdateRequest} [yardBlockRowsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(yardBlockRowsUpdateRequest?: YardBlockRowsUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YardBlockRowDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(yardBlockRowsUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * YardBlockRowsApi - factory interface
 * @export
 */
export const YardBlockRowsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = YardBlockRowsApiFp(configuration)
    return {
        /**
         * 
         * @param {YardBlockRowsApiDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(requestParameters: YardBlockRowsApiDeleteRequest = {}, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp._delete(requestParameters.yardBlockRowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlockRowsApiCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestParameters: YardBlockRowsApiCreateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<YardBlockRowDto> {
            return localVarFp.create(requestParameters.yardBlockRowsCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlockRowsApiGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(requestParameters: YardBlockRowsApiGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<YardBlockRowDto>> {
            return localVarFp.get(requestParameters.yardBlockId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: AxiosRequestConfig): AxiosPromise<Array<YardBlockRowDto>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlockRowsApiIsUniqueRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUnique(requestParameters: YardBlockRowsApiIsUniqueRequest = {}, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.isUnique(requestParameters.name, requestParameters.yardBlockId, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlockRowsApiSetLockStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLockStatus(requestParameters: YardBlockRowsApiSetLockStatusRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<SlotDto>> {
            return localVarFp.setLockStatus(requestParameters.yardBlockRowId, requestParameters.lockStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlockRowsApiUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(requestParameters: YardBlockRowsApiUpdateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<YardBlockRowDto> {
            return localVarFp.update(requestParameters.yardBlockRowsUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in YardBlockRowsApi.
 * @export
 * @interface YardBlockRowsApiDeleteRequest
 */
export interface YardBlockRowsApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockRowsApiDelete
     */
    readonly yardBlockRowId?: string
}

/**
 * Request parameters for create operation in YardBlockRowsApi.
 * @export
 * @interface YardBlockRowsApiCreateRequest
 */
export interface YardBlockRowsApiCreateRequest {
    /**
     * 
     * @type {YardBlockRowsCreateRequest}
     * @memberof YardBlockRowsApiCreate
     */
    readonly yardBlockRowsCreateRequest?: YardBlockRowsCreateRequest
}

/**
 * Request parameters for get operation in YardBlockRowsApi.
 * @export
 * @interface YardBlockRowsApiGetRequest
 */
export interface YardBlockRowsApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockRowsApiGet
     */
    readonly yardBlockId?: string
}

/**
 * Request parameters for isUnique operation in YardBlockRowsApi.
 * @export
 * @interface YardBlockRowsApiIsUniqueRequest
 */
export interface YardBlockRowsApiIsUniqueRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockRowsApiIsUnique
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlockRowsApiIsUnique
     */
    readonly yardBlockId?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlockRowsApiIsUnique
     */
    readonly id?: string
}

/**
 * Request parameters for setLockStatus operation in YardBlockRowsApi.
 * @export
 * @interface YardBlockRowsApiSetLockStatusRequest
 */
export interface YardBlockRowsApiSetLockStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockRowsApiSetLockStatus
     */
    readonly yardBlockRowId?: string

    /**
     * 
     * @type {boolean}
     * @memberof YardBlockRowsApiSetLockStatus
     */
    readonly lockStatus?: boolean
}

/**
 * Request parameters for update operation in YardBlockRowsApi.
 * @export
 * @interface YardBlockRowsApiUpdateRequest
 */
export interface YardBlockRowsApiUpdateRequest {
    /**
     * 
     * @type {YardBlockRowsUpdateRequest}
     * @memberof YardBlockRowsApiUpdate
     */
    readonly yardBlockRowsUpdateRequest?: YardBlockRowsUpdateRequest
}

/**
 * YardBlockRowsApi - object-oriented interface
 * @export
 * @class YardBlockRowsApi
 * @extends {BaseAPI}
 */
export class YardBlockRowsApi extends BaseAPI {
    /**
     * 
     * @param {YardBlockRowsApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockRowsApi
     */
    public _delete(requestParameters: YardBlockRowsApiDeleteRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockRowsApiFp(this.configuration)._delete(requestParameters.yardBlockRowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlockRowsApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockRowsApi
     */
    public create(requestParameters: YardBlockRowsApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockRowsApiFp(this.configuration).create(requestParameters.yardBlockRowsCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlockRowsApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockRowsApi
     */
    public get(requestParameters: YardBlockRowsApiGetRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockRowsApiFp(this.configuration).get(requestParameters.yardBlockId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockRowsApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return YardBlockRowsApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlockRowsApiIsUniqueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockRowsApi
     */
    public isUnique(requestParameters: YardBlockRowsApiIsUniqueRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockRowsApiFp(this.configuration).isUnique(requestParameters.name, requestParameters.yardBlockId, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlockRowsApiSetLockStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockRowsApi
     */
    public setLockStatus(requestParameters: YardBlockRowsApiSetLockStatusRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockRowsApiFp(this.configuration).setLockStatus(requestParameters.yardBlockRowId, requestParameters.lockStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlockRowsApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockRowsApi
     */
    public update(requestParameters: YardBlockRowsApiUpdateRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockRowsApiFp(this.configuration).update(requestParameters.yardBlockRowsUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * YardBlockSlotOccupanciesApi - axios parameter creator
 * @export
 */
export const YardBlockSlotOccupanciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {YardBlockSlotOccupanciesCreateReservationRequest} [yardBlockSlotOccupanciesCreateReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReservation: async (yardBlockSlotOccupanciesCreateReservationRequest?: YardBlockSlotOccupanciesCreateReservationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockSlotOccupancies/CreateReservation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(yardBlockSlotOccupanciesCreateReservationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [turnoverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetSlot: async (turnoverId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockSlotOccupancies/GetTargetSlot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (turnoverId !== undefined) {
                localVarQueryParameter['turnoverId'] = turnoverId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {YardBlockSlotOccupanciesUpdateContainerPositionRequest} [yardBlockSlotOccupanciesUpdateContainerPositionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContainerPosition: async (yardBlockSlotOccupanciesUpdateContainerPositionRequest?: YardBlockSlotOccupanciesUpdateContainerPositionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockSlotOccupancies/UpdateContainerPosition`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(yardBlockSlotOccupanciesUpdateContainerPositionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {YardBlockSlotOccupanciesUpdateContainerPositionRequest} [yardBlockSlotOccupanciesUpdateContainerPositionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateDGStackingAndSegregationRulesViolation: async (yardBlockSlotOccupanciesUpdateContainerPositionRequest?: YardBlockSlotOccupanciesUpdateContainerPositionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockSlotOccupancies/ValidateDGStackingAndSegregationRulesViolation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(yardBlockSlotOccupanciesUpdateContainerPositionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * YardBlockSlotOccupanciesApi - functional programming interface
 * @export
 */
export const YardBlockSlotOccupanciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = YardBlockSlotOccupanciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {YardBlockSlotOccupanciesCreateReservationRequest} [yardBlockSlotOccupanciesCreateReservationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReservation(yardBlockSlotOccupanciesCreateReservationRequest?: YardBlockSlotOccupanciesCreateReservationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<YardBlockSlotOccupancyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReservation(yardBlockSlotOccupanciesCreateReservationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [turnoverId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTargetSlot(turnoverId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YardBlockSlotLocationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTargetSlot(turnoverId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {YardBlockSlotOccupanciesUpdateContainerPositionRequest} [yardBlockSlotOccupanciesUpdateContainerPositionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContainerPosition(yardBlockSlotOccupanciesUpdateContainerPositionRequest?: YardBlockSlotOccupanciesUpdateContainerPositionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<YardBlockSlotOccupancyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContainerPosition(yardBlockSlotOccupanciesUpdateContainerPositionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {YardBlockSlotOccupanciesUpdateContainerPositionRequest} [yardBlockSlotOccupanciesUpdateContainerPositionRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateDGStackingAndSegregationRulesViolation(yardBlockSlotOccupanciesUpdateContainerPositionRequest?: YardBlockSlotOccupanciesUpdateContainerPositionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateDGStackingAndSegregationRulesViolation(yardBlockSlotOccupanciesUpdateContainerPositionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * YardBlockSlotOccupanciesApi - factory interface
 * @export
 */
export const YardBlockSlotOccupanciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = YardBlockSlotOccupanciesApiFp(configuration)
    return {
        /**
         * 
         * @param {YardBlockSlotOccupanciesApiCreateReservationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReservation(requestParameters: YardBlockSlotOccupanciesApiCreateReservationRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<YardBlockSlotOccupancyDto>> {
            return localVarFp.createReservation(requestParameters.yardBlockSlotOccupanciesCreateReservationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlockSlotOccupanciesApiGetTargetSlotRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTargetSlot(requestParameters: YardBlockSlotOccupanciesApiGetTargetSlotRequest = {}, options?: AxiosRequestConfig): AxiosPromise<YardBlockSlotLocationDto> {
            return localVarFp.getTargetSlot(requestParameters.turnoverId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlockSlotOccupanciesApiUpdateContainerPositionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContainerPosition(requestParameters: YardBlockSlotOccupanciesApiUpdateContainerPositionRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<YardBlockSlotOccupancyDto>> {
            return localVarFp.updateContainerPosition(requestParameters.yardBlockSlotOccupanciesUpdateContainerPositionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlockSlotOccupanciesApiValidateDGStackingAndSegregationRulesViolationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateDGStackingAndSegregationRulesViolation(requestParameters: YardBlockSlotOccupanciesApiValidateDGStackingAndSegregationRulesViolationRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.validateDGStackingAndSegregationRulesViolation(requestParameters.yardBlockSlotOccupanciesUpdateContainerPositionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createReservation operation in YardBlockSlotOccupanciesApi.
 * @export
 * @interface YardBlockSlotOccupanciesApiCreateReservationRequest
 */
export interface YardBlockSlotOccupanciesApiCreateReservationRequest {
    /**
     * 
     * @type {YardBlockSlotOccupanciesCreateReservationRequest}
     * @memberof YardBlockSlotOccupanciesApiCreateReservation
     */
    readonly yardBlockSlotOccupanciesCreateReservationRequest?: YardBlockSlotOccupanciesCreateReservationRequest
}

/**
 * Request parameters for getTargetSlot operation in YardBlockSlotOccupanciesApi.
 * @export
 * @interface YardBlockSlotOccupanciesApiGetTargetSlotRequest
 */
export interface YardBlockSlotOccupanciesApiGetTargetSlotRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotOccupanciesApiGetTargetSlot
     */
    readonly turnoverId?: string
}

/**
 * Request parameters for updateContainerPosition operation in YardBlockSlotOccupanciesApi.
 * @export
 * @interface YardBlockSlotOccupanciesApiUpdateContainerPositionRequest
 */
export interface YardBlockSlotOccupanciesApiUpdateContainerPositionRequest {
    /**
     * 
     * @type {YardBlockSlotOccupanciesUpdateContainerPositionRequest}
     * @memberof YardBlockSlotOccupanciesApiUpdateContainerPosition
     */
    readonly yardBlockSlotOccupanciesUpdateContainerPositionRequest?: YardBlockSlotOccupanciesUpdateContainerPositionRequest
}

/**
 * Request parameters for validateDGStackingAndSegregationRulesViolation operation in YardBlockSlotOccupanciesApi.
 * @export
 * @interface YardBlockSlotOccupanciesApiValidateDGStackingAndSegregationRulesViolationRequest
 */
export interface YardBlockSlotOccupanciesApiValidateDGStackingAndSegregationRulesViolationRequest {
    /**
     * 
     * @type {YardBlockSlotOccupanciesUpdateContainerPositionRequest}
     * @memberof YardBlockSlotOccupanciesApiValidateDGStackingAndSegregationRulesViolation
     */
    readonly yardBlockSlotOccupanciesUpdateContainerPositionRequest?: YardBlockSlotOccupanciesUpdateContainerPositionRequest
}

/**
 * YardBlockSlotOccupanciesApi - object-oriented interface
 * @export
 * @class YardBlockSlotOccupanciesApi
 * @extends {BaseAPI}
 */
export class YardBlockSlotOccupanciesApi extends BaseAPI {
    /**
     * 
     * @param {YardBlockSlotOccupanciesApiCreateReservationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockSlotOccupanciesApi
     */
    public createReservation(requestParameters: YardBlockSlotOccupanciesApiCreateReservationRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockSlotOccupanciesApiFp(this.configuration).createReservation(requestParameters.yardBlockSlotOccupanciesCreateReservationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlockSlotOccupanciesApiGetTargetSlotRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockSlotOccupanciesApi
     */
    public getTargetSlot(requestParameters: YardBlockSlotOccupanciesApiGetTargetSlotRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockSlotOccupanciesApiFp(this.configuration).getTargetSlot(requestParameters.turnoverId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlockSlotOccupanciesApiUpdateContainerPositionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockSlotOccupanciesApi
     */
    public updateContainerPosition(requestParameters: YardBlockSlotOccupanciesApiUpdateContainerPositionRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockSlotOccupanciesApiFp(this.configuration).updateContainerPosition(requestParameters.yardBlockSlotOccupanciesUpdateContainerPositionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlockSlotOccupanciesApiValidateDGStackingAndSegregationRulesViolationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockSlotOccupanciesApi
     */
    public validateDGStackingAndSegregationRulesViolation(requestParameters: YardBlockSlotOccupanciesApiValidateDGStackingAndSegregationRulesViolationRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockSlotOccupanciesApiFp(this.configuration).validateDGStackingAndSegregationRulesViolation(requestParameters.yardBlockSlotOccupanciesUpdateContainerPositionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * YardBlockSlotsApi - axios parameter creator
 * @export
 */
export const YardBlockSlotsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [yardBlockId] 
         * @param {string} [yardBlockBayId] 
         * @param {string} [yardBlockRowId] 
         * @param {number} [tier] 
         * @param {boolean} [isOccupied] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (yardBlockId?: string, yardBlockBayId?: string, yardBlockRowId?: string, tier?: number, isOccupied?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockSlots/Get`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (yardBlockId !== undefined) {
                localVarQueryParameter['YardBlockId'] = yardBlockId;
            }

            if (yardBlockBayId !== undefined) {
                localVarQueryParameter['YardBlockBayId'] = yardBlockBayId;
            }

            if (yardBlockRowId !== undefined) {
                localVarQueryParameter['YardBlockRowId'] = yardBlockRowId;
            }

            if (tier !== undefined) {
                localVarQueryParameter['Tier'] = tier;
            }

            if (isOccupied !== undefined) {
                localVarQueryParameter['IsOccupied'] = isOccupied;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [yardBlockSlotId] 
         * @param {boolean} [lockStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLockStatus: async (yardBlockSlotId?: string, lockStatus?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockSlots/SetLockStatus/lock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (yardBlockSlotId !== undefined) {
                localVarQueryParameter['yardBlockSlotId'] = yardBlockSlotId;
            }

            if (lockStatus !== undefined) {
                localVarQueryParameter['lockStatus'] = lockStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * YardBlockSlotsApi - functional programming interface
 * @export
 */
export const YardBlockSlotsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = YardBlockSlotsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [yardBlockId] 
         * @param {string} [yardBlockBayId] 
         * @param {string} [yardBlockRowId] 
         * @param {number} [tier] 
         * @param {boolean} [isOccupied] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(yardBlockId?: string, yardBlockBayId?: string, yardBlockRowId?: string, tier?: number, isOccupied?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlotDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(yardBlockId, yardBlockBayId, yardBlockRowId, tier, isOccupied, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [yardBlockSlotId] 
         * @param {boolean} [lockStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setLockStatus(yardBlockSlotId?: string, lockStatus?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlotDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setLockStatus(yardBlockSlotId, lockStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * YardBlockSlotsApi - factory interface
 * @export
 */
export const YardBlockSlotsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = YardBlockSlotsApiFp(configuration)
    return {
        /**
         * 
         * @param {YardBlockSlotsApiGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(requestParameters: YardBlockSlotsApiGetRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<SlotDto>> {
            return localVarFp.get(requestParameters.yardBlockId, requestParameters.yardBlockBayId, requestParameters.yardBlockRowId, requestParameters.tier, requestParameters.isOccupied, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlockSlotsApiSetLockStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLockStatus(requestParameters: YardBlockSlotsApiSetLockStatusRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<SlotDto>> {
            return localVarFp.setLockStatus(requestParameters.yardBlockSlotId, requestParameters.lockStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for get operation in YardBlockSlotsApi.
 * @export
 * @interface YardBlockSlotsApiGetRequest
 */
export interface YardBlockSlotsApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotsApiGet
     */
    readonly yardBlockId?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotsApiGet
     */
    readonly yardBlockBayId?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotsApiGet
     */
    readonly yardBlockRowId?: string

    /**
     * 
     * @type {number}
     * @memberof YardBlockSlotsApiGet
     */
    readonly tier?: number

    /**
     * 
     * @type {boolean}
     * @memberof YardBlockSlotsApiGet
     */
    readonly isOccupied?: boolean
}

/**
 * Request parameters for setLockStatus operation in YardBlockSlotsApi.
 * @export
 * @interface YardBlockSlotsApiSetLockStatusRequest
 */
export interface YardBlockSlotsApiSetLockStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockSlotsApiSetLockStatus
     */
    readonly yardBlockSlotId?: string

    /**
     * 
     * @type {boolean}
     * @memberof YardBlockSlotsApiSetLockStatus
     */
    readonly lockStatus?: boolean
}

/**
 * YardBlockSlotsApi - object-oriented interface
 * @export
 * @class YardBlockSlotsApi
 * @extends {BaseAPI}
 */
export class YardBlockSlotsApi extends BaseAPI {
    /**
     * 
     * @param {YardBlockSlotsApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockSlotsApi
     */
    public get(requestParameters: YardBlockSlotsApiGetRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockSlotsApiFp(this.configuration).get(requestParameters.yardBlockId, requestParameters.yardBlockBayId, requestParameters.yardBlockRowId, requestParameters.tier, requestParameters.isOccupied, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlockSlotsApiSetLockStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockSlotsApi
     */
    public setLockStatus(requestParameters: YardBlockSlotsApiSetLockStatusRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockSlotsApiFp(this.configuration).setLockStatus(requestParameters.yardBlockSlotId, requestParameters.lockStatus, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * YardBlockStacksApi - axios parameter creator
 * @export
 */
export const YardBlockStacksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [containerNumber] 
         * @param {string} [yardBlockId] 
         * @param {string} [yardBlockBayId] 
         * @param {string} [yardBlockRowId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYardBlockStacks: async (containerNumber?: string, yardBlockId?: string, yardBlockBayId?: string, yardBlockRowId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockStacks/GetYardBlockStacks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (containerNumber !== undefined) {
                localVarQueryParameter['ContainerNumber'] = containerNumber;
            }

            if (yardBlockId !== undefined) {
                localVarQueryParameter['YardBlockId'] = yardBlockId;
            }

            if (yardBlockBayId !== undefined) {
                localVarQueryParameter['YardBlockBayId'] = yardBlockBayId;
            }

            if (yardBlockRowId !== undefined) {
                localVarQueryParameter['YardBlockRowId'] = yardBlockRowId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [containerOrReferenceNumber] 
         * @param {CarrierType} [inboundCarrierType] 
         * @param {CarrierType} [outboundCarrierType] 
         * @param {number} [inboundCarrier] 
         * @param {number} [outboundCarrier] 
         * @param {boolean} [isEmpty] 
         * @param {number} [size] 
         * @param {string} [containerIsoCode] 
         * @param {string} [typeCode] 
         * @param {string} [portOfLoading] 
         * @param {string} [portOfDischarge] 
         * @param {Array<string>} [weightClasses] 
         * @param {boolean} [isReefer] 
         * @param {boolean} [isDamaged] 
         * @param {Array<string>} [imoClasses] 
         * @param {boolean} [isRestow] 
         * @param {string} [containerOperator] 
         * @param {string} [consignee] 
         * @param {boolean} [isDangerous] 
         * @param {ContainerStatus} [locationStatus] 
         * @param {ContainerType} [containerType] 
         * @param {ContainerHeight} [containerHeight] 
         * @param {boolean} [isInTerminal] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYardBlockStacksAdvanced: async (containerOrReferenceNumber?: string, inboundCarrierType?: CarrierType, outboundCarrierType?: CarrierType, inboundCarrier?: number, outboundCarrier?: number, isEmpty?: boolean, size?: number, containerIsoCode?: string, typeCode?: string, portOfLoading?: string, portOfDischarge?: string, weightClasses?: Array<string>, isReefer?: boolean, isDamaged?: boolean, imoClasses?: Array<string>, isRestow?: boolean, containerOperator?: string, consignee?: string, isDangerous?: boolean, locationStatus?: ContainerStatus, containerType?: ContainerType, containerHeight?: ContainerHeight, isInTerminal?: boolean, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockStacks/GetYardBlockStacksAdvanced`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (containerOrReferenceNumber !== undefined) {
                localVarQueryParameter['ContainerOrReferenceNumber'] = containerOrReferenceNumber;
            }

            if (inboundCarrierType !== undefined) {
                localVarQueryParameter['InboundCarrierType'] = inboundCarrierType;
            }

            if (outboundCarrierType !== undefined) {
                localVarQueryParameter['OutboundCarrierType'] = outboundCarrierType;
            }

            if (inboundCarrier !== undefined) {
                localVarQueryParameter['InboundCarrier'] = inboundCarrier;
            }

            if (outboundCarrier !== undefined) {
                localVarQueryParameter['OutboundCarrier'] = outboundCarrier;
            }

            if (isEmpty !== undefined) {
                localVarQueryParameter['IsEmpty'] = isEmpty;
            }

            if (size !== undefined) {
                localVarQueryParameter['Size'] = size;
            }

            if (containerIsoCode !== undefined) {
                localVarQueryParameter['ContainerIsoCode'] = containerIsoCode;
            }

            if (typeCode !== undefined) {
                localVarQueryParameter['TypeCode'] = typeCode;
            }

            if (portOfLoading !== undefined) {
                localVarQueryParameter['PortOfLoading'] = portOfLoading;
            }

            if (portOfDischarge !== undefined) {
                localVarQueryParameter['PortOfDischarge'] = portOfDischarge;
            }

            if (weightClasses) {
                localVarQueryParameter['WeightClasses'] = weightClasses;
            }

            if (isReefer !== undefined) {
                localVarQueryParameter['IsReefer'] = isReefer;
            }

            if (isDamaged !== undefined) {
                localVarQueryParameter['IsDamaged'] = isDamaged;
            }

            if (imoClasses) {
                localVarQueryParameter['ImoClasses'] = imoClasses;
            }

            if (isRestow !== undefined) {
                localVarQueryParameter['IsRestow'] = isRestow;
            }

            if (containerOperator !== undefined) {
                localVarQueryParameter['ContainerOperator'] = containerOperator;
            }

            if (consignee !== undefined) {
                localVarQueryParameter['Consignee'] = consignee;
            }

            if (isDangerous !== undefined) {
                localVarQueryParameter['IsDangerous'] = isDangerous;
            }

            if (locationStatus !== undefined) {
                localVarQueryParameter['LocationStatus'] = locationStatus;
            }

            if (containerType !== undefined) {
                localVarQueryParameter['ContainerType'] = containerType;
            }

            if (containerHeight !== undefined) {
                localVarQueryParameter['ContainerHeight'] = containerHeight;
            }

            if (isInTerminal !== undefined) {
                localVarQueryParameter['isInTerminal'] = isInTerminal;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [bayId] 
         * @param {string} [rowId] 
         * @param {boolean} [lockStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLockStatus: async (bayId?: string, rowId?: string, lockStatus?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlockStacks/SetLockStatus/lock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (bayId !== undefined) {
                localVarQueryParameter['bayId'] = bayId;
            }

            if (rowId !== undefined) {
                localVarQueryParameter['rowId'] = rowId;
            }

            if (lockStatus !== undefined) {
                localVarQueryParameter['lockStatus'] = lockStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * YardBlockStacksApi - functional programming interface
 * @export
 */
export const YardBlockStacksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = YardBlockStacksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [containerNumber] 
         * @param {string} [yardBlockId] 
         * @param {string} [yardBlockBayId] 
         * @param {string} [yardBlockRowId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getYardBlockStacks(containerNumber?: string, yardBlockId?: string, yardBlockBayId?: string, yardBlockRowId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StackDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getYardBlockStacks(containerNumber, yardBlockId, yardBlockBayId, yardBlockRowId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [containerOrReferenceNumber] 
         * @param {CarrierType} [inboundCarrierType] 
         * @param {CarrierType} [outboundCarrierType] 
         * @param {number} [inboundCarrier] 
         * @param {number} [outboundCarrier] 
         * @param {boolean} [isEmpty] 
         * @param {number} [size] 
         * @param {string} [containerIsoCode] 
         * @param {string} [typeCode] 
         * @param {string} [portOfLoading] 
         * @param {string} [portOfDischarge] 
         * @param {Array<string>} [weightClasses] 
         * @param {boolean} [isReefer] 
         * @param {boolean} [isDamaged] 
         * @param {Array<string>} [imoClasses] 
         * @param {boolean} [isRestow] 
         * @param {string} [containerOperator] 
         * @param {string} [consignee] 
         * @param {boolean} [isDangerous] 
         * @param {ContainerStatus} [locationStatus] 
         * @param {ContainerType} [containerType] 
         * @param {ContainerHeight} [containerHeight] 
         * @param {boolean} [isInTerminal] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getYardBlockStacksAdvanced(containerOrReferenceNumber?: string, inboundCarrierType?: CarrierType, outboundCarrierType?: CarrierType, inboundCarrier?: number, outboundCarrier?: number, isEmpty?: boolean, size?: number, containerIsoCode?: string, typeCode?: string, portOfLoading?: string, portOfDischarge?: string, weightClasses?: Array<string>, isReefer?: boolean, isDamaged?: boolean, imoClasses?: Array<string>, isRestow?: boolean, containerOperator?: string, consignee?: string, isDangerous?: boolean, locationStatus?: ContainerStatus, containerType?: ContainerType, containerHeight?: ContainerHeight, isInTerminal?: boolean, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StackDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getYardBlockStacksAdvanced(containerOrReferenceNumber, inboundCarrierType, outboundCarrierType, inboundCarrier, outboundCarrier, isEmpty, size, containerIsoCode, typeCode, portOfLoading, portOfDischarge, weightClasses, isReefer, isDamaged, imoClasses, isRestow, containerOperator, consignee, isDangerous, locationStatus, containerType, containerHeight, isInTerminal, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [bayId] 
         * @param {string} [rowId] 
         * @param {boolean} [lockStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setLockStatus(bayId?: string, rowId?: string, lockStatus?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlotDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setLockStatus(bayId, rowId, lockStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * YardBlockStacksApi - factory interface
 * @export
 */
export const YardBlockStacksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = YardBlockStacksApiFp(configuration)
    return {
        /**
         * 
         * @param {YardBlockStacksApiGetYardBlockStacksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYardBlockStacks(requestParameters: YardBlockStacksApiGetYardBlockStacksRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<StackDto>> {
            return localVarFp.getYardBlockStacks(requestParameters.containerNumber, requestParameters.yardBlockId, requestParameters.yardBlockBayId, requestParameters.yardBlockRowId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlockStacksApiGetYardBlockStacksAdvancedRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYardBlockStacksAdvanced(requestParameters: YardBlockStacksApiGetYardBlockStacksAdvancedRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<StackDto>> {
            return localVarFp.getYardBlockStacksAdvanced(requestParameters.containerOrReferenceNumber, requestParameters.inboundCarrierType, requestParameters.outboundCarrierType, requestParameters.inboundCarrier, requestParameters.outboundCarrier, requestParameters.isEmpty, requestParameters.size, requestParameters.containerIsoCode, requestParameters.typeCode, requestParameters.portOfLoading, requestParameters.portOfDischarge, requestParameters.weightClasses, requestParameters.isReefer, requestParameters.isDamaged, requestParameters.imoClasses, requestParameters.isRestow, requestParameters.containerOperator, requestParameters.consignee, requestParameters.isDangerous, requestParameters.locationStatus, requestParameters.containerType, requestParameters.containerHeight, requestParameters.isInTerminal, requestParameters.customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlockStacksApiSetLockStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLockStatus(requestParameters: YardBlockStacksApiSetLockStatusRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<SlotDto>> {
            return localVarFp.setLockStatus(requestParameters.bayId, requestParameters.rowId, requestParameters.lockStatus, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getYardBlockStacks operation in YardBlockStacksApi.
 * @export
 * @interface YardBlockStacksApiGetYardBlockStacksRequest
 */
export interface YardBlockStacksApiGetYardBlockStacksRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockStacksApiGetYardBlockStacks
     */
    readonly containerNumber?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlockStacksApiGetYardBlockStacks
     */
    readonly yardBlockId?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlockStacksApiGetYardBlockStacks
     */
    readonly yardBlockBayId?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlockStacksApiGetYardBlockStacks
     */
    readonly yardBlockRowId?: string
}

/**
 * Request parameters for getYardBlockStacksAdvanced operation in YardBlockStacksApi.
 * @export
 * @interface YardBlockStacksApiGetYardBlockStacksAdvancedRequest
 */
export interface YardBlockStacksApiGetYardBlockStacksAdvancedRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly containerOrReferenceNumber?: string

    /**
     * 
     * @type {CarrierType}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly inboundCarrierType?: CarrierType

    /**
     * 
     * @type {CarrierType}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly outboundCarrierType?: CarrierType

    /**
     * 
     * @type {number}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly inboundCarrier?: number

    /**
     * 
     * @type {number}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly outboundCarrier?: number

    /**
     * 
     * @type {boolean}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly isEmpty?: boolean

    /**
     * 
     * @type {number}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly containerIsoCode?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly typeCode?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly portOfLoading?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly portOfDischarge?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly weightClasses?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly isReefer?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly isDamaged?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly imoClasses?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly isRestow?: boolean

    /**
     * 
     * @type {string}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly containerOperator?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly consignee?: string

    /**
     * 
     * @type {boolean}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly isDangerous?: boolean

    /**
     * 
     * @type {ContainerStatus}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly locationStatus?: ContainerStatus

    /**
     * 
     * @type {ContainerType}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly containerType?: ContainerType

    /**
     * 
     * @type {ContainerHeight}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly containerHeight?: ContainerHeight

    /**
     * 
     * @type {boolean}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly isInTerminal?: boolean

    /**
     * 
     * @type {number}
     * @memberof YardBlockStacksApiGetYardBlockStacksAdvanced
     */
    readonly customerId?: number
}

/**
 * Request parameters for setLockStatus operation in YardBlockStacksApi.
 * @export
 * @interface YardBlockStacksApiSetLockStatusRequest
 */
export interface YardBlockStacksApiSetLockStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlockStacksApiSetLockStatus
     */
    readonly bayId?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlockStacksApiSetLockStatus
     */
    readonly rowId?: string

    /**
     * 
     * @type {boolean}
     * @memberof YardBlockStacksApiSetLockStatus
     */
    readonly lockStatus?: boolean
}

/**
 * YardBlockStacksApi - object-oriented interface
 * @export
 * @class YardBlockStacksApi
 * @extends {BaseAPI}
 */
export class YardBlockStacksApi extends BaseAPI {
    /**
     * 
     * @param {YardBlockStacksApiGetYardBlockStacksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockStacksApi
     */
    public getYardBlockStacks(requestParameters: YardBlockStacksApiGetYardBlockStacksRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockStacksApiFp(this.configuration).getYardBlockStacks(requestParameters.containerNumber, requestParameters.yardBlockId, requestParameters.yardBlockBayId, requestParameters.yardBlockRowId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlockStacksApiGetYardBlockStacksAdvancedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockStacksApi
     */
    public getYardBlockStacksAdvanced(requestParameters: YardBlockStacksApiGetYardBlockStacksAdvancedRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockStacksApiFp(this.configuration).getYardBlockStacksAdvanced(requestParameters.containerOrReferenceNumber, requestParameters.inboundCarrierType, requestParameters.outboundCarrierType, requestParameters.inboundCarrier, requestParameters.outboundCarrier, requestParameters.isEmpty, requestParameters.size, requestParameters.containerIsoCode, requestParameters.typeCode, requestParameters.portOfLoading, requestParameters.portOfDischarge, requestParameters.weightClasses, requestParameters.isReefer, requestParameters.isDamaged, requestParameters.imoClasses, requestParameters.isRestow, requestParameters.containerOperator, requestParameters.consignee, requestParameters.isDangerous, requestParameters.locationStatus, requestParameters.containerType, requestParameters.containerHeight, requestParameters.isInTerminal, requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlockStacksApiSetLockStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlockStacksApi
     */
    public setLockStatus(requestParameters: YardBlockStacksApiSetLockStatusRequest = {}, options?: AxiosRequestConfig) {
        return YardBlockStacksApiFp(this.configuration).setLockStatus(requestParameters.bayId, requestParameters.rowId, requestParameters.lockStatus, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * YardBlocksApi - axios parameter creator
 * @export
 */
export const YardBlocksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [yardBlockId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (yardBlockId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlocks/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (yardBlockId !== undefined) {
                localVarQueryParameter['yardBlockId'] = yardBlockId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {YardBlocksCreateRequest} [yardBlocksCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (yardBlocksCreateRequest?: YardBlocksCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlocks/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(yardBlocksCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id)
            const localVarPath = `/storage/api/YardBlocks/Get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlocks/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [yardBlockId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlockInconsistencies: async (yardBlockId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlocks/GetBlockInconsistencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (yardBlockId !== undefined) {
                localVarQueryParameter['yardBlockId'] = yardBlockId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [containerNumbers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainersBlockNames: async (containerNumbers?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlocks/GetContainersBlockNames`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (containerNumbers) {
                localVarQueryParameter['ContainerNumbers'] = containerNumbers;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlocks/GetSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [containerOrReferenceNumber] 
         * @param {CarrierType} [inboundCarrierType] 
         * @param {CarrierType} [outboundCarrierType] 
         * @param {number} [inboundCarrier] 
         * @param {number} [outboundCarrier] 
         * @param {boolean} [isEmpty] 
         * @param {number} [size] 
         * @param {string} [containerIsoCode] 
         * @param {string} [typeCode] 
         * @param {string} [portOfLoading] 
         * @param {string} [portOfDischarge] 
         * @param {Array<string>} [weightClasses] 
         * @param {boolean} [isReefer] 
         * @param {boolean} [isDamaged] 
         * @param {Array<string>} [imoClasses] 
         * @param {boolean} [isRestow] 
         * @param {string} [containerOperator] 
         * @param {string} [consignee] 
         * @param {boolean} [isDangerous] 
         * @param {ContainerStatus} [locationStatus] 
         * @param {ContainerType} [containerType] 
         * @param {ContainerHeight} [containerHeight] 
         * @param {boolean} [isInTerminal] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYardBlockDetails: async (containerOrReferenceNumber?: string, inboundCarrierType?: CarrierType, outboundCarrierType?: CarrierType, inboundCarrier?: number, outboundCarrier?: number, isEmpty?: boolean, size?: number, containerIsoCode?: string, typeCode?: string, portOfLoading?: string, portOfDischarge?: string, weightClasses?: Array<string>, isReefer?: boolean, isDamaged?: boolean, imoClasses?: Array<string>, isRestow?: boolean, containerOperator?: string, consignee?: string, isDangerous?: boolean, locationStatus?: ContainerStatus, containerType?: ContainerType, containerHeight?: ContainerHeight, isInTerminal?: boolean, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlocks/GetYardBlockDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (containerOrReferenceNumber !== undefined) {
                localVarQueryParameter['ContainerOrReferenceNumber'] = containerOrReferenceNumber;
            }

            if (inboundCarrierType !== undefined) {
                localVarQueryParameter['InboundCarrierType'] = inboundCarrierType;
            }

            if (outboundCarrierType !== undefined) {
                localVarQueryParameter['OutboundCarrierType'] = outboundCarrierType;
            }

            if (inboundCarrier !== undefined) {
                localVarQueryParameter['InboundCarrier'] = inboundCarrier;
            }

            if (outboundCarrier !== undefined) {
                localVarQueryParameter['OutboundCarrier'] = outboundCarrier;
            }

            if (isEmpty !== undefined) {
                localVarQueryParameter['IsEmpty'] = isEmpty;
            }

            if (size !== undefined) {
                localVarQueryParameter['Size'] = size;
            }

            if (containerIsoCode !== undefined) {
                localVarQueryParameter['ContainerIsoCode'] = containerIsoCode;
            }

            if (typeCode !== undefined) {
                localVarQueryParameter['TypeCode'] = typeCode;
            }

            if (portOfLoading !== undefined) {
                localVarQueryParameter['PortOfLoading'] = portOfLoading;
            }

            if (portOfDischarge !== undefined) {
                localVarQueryParameter['PortOfDischarge'] = portOfDischarge;
            }

            if (weightClasses) {
                localVarQueryParameter['WeightClasses'] = weightClasses;
            }

            if (isReefer !== undefined) {
                localVarQueryParameter['IsReefer'] = isReefer;
            }

            if (isDamaged !== undefined) {
                localVarQueryParameter['IsDamaged'] = isDamaged;
            }

            if (imoClasses) {
                localVarQueryParameter['ImoClasses'] = imoClasses;
            }

            if (isRestow !== undefined) {
                localVarQueryParameter['IsRestow'] = isRestow;
            }

            if (containerOperator !== undefined) {
                localVarQueryParameter['ContainerOperator'] = containerOperator;
            }

            if (consignee !== undefined) {
                localVarQueryParameter['Consignee'] = consignee;
            }

            if (isDangerous !== undefined) {
                localVarQueryParameter['IsDangerous'] = isDangerous;
            }

            if (locationStatus !== undefined) {
                localVarQueryParameter['LocationStatus'] = locationStatus;
            }

            if (containerType !== undefined) {
                localVarQueryParameter['ContainerType'] = containerType;
            }

            if (containerHeight !== undefined) {
                localVarQueryParameter['ContainerHeight'] = containerHeight;
            }

            if (isInTerminal !== undefined) {
                localVarQueryParameter['isInTerminal'] = isInTerminal;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['CustomerId'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYardBlockSummaries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlocks/GetYardBlockSummaries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYardBlockSummary: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlocks/GetYardBlockSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYardInconsistencies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlocks/GetYardInconsistencies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUnique: async (name?: string, id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlocks/IsUnique`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [yardBlockId] 
         * @param {number} [fromTier] 
         * @param {boolean} [lockStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLockStatus: async (yardBlockId?: string, fromTier?: number, lockStatus?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlocks/SetLockStatus/lock`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)

            if (yardBlockId !== undefined) {
                localVarQueryParameter['yardBlockId'] = yardBlockId;
            }

            if (fromTier !== undefined) {
                localVarQueryParameter['fromTier'] = fromTier;
            }

            if (lockStatus !== undefined) {
                localVarQueryParameter['lockStatus'] = lockStatus;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {YardBlocksUpdateRequest} [yardBlocksUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (yardBlocksUpdateRequest?: YardBlocksUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardBlocks/Update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(yardBlocksUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * YardBlocksApi - functional programming interface
 * @export
 */
export const YardBlocksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = YardBlocksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [yardBlockId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(yardBlockId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(yardBlockId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {YardBlocksCreateRequest} [yardBlocksCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(yardBlocksCreateRequest?: YardBlocksCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YardBlockDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(yardBlocksCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YardBlockDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<YardBlockDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [yardBlockId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBlockInconsistencies(yardBlockId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<YardBlockInconsistencyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBlockInconsistencies(yardBlockId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} [containerNumbers] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContainersBlockNames(containerNumbers?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ContainerBlockInfoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContainersBlockNames(containerNumbers, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSummary(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YardSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSummary(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [containerOrReferenceNumber] 
         * @param {CarrierType} [inboundCarrierType] 
         * @param {CarrierType} [outboundCarrierType] 
         * @param {number} [inboundCarrier] 
         * @param {number} [outboundCarrier] 
         * @param {boolean} [isEmpty] 
         * @param {number} [size] 
         * @param {string} [containerIsoCode] 
         * @param {string} [typeCode] 
         * @param {string} [portOfLoading] 
         * @param {string} [portOfDischarge] 
         * @param {Array<string>} [weightClasses] 
         * @param {boolean} [isReefer] 
         * @param {boolean} [isDamaged] 
         * @param {Array<string>} [imoClasses] 
         * @param {boolean} [isRestow] 
         * @param {string} [containerOperator] 
         * @param {string} [consignee] 
         * @param {boolean} [isDangerous] 
         * @param {ContainerStatus} [locationStatus] 
         * @param {ContainerType} [containerType] 
         * @param {ContainerHeight} [containerHeight] 
         * @param {boolean} [isInTerminal] 
         * @param {number} [customerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getYardBlockDetails(containerOrReferenceNumber?: string, inboundCarrierType?: CarrierType, outboundCarrierType?: CarrierType, inboundCarrier?: number, outboundCarrier?: number, isEmpty?: boolean, size?: number, containerIsoCode?: string, typeCode?: string, portOfLoading?: string, portOfDischarge?: string, weightClasses?: Array<string>, isReefer?: boolean, isDamaged?: boolean, imoClasses?: Array<string>, isRestow?: boolean, containerOperator?: string, consignee?: string, isDangerous?: boolean, locationStatus?: ContainerStatus, containerType?: ContainerType, containerHeight?: ContainerHeight, isInTerminal?: boolean, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<YardBlockDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getYardBlockDetails(containerOrReferenceNumber, inboundCarrierType, outboundCarrierType, inboundCarrier, outboundCarrier, isEmpty, size, containerIsoCode, typeCode, portOfLoading, portOfDischarge, weightClasses, isReefer, isDamaged, imoClasses, isRestow, containerOperator, consignee, isDangerous, locationStatus, containerType, containerHeight, isInTerminal, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getYardBlockSummaries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<YardBlockInformationDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getYardBlockSummaries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getYardBlockSummary(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YardBlockInformationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getYardBlockSummary(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getYardInconsistencies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<YardInconsistencyDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getYardInconsistencies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isUnique(name?: string, id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isUnique(name, id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [yardBlockId] 
         * @param {number} [fromTier] 
         * @param {boolean} [lockStatus] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setLockStatus(yardBlockId?: string, fromTier?: number, lockStatus?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlotDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setLockStatus(yardBlockId, fromTier, lockStatus, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {YardBlocksUpdateRequest} [yardBlocksUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(yardBlocksUpdateRequest?: YardBlocksUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<YardBlockDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(yardBlocksUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * YardBlocksApi - factory interface
 * @export
 */
export const YardBlocksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = YardBlocksApiFp(configuration)
    return {
        /**
         * 
         * @param {YardBlocksApiDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(requestParameters: YardBlocksApiDeleteRequest = {}, options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp._delete(requestParameters.yardBlockId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlocksApiCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestParameters: YardBlocksApiCreateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<YardBlockDto> {
            return localVarFp.create(requestParameters.yardBlocksCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlocksApiGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(requestParameters: YardBlocksApiGetRequest, options?: AxiosRequestConfig): AxiosPromise<YardBlockDto> {
            return localVarFp.get(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: AxiosRequestConfig): AxiosPromise<Array<YardBlockDto>> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlocksApiGetBlockInconsistenciesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBlockInconsistencies(requestParameters: YardBlocksApiGetBlockInconsistenciesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<YardBlockInconsistencyDto>> {
            return localVarFp.getBlockInconsistencies(requestParameters.yardBlockId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlocksApiGetContainersBlockNamesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContainersBlockNames(requestParameters: YardBlocksApiGetContainersBlockNamesRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<ContainerBlockInfoDto>> {
            return localVarFp.getContainersBlockNames(requestParameters.containerNumbers, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummary(options?: AxiosRequestConfig): AxiosPromise<YardSummaryDto> {
            return localVarFp.getSummary(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlocksApiGetYardBlockDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYardBlockDetails(requestParameters: YardBlocksApiGetYardBlockDetailsRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<YardBlockDetailsDto>> {
            return localVarFp.getYardBlockDetails(requestParameters.containerOrReferenceNumber, requestParameters.inboundCarrierType, requestParameters.outboundCarrierType, requestParameters.inboundCarrier, requestParameters.outboundCarrier, requestParameters.isEmpty, requestParameters.size, requestParameters.containerIsoCode, requestParameters.typeCode, requestParameters.portOfLoading, requestParameters.portOfDischarge, requestParameters.weightClasses, requestParameters.isReefer, requestParameters.isDamaged, requestParameters.imoClasses, requestParameters.isRestow, requestParameters.containerOperator, requestParameters.consignee, requestParameters.isDangerous, requestParameters.locationStatus, requestParameters.containerType, requestParameters.containerHeight, requestParameters.isInTerminal, requestParameters.customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYardBlockSummaries(options?: AxiosRequestConfig): AxiosPromise<Array<YardBlockInformationDto>> {
            return localVarFp.getYardBlockSummaries(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlocksApiGetYardBlockSummaryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYardBlockSummary(requestParameters: YardBlocksApiGetYardBlockSummaryRequest = {}, options?: AxiosRequestConfig): AxiosPromise<YardBlockInformationDto> {
            return localVarFp.getYardBlockSummary(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYardInconsistencies(options?: AxiosRequestConfig): AxiosPromise<Array<YardInconsistencyDto>> {
            return localVarFp.getYardInconsistencies(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlocksApiIsUniqueRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isUnique(requestParameters: YardBlocksApiIsUniqueRequest = {}, options?: AxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.isUnique(requestParameters.name, requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlocksApiSetLockStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLockStatus(requestParameters: YardBlocksApiSetLockStatusRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<SlotDto>> {
            return localVarFp.setLockStatus(requestParameters.yardBlockId, requestParameters.fromTier, requestParameters.lockStatus, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardBlocksApiUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(requestParameters: YardBlocksApiUpdateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<YardBlockDto> {
            return localVarFp.update(requestParameters.yardBlocksUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for _delete operation in YardBlocksApi.
 * @export
 * @interface YardBlocksApiDeleteRequest
 */
export interface YardBlocksApiDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlocksApiDelete
     */
    readonly yardBlockId?: string
}

/**
 * Request parameters for create operation in YardBlocksApi.
 * @export
 * @interface YardBlocksApiCreateRequest
 */
export interface YardBlocksApiCreateRequest {
    /**
     * 
     * @type {YardBlocksCreateRequest}
     * @memberof YardBlocksApiCreate
     */
    readonly yardBlocksCreateRequest?: YardBlocksCreateRequest
}

/**
 * Request parameters for get operation in YardBlocksApi.
 * @export
 * @interface YardBlocksApiGetRequest
 */
export interface YardBlocksApiGetRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlocksApiGet
     */
    readonly id: string
}

/**
 * Request parameters for getBlockInconsistencies operation in YardBlocksApi.
 * @export
 * @interface YardBlocksApiGetBlockInconsistenciesRequest
 */
export interface YardBlocksApiGetBlockInconsistenciesRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlocksApiGetBlockInconsistencies
     */
    readonly yardBlockId?: string
}

/**
 * Request parameters for getContainersBlockNames operation in YardBlocksApi.
 * @export
 * @interface YardBlocksApiGetContainersBlockNamesRequest
 */
export interface YardBlocksApiGetContainersBlockNamesRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof YardBlocksApiGetContainersBlockNames
     */
    readonly containerNumbers?: Array<string>
}

/**
 * Request parameters for getYardBlockDetails operation in YardBlocksApi.
 * @export
 * @interface YardBlocksApiGetYardBlockDetailsRequest
 */
export interface YardBlocksApiGetYardBlockDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly containerOrReferenceNumber?: string

    /**
     * 
     * @type {CarrierType}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly inboundCarrierType?: CarrierType

    /**
     * 
     * @type {CarrierType}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly outboundCarrierType?: CarrierType

    /**
     * 
     * @type {number}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly inboundCarrier?: number

    /**
     * 
     * @type {number}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly outboundCarrier?: number

    /**
     * 
     * @type {boolean}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly isEmpty?: boolean

    /**
     * 
     * @type {number}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly containerIsoCode?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly typeCode?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly portOfLoading?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly portOfDischarge?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly weightClasses?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly isReefer?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly isDamaged?: boolean

    /**
     * 
     * @type {Array<string>}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly imoClasses?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly isRestow?: boolean

    /**
     * 
     * @type {string}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly containerOperator?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly consignee?: string

    /**
     * 
     * @type {boolean}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly isDangerous?: boolean

    /**
     * 
     * @type {ContainerStatus}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly locationStatus?: ContainerStatus

    /**
     * 
     * @type {ContainerType}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly containerType?: ContainerType

    /**
     * 
     * @type {ContainerHeight}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly containerHeight?: ContainerHeight

    /**
     * 
     * @type {boolean}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly isInTerminal?: boolean

    /**
     * 
     * @type {number}
     * @memberof YardBlocksApiGetYardBlockDetails
     */
    readonly customerId?: number
}

/**
 * Request parameters for getYardBlockSummary operation in YardBlocksApi.
 * @export
 * @interface YardBlocksApiGetYardBlockSummaryRequest
 */
export interface YardBlocksApiGetYardBlockSummaryRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlocksApiGetYardBlockSummary
     */
    readonly id?: string
}

/**
 * Request parameters for isUnique operation in YardBlocksApi.
 * @export
 * @interface YardBlocksApiIsUniqueRequest
 */
export interface YardBlocksApiIsUniqueRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlocksApiIsUnique
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof YardBlocksApiIsUnique
     */
    readonly id?: string
}

/**
 * Request parameters for setLockStatus operation in YardBlocksApi.
 * @export
 * @interface YardBlocksApiSetLockStatusRequest
 */
export interface YardBlocksApiSetLockStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof YardBlocksApiSetLockStatus
     */
    readonly yardBlockId?: string

    /**
     * 
     * @type {number}
     * @memberof YardBlocksApiSetLockStatus
     */
    readonly fromTier?: number

    /**
     * 
     * @type {boolean}
     * @memberof YardBlocksApiSetLockStatus
     */
    readonly lockStatus?: boolean
}

/**
 * Request parameters for update operation in YardBlocksApi.
 * @export
 * @interface YardBlocksApiUpdateRequest
 */
export interface YardBlocksApiUpdateRequest {
    /**
     * 
     * @type {YardBlocksUpdateRequest}
     * @memberof YardBlocksApiUpdate
     */
    readonly yardBlocksUpdateRequest?: YardBlocksUpdateRequest
}

/**
 * YardBlocksApi - object-oriented interface
 * @export
 * @class YardBlocksApi
 * @extends {BaseAPI}
 */
export class YardBlocksApi extends BaseAPI {
    /**
     * 
     * @param {YardBlocksApiDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlocksApi
     */
    public _delete(requestParameters: YardBlocksApiDeleteRequest = {}, options?: AxiosRequestConfig) {
        return YardBlocksApiFp(this.configuration)._delete(requestParameters.yardBlockId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlocksApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlocksApi
     */
    public create(requestParameters: YardBlocksApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return YardBlocksApiFp(this.configuration).create(requestParameters.yardBlocksCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlocksApiGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlocksApi
     */
    public get(requestParameters: YardBlocksApiGetRequest, options?: AxiosRequestConfig) {
        return YardBlocksApiFp(this.configuration).get(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlocksApi
     */
    public getAll(options?: AxiosRequestConfig) {
        return YardBlocksApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlocksApiGetBlockInconsistenciesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlocksApi
     */
    public getBlockInconsistencies(requestParameters: YardBlocksApiGetBlockInconsistenciesRequest = {}, options?: AxiosRequestConfig) {
        return YardBlocksApiFp(this.configuration).getBlockInconsistencies(requestParameters.yardBlockId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlocksApiGetContainersBlockNamesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlocksApi
     */
    public getContainersBlockNames(requestParameters: YardBlocksApiGetContainersBlockNamesRequest = {}, options?: AxiosRequestConfig) {
        return YardBlocksApiFp(this.configuration).getContainersBlockNames(requestParameters.containerNumbers, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlocksApi
     */
    public getSummary(options?: AxiosRequestConfig) {
        return YardBlocksApiFp(this.configuration).getSummary(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlocksApiGetYardBlockDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlocksApi
     */
    public getYardBlockDetails(requestParameters: YardBlocksApiGetYardBlockDetailsRequest = {}, options?: AxiosRequestConfig) {
        return YardBlocksApiFp(this.configuration).getYardBlockDetails(requestParameters.containerOrReferenceNumber, requestParameters.inboundCarrierType, requestParameters.outboundCarrierType, requestParameters.inboundCarrier, requestParameters.outboundCarrier, requestParameters.isEmpty, requestParameters.size, requestParameters.containerIsoCode, requestParameters.typeCode, requestParameters.portOfLoading, requestParameters.portOfDischarge, requestParameters.weightClasses, requestParameters.isReefer, requestParameters.isDamaged, requestParameters.imoClasses, requestParameters.isRestow, requestParameters.containerOperator, requestParameters.consignee, requestParameters.isDangerous, requestParameters.locationStatus, requestParameters.containerType, requestParameters.containerHeight, requestParameters.isInTerminal, requestParameters.customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlocksApi
     */
    public getYardBlockSummaries(options?: AxiosRequestConfig) {
        return YardBlocksApiFp(this.configuration).getYardBlockSummaries(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlocksApiGetYardBlockSummaryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlocksApi
     */
    public getYardBlockSummary(requestParameters: YardBlocksApiGetYardBlockSummaryRequest = {}, options?: AxiosRequestConfig) {
        return YardBlocksApiFp(this.configuration).getYardBlockSummary(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlocksApi
     */
    public getYardInconsistencies(options?: AxiosRequestConfig) {
        return YardBlocksApiFp(this.configuration).getYardInconsistencies(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlocksApiIsUniqueRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlocksApi
     */
    public isUnique(requestParameters: YardBlocksApiIsUniqueRequest = {}, options?: AxiosRequestConfig) {
        return YardBlocksApiFp(this.configuration).isUnique(requestParameters.name, requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlocksApiSetLockStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlocksApi
     */
    public setLockStatus(requestParameters: YardBlocksApiSetLockStatusRequest = {}, options?: AxiosRequestConfig) {
        return YardBlocksApiFp(this.configuration).setLockStatus(requestParameters.yardBlockId, requestParameters.fromTier, requestParameters.lockStatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardBlocksApiUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardBlocksApi
     */
    public update(requestParameters: YardBlocksApiUpdateRequest = {}, options?: AxiosRequestConfig) {
        return YardBlocksApiFp(this.configuration).update(requestParameters.yardBlocksUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * YardInternalMovesApi - axios parameter creator
 * @export
 */
export const YardInternalMovesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {YardInternalMovesCreateRequest} [yardInternalMovesCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (yardInternalMovesCreateRequest?: YardInternalMovesCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardInternalMoves/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(yardInternalMovesCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {YardInternalMovesCreateMoveRequest} [yardInternalMovesCreateMoveRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMove: async (yardInternalMovesCreateMoveRequest?: YardInternalMovesCreateMoveRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/storage/api/YardInternalMoves/CreateMove`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "Bearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(yardInternalMovesCreateMoveRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * YardInternalMovesApi - functional programming interface
 * @export
 */
export const YardInternalMovesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = YardInternalMovesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {YardInternalMovesCreateRequest} [yardInternalMovesCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(yardInternalMovesCreateRequest?: YardInternalMovesCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<YardInternalMoveDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(yardInternalMovesCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {YardInternalMovesCreateMoveRequest} [yardInternalMovesCreateMoveRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMove(yardInternalMovesCreateMoveRequest?: YardInternalMovesCreateMoveRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMove(yardInternalMovesCreateMoveRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * YardInternalMovesApi - factory interface
 * @export
 */
export const YardInternalMovesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = YardInternalMovesApiFp(configuration)
    return {
        /**
         * 
         * @param {YardInternalMovesApiCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestParameters: YardInternalMovesApiCreateRequest = {}, options?: AxiosRequestConfig): AxiosPromise<Array<YardInternalMoveDto>> {
            return localVarFp.create(requestParameters.yardInternalMovesCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {YardInternalMovesApiCreateMoveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMove(requestParameters: YardInternalMovesApiCreateMoveRequest = {}, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createMove(requestParameters.yardInternalMovesCreateMoveRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for create operation in YardInternalMovesApi.
 * @export
 * @interface YardInternalMovesApiCreateRequest
 */
export interface YardInternalMovesApiCreateRequest {
    /**
     * 
     * @type {YardInternalMovesCreateRequest}
     * @memberof YardInternalMovesApiCreate
     */
    readonly yardInternalMovesCreateRequest?: YardInternalMovesCreateRequest
}

/**
 * Request parameters for createMove operation in YardInternalMovesApi.
 * @export
 * @interface YardInternalMovesApiCreateMoveRequest
 */
export interface YardInternalMovesApiCreateMoveRequest {
    /**
     * 
     * @type {YardInternalMovesCreateMoveRequest}
     * @memberof YardInternalMovesApiCreateMove
     */
    readonly yardInternalMovesCreateMoveRequest?: YardInternalMovesCreateMoveRequest
}

/**
 * YardInternalMovesApi - object-oriented interface
 * @export
 * @class YardInternalMovesApi
 * @extends {BaseAPI}
 */
export class YardInternalMovesApi extends BaseAPI {
    /**
     * 
     * @param {YardInternalMovesApiCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardInternalMovesApi
     */
    public create(requestParameters: YardInternalMovesApiCreateRequest = {}, options?: AxiosRequestConfig) {
        return YardInternalMovesApiFp(this.configuration).create(requestParameters.yardInternalMovesCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {YardInternalMovesApiCreateMoveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YardInternalMovesApi
     */
    public createMove(requestParameters: YardInternalMovesApiCreateMoveRequest = {}, options?: AxiosRequestConfig) {
        return YardInternalMovesApiFp(this.configuration).createMove(requestParameters.yardInternalMovesCreateMoveRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


