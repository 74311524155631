import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment, useMinimalsTheme } from '@tom-ui/ui'
import { matchSorter } from 'match-sorter'
import { observer } from 'mobx-react-lite'
import { ImageUploader } from 'modules/utils/src/components/ImageUploader/ImageUploader'
import { UploadedImageList } from 'modules/utils/src/components/ImageUploader/UploadedImageList'
import { useState } from 'react'
import {
  Control,
  Controller,
  FormState,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
} from 'react-hook-form'
import {
  getDefaultContainerDamage,
  IContainerDamage,
  IContainerDamageFormData,
  IContainerDamageOption,
} from './ContainerDamage.model'

interface IProps {
  index: number
  control: Control<IContainerDamageFormData>
  formState: FormState<IContainerDamageFormData>
  previousDamageCount: number
  hasDamageCatalogue?: boolean
  containerDamageLocations: IContainerDamageOption[]
  containerDamageTypes: IContainerDamageOption[]
  canRemoveDamageFields?: boolean
  removeDamage: UseFieldArrayRemove
  clearFields: UseFieldArrayUpdate<IContainerDamageFormData, 'damagesReported'>
}
export const ContainerDamageReportFields = observer(
  ({
    control,
    formState: { errors, isDirty },
    index,
    previousDamageCount,
    hasDamageCatalogue,
    containerDamageLocations,
    containerDamageTypes,
    canRemoveDamageFields,
    removeDamage,
    clearFields,
  }: IProps) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const [selectedTypes, setSelectedTypes] = useState<IContainerDamageOption[]>([])

    const hasErrorForField = (key: keyof IContainerDamage): boolean => {
      const damageError = errors.damagesReported && errors.damagesReported[index]

      return !!(damageError && damageError[key])
    }

    const getErrorMessage = (key: keyof IContainerDamage) => {
      if (hasErrorForField(key)) return t('fieldIsRequired', 'Field is required.')

      return undefined
    }

    const filterOptions = (
      options: IContainerDamageOption[],
      { inputValue }: { inputValue: string },
    ) => {
      return matchSorter(options, inputValue.replace(/\s/g, ''), {
        sorter: rankedItems => rankedItems,
        keys: [item => item.name.replace(/\./g, '').replace(/\s/g, '')],
        threshold: matchSorter.rankings.CONTAINS,
      })
    }

    return (
      <>
        {hasDamageCatalogue && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'
                height={theme.customSpacing.m}
              >
                <Typography variant='subtitle1'>
                  {t('damage', 'Damage')} {index + previousDamageCount + 1}
                </Typography>
                <Stack flexDirection='row' gap={2}>
                  {isDirty && (
                    <Button
                      color='inherit'
                      onClick={() => clearFields(index, getDefaultContainerDamage())}
                    >
                      {t('clearAll', 'Clear all')}
                    </Button>
                  )}
                  {(canRemoveDamageFields || index > 0 || previousDamageCount > 0) && (
                    <Button color='inherit' onClick={() => removeDamage(index)}>
                      {t('delete', 'Delete')}
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={control}
                name={`damagesReported.${index}.containerDamageLocation`}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    value={containerDamageLocations.find(x => value === x.id) ?? null}
                    options={containerDamageLocations}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    onChange={(_, value) => onChange(value?.id)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label={t('damageLocation', 'Damage location')}
                        helperText={getErrorMessage('containerDamageLocation')}
                        error={hasErrorForField('containerDamageLocation')}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box component='li' {...props} key={`location_${option.id}`}>
                        <Stack flexDirection='row' justifyContent='space-between' width='100%'>
                          <Typography>{option.name}</Typography>
                          {option.code && <Typography>{option.code}</Typography>}
                        </Stack>
                      </Box>
                    )}
                    filterOptions={filterOptions}
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name={`damagesReported.${index}.containerDamageTypes`}
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    value={containerDamageTypes.filter(x => value?.includes(x.id))}
                    options={containerDamageTypes}
                    getOptionLabel={option => option.name}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    onChange={(_, value) => {
                      onChange(value.map(x => x.id))
                      setSelectedTypes(value)
                    }}
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          key={`type_selected_${option.id}`}
                          label={option.name}
                        />
                      ))
                    }}
                    renderOption={(props, option, { selected }) => (
                      <Box component='li' {...props} key={`type_${option.id}`}>
                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                        {option.name}
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        required
                        label={t('damageTypes', 'Damage types')}
                        helperText={getErrorMessage('containerDamageTypes')}
                        error={hasErrorForField('containerDamageTypes')}
                      />
                    )}
                    filterOptions={filterOptions}
                    disableCloseOnSelect
                    fullWidth
                    multiple
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Controller
                control={control}
                name={`damagesReported.${index}.size`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant='outlined'
                    fullWidth
                    label={`${t('damageSize', 'Damage size')}`}
                    placeholder={`${t('length', 'Length')} x ${t('width', 'width')} x ${t('height', 'Height')}`}
                    helperText={getErrorMessage('size')}
                    error={hasErrorForField('size')}
                    InputProps={{
                      endAdornment: (
                        <CustomInputAdornment position='end' color='secondary'>
                          cm
                        </CustomInputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        )}

        {(!hasDamageCatalogue || selectedTypes.some(x => x.name.toLowerCase() === 'other')) && (
          <Controller
            control={control}
            name={`damagesReported.${index}.description`}
            rules={{ required: !hasDamageCatalogue }}
            render={({ field }) => (
              <TextField
                {...field}
                variant='outlined'
                fullWidth
                label={`${t('damageDescription', 'Damage description')}`}
                multiline
                minRows={4}
                required={!hasDamageCatalogue}
                helperText={getErrorMessage('description')}
                error={hasErrorForField('description')}
                placeholder={t('typeHereToAddADescription', 'Type here to add a description')}
              />
            )}
          />
        )}

        <Typography variant='subtitle1'>
          {t('selectPicturesOfTheDamage', 'Select pictures of the damage')}
        </Typography>

        <Controller
          name={`damagesReported.${index}.images`}
          control={control}
          render={({ field: { value, onChange } }) => (
            <Stack
              flexDirection='row'
              gap={theme.customSpacing.m}
              justifyContent={value.length ? 'start' : 'center'}
            >
              <UploadedImageList
                damageImageFiles={value}
                onUpdateDamagedFiles={files => {
                  onChange(files)
                }}
              />
              <ImageUploader
                damageImageFiles={value}
                onUpdateDamagedFiles={files => {
                  onChange(files)
                }}
              />
            </Stack>
          )}
        />
      </>
    )
  },
)
