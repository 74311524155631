import { AppViewStore } from '@planning/stores/AppViewStore'
import dayjs from 'dayjs'

export const isNumberWithinRange = (value: number, min: number, max: number) =>
  value >= min && value <= max

export const getDateWithoutSecondsAndMilliseconds = (date?: string) => {
  return dayjs(date).set('seconds', 0).set('milliseconds', 0)
}

export const isDateWithinRange = (date?: string | null, min?: string | null, max?: string | null) =>
  date &&
  min &&
  max &&
  getDateWithoutSecondsAndMilliseconds(date).diff(
    getDateWithoutSecondsAndMilliseconds(min),
    'minutes',
  ) >= 0 &&
  getDateWithoutSecondsAndMilliseconds(date).diff(
    getDateWithoutSecondsAndMilliseconds(max),
    'minutes',
  ) <= 0

export const getPixelsBetweenDates = (
  pixelPerHour: number,
  startDate?: string,
  endDate?: string,
) => {
  const minutes = getDateWithoutSecondsAndMilliseconds(endDate).diff(
    getDateWithoutSecondsAndMilliseconds(startDate),
    'minutes',
  )

  return (minutes / 60) * pixelPerHour
}

export const getPixelsPerHour = (visualizationWidth: number, daysVisualization: number) => {
  const amountOfHoursAvailable = daysVisualization * 24

  return visualizationWidth / amountOfHoursAvailable
}

export const getXAxisAndWidthForVisit = (
  visualizationStartDate: Date,
  visualizationEndDate: Date,
  pixelsPerHour: number,
  eta?: string | null,
  etd?: string | null,
) => {
  const startDateFormatted = dayjs(visualizationStartDate).format()
  const endDate = dayjs(visualizationEndDate).format()

  const visitEndDate = etd ?? ''
  let visitStartDate = eta ?? ''
  let xAxis = getPixelsBetweenDates(pixelsPerHour, startDateFormatted, visitStartDate)

  if (xAxis < 0) {
    xAxis = 0
    visitStartDate = startDateFormatted
  }

  let width = getPixelsBetweenDates(pixelsPerHour, visitStartDate, visitEndDate)
  if (getPixelsBetweenDates(pixelsPerHour, endDate, visitEndDate) > 0) {
    width = getPixelsBetweenDates(pixelsPerHour, visitStartDate, endDate)
  }

  return {
    x: xAxis,
    width: width,
  }
}

export const isDate1BeforeDate2 = (date1?: dayjs.Dayjs, date2?: dayjs.Dayjs) =>
  date1 && date2 && date1.diff(date2, 'minute') < 0

export const validateVisitDates = (
  appViewStore: AppViewStore,
  skipBerthTimestamp: boolean,
  t: any,
  eta?: dayjs.Dayjs,
  etd?: dayjs.Dayjs,
  etb?: dayjs.Dayjs,
) => {
  if (isDate1BeforeDate2(etd, eta)) {
    appViewStore.setShowAlert(
      'warning',
      t('date1IsBeforeDate2', '{date1} cannot be before than {date2}, please adjust the timings', {
        date1: t('etd', 'ETD'),
        date2: t('eta', 'ETA'),
      }),
    )
    return false
  }

  if (!skipBerthTimestamp) {
    if (isDate1BeforeDate2(etd, etb)) {
      appViewStore.setShowAlert(
        'warning',
        t(
          'date1IsBeforeDate2',
          '{date1} cannot be before than {date2}, please adjust the timings',
          {
            date1: t('etd', 'ETD'),
            date2: t('etb', 'ETB'),
          },
        ),
      )
      return false
    }

    if (isDate1BeforeDate2(etb, eta)) {
      appViewStore.setShowAlert(
        'warning',
        t(
          'date1IsBeforeDate2',
          '{date1} cannot be before than {date2}, please adjust the timings',
          {
            date1: t('etb', 'ETB'),
            date2: t('eta', 'ETA'),
          },
        ),
      )
      return false
    }
  }

  return true
}
