import { useTranslate } from '@tolgee/react'
import { IconButton, PlusIcon } from '@tom-ui/ui'
import { FC } from 'react'

type Props = {
  onClick: () => void
}

export const AddIconButton: FC<Props> = ({ onClick }) => {
  const { t } = useTranslate()
  const label = t('add', 'Add')

  return (
    <IconButton aria-label={label} onClick={onClick}>
      <PlusIcon />
    </IconButton>
  )
}
