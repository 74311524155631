import { Button } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { FC } from 'react'

interface IProps {
  formId: string
  onBack?: () => void
}

export const PageHeaderActions: FC<IProps> = ({ formId, onBack }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Stack gap={theme.customSpacing.m} direction={'row'} alignItems={'center'}>
      <Button
        variant='text'
        onClick={() => {
          onBack?.()
        }}
        color='inherit'
      >
        {t('cancel', 'Cancel')}
      </Button>

      <Button variant='contained' color='primary' form={formId} type='submit'>
        {t('save', 'Save')}
      </Button>
    </Stack>
  )
}
