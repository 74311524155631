export const formatContainerNumber = (containerNumber: string) => {
  const container = containerNumber.trim().toUpperCase()
  const ownerCode = container.substring(0, 4)
  const serialNumber = container.substring(4, 10)
  const checkDigit = container.substring(10)

  const formattedContainerNumber = `${ownerCode} ${serialNumber} ${checkDigit}`

  return formattedContainerNumber
}
