import { IconButton, Tooltip } from '@mui/material'
import { Stack } from '@mui/system'
import { CoolingOrderDeviationDto, CoolingOrderDto, OrderStatus } from '@operations/app/api'
import { EntityTable } from '@operations/components/table/EntityTable'
import { CoolingOrderTemperatureCheckWarning } from '@operations/features/coolingOperator/components/CoolingOrderCard/CoolingOrderTemperatureCheckWarning'
import { CoolingOrderUnpluggedTooLongWarning } from '@operations/features/coolingOperator/components/CoolingOrderCard/CoolingOrderUnpluggedTooLongWarning'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, PlusIcon, useMinimalsTheme } from '@tom-ui/ui'
import dayjs from 'dayjs'
import { MRT_ColumnDef } from 'material-react-table'
import { useCallback, useMemo } from 'react'
import { ReeferHistoryTable } from './ReeferHistoryTable'
interface Props {
  items: CoolingOrderDto[]
  onAddItem?: (id?: number, recordId?: number) => Promise<void> | void
}
export const CoolingOrderTable = ({ items, ...handlers }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const getDeviationHelperText = useCallback((deviationDto: CoolingOrderDeviationDto) => {
    if (deviationDto.min && deviationDto.max) {
      return `${deviationDto.min} - ${deviationDto.max}`
    } else {
      return `${deviationDto.ideal}`
    }
  }, [])

  const getAlertMessages = useCallback(
    (dto: CoolingOrderDto) => {
      const messages = []

      if (dto.isPlugInRequired) messages.push(t('plugInRequired', 'Plug in required'))
      if (dto.isPlugOutRequired) messages.push(t('plugOutRequired', 'Plug out required'))
      if (dto.lastRecordedAdditionalInfo?.trim) messages.push(t('hasError', 'Has error'))

      if (dto.temperatureDeviation)
        messages.push(
          `${t('temperatureDeviation', 'Temperature deviation')} (${getDeviationHelperText(dto.temperatureDeviation)})`,
        )

      if (dto.humidityDeviation)
        messages.push(
          `${t('humidityDeviation', 'Humidity deviation')} (${getDeviationHelperText(dto.humidityDeviation)})`,
        )

      if (dto.ventilationDeviation)
        messages.push(
          `${t('ventilationDeviation', 'Ventilation deviation')} (${getDeviationHelperText(dto.ventilationDeviation)})`,
        )

      return messages
    },
    [getDeviationHelperText, t],
  )

  const getLastPlugInDate = useCallback((dto: CoolingOrderDto) => {
    const sortedReefer = dto.reeferTemperatures
      ? [...dto.reeferTemperatures].sort((a, b) => {
          const atA = new Date(a.recordedAt)
          const atB = new Date(b.recordedAt)

          return atB.getTime() - atA.getTime()
        })
      : []

    const recordedAt = sortedReefer?.find(x => x.isPluggedIn)?.recordedAt

    return recordedAt ? dayjs(recordedAt).format('MM/DD/YYYY hh:mm A') : ' - '
  }, [])

  const getLastPlugOutDate = useCallback((dto: CoolingOrderDto) => {
    const sortedReefer = dto.reeferTemperatures
      ? [...dto.reeferTemperatures].sort((a, b) => {
          const atA = new Date(a.recordedAt)
          const atB = new Date(b.recordedAt)

          return atB.getTime() - atA.getTime()
        })
      : []

    const recordedAt = sortedReefer?.find(x => !x.isPluggedIn)?.recordedAt

    return recordedAt ? dayjs(recordedAt).format('MM/DD/YYYY hh:mm A') : ' - '
  }, [])

  const columns = useMemo<MRT_ColumnDef<CoolingOrderDto>[]>(
    () => [
      {
        header: t('containerNo.', 'Container No.'),
        accessorKey: 'containerNumber',
        maxSize: 150,
      },
      {
        header: t('customer', 'Customer'),
        accessorKey: 'customer',
        maxSize: 50,
      },
      {
        header: t('size', 'Size'),
        accessorKey: 'containerLength',
        maxSize: 50,
      },
      {
        header: t('idealTemperature', 'Ideal temperature'),
        accessorKey: 'requestedTemperature',
        maxSize: 170,
      },
      {
        header: t('lastTemperature', 'Last temperature'),
        accessorKey: 'lastRecordedTemperature',
      },
      {
        header: t('lastCheck', 'Last check'),
        accessorFn: row =>
          `${row.lastRecordedTemperatureDateTime ? dayjs(row.lastRecordedTemperatureDateTime).format('MM/DD/YYYY hh:mm A') : '-'}`,
      },
      {
        header: t('lastPlugIn', 'Last plug in'),
        accessorFn: row => getLastPlugInDate(row),
      },
      {
        header: t('lastPlugOut', 'Last plug out'),
        accessorFn: row => getLastPlugOutDate(row),
      },
      {
        header: t('nextCheck', 'Next check'),
        accessorFn: row =>
          `${row.status === OrderStatus.Open && row.nextCheckDateTime ? dayjs(row.nextCheckDateTime).format('MM/DD/YYYY hh:mm A') : '-'}`,
      },
      {
        header: t('status', 'Status'),
        accessorFn: row => (
          <Stack direction='row' gap={theme.customSpacing.s} alignItems='center'>
            {(row.status === OrderStatus.Fulfilled && (
              <ColoredLabel
                key={`${row.id}_fulfilled`}
                color='info'
                label={t('fulfilled', 'Fulfilled')}
              />
            )) ||
              getAlertMessages(row).map(message => (
                <ColoredLabel key={`${row.id}_${message}`} color='warning' label={message} />
              ))}
            {row.status !== OrderStatus.Fulfilled && (
              <CoolingOrderTemperatureCheckWarning order={row} />
            )}
            {row.status !== OrderStatus.Fulfilled && (
              <CoolingOrderUnpluggedTooLongWarning order={row} />
            )}
          </Stack>
        ),
      },
    ],
    [getAlertMessages, t, theme.customSpacing.s],
  )

  const customizeRowActions = (row: any) =>
    handlers.onAddItem && !row.original.deleted && row.original.status === OrderStatus.Open ? (
      <Tooltip arrow placement='left' title={t('addRecordToContainer', 'Add record to container')}>
        <IconButton onClick={() => handlers.onAddItem!(row.original.id)}>
          <PlusIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <></>
    )

  return (
    <EntityTable
      items={items}
      columns={columns}
      customizeRowActions={customizeRowActions}
      {...handlers}
      getRowId={row => row.id.toString()}
      expandElement={order => (
        <ReeferHistoryTable
          coolingOrderId={order.id}
          items={order.reeferTemperatures}
          onEditItem={
            order.deleted || order.status === OrderStatus.Fulfilled ? undefined : handlers.onAddItem
          }
        />
      )}
    ></EntityTable>
  )
}
