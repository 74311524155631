import { DirectionsBoat, DirectionsTransit, LocalShipping } from '@mui/icons-material'
import { SvgIconProps } from '@mui/material'
import { CarrierType } from '@storage/app/api'

const carrierTypeIcons = {
  Truck: LocalShipping,
  Vessel: DirectionsBoat,
  Train: DirectionsTransit,
  Universal: undefined,
  Unknown: undefined,
}

export const get = (carrierType: CarrierType, props?: SvgIconProps) => {
  const Icon = carrierTypeIcons[carrierType]

  return Icon ? <Icon {...props} /> : <></>
}
