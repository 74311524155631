import { UnitType } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { SwapBodyIcon, TrailerIcon } from '@tom-ui/ui'
import { getContainerTypeParameters } from './ContainerTypesMap'

export const useUnitTypeIcon = (unitType?: UnitType) => {
  const { t } = useTranslate()
  const { icon } = getContainerTypeParameters('General', t)

  switch (unitType) {
    case UnitType.SwapBody:
      return <SwapBodyIcon />
    case UnitType.Trailer:
      return <TrailerIcon />
    default:
      return icon
  }
}

export const useUnitTypeLabel = (unitType?: UnitType) => {
  const { t } = useTranslate()

  switch (unitType) {
    case UnitType.SwapBody:
      return t('swapBody', 'Swap Body')
    case UnitType.Trailer:
      return t('trailer', 'Trailer')
    default:
      return t('container', 'Container')
  }
}
