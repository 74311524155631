import { useAdminStore } from '@admin/AppProvider'
import { useTranslate } from '@tolgee/react'
import { appStore } from '@tom-ui/utils'
import { observer } from 'mobx-react-lite'

import { IconButton, Stack, Typography } from '@mui/material'
import { ConfirmationDialog, EditIcon } from '@tom-ui/ui'
import { useEffect, useState } from 'react'
import { TenantReeferMaxUnpluggedTimeFormMapper } from '../components/form/tenant-reefer-max-unplugged-time-form.mapper'
import { TenantReeferMaxUnpluggedTimeFormProfile } from '../components/form/tenant-reefer-max-unplugged-time-form.profile'
import { TenantReeferMaxUnpluggedTimeForm } from '../components/form/TenantReeferMaxUnpluggedTimeForm'

export const TenantReeferMaxUnpluggedTimeContainer = observer(() => {
  const [openEdit, setOpenEdit] = useState(false)
  const { t } = useTranslate()
  const { tenantStore, authStore } = useAdminStore()

  useEffect(() => {
    const pageData = async () => {
      tenantStore.loadOne(Number(authStore.claims.user_metadata.tenantId))
    }
    pageData()
  }, [authStore.claims, tenantStore])

  const tenant = tenantStore.items.filter(
    item => item.id.toString() === authStore.claims.user_metadata.tenantId,
  )[0]

  const handleSubmit = async (formValues: TenantReeferMaxUnpluggedTimeFormProfile) => {
    try {
      await tenantStore.updateReeferContainerMaxUnpluggedTime(
        TenantReeferMaxUnpluggedTimeFormMapper.mapFormValuesToUpdateDto(formValues),
      )
      setOpenEdit(false)
      appStore.setShowAlert(
        'success',
        t(
          'reeferMaxUnpluggedTimeSuccessfullySaved',
          'Reefer max unplugged time successfully saved',
        ),
      )
    } catch (error) {
      appStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  if (!tenant) {
    return <></>
  }

  const reeferContainerMaxUnpluggedTime = tenant?.reeferContainerMaxUnpluggedTime

  return (
    <>
      <Stack spacing={1} direction='row'>
        <Typography variant='body2' color='GrayText'>
          {t('maxPlugOutTime', 'Max plug out time')}:{' '}
          {reeferContainerMaxUnpluggedTime || reeferContainerMaxUnpluggedTime === 0
            ? `${reeferContainerMaxUnpluggedTime}h`
            : 'unset'}
        </Typography>
        <IconButton
          onClick={() => {
            setOpenEdit(true)
          }}
          size='small'
          style={{ padding: 0 }}
          aria-label='edit'
        >
          <EditIcon />
        </IconButton>
      </Stack>

      <ConfirmationDialog
        open={openEdit}
        formId='tenant-reefer-max-unplugged-time-form'
        maxWidth='sm'
        title={t('edit', 'Edit')}
        primaryActionText={t('save', 'Save')}
        closeLabel={t('close', 'Close')}
        onClose={() => {
          setOpenEdit(false)
        }}
        hasBorderTop
      >
        <TenantReeferMaxUnpluggedTimeForm
          id='tenant-reefer-max-unplugged-time-form'
          tenant={tenant}
          onSubmit={handleSubmit}
        />
      </ConfirmationDialog>
    </>
  )
})
