import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { Loader } from '@tom-ui/ui'
import { FC, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppConfiguration } from './AppConfigurationProvider'

export const Auth0ProviderWithHistory: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate()
  const { auth } = useAppConfiguration()

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(appState?.returnTo ?? window.location.pathname)
  }

  if (!auth) return <Loader show />

  return (
    <Auth0Provider
      domain={auth.domain}
      clientId={auth.clientId}
      authorizationParams={{
        audience: auth.audience,
        redirect_uri: `${window.location.origin}`,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}
