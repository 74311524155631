import { ArrowDownward, ArrowUpward } from '@mui/icons-material'
import { Grid, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

export interface ContainerSummary {
  expected: number
  completed: number
}

interface Props {
  containersIn: ContainerSummary
  containersOut: ContainerSummary
  dataCy?: string
}
export const ContainerAggregation = observer(({ containersIn, containersOut, dataCy }: Props) => {
  const { t } = useTranslate()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h4'>{t('containers', 'Containers')}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Paper variant='outlined'>
          <Stack p={4} justifyItems='center' alignItems='center'>
            <ArrowDownward />
            <Typography variant='h3' data-cy={`${dataCy}-Inbound`}>
              {containersIn.completed} / {containersIn.expected}
            </Typography>

            <Typography variant='body2' color='secondary'>
              {t('in', 'In')}
            </Typography>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper variant='outlined'>
          <Stack p={4} justifyItems='center' alignItems='center'>
            <ArrowUpward />
            <Typography variant='h3' data-cy={`${dataCy}-Outbound`}>
              {containersOut.completed} / {containersOut.expected}
            </Typography>

            <Typography variant='body2' color='secondary'>
              {t('out', 'Out')}
            </Typography>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
  )
})
