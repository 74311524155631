import { IssuesApi, ResolveOrderIssueCommand } from '@planning/app/api'
import { createApiClient } from '@planning/app/http-client'

class IssueService {
  httpClient = createApiClient(IssuesApi)

  async getAll() {
    const { data } = await this.httpClient.getAll()
    return data
  }

  async resolveOrderIssue(cmd: ResolveOrderIssueCommand) {
    await this.httpClient.resolveOrderIssue(cmd)
  }
}

const issueService = new IssueService()

export default issueService
