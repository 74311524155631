import { FormHelperText, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { ContainerPositionType, JobDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { FieldboxSelectionGroup, FieldboxWithRadio, useMinimalsTheme } from '@tom-ui/ui'
import { JobInformationJourney } from '../JobInformation/JobInformationJourney'
import { JobInformationOperationalInstructions } from '../JobInformation/JobInformationOperationalInstructions'
import { JobInformationWrapper } from '../JobInformation/JobInformationWrapper'

interface Props {
  job: JobDto
  containerOrigin?: string
  currentStep: number
  stepsTotal: number
  hasError?: boolean
  handleChange: (newValue?: ContainerPositionType) => void
}

export const RestowDestination = ({
  job,
  containerOrigin,
  currentStep,
  stepsTotal,
  hasError,
  handleChange,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const handleEditDestination = (value: string) => {
    handleChange(value as ContainerPositionType)
  }

  return (
    <JobInformationWrapper job={job} currentStep={currentStep} stepsTotal={stepsTotal}>
      {(!currentStep || currentStep === 1) && <JobInformationOperationalInstructions job={job} />}
      <JobInformationJourney job={job} origin={containerOrigin}>
        <Stack>
          <Typography mb={theme.customSpacing.xs} variant='subtitle1'>
            {t('selectLocation', 'Select location')}
          </Typography>

          <FieldboxSelectionGroup
            onSelect={handleEditDestination}
            options={controller => (
              <>
                <FieldboxWithRadio
                  selectionKey={ContainerPositionType.Vessel}
                  controller={controller}
                  label={job.to.locationName}
                />
                <FieldboxWithRadio
                  selectionKey={ContainerPositionType.Berth}
                  controller={controller}
                  label={t('berth', 'Berth')}
                />
              </>
            )}
          />

          {hasError && (
            <FormHelperText error sx={{ marginLeft: '14px' }}>
              {t('selectOneOption', 'Select one option')}
            </FormHelperText>
          )}
        </Stack>
      </JobInformationJourney>
    </JobInformationWrapper>
  )
}
