import { Button, Grid, IconButton, TextField, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { BinIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'

export interface ITripId {
  inbound: string
  outbound: string
}

export const getDefaultTripId = (): ITripId => {
  return { inbound: '', outbound: '' }
}

export const VisitTripIdsFields = observer(() => {
  const { t } = useTranslate()

  const { control, watch } = useFormContext()

  const { fields, append, remove, update } = useFieldArray({
    control: control,
    name: 'tripIds',
  })

  const canDelete = (index: number) => {
    if (index > 0) return true

    const tripIds = watch('tripIds')
    if (tripIds[index] && (tripIds[index].inbound || tripIds[index].outbound)) return true

    return false
  }

  return (
    <>
      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('tripIds', `Trip ID's`)}
      </Typography>
      <Grid container>
        {fields.map((field, index) => (
          <Grid key={field.id} container direction='row' columnSpacing={{ xs: 1 }} mb={'.5rem'}>
            <Grid item xs={5}>
              <Controller
                control={control}
                name={`tripIds.${index}.inbound`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    value={field.value ?? ''}
                    label={t('inboundTripId', 'Inbound Trip ID')}
                    variant='outlined'
                  />
                )}
              />
            </Grid>
            <Grid item xs={5}>
              <Controller
                control={control}
                name={`tripIds.${index}.outbound`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    value={field.value ?? ''}
                    label={t('outboundTripId', 'Outbound Trip ID')}
                    variant='outlined'
                  />
                )}
              />
            </Grid>
            <Grid item xs={2} alignSelf='center'>
              {canDelete(index) && (
                <IconButton
                  color='error'
                  onClick={() => {
                    if (fields.length === 1) {
                      update(0, getDefaultTripId())
                    } else {
                      remove(index)
                    }
                  }}
                >
                  <BinIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        ))}

        <Grid item mt={'.5rem'}>
          <Button size='small' onClick={() => append(getDefaultTripId())}>
            + {t('add', 'Add')}
          </Button>
        </Grid>
      </Grid>
    </>
  )
})
