import Multi2 from '@storage/components/icons/svg/MultipleIndicators/Multi2.svg'
import Multi3 from '@storage/components/icons/svg/MultipleIndicators/Multi3.svg'
import Multi4 from '@storage/components/icons/svg/MultipleIndicators/Multi4.svg'
import Multi5 from '@storage/components/icons/svg/MultipleIndicators/Multi5.svg'
import Multi6 from '@storage/components/icons/svg/MultipleIndicators/Multi6.svg'
import Multi7 from '@storage/components/icons/svg/MultipleIndicators/Multi7.svg'
import Multi8 from '@storage/components/icons/svg/MultipleIndicators/Multi8.svg'
import Multi9 from '@storage/components/icons/svg/MultipleIndicators/Multi9.svg'
import Multi9plus from '@storage/components/icons/svg//MultipleIndicators/Multi9plus.svg'

const multiImages = [Multi2, Multi3, Multi4, Multi5, Multi6, Multi7, Multi8, Multi9]

export const multipleStackingIndicator = (occupancyCount: number): string => {
  if (occupancyCount >= 2 && occupancyCount <= 9) {
    return multiImages[occupancyCount - 2]
  }
  return Multi9plus
}
