import { createSvgIcon } from '@mui/material'

export const PowerOutIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.33333 13.3333V12L4.38333 10.05C4.26111 9.92778 4.16667 9.78611 4.1 9.625C4.03333 9.46389 4 9.29445 4 9.11667V6C4 5.63333 4.13056 5.31944 4.39167 5.05833C4.65278 4.79722 4.96667 4.66667 5.33333 4.66667H6L5.33333 5.33333V2.66667C5.33333 2.47778 5.39722 2.31944 5.525 2.19167C5.65278 2.06389 5.81111 2 6 2C6.18889 2 6.34722 2.06389 6.475 2.19167C6.60278 2.31944 6.66667 2.47778 6.66667 2.66667V4.66667H9.33333V2.66667C9.33333 2.47778 9.39722 2.31944 9.525 2.19167C9.65278 2.06389 9.81111 2 10 2C10.1889 2 10.3472 2.06389 10.475 2.19167C10.6028 2.31944 10.6667 2.47778 10.6667 2.66667V5.33333L10 4.66667H10.6667C11.0333 4.66667 11.3472 4.79722 11.6083 5.05833C11.8694 5.31944 12 5.63333 12 6V9.11667C12 9.29445 11.9667 9.46389 11.9 9.625C11.8333 9.78611 11.7389 9.92778 11.6167 10.05L9.66667 12V13.3333C9.66667 13.5222 9.60278 13.6806 9.475 13.8083C9.34722 13.9361 9.18889 14 9 14H7C6.81111 14 6.65278 13.9361 6.525 13.8083C6.39722 13.6806 6.33333 13.5222 6.33333 13.3333Z'
      fill='currentColor'
    />
    <g clipPath='url(#clip0_1537_3553)'>
      <path
        d='M12 12.225L11.2083 11.4333C11.1472 11.3722 11.0708 11.3417 10.9791 11.3417C10.8875 11.3417 10.8083 11.3722 10.7416 11.4333C10.675 11.5 10.6416 11.5792 10.6416 11.6708C10.6416 11.7625 10.675 11.8417 10.7416 11.9083L11.7666 12.9333C11.8333 13 11.9111 13.0333 12 13.0333C12.0888 13.0333 12.1666 13 12.2333 12.9333L13.2666 11.9C13.3333 11.8333 13.3652 11.7555 13.3625 11.6667C13.3597 11.5778 13.325 11.5 13.2583 11.4333C13.1916 11.3722 13.1138 11.3403 13.025 11.3375C12.9361 11.3347 12.8583 11.3667 12.7916 11.4333L12 12.225ZM12 15.3333C11.5388 15.3333 11.1055 15.2458 10.7 15.0708C10.2944 14.8958 9.94163 14.6583 9.64163 14.3583C9.34163 14.0583 9.10413 13.7055 8.92913 13.3C8.75413 12.8944 8.66663 12.4611 8.66663 12C8.66663 11.5389 8.75413 11.1055 8.92913 10.7C9.10413 10.2944 9.34163 9.94166 9.64163 9.64166C9.94163 9.34166 10.2944 9.10416 10.7 8.92916C11.1055 8.75416 11.5388 8.66666 12 8.66666C12.4611 8.66666 12.8944 8.75416 13.3 8.92916C13.7055 9.10416 14.0583 9.34166 14.3583 9.64166C14.6583 9.94166 14.8958 10.2944 15.0708 10.7C15.2458 11.1055 15.3333 11.5389 15.3333 12C15.3333 12.4611 15.2458 12.8944 15.0708 13.3C14.8958 13.7055 14.6583 14.0583 14.3583 14.3583C14.0583 14.6583 13.7055 14.8958 13.3 15.0708C12.8944 15.2458 12.4611 15.3333 12 15.3333Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1537_3553'>
        <rect width='8' height='8' fill='white' transform='translate(8 8)' />
      </clipPath>
    </defs>
  </svg>,
  'PowerOutIcon',
)
