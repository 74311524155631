import { Chip, FormControl, Stack } from '@mui/material'
import { RailTrackResponseDto } from '@planning/app/api'
import { usePlanningStore } from '@planning/AppProvider'
import { useTranslate } from '@tolgee/react'
import { SuccessIcon } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

interface Props {
  value: string[]
  railTracks: RailTrackResponseDto[]
  allocatedRailTrackIds?: string[]
  onChange: (value: string[]) => Promise<void> | void
  disabled?: boolean
}

export const RailTrackChips: FC<Props> = observer(
  ({ value, railTracks, allocatedRailTrackIds, onChange, disabled }) => {
    const { appViewStore } = usePlanningStore()
    const { t } = useTranslate()

    const validateRailTrackId = (id?: string) => {
      if (allocatedRailTrackIds?.some(x => x === id)) {
        appViewStore.setShowAlert(
          'warning',
          t(
            'railTrackJustRemovedHasRailcarsYouWillFindThemInAnotherTrack',
            'The rail track you just removed has railcars allocated. You will find those railcars in the first rail track after you submit',
          ),
        )
      }
    }

    return (
      <FormControl fullWidth>
        <Stack direction='row' spacing={1} mb={1} flexWrap='wrap'>
          {railTracks.map(railTrack => {
            const isSelected = value.includes(railTrack.id)
            const getValues = () => {
              if (value.includes(railTrack.id)) {
                validateRailTrackId(railTrack.id)
                return value.filter(x => x !== railTrack.id)
              } else {
                return [...value, railTrack.id]
              }
            }

            return (
              <Chip
                data-cy={`rail-track-chip-${railTrack.name}`}
                key={`railTrack_chip_${railTrack.id}`}
                disabled={disabled}
                label={railTrack.name}
                icon={isSelected ? <SuccessIcon /> : undefined}
                color={isSelected ? 'primary' : 'default'}
                onClick={() => onChange(getValues())}
              />
            )
          })}
        </Stack>
      </FormControl>
    )
  },
)
