import { OrderResponseDto, OrderUpdateResponseDto, OrderUpdateType } from '@planning/app/api'
import { OrderUpdateItemStore } from '@planning/rt-stores/orderUpdate/OrderUpdateItemStore'
import { orderService } from '@planning/services'
import { action, computed, makeObservable, observable } from 'mobx'

export interface IOrderWithUpdates {
  order: OrderResponseDto | null
  update: OrderUpdateResponseDto
}

interface IEntityFieldChangeUpdateData {
  name: string
  prevValue: string
  newValue: string
  received: string
  source: string
}

export class OrderUpdatesViewStore {
  orderId?: number

  constructor(private orderUpdateItemStore?: OrderUpdateItemStore) {
    makeObservable(this, {
      orderId: observable,

      updates: computed,

      setOrderId: action,
      markAllAsRead: action,
    })
  }

  setOrderId(orderId: number) {
    this.orderId = orderId
  }

  async markAllAsRead() {
    const cmd = {
      ids: this.updates.map(i => i.id),
    }
    await orderService.markOrderUpdatesAsRead(cmd)
  }

  get updates() {
    return (
      this.orderUpdateItemStore?.unreadOrderUpdates.filter(
        update => update.orderId === this.orderId,
      ) || []
    )
  }

  get entityFieldUpdates() {
    return this.updates.filter(update => update.type === OrderUpdateType.Update)
  }

  get mappedUpdates() {
    const latestChanges: Map<string, IEntityFieldChangeUpdateData> = this.updates.reduce(
      (fieldUpdate, { received, source, entityFieldDataChanges }) => {
        entityFieldDataChanges.forEach(change => {
          const { name } = change

          if (!fieldUpdate.has(name) || fieldUpdate.get(name).received < received) {
            fieldUpdate.set(name, { ...change, received, source })
          }
        })
        return fieldUpdate
      },
      new Map(),
    )

    const result = Array.from(latestChanges.values())

    return result
  }
}
