import * as yup from 'yup'
import { CTReportFieldsFormProfile } from './ct-report-fields-form.profile'
export const schema: yup.ObjectSchema<CTReportFieldsFormProfile> = yup.object({
  includeContainerNumber: yup.boolean().required(),
  includeIsoCode: yup.boolean().required(),
  includeOperator: yup.boolean().required(),
  includeGrossWeight: yup.boolean().required(),
  includeIsOOG: yup.boolean().required(),
  includeIsReefer: yup.boolean().required(),
  includeIsEmpty: yup.boolean().required(),
  includeIsDangerous: yup.boolean().required(),
  includeEntryDateTime: yup.boolean().required(),
  includeIsDamaged: yup.boolean().required(),
})
