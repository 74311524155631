import { Box, Card } from '@mui/material'
import { WeightClassDto } from '@storage/app/api'
import { CarrierVisitDirection } from '@storage/app/api'
import { GlobalRulesAccordionContainer } from '@storage/features/carrier-visit-allocation-rules/components/GlobalRulesAccordionContainer'
import CarrierVisitDirectionTabs from '@storage/features/carrier-visit-allocation-rules/components/CarrierVisitDirectionTabs'
import { AllocationRulePanel } from '@storage/pages/yard-planning-dashboard-details/components/allocation-rule-panel/AllocationRulePanel'
import UnAllocatedContainers from '@storage/pages/yard-planning-dashboard-details/components/UnAllocatedContainers'
import { SyntheticEvent, useEffect } from 'react'
import { useTranslate } from '@tolgee/react'
import { useStores } from '@storage/hooks/use-stores.hook'
import { observer } from 'mobx-react-lite'

interface GlobalAllocationRulesSidePanelProps {
  weightClasses: WeightClassDto[]
}

export const GlobalAllocationRulesSidePanel = observer(
  ({ weightClasses }: GlobalAllocationRulesSidePanelProps) => {
    const { t } = useTranslate()
    const { globalAllocationRulesUIStore, globalAllocationRuleStore, unallocatedTurnoversStore } =
      useStores()

    useEffect(() => {
      let turnovers = unallocatedTurnoversStore.containerTurnovers

      globalAllocationRuleStore.entries.forEach(rule => {
        turnovers = globalAllocationRulesUIStore.filterTurnoversByAllocationRule(rule, turnovers)
      })
    }, [
      unallocatedTurnoversStore.containerTurnovers,
      globalAllocationRuleStore.entries,
      globalAllocationRulesUIStore,
    ])

    useEffect(() => {
      globalAllocationRulesUIStore.loadAllocationRules()

      return () => {
        globalAllocationRulesUIStore.setSelectedAllocationRule()
      }
    }, [globalAllocationRulesUIStore, globalAllocationRulesUIStore.activeHandlingDirection])

    const showAllocationRulePanel =
      globalAllocationRulesUIStore.showAllocationRuleForm ||
      globalAllocationRulesUIStore.showDestinationForm

    const handleDirectionChange = (_: SyntheticEvent, newValue: CarrierVisitDirection) => {
      if (globalAllocationRuleStore.hasChanges) {
        globalAllocationRulesUIStore.toggleUnsavedChangesDialog('direction')
      } else {
        globalAllocationRulesUIStore.setActiveHandlingDirection(newValue)
      }
    }

    if (showAllocationRulePanel) {
      return <AllocationRulePanel uiStore={globalAllocationRulesUIStore} />
    }
    const handleImport = () => {
      globalAllocationRulesUIStore.allocationRuleTemplatesDialogUtilStore.toggleDialog('Add')
    }

    return (
      <Card sx={{ height: '80vh', display: 'flex', flexDirection: 'column' }}>
        <CarrierVisitDirectionTabs
          value={globalAllocationRulesUIStore.activeHandlingDirection}
          onChange={handleDirectionChange}
        />
        <GlobalRulesAccordionContainer
          tooltip={t(
            'globalRulesTooltip',
            'Global rules are always working to organize your yard. These can be overridden when planning specific visits.',
          )}
          onImport={handleImport}
          carrierVisitDirection={globalAllocationRulesUIStore.activeHandlingDirection}
        />

        <Box sx={{ flexGrow: 1, minHeight: 0 }}>
          <UnAllocatedContainers
            uiStore={globalAllocationRulesUIStore}
            isLoading={unallocatedTurnoversStore.dataLoading}
            unallocatedTurnovers={globalAllocationRulesUIStore.unallocatedTurnovers}
            carrierVisitDirection={globalAllocationRulesUIStore.activeHandlingDirection}
            weightClasses={weightClasses}
          />
        </Box>
      </Card>
    )
  },
)
