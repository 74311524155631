import { Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { PageHeaderActions } from './PageHeaderActions'

interface IProps {
  children?: React.ReactNode
  formId: string
  onBack?: () => void
}

export const PageHeader: FC<IProps> = observer(({ children, formId, onBack }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Stack
      paddingX={theme.customSpacing.l}
      paddingY={theme.customSpacing.s}
      alignItems={'center'}
      direction={'row'}
      sx={{ placeContent: 'space-between' }}
      borderBottom={`1px solid ${theme.palette.divider}`}
    >
      <Stack alignItems={'flex-start'}>
        <Typography variant='h3' align='center'>
          {t('orderDetails', 'Order details')}
        </Typography>
        <Box>{children}</Box>
      </Stack>
      <PageHeaderActions formId={formId} onBack={onBack} />
    </Stack>
  )
})
