import { YardPositionDto } from '@storage/app/api'
import { useStores } from '@storage/hooks/use-stores.hook'
import { YardBlockStore } from '@storage/stores/yard-block.store'
import { useTranslate } from '@tolgee/react'
import { useMemo } from 'react'

export const usePositionLabel = (position?: YardPositionDto[]): string[] => {
  const { t } = useTranslate()
  const { tenantConfigStore, yardBlockStore } = useStores()
  const hideBlockName = tenantConfigStore.isBayRowUniqueIdentificationModeActivated

  const blockGroups = useMemo(() => {
    if (!position?.length) return {}
    return position.reduce(
      (acc, obj) => {
        const key = obj.block || ''
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      },
      {} as Record<string, YardPositionDto[]>,
    )
  }, [position])

  const labels = useMemo(() => {
    if (!position?.length) return [t('noPosition', 'No Position')]
    return Object.values(blockGroups).map(group =>
      generatePositionLabel(group, yardBlockStore, hideBlockName),
    )
  }, [position, t, blockGroups, yardBlockStore, hideBlockName])

  return labels
}

const generatePositionLabel = (
  position: YardPositionDto[],
  yardBlockStore: YardBlockStore,
  hideBlockName: boolean,
) => {
  const { block, bay, row, tier } = position[0]

  if (yardBlockStore.isFlexibleZone(block!)) return block!

  let positionLabel = ''

  if (!hideBlockName && block) {
    positionLabel += `${block}`
  }
  if (bay) {
    const baySeparator = hideBlockName ? '' : '.'
    if (position.length === 2 && bay !== position[1].bay) {
      positionLabel += `${baySeparator}${position.map(e => e.bay).join('-')}`
    } else {
      positionLabel += `${baySeparator}${bay}`
    }

    if (row) {
      if (position.length === 2 && row !== position[1].row) {
        positionLabel += `.${position.map(e => e.row).join('-')}`
      } else {
        positionLabel += `.${row}`
      }
    }
    if (tier) {
      positionLabel += `.${tier}`
    }
  }

  return positionLabel!
}
