import { CarrierVisitStatus } from '@planning/app/api'
import { CarrierVisitViewStatus } from '@planning/constants'
import moment from 'moment'
import { IDateRange, IElementWithId, IEntityMap, IdType } from './types'

export const getMidnightTime = (value: Date) => {
  return moment(value).startOf('d').toDate()
}

export const getEndOfDayTime = (value: Date) => {
  return moment(value).endOf('d').toDate()
}

export const calculateDateRangeDiff = (range1: IDateRange, range2: IDateRange) => {
  // fetch data before the current data range
  if (range2.from < range1.from && range2.to <= range1.to) {
    return {
      from: getMidnightTime(range2.from),
      to: getEndOfDayTime(range1.from),
    }
  }

  // fetch data after the current data range
  if (range2.to > range1.to && range2.from >= range1.from) {
    return {
      from: getMidnightTime(range1.to),
      to: getEndOfDayTime(range2.to),
    }
  }

  // default case for both are smaller or bigger than the current data range
  return {
    from: getMidnightTime(range2.from > range1.from ? range1.from : range2.from),
    to: getEndOfDayTime(range2.to > range1.to ? range2.to : range1.to),
  }
}

export const groupById = <T extends IElementWithId<IdType>>(elems: T[]) => {
  return elems.reduce((acc, cur) => {
    acc[cur.id] = cur
    return acc
  }, {} as IEntityMap<T>)
}

export const validateContainerNumber = (containerNumber: string) => {
  const regex = /^[A-Z]{3}[UJZ][0-9]{7}$/
  return !!containerNumber.match(regex)
}

export const validateUnitNumber = (unitNumber: string) => {
  const regex = /^[A-Z]{4}[0-9]{7}$/
  return !!unitNumber.match(regex)
}

export const validateIsoCode = (isoCode: string) => {
  if (isoCode.length !== 4) return false

  const part1 = /^[1-5ABCDEFGHKLMNP]/
  const part2 = /[024-9CDEFLMNPRSTU]/
  const part3 =
    /A[0S]|GP|VH|BU|UT|KL|R[ETSIH]|H[RIE]|W[RS]|P[LFCST]|N[HNP]|[GVBRHWUPKN]\d|S[\dNCABDGJMVWXY]/

  const part4 = /^[1-4]\d/
  const part5 = /(0[0-4]|1[013468]|2[0256]|3[0-2]|4[0-2]|5[0-3]|6[0-7]|7[0-8]|8\d|9\d)/

  const isoCodeRegex = new RegExp(
    `(${part1.source}${part2.source}${part3.source})|(${part4.source}${part5.source})`,
    'i',
  )

  return !!isoCode.match(isoCodeRegex)
}

export const getCarrierVisitViewStatus = (status: CarrierVisitStatus, atb?: string | null) => {
  if (status === CarrierVisitStatus.Departed) return CarrierVisitViewStatus.Departed

  if (status === CarrierVisitStatus.Completed) return CarrierVisitViewStatus.Completed

  if (status === CarrierVisitStatus.InOperation) return CarrierVisitViewStatus.InOperation

  if (atb) return CarrierVisitViewStatus.Berthed

  return status
}
