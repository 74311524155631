import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { getContainerTypeParameters } from '@planning/components/ContainerTypesMap'
import { containerTypes } from '@planning/constants'
import { validateUnitNumber } from '@planning/rt-stores/helpers'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { Controller, FieldErrors, useFormContext } from 'react-hook-form'

export const TrailerInfoForm: FC = observer(() => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  const { formState, control } = useFormContext()

  const { errors } = formState

  const unitNumberErrorText = (errors: FieldErrors) => {
    const containerNumberError = errors.number
    if (containerNumberError) {
      const errorType = containerNumberError.type
      if (errorType === 'required') return t('fieldIsRequired', 'Field is required.')
      if (errorType === 'validate')
        return `${t('mustMatchPattern', 'Must match pattern')}: AAAA9999999`

      return containerNumberError.message?.toString()
    }
  }

  return (
    <>
      <Grid item sm={6} xs={12}>
        <Controller
          name='number'
          control={control}
          rules={{
            validate: v => !!v && validateUnitNumber(v),
          }}
          render={({ field }) => (
            <TextField
              {...field}
              data-cy='container-number-field'
              label={`${t('iluCode', 'ILU Code')}`}
              fullWidth
              variant='outlined'
              type='text'
              name='number'
              required={true}
              onChange={event => field.onChange(event.target.value.toUpperCase())}
              error={!!errors.number}
              helperText={unitNumberErrorText(errors)}
            />
          )}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <Controller
          name='operator'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              data-cy='container-operator-field'
              label={`${t('operator', 'Operator')}`}
              fullWidth
              variant='outlined'
              type='text'
              name='operator'
            />
          )}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <Controller
          name='length'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              data-cy='container-length'
              label={`${t('length', 'Length')}`}
              fullWidth
              variant='outlined'
              type='text'
              name='length'
              error={!!errors.length}
              helperText={errors.length && t('fieldIsRequired', 'Field is required.')}
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment
                    position='end'
                    color='secondary'
                    padding={theme.customSpacing.l}
                  >
                    ft
                  </CustomInputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>{`${t('unitType', 'Unit Type')}`}</InputLabel>
          <Controller
            name='type'
            control={control}
            render={({ field }) => (
              <Select {...field} label={`${t('unitType', 'Unit Type')} *`} data-cy='container-type'>
                {containerTypes.map(d => {
                  return (
                    <MenuItem key={d.key} value={d.name}>
                      {getContainerTypeParameters(d.name, t)['label']}
                    </MenuItem>
                  )
                })}
              </Select>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item sm={12}>
        <Controller
          name='plateNumber'
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              data-cy='trailer-plate-no-field'
              label={`${t('plateNo', 'Plate no.')}`}
              fullWidth
              variant='outlined'
              type='text'
              name='plateNumber'
              error={!!errors.plateNumber}
            />
          )}
        />
      </Grid>
    </>
  )
})
