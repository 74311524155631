const STORAGE_PREFIX = 'storage'

export const PATH_STORAGE = {
  root: '/',
  yardManagement: `/${STORAGE_PREFIX}/yard-management`,
  containerTurnovers: `/${STORAGE_PREFIX}/container-turnovers`,
  yardPlanningDashboard: {
    root: `/${STORAGE_PREFIX}/yard-planning-dashboard`,
    details: {
      root: `/${STORAGE_PREFIX}/yard-planning-dashboard/:carrierVisitId/:handlingDirection/:discriminator`,
      params: {
        carrierVisitId: 'carrierVisitId',
        handlingDirection: 'handlingDirection',
        discriminator: 'discriminator',
      },
    },
  },
  vesselVisitDetails: {
    root: `/vessel-visits/:vesselVisitId/dashboard`,
    params: {
      vesselVisitId: 'vesselVisitId',
    },
  },
  railVisitDetails: {
    root: `/rail-visits/:railVisitId/dashboard`,
    params: {
      railVisitId: 'railVisitId',
    },
  },
  globalAllocationRules: `/${STORAGE_PREFIX}/global-allocation-rules`,
  yardPlanningIssues: `/${STORAGE_PREFIX}/yard-planning-issues`,
  layout: {
    piers: `/layout/${STORAGE_PREFIX}/piers`,
    berths: `/layout/${STORAGE_PREFIX}/berths`,
    yardSetup: `/layout/${STORAGE_PREFIX}/yard-setup`,
  },
  yardInconsistencies: `/${STORAGE_PREFIX}/yard-inconsistencies`,
  operatorContainerTurnoversList: {
    root: `/${STORAGE_PREFIX}/operator-container-turnovers-list`,
    positionUpdate: {
      root: `/${STORAGE_PREFIX}/operator-container-turnovers-list/:containerTurnoverId`,
      params: {
        containerTurnoverId: 'containerTurnoverId',
      },
    },
  },
  position: `/${STORAGE_PREFIX}/position`,
  generalCargoInventory: `/${STORAGE_PREFIX}/general-cargo-inventory`,
  notFound: '404',
}
