import { Box } from '@mui/system'
import { CarrierVisitDirection } from '@planning/app/api'
import { OrderListUploadViewStoreV2 } from '@planning/pages/Order/stores/OrderListUploadViewStoreV2'
import { CustomAlert } from '@planning/pages/TallymanV2/Components/CustomAlert'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { OrderListCard } from './OrderListCard'
import { OrderListUploadAttributes } from './OrderListUploadAttributes'
import { OrderListUploadCounter } from './OrderListUploadCounter'
import { RestowListCard } from './RestowListCard'

interface IProps {
  store: OrderListUploadViewStoreV2
  isRestow?: boolean
}

export const OrderListReviewAndEditContent: FC<IProps> = observer(({ store, isRestow }) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const orderAttributuesData = [
    { title: t('filename', 'Filename'), value: store.parsingStore.file?.name ?? '' },
    {
      title: t('contains', 'Contains'),
      value: isRestow
        ? t('restows', 'Restows')
        : store.direction === CarrierVisitDirection.Inbound
          ? t('dischargeOrders', 'Discharge orders')
          : t('loadOrders', 'Load orders'),
    },
    { title: t('for', 'For'), value: store.vesselVisit?.identifier ?? '' },
    { title: t('group', 'Group'), value: store.listName ?? '' },
    {
      title: t('arriving', 'Arriving'),
      value: formatDateTime(store.vesselVisit?.ata ?? store.vesselVisit?.eta) ?? '',
    },
  ]

  return (
    <Box display='flex' flexDirection='column'>
      <OrderListUploadAttributes data={orderAttributuesData} />

      <Box sx={{ padding: '1rem' }}>
        {store.parsingStore.uploadWarningMessages.length > 0 && (
          <Box marginBottom={theme.customSpacing.l}>
            <CustomAlert
              message={store.parsingStore.uploadWarningMessages.join('\n')}
              severity='warning'
            />
          </Box>
        )}

        <OrderListUploadCounter store={store} isRestow={isRestow} />

        {isRestow ? (
          store.dataStore.restowUnits.map((o, i) => (
            <RestowListCard key={`new-restow-${i}`} restow={o} />
          ))
        ) : (
          <>
            {store.orderUpdates?.created.map((o, i) => (
              <OrderListCard
                key={`new-order-${i}`}
                order={o}
                type='New'
                accept={store.acceptUpdates[o.containerNumber]}
                onAcceptChange={(containerNumber, accept) =>
                  store.setAcceptUpdate(containerNumber, accept, true)
                }
              />
            ))}
            {store.orderUpdates?.amended.map(o => (
              <OrderListCard
                key={o.id}
                order={o}
                type='Amended'
                accept={store.acceptUpdates[o.containerNumber ?? '']}
                onAcceptChange={store.setAcceptUpdate}
              />
            ))}
            {store.orderUpdates?.deleted.map(o => (
              <OrderListCard
                key={o.id}
                order={o}
                type='Cancelled'
                accept={store.acceptUpdates[o.containerNumber ?? '']}
                onAcceptChange={store.setAcceptUpdate}
              />
            ))}
          </>
        )}

        {store.orderUpdates!.unchanged.length > 0 &&
        store.totalCount === store.orderUpdates?.unchanged.length ? (
          <CustomAlert
            message={t('noUpdatesWillBeApplied', 'No updates will be applied')}
            severity='info'
          />
        ) : (
          ''
        )}
      </Box>
    </Box>
  )
})
