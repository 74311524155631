import { TenantDto, UnLocationCodeDto } from '@admin/app/api'
import { Box, Button, IconButton, Stack, Switch, TextField, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { BinIcon } from 'modules/ui/src'
import { useEffect, useState } from 'react'
import { Control, Controller, FormState, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { TenantConfigFormProfile } from './tenant-config-form.profile'

interface Props {
  tenant?: TenantDto
  formState: FormState<TenantConfigFormProfile>
  control: Control<TenantConfigFormProfile>
  setValue: UseFormSetValue<TenantConfigFormProfile>
  getValues: UseFormGetValues<TenantConfigFormProfile>
  formErrors: any
}

export interface IUnloCodesInitialState extends UnLocationCodeDto {
  id: number
}

export const TenantLocationCodeField = observer(
  ({ tenant, control, setValue, getValues, formErrors }: Props) => {
    const { t } = useTranslate()

    const unloCodesInitialState: IUnloCodesInitialState[] =
      tenant?.unloCodes && tenant?.unloCodes.length > 0
        ? tenant.unloCodes.map((item, index) => ({
            id: index,
            name: item.name,
            isDefault: item.isDefault,
          }))
        : [{ id: 0, name: '', isDefault: true }]

    const [locationCodeFields, setLocationCodeFields] =
      useState<IUnloCodesInitialState[]>(unloCodesInitialState)

    const [defaultIndex, setDefaultIndex] = useState<number | null>(
      unloCodesInitialState.findIndex(field => field.isDefault),
    )

    useEffect(() => {
      setValue('unloCodes', locationCodeFields)
    }, [locationCodeFields, setValue])

    const addField = () => {
      setLocationCodeFields(prevFields => [
        ...prevFields,
        { id: prevFields.length, name: '', isDefault: defaultIndex === null },
      ])
    }

    const removeField = (id: number) => {
      if (locationCodeFields.length <= 1) {
        return
      }

      const updatedFields = locationCodeFields.filter(field => field.id !== id)
      setLocationCodeFields(updatedFields)

      if (defaultIndex === id) {
        if (updatedFields.length > 0) {
          setDefaultIndex(updatedFields[0].id)
        } else {
          setDefaultIndex(null)
        }
      }

      const currentLocationCodes = getValues('unloCodes')
      const updatedLocationCodes = currentLocationCodes?.filter(
        (_: any, index: number) => index !== id,
      )
      setValue('unloCodes', updatedLocationCodes)
    }

    const handleSwitchChange = (index: number) => {
      setDefaultIndex(index)
      setLocationCodeFields(prevFields =>
        prevFields.map(f =>
          f.id === index ? { ...f, isDefault: true } : { ...f, isDefault: false },
        ),
      )
      setValue(
        'unloCodes',
        locationCodeFields.map(f =>
          f.id === index ? { ...f, isDefault: true } : { ...f, isDefault: false },
        ),
      )
    }

    return (
      <Box>
        <Typography variant='subtitle2'>
          {t('terminalLocationCodes', 'Terminal location codes')}
        </Typography>

        {locationCodeFields.map((field, index) => (
          <Stack key={field.id} direction='row' spacing={'1rem'} margin={'1rem 0'}>
            <Controller
              name={`unloCodes.${index}`}
              control={control}
              defaultValue={field}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Stack direction={'row'} alignItems={'center'}>
                  <TextField
                    label={t('locationCode', 'Location Code')}
                    defaultValue={value.name}
                    onChange={e => {
                      onChange({ ...value, name: e.target.value.toUpperCase() })
                      setLocationCodeFields(prevFields =>
                        prevFields.map(f =>
                          f.id === index ? { ...f, name: e.target.value.toUpperCase() } : f,
                        ),
                      )
                      setValue('unloCodes', locationCodeFields)
                    }}
                    fullWidth
                    inputProps={{ maxLength: 5, style: { textTransform: 'uppercase' } }}
                    error={!!error}
                    helperText={error?.message || ''}
                  />

                  <Switch
                    checked={defaultIndex === index || locationCodeFields.length === 1}
                    onChange={() => handleSwitchChange(index)}
                  />
                </Stack>
              )}
            />

            {!field.isDefault && (
              <IconButton color='error' onClick={() => removeField(field.id)}>
                <BinIcon />
              </IconButton>
            )}
          </Stack>
        ))}

        {formErrors.hasDuplicateLocationCodes && (
          <Typography variant='body2' color='error'>
            {t('duplicateLocationCodesAreNotAllowed', 'Duplicate location codes are not allowed')}
          </Typography>
        )}

        {formErrors.isRequiredLocationCode && (
          <Typography variant='body2' color='error'>
            {t('atLeastOneLocationCodeIsRequired', 'At least one location code is required')}
          </Typography>
        )}

        <Button onClick={addField}>+ {t('add', 'Add')}</Button>
      </Box>
    )
  },
)
