import { IconButton, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import { RailTrackDto } from '@storage/app/api'
import { Permission } from '@storage/app/models/permission.enum'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { BinIcon, EditIcon, useMinimalsTheme } from '@tom-ui/ui'
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'

const RailTracksTable = observer(() => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { railTracksUIStore, authStore } = useStores()
  const dialogUtilStore = railTracksUIStore.dialogUtilStore

  const overwriteMinimalTableHeadCellStyle = {
    sx: {
      backgroundColor: theme.palette.background.neutral,
    },
  }

  const columns = useMemo<MRT_ColumnDef<RailTrackDto>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name', 'Name'),
      },
      {
        accessorKey: 'length',
        header: t('lengthM', 'Length (m)'),
      },
    ],
    [t],
  )

  useEffect(() => {
    railTracksUIStore.loadRailTracks()
  }, [railTracksUIStore])

  return (
    <Box m={theme.customSpacing.l}>
      <MaterialReactTable
        muiTableHeadCellProps={overwriteMinimalTableHeadCellStyle}
        columns={columns}
        data={railTracksUIStore.railTracks}
        autoResetPageIndex={false}
        enableColumnActions={false}
        enableTopToolbar={false}
        enableRowActions
        renderRowActions={({ row }) => [
          authStore.hasPermission(Permission.WriteRailTracks) && (
            <Box key={0} sx={{ minWidth: '120px' }}>
              <Tooltip title={t('edit', 'Edit')}>
                <IconButton
                  aria-label='edit'
                  onClick={() => dialogUtilStore.toggleDialog('Edit', row.original.id)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={t('remove', 'Remove')}>
                <IconButton
                  aria-label='delete'
                  onClick={() => dialogUtilStore.toggleDialog('Delete', row.original.id)}
                >
                  <BinIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ),
        ]}
        positionActionsColumn='last'
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: '',
            muiTableBodyCellProps: {
              align: 'right',
            },
          },
        }}
      />
    </Box>
  )
})

export default RailTracksTable
