import { FormControlLabel, Grid, styled, Switch, TextField, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { Controller, FieldError, useFormContext } from 'react-hook-form'
import { ICoolingOrder } from '../../Stores/ServiceOrdersViewStore'

const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  width: '100%',
  justifyContent: 'space-between',
  margin: theme.spacing(1, 0),
  '& .MuiFormControlLabel-label': {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
}))

export const CoolingOrderForm = observer(() => {
  const { t } = useTranslate()
  const { control, formState, register, watch } = useFormContext<ICoolingOrder>()

  const { errors } = formState

  const getErrorMessage = (min: number, max: number, error?: FieldError) => {
    if (error) {
      const errorType = error.type
      if (errorType === 'required') return t('fieldIsRequired', 'Field is required.')
      if (errorType === 'min')
        return t('valueCannotBeLowerThan', 'Value cannot be lower than {value}', { value: min })
      if (errorType === 'max')
        return t('valueCannotBeGreaterThan', 'Value cannot be greater than {value}', {
          value: max,
        })
    }

    return undefined
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='subtitle1'>{t('temperature', 'Temperature')}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Controller
          control={control}
          name='requestedTemperature'
          rules={{
            required: true,
            min: watch('minRequestedTemperature') ?? -99,
            max: watch('maxRequestedTemperature') ?? 99,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type='number'
              value={field.value ?? ''}
              {...register('requestedTemperature', { valueAsNumber: true })}
              label={t('requested', 'requested')}
              error={!!errors.requestedTemperature}
              helperText={getErrorMessage(
                watch('minRequestedTemperature') ?? -99,
                watch('maxRequestedTemperature') ?? 99,
                errors.requestedTemperature,
              )}
              required
              fullWidth
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment position='end' color='secondary'>
                    °C
                  </CustomInputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Controller
          control={control}
          name='minRequestedTemperature'
          rules={{ min: -99, max: 99, required: !!watch('maxRequestedTemperature') }}
          render={({ field }) => (
            <TextField
              {...field}
              type='number'
              value={field.value ?? ''}
              {...register('minRequestedTemperature', { valueAsNumber: true })}
              label={t('minimum', 'Minimum')}
              error={!!errors.minRequestedTemperature}
              helperText={getErrorMessage(-99, 99, errors.minRequestedTemperature)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment position='end' color='secondary'>
                    °C
                  </CustomInputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Controller
          control={control}
          name='maxRequestedTemperature'
          rules={{
            min: watch('minRequestedTemperature') ?? -99,
            max: 99,
            required: !!watch('minRequestedTemperature'),
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type='number'
              value={field.value ?? ''}
              {...register('maxRequestedTemperature', { valueAsNumber: true })}
              label={t('maximum', 'Maximum')}
              error={!!errors.maxRequestedTemperature}
              helperText={getErrorMessage(
                watch('minRequestedTemperature') ?? -99,
                99,
                errors.maxRequestedTemperature,
              )}
              fullWidth
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment position='end' color='secondary'>
                    °C
                  </CustomInputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant='subtitle1'>{t('humidity', 'Humidity')}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Controller
          control={control}
          name='idealHumidity'
          rules={{ min: watch('minIdealHumidity') ?? 0, max: watch('maxIdealHumidity') ?? 100 }}
          render={({ field }) => (
            <TextField
              {...field}
              type='number'
              value={field.value ?? ''}
              {...register('idealHumidity', { valueAsNumber: true })}
              label={t('requested', 'Requested')}
              error={!!errors.idealHumidity}
              helperText={getErrorMessage(
                watch('minIdealHumidity') ?? 0,
                watch('maxIdealHumidity') ?? 100,
                errors.idealHumidity,
              )}
              fullWidth
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment position='end' color='secondary'>
                    %
                  </CustomInputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Controller
          control={control}
          name='minIdealHumidity'
          rules={{ min: 0, max: 100, required: !!watch('maxIdealHumidity') }}
          render={({ field }) => (
            <TextField
              {...field}
              type='number'
              value={field.value ?? ''}
              {...register('minIdealHumidity', { valueAsNumber: true })}
              label={t('minimum', 'Minimum')}
              error={!!errors.minIdealHumidity}
              helperText={getErrorMessage(0, 100, errors.minIdealHumidity)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment position='end' color='secondary'>
                    %
                  </CustomInputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Controller
          control={control}
          name='maxIdealHumidity'
          rules={{
            min: watch('minIdealHumidity') ?? 0,
            max: 100,
            required: !!watch('minIdealHumidity'),
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type='number'
              label={t('maximum', 'Maximum')}
              value={field.value ?? ''}
              {...register('maxIdealHumidity', { valueAsNumber: true })}
              error={!!errors.maxIdealHumidity}
              helperText={getErrorMessage(
                watch('minIdealHumidity') ?? 0,
                100,
                errors.maxIdealHumidity,
              )}
              fullWidth
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment position='end' color='secondary'>
                    %
                  </CustomInputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant='subtitle1'>{t('ventilation', 'Ventilation')}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Controller
          control={control}
          name='idealVentilation'
          rules={{
            min: watch('minIdealVentilation') ?? 0,
            max: watch('maxIdealVentilation') ?? 180,
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type='number'
              value={field.value ?? ''}
              {...register('idealVentilation', { valueAsNumber: true })}
              label={t('requested', 'Requested')}
              error={!!errors.idealVentilation}
              helperText={getErrorMessage(
                watch('minIdealVentilation') ?? 0,
                watch('maxIdealVentilation') ?? 180,
                errors.idealVentilation,
              )}
              fullWidth
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment position='end' color='secondary'>
                    {t('m³/h', 'm³/h')}
                  </CustomInputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Controller
          control={control}
          name='minIdealVentilation'
          rules={{ min: 0, max: 180, required: !!watch('maxIdealVentilation') }}
          render={({ field }) => (
            <TextField
              {...field}
              type='number'
              value={field.value ?? ''}
              {...register('minIdealVentilation', { valueAsNumber: true })}
              label={t('minimum', 'Minimum')}
              error={!!errors.minIdealVentilation}
              helperText={getErrorMessage(0, 180, errors.minIdealVentilation)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment position='end' color='secondary'>
                    {t('m³/h', 'm³/h')}
                  </CustomInputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Controller
          control={control}
          name='maxIdealVentilation'
          rules={{
            min: watch('minIdealVentilation') ?? 0,
            max: 180,
            required: !!watch('minIdealVentilation'),
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type='number'
              value={field.value ?? ''}
              {...register('maxIdealVentilation', { valueAsNumber: true })}
              label={t('maximum', 'Maximum')}
              error={!!errors.maxIdealVentilation}
              helperText={getErrorMessage(
                watch('minIdealVentilation') ?? 0,
                180,
                errors.maxIdealVentilation,
              )}
              fullWidth
              InputProps={{
                endAdornment: (
                  <CustomInputAdornment position='end' color='secondary'>
                    {t('m³/h', 'm³/h')}
                  </CustomInputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          control={control}
          name='isMonitoringRequired'
          render={({ field }) => (
            <CustomFormControlLabel
              value='start'
              control={
                <Switch
                  color='primary'
                  checked={field.value ?? false}
                  onChange={e => {
                    const value = e.target.checked
                    field.onChange(value)
                  }}
                />
              }
              label={t('requireMonitoring', 'Require monitoring')}
              labelPlacement='start'
            />
          )}
        />
      </Grid>

      {watch('isMonitoringRequired') && (
        <Grid item xs={12}>
          <Controller
            control={control}
            name='monitoringFrequency'
            rules={{ required: true, min: 0 }}
            render={({ field }) => (
              <TextField
                {...field}
                type='number'
                value={field.value ?? ''}
                {...register('monitoringFrequency', { valueAsNumber: true })}
                label={t('Frequency', 'Frequency')}
                fullWidth
                required
                error={!!errors.monitoringFrequency}
                helperText={getErrorMessage(0, 1000, errors.monitoringFrequency)}
                InputProps={{
                  endAdornment: (
                    <CustomInputAdornment position='end' color='secondary'>
                      {t('hours', 'Hours')}
                    </CustomInputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Controller
          control={control}
          name='isPlugInRequired'
          render={({ field }) => (
            <CustomFormControlLabel
              value='start'
              control={
                <Switch
                  color='primary'
                  checked={field.value ?? false}
                  onChange={e => field.onChange(e.target.checked)}
                />
              }
              label={t('requirePlugIn', 'Require plug in')}
              labelPlacement='start'
            />
          )}
        />
      </Grid>
    </Grid>
  )
})
