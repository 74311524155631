import { CarrierType, ContainerHeight, ContainerType, StackingStrategy } from '@storage/app/api'
import { AllSelectOption, BooleanSelectOption } from '@storage/app/models'
import { tolgee } from '@storage/app/translation'
import { allocationRuleTemplatesService } from '@storage/services'
import * as yup from 'yup'
import {
  AllocationRuleTemplateFormProfile,
  defaultValues,
} from './allocation-rule-templates-form.profile'

const uniqueNameValidator = async (name: string, id?: string): Promise<boolean> => {
  try {
    const { data: isUnique } = await allocationRuleTemplatesService.isNameUnique(name, id)
    return isUnique
  } catch (error) {
    return false
  }
}

export const schema = (
  isATemplate?: boolean,
  validateDestinations = false,
): yup.ObjectSchema<AllocationRuleTemplateFormProfile> => {
  return yup.object({
    id: yup.string().optional(),
    name: yup
      .string()
      .required(tolgee.t('nameIsRequired', 'Name field is required'))
      .test(
        'is-name-unique',
        tolgee.t(
          'AllocationRuleNameMustBeUnique',
          'An allocation rule with the same name already exists',
        ),
        async (name, context) => {
          if (!isATemplate) return true

          const id = (context.parent as AllocationRuleTemplateFormProfile).id
          return await uniqueNameValidator(name, id)
        },
      ),
    facets: yup.object({
      size: yup.string().default(defaultValues.facets.size).required(),
      containerOperator: yup.string().default(defaultValues.facets.containerOperator),
      consignee: yup.string().default(defaultValues.facets.consignee),
      portOfDischarge: yup.string().default(defaultValues.facets.portOfDischarge),
      weightClasses: yup.array().default(defaultValues.facets.weightClasses),
      isEmpty: yup
        .string<BooleanSelectOption | AllSelectOption>()
        .default(defaultValues.facets.isEmpty)
        .required(),
      isReefer: yup.string<BooleanSelectOption>().default(defaultValues.facets.isReefer).required(),
      isDangerous: yup
        .string<BooleanSelectOption>()
        .default(defaultValues.facets.isDangerous)
        .required(),
      containerHeight: yup
        .string<ContainerHeight | AllSelectOption>()
        .default(defaultValues.facets.containerHeight)
        .required(),
      containerType: yup
        .string<ContainerType | AllSelectOption>()
        .default(defaultValues.facets.containerType)
        .required(),
      carrierType: yup
        .string<CarrierType | AllSelectOption>()
        .default(defaultValues.facets.carrierType)
        .optional(),
      customer: yup.string().default(defaultValues.facets.customer).optional(),
      containerNumber: yup.string().default(defaultValues.facets.containerNumber).optional(),
    }),
    destination: yup.string().optional(),
    destinationAndStackingStrategies: validateDestinations
      ? yup
          .array()
          .of(
            yup.object({
              destination: yup
                .object({
                  block: yup.string().required(),
                  bay: yup.string().nullable().optional(),
                  row: yup.string().nullable().optional(),
                })
                .required(),
              stackingStrategy: yup.mixed<StackingStrategy>().required(),
              position: yup.number().required(),
              id: yup.string().optional(),
            }),
          )
          .min(1, tolgee.t('atLeastOneDestinationRequired', 'At least one destination is required'))
          .required()
      : yup.array().default([]),
  })
}
