import { Chip } from '@mui/material'
import { FC } from 'react'

type Props = {
  label: string
  onDelete?: () => void
}

export const FilterChip: FC<Props> = ({ label, onDelete }) => {
  return <Chip label={label} variant={'filled'} sx={{ m: 1 }} size={'medium'} onDelete={onDelete} />
}
