import { yupResolver } from '@hookform/resolvers/yup'
import { DefaultValues, FieldValues, useForm } from 'react-hook-form'
import { ObjectSchema } from 'yup'

interface Options<TContext> {
  context?: TContext
}

const useFormWithSchema = <T extends FieldValues, TContext = unknown>(
  schema: ObjectSchema<T>,
  defaultValues: DefaultValues<T>,
  { context }: Options<TContext> = {},
) =>
  useForm<T>({
    resolver: yupResolver(schema) as any,
    defaultValues,
    context,
  })

export default useFormWithSchema
