import { Box, Grid, TextField } from '@mui/material'
import { ReeferTemperatureDto } from '@operations/app/api'
import { FormProps } from '@operations/app/models'
import { ControlledNumberField, ControlledTextField } from '@operations/components/form-controls'
import { ControlledDateTimePicker } from '@operations/components/form-controls/ControlledDateTimePicker'
import { ControlledSwitch } from '@operations/components/form-controls/ControlledSwitch'
import useFormWithSchema from '@operations/hooks/use-form-with-schema.hook'
import { useTranslate } from '@tolgee/react'
import { CustomInputAdornment, useMinimalsTheme } from '@tom-ui/ui'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { ReeferMonitoringFormMapper } from './reefer-monitoring-form.mapper'
import { ReeferMonitoringFormProfile, defaultValues } from './reefer-monitoring-form.profile'
import { schema } from './reefer-monitoring-form.scheme'

interface Props extends FormProps {
  dto?: ReeferTemperatureDto
  cargoUnitId: number
  containerNumber?: string | null
  requestedTemperature: number
  isTemperatureRequired: boolean
  isCurrentlyPluggedIn: boolean
  lastCheck?: string | null
  hideRecordedAt?: boolean
  hideContainerNumberField?: boolean
  hideAdditionalInfoField?: boolean
  onSubmit: (formValues: ReeferMonitoringFormProfile) => Promise<void> | void
}

export const ReeferMonitoringForm = ({
  id,
  dto,
  cargoUnitId,
  containerNumber,
  requestedTemperature,
  isTemperatureRequired,
  isCurrentlyPluggedIn,
  lastCheck,
  hideRecordedAt,
  hideContainerNumberField,
  hideAdditionalInfoField,
  onSubmit,
}: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { handleSubmit, formState, control, reset } =
    useFormWithSchema<ReeferMonitoringFormProfile>(schema(), defaultValues)

  useEffect(() => {
    if (dto) {
      reset({
        ...ReeferMonitoringFormMapper.mapDtoToFormValues(dto),
        cargoUnitId: cargoUnitId,
        isTemperatureRequired,
      })
    } else {
      reset({
        cargoUnitId: cargoUnitId,
        recordedAt: new Date(),
        isTemperatureRequired,
        isPluggedIn: isCurrentlyPluggedIn,
      })
    }
  }, [cargoUnitId, dto, isCurrentlyPluggedIn, isTemperatureRequired, reset])

  return (
    <Box id={id} component='form' noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={theme.customSpacing.m}>
        <Grid item xs={12}>
          {!hideContainerNumberField && (
            <TextField
              label={t('containerNumber', 'Container number')}
              fullWidth
              disabled
              value={containerNumber}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label={t('requestedTemperature', 'Requested temperature')}
            fullWidth
            disabled
            value={requestedTemperature}
            InputProps={{
              endAdornment: <CustomInputAdornment position='end'>ºC</CustomInputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label={t('lastCheck', 'Last check')}
            fullWidth
            disabled
            value={lastCheck ? dayjs(lastCheck).format('MM/DD/YYYY hh:mm A') : '-'}
          />
        </Grid>

        <Grid item xs={12}>
          {!hideRecordedAt && (
            <ControlledDateTimePicker
              name='recordedAt'
              formState={formState}
              control={control}
              label={t('recordedAt', 'Recorded at')}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={4}>
          <ControlledNumberField
            name='temperature'
            formState={formState}
            control={control}
            label={t('temperature', 'Temperature')}
            fullWidth
            endAdornment={<CustomInputAdornment position='end'>ºC</CustomInputAdornment>}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <ControlledNumberField
            name='humidity'
            formState={formState}
            control={control}
            label={t('humidity', 'Humidity')}
            fullWidth
            endAdornment={<CustomInputAdornment position='end'>%</CustomInputAdornment>}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <ControlledNumberField
            name='ventilation'
            formState={formState}
            control={control}
            label={t('ventilation', 'Ventilation')}
            fullWidth
            endAdornment={
              <CustomInputAdornment position='end'>{t('m³/h', 'm³/h')}</CustomInputAdornment>
            }
          />
        </Grid>

        <Grid item xs={12}>
          <ControlledSwitch
            name='isPluggedIn'
            formState={formState}
            control={control}
            label={t('isPluggedIn', 'Plugged in')}
          />
        </Grid>

        <Grid item xs={12}>
          {!hideAdditionalInfoField && (
            <ControlledTextField
              name='additionalInfo'
              formState={formState}
              control={control}
              label={t('additionalErrorInfo', 'Additional error info')}
              multiline
              minRows={2}
              maxRows={3}
              fullWidth
            />
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
