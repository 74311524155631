import { createSvgIcon } from '@mui/material'

export const PowerTimeIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6.33333 13.3333V12L4.38333 10.05C4.26111 9.92778 4.16667 9.78611 4.1 9.625C4.03333 9.46389 4 9.29445 4 9.11667V6C4 5.63333 4.13056 5.31944 4.39167 5.05833C4.65278 4.79722 4.96667 4.66667 5.33333 4.66667H6L5.33333 5.33333V2.66667C5.33333 2.47778 5.39722 2.31944 5.525 2.19167C5.65278 2.06389 5.81111 2 6 2C6.18889 2 6.34722 2.06389 6.475 2.19167C6.60278 2.31944 6.66667 2.47778 6.66667 2.66667V4.66667H9.33333V2.66667C9.33333 2.47778 9.39722 2.31944 9.525 2.19167C9.65278 2.06389 9.81111 2 10 2C10.1889 2 10.3472 2.06389 10.475 2.19167C10.6028 2.31944 10.6667 2.47778 10.6667 2.66667V5.33333L10 4.66667H10.6667C11.0333 4.66667 11.3472 4.79722 11.6083 5.05833C11.8694 5.31944 12 5.63333 12 6V9.11667C12 9.29445 11.9667 9.46389 11.9 9.625C11.8333 9.78611 11.7389 9.92778 11.6167 10.05L9.66667 12V13.3333C9.66667 13.5222 9.60278 13.6806 9.475 13.8083C9.34722 13.9361 9.18889 14 9 14H7C6.81111 14 6.65278 13.9361 6.525 13.8083C6.39722 13.6806 6.33333 13.5222 6.33333 13.3333Z'
      fill='currentColor'
    />
    <g clipPath='url(#clip0_1537_3529)'>
      <path
        d='M12.3333 11.8667V10.6667C12.3333 10.5722 12.3013 10.493 12.2375 10.4292C12.1736 10.3653 12.0944 10.3333 12 10.3333C11.9055 10.3333 11.8263 10.3653 11.7625 10.4292C11.6986 10.493 11.6666 10.5722 11.6666 10.6667V11.9917C11.6666 12.0361 11.675 12.0792 11.6916 12.1208C11.7083 12.1625 11.7333 12.2 11.7666 12.2333L12.8666 13.3333C12.9277 13.3944 13.0055 13.425 13.1 13.425C13.1944 13.425 13.2722 13.3944 13.3333 13.3333C13.3944 13.2722 13.425 13.1944 13.425 13.1C13.425 13.0055 13.3944 12.9278 13.3333 12.8667L12.3333 11.8667ZM12 15.3333C11.5388 15.3333 11.1055 15.2458 10.7 15.0708C10.2944 14.8958 9.94163 14.6583 9.64163 14.3583C9.34163 14.0583 9.10413 13.7055 8.92913 13.3C8.75413 12.8944 8.66663 12.4611 8.66663 12C8.66663 11.5389 8.75413 11.1055 8.92913 10.7C9.10413 10.2944 9.34163 9.94166 9.64163 9.64166C9.94163 9.34166 10.2944 9.10416 10.7 8.92916C11.1055 8.75416 11.5388 8.66666 12 8.66666C12.4611 8.66666 12.8944 8.75416 13.3 8.92916C13.7055 9.10416 14.0583 9.34166 14.3583 9.64166C14.6583 9.94166 14.8958 10.2944 15.0708 10.7C15.2458 11.1055 15.3333 11.5389 15.3333 12C15.3333 12.4611 15.2458 12.8944 15.0708 13.3C14.8958 13.7055 14.6583 14.0583 14.3583 14.3583C14.0583 14.6583 13.7055 14.8958 13.3 15.0708C12.8944 15.2458 12.4611 15.3333 12 15.3333Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_1537_3529'>
        <rect width='8' height='8' fill='white' transform='translate(8 8)' />
      </clipPath>
    </defs>
  </svg>,
  'PowerTimeIcon',
)
