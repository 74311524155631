import { WifiOff } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, ReactElement } from 'react'
import { appStore } from '../stores/AppStore'

type Props = {
  children?: ReactElement
}

export const OfflinePage = () => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: theme.spacing(2),
        }}
      >
        <WifiOff sx={{ fontSize: '5rem', color: theme.palette.text.secondary }} />
        <Typography variant='h1'>{t('youAreOffline', 'You are offline')}</Typography>
        <Box
          sx={{
            fontSize: '1rem',
            color: theme.palette.text.secondary,
          }}
        >
          {t(
            'pleaseCheckYourInternetConnectionBeforeContinuing',
            'Please check your internet connection before continuing',
          )}
        </Box>
      </Box>
    </Box>
  )
}

export const OfflineWrapper: FC<Props> = observer(({ children }) => {
  const isOnline = appStore.isOnline
  return ((!isOnline && <OfflinePage />) || <>{children}</>) as ReactElement
})
