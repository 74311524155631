import {
  AllocationDestinationAndStackingStrategyDto,
  AllocationRuleTemplateDto,
  CarrierVisitAllocationRuleDto,
  CarrierVisitDirection,
} from '@storage/app/api'
import { tolgee } from '@storage/app/translation'
import { DialogUtilStore } from '@storage/stores/dialog.util-store'
import { SnackbarStore } from '@storage/stores/snackbar.store'
import { action, makeObservable, observable } from 'mobx'
import { mapAllocationRuleTemplateToCarrierVisitAllocationRuleDto } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'
import { AllocationRuleTemplatesV2Store } from './allocation-rule-templates-v2.store'
import { CarrierVisitAllocationRulesV2Store } from './carrier-visit-allocation-rules-v2.store'
import { CarrierVisitAllocationRulesV2UIStore } from './carrier-visit-allocation-rules-v2.ui-store'
import { UnallocatedTurnoversStore } from '@storage/stores/unallocated-turnovers.store'

export class AllocationRuleTemplatesV2UIStore {
  searchQuery = ''

  constructor(
    private readonly _allocationRuleTemplatesStore: AllocationRuleTemplatesV2Store,
    private readonly _carrierVisitAllocationRulesStore: CarrierVisitAllocationRulesV2Store,
    private readonly _carrierVisitAllocationRulesV2UIStore: CarrierVisitAllocationRulesV2UIStore,
    private readonly _unallocatedTurnoversStore: UnallocatedTurnoversStore,
    public readonly _snackbarStore: SnackbarStore,
    public readonly dialogUtilStore: DialogUtilStore,
  ) {
    makeObservable(this, {
      searchQuery: observable,
      setSearchQuery: action,
      resetSearchFilter: action,
    })
  }

  async loadAllocationRuleTemplates() {
    this.resetSearchFilter()
    await this._allocationRuleTemplatesStore.loadAll()
  }

  resetSearchFilter() {
    this.searchQuery = ''
  }

  get allocationRuleTemplates(): AllocationRuleTemplateDto[] {
    if (!this.searchQuery) return this._allocationRuleTemplatesStore.entries

    return this._allocationRuleTemplatesStore.entries.filter(entry =>
      entry.name.toLowerCase().includes(this.searchQuery),
    )
  }

  setSearchQuery(searchQuery: string) {
    const trimmedSearchQuery = searchQuery.trim()
    if (this.searchQuery !== trimmedSearchQuery) {
      this.searchQuery = trimmedSearchQuery.toLocaleLowerCase()
    }
  }

  get searchInfoMessage() {
    if (
      this._allocationRuleTemplatesStore.entries.length &&
      this.searchQuery &&
      !this.allocationRuleTemplates.length
    ) {
      return tolgee.t(
        'allocationRuleTemplatesSearchInfoMessage',
        'No allocation rule templates match the name you searched for. Please try a different name.',
      )
    } else if (!this._allocationRuleTemplatesStore.entries.length) {
      return tolgee.t(
        'noAllocationRuleTemplates',
        'There are no allocation rule templates available. Please create a new one.',
      )
    }
  }

  async copyRuleTemplateToCarrierVisit(
    allocationRuleId: string,
    destinationAndStackingStrategies: AllocationDestinationAndStackingStrategyDto[],
    handlingDirection: CarrierVisitDirection,
    discriminator: string,
    onSuccess: (allocationRule: CarrierVisitAllocationRuleDto) => void,
    onFinally: () => void,
  ) {
    const allocationRuleTemplate = this._allocationRuleTemplatesStore.getById(allocationRuleId)

    if (!allocationRuleTemplate) return

    const carrierVisitAllocationRule = mapAllocationRuleTemplateToCarrierVisitAllocationRuleDto(
      allocationRuleTemplate,
      destinationAndStackingStrategies,
      handlingDirection,
      discriminator,
    )

    this._carrierVisitAllocationRulesStore.add(carrierVisitAllocationRule)

    let turnovers = this._unallocatedTurnoversStore.containerTurnovers

    this._carrierVisitAllocationRulesV2UIStore.allocationRules.forEach(rule => {
      turnovers = this._carrierVisitAllocationRulesV2UIStore.filterTurnoversByAllocationRule(
        rule,
        turnovers,
      )
    })

    onSuccess(carrierVisitAllocationRule)

    onFinally()
  }
}
