import { Chip, FormControl, Stack, Typography } from '@mui/material'
import { SelectOption } from '@operations/app/models'
import { SuccessIcon, useMinimalsTheme } from '@tom-ui/ui'

interface Props {
  label: string
  options: SelectOption[]
  selectedChips?: (string | number)[] | null
  onChange: (options: (string | number)[]) => void
}
export const ChipSelector = ({ label, options, selectedChips, onChange }: Props) => {
  const theme = useMinimalsTheme()

  const isSelected = (option: SelectOption) => {
    return selectedChips?.some(x => x === option.value)
  }

  const handleChipClick = (option: SelectOption) => {
    const newValue = selectedChips ? [...selectedChips] : []

    if (newValue.length > 0) {
      const index = newValue.findIndex(x => x === option.value)

      if (index === -1) {
        newValue.push(option.value)
      } else {
        newValue.splice(index, 1)
      }
    } else {
      newValue.push(option.value)
    }

    onChange(newValue)
  }

  const handleChipDelete = (option: SelectOption) => {
    return isSelected(option)
      ? () => {
          const newValue = selectedChips ? [...selectedChips] : []

          if (newValue.length > 0) {
            const index = newValue.findIndex(x => x === option.value)

            if (index >= 0) {
              newValue.splice(index, 1)
            }
          }

          onChange(newValue)
        }
      : undefined
  }

  return (
    <FormControl component='fieldset' variant='standard' fullWidth>
      <Typography variant='subtitle2' marginBottom={theme.customSpacing.m}>
        {label}
      </Typography>
      <Stack flexDirection='row' flexWrap='wrap' gap={theme.customSpacing.xs}>
        {options?.map(option => (
          <Chip
            key={option.value}
            label={option.label}
            onClick={() => {
              handleChipClick(option)
            }}
            color={isSelected(option) ? 'primary' : 'default'}
            onDelete={handleChipDelete(option)}
            icon={isSelected(option) ? <SuccessIcon /> : undefined}
          ></Chip>
        ))}
      </Stack>
    </FormControl>
  )
}
