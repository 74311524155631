import { CarrierType, ContainerHeight, ContainerStatus, ContainerType } from '@storage/app/api'
import { AllSelectOption, BooleanSelectOption, UnknownSelectOption } from '@storage/app/models'

export interface ContainerTurnoversFilterFormProfile {
  containerOrReferenceNumber: string
  inboundCarrierType: CarrierType | AllSelectOption
  outboundCarrierType: CarrierType | UnknownSelectOption | AllSelectOption
  inboundCarrier: string | AllSelectOption
  outboundCarrier: string | AllSelectOption
  isEmpty: BooleanSelectOption | AllSelectOption
  size: string | AllSelectOption
  containerIsoCode: string
  typeCode: string
  portOfLoading: string
  portOfDischarge: string
  isReefer: BooleanSelectOption | AllSelectOption
  isDamaged: BooleanSelectOption | AllSelectOption
  imoClasses: Array<string>
  isRestow: BooleanSelectOption | AllSelectOption
  containerOperator: string
  isDangerous: BooleanSelectOption | AllSelectOption
  locationStatus: ContainerStatus | AllSelectOption
  containerType: ContainerType | AllSelectOption
  containerHeight: ContainerHeight | AllSelectOption
  isInTerminal: BooleanSelectOption | AllSelectOption
  weightClasses: Array<string>
}

export type ContainerTurnoversFilterFormProfileKey = keyof ContainerTurnoversFilterFormProfile

export const defaultValues: ContainerTurnoversFilterFormProfile = {
  imoClasses: [],
  weightClasses: [],
  containerIsoCode: '',
  containerOrReferenceNumber: '',
  portOfDischarge: '',
  portOfLoading: '',
  containerOperator: '',
  typeCode: '',
  inboundCarrier: 'All',
  inboundCarrierType: 'All',
  isRestow: 'All',
  isDamaged: 'All',
  isDangerous: 'All',
  isEmpty: 'All',
  isReefer: 'All',
  locationStatus: 'All',
  outboundCarrier: 'All',
  outboundCarrierType: 'All',
  size: 'All',
  containerType: 'All',
  containerHeight: 'All',
  isInTerminal: 'All',
}
