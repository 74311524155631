import {
  CarrierType,
  CarrierVisitDirection,
  GlobalAllocationRuleDto,
  GlobalAllocationRulesApi,
  GlobalAllocationRulesCreateGlobalRuleAndAllocationRequest,
} from '@storage/app/api'

export class GlobalAllocationRuleService {
  constructor(private api: GlobalAllocationRulesApi) {}

  async get(
    direction?: CarrierVisitDirection,
    carrierType?: CarrierType,
  ): Promise<Array<GlobalAllocationRuleDto>> {
    const { data: globalAllocationRules } = await this.api.get({
      direction,
      carrierType,
    })
    return globalAllocationRules
  }

  async create(
    globalAllocationRulesCreateGlobalRuleAndAllocationRequest?: GlobalAllocationRulesCreateGlobalRuleAndAllocationRequest,
  ): Promise<void> {
    await this.api.createGlobalRuleAndAllocation({
      globalAllocationRulesCreateGlobalRuleAndAllocationRequest,
    })
  }
}
