import { Box, Typography } from '@mui/material'
import { UnallocatedTurnoversBreakDown } from '@storage/stores/carrier-visit.store'
import { useTranslate } from '@tolgee/react'
import { TurnoverBreakdownLabel } from './UnAllocatedBreakdownLabel'
import { UnAllocatedItem } from './UnAllocatedItem'

interface Props {
  unallocatedContainersBreakdown: UnallocatedTurnoversBreakDown[]
  variant: 'standard' | 'special'
  onExpand?: (breakdownItem: UnallocatedTurnoversBreakDown) => void
  onCreateNewRule: (breakdownItem: UnallocatedTurnoversBreakDown) => void
}

const UnAllocatedContainersGroup = ({
  unallocatedContainersBreakdown,
  variant,
  onCreateNewRule,
  onExpand,
}: Props) => {
  const { t } = useTranslate()
  return (
    <Box>
      <Typography variant='h6' my={1} mx={4}>
        {variant === 'standard'
          ? t('standardContainers', 'Standard')
          : t('specialContainers', 'Special')}
      </Typography>
      {unallocatedContainersBreakdown.map((item, i) => (
        <UnAllocatedItem
          key={`unallocated_item-${i}`}
          breakdownItem={item}
          label={<TurnoverBreakdownLabel breakdownItem={item} />}
          total={item.value.length}
          onCreateNewRule={onCreateNewRule}
          onExpand={onExpand}
        />
      ))}
    </Box>
  )
}

export default UnAllocatedContainersGroup
