import { Button, Card, CardContent, CardHeader, Divider } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { useNavigate } from 'react-router'
import { ContainerAggregation, ContainerSummary } from './ContainerAggregation'
import { PieGraph, PieItem } from './PieGraph'

interface Props {
  title: string
  seeMorePath: string
  graphItems: PieItem[]
  containersIn: ContainerSummary
  containersOut: ContainerSummary
  dataCy?: string
}

export const VisitCard = ({
  title,
  seeMorePath,
  graphItems,
  containersIn,
  containersOut,
  dataCy,
}: Props) => {
  const { t } = useTranslate()
  const navigate = useNavigate()

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          <Button onClick={() => navigate(seeMorePath)} color='inherit'>
            {t('seeMore', 'See More')}
          </Button>
        }
      />
      <CardContent>
        <Stack gap={2} justifyContent='space-between' height='100%'>
          <PieGraph items={graphItems} dataCy={dataCy} />
          <Divider />
          <Box height='100%'>
            <ContainerAggregation
              containersIn={containersIn}
              containersOut={containersOut}
              dataCy={dataCy}
            />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  )
}
