import { Autocomplete, TextField } from '@mui/material'
import { RailTrackResponseDto } from '@planning/app/api'
import { useTranslate } from '@tolgee/react'
import { useEffect } from 'react'

interface IProps {
  label: string
  value?: string
  onChange: (railTrackId?: string) => void
  options: RailTrackResponseDto[]
  hasError: boolean
}

const RailTrackAutocomplete = ({ label, options, value, onChange, hasError }: IProps) => {
  const singleOption = options.length === 1 ? options[0] : null
  const { t } = useTranslate()

  useEffect(() => {
    if (singleOption) {
      onChange(singleOption.id)
    }
  }, [singleOption, onChange])

  return (
    <Autocomplete
      disablePortal
      getOptionLabel={railTrack => railTrack?.name}
      options={options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={singleOption ?? options.find((x: any) => x?.id === value) ?? null}
      onChange={(_, railTrack) => {
        onChange(railTrack?.id)
      }}
      disabled={!!singleOption}
      renderInput={params => (
        <TextField
          {...params}
          data-cy='rail-track-autocomplete'
          label={label}
          error={hasError}
          helperText={hasError ? t('fieldIsRequired', 'Field is required') : undefined}
        />
      )}
    />
  )
}

export default RailTrackAutocomplete
