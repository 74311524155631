import { Checkbox } from '@mui/material'
import { FC } from 'react'

type Props = {
  value: boolean | 'indeterminate'
  handleOnChange: (checked: boolean) => void
}

export const MultiselectCheckbox: FC<Props> = ({ value, handleOnChange }) => {
  return (
    <Checkbox
      checked={value === true}
      indeterminate={value === 'indeterminate'}
      color='default'
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        handleOnChange(event.target.checked)
      }}
    />
  )
}
