import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import EditIcon from '@mui/icons-material/Edit'
import { Box, IconButton, Paper, Stack, Typography } from '@mui/material'
import { AllocationDestinationAndStackingStrategyDto } from '@storage/app/api'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { mapYardPositionDtoToYardPositionDescriptor } from '../forms/allocation-rule-templates-form/allocation-rule-templates-form.mapper'
import { useStackingStrategyOptions } from '../hooks/use-stacking-strategy-options.hook'
import { useTranslate } from '@tolgee/react'

interface DestinationsListProps {
  label: string
  destinationAndStackingStrategies: AllocationDestinationAndStackingStrategyDto[]
  onAdd: () => void
  onEdit: (id: number) => void
  onDelete: (id: number) => void
  onReorder: (startIndex: number, endIndex: number) => void
}

interface DestinationItemProps {
  id: number
  destinationAndStackingStrategy: AllocationDestinationAndStackingStrategyDto
  onEdit: (id: number) => void
  onDelete: (id: number) => void
  index: number
}

const DestinationItem = ({
  id,
  destinationAndStackingStrategy,
  onEdit,
  onDelete,
  index,
}: DestinationItemProps) => {
  const { getStackingStrategyTranslationKey } = useStackingStrategyOptions()
  const { t } = useTranslate()
  return (
    <Draggable draggableId={id.toString()} index={index}>
      {provided => (
        <Paper
          elevation={0}
          sx={{ p: 2, mb: 1, border: '1px solid', borderColor: 'divider' }}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box {...provided.dragHandleProps} sx={{ mr: 1, cursor: 'move' }}>
                <DragIndicatorIcon />
              </Box>
              <Box>
                <Typography variant='subtitle1'>
                  {mapYardPositionDtoToYardPositionDescriptor([destinationAndStackingStrategy])}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  {t(
                    getStackingStrategyTranslationKey(
                      destinationAndStackingStrategy?.stackingStrategy,
                    ),
                  )}
                </Typography>
              </Box>
            </Box>
            <Box>
              <IconButton onClick={() => onEdit(id)} size='small'>
                <EditIcon fontSize='small' />
              </IconButton>
              <IconButton onClick={() => onDelete(id)} size='small'>
                <DeleteIcon fontSize='small' />
              </IconButton>
            </Box>
          </Stack>
        </Paper>
      )}
    </Draggable>
  )
}

export const AllocationRuleDestinationsList = ({
  label,
  destinationAndStackingStrategies,
  onAdd,
  onEdit,
  onDelete,
  onReorder,
}: DestinationsListProps) => {
  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return
    onReorder(result.source.index, result.destination.index)
  }

  const sortedDestinations = [...destinationAndStackingStrategies].sort(
    (a, b) => a.position - b.position,
  )

  return (
    <Box>
      <Stack direction='row' justifyContent='space-between' alignItems='center' mb={2}>
        <Typography variant='h4'>{label}</Typography>
        <IconButton onClick={onAdd} data-cy='destination-add-button'>
          <AddIcon />
        </IconButton>
      </Stack>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='destinationsList'>
          {provided => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {sortedDestinations.map((destinationAndStackingStrategy, index) => (
                <DestinationItem
                  key={index}
                  id={index}
                  destinationAndStackingStrategy={destinationAndStackingStrategy}
                  onEdit={onEdit}
                  onDelete={onDelete}
                  index={index}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  )
}
