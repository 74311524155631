import { Box, Divider } from '@mui/material'
import { CarrierVisitAllocationRuleDto, CarrierVisitDirection } from '@storage/app/api' // Adjust the import path as needed
import { Draggable } from 'react-beautiful-dnd'
import { AllocationRuleType } from '../interfaces'
import CarrierVisitAllocationRulesListItem from './CarrierVisitAllocationRulesListItem' // Adjust the import path as needed
import { CarrierVisitAllocationRulesV2UIStore } from '../stores/carrier-visit-allocation-rules-v2.ui-store'
import { GlobalAllocationRulesUIStore } from '@storage/pages/global-allocation-rules/stores/global-allocation-rules.ui-store'

interface DraggableAllocationRuleListItemProps {
  allocationRule: CarrierVisitAllocationRuleDto
  index: number
  isLast: boolean
  variant: AllocationRuleType
  carrierVisitDirection?: CarrierVisitDirection
  uiStore: CarrierVisitAllocationRulesV2UIStore | GlobalAllocationRulesUIStore
  editable?: boolean
}

const DraggableAllocationRuleListItem = ({
  allocationRule,
  index,
  isLast,
  variant,
  carrierVisitDirection,
  uiStore,
  editable,
}: DraggableAllocationRuleListItemProps) => (
  <Draggable draggableId={allocationRule.id.toString()} index={index}>
    {provided => (
      <Box
        ref={provided.innerRef}
        {...provided.draggableProps}
        sx={{
          background: 'white',
        }}
      >
        <CarrierVisitAllocationRulesListItem
          uiStore={uiStore}
          allocationRule={allocationRule}
          dragHandleProps={provided.dragHandleProps}
          carrierVisitDirection={carrierVisitDirection}
          variant={variant}
          editable={editable}
        />
        {!isLast && <Divider />}
      </Box>
    )}
  </Draggable>
)

export default DraggableAllocationRuleListItem
