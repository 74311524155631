import { Box, DialogContent, Step, StepLabel, Stepper, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import {
  CarrierVisitDirection,
  CreateContainerVisitCommand,
  UpsertContainerCommand,
} from '@planning/app/api'
import { containerService } from '@planning/services'
import { useTranslate } from '@tolgee/react'
import { ConfirmationActions, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useMemo, useState } from 'react'
import { CreateOrderPageForm, IOrderFormData } from './components/CreateOrderPageForm'
import { SearchContainerVisit } from './components/SearchContainerVisit'
import { ContainerAutocompleteStore } from './stores/ContainerAutocompleteStore'
import { OrderListStore } from './stores/OrderListStore'
import { IAmAContainerVisitToo } from './stores/SelectOrderViewStore'

interface Props {
  forcedDirection?: CarrierVisitDirection
  containerVisit?: IAmAContainerVisitToo
  isTruckAppointment?: boolean
  orderCreationOnly?: boolean
}

export const CreateOrdersPage: FC<Props> = observer(
  ({ forcedDirection, containerVisit, isTruckAppointment, orderCreationOnly }) => {
    //TODO Refactor to use a new SelectOrderViewStore with useMemo
    const {
      orderItemStore,
      selectOrderViewStore,
      appViewStore,
      vesselItemStore,
      truckItemStore,
      dialogStore,
    } = usePlanningStore()

    const containerAutocompleteStore = useMemo(() => new ContainerAutocompleteStore(), [])
    const orderListStore = useMemo(
      () => new OrderListStore(orderItemStore, vesselItemStore, truckItemStore),
      [orderItemStore, truckItemStore, vesselItemStore],
    )

    const { t } = useTranslate()
    const theme = useMinimalsTheme()

    const [activeStep, setActiveStep] = useState(0)

    const steps = [t('findUnit', 'Find Unit'), t('orderDetails', 'Order details')]

    const handleNext = () => {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }

    const handleBack = () => {
      setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    const onAddContainerFormSubmit = async (data: UpsertContainerCommand) => {
      try {
        const containerId = await containerService.post(data)
        selectOrderViewStore.selectContainerVisit()
        selectOrderViewStore.setContainerId(containerId)
        if (containerVisit) {
          selectOrderViewStore.selectContainerVisit(containerVisit)
        }
        handleNext()
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
      }
    }

    const onCreateOrderFormSubmit = async (data: IOrderFormData) => {
      try {
        const cmd: CreateContainerVisitCommand = {
          containerId: data.containerId,
          containerNumber: data.containerNumber,
          containerIsoCode: data.containerIsoCode,
          referenceNumber: data.referenceNumber,
          consignee: data.consignee,
          customerId: data.customer?.id,
          notes: data?.notes,
          operationalInstructions: data?.operationalInstructions,
          inboundTransportData: selectOrderViewStore.getTransportData(
            CarrierVisitDirection.Inbound,
            data,
          ),
          outboundTransportData: selectOrderViewStore.getTransportData(
            CarrierVisitDirection.Outbound,
            data,
          ),
          inboundCargo: selectOrderViewStore.getCargoData(data.inboundCargo),
          outboundCargo: selectOrderViewStore.getCargoData(data.outboundCargo),
          inboundHasSeals: data.inboundHasSeals,
          outboundHasSeals: data.outboundHasSeals,
          inboundSeals: data.inboundSeals,
          outboundSeals: data.outboundSeals,
        }

        await containerService.createContainerVisit(data.containerId, cmd)

        dialogStore.close()
        appViewStore.setShowAlert(
          'success',
          t('orderCreatedSuccessfully', 'Order Created Successfully'),
        )
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
      }
    }

    const CreateOrderStepper = () => (
      <Box
        sx={{
          mb: '2rem',
          p: '2rem 0',
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          borderTop: `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Stepper activeStep={activeStep} alternativeLabel sx={{ width: '100%' }}>
          {steps.map(label => {
            return (
              <Step key={label}>
                <StepLabel sx={{ fontSize: '1.5rem !important' }}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </Box>
    )

    const Title = () => (
      <Typography variant='h4' m='1rem 0' align='center'>
        {t('createOrder', 'Create Order')}
      </Typography>
    )

    return (
      <>
        <Title />
        <DialogContent sx={{ paddingX: '0 !important' }}>
          <CreateOrderStepper />

          <Box paddingX={theme.customSpacing.l}>
            {activeStep === 0 && (
              <SearchContainerVisit
                searchStore={containerAutocompleteStore}
                listStore={orderListStore}
                viewStore={selectOrderViewStore}
                orderCreationOnly={orderCreationOnly}
                onCreateNewOrderClick={() => {
                  selectOrderViewStore.selectContainerVisit()

                  if (containerVisit) {
                    selectOrderViewStore.selectContainerVisit(containerVisit)
                  }

                  handleNext()
                }}
                onNewContainerSubmit={onAddContainerFormSubmit}
                showHavingOpenOrdersWarning
              />
            )}
            {activeStep === 1 && (
              <CreateOrderPageForm
                store={selectOrderViewStore}
                forcedDirection={forcedDirection}
                isTruckAppointment={isTruckAppointment}
                onSubmit={onCreateOrderFormSubmit}
              />
            )}
          </Box>
        </DialogContent>
        {activeStep === 1 && (
          <ConfirmationActions
            formId='order-form'
            primaryActionText={t('submit', 'Submit')}
            closeLabel={t('back', 'Back')}
            onClose={() => {
              handleBack()
            }}
          />
        )}
      </>
    )
  },
)
