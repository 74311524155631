import { Container } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

import { usePlanningStore } from '@planning/AppProvider'
import { serviceOrdersService } from '@planning/services'
import {
  submitCoolingOrder,
  submitCreateCoolingOrders,
} from '../../Helpers/CoolingOrderDialogUtils'
import { CoolingOrderDialogViewStore } from '../../Stores/CoolingOrderDialogViewStore'
import { ICoolingOrder } from '../../Stores/ServiceOrdersViewStore'
import { CoolingOrderDialogRender } from './CoolingOrderDialogRender'

export const CoolingOrderDialog: FC<{
  store: CoolingOrderDialogViewStore
  onClose: () => void
}> = observer(({ store, onClose }) => {
  const { t } = useTranslate()
  const { appViewStore } = usePlanningStore()

  const handleOverwriteConfirmation = async () => {
    if (store.coolingOrder) {
      const journeyIds = store.selectedContainerJourneys.map(journey => journey.id)
      try {
        await submitCreateCoolingOrders(store.coolingOrder, journeyIds)
        appViewStore.setShowAlert(
          'success',
          t('coolingOrdersSavedSuccessfully', 'Cooling orders saved successfully'),
        )
        onClose()
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
      }
    }
  }

  const handleSubmit = async (coolingOrder: ICoolingOrder) => {
    if (!validateContainerSelection()) {
      store.setIsError(true)
      return
    }

    store.setCoolingOrder(coolingOrder)
    try {
      await submitCoolingOrder(coolingOrder, store)

      if (!store.showOverwriteConfirmationDialog) {
        appViewStore.setShowAlert(
          'success',
          t('coolingOrdersSavedSuccessfully', 'Cooling orders saved successfully'),
        )
        onClose()
      }
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  const validateContainerSelection = () => {
    if (store.selectedContainerJourneys.length === 0) return false
    return true
  }

  const handleDelete = async () => {
    if (store.selectedContainerJourneys.length !== 1) return

    const journey = store.selectedContainerJourneys[0]
    if (journey.coolingOrderId) {
      try {
        await serviceOrdersService.deleteCoolingOrder(journey.coolingOrderId)
        appViewStore.setShowAlert(
          'success',
          t('coolingOrderDeletedSuccessfully', 'Cooling Order deleted successfully!'),
        )
        onClose()
      } catch (e) {
        appViewStore.setShowAlert(
          'error',
          t('failedToDeleteCoolingOrder', 'Failed to delete Cooling Order'),
        )
      }
    }
  }

  return (
    <Container>
      <ConfirmationDialog
        open={store.showDeleteConfirmationDialog}
        title={t('confirmation', 'Confirmation')}
        message={t(
          'doYouWantToRemoveThisCoolingOrder',
          'Do you want to remove this cooling order?',
        )}
        primaryActionText={t('yes', 'Yes')}
        closeLabel={t('no', 'No')}
        onConfirm={() => {
          handleDelete()
          store.setDialogOpen()
        }}
        onClose={() => store.setDialogOpen()}
      />
      <ConfirmationDialog
        open={store.showOverwriteConfirmationDialog}
        title={t('confirmation', 'Confirmation')}
        message={`${t(
          'thereAreExistingCoolingOrdersForTheSelectedContainers',
          'There are existing cooling orders for the selected containers',
        )}. ${t('doYouWantToOverwriteThem', 'Do you want to overwrite them')}?`}
        primaryActionText={t('yes', 'Yes')}
        closeLabel={t('no', 'No')}
        onConfirm={handleOverwriteConfirmation}
        onClose={() => store.setDialogOpen()}
      />

      <CoolingOrderDialogRender
        onDelete={() => store.setDialogOpen('delete')}
        onClose={onClose}
        onSubmit={handleSubmit}
        store={store}
      />
    </Container>
  )
})
