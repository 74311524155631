/* tslint:disable */
/* eslint-disable */
/**
 * TOM BILLING SERVICE API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration'
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common'
import type { RequestArgs } from './base'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base'

/**
 *
 * @export
 * @interface AuthConfiguration
 */
export interface AuthConfiguration {
  /**
   *
   * @type {string}
   * @memberof AuthConfiguration
   */
  audience: string
  /**
   *
   * @type {string}
   * @memberof AuthConfiguration
   */
  domain: string
  /**
   *
   * @type {string}
   * @memberof AuthConfiguration
   */
  clientId: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const CarrierType = {
  Universal: 'Universal',
  Truck: 'Truck',
  Vessel: 'Vessel',
  Train: 'Train',
} as const

export type CarrierType = (typeof CarrierType)[keyof typeof CarrierType]

/**
 *
 * @export
 * @interface CarrierVisitDto
 */
export interface CarrierVisitDto {
  /**
   *
   * @type {number}
   * @memberof CarrierVisitDto
   */
  id: number
  /**
   *
   * @type {CarrierType}
   * @memberof CarrierVisitDto
   */
  type: CarrierType
  /**
   *
   * @type {string}
   * @memberof CarrierVisitDto
   */
  carrierName?: string | null
  /**
   *
   * @type {string}
   * @memberof CarrierVisitDto
   */
  eta?: string | null
  /**
   *
   * @type {string}
   * @memberof CarrierVisitDto
   */
  ata?: string | null
  /**
   *
   * @type {string}
   * @memberof CarrierVisitDto
   */
  importVoyage?: string | null
  /**
   *
   * @type {string}
   * @memberof CarrierVisitDto
   */
  exportVoyage?: string | null
}
/**
 *
 * @export
 * @interface CommodityAttributeDto
 */
export interface CommodityAttributeDto {
  /**
   *
   * @type {string}
   * @memberof CommodityAttributeDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CommodityAttributeDto
   */
  value: string
}
/**
 *
 * @export
 * @interface CommodityDto
 */
export interface CommodityDto {
  /**
   *
   * @type {number}
   * @memberof CommodityDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof CommodityDto
   */
  name: string
  /**
   *
   * @type {Array<CommodityAttributeDto>}
   * @memberof CommodityDto
   */
  attributes: Array<CommodityAttributeDto>
  /**
   *
   * @type {Array<number>}
   * @memberof CommodityDto
   */
  packageIds: Array<number>
  /**
   *
   * @type {boolean}
   * @memberof CommodityDto
   */
  usedByOrder: boolean
}
/**
 *
 * @export
 * @enum {string}
 */

export const ContainerLength = {
  Twenty: 'TWENTY',
  Forty: 'FORTY',
  FortyFive: 'FORTY_FIVE',
  Other: 'OTHER',
} as const

export type ContainerLength = (typeof ContainerLength)[keyof typeof ContainerLength]

/**
 *
 * @export
 * @interface CreateCommodityCommand
 */
export interface CreateCommodityCommand {
  /**
   *
   * @type {string}
   * @memberof CreateCommodityCommand
   */
  name: string
  /**
   *
   * @type {Array<CommodityAttributeDto>}
   * @memberof CreateCommodityCommand
   */
  attributes: Array<CommodityAttributeDto>
  /**
   *
   * @type {Array<number>}
   * @memberof CreateCommodityCommand
   */
  packageIds: Array<number>
}
/**
 *
 * @export
 * @interface CreateCustomerCommand
 */
export interface CreateCustomerCommand {
  /**
   *
   * @type {string}
   * @memberof CreateCustomerCommand
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CreateCustomerCommand
   */
  abbreviation: string
  /**
   *
   * @type {string}
   * @memberof CreateCustomerCommand
   */
  address?: string | null
}
/**
 *
 * @export
 * @interface CreatePackageCommand
 */
export interface CreatePackageCommand {
  /**
   *
   * @type {string}
   * @memberof CreatePackageCommand
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof CreatePackageCommand
   */
  height?: number | null
  /**
   *
   * @type {number}
   * @memberof CreatePackageCommand
   */
  width?: number | null
  /**
   *
   * @type {number}
   * @memberof CreatePackageCommand
   */
  length?: number | null
  /**
   *
   * @type {LengthMeasurementUnit}
   * @memberof CreatePackageCommand
   */
  lengthUnit?: LengthMeasurementUnit | null
  /**
   *
   * @type {WeightMeasurementUnit}
   * @memberof CreatePackageCommand
   */
  weightUnit: WeightMeasurementUnit
}
/**
 *
 * @export
 * @interface CreateRequest
 */
export interface CreateRequest {
  /**
   *
   * @type {string}
   * @memberof CreateRequest
   */
  name: string
  /**
   *
   * @type {Array<CommodityAttributeDto>}
   * @memberof CreateRequest
   */
  attributes: Array<CommodityAttributeDto>
  /**
   *
   * @type {Array<number>}
   * @memberof CreateRequest
   */
  packageIds: Array<number>
}
/**
 *
 * @export
 * @interface CreateRequest1
 */
export interface CreateRequest1 {
  /**
   *
   * @type {string}
   * @memberof CreateRequest1
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CreateRequest1
   */
  abbreviation: string
  /**
   *
   * @type {string}
   * @memberof CreateRequest1
   */
  address?: string | null
}
/**
 *
 * @export
 * @interface CreateRequest2
 */
export interface CreateRequest2 {
  /**
   *
   * @type {string}
   * @memberof CreateRequest2
   */
  name: string
  /**
   *
   * @type {number}
   * @memberof CreateRequest2
   */
  height?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateRequest2
   */
  width?: number | null
  /**
   *
   * @type {number}
   * @memberof CreateRequest2
   */
  length?: number | null
  /**
   *
   * @type {LengthMeasurementUnit}
   * @memberof CreateRequest2
   */
  lengthUnit?: LengthMeasurementUnit | null
  /**
   *
   * @type {WeightMeasurementUnit}
   * @memberof CreateRequest2
   */
  weightUnit: WeightMeasurementUnit
}
/**
 *
 * @export
 * @interface CustomerDto
 */
export interface CustomerDto {
  /**
   *
   * @type {number}
   * @memberof CustomerDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  abbreviation: string
  /**
   *
   * @type {string}
   * @memberof CustomerDto
   */
  address?: string | null
}
/**
 *
 * @export
 * @enum {string}
 */

export const LengthMeasurementUnit = {
  Meter: 'Meter',
  Centimeter: 'Centimeter',
  Millimeters: 'Millimeters',
  Feet: 'Feet',
  Inches: 'Inches',
} as const

export type LengthMeasurementUnit =
  (typeof LengthMeasurementUnit)[keyof typeof LengthMeasurementUnit]

/**
 *
 * @export
 * @enum {string}
 */

export const OperationType = {
  Inbound: 'Inbound',
  Outbound: 'Outbound',
  Internal: 'Internal',
  Restow: 'Restow',
} as const

export type OperationType = (typeof OperationType)[keyof typeof OperationType]

/**
 *
 * @export
 * @interface OrderDto
 */
export interface OrderDto {
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  externalOrderId: number
  /**
   *
   * @type {OperationType}
   * @memberof OrderDto
   */
  handlingDirection: OperationType
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  referenceNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  customer?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  grossWeight?: string | null
  /**
   *
   * @type {WeightMeasurementUnit}
   * @memberof OrderDto
   */
  weightMeasurementUnit?: WeightMeasurementUnit | null
  /**
   *
   * @type {CommodityDto}
   * @memberof OrderDto
   */
  commodity?: CommodityDto | null
  /**
   *
   * @type {CarrierVisitDto}
   * @memberof OrderDto
   */
  carrierVisit?: CarrierVisitDto | null
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  plannedAmount?: number | null
  /**
   *
   * @type {number}
   * @memberof OrderDto
   */
  actualAmount?: number | null
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  lotNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof OrderDto
   */
  closedAt?: string | null
}
/**
 *
 * @export
 * @interface PackageDto
 */
export interface PackageDto {
  /**
   *
   * @type {number}
   * @memberof PackageDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof PackageDto
   */
  name: string
  /**
   *
   * @type {WeightMeasurementUnit}
   * @memberof PackageDto
   */
  weightUnit: WeightMeasurementUnit
  /**
   *
   * @type {number}
   * @memberof PackageDto
   */
  height?: number | null
  /**
   *
   * @type {number}
   * @memberof PackageDto
   */
  width?: number | null
  /**
   *
   * @type {number}
   * @memberof PackageDto
   */
  length?: number | null
  /**
   *
   * @type {LengthMeasurementUnit}
   * @memberof PackageDto
   */
  lengthUnit?: LengthMeasurementUnit | null
}
/**
 *
 * @export
 * @interface StockDto
 */
export interface StockDto {
  /**
   *
   * @type {number}
   * @memberof StockDto
   */
  stockId: number
  /**
   *
   * @type {string}
   * @memberof StockDto
   */
  lotNumber: string
  /**
   *
   * @type {string}
   * @memberof StockDto
   */
  commodity: string
  /**
   *
   * @type {string}
   * @memberof StockDto
   */
  packaging: string
  /**
   *
   * @type {string}
   * @memberof StockDto
   */
  customer: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const StorageReportType = {
  Standard: 'Standard',
  ExitDate: 'ExitDate',
} as const

export type StorageReportType = (typeof StorageReportType)[keyof typeof StorageReportType]

/**
 *
 * @export
 * @interface StorageSummaryDto
 */
export interface StorageSummaryDto {
  /**
   *
   * @type {string}
   * @memberof StorageSummaryDto
   */
  name: string
  /**
   *
   * @type {Array<SummaryEntryDto>}
   * @memberof StorageSummaryDto
   */
  containers: Array<SummaryEntryDto>
}
/**
 *
 * @export
 * @interface SummaryEntryDto
 */
export interface SummaryEntryDto {
  /**
   *
   * @type {ContainerLength}
   * @memberof SummaryEntryDto
   */
  type: ContainerLength
  /**
   *
   * @type {boolean}
   * @memberof SummaryEntryDto
   */
  isEmpty: boolean
  /**
   *
   * @type {boolean}
   * @memberof SummaryEntryDto
   */
  isPluggedIn: boolean
  /**
   *
   * @type {boolean}
   * @memberof SummaryEntryDto
   */
  isDangerous: boolean
  /**
   *
   * @type {boolean}
   * @memberof SummaryEntryDto
   */
  isDamaged: boolean
  /**
   *
   * @type {number}
   * @memberof SummaryEntryDto
   */
  amount: number
}
/**
 *
 * @export
 * @interface TenantDto
 */
export interface TenantDto {
  /**
   *
   * @type {number}
   * @memberof TenantDto
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof TenantDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof TenantDto
   */
  address?: string | null
}
/**
 *
 * @export
 * @interface TruckVisitOrderDto
 */
export interface TruckVisitOrderDto {
  /**
   *
   * @type {number}
   * @memberof TruckVisitOrderDto
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof TruckVisitOrderDto
   */
  externalOrderId: number
  /**
   *
   * @type {OperationType}
   * @memberof TruckVisitOrderDto
   */
  handlingDirection: OperationType
  /**
   *
   * @type {string}
   * @memberof TruckVisitOrderDto
   */
  customerName?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckVisitOrderDto
   */
  carrierName?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckVisitOrderDto
   */
  nameOfGoods?: string | null
  /**
   *
   * @type {number}
   * @memberof TruckVisitOrderDto
   */
  quantity: number
  /**
   *
   * @type {string}
   * @memberof TruckVisitOrderDto
   */
  referenceNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckVisitOrderDto
   */
  lotNumber?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckVisitOrderDto
   */
  packageName?: string | null
  /**
   *
   * @type {number}
   * @memberof TruckVisitOrderDto
   */
  grossWeight?: number | null
  /**
   *
   * @type {WeightMeasurementUnit}
   * @memberof TruckVisitOrderDto
   */
  weightMeasurementUnit?: WeightMeasurementUnit | null
}
/**
 *
 * @export
 * @interface TruckVisitSignaturesDto
 */
export interface TruckVisitSignaturesDto {
  /**
   *
   * @type {string}
   * @memberof TruckVisitSignaturesDto
   */
  operatorSignature?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckVisitSignaturesDto
   */
  operatorName?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckVisitSignaturesDto
   */
  operatorSignatureCreatedTimestamp?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckVisitSignaturesDto
   */
  driverSignature?: string | null
  /**
   *
   * @type {string}
   * @memberof TruckVisitSignaturesDto
   */
  driverSignatureCreatedTimestamp?: string | null
}
/**
 *
 * @export
 * @interface UpdateCommodityCommand
 */
export interface UpdateCommodityCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateCommodityCommand
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateCommodityCommand
   */
  name: string
  /**
   *
   * @type {Array<CommodityAttributeDto>}
   * @memberof UpdateCommodityCommand
   */
  attributes: Array<CommodityAttributeDto>
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateCommodityCommand
   */
  packageIds: Array<number>
}
/**
 *
 * @export
 * @interface UpdateCustomerCommand
 */
export interface UpdateCustomerCommand {
  /**
   *
   * @type {number}
   * @memberof UpdateCustomerCommand
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerCommand
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerCommand
   */
  abbreviation: string
  /**
   *
   * @type {string}
   * @memberof UpdateCustomerCommand
   */
  address?: string | null
}
/**
 *
 * @export
 * @interface UpdateRequest
 */
export interface UpdateRequest {
  /**
   *
   * @type {number}
   * @memberof UpdateRequest
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateRequest
   */
  name: string
  /**
   *
   * @type {Array<CommodityAttributeDto>}
   * @memberof UpdateRequest
   */
  attributes: Array<CommodityAttributeDto>
  /**
   *
   * @type {Array<number>}
   * @memberof UpdateRequest
   */
  packageIds: Array<number>
}
/**
 *
 * @export
 * @interface UpdateRequest1
 */
export interface UpdateRequest1 {
  /**
   *
   * @type {number}
   * @memberof UpdateRequest1
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof UpdateRequest1
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof UpdateRequest1
   */
  abbreviation: string
  /**
   *
   * @type {string}
   * @memberof UpdateRequest1
   */
  address?: string | null
}
/**
 *
 * @export
 * @interface VesselSummaryDto
 */
export interface VesselSummaryDto {
  /**
   *
   * @type {string}
   * @memberof VesselSummaryDto
   */
  name: string
  /**
   *
   * @type {Array<SummaryEntryDto>}
   * @memberof VesselSummaryDto
   */
  discharge: Array<SummaryEntryDto>
  /**
   *
   * @type {Array<SummaryEntryDto>}
   * @memberof VesselSummaryDto
   */
  load: Array<SummaryEntryDto>
  /**
   *
   * @type {Array<SummaryEntryDto>}
   * @memberof VesselSummaryDto
   */
  restow: Array<SummaryEntryDto>
}
/**
 *
 * @export
 * @interface WebAppConfiguration
 */
export interface WebAppConfiguration {
  /**
   *
   * @type {WebAppConfigurationAuth}
   * @memberof WebAppConfiguration
   */
  auth: WebAppConfigurationAuth
}
/**
 *
 * @export
 * @interface WebAppConfigurationAuth
 */
export interface WebAppConfigurationAuth {
  /**
   *
   * @type {string}
   * @memberof WebAppConfigurationAuth
   */
  audience: string
  /**
   *
   * @type {string}
   * @memberof WebAppConfigurationAuth
   */
  domain: string
  /**
   *
   * @type {string}
   * @memberof WebAppConfigurationAuth
   */
  clientId: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const WeightMeasurementUnit = {
  Kilogram: 'Kilogram',
  Ton: 'Ton',
} as const

export type WeightMeasurementUnit =
  (typeof WeightMeasurementUnit)[keyof typeof WeightMeasurementUnit]

/**
 * CarrierVisitsApi - axios parameter creator
 * @export
 */
export const CarrierVisitsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} [truckVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTruckVisitOrders: async (
      truckVisitId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/CarrierVisits/getTruckVisitOrders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (truckVisitId !== undefined) {
        localVarQueryParameter['TruckVisitId'] = truckVisitId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [truckVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTruckVisitSignatures: async (
      truckVisitId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/CarrierVisits/getTruckVisitSignatures`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (truckVisitId !== undefined) {
        localVarQueryParameter['TruckVisitId'] = truckVisitId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {boolean} [notDepartedVisit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTruckVisits: async (
      notDepartedVisit?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/CarrierVisits/getTruckVisits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (notDepartedVisit !== undefined) {
        localVarQueryParameter['NotDepartedVisit'] = notDepartedVisit
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {boolean} [onlyDepartedVisit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVesselVisits: async (
      onlyDepartedVisit?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/CarrierVisits/getVesselVisits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (onlyDepartedVisit !== undefined) {
        localVarQueryParameter['OnlyDepartedVisit'] = onlyDepartedVisit
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CarrierVisitsApi - functional programming interface
 * @export
 */
export const CarrierVisitsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CarrierVisitsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} [truckVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTruckVisitOrders(
      truckVisitId?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TruckVisitOrderDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTruckVisitOrders(
        truckVisitId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [truckVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTruckVisitSignatures(
      truckVisitId?: number,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TruckVisitSignaturesDto>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTruckVisitSignatures(
        truckVisitId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {boolean} [notDepartedVisit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTruckVisits(
      notDepartedVisit?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarrierVisitDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTruckVisits(
        notDepartedVisit,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {boolean} [onlyDepartedVisit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVesselVisits(
      onlyDepartedVisit?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CarrierVisitDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselVisits(
        onlyDepartedVisit,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CarrierVisitsApi - factory interface
 * @export
 */
export const CarrierVisitsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CarrierVisitsApiFp(configuration)
  return {
    /**
     *
     * @param {number} [truckVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTruckVisitOrders(
      truckVisitId?: number,
      options?: any,
    ): AxiosPromise<Array<TruckVisitOrderDto>> {
      return localVarFp
        .getTruckVisitOrders(truckVisitId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [truckVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTruckVisitSignatures(
      truckVisitId?: number,
      options?: any,
    ): AxiosPromise<TruckVisitSignaturesDto> {
      return localVarFp
        .getTruckVisitSignatures(truckVisitId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {boolean} [notDepartedVisit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTruckVisits(
      notDepartedVisit?: boolean,
      options?: any,
    ): AxiosPromise<Array<CarrierVisitDto>> {
      return localVarFp
        .getTruckVisits(notDepartedVisit, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {boolean} [onlyDepartedVisit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVesselVisits(
      onlyDepartedVisit?: boolean,
      options?: any,
    ): AxiosPromise<Array<CarrierVisitDto>> {
      return localVarFp
        .getVesselVisits(onlyDepartedVisit, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * CarrierVisitsApi - object-oriented interface
 * @export
 * @class CarrierVisitsApi
 * @extends {BaseAPI}
 */
export class CarrierVisitsApi extends BaseAPI {
  /**
   *
   * @param {number} [truckVisitId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public getTruckVisitOrders(truckVisitId?: number, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .getTruckVisitOrders(truckVisitId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [truckVisitId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public getTruckVisitSignatures(truckVisitId?: number, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .getTruckVisitSignatures(truckVisitId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {boolean} [notDepartedVisit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public getTruckVisits(notDepartedVisit?: boolean, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .getTruckVisits(notDepartedVisit, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {boolean} [onlyDepartedVisit]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CarrierVisitsApi
   */
  public getVesselVisits(onlyDepartedVisit?: boolean, options?: AxiosRequestConfig) {
    return CarrierVisitsApiFp(this.configuration)
      .getVesselVisits(onlyDepartedVisit, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * CommoditiesApi - axios parameter creator
 * @export
 */
export const CommoditiesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('_delete', 'id', id)
      const localVarPath = `/billingService/api/Commodities/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateRequest} [createRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (
      createRequest?: CreateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Commodities`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (query?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Commodities`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (query !== undefined) {
        localVarQueryParameter['query'] = query
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateRequest} [updateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update: async (
      updateRequest?: UpdateRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Commodities`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateRequest,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CommoditiesApi - functional programming interface
 * @export
 */
export const CommoditiesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CommoditiesApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateRequest} [createRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      createRequest?: CreateRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(createRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      query?: object,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CommodityDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(query, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateRequest} [updateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update(
      updateRequest?: UpdateRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update(updateRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CommoditiesApi - factory interface
 * @export
 */
export const CommoditiesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CommoditiesApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp._delete(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateRequest} [createRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(createRequest?: CreateRequest, options?: any): AxiosPromise<number> {
      return localVarFp.create(createRequest, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(query?: object, options?: any): AxiosPromise<Array<CommodityDto>> {
      return localVarFp.get(query, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateRequest} [updateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(updateRequest?: UpdateRequest, options?: any): AxiosPromise<number> {
      return localVarFp.update(updateRequest, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * CommoditiesApi - object-oriented interface
 * @export
 * @class CommoditiesApi
 * @extends {BaseAPI}
 */
export class CommoditiesApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommoditiesApi
   */
  public _delete(id: number, options?: AxiosRequestConfig) {
    return CommoditiesApiFp(this.configuration)
      ._delete(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateRequest} [createRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommoditiesApi
   */
  public create(createRequest?: CreateRequest, options?: AxiosRequestConfig) {
    return CommoditiesApiFp(this.configuration)
      .create(createRequest, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {object} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommoditiesApi
   */
  public get(query?: object, options?: AxiosRequestConfig) {
    return CommoditiesApiFp(this.configuration)
      .get(query, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateRequest} [updateRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CommoditiesApi
   */
  public update(updateRequest?: UpdateRequest, options?: AxiosRequestConfig) {
    return CommoditiesApiFp(this.configuration)
      .update(updateRequest, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * CustomersApi - axios parameter creator
 * @export
 */
export const CustomersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('_delete', 'id', id)
      const localVarPath = `/billingService/api/Customers/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateRequest1} [createRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (
      createRequest1?: CreateRequest1,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Customers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRequest1,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (query?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Customers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (query !== undefined) {
        localVarQueryParameter['query'] = query
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {UpdateRequest1} [updateRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update: async (
      updateRequest1?: UpdateRequest1,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Customers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateRequest1,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CustomersApi - functional programming interface
 * @export
 */
export const CustomersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CustomersApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateRequest1} [createRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      createRequest1?: CreateRequest1,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(createRequest1, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      query?: object,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(query, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {UpdateRequest1} [updateRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update(
      updateRequest1?: UpdateRequest1,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update(updateRequest1, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CustomersApi - factory interface
 * @export
 */
export const CustomersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CustomersApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp._delete(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateRequest1} [createRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(createRequest1?: CreateRequest1, options?: any): AxiosPromise<number> {
      return localVarFp.create(createRequest1, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(query?: object, options?: any): AxiosPromise<Array<CustomerDto>> {
      return localVarFp.get(query, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {UpdateRequest1} [updateRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(updateRequest1?: UpdateRequest1, options?: any): AxiosPromise<number> {
      return localVarFp.update(updateRequest1, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * CustomersApi - object-oriented interface
 * @export
 * @class CustomersApi
 * @extends {BaseAPI}
 */
export class CustomersApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public _delete(id: number, options?: AxiosRequestConfig) {
    return CustomersApiFp(this.configuration)
      ._delete(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateRequest1} [createRequest1]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public create(createRequest1?: CreateRequest1, options?: AxiosRequestConfig) {
    return CustomersApiFp(this.configuration)
      .create(createRequest1, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {object} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public get(query?: object, options?: AxiosRequestConfig) {
    return CustomersApiFp(this.configuration)
      .get(query, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {UpdateRequest1} [updateRequest1]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CustomersApi
   */
  public update(updateRequest1?: UpdateRequest1, options?: AxiosRequestConfig) {
    return CustomersApiFp(this.configuration)
      .update(updateRequest1, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * DemoGcsApi - axios parameter creator
 * @export
 */
export const DemoGcsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (path?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/DemoGcs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (path !== undefined) {
        localVarQueryParameter['path'] = path
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    download: async (path?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/DemoGcs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (path !== undefined) {
        localVarQueryParameter['path'] = path
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [path]
     * @param {number} [validMinutes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSignedUrl: async (
      path?: string,
      validMinutes?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/DemoGcs/GetSignedUrlAsync`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (path !== undefined) {
        localVarQueryParameter['path'] = path
      }

      if (validMinutes !== undefined) {
        localVarQueryParameter['validMinutes'] = validMinutes
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAllFileNames: async (
      path?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/DemoGcs/ListAllFileNamesAsync`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (path !== undefined) {
        localVarQueryParameter['path'] = path
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [folderName]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upload: async (
      folderName?: string,
      file?: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/DemoGcs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)()

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (folderName !== undefined) {
        localVarQueryParameter['folderName'] = folderName
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any)
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DemoGcsApi - functional programming interface
 * @export
 */
export const DemoGcsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DemoGcsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      path?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(path, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async download(
      path?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.download(path, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [path]
     * @param {number} [validMinutes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSignedUrl(
      path?: string,
      validMinutes?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSignedUrl(
        path,
        validMinutes,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listAllFileNames(
      path?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listAllFileNames(path, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [folderName]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upload(
      folderName?: string,
      file?: File,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upload(folderName, file, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * DemoGcsApi - factory interface
 * @export
 */
export const DemoGcsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DemoGcsApiFp(configuration)
  return {
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(path?: string, options?: any): AxiosPromise<void> {
      return localVarFp._delete(path, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    download(path?: string, options?: any): AxiosPromise<void> {
      return localVarFp.download(path, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [path]
     * @param {number} [validMinutes]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSignedUrl(path?: string, validMinutes?: number, options?: any): AxiosPromise<string> {
      return localVarFp
        .getSignedUrl(path, validMinutes, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [path]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listAllFileNames(path?: string, options?: any): AxiosPromise<Array<string>> {
      return localVarFp.listAllFileNames(path, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [folderName]
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upload(folderName?: string, file?: File, options?: any): AxiosPromise<string> {
      return localVarFp.upload(folderName, file, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * DemoGcsApi - object-oriented interface
 * @export
 * @class DemoGcsApi
 * @extends {BaseAPI}
 */
export class DemoGcsApi extends BaseAPI {
  /**
   *
   * @param {string} [path]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DemoGcsApi
   */
  public _delete(path?: string, options?: AxiosRequestConfig) {
    return DemoGcsApiFp(this.configuration)
      ._delete(path, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [path]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DemoGcsApi
   */
  public download(path?: string, options?: AxiosRequestConfig) {
    return DemoGcsApiFp(this.configuration)
      .download(path, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [path]
   * @param {number} [validMinutes]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DemoGcsApi
   */
  public getSignedUrl(path?: string, validMinutes?: number, options?: AxiosRequestConfig) {
    return DemoGcsApiFp(this.configuration)
      .getSignedUrl(path, validMinutes, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [path]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DemoGcsApi
   */
  public listAllFileNames(path?: string, options?: AxiosRequestConfig) {
    return DemoGcsApiFp(this.configuration)
      .listAllFileNames(path, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [folderName]
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DemoGcsApi
   */
  public upload(folderName?: string, file?: File, options?: AxiosRequestConfig) {
    return DemoGcsApiFp(this.configuration)
      .upload(folderName, file, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * ExportApi - axios parameter creator
 * @export
 */
export const ExportApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportBillableItemsReport: async (
      customerId?: number,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Export/billableItemsReport`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (customerId !== undefined) {
        localVarQueryParameter['CustomerId'] = customerId
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {boolean} [isDangerous]
     * @param {boolean} [isDamaged]
     * @param {boolean} [includeContainerNumber]
     * @param {boolean} [includeIsoCode]
     * @param {boolean} [includeOperator]
     * @param {boolean} [includeGrossWeight]
     * @param {boolean} [includeIsOOG]
     * @param {boolean} [includeIsReefer]
     * @param {boolean} [includeIsEmpty]
     * @param {boolean} [includeIsDangerous]
     * @param {boolean} [includeEntryDateTime]
     * @param {boolean} [includeIsDamaged]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportContainerTurnovers: async (
      isDangerous?: boolean,
      isDamaged?: boolean,
      includeContainerNumber?: boolean,
      includeIsoCode?: boolean,
      includeOperator?: boolean,
      includeGrossWeight?: boolean,
      includeIsOOG?: boolean,
      includeIsReefer?: boolean,
      includeIsEmpty?: boolean,
      includeIsDangerous?: boolean,
      includeEntryDateTime?: boolean,
      includeIsDamaged?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Export/containerTurnoversExport`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (isDangerous !== undefined) {
        localVarQueryParameter['IsDangerous'] = isDangerous
      }

      if (isDamaged !== undefined) {
        localVarQueryParameter['IsDamaged'] = isDamaged
      }

      if (includeContainerNumber !== undefined) {
        localVarQueryParameter['IncludeContainerNumber'] = includeContainerNumber
      }

      if (includeIsoCode !== undefined) {
        localVarQueryParameter['IncludeIsoCode'] = includeIsoCode
      }

      if (includeOperator !== undefined) {
        localVarQueryParameter['IncludeOperator'] = includeOperator
      }

      if (includeGrossWeight !== undefined) {
        localVarQueryParameter['IncludeGrossWeight'] = includeGrossWeight
      }

      if (includeIsOOG !== undefined) {
        localVarQueryParameter['IncludeIsOOG'] = includeIsOOG
      }

      if (includeIsReefer !== undefined) {
        localVarQueryParameter['IncludeIsReefer'] = includeIsReefer
      }

      if (includeIsEmpty !== undefined) {
        localVarQueryParameter['IncludeIsEmpty'] = includeIsEmpty
      }

      if (includeIsDangerous !== undefined) {
        localVarQueryParameter['IncludeIsDangerous'] = includeIsDangerous
      }

      if (includeEntryDateTime !== undefined) {
        localVarQueryParameter['IncludeEntryDateTime'] = includeEntryDateTime
      }

      if (includeIsDamaged !== undefined) {
        localVarQueryParameter['IncludeIsDamaged'] = includeIsDamaged
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportCustomerBillingData: async (
      customerId?: number,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Export/customerBillingExport`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (customerId !== undefined) {
        localVarQueryParameter['CustomerId'] = customerId
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Array<number>} [ids]
     * @param {boolean} [byStock]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportGcStoragePrebillingData: async (
      ids?: Array<number>,
      byStock?: boolean,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Export/gcStoragePreBillingReport`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (ids) {
        localVarQueryParameter['ids'] = ids
      }

      if (byStock !== undefined) {
        localVarQueryParameter['byStock'] = byStock
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Array<number>} [ids]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportHandlingServicesPrebillingReport: async (
      ids?: Array<number>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Export/serviceHandlingPrebillingReport`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (ids) {
        localVarQueryParameter['ids'] = ids
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [start]
     * @param {string} [end]
     * @param {StorageReportType} [storageReportType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportNonVesselContainers: async (
      start?: string,
      end?: string,
      storageReportType?: StorageReportType,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Export/storageExport`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (start !== undefined) {
        localVarQueryParameter['start'] =
          (start as any) instanceof Date ? (start as any).toISOString() : start
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] =
          (end as any) instanceof Date ? (end as any).toISOString() : end
      }

      if (storageReportType !== undefined) {
        localVarQueryParameter['storageReportType'] = storageReportType
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportServiceOrdersReport: async (
      customerId?: number,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Export/serviceOrdersReport`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (customerId !== undefined) {
        localVarQueryParameter['CustomerId'] = customerId
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [vesselVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportVesselContainers: async (
      vesselVisitId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Export/vesselExport`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (vesselVisitId !== undefined) {
        localVarQueryParameter['vesselVisitId'] = vesselVisitId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [start]
     * @param {string} [end]
     * @param {StorageReportType} [storageReportType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStorageSummary: async (
      start?: string,
      end?: string,
      storageReportType?: StorageReportType,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Export/storageSummary`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (start !== undefined) {
        localVarQueryParameter['start'] =
          (start as any) instanceof Date ? (start as any).toISOString() : start
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] =
          (end as any) instanceof Date ? (end as any).toISOString() : end
      }

      if (storageReportType !== undefined) {
        localVarQueryParameter['storageReportType'] = storageReportType
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [vesselVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVesselSummary: async (
      vesselVisitId?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Export/vesselSummary`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (vesselVisitId !== undefined) {
        localVarQueryParameter['vesselVisitId'] = vesselVisitId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ExportApi - functional programming interface
 * @export
 */
export const ExportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ExportApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportBillableItemsReport(
      customerId?: number,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportBillableItemsReport(
        customerId,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {boolean} [isDangerous]
     * @param {boolean} [isDamaged]
     * @param {boolean} [includeContainerNumber]
     * @param {boolean} [includeIsoCode]
     * @param {boolean} [includeOperator]
     * @param {boolean} [includeGrossWeight]
     * @param {boolean} [includeIsOOG]
     * @param {boolean} [includeIsReefer]
     * @param {boolean} [includeIsEmpty]
     * @param {boolean} [includeIsDangerous]
     * @param {boolean} [includeEntryDateTime]
     * @param {boolean} [includeIsDamaged]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportContainerTurnovers(
      isDangerous?: boolean,
      isDamaged?: boolean,
      includeContainerNumber?: boolean,
      includeIsoCode?: boolean,
      includeOperator?: boolean,
      includeGrossWeight?: boolean,
      includeIsOOG?: boolean,
      includeIsReefer?: boolean,
      includeIsEmpty?: boolean,
      includeIsDangerous?: boolean,
      includeEntryDateTime?: boolean,
      includeIsDamaged?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportContainerTurnovers(
        isDangerous,
        isDamaged,
        includeContainerNumber,
        includeIsoCode,
        includeOperator,
        includeGrossWeight,
        includeIsOOG,
        includeIsReefer,
        includeIsEmpty,
        includeIsDangerous,
        includeEntryDateTime,
        includeIsDamaged,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportCustomerBillingData(
      customerId?: number,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportCustomerBillingData(
        customerId,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {Array<number>} [ids]
     * @param {boolean} [byStock]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportGcStoragePrebillingData(
      ids?: Array<number>,
      byStock?: boolean,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportGcStoragePrebillingData(
        ids,
        byStock,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {Array<number>} [ids]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportHandlingServicesPrebillingReport(
      ids?: Array<number>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.exportHandlingServicesPrebillingReport(ids, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [start]
     * @param {string} [end]
     * @param {StorageReportType} [storageReportType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportNonVesselContainers(
      start?: string,
      end?: string,
      storageReportType?: StorageReportType,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportNonVesselContainers(
        start,
        end,
        storageReportType,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportServiceOrdersReport(
      customerId?: number,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportServiceOrdersReport(
        customerId,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [vesselVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportVesselContainers(
      vesselVisitId?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportVesselContainers(
        vesselVisitId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [start]
     * @param {string} [end]
     * @param {StorageReportType} [storageReportType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStorageSummary(
      start?: string,
      end?: string,
      storageReportType?: StorageReportType,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StorageSummaryDto>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStorageSummary(
        start,
        end,
        storageReportType,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [vesselVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVesselSummary(
      vesselVisitId?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VesselSummaryDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVesselSummary(
        vesselVisitId,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ExportApi - factory interface
 * @export
 */
export const ExportApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = ExportApiFp(configuration)
  return {
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportBillableItemsReport(
      customerId?: number,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .exportBillableItemsReport(customerId, from, to, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {boolean} [isDangerous]
     * @param {boolean} [isDamaged]
     * @param {boolean} [includeContainerNumber]
     * @param {boolean} [includeIsoCode]
     * @param {boolean} [includeOperator]
     * @param {boolean} [includeGrossWeight]
     * @param {boolean} [includeIsOOG]
     * @param {boolean} [includeIsReefer]
     * @param {boolean} [includeIsEmpty]
     * @param {boolean} [includeIsDangerous]
     * @param {boolean} [includeEntryDateTime]
     * @param {boolean} [includeIsDamaged]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportContainerTurnovers(
      isDangerous?: boolean,
      isDamaged?: boolean,
      includeContainerNumber?: boolean,
      includeIsoCode?: boolean,
      includeOperator?: boolean,
      includeGrossWeight?: boolean,
      includeIsOOG?: boolean,
      includeIsReefer?: boolean,
      includeIsEmpty?: boolean,
      includeIsDangerous?: boolean,
      includeEntryDateTime?: boolean,
      includeIsDamaged?: boolean,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .exportContainerTurnovers(
          isDangerous,
          isDamaged,
          includeContainerNumber,
          includeIsoCode,
          includeOperator,
          includeGrossWeight,
          includeIsOOG,
          includeIsReefer,
          includeIsEmpty,
          includeIsDangerous,
          includeEntryDateTime,
          includeIsDamaged,
          options,
        )
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportCustomerBillingData(
      customerId?: number,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .exportCustomerBillingData(customerId, from, to, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {Array<number>} [ids]
     * @param {boolean} [byStock]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportGcStoragePrebillingData(
      ids?: Array<number>,
      byStock?: boolean,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .exportGcStoragePrebillingData(ids, byStock, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {Array<number>} [ids]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportHandlingServicesPrebillingReport(ids?: Array<number>, options?: any): AxiosPromise<void> {
      return localVarFp
        .exportHandlingServicesPrebillingReport(ids, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [start]
     * @param {string} [end]
     * @param {StorageReportType} [storageReportType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportNonVesselContainers(
      start?: string,
      end?: string,
      storageReportType?: StorageReportType,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .exportNonVesselContainers(start, end, storageReportType, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportServiceOrdersReport(
      customerId?: number,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .exportServiceOrdersReport(customerId, from, to, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [vesselVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportVesselContainers(vesselVisitId?: number, options?: any): AxiosPromise<void> {
      return localVarFp
        .exportVesselContainers(vesselVisitId, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [start]
     * @param {string} [end]
     * @param {StorageReportType} [storageReportType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStorageSummary(
      start?: string,
      end?: string,
      storageReportType?: StorageReportType,
      options?: any,
    ): AxiosPromise<Array<StorageSummaryDto>> {
      return localVarFp
        .getStorageSummary(start, end, storageReportType, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [vesselVisitId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVesselSummary(vesselVisitId?: number, options?: any): AxiosPromise<VesselSummaryDto> {
      return localVarFp
        .getVesselSummary(vesselVisitId, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * ExportApi - object-oriented interface
 * @export
 * @class ExportApi
 * @extends {BaseAPI}
 */
export class ExportApi extends BaseAPI {
  /**
   *
   * @param {number} [customerId]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public exportBillableItemsReport(
    customerId?: number,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return ExportApiFp(this.configuration)
      .exportBillableItemsReport(customerId, from, to, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {boolean} [isDangerous]
   * @param {boolean} [isDamaged]
   * @param {boolean} [includeContainerNumber]
   * @param {boolean} [includeIsoCode]
   * @param {boolean} [includeOperator]
   * @param {boolean} [includeGrossWeight]
   * @param {boolean} [includeIsOOG]
   * @param {boolean} [includeIsReefer]
   * @param {boolean} [includeIsEmpty]
   * @param {boolean} [includeIsDangerous]
   * @param {boolean} [includeEntryDateTime]
   * @param {boolean} [includeIsDamaged]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public exportContainerTurnovers(
    isDangerous?: boolean,
    isDamaged?: boolean,
    includeContainerNumber?: boolean,
    includeIsoCode?: boolean,
    includeOperator?: boolean,
    includeGrossWeight?: boolean,
    includeIsOOG?: boolean,
    includeIsReefer?: boolean,
    includeIsEmpty?: boolean,
    includeIsDangerous?: boolean,
    includeEntryDateTime?: boolean,
    includeIsDamaged?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return ExportApiFp(this.configuration)
      .exportContainerTurnovers(
        isDangerous,
        isDamaged,
        includeContainerNumber,
        includeIsoCode,
        includeOperator,
        includeGrossWeight,
        includeIsOOG,
        includeIsReefer,
        includeIsEmpty,
        includeIsDangerous,
        includeEntryDateTime,
        includeIsDamaged,
        options,
      )
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [customerId]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public exportCustomerBillingData(
    customerId?: number,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return ExportApiFp(this.configuration)
      .exportCustomerBillingData(customerId, from, to, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Array<number>} [ids]
   * @param {boolean} [byStock]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public exportGcStoragePrebillingData(
    ids?: Array<number>,
    byStock?: boolean,
    options?: AxiosRequestConfig,
  ) {
    return ExportApiFp(this.configuration)
      .exportGcStoragePrebillingData(ids, byStock, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Array<number>} [ids]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public exportHandlingServicesPrebillingReport(ids?: Array<number>, options?: AxiosRequestConfig) {
    return ExportApiFp(this.configuration)
      .exportHandlingServicesPrebillingReport(ids, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [start]
   * @param {string} [end]
   * @param {StorageReportType} [storageReportType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public exportNonVesselContainers(
    start?: string,
    end?: string,
    storageReportType?: StorageReportType,
    options?: AxiosRequestConfig,
  ) {
    return ExportApiFp(this.configuration)
      .exportNonVesselContainers(start, end, storageReportType, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [customerId]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public exportServiceOrdersReport(
    customerId?: number,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return ExportApiFp(this.configuration)
      .exportServiceOrdersReport(customerId, from, to, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [vesselVisitId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public exportVesselContainers(vesselVisitId?: number, options?: AxiosRequestConfig) {
    return ExportApiFp(this.configuration)
      .exportVesselContainers(vesselVisitId, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [start]
   * @param {string} [end]
   * @param {StorageReportType} [storageReportType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public getStorageSummary(
    start?: string,
    end?: string,
    storageReportType?: StorageReportType,
    options?: AxiosRequestConfig,
  ) {
    return ExportApiFp(this.configuration)
      .getStorageSummary(start, end, storageReportType, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [vesselVisitId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExportApi
   */
  public getVesselSummary(vesselVisitId?: number, options?: AxiosRequestConfig) {
    return ExportApiFp(this.configuration)
      .getVesselSummary(vesselVisitId, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * GeneralCargoApi - axios parameter creator
 * @export
 */
export const GeneralCargoApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompletedHandlingOrders: async (
      customerId?: number,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/GeneralCargo/getCompletedHandlingOrders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (customerId !== undefined) {
        localVarQueryParameter['CustomerId'] = customerId
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompletedInboundOrders: async (
      customerId?: number,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/GeneralCargo/getCompletedInboundOrders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (customerId !== undefined) {
        localVarQueryParameter['CustomerId'] = customerId
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStockInventory: async (
      customerId?: number,
      from?: string,
      to?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/GeneralCargo/getStockInventory`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (customerId !== undefined) {
        localVarQueryParameter['CustomerId'] = customerId
      }

      if (from !== undefined) {
        localVarQueryParameter['From'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      if (to !== undefined) {
        localVarQueryParameter['To'] = (to as any) instanceof Date ? (to as any).toISOString() : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * GeneralCargoApi - functional programming interface
 * @export
 */
export const GeneralCargoApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GeneralCargoApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompletedHandlingOrders(
      customerId?: number,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompletedHandlingOrders(
        customerId,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompletedInboundOrders(
      customerId?: number,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompletedInboundOrders(
        customerId,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStockInventory(
      customerId?: number,
      from?: string,
      to?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StockDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStockInventory(
        customerId,
        from,
        to,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * GeneralCargoApi - factory interface
 * @export
 */
export const GeneralCargoApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GeneralCargoApiFp(configuration)
  return {
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompletedHandlingOrders(
      customerId?: number,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<Array<OrderDto>> {
      return localVarFp
        .getCompletedHandlingOrders(customerId, from, to, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompletedInboundOrders(
      customerId?: number,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<Array<OrderDto>> {
      return localVarFp
        .getCompletedInboundOrders(customerId, from, to, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {number} [customerId]
     * @param {string} [from]
     * @param {string} [to]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStockInventory(
      customerId?: number,
      from?: string,
      to?: string,
      options?: any,
    ): AxiosPromise<Array<StockDto>> {
      return localVarFp
        .getStockInventory(customerId, from, to, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * GeneralCargoApi - object-oriented interface
 * @export
 * @class GeneralCargoApi
 * @extends {BaseAPI}
 */
export class GeneralCargoApi extends BaseAPI {
  /**
   *
   * @param {number} [customerId]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralCargoApi
   */
  public getCompletedHandlingOrders(
    customerId?: number,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return GeneralCargoApiFp(this.configuration)
      .getCompletedHandlingOrders(customerId, from, to, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [customerId]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralCargoApi
   */
  public getCompletedInboundOrders(
    customerId?: number,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return GeneralCargoApiFp(this.configuration)
      .getCompletedInboundOrders(customerId, from, to, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} [customerId]
   * @param {string} [from]
   * @param {string} [to]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GeneralCargoApi
   */
  public getStockInventory(
    customerId?: number,
    from?: string,
    to?: string,
    options?: AxiosRequestConfig,
  ) {
    return GeneralCargoApiFp(this.configuration)
      .getStockInventory(customerId, from, to, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * InternalApiApi - axios parameter creator
 * @export
 */
export const InternalApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportJsonSnapshot: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/InternalApi/ExportJsonSnapshot`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/InternalApi/ResetData`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetDataFromJsonSnapshot: async (
      file?: File,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/InternalApi/ResetDataFromJsonSnapshot`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)()

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (file !== undefined) {
        localVarFormParams.append('file', file as any)
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveDemoData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/InternalApi/SaveDemoData`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * InternalApiApi - functional programming interface
 * @export
 */
export const InternalApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InternalApiApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportJsonSnapshot(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportJsonSnapshot(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetData(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetData(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetDataFromJsonSnapshot(
      file?: File,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetDataFromJsonSnapshot(
        file,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveDemoData(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveDemoData(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * InternalApiApi - factory interface
 * @export
 */
export const InternalApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = InternalApiApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportJsonSnapshot(options?: any): AxiosPromise<void> {
      return localVarFp.exportJsonSnapshot(options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetData(options?: any): AxiosPromise<void> {
      return localVarFp.resetData(options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {File} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetDataFromJsonSnapshot(file?: File, options?: any): AxiosPromise<void> {
      return localVarFp
        .resetDataFromJsonSnapshot(file, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveDemoData(options?: any): AxiosPromise<void> {
      return localVarFp.saveDemoData(options).then(request => request(axios, basePath))
    },
  }
}

/**
 * InternalApiApi - object-oriented interface
 * @export
 * @class InternalApiApi
 * @extends {BaseAPI}
 */
export class InternalApiApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalApiApi
   */
  public exportJsonSnapshot(options?: AxiosRequestConfig) {
    return InternalApiApiFp(this.configuration)
      .exportJsonSnapshot(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalApiApi
   */
  public resetData(options?: AxiosRequestConfig) {
    return InternalApiApiFp(this.configuration)
      .resetData(options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {File} [file]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalApiApi
   */
  public resetDataFromJsonSnapshot(file?: File, options?: AxiosRequestConfig) {
    return InternalApiApiFp(this.configuration)
      .resetDataFromJsonSnapshot(file, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InternalApiApi
   */
  public saveDemoData(options?: AxiosRequestConfig) {
    return InternalApiApiFp(this.configuration)
      .saveDemoData(options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * PackagesApi - axios parameter creator
 * @export
 */
export const PackagesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('_delete', 'id', id)
      const localVarPath = `/billingService/api/Packages/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateRequest2} [createRequest2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (
      createRequest2?: CreateRequest2,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Packages`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRequest2,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (query?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Packages`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (query !== undefined) {
        localVarQueryParameter['query'] = query
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PackagesApi - functional programming interface
 * @export
 */
export const PackagesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PackagesApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateRequest2} [createRequest2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      createRequest2?: CreateRequest2,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(createRequest2, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      query?: object,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PackageDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(query, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * PackagesApi - factory interface
 * @export
 */
export const PackagesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PackagesApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp._delete(id, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {CreateRequest2} [createRequest2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(createRequest2?: CreateRequest2, options?: any): AxiosPromise<number> {
      return localVarFp.create(createRequest2, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {object} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(query?: object, options?: any): AxiosPromise<Array<PackageDto>> {
      return localVarFp.get(query, options).then(request => request(axios, basePath))
    },
  }
}

/**
 * PackagesApi - object-oriented interface
 * @export
 * @class PackagesApi
 * @extends {BaseAPI}
 */
export class PackagesApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PackagesApi
   */
  public _delete(id: number, options?: AxiosRequestConfig) {
    return PackagesApiFp(this.configuration)
      ._delete(id, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateRequest2} [createRequest2]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PackagesApi
   */
  public create(createRequest2?: CreateRequest2, options?: AxiosRequestConfig) {
    return PackagesApiFp(this.configuration)
      .create(createRequest2, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {object} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PackagesApi
   */
  public get(query?: object, options?: AxiosRequestConfig) {
    return PackagesApiFp(this.configuration)
      .get(query, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * SyncApiApi - axios parameter creator
 * @export
 */
export const SyncApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post: async (from?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/billingService/internal/SyncApi`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      if (from !== undefined) {
        localVarQueryParameter['from'] =
          (from as any) instanceof Date ? (from as any).toISOString() : from
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncContainers: async (
      body?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/internal/SyncApi/containers`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncOrders: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/billingService/internal/SyncApi/orders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncReeferTemperatures: async (
      body?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/internal/SyncApi/reeferTemperatures`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncStrippingOrders: async (
      body?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/internal/SyncApi/strippingOrders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncStuffingOrders: async (
      body?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/internal/SyncApi/stuffingOrders`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncTenants: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/billingService/internal/SyncApi/tenants`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncTrainVisits: async (
      body?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/internal/SyncApi/TrainVisits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncTruckVisits: async (
      body?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/internal/SyncApi/TruckVisits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncVesselVisits: async (
      body?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/internal/SyncApi/VesselVisits`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncWorkInstructions: async (
      body?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/billingService/internal/SyncApi/WorkInstructions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SyncApiApi - functional programming interface
 * @export
 */
export const SyncApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SyncApiApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async post(
      from?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.post(from, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async syncContainers(
      body?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.syncContainers(body, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async syncOrders(
      body?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.syncOrders(body, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async syncReeferTemperatures(
      body?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.syncReeferTemperatures(
        body,
        options,
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async syncStrippingOrders(
      body?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.syncStrippingOrders(body, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async syncStuffingOrders(
      body?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.syncStuffingOrders(body, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async syncTenants(
      body?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.syncTenants(body, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async syncTrainVisits(
      body?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.syncTrainVisits(body, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async syncTruckVisits(
      body?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.syncTruckVisits(body, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async syncVesselVisits(
      body?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.syncVesselVisits(body, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async syncWorkInstructions(
      body?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.syncWorkInstructions(body, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SyncApiApi - factory interface
 * @export
 */
export const SyncApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = SyncApiApiFp(configuration)
  return {
    /**
     *
     * @param {string} [from]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(from?: string, options?: any): AxiosPromise<void> {
      return localVarFp.post(from, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncContainers(body?: string, options?: any): AxiosPromise<void> {
      return localVarFp.syncContainers(body, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncOrders(body?: string, options?: any): AxiosPromise<void> {
      return localVarFp.syncOrders(body, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncReeferTemperatures(body?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .syncReeferTemperatures(body, options)
        .then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncStrippingOrders(body?: string, options?: any): AxiosPromise<void> {
      return localVarFp.syncStrippingOrders(body, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncStuffingOrders(body?: string, options?: any): AxiosPromise<void> {
      return localVarFp.syncStuffingOrders(body, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncTenants(body?: string, options?: any): AxiosPromise<void> {
      return localVarFp.syncTenants(body, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncTrainVisits(body?: string, options?: any): AxiosPromise<void> {
      return localVarFp.syncTrainVisits(body, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncTruckVisits(body?: string, options?: any): AxiosPromise<void> {
      return localVarFp.syncTruckVisits(body, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncVesselVisits(body?: string, options?: any): AxiosPromise<void> {
      return localVarFp.syncVesselVisits(body, options).then(request => request(axios, basePath))
    },
    /**
     *
     * @param {string} [body]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    syncWorkInstructions(body?: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .syncWorkInstructions(body, options)
        .then(request => request(axios, basePath))
    },
  }
}

/**
 * SyncApiApi - object-oriented interface
 * @export
 * @class SyncApiApi
 * @extends {BaseAPI}
 */
export class SyncApiApi extends BaseAPI {
  /**
   *
   * @param {string} [from]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncApiApi
   */
  public post(from?: string, options?: AxiosRequestConfig) {
    return SyncApiApiFp(this.configuration)
      .post(from, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncApiApi
   */
  public syncContainers(body?: string, options?: AxiosRequestConfig) {
    return SyncApiApiFp(this.configuration)
      .syncContainers(body, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncApiApi
   */
  public syncOrders(body?: string, options?: AxiosRequestConfig) {
    return SyncApiApiFp(this.configuration)
      .syncOrders(body, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncApiApi
   */
  public syncReeferTemperatures(body?: string, options?: AxiosRequestConfig) {
    return SyncApiApiFp(this.configuration)
      .syncReeferTemperatures(body, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncApiApi
   */
  public syncStrippingOrders(body?: string, options?: AxiosRequestConfig) {
    return SyncApiApiFp(this.configuration)
      .syncStrippingOrders(body, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncApiApi
   */
  public syncStuffingOrders(body?: string, options?: AxiosRequestConfig) {
    return SyncApiApiFp(this.configuration)
      .syncStuffingOrders(body, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncApiApi
   */
  public syncTenants(body?: string, options?: AxiosRequestConfig) {
    return SyncApiApiFp(this.configuration)
      .syncTenants(body, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncApiApi
   */
  public syncTrainVisits(body?: string, options?: AxiosRequestConfig) {
    return SyncApiApiFp(this.configuration)
      .syncTrainVisits(body, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncApiApi
   */
  public syncTruckVisits(body?: string, options?: AxiosRequestConfig) {
    return SyncApiApiFp(this.configuration)
      .syncTruckVisits(body, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncApiApi
   */
  public syncVesselVisits(body?: string, options?: AxiosRequestConfig) {
    return SyncApiApiFp(this.configuration)
      .syncVesselVisits(body, options)
      .then(request => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} [body]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SyncApiApi
   */
  public syncWorkInstructions(body?: string, options?: AxiosRequestConfig) {
    return SyncApiApiFp(this.configuration)
      .syncWorkInstructions(body, options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * TenantsApi - axios parameter creator
 * @export
 */
export const TenantsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/Tenants`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * TenantsApi - functional programming interface
 * @export
 */
export const TenantsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TenantsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TenantDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * TenantsApi - factory interface
 * @export
 */
export const TenantsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TenantsApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(options?: any): AxiosPromise<TenantDto> {
      return localVarFp.get(options).then(request => request(axios, basePath))
    },
  }
}

/**
 * TenantsApi - object-oriented interface
 * @export
 * @class TenantsApi
 * @extends {BaseAPI}
 */
export class TenantsApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TenantsApi
   */
  public get(options?: AxiosRequestConfig) {
    return TenantsApiFp(this.configuration)
      .get(options)
      .then(request => request(this.axios, this.basePath))
  }
}

/**
 * WebAppConfigurationsApi - axios parameter creator
 * @export
 */
export const WebAppConfigurationsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/billingService/api/WebAppConfigurations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication Bearer required
      // oauth required
      await setOAuthToObject(localVarHeaderParameter, 'Bearer', [], configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * WebAppConfigurationsApi - functional programming interface
 * @export
 */
export const WebAppConfigurationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WebAppConfigurationsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async get(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebAppConfiguration>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.get(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * WebAppConfigurationsApi - factory interface
 * @export
 */
export const WebAppConfigurationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WebAppConfigurationsApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    get(options?: any): AxiosPromise<WebAppConfiguration> {
      return localVarFp.get(options).then(request => request(axios, basePath))
    },
  }
}

/**
 * WebAppConfigurationsApi - object-oriented interface
 * @export
 * @class WebAppConfigurationsApi
 * @extends {BaseAPI}
 */
export class WebAppConfigurationsApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAppConfigurationsApi
   */
  public get(options?: AxiosRequestConfig) {
    return WebAppConfigurationsApiFp(this.configuration)
      .get(options)
      .then(request => request(this.axios, this.basePath))
  }
}
