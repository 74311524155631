import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

export const GlobalAllocationRuleDeletionConfirmationDialog = observer(() => {
  const { t } = useTranslate()
  const { globalAllocationRulesUIStore } = useStores()

  const dialogLabels = {
    title: t('removeGlobalAllocationRule', 'Remove Global Allocation Rule?'),
    buttons: { primary: t('remove', 'Remove'), secondary: t('cancel', 'Cancel') },
    deletionConfirmationMessage: t(
      'globalAllocationRuleWillBeRemoved',
      'This global allocation rule will be removed and you cannot undo this action!',
    ),
  }

  const handleOnClose = () => globalAllocationRulesUIStore.toggleDeletionConfirmationDialog()

  const handleOnDelete = () => globalAllocationRulesUIStore.deleteAllocationRule()

  return (
    <ConfirmationDialog
      open={globalAllocationRulesUIStore.isDeletionConfirmationDialogOpen}
      title={dialogLabels.title}
      primaryActionText={dialogLabels.buttons.primary}
      closeLabel={dialogLabels.buttons.secondary}
      maxWidth='sm'
      onClose={handleOnClose}
      blockClickOutside={true}
      message={dialogLabels.deletionConfirmationMessage}
      onConfirm={handleOnDelete}
      primaryActionDataCy='allocation-confirm-btn'
    ></ConfirmationDialog>
  )
})
