import { DialogFormContainer } from '@storage/components/DialogFormContainer'
import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { PierFormMapper } from '../components/pier-form/pier-form.mapper'
import { PierFormProfile } from '../components/pier-form/pier-form.profile'
import { PierForm } from '../components/pier-form/PierForm'
import { BerthsFeatureStore } from '../stores'

interface Props {
  featureStore: BerthsFeatureStore
}

export const PiersDialogContainer = observer(({ featureStore: { piersDialog } }: Props) => {
  const { pierStore } = useStores()
  const { t } = useTranslate()

  const handleSubmit = async (formValues: PierFormProfile) => {
    if (piersDialog.formType === 'Add') {
      await pierStore.add(PierFormMapper.mapFormValuesToCreateDto(formValues))
    } else {
      await pierStore.update(PierFormMapper.mapFormValuesToUpdateDto(formValues))
    }

    piersDialog.toggle()
  }

  return (
    <DialogFormContainer
      open={piersDialog.isOpen}
      formType={piersDialog.formType}
      form={<PierForm id='pier-form' pier={piersDialog.editingEntity} onSubmit={handleSubmit} />}
      entityTitle={t('pier', 'pier')}
      onClose={() => piersDialog.toggle()}
    />
  )
})
