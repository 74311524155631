import { ListItemText, MenuItem, Typography } from '@mui/material'
import { JobDto } from '@operations/app/api'
import { SelectOption } from '@operations/app/models'
import { getJobAmountByType, JobTypes } from '@operations/app/models/operator-pages'
import { useTranslate } from '@tolgee/react'
import { ChipWithOptions } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface Props {
  jobs: JobDto[]
  selectedType: JobTypes
  onSelectType: (jobType: JobTypes) => void
}
export const JobFilterTypes = observer(({ jobs, selectedType, onSelectType }: Props) => {
  const { t } = useTranslate()

  const options: SelectOption[] = [
    {
      label: t('vessels', 'Vessels'),
      value: JobTypes.vessel,
    },
    {
      label: t('trains', 'Trains'),
      value: JobTypes.train,
    },
    {
      label: t('externalTrucks', 'External trucks'),
      value: JobTypes.truck,
    },
    {
      label: t('internal', 'Internal'),
      value: JobTypes.internal,
    },
    {
      label: t('service', 'Service'),
      value: JobTypes.service,
    },
  ]

  const optionsElements = options.map(option => (
    <MenuItem
      key={option.value}
      selected={option.value === selectedType}
      value={option.value}
      onClick={() => {
        onSelectType(option.value as JobTypes)
      }}
      data-cy={`operator-select-carrier-type-${option.value}-option`}
    >
      <ListItemText primary={option.label} primaryTypographyProps={{ variant: 'body2' }} />
      <Typography variant='body2' color='secondary'>
        {getJobAmountByType(option.value as JobTypes, jobs)}
      </Typography>
    </MenuItem>
  ))

  return (
    <ChipWithOptions
      value={options.find(x => x.value === selectedType)?.label}
      label={t('type', 'Type')}
      onReset={() => onSelectType(JobTypes.all)}
      options={optionsElements}
      dataCy='operator-select-carrier-type-chip'
    />
  )
})
