import { TextField, TextFieldProps } from '@mui/material'
import { Controller, FieldValues, FormState, UseControllerProps } from 'react-hook-form'

type FieldProps = Pick<TextFieldProps, 'label' | 'disabled' | 'fullWidth' | 'variant'>

interface Props<T extends FieldValues> extends UseControllerProps<T>, FieldProps {
  formState: FormState<T>
  min?: number
  max?: number
  integerOnly?: boolean
  endAdornment?: React.ReactNode
}

export const ControlledNumberField = <T extends FieldValues>({
  name,
  control,
  label,
  fullWidth,
  disabled,
  min,
  max,
  variant,
  formState: { errors },
  integerOnly,
  endAdornment,
}: Props<T>) => {
  return (
    <Controller
      render={({ field }) => (
        <TextField
          {...field}
          error={!!errors[name]}
          helperText={errors[name]?.message as string}
          label={label}
          inputProps={{ min, max }}
          type='number'
          fullWidth={fullWidth}
          disabled={disabled}
          variant={variant ?? 'outlined'}
          onChange={event => {
            if (integerOnly && event.target.value.match(/[^0-9]/)) {
              event.target.value = Math.floor(Number(event.target.value)).toString()
            }

            field.onChange(event)
          }}
          InputProps={{
            endAdornment: endAdornment,
          }}
        />
      )}
      name={name}
      control={control}
    />
  )
}
