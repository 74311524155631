import { Badge, Button, Divider, Popover, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useTranslate } from '@tolgee/react'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseButton,
  ErrorIcon,
  IconButton,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { CoolingOrderCardDto } from '../../models/cooling-order-model'
import { ReeferMonitoringDetailsUIStore } from '../../stores/reefer-monitoring-details.ui-store'
import { AlertsCard } from './AlertsCard'

interface Props {
  coolingOrders: CoolingOrderCardDto[]
  reeferMonitoringDetailsUIStore?: ReeferMonitoringDetailsUIStore
}
export const AlertsCardList = observer(
  ({ coolingOrders, reeferMonitoringDetailsUIStore }: Props) => {
    const theme = useMinimalsTheme()
    const { t } = useTranslate()
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const open = Boolean(anchorEl)
    const id = open ? 'notification-popover' : undefined

    const handleClose = () => {
      setAnchorEl(null)
    }
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }

    const ordersWithAlerts = coolingOrders.filter(c => c.alerts.length > 0)
    const totalContainerWithAlerts = ordersWithAlerts.length
    const totalPages = Math.ceil(totalContainerWithAlerts / 2)

    return (
      <>
        <Badge badgeContent={totalContainerWithAlerts} color='error'>
          <Button
            variant='contained'
            onClick={handleClick}
            startIcon={<ErrorIcon />}
            color='inherit'
          >
            {t('alerts', 'Alerts')}
          </Button>
        </Badge>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              style: {
                width: '400px',
              },
            },
          }}
        >
          <Stack direction='column'>
            <Stack
              sx={{
                backgroundColor: theme.palette.background.default,
                padding: theme.customSpacing.m,
              }}
              flexDirection='row'
              flexWrap='wrap'
              justifyContent='space-between'
            >
              <Stack flexDirection='column' flexWrap='wrap'>
                <Typography variant='subtitle1'>{t('reviewAlerts', 'Review alerts')}</Typography>
                <Typography variant='body2' color='secondary'>
                  {t('youHaveXAlerts', 'You have {alerts} alerts', {
                    alerts: totalContainerWithAlerts,
                  })}
                </Typography>
              </Stack>
              <CloseButton onClose={handleClose} />
            </Stack>
            <Divider />
            {totalContainerWithAlerts > 0 && (
              <>
                {currentPageIndex * 2 + 1 <= totalContainerWithAlerts && (
                  <AlertsCard
                    key={ordersWithAlerts[currentPageIndex * 2].id}
                    coolingOrder={ordersWithAlerts[currentPageIndex * 2]}
                    reeferMonitoringDetailsUIStore={reeferMonitoringDetailsUIStore}
                  />
                )}
                {currentPageIndex * 2 + 2 <= totalContainerWithAlerts && (
                  <AlertsCard
                    key={ordersWithAlerts[currentPageIndex * 2 + 1].id}
                    coolingOrder={ordersWithAlerts[currentPageIndex * 2 + 1]}
                    reeferMonitoringDetailsUIStore={reeferMonitoringDetailsUIStore}
                  />
                )}
                <Divider />
                <Stack
                  sx={{
                    backgroundColor: theme.palette.background.default,
                    padding: theme.customSpacing.xs,
                    paddingLeft: theme.customSpacing.m,
                  }}
                  flexDirection='row'
                  gap={theme.customSpacing.s}
                  flexWrap='wrap'
                >
                  <Typography variant='body1'>
                    {currentPageIndex * 2 + 1} -{' '}
                    {currentPageIndex * 2 + 2 <= totalContainerWithAlerts
                      ? currentPageIndex * 2 + 2
                      : currentPageIndex * 2 + 1}{' '}
                    of {totalContainerWithAlerts}
                  </Typography>
                  <Stack flexDirection='row' gap={theme.customSpacing.xxs} flexWrap='wrap'>
                    <IconButton
                      sx={{ padding: 0 }}
                      size='small'
                      disabled={currentPageIndex <= 0}
                      onClick={() => setCurrentPageIndex(currentPageIndex - 1)}
                    >
                      <ChevronLeftIcon fontSize='small' />
                    </IconButton>
                    <IconButton
                      sx={{ padding: 0 }}
                      size='small'
                      disabled={currentPageIndex >= totalPages - 1}
                      onClick={() => setCurrentPageIndex(currentPageIndex + 1)}
                    >
                      <ChevronRightIcon fontSize='small' />
                    </IconButton>
                  </Stack>
                </Stack>
              </>
            )}
          </Stack>
        </Popover>
      </>
    )
  },
)
