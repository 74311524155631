import {
  ContainerYardOperationDto,
  DeviceOperationMode,
  ErrorCodes,
  YardPositionDto,
} from '@storage/app/api'
import { isApplicationDomainException } from '@storage/app/http-client/interceptors/domain-exception.response-interceptor'
import { tolgee } from '@storage/app/translation'
import { ContainerTurnoverService } from '@storage/services/container-turnover.service'
import { YardBlockSlotOccupancyService } from '@storage/services/yard-block-slot-occupancy.service'
import { action, makeObservable, observable } from 'mobx'

export enum YardPositionInputMode {
  StackIn,
  StackOut,
  CorrectPosition,
}

interface JobData {
  containerNumber: string
  orderId?: number | null
  turnoverData?: ContainerYardOperationDto
  location?: YardPositionDto | null
  mode: YardPositionInputMode
}

export class YardOperationControlStore {
  jobsDataMap: Map<string, JobData> = new Map()

  handleChange?: (value: any) => void
  handleError?: (msg: string) => void

  constructor(
    private readonly _yardBlockSlotOccupancyService: YardBlockSlotOccupancyService,
    private readonly _containerTurnoverService: ContainerTurnoverService,
  ) {
    makeObservable(this, {
      jobsDataMap: observable,
      setCallback: action,
      setJobData: action,
      updateJobLocation: action,
    })
  }

  setJobData(jobsDataKey: string, data: JobData) {
    this.jobsDataMap.set(jobsDataKey, data)
  }

  updateJobLocation(jobsDataKey: string, location?: YardPositionDto) {
    const jobData = this.jobsDataMap.get(jobsDataKey)
    if (jobData) {
      jobData.location = location
      this.handleChange?.(location)
    }
  }

  async loadTurnoverData(jobsDataKey: string, mode: DeviceOperationMode = DeviceOperationMode.Bay) {
    const jobData = this.jobsDataMap.get(jobsDataKey)
    if (!jobData) throw new Error('Job data not found')

    const yardOperationData = await this._containerTurnoverService.getYardOperationData(
      jobData.containerNumber,
      jobData.orderId!,
      jobData.mode !== YardPositionInputMode.StackOut,
      mode,
    )

    this.setJobData(jobsDataKey, {
      ...jobData,
      turnoverData: yardOperationData,
      location: yardOperationData.plannedPosition,
    })

    this.handleChange?.(yardOperationData.plannedPosition)
  }

  getTurnoverDataByOrderId(jobsDataKey: string) {
    return this.jobsDataMap.get(jobsDataKey)?.turnoverData
  }

  async validateDGStackingAndSegregationRulesViolation(
    containerTurnoverId: string,
    location: YardPositionDto,
  ) {
    await this._yardBlockSlotOccupancyService
      .validateDGStackingAndSegregationRulesViolation(containerTurnoverId, location)
      .catch(error => {
        // Check for dg stacking rules violations
        switch (true) {
          case isApplicationDomainException(
            error,
            ErrorCodes.DgStackingRulesViolationStackingOnTop,
          ):
            this.handleError?.(
              tolgee.t(
                'stackingValidationStackingOnTopOperator',
                `We can't use this location. There is a stacking rule that prohibits stacking on top`,
              ),
            )
            break

          case isApplicationDomainException(
            error,
            ErrorCodes.DgStackingRulesViolationMaxAllowedTier,
          ):
            this.handleError?.(
              tolgee.t(
                'stackingValidationMaxStackTierOperator',
                `We can't use this location. There is a maximum tier stacking rule in place for this position`,
              ),
            )
            break

          case isApplicationDomainException(error, ErrorCodes.DgSegregationRulesViolation):
            this.handleError?.(
              tolgee.t(
                'segregationRulesViolationOperator',
                `We can't use this location, your action violates the current segregation rules.`,
              ),
            )
            break

          default:
            // Generic error
            this.handleError?.(tolgee.t('rulesValidationError', 'Rules validation error'))
            break
        }

        throw error
      })
  }

  async updateContainerPosition(containerTurnoverId: string, location: YardPositionDto) {
    await this._yardBlockSlotOccupancyService
      .updateContainerPosition(containerTurnoverId, location)
      .catch(() => this.handleError?.(tolgee.t('databaseIOError', 'database read/write error')))
  }

  async onConfirm(containerNumber: string, orderId: number) {
    const jobData = this.jobsDataMap.get(this.getJobsDataMapKey(containerNumber, orderId))
    if (!jobData) return

    const containerTurnover = jobData.turnoverData?.turnover
    const location = jobData.location

    if (containerTurnover && !!location) {
      await this.validateDGStackingAndSegregationRulesViolation(containerTurnover.id, location)
      await this.updateContainerPosition(containerTurnover.id, location)
    }
  }

  getJobsDataMapKey(containerNumber: string, orderId?: number | null): string {
    return orderId ? orderId.toString() : containerNumber
  }

  setCallback(handleChange?: (value: any) => void, handleError?: (msg: string) => void) {
    this.handleChange = handleChange
    this.handleError = handleError
  }
}
