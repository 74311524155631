import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material'
import CraneOperatorLandingIcon from '@operations/assets/icons/operators/CraneOperatorLandingIcon'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { CraneOperatorLandingContainerUIStore } from '../stores/crane-operator-landing-container.ui-store'

export interface Props {
  uiStore: CraneOperatorLandingContainerUIStore
}

const CraneOperatorLandingContainer = observer(({ uiStore }: Props) => {
  const { t } = useTranslate()
  const navigate = useNavigate()

  const onSelectVesselVisit = async (event: SelectChangeEvent) => {
    uiStore.setSelectedVesselVisitId(+event.target.value)
  }

  const onSelectedEquipment = async (event: SelectChangeEvent) => {
    uiStore.setSelectedEquipmentId(event.target.value === '' ? undefined : +event.target.value)
  }

  const dateToString = (value?: string | null) =>
    value ? ` - ${new Date(value).toLocaleString()}` : null

  const onContinue = async () => {
    const vesselVisit = uiStore.vesselVisitsWithGeometry.find(
      x => x.id === uiStore.selectedVesselVisitId,
    )

    if (vesselVisit?.hasGeometry) {
      navigate(
        `/operatorViews/Crane/BayView/List/${uiStore.selectedVesselVisitId}/${uiStore.selectedEquipmentId}`,
      )
    } else {
      navigate(
        `/operatorViews/Crane/NoGeometryView/${uiStore.selectedVesselVisitId}/${uiStore.selectedEquipmentId}`,
      )
    }
  }

  return (
    <Stack direction='row' justifyContent='center' spacing={8} alignItems='center' height='70vh'>
      <CraneOperatorLandingIcon />
      <Stack spacing={2} width='30%'>
        <FormControl fullWidth>
          <InputLabel>{t('vesselVisit')}</InputLabel>
          <Select
            label={t('vesselVisit')}
            value={(uiStore.selectedVesselVisitId ?? '') as string}
            onChange={onSelectVesselVisit}
          >
            {uiStore.vesselVisitsWithGeometry.map(vv => (
              <MenuItem key={vv.id} value={vv.id}>
                {vv.carrierName}
                {dateToString(vv.ata) ?? dateToString(vv.eta) ?? ''}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>{t('crane', 'Crane')}</InputLabel>
          <Select
            label={t('crane', 'Crane')}
            value={(uiStore.selectedEquipmentId ?? '') as string}
            onChange={onSelectedEquipment}
          >
            <MenuItem value=''>{t('none', 'None')}</MenuItem>
            {uiStore.plannedCranesOfSelectedVisit.map(c => (
              <MenuItem key={c.id} value={c.id}>
                {c.isOnMaintenance ? `${c.name} (${t('onMaintenance', 'on maintenance')})` : c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant='contained'
          color='primary'
          sx={{ width: 'fit-content' }}
          disabled={!uiStore.selectedVesselVisitId}
          onClick={onContinue}
        >
          {t('continue', 'Continue')}
        </Button>
      </Stack>
    </Stack>
  )
})

export default CraneOperatorLandingContainer
