import { CarrierVisitDirection } from '@storage/app/api'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { SyntheticEvent } from 'react'
import CarrierVisitDirectionTabs from './components/CarrierVisitDirectionTabs'
import { CarrierVisitAccordionContainer } from './components/CarrierVisitAccordionContainer'
import { CarrierVisitGlobalRulesAccordionContainer } from './components/CarrierVisitGlobalRulesAccordionContainer'
import { useBooleanFlagValue } from '@openfeature/react-sdk'

interface CarrierVisitAllocationRulesContainerV2Props {
  activeCarrierVisitDirection: CarrierVisitDirection
  handleDirectionChange: (event: SyntheticEvent, newValue: CarrierVisitDirection) => void
}

export const CarrierVisitAllocationRulesContainer = observer(
  ({
    activeCarrierVisitDirection,
    handleDirectionChange,
  }: CarrierVisitAllocationRulesContainerV2Props) => {
    const { t } = useTranslate()

    const isGlobalAllocationRulesFeatureEnabled = useBooleanFlagValue(
      'global-allocation-rules',
      false,
    )

    return (
      <>
        <CarrierVisitDirectionTabs
          value={activeCarrierVisitDirection}
          onChange={handleDirectionChange}
        />

        <CarrierVisitAccordionContainer />

        {isGlobalAllocationRulesFeatureEnabled && (
          <CarrierVisitGlobalRulesAccordionContainer
            tooltip={t(
              'globalRulesTooltip',
              'Global rules are always working to organize your yard. These can be overridden with visit rules',
            )}
            carrierVisitDirection={activeCarrierVisitDirection}
          />
        )}
      </>
    )
  },
)
