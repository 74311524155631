import { Box, Chip, ClickAwayListener, Stack, Tooltip } from '@mui/material'
import { GetRangeFromStringListByListWithMinAndMax } from '@operations/utils/get-range-from-list'
import { useTranslate } from '@tolgee/react'
import {
  IconButton,
  PositionRangeAutocomplete,
  RemoveIcon,
  SuccessIcon,
  useMinimalsTheme,
} from '@tom-ui/ui'
import { useEffect, useState } from 'react'

export interface YardBlockBay {
  id: string
  name: string
  blockBays: string[]
  selectedBays: string[]
  recentlyAdded?: boolean
}

interface Props {
  yardBlockBay: YardBlockBay
  onUnassign: (id: string) => void
  onBaySelection: (bays: string[]) => void
}
export const YardBlockBayChip = ({ yardBlockBay, onUnassign, onBaySelection }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()
  const [isSelected, setIsSelected] = useState(false)
  const [selectedBays, setSelectedBays] = useState<string[]>([])

  useEffect(() => {
    if (yardBlockBay.recentlyAdded) {
      setIsSelected(true)
    }

    if (yardBlockBay.selectedBays.length) {
      setSelectedBays(yardBlockBay.selectedBays)
    }
  }, [yardBlockBay])

  const handleBaySelection = (newBays: string[]) => {
    setSelectedBays(newBays)

    const rangeOfSelectedBays = GetRangeFromStringListByListWithMinAndMax(
      yardBlockBay.blockBays,
      newBays,
    )
    onBaySelection(rangeOfSelectedBays)
  }

  return (
    <Box>
      <ClickAwayListener onClickAway={() => setIsSelected(false)}>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: theme.customSpacing.s,
            p: theme.customSpacing.xs,
            borderRadius: theme.customRadius.medium,
            backgroundColor: isSelected ? theme.palette.primary.lighter : theme.palette.grey[200],
          }}
          onClick={() => setIsSelected(true)}
        >
          <Chip label={yardBlockBay.name} color={'primary'} icon={<SuccessIcon />} />

          <Box mb={theme.customSpacing.xxs}>
            <PositionRangeAutocomplete
              value={selectedBays}
              options={yardBlockBay.blockBays}
              size='small'
              handleChange={(value?: string) => handleBaySelection(value?.split('-') ?? [])}
            />
          </Box>

          <Tooltip title={t('unassignYardBlock', 'Unassign yard block')}>
            <IconButton onClick={() => onUnassign(yardBlockBay.id)} size='small' color='secondary'>
              <RemoveIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </Stack>
      </ClickAwayListener>
    </Box>
  )
}
