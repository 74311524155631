import { Power, Thermostat } from '@mui/icons-material'
import { Card, CardContent, CardHeader, Stack, Tooltip, Typography } from '@mui/material'
import { OrderStatus } from '@operations/app/api'
import { formatContainerNumber } from '@operations/utils/helper'
import { useTranslate } from '@tolgee/react'
import { ColoredLabel, EmptyIndicator, OptionsButton, Tile, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { CoolingOrderCardDto } from '../../models/cooling-order-model'
import { ReeferMonitoringDetailsUIStore } from '../../stores/reefer-monitoring-details.ui-store'
import { AlertNameIcon } from './AlertNameIcon'
import { CoolingOrderDate } from './CoolingOrderDate'

interface Props {
  coolingOrder: CoolingOrderCardDto
  reeferMonitoringDetailsUIStore: ReeferMonitoringDetailsUIStore
}
export const CoolingOrderCard = observer(
  ({ coolingOrder, reeferMonitoringDetailsUIStore }: Props) => {
    const { t } = useTranslate()
    const theme = useMinimalsTheme()
    const emptyIndicator = ' - '

    const orderAlerts = AlertNameIcon(coolingOrder.alerts, t)

    return (
      <Card>
        <CardHeader
          title={
            <Stack flexDirection='row' gap={theme.customSpacing.m}>
              <Typography variant='h6'>
                {coolingOrder.container.containerNumber
                  ? formatContainerNumber(coolingOrder.container.containerNumber)
                  : emptyIndicator}
              </Typography>

              {coolingOrder.status === OrderStatus.Fulfilled && (
                <ColoredLabel color='info' label={t('fulfilled', 'Fulfilled')} />
              )}
            </Stack>
          }
          action={
            <OptionsButton
              tooltip={t('moreOptions', 'More options')}
              options={[
                {
                  label: t('details', 'Details'),
                  onClick: () => reeferMonitoringDetailsUIStore.toggle(coolingOrder.id),
                },
              ]}
            />
          }
        />
        <CardContent>
          <Stack flexDirection='row' gap={theme.customSpacing.l} flexWrap='wrap'>
            <Tile label={t('customer', 'Customer')}>
              <Typography variant='subtitle1'>{coolingOrder.customer ?? emptyIndicator}</Typography>
            </Tile>
            <Tile label={t('length', 'Length')}>
              <Typography variant='subtitle1'>
                {coolingOrder.container.length ?? emptyIndicator}
              </Typography>
            </Tile>
            <Tile label={t('idealTemperature', 'Ideal temperature')}>
              <ColoredLabel
                color='secondary'
                startIcon={<Thermostat fontSize='small' />}
                label={coolingOrder.requestedTemperature.toString()}
                sx={{ width: 'min-content' }}
              />
            </Tile>
            {coolingOrder.status !== OrderStatus.Fulfilled && (
              <Tile label={t('actionRequired', 'Action required')}>
                <Stack flexDirection='row' gap={theme.customSpacing.xs}>
                  {orderAlerts.map(alert => (
                    <ColoredLabel
                      key={`${coolingOrder.id}_${alert.label}`}
                      color='warning'
                      startIcon={<Tooltip title={alert.label}>{alert.icon}</Tooltip>}
                    />
                  ))}
                  {orderAlerts.length === 0 && EmptyIndicator}
                </Stack>
              </Tile>
            )}
            <Tile label={t('lastStatus', 'Last status')}>
              {coolingOrder.lastStatus ? (
                <Stack flexDirection='row' gap={theme.customSpacing.xs}>
                  <ColoredLabel
                    color='secondary'
                    startIcon={<Power fontSize='small' />}
                    label={coolingOrder.lastStatus.isPluggedIn ? t('in', 'In') : t('out', 'Out')}
                    sx={{ height: 'min-content' }}
                  />
                  <ColoredLabel
                    color='secondary'
                    startIcon={<Thermostat fontSize='small' />}
                    label={coolingOrder.lastStatus?.temperature?.toString() ?? emptyIndicator}
                    sx={{ height: 'min-content' }}
                  />
                  {coolingOrder.lastStatus.dateTime && (
                    <CoolingOrderDate date={coolingOrder.lastStatus.dateTime} />
                  )}
                </Stack>
              ) : (
                emptyIndicator
              )}
            </Tile>
          </Stack>
        </CardContent>
      </Card>
    )
  },
)
