import {
  CarrierVisitAllocationRuleDto,
  CarrierVisitDirection,
  CreateAllocationRuleDto,
} from '@storage/app/api'
import { EntityStore } from '@storage/app/store/entity.store'
import { CarrierVisitsService } from '@storage/services/carrier-visits.service'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

export class CarrierVisitAllocationRulesV2Store extends EntityStore<CarrierVisitAllocationRuleDto> {
  initialEntries: CarrierVisitAllocationRuleDto[] = []
  isLoading = false

  constructor(private readonly _carrierVisitsService: CarrierVisitsService) {
    super()
    makeObservable(this, {
      initialEntries: observable,
      isLoading: observable,
      setIsLoading: action,
      resetData: action,
      reorderAllocationRules: action,
      hasChanges: computed,
    })
  }

  get hasChanges(): boolean {
    return !isEqual(this.initialEntries, this.entries)
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  async loadAll(carrierVisitId: number, carrierVisitDirection: CarrierVisitDirection) {
    this.setIsLoading(true)
    const carrierVisitAllocationRules = await this._carrierVisitsService.getAllocationRules(
      carrierVisitId,
      carrierVisitDirection,
    )
    runInAction(() => {
      this.initialEntries = cloneDeep(carrierVisitAllocationRules)
      this.setAll(carrierVisitAllocationRules)
    })
    this.setIsLoading(false)
  }

  resetData() {
    this.initialEntries = []
    this.removeAll()
  }

  async saveChanges(
    carrierVisitId: number,
    direction: CarrierVisitDirection,
    allocationRules: CreateAllocationRuleDto[],
  ) {
    await this._carrierVisitsService.createCarrierVisitAllocationAndRules({
      carrierVisitId,
      direction,
      allocationRules,
    })
  }

  async delete(id: string) {
    this.remove(id)
  }

  async add(carrierVisitAllocationRule: CarrierVisitAllocationRuleDto) {
    this.addOrUpdate(carrierVisitAllocationRule)
    return carrierVisitAllocationRule
  }

  reorderAllocationRules(startIndex: number, endIndex: number) {
    const currentEntries = this.entries
    const [removed] = currentEntries.splice(startIndex, 1)
    currentEntries.splice(endIndex, 0, removed)

    this.setAll(currentEntries)
  }
}
