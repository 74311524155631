import { Cached } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

interface IProps {
  onClick: () => void
  updateCount?: number
}

export const UpdatesNotificationCard: React.FC<IProps> = observer(({ onClick, updateCount }) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='space-between'
      padding={1}
      bgcolor={theme.palette.grey[200]}
      width='100%'
    >
      <Box display='flex' alignItems='center'>
        <Cached fontSize='large' />
        <Typography variant='body2' ml={1}>
          {updateCount ?? 0} {updateCount === 1 ? t('update', 'Update') : t('updates', 'Updates')}
        </Typography>
      </Box>
      <Button variant='text' color='secondary' size='small' onClick={onClick}>
        {t('review', 'Review')}
      </Button>
    </Box>
  )
})
