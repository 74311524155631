import { Tab, Tabs } from '@mui/material'
import { NumberBox } from '@planning/components/NumberBox'
import { TabPanel } from '@planning/components/TabPanel'
import { HoldViewStore } from '@planning/stores/hold/HoldViewStore'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useState } from 'react'
import { HoldListBox } from './HoldListBox'

interface Props {
  store: HoldViewStore
  listMaxHeight?: string
}

export const HoldTabs: FC<Props> = observer(({ store, listMaxHeight }: Props) => {
  const [selectedTab, setSelectedTab] = useState<number>(0)

  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  const { t } = useTranslate()

  const handleCheck = (holdId: number) => {
    store.toggleSelectHoldId(holdId)
  }

  const handleSelectAll = () => {
    store.toggleSelectAll()
  }

  return (
    <>
      <Tabs
        value={selectedTab}
        onChange={(_, newValue) => setSelectedTab(newValue)}
        sx={{ p: '1rem', maxHeight: '65vh' }}
      >
        <Tab
          icon={<NumberBox number={store.activeHolds.length + store.activeIssueHolds.length} />}
          iconPosition='start'
          label={t('active', 'Active')}
          {...a11yProps(0)}
        />
        <Tab
          icon={<NumberBox number={store.resolvedHolds.length + store.resolvedIssueHolds.length} />}
          iconPosition='start'
          label={t('resolved', 'Resolved')}
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        <HoldListBox
          holds={store.activeHolds}
          issueHolds={store.activeIssueHolds}
          checkedIds={store.selectedHoldIds}
          handleCheck={handleCheck}
          handleSelectAll={handleSelectAll}
          listMaxHeight={listMaxHeight}
          showDirection={store.isBothDirections}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <HoldListBox
          holds={store.resolvedHolds}
          issueHolds={store.resolvedIssueHolds}
          listMaxHeight={listMaxHeight}
          showDirection={store.isBothDirections}
        />
      </TabPanel>
    </>
  )
})
