import { Button, IconButton, Stack } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { ContainerDamageResponseDto, OrderResponseDto } from '@planning/app/api'
import { useUnitTypeLabel } from '@planning/components/UnitType'
import { NavigationStackBackButton } from '@planning/pages/TallymanV2/Components/HeaderComponents/NavigationStackBackButton'
import { useTranslate } from '@tolgee/react'
import { BinIcon, ContainerMobile, Header, StepperInfo, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  IInspectContainerFormData,
  InspectContainerForm,
} from './Components/InspectContainer/InspectContainerForm'

interface IProps {
  order: OrderResponseDto
  damages?: ContainerDamageResponseDto[]
  submitButtonText: string
  onSubmit: (data: IInspectContainerFormData) => Promise<void> | void
  onDelete?: () => void
  deleteContainerDialog?: () => JSX.Element
}

export const InspectContainer: FC<IProps> = observer(
  ({ order, damages, submitButtonText, onDelete, onSubmit, deleteContainerDialog }) => {
    const { t } = useTranslate()
    const { containerItemStore, appViewStore } = usePlanningStore()
    const theme = useMinimalsTheme()

    useEffect(() => {
      if (order.containerId) containerItemStore.fetchById(order.containerId)
    }, [containerItemStore, order.containerId])

    const containerItem = containerItemStore.elements[order.containerId ?? 0]

    const form = useForm<IInspectContainerFormData>({
      defaultValues: {
        id: order.id,
        referenceNumber: order.referenceNumber,
        direction: order.direction,
        carrierType: order.carrierVisitType,
        containerId: order.containerId,
        containerNumber: order.containerNumber,
        containerIsoCode: order.containerIsoCode,
        containerTare: order.containerTare,
        containerMaxGross: order.containerMaxGross,
        isEmpty: order.isEmpty,
        imoClasses: order.imoClasses,
        holds: order.holds,
        damages: damages ?? containerItem?.data.damages ?? [],
        hasSeals: order.hasSeals,
        seals: order.seals,
        hasDamage: !!(damages?.length ?? containerItem?.data.damages.length),
        waggon: order.waggon,
        railTrackId: order.railTrackId,
      },
    })

    const onFormSubmit = async (data: any) => {
      try {
        onSubmit(data)
      } catch (error) {
        appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
      }
    }

    const getStepText = () => {
      const hasDamage = form.watch('hasDamage')
      if (order.direction === 'Inbound') {
        return `1 ${t('of', 'of')} ${hasDamage ? 3 : 2}`
      }

      return `1 ${t('of', 'of')} ${hasDamage ? 2 : 1}`
    }

    const getButtonText = () => {
      return order.direction === 'Inbound' || form.watch('hasDamage')
        ? submitButtonText
        : t('checkOut', 'Check out')
    }

    const subtitle = order?.isEmpty
      ? t('emptyContainer', 'Empty Container')
      : t('fullContainer', 'Full Container')

    const title = `Inspect ${useUnitTypeLabel(containerItem.data.unitType)}`

    return (
      <>
        <Header
          leftRenderOption={<NavigationStackBackButton />}
          title={order?.containerNumber ?? ''}
          subTitle={subtitle}
          rightRenderOption={
            <Stack flexDirection='row' gap={theme.customSpacing.xs} alignItems='center'>
              {onDelete && (
                <IconButton data-cy='delete-order-inspect-container' onClick={onDelete}>
                  <BinIcon />
                </IconButton>
              )}
              <Button
                data-cy='submit-inspect-container'
                variant='contained'
                sx={{ flex: 'none' }}
                onClick={form.handleSubmit(onFormSubmit)}
              >
                {getButtonText()}
              </Button>
            </Stack>
          }
        />
        <StepperInfo title={title} steps={getStepText()} />
        {order && (
          <ContainerMobile sx={{ pt: theme.customSpacing.l }}>
            <FormProvider {...form}>
              <InspectContainerForm containerItem={containerItem} />
            </FormProvider>
          </ContainerMobile>
        )}
      </>
    )
  },
)
