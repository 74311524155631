import { Box, Grid, MenuItem, Stack, Typography } from '@mui/material'
import { RestowResponseDto } from '@planning/app/api'
import { OrderStatusChip } from '@planning/components/OrderStatusChip'
import { OrderItemChip } from '@planning/pages/Order/components/OrderItemChip'
import { formatPosition } from '@planning/utils/container-utils'
import { useTranslate } from '@tolgee/react'
import { DangerousIcon, HighlightedText, ThreeDotMenu, useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'

interface RestowListItemProps {
  data: RestowResponseDto
  highlightText?: string
  onDelete: (id: number) => void
}

export const RestowListItem = observer(({ data, highlightText, onDelete }: RestowListItemProps) => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  return (
    <Grid
      key={`grp-row-${data.number}`}
      container
      paddingLeft={'1rem'}
      paddingRight={'1rem'}
      height={'60px'}
      display={'flex'}
      alignItems={'center'}
      borderBottom={`1px solid ${theme.palette.grey[300]}`}
    >
      <Grid item xs={2} textAlign={'start'}>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Box>
            <Typography component={'div'} variant='body2'>
              <HighlightedText highlight={highlightText} color={theme.palette.info.main}>
                {data.number}
              </HighlightedText>
            </Typography>
            {/* <Typography component={'div'} variant='mini'>
              <HighlightedText highlight={'filter'} color={'blue'}>
                {data.referenceNumber}
              </HighlightedText>
            </Typography> */}
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={7} xl={7} alignSelf={'center'} textAlign={'start'}>
        <Stack direction={'row'} spacing={0.5}>
          <OrderItemChip label={data.isoCode}></OrderItemChip>
          <OrderItemChip label={(data.isEmpty && 'E') || 'F'} />
          {!!data.grossWeight && (
            <OrderItemChip
              label={`${t('gwt', 'GWT')}: ${data.grossWeight.toLocaleString(undefined, { maximumFractionDigits: 0 })}kg`}
            />
          )}
          {data.imoClasses && data.imoClasses.length > 0 && (
            <OrderItemChip
              label={
                <Typography
                  display={'flex'}
                  gap={'4px'}
                  fontSize={'14px'}
                  sx={{ alignItems: 'center' }}
                  component={'div'}
                  variant='body2'
                >
                  <DangerousIcon sx={{ height: '16px', width: '16px', marginRight: '4px' }} />
                  {data.imoClasses.join(', ')}
                </Typography>
              }
            />
          )}
          {data.origin && (
            <OrderItemChip
              label={`${t('originSlot', 'Origin slot')}: ${formatPosition(data.origin)} `}
            />
          )}
          {data.destination && (
            <OrderItemChip
              label={`${t('targetSlot', 'Target slot')}: ${formatPosition(data.destination)}`}
            />
          )}
        </Stack>
      </Grid>
      <Grid textAlign={'end'} item xs={3} onClick={event => event.stopPropagation()}>
        <Grid container>
          <Grid item xs={10} xl={11} sx={{ placeContent: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                placeContent: 'flex-end',
                gap: '4px',
              }}
            >
              <OrderStatusChip status={'Open'} />
            </Box>
          </Grid>
          <Grid item xs={2} xl={1}>
            <ThreeDotMenu>
              <MenuItem onClick={() => onDelete(data.id)}>{t('delete')}</MenuItem>
            </ThreeDotMenu>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
})
