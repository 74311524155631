import { CarrierVisitDirection, OrderResponseDto } from '@planning/app/api'

export const filterOutboundOrdersByVisitId = (orders: OrderResponseDto[], visitId: number) => {
  return orders.filter(
    order => order.carrierVisitId === visitId && order.direction === CarrierVisitDirection.Outbound,
  )
}

export const sortOrdersByContainerNumber = (orders: OrderResponseDto[]) => {
  return orders.sort((a, b) => (a.containerNumber ?? '').localeCompare(b.containerNumber ?? ''))
}
