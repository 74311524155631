import { Loader } from '@tom-ui/ui'
import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

interface WebAppConfigurationAuth {
  audience: string
  domain: string
  clientId: string
}

interface WebAppConfiguration {
  auth?: WebAppConfigurationAuth
  environment?: string
  devCycleProviderSdkKey?: string | null
}

interface Props {
  getAppConfig: () => Promise<{ data: WebAppConfiguration }>
  children: ReactNode
}

const AppConfigurationContext = createContext<WebAppConfiguration>({})
export const AppConfigurationProvider = ({ children, getAppConfig }: Props) => {
  const [webAppConfiguration, setWebAppConfiguration] = useState<WebAppConfiguration>()

  useEffect(() => {
    const init = async () => {
      const { data } = await getAppConfig()
      setWebAppConfiguration(data)
    }
    init()
  }, [getAppConfig])

  if (!webAppConfiguration) return <Loader show />

  return (
    <AppConfigurationContext.Provider value={webAppConfiguration}>
      {children}
    </AppConfigurationContext.Provider>
  )
}

export const useAppConfiguration = () => useContext(AppConfigurationContext)
