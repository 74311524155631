import { Stack, Typography } from '@mui/material'
import { OperationType, VesselBayDto } from '@operations/app/api'
import { useTranslate } from '@tolgee/react'
import { BayWorkStatus } from './BayWorkStatus'

export interface Props {
  bayDto: VesselBayDto
  bayName?: string
  zoom: number
  operationType: OperationType
  workStatusPerBay: any[] | undefined
}

export const BayHeader = ({ bayDto, bayName, zoom, operationType, workStatusPerBay }: Props) => {
  const { t } = useTranslate()

  return (
    <Stack justifyContent='center' alignItems='center'>
      <Typography
        fontWeight={zoom < 0.5 ? undefined : 'bold'}
        fontSize={zoom < 0.5 ? 12 : 18 * zoom}
      >
        {t('bay')} {bayName ?? bayDto.bay}
      </Typography>
      {zoom >= 0.5 && (
        <BayWorkStatus
          operationType={operationType}
          bays={[bayDto]}
          workStatusPerBay={workStatusPerBay}
        />
      )}
    </Stack>
  )
}
