import { Button, Container, Grid, Typography } from '@mui/material'
import { CreateContainerDamageCommand } from '@planning/app/api'
import { LoaderButton } from '@planning/components/LoaderButton'
import containerService from '@planning/services/containerService'
import { useNavigationStore } from '@planning/stores/NavigationStack'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { useForm } from 'react-hook-form'

interface Props {
  containerId: number
}

export const CreateContainerDamageForm: FC<Props> = observer(({ containerId }) => {
  const navigationStore = useNavigationStore()
  const { t } = useTranslate()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: { containerId, containerDamages: [] },
  })

  const onSubmit = async (cmd: CreateContainerDamageCommand) => {
    await containerService.createDamage(cmd)

    navigationStore.pop()
  }

  return (
    <Container sx={{ width: '300px' }}>
      <Typography variant='h4' sx={{ marginBottom: '20px', marginTop: '20px' }}>
        {t('createDamage', 'Create damage')}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction='column' rowSpacing={{ xs: 1.5 }}>
          <Grid item>
            {/* <TextField
              fullWidth
              {...register(`containerDamages`, { required: true })}
              label={`${t('description', 'Description')} *`}
              multiline
              rows={3}
            /> */}
          </Grid>
        </Grid>
        <Grid container direction='row' spacing={'12px'} marginTop='1rem'>
          <Grid item>
            <LoaderButton variant='contained' type='submit'>
              {t('create', 'Create')}
            </LoaderButton>
          </Grid>
          <Grid item>
            <Button
              variant='outlined'
              type='button'
              color='error'
              onClick={() => navigationStore.pop()}
            >
              {t('close', 'Close')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
})
