import { Box, Stack, Typography } from '@mui/material'
import { useMinimalsTheme } from '@tom-ui/ui'
import { FC, ReactNode } from 'react'
import { RequiredField } from '../atoms/RequiredField'

type IProps = {
  label: string
  required?: boolean
  actions?: ReactNode[]
}

export const SectionHeader: FC<IProps> = ({ label, required, actions }) => {
  const theme = useMinimalsTheme()

  return (
    <Typography variant='subtitle1' align='left'>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Box>
          {label} {required && <RequiredField />}
        </Box>
        {actions && (
          <Stack direction={'row'} gap={theme.customSpacing.xs}>
            {actions}
          </Stack>
        )}
      </Stack>
    </Typography>
  )
}
