import { ContainerDto, ContainerType } from '@planning/app/api'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'
import { CoolingOrderDialogViewStore } from '../Stores/CoolingOrderDialogViewStore'
import { MultiSelectNumericForm } from './MultiSelectNumericForm'
import { NumericForm } from './NumericForm'

export const CoolingOrderNumericForm: FC<{
  store: CoolingOrderDialogViewStore
}> = observer(({ store }) => {
  const onSearchFilterChange = (value: string | ContainerDto | null) => {
    if (value && typeof value != 'string' && value.id) {
      store.fetchContainerVisits(value.id)
    }
  }

  const onMultipleContainerSearchTextChange = (containerNumbers?: string[]) => {
    if (containerNumbers && containerNumbers.length) {
      store.fetchContainerVisitsByContainerNumbers(containerNumbers)
    }
  }

  return (
    (!store.isMultiSelectMode && (
      <NumericForm
        selectedContainerJourneys={store.selectedContainerJourneys}
        containerJourneyOptions={store.containerJourneys}
        onSelectContainerJourney={journeys => {
          store.setSelectedContainerJourneys(journeys)
        }}
        onSearchFilterChange={(_, value) => onSearchFilterChange(value)}
        isEmpty={false}
        type={ContainerType.Reefer}
        isNoContainersSelectedError={store.isError && store.selectedContainerJourneys.length === 0}
      />
    )) || (
      <MultiSelectNumericForm
        selectedContainerJourneys={store.selectedContainerJourneys}
        containerJourneyOptions={store.containerJourneys}
        onSelectContainerJourney={journeys => {
          store.setSelectedContainerJourneys(journeys)
        }}
        onSearchFilterChange={onMultipleContainerSearchTextChange}
        isNoContainersSelectedError={store.isError && store.selectedContainerJourneys.length === 0}
        isLoading={store.isLoading}
      />
    )
  )
})
