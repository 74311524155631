import { Close } from '@mui/icons-material'
import { Box, IconButton, Paper, Typography } from '@mui/material'
import { StackedInContainerDto } from '@storage/app/api'
import { Point } from '@storage/app/models'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'

interface Props {
  position: Point
  containers: StackedInContainerDto[]
  onClick: () => void
}

export const FlexibleZoneStackedContainers = ({ position, containers, onClick }: Props) => {
  const { palette } = useMinimalsTheme()
  const { t } = useTranslate()
  return (
    <Paper
      sx={{
        position: 'fixed',
        top: position.y,
        left: position.x,
        display: 'flex',
        flexFlow: 'column',
        padding: 2,
        background: palette.background.neutral,
        width: 250,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant='h6'> {t('containers', 'Containers')}</Typography>
        <Box>
          <IconButton onClick={onClick}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      {containers.map((container, index) => (
        <Typography variant='body1' key={index}>
          {`${container.containerNumber} - ${container.isoCode}`}
        </Typography>
      ))}
    </Paper>
  )
}
