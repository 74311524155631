import { CarrierVisitDirection, ErrorCodes } from '@storage/app/api'
import {
  getApplicationDomainExceptionPayload,
  isApplicationDomainException,
} from '@storage/app/http-client/interceptors/domain-exception.response-interceptor'
import { tolgee } from '@storage/app/translation'
import { ContainerPlanningService } from '@storage/services/container-planning.service'
import { AlertStore } from '@storage/stores/alert.store'
import { ContainerTurnoverStore } from '@storage/stores/container-turnover.store'
import { SeverityType, SnackbarStore } from '@storage/stores/snackbar.store'
import { TenantConfigStore } from '@storage/stores/tenantConfig.store'
import { YardBlockBayStore } from '@storage/stores/yard-block-bay.store'
import { YardBlockStore } from '@storage/stores/yard-block.store'
import { getInsufficientPlanningSpaceMsg } from '@storage/utils/translation'
import { action, computed, makeObservable, observable } from 'mobx'
import { ContainerTurnoverFilterFormMapper } from '../components/container-turnovers-filter-form'
import { mapFormValuesToFilterDto } from '../components/container-turnovers-filter-form/container-turnovers-filter-form.mapper'
import { ManualReservationFormMapper } from '../components/manual-reservation-form/manual-reservation-form.mapper'
import { ManualPlanningFormProfile } from '../components/manual-reservation-form/manual-reservation-form.profile'
import { ContainerTurnoversFilterStore } from './container-turnovers-filter.store'
import { ContainerTurnoversListStore } from './container-turnovers-list.store'
import { ManualInputValidationStore } from './manual-input-validation.store'

export type ManualPlanningTypes = 'planning' | 'internalMove'

interface PrefilterOptions {
  vesselVisitId: number
  handlingDirection: CarrierVisitDirection
}

export class ManualPlanningDialogStore {
  static readonly INSUFFICIENT_PLANNING_SPACE_ALERT_KEY = 'INSUFFICIENT_PLANNING_SPACE_ALERT_KEY'
  static readonly DANGEROUS_GOODS_STACKING_VIOLATION_ALERT_KEY =
    'DANGEROUS_GOODS_STACKING_VIOLATION_ALERT_KEY'
  static readonly DANGEROUS_GOODS_SEGREGATION_VIOLATION_ALERT_KEY =
    'DANGEROUS_GOODS_SEGREGATION_VIOLATION_ALERT_KEY'

  isOpen = false
  isSingleContainer = false
  type: ManualPlanningTypes = 'planning'
  containerTurnoverId = ''
  isPlanningRequestLoading = false

  constructor(
    private readonly _containerPlanningService: ContainerPlanningService,
    private readonly _containerTurnoverListStore: ContainerTurnoversListStore,
    private readonly _containerTurnoversFilterStore: ContainerTurnoversFilterStore,
    private readonly _containerTurnoverStore: ContainerTurnoverStore,
    private readonly _manualInputValidationStore: ManualInputValidationStore,
    private readonly _tenantConfigStore: TenantConfigStore,
    private readonly _yardBlockStore: YardBlockStore,
    private readonly _yardBlockBayStore: YardBlockBayStore,
    private readonly _snackbarStore: SnackbarStore,
    private readonly _alertStore: AlertStore,
    private readonly prefilterOptions?: PrefilterOptions,
  ) {
    makeObservable(this, {
      isOpen: observable,
      isSingleContainer: observable,
      type: observable,
      containerTurnoverId: observable,
      isPlanningRequestLoading: observable,
      containerTurnover: computed,
      numberOfMatchingFilters: computed,
      filter: computed,
      filterFormProfile: computed,
      toggle: action,
      setType: action,
      setContainerTurnoverId: action,
      setIsSingleContainer: action,
      toggleRequest: action,
      parser: computed,
      containerSize: computed,
    })
  }

  get parser() {
    return this._manualInputValidationStore
  }

  public toggle() {
    // Clear alerts when dialog is closed
    if (this.isOpen) {
      this._alertStore.clearAlerts()
    }
    this.isOpen = !this.isOpen
  }

  public setType(type: ManualPlanningTypes) {
    this.type = type
  }

  public setContainerTurnoverId(id: string) {
    this.containerTurnoverId = id
  }

  get containerTurnover() {
    return this._containerTurnoverStore.entries.find(
      containerTurnover => containerTurnover.id === this.containerTurnoverId,
    )
  }

  get filter() {
    return mapFormValuesToFilterDto(this._containerTurnoversFilterStore.filter)
  }

  get filterFormProfile() {
    return this._containerTurnoversFilterStore.filter
  }

  get containerSize() {
    if (this.containerTurnover) return this.containerTurnover.size

    return this.filter.size ?? 20
  }

  get numberOfMatchingFilters() {
    return this._containerTurnoverStore.pagination.totalCount
  }

  get planningAlerts() {
    return this._alertStore.getAlerts()
  }

  public setIsSingleContainer(isSingleContainer: boolean) {
    this.isSingleContainer = isSingleContainer
  }

  async createReservation(blockName: string, bayName: string, rowName: string, tierString: string) {
    await this._containerPlanningService.createReservation(
      ManualReservationFormMapper.mapFormValuesToReservationDto(
        this.containerTurnoverId,
        blockName,
        bayName.split('-')[0],
        rowName,
        tierString,
        this.doesPlanningAlertExist(
          ManualPlanningDialogStore.DANGEROUS_GOODS_SEGREGATION_VIOLATION_ALERT_KEY,
        ),
      ),
    )

    this.planningSuccessfulEffect()
  }

  async createAllocationForSingleTurnover(formValues: ManualPlanningFormProfile) {
    await this._containerPlanningService.createAllocationForSingleTurnover(
      ManualReservationFormMapper.mapFormValuesToSingleContainerAllocationDto(
        this.containerTurnoverId,
        formValues,
        this.doesPlanningAlertExist(
          ManualPlanningDialogStore.INSUFFICIENT_PLANNING_SPACE_ALERT_KEY,
        ),
      ),
    )

    this.planningSuccessfulEffect()
  }

  async createAllocation(formValues: ManualPlanningFormProfile) {
    await this._containerPlanningService.createAllocation(
      ManualReservationFormMapper.mapFormValuesToAllocationDto(
        this._containerTurnoversFilterStore.filter,
        formValues,
        this.doesPlanningAlertExist(
          ManualPlanningDialogStore.INSUFFICIENT_PLANNING_SPACE_ALERT_KEY,
        ),
      ),
    )
    this.planningSuccessfulEffect()
  }

  async planningSuccessfulEffect() {
    await this._containerTurnoverListStore.reload()
  }

  public toggleRequest() {
    this.isPlanningRequestLoading = !this.isPlanningRequestLoading
  }

  public clearPlanningAlert(key: string) {
    this._alertStore.hideAlert(key)
  }

  public showPlanningAlert(key: string, message: string, severity?: SeverityType) {
    this._alertStore.showAlert(key, message, severity)
  }

  public hidePlanningAlert(key: string) {
    this._alertStore.hideAlert(key)
  }

  public doesPlanningAlertExist(key: string) {
    return this._alertStore.doesAlertExist(key)
  }

  public doesPlanningAlertsExist(keys: string[]) {
    return keys.some(key => this._alertStore.doesAlertExist(key))
  }

  async manualPlanningRequest(formValues: ManualPlanningFormProfile): Promise<void> {
    this.toggleRequest()
    const parts = formValues.manualPosition.split('.')
    let blockName
    let bayName
    let rowName
    let tierString

    if (this._tenantConfigStore.isBayRowUniqueIdentificationModeActivated) {
      const [bay, row, tier] = parts
      const blockBay = this._yardBlockBayStore.entries.find(e => e.name === bay.split('-')[0])
      blockName = this._yardBlockStore.entries.find(e => e.id === blockBay?.yardBlockId)?.name
      bayName = bay
      rowName = row
      tierString = tier
      formValues.manualPosition = `${blockName}.${parts.join('.')}`
    } else {
      const [block, bay, row, tier] = parts
      blockName = block
      bayName = bay
      rowName = row
      tierString = tier
    }
    const req = this.isSingleContainer
      ? blockName && bayName && rowName && tierString
        ? this.createReservation(blockName, bayName, rowName, tierString)
        : this.createAllocationForSingleTurnover(formValues)
      : this.createAllocation(formValues)

    req
      .then(() => {
        this._snackbarStore.showMessage(
          this.isSingleContainer
            ? tolgee.t(
                'containerPlanned',
                'The container has been successfully planned to {location}',
                {
                  location: this._tenantConfigStore.isBayRowUniqueIdentificationModeActivated
                    ? formValues.manualPosition.split('.').slice(1).join('.').toLocaleUpperCase()
                    : formValues.manualPosition.toLocaleUpperCase(),
                },
              )
            : tolgee.t(
                'containersPlanned',
                '{n} containers has been successfully planned to {location}',
                {
                  n: this.numberOfMatchingFilters,
                  location: this._tenantConfigStore.isBayRowUniqueIdentificationModeActivated
                    ? formValues.manualPosition.split('.').slice(1).join('.').toLocaleUpperCase()
                    : formValues.manualPosition.toLocaleUpperCase(),
                },
              ),
          'success',
        )

        this.toggle()
      })
      .catch(error => {
        // Check for allocation space conflict (not enough allocation space)
        if (isApplicationDomainException(error, ErrorCodes.AllocationSpaceConflict)) {
          const payload = getApplicationDomainExceptionPayload(error)

          this.showPlanningAlert(
            ManualPlanningDialogStore.INSUFFICIENT_PLANNING_SPACE_ALERT_KEY,
            getInsufficientPlanningSpaceMsg(payload.locationsAvailable),
          )
        }
        // Check for dg stacking rules violations
        else if (
          isApplicationDomainException(error, ErrorCodes.DgStackingRulesViolationStackingOnTop)
        ) {
          this.showPlanningAlert(
            ManualPlanningDialogStore.DANGEROUS_GOODS_STACKING_VIOLATION_ALERT_KEY,
            tolgee.t(
              'stackingValidationStackingOnTopPlan',
              `We can't plan at this position. There is a stacking rule that prohibits stacking on top`,
            ),
          )
        } else if (
          isApplicationDomainException(error, ErrorCodes.DgStackingRulesViolationMaxAllowedTier)
        ) {
          this.showPlanningAlert(
            ManualPlanningDialogStore.DANGEROUS_GOODS_STACKING_VIOLATION_ALERT_KEY,
            tolgee.t(
              'stackingValidationMaxStackTierPlan',
              `We can't plan at this position. There is a maximum tier stacking rule in place for this position.`,
            ),
          )
        } else if (isApplicationDomainException(error, ErrorCodes.DgSegregationRulesViolation)) {
          this.showPlanningAlert(
            ManualPlanningDialogStore.DANGEROUS_GOODS_SEGREGATION_VIOLATION_ALERT_KEY,
            tolgee.t(
              'segregationRulesViolationPlan',
              `Be aware, you action violates the current segreation rules.`,
            ),
          )
        } else {
          // Generic error
          this._snackbarStore.showMessage(
            tolgee.t(
              'cantPlanAtThisPosition',
              `Something went wrong. We can't plan at this position`,
            ),
            'error',
          )
        }
      })
      .finally(() => this.toggleRequest())
  }

  public preplanningValidation() {
    this._containerPlanningService
      .prePlanningValidation({
        filter: ContainerTurnoverFilterFormMapper.mapFormValuesToFilterDto(
          this._containerTurnoversFilterStore.filter,
        ),
      })
      .then(() => this.toggle())
      .catch(error => {
        // This API error is managed using Axios interceptors.
        if (
          isApplicationDomainException(error, ErrorCodes.PreplanningMixedContainerTurnoverSizes)
        ) {
          return
        }

        this.toggle()
        if (
          isApplicationDomainException(error, ErrorCodes.PreplanningMixedDangerousAndNonDangerous)
        ) {
          this.showPlanningAlert(
            ErrorCodes.PreplanningMixedDangerousAndNonDangerous,
            tolgee.t(
              'planningContainerOfDifferentHandling',
              `Pay attention! you're planning dangerous & non dangerous containers`,
            ),
          )
        }

        if (isApplicationDomainException(error, ErrorCodes.PreplanningMixedFullAndEmpties)) {
          this.showPlanningAlert(
            ErrorCodes.PreplanningMixedFullAndEmpties,
            tolgee.t(
              'preplanningMixedFullAndEmpties',
              `Pay attention! you're planning full & empty containers`,
            ),
          )
        }
      })
  }
}
