import { useStores } from '@storage/hooks/use-stores.hook'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'

import { Button, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect } from 'react'
import WeightClassesForm from '../forms/weight-classes-form/WeightClassesForm'
import { WeightClassFormProfile } from '../forms/weight-classes-form/weight-classes-form.profile'

const WeightClassesContainer = observer(() => {
  const { t } = useTranslate()
  const { weightClassContainerUIStore, authStore } = useStores()
  const formId = 'weight-classes-form'

  useEffect(() => {
    weightClassContainerUIStore.loadWeightClasses()
  }, [weightClassContainerUIStore])

  const handleOnSubmit = (formValues: WeightClassFormProfile) => {
    if (weightClassContainerUIStore.areFormValuesValid(formValues)) {
      weightClassContainerUIStore.update(formValues)
    }
  }

  return (
    <Stack gap={3} maxWidth='710px'>
      <WeightClassesForm
        id={formId}
        onSubmit={handleOnSubmit}
        weightClasses={weightClassContainerUIStore.weightClasses}
        validationAlerts={weightClassContainerUIStore.validationAlerts}
        showAlert={(key, message) => weightClassContainerUIStore.showAlert(key, message)}
        canWrite={authStore.hasPermission('write:weight-classes')}
      />

      {authStore.hasPermission('write:weight-classes') && (
        <Box>
          <Button type='submit' form={formId} variant='contained' color='primary'>
            {t('save', 'Save')}
          </Button>
        </Box>
      )}
    </Stack>
  )
})

export default WeightClassesContainer
