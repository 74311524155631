import { useOperationsStore } from '@operations/AppProvider'
import { useTranslate } from '@tolgee/react'
import { ConfirmationDialog } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { ReeferMonitoringForm } from '../components/form/ReeferMonitoringForm'
import { ReeferMonitoringFormMapper } from '../components/form/reefer-monitoring-form.mapper'
import { ReeferMonitoringFormProfile } from '../components/form/reefer-monitoring-form.profile'
import { ReeferMonitoringDetailsUIStore } from '../stores/reefer-monitoring-details.ui-store'

interface Props {
  reeferMonitoringDetailsUIStore: ReeferMonitoringDetailsUIStore
}
export const ReeferMonitoringDialogContainer = observer(
  ({ reeferMonitoringDetailsUIStore }: Props) => {
    const { t } = useTranslate()

    const { appStore, reeferTemperatureStore } = useOperationsStore()

    const handleSubmit = async (formValues: ReeferMonitoringFormProfile) => {
      if (reeferMonitoringDetailsUIStore.formType === 'Add') {
        await reeferTemperatureStore.create(
          ReeferMonitoringFormMapper.mapFormValuesToCreateDto(formValues),
        )
      } else {
        await reeferTemperatureStore.update(
          ReeferMonitoringFormMapper.mapFormValuesToUpdateDto(formValues),
        )
      }

      appStore.setShowAlert('success', t('recordSaved', 'Record Saved'))
      reeferMonitoringDetailsUIStore.toggleRecord()
    }

    return (
      <ConfirmationDialog
        open={reeferMonitoringDetailsUIStore.isRecordOpen}
        title={`${t(reeferMonitoringDetailsUIStore.formType)} ${t('record', 'Record')}`}
        primaryActionText={t('save', 'Save')}
        closeLabel={t('close', 'Close')}
        formId='reefer-monitoring-form'
        maxWidth='md'
        onClose={() => reeferMonitoringDetailsUIStore.toggleRecord()}
      >
        <ReeferMonitoringForm
          id='reefer-monitoring-form'
          dto={reeferMonitoringDetailsUIStore.editingRecord}
          cargoUnitId={reeferMonitoringDetailsUIStore.editingEntity?.container.id ?? 0}
          containerNumber={reeferMonitoringDetailsUIStore.editingEntity?.container.containerNumber}
          requestedTemperature={
            reeferMonitoringDetailsUIStore.editingEntity?.requestedTemperature ?? 0
          }
          isTemperatureRequired={
            reeferMonitoringDetailsUIStore.editingEntity?.isMonitoringRequired ?? false
          }
          isCurrentlyPluggedIn={
            reeferMonitoringDetailsUIStore.editingEntity?.lastStatus?.isPluggedIn ?? false
          }
          lastCheck={reeferMonitoringDetailsUIStore.editingEntity?.lastStatus?.dateTime}
          onSubmit={handleSubmit}
        />
      </ConfirmationDialog>
    )
  },
)
