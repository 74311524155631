import { Button, Grid, IconButton, TextField, Typography } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { usePlanningStore } from '@planning/AppProvider'
import { CargoType, RailVisitResponseDto, UpdateRailVisitCommand } from '@planning/app/api'
import { CustomDateTimePicker } from '@planning/components/CustomDateTimePicker'
import { CargoTypeSelect } from '@planning/components/visit/CargoTypeSelect'
import railVisitService from '@planning/services/railVisitService'
import { useTranslate } from '@tolgee/react'
import { BinIcon } from '@tom-ui/ui'
import dayjs, { Dayjs } from 'dayjs'
import { observer } from 'mobx-react-lite'
import { ChangeEvent, FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

interface IProps {
  onClose: () => void
  visit: RailVisitResponseDto
}
export interface IUpdateRailVisitFormData {
  id: number
  name?: string
  eta: Dayjs | null
  etd: Dayjs | null
  inboundTripIds: string[]
  outboundTripIds: string[]
  cargoType: CargoType
  dischargeEstimate: number | null
  loadEstimate: number | null
}

export const EditRailVisitForm: FC<IProps> = observer(({ onClose, visit }) => {
  const { appViewStore } = usePlanningStore()
  const { t } = useTranslate()

  const {
    handleSubmit,
    control,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: visit.id,
      name: visit.name ?? '',
      eta: visit.eta ?? null,
      etd: visit.etd ?? null,
      cargoType: visit.cargoType,
      dischargeEstimate: visit?.dischargeEstimate === 0 ? null : visit?.dischargeEstimate,
      loadEstimate: visit?.loadEstimate === 0 ? null : visit?.loadEstimate,
    } as IUpdateRailVisitFormData,
  })

  const onFormSubmit = async (data: IUpdateRailVisitFormData) => {
    const updateCmd = {
      ...data,
      inboundTripIds: tripIdsInputs?.flatMap(obj => [obj.inbound]),
      outboundTripIds: tripIdsInputs?.flatMap(obj => [obj.outbound]),
    } as UpdateRailVisitCommand

    try {
      await railVisitService.put(updateCmd)

      appViewStore.setShowAlert(
        'success',
        t('railVisitUpdatedSuccessfully', 'Rail visit updated successfully'),
      )
      onClose?.()
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToSave', 'Failed to save'))
    }
  }

  const handleDateChange = (newValue: dayjs.Dayjs | null, onChange: any) => {
    if (newValue) {
      onChange(newValue)
    }
  }

  const buildStateForTripIds = (visit: any | undefined) => {
    if (visit && visit.inboundTripIds && visit.outboundTripIds) {
      const output = []
      for (let i = 0; i < visit.inboundTripIds.length; i++) {
        const id = i + 1
        const inbound = visit.inboundTripIds[i]
        const outbound = visit.outboundTripIds[i]
        output.push({ id, inbound, outbound })
      }

      return output.length ? output : [{ id: 1, inbound: '', outbound: '' }]
    }
  }

  const [tripIdsInputs, setTripIdsInputs] = useState(buildStateForTripIds(visit))

  const duplicateTripIdsInputs = () => {
    if (tripIdsInputs) {
      const newId = Math.max(...tripIdsInputs.map(ti => ti.id), 0) + 1
      setTripIdsInputs([...tripIdsInputs, { id: newId, inbound: '', outbound: '' }])
    }
  }

  const removeTripIds = (idToRemove: number) => {
    if (tripIdsInputs?.length === 1) {
      setTripIdsInputs([{ id: 1, inbound: '', outbound: '' }])
    } else {
      const filteredInputs = tripIdsInputs?.filter(input => input.id !== idToRemove)
      setTripIdsInputs(filteredInputs)
    }
  }

  const handleTripIdInputChanges = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number,
    type: string,
  ) => {
    const { value } = event.target
    const updatedTripIds = tripIdsInputs?.map(tripId => {
      if (tripId.id === id) {
        return { ...tripId, [type]: value }
      }
      return tripId
    })

    setTripIdsInputs(updatedTripIds)
  }

  return (
    <form id='edit-rail-visit' onSubmit={handleSubmit(onFormSubmit)}>
      <TextField
        sx={{ mb: '1rem' }}
        fullWidth
        type='text'
        label={t('trainName', 'Train Name')}
        variant='outlined'
        {...register('name')}
        name='name'
        defaultValue={visit.name}
        onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
        inputProps={{ min: 1 }}
      />
      <Controller
        control={control}
        name={`cargoType`}
        rules={{ required: true }}
        render={({ field: { onChange, value } }) => (
          <CargoTypeSelect disabled value={value} onChange={onChange} />
        )}
      />

      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('timingsEstimated', 'Timings (Estimated)')}
      </Typography>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container direction='row' columnSpacing={{ xs: 1 }}>
          <Grid item xs={12} mb={'1rem'}>
            <Controller
              control={control}
              name={`eta`}
              render={({ field: { onChange, value } }) => (
                <CustomDateTimePicker
                  label={t('eta', 'ETA')}
                  value={value?.toString() ?? null}
                  popperPlacement='left-start'
                  onChange={newValue => handleDateChange(newValue, onChange)}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={`etd`}
              render={({ field: { onChange, value } }) => (
                <CustomDateTimePicker
                  label={t('etd', 'ETD')}
                  value={value?.toString() ?? null}
                  popperPlacement='left-start'
                  onChange={newValue => handleDateChange(newValue, onChange)}
                />
              )}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>

      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('tripIds', 'Trip IDs')}
      </Typography>
      <Grid container>
        {tripIdsInputs?.map((input, index) => (
          <Grid key={index} container direction='row' columnSpacing={{ xs: 1 }} mb={'.5rem'}>
            <Grid item xs={5}>
              <TextField
                size='small'
                fullWidth
                value={input.inbound}
                label={t('inboundTripId', 'Inbound Trip ID')}
                variant='outlined'
                name='importVoyage'
                onChange={event => handleTripIdInputChanges(event, input.id, 'inbound')}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                size='small'
                fullWidth
                value={input.outbound}
                label={t('outboundTripId', 'Outbound Trip ID')}
                variant='outlined'
                name='exportVoyage'
                onChange={event => handleTripIdInputChanges(event, input.id, 'outbound')}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton color='error' onClick={() => removeTripIds(input.id)}>
                <BinIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        <Grid item mt={'.5rem'}>
          <Button size='small' onClick={duplicateTripIdsInputs}>
            + Add
          </Button>
        </Grid>
      </Grid>
      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('dischargeEstimates', 'Discharge Estimates')}
      </Typography>
      <TextField
        fullWidth
        type='number'
        defaultValue={visit.dischargeEstimate}
        label={t('dischargeEstimate', 'Discharge Estimate')}
        variant='outlined'
        {...register('dischargeEstimate')}
        name='dischargeEstimate'
        data-cy='train-edit-discharge-estimate-field'
        onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
        inputProps={{ min: 1 }}
      />
      <Typography variant='subtitle1' marginTop='1rem' marginBottom='0.5rem'>
        {t('loadEstimates', 'Load Estimates')}
      </Typography>
      <TextField
        fullWidth
        type='number'
        defaultValue={visit.loadEstimate}
        label={t('loadEstimate', 'Load Estimate')}
        variant='outlined'
        {...register('loadEstimate')}
        name='loadEstimate'
        data-cy='train-edit-load-estimate-field'
        onWheel={event => event.target instanceof HTMLElement && event.target.blur()}
        inputProps={{ min: 1 }}
      />
    </form>
  )
})
