import { Button, Grid, Typography } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { NonNumericOrderDto } from '@planning/app/api'
import { GridToolbar } from '@planning/components/GridToolbar'
import { IOrderItem } from '@planning/rt-stores/order/OrderItem'
import nnrOrderService from '@planning/services/nnrOrderService'
import { IPaginatedStoreWithItems } from '@planning/stores/PaginatedStore'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'
import { observer } from 'mobx-react-lite'
import { AssignNNROrderContainer } from './AssignNNROrderContainer'

interface Props {
  nnrOrder: NonNumericOrderDto
  orderStore: IPaginatedStoreWithItems<IOrderItem>
}

export const NNROrderDetailsToolbar = observer(({ nnrOrder, orderStore }: Props) => {
  const { t } = useTranslate()
  const theme = useMinimalsTheme()

  const { appViewStore, drawerStore } = usePlanningStore()

  if (!nnrOrder) {
    return <></>
  }

  const headerText = () => {
    const numberUnassigned = nnrOrder.amount - nnrOrder.fulfilled
    return numberUnassigned
      ? t('xContainersToBeAssigned', '{numberUnassigned} containers to be assigned', {
          numberUnassigned: numberUnassigned,
        })
      : t('containerAssignmentFinished', 'Container assignment finished')
  }

  const handleOrderClose = async () => {
    try {
      const confirmed = await appViewStore.setOpenConfirmDialog(
        true,
        `Do you really want to close this ${t(
          'non-numericRelease',
          'Non-numeric release',
        )}? The action is irreversible.`,
      )

      if (confirmed) {
        await nnrOrderService.closeOrder({ id: nnrOrder.id })
        appViewStore.setShowAlert('success', 'Order closed successfully')
      }
    } catch (error) {
      appViewStore.setShowAlert('error', 'Error in closing order')
    }
  }

  return (
    <>
      <Grid
        container
        sx={{ backgroundColor: 'black', borderRadius: theme.customRadius.medium, p: '0.5rem' }}
      >
        <Grid item xs>
          <Typography variant='h4' color='white'>
            {headerText()}
          </Typography>
        </Grid>
        <Grid item xs={1} display='flex' justifyContent='flex-end'>
          <Button
            variant='text'
            type='submit'
            disabled={nnrOrder.isClosed}
            onClick={async () => {
              drawerStore.showView(<AssignNNROrderContainer nnrOrder={nnrOrder} />)
            }}
          >
            {t('assign', 'Assign')}
          </Button>
        </Grid>
        <Grid item xs={1.3} display='flex' justifyContent='flex-end'>
          <Button
            variant='text'
            type='submit'
            onClick={handleOrderClose}
            disabled={nnrOrder.isClosed}
          >
            {t('closeOrder', 'Close Order')}
          </Button>
        </Grid>
      </Grid>
      <GridToolbar store={orderStore} />
    </>
  )
})
