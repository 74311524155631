import { debounce, TextField } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMemo } from 'react'

interface Props {
  onChange?: (containerNumbers?: string[]) => Promise<void> | void
}

export const MultipleContainerSearchTextField = ({ onChange }: Props) => {
  const { t } = useTranslate()

  const onSearchFilterChange = useMemo(
    () =>
      debounce(async (filter?: string) => {
        // setOptions(await fetch(filter))
        const containerNumbers = filter
          ?.split(/[\s,]+/)
          .map(item => item.trim())
          .filter(Boolean)
        if (onChange) onChange(containerNumbers)
      }, 1000),
    [onChange],
  )

  return (
    <TextField
      id='outlined-multiline-static'
      multiline
      rows={10}
      sx={{ width: '100%' }}
      onChange={async (event: any) => {
        onSearchFilterChange(event.target.value)
      }}
      placeholder={t(
        'hereYouCanPasteContainerNumbersSeparatedWithCommaOrSpaceToCreateOrdersForMultipleContainersAtOnce',
        'Here you can paste container numbers separated with comma or space to create orders for multiple containers at once.',
      )}
    />
  )
}
