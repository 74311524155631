import { EquipmentType } from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { useTranslate } from '@tolgee/react'
import { useMemo } from 'react'

export const useEquipmentTypesOptions = (): SelectOption[] => {
  const { t } = useTranslate()

  return useMemo(
    () =>
      Object.values(EquipmentType).map(value => {
        return {
          value: value,
          label: t(value),
        }
      }),
    [],
  )
}
