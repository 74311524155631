import {
  ControlledIsoCodeMappingInput,
  IControlledIsoCodeMappingInputProps,
} from '@host/components/iso-code-mapping-input/ControlledIsoCodeMappingInput'
import { IsoCodeMappings } from '@tom-ui/admin'
import { containerService } from '@tom-ui/planning'
import { observer } from 'mobx-react-lite'
import { FC } from 'react'

export const renderIsoCodeMappingInput = (
  props: IControlledIsoCodeMappingInputProps<any>,
): React.ReactElement => <ControlledIsoCodeMappingInput {...props} />

export const IsoCodeMappingWrapper: FC = observer(() => {
  const onFilter = async (filter: string) => {
    const characteristics = await containerService.getContainerCharacteristicsByIsoCode(
      filter?.toUpperCase(),
      true,
    )

    if (!characteristics) return null

    const standardIsoCode = {
      ...characteristics,
      isoCode: filter,
      isoCodeMappingType: 'Standard',
    }

    //TODO: Untangle this mess
    return standardIsoCode as any
  }

  return <IsoCodeMappings onFilter={onFilter} />
})
