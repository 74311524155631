import { CarrierVisitDirection, ContainerStatus, ContainerTurnoverDto } from '@storage/app/api'
import { ContainerTurnoverStore } from '@storage/stores/container-turnover.store'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

export class UnallocatedTurnoversStore {
  containerTurnovers: ContainerTurnoverDto[] = []
  tempContainerTurnovers: ContainerTurnoverDto[] = []
  dataLoading = false
  constructor(private readonly _containerTurnoverStore: ContainerTurnoverStore) {
    makeObservable(this, {
      unAllocatedTurnovers: computed,
      containerTurnovers: observable,
      tempContainerTurnovers: observable,
      setTempTurnovers: action,
      resetTempTurnovers: action,
      resetTurnovers: action,
      dataLoading: observable,
      setDataLoading: action,
    })
  }

  async loadTurnovers(carrierVisitDirection: CarrierVisitDirection, carrierVisitId?: number) {
    this.setDataLoading(true)
    const turnovers = carrierVisitId
      ? await this._containerTurnoverStore.loadCarrierVisitTurnovers(
          carrierVisitId,
          carrierVisitDirection,
        )
      : await this._containerTurnoverStore.loadAllByHandlingDirection(carrierVisitDirection)
    const turnoversNotInYard = turnovers.filter(t => t.currentPosition.length === 0)

    runInAction(() => {
      this.containerTurnovers = turnoversNotInYard
      this.tempContainerTurnovers = turnoversNotInYard
    })

    this.setDataLoading(false)
  }

  setDataLoading(value: boolean) {
    this.dataLoading = value
  }

  resetTurnovers() {
    this.containerTurnovers = []
    this.tempContainerTurnovers = []
  }

  setTempTurnovers(turnovers: ContainerTurnoverDto[]) {
    this.tempContainerTurnovers = turnovers
  }

  resetTempTurnovers(turnovers: ContainerTurnoverDto[]) {
    turnovers.forEach(turnover => {
      turnover.isAllocated = false
      turnover.currentPosition = []
      turnover.yardBlockEntry = null
      turnover.status = ContainerStatus.NotReserved
    })

    const existingTurnovers = this.containerTurnovers.filter(t => !turnovers.includes(t))

    this.setTempTurnovers([...existingTurnovers, ...turnovers])
  }

  get unAllocatedTurnovers() {
    return this.tempContainerTurnovers
  }
}
