import { JobDto, WorkInstructionJobDto } from '@operations/app/api'

export interface JobGroupDto {
  identifier: number
  groupName: string
  type: JobGroupType
  jobs: JobDto[]
}

export interface WorkInstructionJobGroupDto {
  identifier: number | string
  groupName: string
  jobs: WorkInstructionJobDto[]
}

export enum JobGroupType {
  default,
  truckVisit,
  vesselBay,
}
