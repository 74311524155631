import { createSvgIcon } from '@mui/material'

export const PowerOnIcon = createSvgIcon(
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1510_3493)'>
      <path
        d='M6.33333 13.3333V12L4.38333 10.05C4.26111 9.92778 4.16667 9.78611 4.1 9.625C4.03333 9.46389 4 9.29445 4 9.11667V6C4 5.63333 4.13056 5.31944 4.39167 5.05833C4.65278 4.79722 4.96667 4.66667 5.33333 4.66667H6L5.33333 5.33333V2.66667C5.33333 2.47778 5.39722 2.31944 5.525 2.19167C5.65278 2.06389 5.81111 2 6 2C6.18889 2 6.34722 2.06389 6.475 2.19167C6.60278 2.31944 6.66667 2.47778 6.66667 2.66667V4.66667H9.33333V2.66667C9.33333 2.47778 9.39722 2.31944 9.525 2.19167C9.65278 2.06389 9.81111 2 10 2C10.1889 2 10.3472 2.06389 10.475 2.19167C10.6028 2.31944 10.6667 2.47778 10.6667 2.66667V5.33333L10 4.66667H10.6667C11.0333 4.66667 11.3472 4.79722 11.6083 5.05833C11.8694 5.31944 12 5.63333 12 6V9.11667C12 9.29445 11.9667 9.46389 11.9 9.625C11.8333 9.78611 11.7389 9.92778 11.6167 10.05L9.66667 12V13.3333C9.66667 13.5222 9.60278 13.6806 9.475 13.8083C9.34722 13.9361 9.18889 14 9 14H7C6.81111 14 6.65278 13.9361 6.525 13.8083C6.39722 13.6806 6.33333 13.5222 6.33333 13.3333Z'
        fill='currentColor'
      />
      <g clipPath='url(#clip1_1510_3493)'>
        <path
          d='M12 11.775L12.7917 12.5667C12.8528 12.6278 12.9292 12.6583 13.0209 12.6583C13.1125 12.6583 13.1917 12.6278 13.2584 12.5667C13.325 12.5 13.3584 12.4208 13.3584 12.3292C13.3584 12.2375 13.325 12.1583 13.2584 12.0917L12.2334 11.0667C12.1667 11 12.0889 10.9667 12 10.9667C11.9112 10.9667 11.8334 11 11.7667 11.0667L10.7334 12.1C10.6667 12.1667 10.6348 12.2445 10.6375 12.3333C10.6403 12.4222 10.675 12.5 10.7417 12.5667C10.8084 12.6278 10.8862 12.6597 10.975 12.6625C11.0639 12.6653 11.1417 12.6333 11.2084 12.5667L12 11.775ZM12 8.66668C12.4612 8.66668 12.8945 8.75418 13.3 8.92918C13.7056 9.10418 14.0584 9.34168 14.3584 9.64168C14.6584 9.94168 14.8959 10.2945 15.0709 10.7C15.2459 11.1056 15.3334 11.5389 15.3334 12C15.3334 12.4611 15.2459 12.8945 15.0709 13.3C14.8959 13.7056 14.6584 14.0583 14.3584 14.3583C14.0584 14.6583 13.7056 14.8958 13.3 15.0708C12.8945 15.2458 12.4612 15.3333 12 15.3333C11.5389 15.3333 11.1056 15.2458 10.7 15.0708C10.2945 14.8958 9.94171 14.6583 9.64171 14.3583C9.34171 14.0583 9.10421 13.7056 8.92921 13.3C8.75421 12.8945 8.66671 12.4611 8.66671 12C8.66671 11.5389 8.75421 11.1056 8.92921 10.7C9.10421 10.2945 9.34171 9.94168 9.64171 9.64168C9.94171 9.34168 10.2945 9.10418 10.7 8.92918C11.1056 8.75418 11.5389 8.66668 12 8.66668Z'
          fill='currentColor'
        />
      </g>
    </g>
    <defs>
      <clipPath id='clip0_1510_3493'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
      <clipPath id='clip1_1510_3493'>
        <rect width='8' height='8' fill='white' transform='translate(16 16) rotate(-180)' />
      </clipPath>
    </defs>
  </svg>,
  'PowerOnIcon',
)
