import { Palette, PaletteColor, Theme } from '@mui/material'
import {
  EquipmentType,
  LengthMeasurementUnit,
  OperationType,
  WorkInstructionPackageDto,
} from '@operations/app/api'
import { SelectOption } from '@operations/app/models'

export const numberPadding = (val: number) => {
  return val < 10 ? `0${val}` : val
}

export const convertOperationType = (operationType: OperationType) => {
  switch (operationType) {
    case OperationType.Inbound:
      return 'discharge'
    case OperationType.Outbound:
      return 'load'
    case OperationType.Internal:
      return 'internal'
    case OperationType.Service:
      return 'service'
    case OperationType.Restow:
      return 'restow'
  }
}

export const getThemePalleteByColorName = (theme: Theme, colorname: string) => {
  return theme.palette[colorname as keyof Palette] as PaletteColor
}

export const lowerFirstCase = (value: string) => {
  if (value.length === 0) {
    return value
  }

  const firstCharLowercased = value.charAt(0).toLowerCase()
  return firstCharLowercased + value.slice(1)
}

export const defaultSelectOption = {
  label: 'please select an option',
  value: '',
} as SelectOption

export const getRGBFromThemeByColorName = (theme: Theme, colorname: string, percentage = 1) => {
  const palette = getThemePalleteByColorName(theme, colorname)

  return hexadecimalToRGB(palette.main, percentage)
}

export const hexadecimalToRGB = (hexadecimalColor: string, percentage = 1) => {
  hexadecimalColor = hexadecimalColor.replace(/^#/, '')

  const bigint = parseInt(hexadecimalColor, 16)

  const r = (bigint >> 16) & 255
  const g = (bigint >> 8) & 255
  const b = bigint & 255

  return `rgb(${r}, ${g}, ${b}, ${percentage})`
}

export const formatContainerNumber = (containerNumber: string) => {
  const container = containerNumber.trim().toUpperCase()
  const ownerCode = container.substring(0, 4)
  const serialNumber = container.substring(4, 10)
  const checkDigit = container.substring(10)

  const formattedContainerNumber = `${ownerCode} ${serialNumber} ${checkDigit}`

  return formattedContainerNumber
}

export const canBeAssignedToYardBlock = (equipmentType?: string | number) => {
  return (
    equipmentType === EquipmentType.Rs ||
    equipmentType === EquipmentType.Ech ||
    equipmentType === EquipmentType.Rtg ||
    equipmentType === EquipmentType.Sts ||
    equipmentType === EquipmentType.Fl ||
    equipmentType === EquipmentType.Rmg
  )
}

export const getPackageName = ({
  name,
  length,
  width,
  height,
  lengthUnit,
}: WorkInstructionPackageDto) => {
  let packageName = name
  const divider = 'x'
  const space = ' '
  const slash = ' | '

  if (name) packageName += length || width || lengthUnit ? slash : ''

  if (length) packageName += ` ${length}`

  if (width) packageName += (length ? divider : space) + `${width}`

  if (height) packageName += (length || width ? divider : space) + `${height}`

  if (lengthUnit) packageName += ` ${simplifyLengthMeasurementUnit(lengthUnit)}`

  return packageName
}

export const simplifyLengthMeasurementUnit = (unit?: LengthMeasurementUnit | null) => {
  switch (unit) {
    case LengthMeasurementUnit.Millimeters:
      return 'mm'
    case LengthMeasurementUnit.Centimeter:
      return 'cm'
    case LengthMeasurementUnit.Meter:
      return 'm'
    case LengthMeasurementUnit.Feet:
      return 'ft'
    case LengthMeasurementUnit.Inches:
      return 'in'
    default:
      return ''
  }
}
