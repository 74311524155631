import { RestowResponseDto } from '@planning/app/api'
import { makeObservable } from 'mobx'
import { EntityItem } from '../base/EntityItem'
import { IEntity } from '../types'

export type IRestowItem = IEntity<RestowResponseDto>

export class RestowItem extends EntityItem<RestowResponseDto> implements IRestowItem {
  constructor(id: number, data: any) {
    super(id, data)

    makeObservable(this, {})
  }
}
