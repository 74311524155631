import { Box, Typography } from '@mui/material'
import { stackHeightInPixels, stackWidthInPixels } from '@storage/pages/yard-management/constants'
import { useMinimalsTheme } from '@tom-ui/ui'
import { useMemo } from 'react'

interface YardBlockPreviewProps {
  bayNames: string[]
  rowNames: string[]
}

// Helper function to create a limited label sequence with ellipsis
const createLimitedLabelSequence = (labels: string[], limit: number) => {
  if (labels.length <= limit) {
    return labels
  }
  const splitLimit = Math.floor(limit / 2)
  return [...labels.slice(0, splitLimit), '...', ...labels.slice(-splitLimit)]
}

export const YardBlockPreview = ({ bayNames, rowNames }: YardBlockPreviewProps) => {
  const { palette } = useMinimalsTheme()

  // Labels limits
  const bayLimit = 7
  const rowLimit = 6

  const limitedBayNames = useMemo(
    () => createLimitedLabelSequence(bayNames, bayLimit),
    [bayNames, bayLimit],
  )
  const limitedRowNames = useMemo(
    () => createLimitedLabelSequence(rowNames, rowLimit),
    [rowNames, rowLimit],
  )

  return (
    <Box sx={{ m: 'auto' }}>
      {/* Bay labels */}
      <Box sx={{ display: 'flex', gap: '2px' }}>
        {limitedBayNames.map(bayName => (
          <Box
            key={bayName}
            sx={{
              display: 'flex',
              position: 'relative',
              left: stackWidthInPixels + 2,
              backgroundColor: palette.grey[300],
              borderRadius: 1,
              width: stackWidthInPixels,
              height: stackHeightInPixels,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant='button'>{bayName}</Typography>
          </Box>
        ))}
      </Box>

      {/* Row labels and cells */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2px',
          marginTop: '2px',
        }}
      >
        {limitedRowNames.map(rowName => (
          <Box key={rowName} sx={{ display: 'flex', flexDirection: 'row', gap: '2px' }}>
            <Box
              sx={{
                display: 'flex',
                backgroundColor: palette.grey[300],
                borderRadius: 1,
                width: stackWidthInPixels,
                height: stackHeightInPixels,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant='button'>{rowName}</Typography>
            </Box>
            {limitedBayNames.map((_, cellIndex) => (
              <Box
                key={`${cellIndex}.${rowName}`}
                sx={{
                  border: 1,
                  borderRadius: 1,
                  width: stackWidthInPixels,
                  height: stackHeightInPixels,
                }}
              />
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  )
}
