import { Button } from '@mui/material'
import { Box } from '@mui/system'
import { usePlanningStore } from '@planning/AppProvider'
import DangerousGoodsDefinitionsTable from '@planning/pages/DangerousGoodsDefinitions/components/DangerousGoodsDefinitionsTable'
import DangerousGoodsDefinitionsTableDialog from '@planning/pages/DangerousGoodsDefinitions/components/DangerousGoodsDefinitionsTableDialog'
import Permission from '@planning/permission.enum'

import { useTranslate } from '@tolgee/react'
import { Header, PlusIcon } from '@tom-ui/ui'

export const DangerousGoodsDefinitionsPage = () => {
  const { t } = useTranslate()

  const { dangerousGoodsDefinitionTableViewStore, authStore } = usePlanningStore()
  const formDialogUtilStore = dangerousGoodsDefinitionTableViewStore.formDialogUtilStore
  return (
    <>
      <Header
        searchInputLabel={t('searchByUNNumber', 'Search by UN number')}
        displaySearchIcon
        enableSearchField
        onSearchFieldChange={value => dangerousGoodsDefinitionTableViewStore.setSearchQuery(value)}
        rightRenderOption={
          authStore.hasPermission(Permission.WriteDangerousGoodsSettings) && (
            <Box flex='none'>
              <Button
                color='inherit'
                variant='contained'
                startIcon={<PlusIcon />}
                onClick={() => formDialogUtilStore.toggleDialog('Add')}
              >
                {t('addNew', 'Add new')}
              </Button>
            </Box>
          )
        }
      />

      <DangerousGoodsDefinitionsTable />
      <DangerousGoodsDefinitionsTableDialog />
    </>
  )
}
