import { Stack, Typography } from '@mui/material'

interface InlineDetailProps {
  title: string
  value?: string | number | null
  separator?: string
}

const InlineTextValue = ({ title, value = '-', separator = ':' }: InlineDetailProps) => {
  return (
    <Stack direction='row' spacing={0.5}>
      <Typography variant='body1' color='text.secondary'>
        {title}
        {separator}
      </Typography>
      <Typography variant='body1' color='text.secondary'>
        {value}
      </Typography>
    </Stack>
  )
}

export default InlineTextValue
