import {
  CarrierType,
  CarrierVisitDirection,
  CreateAllocationRuleDto,
  GlobalAllocationRuleDto,
} from '@storage/app/api'
import { EntityStore } from '@storage/app/store/entity.store'
import { GlobalAllocationRuleService } from '@storage/services/global-allocation-rule.service'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { action, makeObservable, observable, runInAction } from 'mobx'
export class GlobalAllocationRuleStore extends EntityStore<GlobalAllocationRuleDto> {
  initialEntries: GlobalAllocationRuleDto[] = []
  isLoading = false

  constructor(private readonly _globalAllocationRuleService: GlobalAllocationRuleService) {
    super()
    makeObservable(this, {
      initialEntries: observable,
      isLoading: observable,
      setIsLoading: action,
    })
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  async loadAll(direction?: CarrierVisitDirection, carrierType?: CarrierType) {
    this.setIsLoading(true)
    const globalAllocationRules = await this._globalAllocationRuleService.get(
      direction,
      carrierType,
    )
    runInAction(() => {
      this.initialEntries = cloneDeep(globalAllocationRules)
      this.setAll(globalAllocationRules)
    })
    this.setIsLoading(false)
  }

  reorder(startIndex: number, endIndex: number) {
    const currentEntries = this.entries
    const [removed] = currentEntries.splice(startIndex, 1)
    currentEntries.splice(endIndex, 0, removed)

    this.setAll(currentEntries)
  }

  resetData() {
    this.removeAll()
  }

  get hasChanges(): boolean {
    return !isEqual(this.initialEntries, this.entries)
  }

  async saveChanges(allocationRules: CreateAllocationRuleDto[], direction: CarrierVisitDirection) {
    await this._globalAllocationRuleService.create({
      allocationRules,
      direction,
    })
  }
}
