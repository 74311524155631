import { Box, Button, Tooltip } from '@mui/material'
import { usePlanningStore } from '@planning/AppProvider'
import { CarrierVisitDirection, CarrierVisitStatus } from '@planning/app/api'
import { CargoTypeIconLabel } from '@planning/components/CargoTypeIconLabel'
import { CarrierVisitStatusChip } from '@planning/components/CarrierVisitStatusChip'
import { FilterPaper } from '@planning/components/FilterPaper'
import { GridToolbar } from '@planning/components/GridToolbar'
import { LabeledTypography } from '@planning/components/LabeledTypography'
import { GridColumn, StickyHeaderTable } from '@planning/components/PaginatedTable'
import { StatusRadioGroupFilter } from '@planning/components/StatusRadioGroupFilter'
import { carrierVisitStatus, emptyIndicator } from '@planning/constants'
import { IVesselVisitItem, VesselVisitItem } from '@planning/rt-stores/vesselVisit/VesselVisitItem'
import carrierVisitService from '@planning/services/carrierVisitService'
import { IFilterDelegate, ISortDelegate } from '@planning/stores/PaginatedLocalStore'
import { SortingModel } from '@planning/stores/PaginatedStore'
import { formatDateTime } from '@planning/utils'
import { useTranslate } from '@tolgee/react'
import { BinIcon, EditIcon, IconButton } from '@tom-ui/ui'
import dayjs from 'dayjs'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { CreateVesselVisitForm } from './Components/Forms/CreateVesselVisitForm'
import { EditVesselVisitForm } from './Components/Forms/EditVesselVisitForm'

const noneIndicator = 'None'

export const vesselVisitSortingDelegate: ISortDelegate<IVesselVisitItem> = (sortingModel, a, b) => {
  const getValue = (item: IVesselVisitItem, sortingModel: SortingModel<IVesselVisitItem>) => {
    if (sortingModel.orderBy) {
      if (_.has(item, sortingModel.orderBy)) return _.get(item, sortingModel.orderBy)
      if (_.has(item.data, sortingModel.orderBy)) return _.get(item.data, sortingModel.orderBy)
    }
    return item.data.eta
  }

  const valA = getValue(a, sortingModel)
  const valB = getValue(b, sortingModel)
  let sort = 0
  if (moment(valA).isValid() && moment(valB).isValid()) {
    sort = moment(valA).diff(moment(valB))
  } else if (typeof valA === 'string' && typeof valB === 'string') {
    sort = valA.localeCompare(valB)
  }

  return sortingModel.isDescending ? -sort : sort
}

export const vesselVisitFilterDelegate: IFilterDelegate<IVesselVisitItem> = (
  filter: string,
  item: IVesselVisitItem,
  showCompleted?: boolean,
  from?: Date,
  to?: Date,
  filterStatus?: string,
) => {
  const filterableProperties: string[] = ['data.cargoType', 'data.name', 'data.shippingLine']

  if (!showCompleted && item.data.status === CarrierVisitStatus.Departed) return false

  if (filterStatus && item.data.status !== filterStatus) {
    return false
  }

  if (dayjs(from) > dayjs(item.arrival) || dayjs(item.arrival) > dayjs(to)) {
    return false
  }

  if (filter !== '') {
    return filterableProperties
      .map(p => p as keyof VesselVisitItem)
      .some(p => {
        const prop = _.get(item, p)
        const vessel = _.map(item.vessels, p)[0]

        if (vessel) {
          return vessel.toLowerCase().includes(filter.toLowerCase())
        }

        if (typeof prop === 'string') return prop.toLowerCase().includes(filter.toLowerCase())
        if (typeof prop === 'number') return prop === parseFloat(filter)

        return false
      })
  }

  return true
}
const VesselVisitActionCell: FC<{
  item: VesselVisitItem
  onDelete: () => void
  onEdit: () => void
}> = observer(({ item, onDelete, onEdit }) => {
  const { t } = useTranslate()
  return (
    <Box sx={{ display: 'flex' }}>
      {item.data.status === 'Expected' && (
        <>
          <Tooltip key='edit-order' arrow placement='bottom' title={t('edit', 'Edit')}>
            <IconButton onClick={onEdit}>
              <EditIcon />
            </IconButton>
          </Tooltip>

          <Tooltip key='delete-order' arrow placement='bottom' title={t('delete', 'Delete')}>
            <IconButton color='error' onClick={onDelete}>
              <BinIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  )
})

export const vesselVisitDetailPages = {
  dashboard: 'dashboard',
  discharge: 'discharge',
  load: 'load',
  restow: 'restow',
}

export const VesselVisitsPage = observer(() => {
  const { appViewStore, vesselVisitItemStore, drawerStore, vesselVisitListViewStore } =
    usePlanningStore()
  const navigate = useNavigate()
  const { t } = useTranslate()

  useEffect(() => {
    vesselVisitListViewStore.loadCurrentPage()
  }, [vesselVisitListViewStore])

  const handleCreateVesselVisit = () => {
    drawerStore.showView(<CreateVesselVisitForm onClose={drawerStore.close} />, {
      title: t('create', 'Create'),
      subTitle: t('vesselVisit', 'Vessel Visit'),
      primaryActionLabel: t('submit', 'Submit'),
      closeActionLabel: t('close', 'Close'),
      formId: 'create-vessel-visit',
    })
  }

  const handleDelete = async (id: number) => {
    try {
      const confirmed = await appViewStore.setOpenConfirmDialog(
        true,
        t('doYouWantToDeleteTheVesselVisit', 'Do you want to delete the vessel visit?'),
      )
      if (confirmed) {
        await carrierVisitService.delete(id)
        appViewStore.setShowAlert(
          'success',
          t('vesselVisitDeletedSuccessfully', 'Vessel Visit deleted successfully'),
        )
      }
    } catch (error) {
      appViewStore.setShowAlert('error', t('failedToDelete', 'Failed to delete'))
    }
  }

  const handleEdit = async (id: number) => {
    const vesselVisitItem = vesselVisitItemStore.elements[id]

    drawerStore.showView(
      <EditVesselVisitForm
        vesselVisit={vesselVisitItem.data}
        vessel={vesselVisitItem.vessels.map(v => v.data)}
        onClose={drawerStore.close}
        showAlert={appViewStore.setShowAlert}
      />,
      {
        title: t('edit', 'Edit'),
        subTitle: t('vesselVisit', 'Vessel Visit'),
        primaryActionLabel: t('save', 'Save'),
        closeActionLabel: t('close', 'Close'),
        formId: 'edit-vessel-visit',
      },
    )
  }

  const handleShowFilter = () => {
    drawerStore.showView(
      <FilterPaper sx={{ minWidth: '24rem' }}>
        <StatusRadioGroupFilter
          store={vesselVisitListViewStore}
          options={carrierVisitStatus.filter(
            s => vesselVisitListViewStore.showCompleted || s.value !== 'Departed',
          )}
          title='Vessel Visit Status'
        />
      </FilterPaper>,
    )
  }

  const getDischargeCount = (visit: VesselVisitItem) => {
    const total = visit.getCount('total', CarrierVisitDirection.Inbound, visit.data.cargoType)

    if (total === 0 || total == null) {
      return visit.discharge.orderCounts.estimated
    }
    return total
  }

  const getLoadCount = (visit: VesselVisitItem) => {
    const total = visit.getCount('total', CarrierVisitDirection.Outbound, visit.data.cargoType)

    if (total === 0 || total == null) {
      return visit.load.orderCounts.estimated
    }
    return total
  }

  const columns: GridColumn<VesselVisitItem>[] = [
    {
      field: 'vesselName',
      headerName: t('vessel', 'Vessel'),
      minWidth: 170,
      renderCell: ({ row }) => {
        let shippingLine = emptyIndicator
        let vesselName = emptyIndicator

        if (row.vessels.length > 0 && row.vessels[0] !== undefined) {
          shippingLine = `${row.vessels[0].data.shippingLine}`
          const primaryVesselName = `${row.vessels[0].data.name}`

          vesselName =
            row.vessels.length === 1
              ? primaryVesselName
              : `${primaryVesselName} +${row.vessels.length - 1}`
        }

        return (
          <LabeledTypography label={shippingLine} text={vesselName} alignItems='start' labelBelow />
        )
      },
      sortable: true,
      orderBy: 'vessel.name',
      flex: 0.5,
    },
    {
      field: 'eta',
      headerName: t('etaAta', 'ETA / ATA'),
      valueGetter: ({ row }) => row.arrival,
      renderCell: ({ row }) => {
        return (
          <LabeledTypography
            label={row.data.ata ? t('ata', 'ATA') : t('eta', 'ETA')}
            text={formatDateTime(row.arrival)}
            alignItems='start'
            labelBelow
          />
        )
      },
      minWidth: 130,
      sortable: true,
      orderBy: 'arrival',
      flex: 0.5,
    },
    {
      field: 'etd',
      headerName: t('etdAtd', 'ETD / ATD'),
      valueGetter: ({ row }) => row.departure,
      renderCell: ({ row }) => {
        return (
          <LabeledTypography
            label={row.data.atd ? t('atd', 'ATD') : t('etd', 'ETD')}
            text={formatDateTime(row.departure)}
            alignItems='start'
            labelBelow
          />
        )
      },
      minWidth: 130,
      sortable: true,
      orderBy: 'departure',
      flex: 0.5,
    },
    {
      field: 'cargoType',
      headerName: t('cargoType', 'Cargo Type'),
      renderCell: ({ row }) => <CargoTypeIconLabel cargoType={row.data.cargoType} />,
      minWidth: 20,
      sortable: false,
      flex: 0.5,
    },
    {
      field: 'tripIds',
      headerName: t('tripIds', 'Trip IDs'),
      renderCell: ({ row }) => {
        const inboundTripIds =
          row.data.inboundTripIds &&
          row.data.inboundTripIds.length &&
          row.data.inboundTripIds[0] !== ''

        const outboundTripIds =
          row.data.outboundTripIds &&
          row.data.outboundTripIds.length &&
          row.data.outboundTripIds[0] !== ''

        return (
          <>
            {inboundTripIds
              ? `In: ${row.data.inboundTripIds?.filter(id => id.trim() !== '').join(', ')}`
              : emptyIndicator}
            <br />
            {outboundTripIds
              ? `Out: ${row.data.outboundTripIds?.filter(id => id.trim() !== '').join(', ')}`
              : emptyIndicator}
          </>
        )
      },
      minWidth: 200,
      sortable: false,
      flex: 0.75,
    },
    {
      field: 'berths',
      headerName: t('berths', 'Berths'),
      valueGetter: ({ row }) => {
        return `${row.berths?.length ? row.berths.map(b => b.name).join(', ') : noneIndicator} ${
          row.data.berthSide ? '(' + row.data.berthSide + ')' : ''
        }`
      },
      minWidth: 20,
      sortable: false,
      flex: 0.5,
    },
    {
      field: 'dischargeEstimate',
      headerName: t('dischargeLoad', 'Discharge / Load'),
      valueGetter: ({ row }) => {
        return `${getDischargeCount(row)} / ${getLoadCount(row)}`
      },
      minWidth: 100,
      sortable: false,
      flex: 0.5,
    },
    {
      field: 'status',
      headerName: t('status', 'Status'),
      minWidth: 150,
      renderCell: ({ row }) => {
        return <CarrierVisitStatusChip status={row.status} />
      },
      sortable: true,
      orderBy: 'status',
      flex: 0.5,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      minWidth: 75,
      sortable: false,
      renderCell: ({ row }) => (
        <VesselVisitActionCell
          item={row}
          onDelete={() => handleDelete(row.id)}
          onEdit={() => handleEdit(row.id)}
        />
      ),
    },
  ]

  /*
    This is a workaround to force MobX refresh when orderCounts and vessels changes
    All calculated properties inside "renderCell: ({ row }) => ()" in columns is not tracked
    @North maybe find a different solution for this problem like using MobX reaction?
  */
  const forceToTrackOrderCountsAndVesselsUpdated = () => {
    vesselVisitListViewStore.pageItems.map(c => c.load.orderCounts)
    vesselVisitListViewStore.pageItems.map(c => c.vessels)
  }
  forceToTrackOrderCountsAndVesselsUpdated()

  return (
    <StickyHeaderTable
      store={vesselVisitListViewStore}
      columns={columns}
      toolBar={
        <GridToolbar
          title={t('vesselVisits', 'Vessel Visits')}
          searchPlaceholder={t(
            'searchByTypeOrVesselOrCustomerName',
            'Search by type or vessel/customer name',
          )}
          store={vesselVisitListViewStore}
          actions={
            <Button
              variant='contained'
              onClick={handleCreateVesselVisit}
              data-cy='create-vessel-visit-btn'
            >
              {t('create', 'Create')}
            </Button>
          }
          dateRange
          onShowFilter={() => handleShowFilter()}
        />
      }
      onCellClick={(column, event) => {
        if (column.field === 'actions') {
          event.stopPropagation()
        }
        event.preventDefault()
      }}
      onRowClick={({ id }) => {
        navigate(`${id}/${vesselVisitDetailPages.dashboard}`)
      }}
      rowsPerPageOptions={[10, 25, 50]}
    />
  )
})
