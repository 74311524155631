import { EquipmentDto } from '@operations/app/api'
import { BaseDrawerStore } from '@operations/stores/base/BaseDrawerStore'
import { EquipmentStore } from '@operations/stores/EquipmentStore'
import { action, computed, makeObservable, observable } from 'mobx'

export class EquipmentDialogUIStore extends BaseDrawerStore<EquipmentDto> {
  isDeleteOpen = false

  constructor(private equipmentStore: EquipmentStore) {
    super(equipmentStore)
    makeObservable(this, {
      isDeleteOpen: observable,

      toggleDelete: action,

      yardBlocksWithEquipments: computed,
    })
  }

  public toggleDelete(): void {
    this.isDeleteOpen = !this.isDeleteOpen
  }

  public get yardBlocksWithEquipments() {
    const dict: Record<string, EquipmentDto[]> = {}

    this.equipmentStore.items
      .filter(x => x.yardBlocks?.length)
      .forEach(equipment => {
        equipment.yardBlocks?.forEach(yardBlock => {
          const dictYard = dict[yardBlock.id]
          if (dictYard) {
            dictYard.push(equipment)
          } else {
            dict[yardBlock.id] = [equipment]
          }
        })
      })

    return dict
  }
}
