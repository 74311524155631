import * as yup from 'yup'
import { ContainerInformationWidgetProfile } from './container-information-widget-form.profile'

export const containerInformationWidgetFormId = 'container-information-widget-form'
const errorMessage = 'atLeastOneAttributeMustBeSelected'
export const yupContainerInformationValidationField = 'container-information-form-validation'
export const schema: yup.ObjectSchema<ContainerInformationWidgetProfile> = yup
  .object({
    isSizeSelected: yup.boolean().required(),
    isCargoStatusSelected: yup.boolean().required(),
    isOperatorSelected: yup.boolean().required(),
    isPortOfDischargeSelected: yup.boolean().required(),
  })
  .test(yupContainerInformationValidationField, errorMessage, function (values) {
    const { isSizeSelected, isCargoStatusSelected, isOperatorSelected, isPortOfDischargeSelected } =
      values

    if (
      !isSizeSelected &&
      !isCargoStatusSelected &&
      !isOperatorSelected &&
      !isPortOfDischargeSelected
    ) {
      return false
    }

    return true
  })
