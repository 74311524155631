import { useTranslate } from '@tolgee/react'
import { BinIcon, IconButton } from '@tom-ui/ui'
import { FC } from 'react'

type Props = {
  onClick: () => void
}

export const DeleteIconButton: FC<Props> = ({ onClick }) => {
  const { t } = useTranslate()
  const label = t('delete', 'Delete')

  return (
    <IconButton aria-label={label} onClick={onClick}>
      <BinIcon />
    </IconButton>
  )
}
