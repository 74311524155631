import {
  CarrierType,
  CarrierVisitDirection,
  ContainerTurnoverDto,
  ContainerTurnoversApiGetAllRequest,
  ContainerTurnoversFilterDto,
  PaginationQuery,
} from '@storage/app/api'
import { SelectOption } from '@storage/app/models'
import { PaginatedEntityStore } from '@storage/app/store/paginated-entity.store'
import { YardManagementHubConnection } from '@storage/hubs/yard-management.hub-connection'
import { ContainerTurnoverService } from '@storage/services/container-turnover.service'
import { makeObservable, observable, runInAction } from 'mobx'
import { CarrierVisitStore } from './carrier-visit.store'

export class ContainerTurnoverStore extends PaginatedEntityStore<ContainerTurnoverDto> {
  carrierVisitTurnovers: Map<number, ContainerTurnoverDto[]> = new Map()
  directionTurnovers: Map<CarrierVisitDirection, ContainerTurnoverDto[]> = new Map()

  constructor(
    private readonly _containerTurnoverService: ContainerTurnoverService,
    vesselVisitStore: CarrierVisitStore,
    yardManagementHubConnection: YardManagementHubConnection,
  ) {
    super()

    makeObservable(this, {
      carrierVisitTurnovers: observable,
      directionTurnovers: observable,
    })

    yardManagementHubConnection.onOrdersUpserted(containerTurnovers => {
      const inboundVesselVisitIds = containerTurnovers
        .filter(x => x.inboundCarrierType === CarrierType.Vessel)
        .map(x => x.inboundCarrierVisitId)

      const outboundVesselVisitIds = containerTurnovers
        .filter(x => x.outboundCarrierType === CarrierType.Vessel)
        .map(x => x.outboundCarrierVisitId)

      const vesselVisitIds = [...inboundVesselVisitIds, ...outboundVesselVisitIds]

      if (vesselVisitIds.length) {
        const uniqueIds = [...new Set(vesselVisitIds)]
        for (const id of uniqueIds) {
          if (id) {
            vesselVisitStore.loadCarrierVisitSummary(id)
          }
        }
      }
    })
  }

  public async loadAll(
    filter?: ContainerTurnoversFilterDto,
    pagination?: PaginationQuery,
  ): Promise<void> {
    const containerTurnovers = await this._containerTurnoverService.getAll({
      ...filter,
      ...pagination,
    } as ContainerTurnoversApiGetAllRequest)

    this.paginate(containerTurnovers)
  }

  public toOptions(entries: ContainerTurnoverDto[]): SelectOption[] {
    return entries.map(({ id, containerNumber }) => ({
      label: containerNumber,
      value: id,
    }))
  }

  public async getContainerTurnover(containerTurnoverId: string) {
    return await this._containerTurnoverService.getTurnover({ id: containerTurnoverId })
  }

  public async loadCarrierVisitTurnovers(carrierVisitId: number, direction: CarrierVisitDirection) {
    const carrierVisitTurnovers =
      await this._containerTurnoverService.getCarrierVisitContainerTurnovers(
        carrierVisitId,
        direction,
      )

    runInAction(() => {
      this.carrierVisitTurnovers.set(carrierVisitId, carrierVisitTurnovers)
    })

    return carrierVisitTurnovers
  }

  public async loadAllByHandlingDirection(handlingDirection: CarrierVisitDirection) {
    const containerTurnovers =
      await this._containerTurnoverService.getTurnoversByCarrierDirection(handlingDirection)

    runInAction(() => {
      this.directionTurnovers.set(handlingDirection, containerTurnovers)
    })

    return containerTurnovers
  }
}
