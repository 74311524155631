import { Box, Typography } from '@mui/material'
import { useTranslate } from '@tolgee/react'
import { useMinimalsTheme } from '@tom-ui/ui'

export const EmptyCustoms = () => {
  const theme = useMinimalsTheme()
  const { t } = useTranslate()

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.neutral,
        textAlign: 'center',
        borderRadius: theme.customRadius.medium,
        px: theme.customSpacing.m,
        py: theme.customSpacing.xs,
      }}
    >
      <Typography variant='body2' color={theme.palette.text.primary}>
        {t('noReferencesAddedYet', 'No references added yet')}
      </Typography>
    </Box>
  )
}
