import { CoolingOrderDtoV2, ReeferTemperatureDto, ReeferTemperaturesApi } from '@operations/app/api'
import { createApiClient } from '@operations/app/http-client'
import { EventTypes } from '@operations/messages/eventsTypes'
import { IEvent, IMessageBus } from '@operations/messages/messageBus'
import { AppStore } from '@tom-ui/utils'
import { BaseEntityStore } from './base/BaseEntityStore'

export class ReeferTemperatureStoreV2 extends BaseEntityStore<CoolingOrderDtoV2> {
  loadingSignalR = false

  constructor(
    private messageBus: IMessageBus,
    private appStore: AppStore,
  ) {
    super()

    this.messageBus.subscribeEvent<ReeferTemperatureDto>(
      EventTypes.ReeferTemperatureUpserted,
      this.handleTemperatureRecordUpserted,
    )

    // this.messageBus.subscribeEvent<CoolingOrderDtoV2>(
    //   EventTypes.CoolingOrderUpserted,
    //   this.handleCoolingOrderUpserted,
    // )

    this.messageBus.subscribeEvent(
      EventTypes.CarrierVisitRefresh,
      this.reloadInProgressCoolingOrdersBySignalR,
    )

    this.messageBus.subscribeEvent(EventTypes.WorkInstructionFinished, this.finishWorkInstruction)
  }

  handleTemperatureRecordUpserted = (res: IEvent<ReeferTemperatureDto>) => {
    const upsertedRecord = res.payload

    if (!upsertedRecord) return

    const coolingOrder = this.items.find(x => x.container.id === upsertedRecord.cargoUnitId)

    if (coolingOrder) {
      const newDto = { ...coolingOrder }

      newDto.reeferTemperatures = newDto.reeferTemperatures?.filter(x => x.id !== upsertedRecord.id)
      newDto.reeferTemperatures?.push(upsertedRecord)

      this.updateStoreItem(newDto, newDto.id)
    }
  }

  handleCoolingOrderUpserted = (res: IEvent<CoolingOrderDtoV2>) => {
    const upsertedOrder = res.payload

    if (!upsertedOrder) return

    const coolingOrder = this.items.find(x => x.id === upsertedOrder?.id)

    if (coolingOrder) {
      upsertedOrder.reeferTemperatures = coolingOrder.reeferTemperatures
    }

    this.updateStoreItem(upsertedOrder, upsertedOrder.id)
  }

  reloadInProgressCoolingOrdersBySignalR = async () => {
    const currentHost = window.location.pathname

    if (
      !currentHost.startsWith('/reefer-operator') &&
      !currentHost.startsWith('/jobs/reeferMonitoring')
    )
      return

    let isItForOperator = undefined
    if (currentHost.startsWith('/reefer-operator')) {
      isItForOperator = true
    }

    if (!this.loadingSignalR) {
      this.loadingSignalR = true
      await this.appStore.triggerRequestWithoutLoader(
        async () => await this.loadInProgressCoolingOrders(isItForOperator),
      )
      this.loadingSignalR = false
    }
  }

  finishWorkInstruction = (res: IEvent<number>) => {
    if (!res.payload) return

    const coolingOrders = this.items.filter(wi => wi.outboundWorkInstruction.id !== res.payload)
    this.updateStoreItems(coolingOrders)
  }

  async loadInProgressCoolingOrders(isItForOperator?: boolean) {
    const { data } =
      await createApiClient(ReeferTemperaturesApi).getInProgressCoolingOrdersV2(isItForOperator)

    this.updateStoreItems(data)
  }

  async getCoolingOrdersHistoryByContainer(containerNumber?: string) {
    const { data } =
      await createApiClient(ReeferTemperaturesApi).getCoolingOrdersHistoryByContainerV2(
        containerNumber,
      )

    return data
  }
}
