import { Button, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import { Box, Stack } from '@mui/system'
import { HeaderMessage, HeaderWithActions } from '@planning/components'
import { useTranslate } from '@tolgee/react'
import { observer } from 'mobx-react-lite'
import { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CoolingOrderDialogViewStore } from '../../Stores/CoolingOrderDialogViewStore'
import { ICoolingOrder } from '../../Stores/ServiceOrdersViewStore'
import { CoolingOrderNumericForm } from '../CoolingOrderNumericForm'
import { CoolingOrderForm } from './CoolingOrderForm'

export const CoolingOrderDialogRender: FC<{
  store: CoolingOrderDialogViewStore
  onClose: () => void
  onSubmit: (coolingOrder: ICoolingOrder) => void
  onDelete: () => void
}> = observer(({ store, onClose, onSubmit, onDelete }) => {
  const { t } = useTranslate()

  const form = useForm<ICoolingOrder>()

  useEffect(() => {
    if (store.coolingOrder) {
      form.reset(store.coolingOrder)
    } else
      form.reset({
        id: undefined,
        isMonitoringRequired: false,
        isPlugInRequired: false,
        requestedTemperature: undefined,
        minRequestedTemperature: undefined,
        maxRequestedTemperature: undefined,
        idealHumidity: undefined,
        minIdealHumidity: undefined,
        maxIdealHumidity: undefined,
        idealVentilation: undefined,
        minIdealVentilation: undefined,
        maxIdealVentilation: undefined,
        monitoringFrequency: undefined,
      })
  }, [store.coolingOrder, form])

  const getActions = () => {
    const actions = [
      <Button key='service-orders-cancel-btn' color='secondary' size='large' onClick={onClose}>
        {t('cancel', 'Cancel')}
      </Button>,
    ]

    actions.push(
      <Button
        key='service-orders-submit-btn'
        variant='contained'
        size='large'
        onClick={form.handleSubmit(onSubmit)}
      >
        {t('submit', 'Submit')}
      </Button>,
    )
    if (store.isEditMode) {
      actions.splice(
        0,
        0,
        <Button key='service-orders-cancel-btn' color='error' size='large' onClick={onDelete}>
          {t('delete', 'Delete')}
        </Button>,
      )
    }

    return actions
  }

  const coolingHeader = store.isEditMode
    ? t('editCoolingOrder', 'Edit cooling order')
    : t('createCoolingOrder', 'Create cooling order')

  const headerMessage: HeaderMessage | undefined = store.isFulfilled
    ? {
        message: t(
          'blockedFulfilledServiceOrderModification',
          'Order is fulfilled: You cannot make any changes to orders that have been completed',
        ),
        severity: 'success',
      }
    : undefined

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HeaderWithActions
            noBorder
            title={coolingHeader}
            actions={getActions()}
            headerMessage={headerMessage}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} padding={'0 24px'}>
        {/* LEFT PANEL */}
        <Grid item xs={12} sm={4}>
          <Box mb={'2rem'}>
            {!store.isEditMode && (
              <Stack direction='row' spacing={2} alignItems='center'>
                <Typography variant='subtitle2' mb={'.5rem'}>
                  {t('singleContainerSearch', 'Single container search')}
                </Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={store.isMultiSelectMode}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        store.reset()
                        store.setIsMultiSelectMode(event.target.checked)
                      }}
                    />
                  }
                  label={t('multiple', 'Multiple')}
                />
              </Stack>
            )}

            {store.isMultiSelectMode ? (
              <Typography variant='subtitle1' mb={'.5rem'}>
                {t('multipleContainerSearch', 'Multiple container search')}
              </Typography>
            ) : (
              <Typography variant='subtitle1' mb={'.5rem'}>
                {t('container', 'Container')}
              </Typography>
            )}

            <CoolingOrderNumericForm store={store} />
          </Box>
        </Grid>

        {/* RIGHT PANEL */}
        <Grid item xs={12} sm={8}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: '.5rem',
            }}
          ></Box>

          <FormProvider {...form}>
            <CoolingOrderForm />
          </FormProvider>
        </Grid>
      </Grid>
    </>
  )
})
