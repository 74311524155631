import { Box, Card, CardContent, Paper, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ReactNode, useRef } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { VariableSizeList } from 'react-window'
import { ContextMenu, GroupingContextAction } from './GroupingContextMenu'
import { NodeItem } from './GroupingNodeItem'
import { Row } from './GroupingRow'
import useContextMenu from './hook/use-context-menu.hook'
import { Group, GroupingState, GroupMap, Id, Item } from './types'

interface IProps {
  state: GroupingState
  groups: GroupMap
  group: Group
  items: Item[]
  itemSize?: number
  height?: number
  selectedItemIds: Id[]
  draggingItemId?: Id
  hasContextMenu: boolean
  actions?: GroupingContextAction[]
  addGroup: (itemIds?: Id[]) => void
  onSequenceChange: (groupId: Id, item: Item, previousSequence: number, newSequence: number) => void
  toggleSelection: (itemId: Id) => void
  toggleSelectionInGroup: (itemId: Id) => void
  multiSelectTo: (itemId: Id) => void
  renderData?: (item: Item, group: Group) => ReactNode
  itemSizeFn?: (item: Item) => number
  heightFn?: (items: Item[]) => number
}

const GroupingArea = observer(
  ({
    state,
    groups,
    group,
    items,
    itemSize = 50,
    height,
    selectedItemIds,
    draggingItemId,
    hasContextMenu,
    actions,
    onSequenceChange,
    toggleSelection,
    toggleSelectionInGroup,
    multiSelectTo,
    renderData,
    itemSizeFn,
    heightFn,
  }: IProps) => {
    const { handleOpenMenu, menuProps } = useContextMenu()
    const vslRef = useRef<VariableSizeList>(null)
    vslRef.current?.resetAfterIndex(0)

    const getHeight = () => {
      if (height) {
        return height
      } else if (heightFn) {
        return heightFn(items)
      } else {
        return items.length * itemSize
      }
    }

    return (
      <Box>
        <Droppable
          droppableId={group.id}
          mode='virtual'
          renderClone={(provided, _snapshot, rubric) => (
            <NodeItem
              group={group}
              provided={provided}
              item={items[rubric.source.index]}
              selectedItemIds={selectedItemIds}
              renderData={renderData}
            />
          )}
        >
          {(provided, snapshot) => (
            <Card
              sx={{
                zIndex: snapshot.isDraggingOver ? 0 : 1,
                borderRadius: 0,
              }}
              {...provided.droppableProps}
            >
              <CardContent sx={{ padding: 0, paddingBottom: '0px !important' }}>
                <Paper
                  sx={{
                    WebkitUserSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none',
                    userSelect: 'none',
                    textAlign: 'center',
                    borderRadius: 0,
                  }}
                >
                  <form id='sequence-form'>
                    <VariableSizeList
                      height={getHeight()}
                      width={'auto'}
                      itemCount={items.length}
                      itemSize={index => (itemSizeFn ? itemSizeFn(items[index]) : itemSize)}
                      itemData={items}
                      ref={vslRef}
                      outerRef={provided.innerRef}
                    >
                      {props => (
                        <Row
                          actions={actions}
                          props={props}
                          group={group}
                          selectedItemIds={selectedItemIds}
                          draggingItemId={draggingItemId}
                          onSequenceChange={onSequenceChange}
                          handleOpenMenu={handleOpenMenu}
                          toggleSelection={toggleSelection}
                          toggleSelectionInGroup={toggleSelectionInGroup}
                          multiSelectTo={multiSelectTo}
                          renderData={renderData}
                        />
                      )}
                    </VariableSizeList>
                  </form>
                  {(items?.length === 0 || !items) && (
                    <Typography sx={{ padding: 2 }} variant='body2' color='secondary'>
                      There are no items in this group
                    </Typography>
                  )}
                </Paper>
              </CardContent>
            </Card>
          )}
        </Droppable>
        {hasContextMenu && !group.hideContextMenu && (
          <ContextMenu
            state={state}
            actions={actions}
            menuProps={menuProps}
            groups={groups}
            group={group}
            selectedItemIds={selectedItemIds}
          />
        )}
      </Box>
    )
  },
)

export default GroupingArea
