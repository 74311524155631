import DevCycleProvider from '@devcycle/openfeature-web-provider'
import { EvaluationContextValue, OpenFeature, OpenFeatureProvider } from '@openfeature/react-sdk'
import { Loader } from '@tom-ui/ui'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useAppConfiguration } from './AppConfigurationProvider'

export type FeatureFlagContext = {
  user_id: string
} & Record<string, EvaluationContextValue>

export const FeatureFlagProvider: FC<{ children: ReactNode; context: FeatureFlagContext }> = ({
  children,
  context,
}) => {
  const { devCycleProviderSdkKey } = useAppConfiguration()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const init = async () => {
      if (devCycleProviderSdkKey) {
        OpenFeature.setContext(context)
        await OpenFeature.setProviderAndWait(new DevCycleProvider(devCycleProviderSdkKey))
        setInitialized(true)
      }
    }
    init()
  }, [context, devCycleProviderSdkKey])

  if (!initialized) return <Loader show />

  return <OpenFeatureProvider>{children}</OpenFeatureProvider>
}
